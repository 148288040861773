import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { getEdgeVersionsSelector } from "selectors";
import { updateEdgeGatewayVersions } from 'actions'
import yamljs from "yamljs";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

import { WithNotifications } from "components/HigherOrder";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import LoadingModal from "components/LoadingModal/LoadingModal.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Table from "components/Table/Table.jsx";
import WizardModal from "components/Modal/Wizard";

// local comps
import DicomDevicesTable from "./components/DicomDevicesTable";
import AddDicomDevice from "./components/AddDicomDevice";
import EdgeAppsList from "./components/EdgeAppsList";
import InstallApp from "./components/InstallApp";
import RouteManager from "./components/RouteManager";
import ConfigManager from "./components/ConfigManager";
import EdgeConfig from "./components/EdgeConfig";
import LogsTable from "./components/LogsTable";
import LogViewer from "./components/LogViewer";
import EULA from "views/TermsAndConditions/EULA";

import { VersionSelectStep, ConfirmStep } from './components/DeviceVersionWizard';

// styles
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import tableStyle from "assets/jss/material-dashboard-pro-react/components/tableStyle.jsx";

// api lib
import {
  getDevice, getDicomNodes, addDicomNode,
  getInstalledApps,
  reDownloadDockerApp, edgeUninstallApp,
  requestAppInstall, removeRouteFromApp,
  addRouteToApp, requestAppUpdate, getAllowedApps,
  deleteDevice, deleteDicomNode, uploadEdgeLogs,
  getWebPreviewToken, sendMqttMessage,
  toggleNotifications, publishAuditTrail,
  getEdgeVersions
} from "libs/apiLib";

import { timeout, UInt8ArrToString } from "libs/utils";
import { listDeviceLogs, downloadFileFromS3 } from "libs/awsLib";

const style = {
  ...dashboardStyle,
  ...sweetAlertStyle,
  ...tableStyle
};

class Device extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      device: null,
      installedApps: null,
      availableApps: null,
      dcmDevices: null,
      modal: null,
      selectedApp: null,
      awsToken: null,
      logs: [],
      proposedUpdate: {
        version: "",
        baseConfig: "",
        config: "",
        baseAnonConfig: "",
        dcm_anon: ""
      },
      step: 0
    };

    this.createDicomDevice = this.createDicomDevice.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleRefreshDcmDevices = this.handleRefreshDcmDevices.bind(this);
    this.handleNewDicomDevice = this.handleNewDicomDevice.bind(this);
    this.handleRefreshOfApps = this.handleRefreshOfApps.bind(this);
    this.getAvailableApps = this.getAvailableApps.bind(this);
    this.getDcmDevices = this.getDcmDevices.bind(this);
    this.getInstalledApps = this.getInstalledApps.bind(this);
    this.handleConfigureApp = this.handleConfigureApp.bind(this);
    this.redownloadApp = this.redownloadApp.bind(this);
    this.uninstallApp = this.uninstallApp.bind(this);
    this.handleInstallApp = this.handleInstallApp.bind(this);
    this.installApp = this.installApp.bind(this);
    this.handleRouteManager = this.handleRouteManager.bind(this);
    this.handleDeleteRoute = this.handleDeleteRoute.bind(this);
    this.handleAddRoute = this.handleAddRoute.bind(this);
    this.handleConfigManager = this.handleConfigManager.bind(this);
    this.handleEdgeConfig = this.handleEdgeConfig.bind(this);
    this.setSelectedApp = this.setSelectedApp.bind(this);
    this.handleConfigSave = this.handleConfigSave.bind(this);
    this.deleteDevice = this.deleteDevice.bind(this);
    this.handleDeleteDevice = this.handleDeleteDevice.bind(this);
    this.handleShowEULA = this.handleShowEULA.bind(this);
  }

  async componentDidMount() {

    this.setState({ isLoading: true });

    try {
      const urlParams = new URLSearchParams(this.props.location.search);
      const deviceId = urlParams.get("id");

      if (this.props.location.state) {
        if (this.props.location.state.device) {
          this.setState({ device: this.props.location.state.device }, async () => {
            await this.postDeviceFetch();
          });
        }
      } else if (deviceId) {
        const device = await getDevice(deviceId);
        this.setState({ device: device.device }, async () => {
          await this.postDeviceFetch();
        });
      } else { this.props.history.push({ pathname: "/dashboard" }); }
    } catch (e) {
      console.error(e);
    }

    try {
      if (!this.props.edgeVersions.length) {
        const resp = await this.props.getEdgeVersions()
        if (resp.versions) {
          const versions = resp.versions.sort((a, b) => {
            if (a.version > b.version) {
              return -1
            } else if (a.version == b.version) {
              return 0
            } else {
              return 1
            }
          })
          this.props.updateEdgeGatewayVersions(versions)
        }
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({ isLoading: false });
  }

  pluckVersion = (str) => {
    if (str) { return str.replace(/[-a-zA-Z]/g, ""); }
  }

  postDeviceFetch = async () => {

    await this.getAvailableApps();
    this.getDcmDevices(this.state.device.deviceId);
    this.getInstalledApps(this.state.device.deviceId);

    if (this.state.device) {
      await this.updateAWSToken(async () => {
        await this.getLogs();
      });
    }
  }

  updateAWSToken = async (cb) => {
    const results = await getWebPreviewToken(this.state.device.orgId);
    if (results) {
      if (results.success && results.token) {
        this.setState({ awsToken: results.token }, () => cb ? cb() : null);
      }
    }

  }

  getLogs = async () => {
    const logsRequest = await listDeviceLogs(
      this.state.awsToken,
      this.state.device.deviceId,
      this.state.device.orgId
    );

    this.setState({
      logs: logsRequest.Contents
    });
  }

  handleViewLog = async (key) => {
    try {
      const { awsToken } = this.state;

      if (awsToken.expired) { await this.updateAWSToken(() => { this.handleViewLog(key); }); }
      else {
        this.showLoading();

        let log = await downloadFileFromS3(key, awsToken);
        log = await UInt8ArrToString(log.Body);
        this.setState({
          modal: (
            <LogViewer log={log} onClose={() => this.closeModal()} />
          )
        });
      }
    } catch (e) {
      console.error(e);
      this.showError("Failed to retrieve logs.");
    }
  }

  showError = (err) => {
    this.setState({
      modal: (
        <SweetAlert
          type="error"
          style={{ display: "block", marginTop: "-100px" }}
          title="Ohh No!"
          onConfirm={() => this.closeModal()}
          onCancel={() => this.closeModal()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div>
            {err}
          </div>
          <div style={{ marginTop: "40px" }}>
            <Button onClick={() => this.closeModal()}>Close</Button>
          </div>
        </SweetAlert>
      )
    });
  }

  handleRefreshDcmDevices = async () => {
    this.showLoading();

    if (this.state.device) { await this.getDcmDevices(this.state.device.deviceId); }

    this.closeModal();
  }

  handleNewDicomDevice = async () => {
    this.setState({
      modal: (<AddDicomDevice
        onCreate={this.createDicomDevice}
        onCancel={this.closeModal}
      />)
    });
  }

  async createDicomDevice({ name, aeTitle, ipAddress, port }) {
    this.showLoading();

    try {
      const results = await addDicomNode(this.state.device.deviceId, { name, aeTitle, ipAddress, port });
      if (!results.success) { throw new Error("Failed to retrieve Dicom Nodes"); }

      await this.getDcmDevices(this.state.device.deviceId);
    } catch (e) {
      console.error(e);
    }

    this.closeModal();
  }

  handleDeleteDicomDevice = async (nodeId) => {
    this.showLoading();

    try {
      const results = await deleteDicomNode(this.state.device.deviceId, nodeId);
      if (!results.success) { throw new Error("Failed to detele dicom device"); }

      await this.getDcmDevices(this.state.device.deviceId);
    } catch (e) {
      console.error(e);
    }

    this.closeModal();
  }

  closeModal() {
    this.setState({
      modal: null
    });
  }

  getDcmDevices = async (deviceId) => {
    try {
      const nodes = await getDicomNodes(deviceId);
      if (nodes.success) { this.setState({ dcmDevices: nodes.dicomNodes }); }
    } catch (e) {
      console.error(e);
    }
  }

  getAvailableApps = async () => {
    try {
      const result = await getAllowedApps(this.state.device.orgId);
      if (result.success) { this.setState({ availableApps: result.apps }); }
    } catch (e) {
      console.error(e);
    }
  }

  getInstalledApps = (deviceId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const installedApps = await getInstalledApps(deviceId);
        if (!installedApps.success) { throw new Error("Failed to get apps"); }

        this.setState({ installedApps: installedApps.apps },
          () => { resolve(); });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  handleRefreshOfApps = async () => {
    this.showLoading();

    try {
      await this.getAvailableApps();
      await this.getInstalledApps(this.state.device.deviceId);
    } catch (e) {
      console.error(e);
    }

    this.closeModal();
  }

  handleConfigureApp = async (app, action) => {
    switch (action) {
    case "Re-Download":
      this.redownloadApp(app);
      break;
    case "Uninstall":
      this.uninstallApp(app);
      break;
    case "Manage Routes":
      this.handleRouteManager(app);
      break;
    case "Config Manager":
      await this.setSelectedApp(app);
      this.handleConfigManager(app);
      break;
    default:
      break;
    }
  }

  handleActions = async (action) => {
    switch (action) {
    case "Upload Logs":
      this.handleSendMqttMessage("uploadEdgeLogs");
      break;
    case "Delete Device":
      this.handleDeleteDevice();
      break;
    case "Dump SCP Logs":
      this.handleSendMqttMessage("dumpScpLogs");
      break;
    case "Dump Mongo Logs":
      this.handleSendMqttMessage("dumpMongoLogs");
      break;
    case "Edge Config":
      this.handleEdgeConfig();
      break;
    case "Anonymization Settings":
      this.handleAnonymizeConfig();
      break;
    case "Restart Edge Gateway":
      this.handleEdgeRestart();
      break;
    case "Reinstall Edge":
    case "Update Edge":
      if (!this.state.device) {
        return;
      }
      this.setState(prevState => ({ ...prevState, modal: 'updateEdge', proposedUpdate: { ...prevState.proposedUpdate, config: this.state.device.configYAML, dcm_anon: this.state.device.dcmAnonYAML } }))
      break;
    case "Enable Notifications":
      this.toggleNotifications(true);
      break;
    case "Disable Notifications":
      this.toggleNotifications(false);
      break;
    default:
      break;
    }
  }

  toggleNotifications = async (enable) => {
    this.setState({ isLoading: true });
    const { device } = this.state;
    const { user, signalError, clearError, signalInfo, clearInfo } = this.props;
    try {
      if (!device || (!user.superAdmin && !user.supportEngineer)) {
        throw new Error("You do not have permissions.");
      }
      const deviceResponse = await toggleNotifications(device.deviceId, enable, user.orgId);
      if (deviceResponse.device.Item) {
        this.setState({ device: deviceResponse.device.Item });
        signalInfo(`Successfully ${enable ? "enabled" : "disabled"} notifications for this device.`);
        setTimeout(clearInfo, 5000);
      }
    } catch (error) {
      const errMsg = error.message ? error.message : error;
      signalError(`Error ${enable ? "enabling" : "disabling"} notifications for this device. ${errMsg}`);
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  redownloadApp = async (app) => {
    this.showLoading();

    try {
      await reDownloadDockerApp(app, this.state.device.deviceId);
      await timeout(5000);
      await this.getInstalledApps(this.state.device.deviceId);
    } catch (e) {
      console.error(e);
    }

    this.closeModal();
  }

  uninstallApp = async (app) => {

    this.showLoading();

    try {
      await edgeUninstallApp(app.id, this.state.device.deviceId);
      await this.getInstalledApps(this.state.device.deviceId);
    } catch (e) {
      console.error(e);
    }

    this.closeModal();
  }

  handleInstallApp = async (app) => {

    this.setState({
      modal: (
        <EULA onClose={() => this.closeModal()} show={true} showInstall={true}
          onInstall={ async () => {
            try {
              const { user: { firstName, lastName, userName } } = this.props;
              await publishAuditTrail({
                type: "addAppEULAAccept",
                description: `${firstName} ${lastName} (${userName}) has accepted the EULA for app ${app.name} (${app.appId}). This app applies to the device ${this.state.device.deviceId}`,
                PHI: false,
                orgId: undefined
              });
            } catch (_) {
            }
            this.setState({
              modal: <InstallApp
                onCancel={this.closeModal}
                onInstall={(app) => this.installApp(app.app, app.installType, app.installVersion)}
                app={app}
                user={this.props.user}
              />
            });
          }}
        />
      )
    });
  }

  handleRouteManager = (app) => {
    this.setState({
      modal: <RouteManager
        onCancel={this.closeModal}
        app={app}
        dcmDevices={this.state.dcmDevices}
        onAddRoute={(app, route) => this.handleAddRoute(app, route)}
        onDeleteRoute={(app, route, index) => this.handleDeleteRoute(app, route, index)}
      />
    });
  }

  async handleDeleteRoute(app, route, index) {
    this.showLoading();

    try {
      await removeRouteFromApp(this.state.device.deviceId, { app, route, index });
      await this.getInstalledApps(this.state.device.deviceId);
      const updatedApp = this.state.installedApps.find((a) => {
        return a.appId === app.appId;
      });

      if (updatedApp) { this.handleRouteManager(updatedApp); }
    } catch (e) {
      console.error(e);
      this.closeModal();
    }
  }

  async handleAddRoute(app, route) {
    this.showLoading();

    try {
      await addRouteToApp(this.state.device.deviceId, { app, route });
      await this.getInstalledApps(this.state.device.deviceId);
      const updatedApp = this.state.installedApps.find((a) => {
        return a.appId === app.appId;
      });

      if (updatedApp) { this.handleRouteManager(updatedApp); }
      else {
        this.setState({
          modal: null
        });
      }
    } catch (e) {
      console.error(e);
      this.closeModal();
    }
  }

  async installApp(app, installType, installVersion) {
    this.showLoading();
    try {
      await requestAppInstall(this.state.device.deviceId, { app, installType, installVersion });
      await timeout(3000);
      await this.getInstalledApps(this.state.device.deviceId);
    } catch (e) {
      console.error(e);
    }

    this.closeModal();
  }

  setSelectedApp(app) {
    return new Promise((resolve) => {
      this.setState({ selectedApp: app }, () => {
        resolve();
      });
    });
  }

  handleConfigManager() {
    this.setState({
      modal: <ConfigManager
        device={this.state.device}
        onClose={this.closeModal}
        app={this.state.selectedApp}
        onUpdate={(app) => {
          this.setState({
            selectedApp: app
          });
        }}
        onSave={this.handleConfigSave}
      />
    });
  }

  handleEdgeConfig() {
    if (this.state.device.configYAML) {
      this.setState({
        modal: <EdgeConfig
          onClose={this.closeModal}
          config={this.state.device.configYAML}
          onSave={(config) => { this.handleSendMqttMessage("updateEdgeConfig", { config }); }}
          title="EdgeOS Configuration"
        />
      });
    }
  }

  handleAnonymizeConfig() {
    if (this.state.device.dcmAnonYAML) {
      this.setState({
        modal: <EdgeConfig
          onClose={this.closeModal}
          config={this.state.device.dcmAnonYAML}
          onSave={(config) => { this.handleSendMqttMessage("updateAnonConfig", { config }); }}
          title="Anonymization Settings"
        />
      });
    }
  }

  handleConfigSave = async (app) => {
    this.showLoading();

    try {
      await requestAppUpdate(this.state.device.deviceId, app);
      await this.getInstalledApps(this.state.device.deviceId);
      const updatedApp = this.state.installedApps.find((a) => {
        return a.appId === app.appId;
      });

      if (updatedApp) {
        await this.setSelectedApp(updatedApp);
        this.handleConfigManager(updatedApp);
      } else {
        this.setState({
          modal: null
        });
      }
    } catch (e) {
      console.error(e);
      this.closeModal();
    }
  }

  handleDeleteDevice = async () => {
    this.setState({
      modal: (<SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => this.closeModal()}
        onCancel={() => this.closeModal()}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        showConfirm={false}
      >
        <div>
          Are you sure you want to delete this device? This cannot be undone.
  			</div>
        <div style={{ marginTop: "40px" }}>
          <Button color="danger"
            style={{ marginRight: "10px" }}
            onClick={() => this.closeModal()}
          >Cancel</Button>
          <Button color="success"
            onClick={() => this.deleteDevice()}
          >Delete</Button>
        </div>
      </SweetAlert>
      )
    });
  }

  async deleteDevice() {
    this.showLoading();

    try {
      await deleteDevice(this.state.device);
      this.setState({
        modal: null
      }, () => {
        this.props.history.push({
          pathname: "/edge/devices"
        });
      });
    } catch (e) {
      console.error(e);
      this.closeModal();
    }
  }

  handleUploadLogsRequest = async () => {
    this.showLoading();

    try {
      await uploadEdgeLogs(this.state.device.deviceId);
    } catch (e) {
      console.error(e);
    }
    this.closeModal();
  }

  handleSendMqttMessage = async (mqttEndpoint, payload) => {
    this.showLoading();

    try {
      await sendMqttMessage(
        this.state.device.deviceId,
        mqttEndpoint,
        payload
      );
      this.closeModal();
    } catch (e) {
      this.showError(e);
    }
  }

  handleShowEULA = (e) => {
    e.preventDefault();
    this.setState({
      modal: (
        <EULA onClose={() => this.closeModal()} show={true} />
      )
    });
  }

  showLoading() {
    this.setState({
      modal: <LoadingModal />
    });
  }

  handleEdgeRestart = async () => {
    this.setState({
      modal: (<SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => this.closeModal()}
        onCancel={() => this.closeModal()}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        showConfirm={false}
      >
        <div>
          Are you sure you want to restart the Edge Gateway on this device?
  			</div>
        <div style={{ marginTop: "40px" }}>
          <Button color="danger"
            style={{ marginRight: "10px" }}
            onClick={() => this.closeModal()}
          >Cancel</Button>
          <Button color="success"
            onClick={() => this.handleSendMqttMessage("restartEdge", {})}
          >Restart</Button>
        </div>
      </SweetAlert>
      )
    });
  }

  dropdownList = () => {
    const { device } = this.state;
    const { user } = this.props;
    const list = [
      "Upload Logs",
      "Edge Config",
      "Anonymization Settings",
      "Dump SCP Logs",
      "Dump Mongo Logs",
      "Restart Edge Gateway"
    ];

    if (device) {
      list.push("Update Edge")
    }

    if (device && (user.superAdmin || user.supportEngineer)) {
      list.push({ divider: true });
      list.push("Delete Device");
      list.push(device.notifications ? "Disable Notifications" : "Enable Notifications");
    }

    return list;
  }

  disableNextButton = () => {
    const { isLoading, step, proposedUpdate } = this.state;
    if (isLoading || this.props.isLoading) {
      return true;
    }
    switch (step) {
    case 0: // select version stage
      return proposedUpdate.version === "";
    // case 1: // update device config stage
    //   try {
    //     yamljs.parse(proposedUpdate.config)
    //     return false;
    //   } catch (e) {
    //     return true;
    //   }
    // case 2: // update anonymization config stage
    //   try {
    //     yamljs.parse(proposedUpdate.dcm_anon)
    //     return false;
    //   } catch (e) {
    //     return true;
    //   }
    default:
      return false;
    }
  }

  onSelectVersion = (version) => {
    const edgeBuild = this.props.edgeVersions.find(ev => ev.version === version)
    this.setState(prevState => ({
      ...prevState,
      proposedUpdate: {
        ...prevState.proposedUpdate,
        version: edgeBuild.version,
        baseConfig: edgeBuild.config,
        baseAnonConfig: edgeBuild.dcm_anon
      }
    }))
  }

  onUpdateConfig = (type, config) => {
    this.setState(prevState => ({ ...prevState, proposedUpdate: {
      ...prevState.proposedUpdate,
      [type]: config
    }}))
  }

  onUpdateEdgeSubmit = () => {
    this.handleSendMqttMessage("updateEdge", {
      version: this.state.proposedUpdate.version,
      config: this.state.proposedUpdate.config,
      dcm_anon: this.state.proposedUpdate.dcm_anon
    })
  }

  renderModal = () => {
    const { modal, isLoading } = this.state;
    switch (modal) {
    case "updateEdge":
      return <WizardModal
        style={{
          width: "50%",
          height: "80%",
          overflow: "hidden",
          overflowY: "scroll",
        }}
        color="primary"
        title="Select Version"
        steps={[
          { stepName: "Version", stepTitle: "Select Version", stepComponent: () => <VersionSelectStep selectedVersion={this.state.proposedUpdate.version.length && this.state.proposedUpdate.version || ""} versions={this.props.edgeVersions.map(v => v.version)} onSelect={this.onSelectVersion} />, stepId: "version" },
          // { stepName: "Device Configuration", stepTitle: "Merge Configuration", stepComponent: () => <ConfigMergeStep selectedVersion={{ version: this.state.proposedUpdate.version, config: this.state.proposedUpdate.baseConfig }} currentVersion={{ version: this.state.device.softwareVersion, config: this.state.proposedUpdate.config }}  onUpdate={config => this.onUpdateConfig('config', config)} />, stepId: "config" },
          // { stepName: "Anonymization Configuration", stepTitle: "Merge Anonymization Configuration", stepComponent: () => <ConfigMergeStep selectedVersion={{ version: this.state.proposedUpdate.version, config: this.state.proposedUpdate.baseAnonConfig }} currentVersion={{ version: this.state.device.softwareVersion, config: this.state.proposedUpdate.dcm_anon }}  onUpdate={(config ) => this.onUpdateConfig('dcm_anon', config)} />, stepId: "anon_config" },
          { stepName: "Confirm", stepTitle: "Confirm Details", stepComponent: () => <ConfirmStep selectedVersion={this.state.proposedUpdate} />, stepId: 'confirm'}
        ]}
        previousButtonText="Back"
        finishButtonText="Submit"
        onCancel={() => this.setState({ modal: null })}
        disableNext={this.disableNextButton()}
        onStepChange={(step) => this.setState({ step })}
        finishButtonClick={this.onUpdateEdgeSubmit}
        loading={isLoading}
      />
    default:
      return null;
    }
  }

  render() {
    let deviceName;
    let deviceData = [];
    const { user } = this.props;

    if (this.state.device) {
      const { device } = this.state;
      deviceName = device.name;

      if (device.softwareVersion) { deviceData.push(["Software Version", device.softwareVersion]); }

      if (device.gpu) { deviceData.push(["Type", `${device.gpu == "no" ? "CPU" : "GPU"}`]); }

      if (device.localIPv4) { deviceData.push(["IP Address", device.localIPv4]); }

      if (device.config) {
        if (device.config.DicomGateway) {
          if (device.config.DicomGateway.Port) { deviceData.push(["Dicom Port", device.config.DicomGateway.Port]); }
        }
      }

      if (device.checkIn) { deviceData.push(["Check-In", `${moment.duration(moment().diff(moment(device.checkIn))).humanize()} ago`]); }

      if (device.locationDetails && device.locationDetails.city && device.locationDetails.region_code && device.locationDetails.country_code) {
        deviceData.push(["Location", `${device.locationDetails.city}, ${device.locationDetails.region_code} ${device.locationDetails.country_code}`]);
      }
      
      if (
        device.diskSpace !== undefined &&
        device.diskSpaceUsed !== undefined &&
        device.diskSpace !== 0 &&
        device.diskSpaceUsed !== 0 &&
        device.diskSpaceUsed <= device.diskSpace
      ) {
        const diskSpaceGB = Number((device.diskSpace * 1e-9).toFixed(2));
        const usedDiskSpaceGB = Number((device.diskSpaceUsed * 1e-9).toFixed(2));
        const percentUsage = Number((( device.diskSpaceUsed / device.diskSpace) * 100).toFixed(2));
        deviceData.push(
          ["Disk Space (\"/\")", diskSpaceGB + "GB"],
          ["Used Disk Space", usedDiskSpaceGB + "GB"],
          ["Percent Disk Usage", percentUsage + "%"],
        );
      }
    }

    let apps = [];

    if (this.state.availableApps && this.state.installedApps) {
      this.state.availableApps.forEach((app) => {
        if (app.type === "Cloud & Edge" || app.type === "Edge") {
          let installedApp = this.state.installedApps.find((installApp) => {
            return installApp.appId === app.appId && installApp.installed !== false;
          });

          if (installedApp) {
            if (installedApp.installed === false) { app.installed = false; }
            else { app.installed = true; }

            app.mode = installedApp.mode;
            app.appConfigs = installedApp.appConfigs;
            app.cloud = installedApp.cloud;
            app.edge = installedApp.edge;
            app.config = installedApp.config;
            app.deviceId = installedApp.deviceId;
            app.id = installedApp.id;
            app._id = installedApp._id;
            app.appStatus = installedApp.appStatus;
            app.version = installedApp.version;

            if (installedApp.routes) { app.routes = installedApp.routes; }

          } else {
            app.installed = false;
          }

          // do a check to see if user can see the app
          if (app.latestRelease) {
            apps.push(app);
          } else if (app.betaRelease &&
            (user.superAdmin || user.supportEngineer
              || user.appAdmin || user.isResearch)) {
            apps.push(app);
          } else if (user.superAdmin || user.supportEngineer || user.appAdmin) {
            apps.push(app);
          }
        }
      });
    }

    return (
      <div>
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              headerColor="orange"
              cardTitle={deviceName ? deviceName : "Loading..."}
              cardSubtitle="Manage Your Device"
              content={
                <GridContainer>
                  <ItemGrid xs={12} sm={12}>
                    <div style={{ textAlign: "right" }}>
                      <CustomDropdown
                        buttonText="More Actions"
                        buttonColor="primary"
                        dropdownList={this.dropdownList()}
                        onAction={(action) => this.handleActions(action)}
                      />
                    </div>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12}>
                    <h4>Details</h4>
                    <Table
                      tableData={deviceData}
                    />
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        </GridContainer>
        {this.state.modal !== "updateEdge" && this.state.modal}
        {this.renderModal()}
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              headerColor="green"
              cardTitle="Apps"
              cardSubtitle="Installed and Available Apps"
              content={
                <GridContainer>
                  <ItemGrid xs={6}>
                    <div style={{ textAlign: "left" }}>
                      Please note that by installing, downloading, and/or using our apps below you agree to our <a href="" onClick={this.handleShowEULA}>End-user License Agreement</a>.
  									</div>
                  </ItemGrid>
                  <ItemGrid xs={6}>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        style={{ marginRight: "10px" }}
                        onClick={this.handleRefreshOfApps}
                      >
                        Refresh</Button>
                    </div>
                  </ItemGrid>
                </GridContainer>}
            />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            {
              apps &&
              <EdgeAppsList apps={apps}
                onConfigureApp={this.handleConfigureApp}
                onInstallApp={this.handleInstallApp} />
            }
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              headerColor="blue"
              cardTitle="Dicom Devices"
              cardSubtitle="Allowed Dicom Devices Configured on Edge Device"
              content={
                <div>
                  <ItemGrid xs={12}>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        style={{ marginRight: "10px" }}
                        onClick={this.handleRefreshDcmDevices}
                      >
                        Refresh</Button>
                      <Button
                        onClick={this.handleNewDicomDevice}
                        color="success"
                      >
                        New</Button>
                    </div>
                  </ItemGrid>
                  {this.state.dcmDevices &&
                    <DicomDevicesTable data={this.state.dcmDevices} onDelete={(nodeId) => this.handleDeleteDicomDevice(nodeId)} />}
                </div>}
            />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              headerColor="blue"
              cardTitle="Logs"
              cardSubtitle="EdgeOS Logs"
              content={
                <div>
                  <ItemGrid xs={12}>
                    <div style={{ textAlign: "right" }}>
                      {/* <Button
                                                style={{ marginRight: "10px" }}
                                                onClick={this.handleRefreshDcmDevices}
                                            >
                                                Refresh</Button> */}
                    </div>
                  </ItemGrid>
                  {this.state.logs &&
                    <LogsTable data={this.state.logs} onLoad={(key) => { this.handleViewLog(key); }} />}
                </div>}
            />
          </ItemGrid>
        </GridContainer>
      </div >
    );
  }
}

Device.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    success: PropTypes.string,
    danger: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      device: PropTypes.shape({}),
    }),
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  signalInfo: PropTypes.func.isRequired,
  edgeVersions: PropTypes.arrayOf(PropTypes.shape({
    version: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    config: PropTypes.string.isRequired,
    dcm_anon: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = (state, props) => ({
  user: state.user.user,
  edgeVersions: getEdgeVersionsSelector(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  getEdgeVersions,
  updateEdgeGatewayVersions: (versions) => dispatch(updateEdgeGatewayVersions(versions)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WithNotifications(withRouter(withStyles(style)(Device))));
