import React from "react";
import pt from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { WithNotifications } from "components/HigherOrder";
import User from "models/User";
import Job from "models/Job";
import { userSelector, accessControlSelector } from "selectors";
import { APPSSET } from "constants/Apps";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import StatsCard from "components/Cards/StatsCardSmall.jsx";
import Button from "components/CustomButtons/Button.jsx";
import LoadingModal from "components/LoadingModal/LoadingModal.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import CustomDropdownSmall from "components/CustomDropdown/CustomDropdownSmall.jsx";

// material-ui icons
import DateRange from "material-ui-icons/DateRange";
import Face from "material-ui-icons/Face";
import SettingsEthernet from "material-ui-icons/SettingsEthernet";
import CloudIcon from "material-ui-icons/CloudQueue";
import DeviceIcon from "material-ui-icons/Devices";
import Vibration from "material-ui-icons/Vibration";
import InfoOutline from "material-ui-icons/InfoOutline";
import Group from "material-ui-icons/Group";
import AddAlert from "material-ui-icons/AddAlert";

// local components
import LogViewer from "views/Case/components/LogViewer";

// api & libs
import { UInt8ArrToString } from "libs/utils";
import { downloadFileFromS3 } from "libs/awsLib";
import {
  getWebPreviewToken,
  deleteJobOnEdge, deleteJobOnCloud, replayJobOnEdge,
  sendDicomRoutes, uploadJobToCloud,
  getJobInputDownloadURL
} from "libs/apiLib";
import { webPreviewTokenUpdate } from "actions";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const style = {
  ...dashboardStyle,
  ...sweetAlertStyle
};

class GeneralCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      job: props.accessControl.hidePHI ? Job.anonymize(props.result) : props.result,
      modal: null,
    };

    this.updateWebPreviewToken = this.updateWebPreviewToken.bind(this);
    this.handleGetLogs = this.handleGetLogs.bind(this);
    this.handleViewCaseDetails = this.handleViewCaseDetails.bind(this);

    this.updateWebPreviewToken(props.result.orgId);
  }

  async updateWebPreviewToken(orgId) {
    const { updateWebPreviewToken, getWebPreviewToken, signalError } = this.props;
    try {
      const results = await getWebPreviewToken(orgId);
      if (results && results.success && results.token) {
        updateWebPreviewToken(results.token);
      }
    } catch (e) {
      signalError(e.message ? e.message : e);
    }
  }

  handleGetLogs = async () => {
    this.showLoading();
    const { webPreviewToken } = this.props;
    const { job } = this.state;

    if (webPreviewToken.expired) {
      await this.updateWebPreviewToken();
    }

    try {
      const key = `${job.outputDir}/app.log`;
      let log = await downloadFileFromS3(key, webPreviewToken);
      log = await UInt8ArrToString(log.Body);
      this.setState({
        modal: (
          <LogViewer log={log} onClose={() => this.setState({ modal: null })} />
        )
      });
    } catch (e) {
      this.props.signalError(e.message ? e.message : e);
      this.showError("Failed to retrieve logs. The app may have not run due to some configuration issue. Check the alerts at the top of this page for more details.");
    }
  }

  handleViewCaseDetails = async () => {
    try {
      if (this.state.job) {
        this.setState({
          modal: (
            <LogViewer log={JSON.stringify(this.state.job, undefined, "\t")}
              onClose={() => this.setState({ modal: null })}
            />
          )
        });
      }
    } catch (e) {
      this.props.signalError(e.message ? e.message : e);
    }
  }

  handleDeleteJob = async () => {
    this.setState({
      modal: (<SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => this.hideModal()}
        onCancel={() => this.hideModal()}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        showConfirm={false}
      >
        <div>
                  Are you sure you want to delete this case?
        </div>
        <div style={{ marginTop: "40px" }}>
          <Button color="danger"
            style={{ marginRight: "10px" }}
            onClick={() => this.hideModal()}
          >Cancel</Button>
          <Button color="success"
            onClick={() => this.deleteJob()}
          >Delete</Button>
        </div>
      </SweetAlert>
      )
    });
  }

  deleteJob = async () => {
    const { job } = this.state;
    this.showLoading();

    try {
      try {
        // delete on edge if originated from edge
        if (job.deviceId) {
          await deleteJobOnEdge(job.deviceId, job.eventId);
        }
      } catch (e) {
        const err = e.message ? e.message : e;
        this.props.signalError(`Failed to delete job on Edge device: ${err}.`);
      }

      // delete from cloud db and its s3 files
      await deleteJobOnCloud(job.eventId);

    } catch (e) {
      const err = e.message ? e.message : e;
      this.props.signalError(`Failed to delete job in cloud: ${err}.`);
    }

    this.props.history.push({ pathname: "/dashboard" });
  }

  showLoading() {
    this.setState({
      modal: <LoadingModal />
    });
  }

  hideModal() {
    this.setState({
      modal: null
    });
  }

  handleCaseAction = async (action) => {
    switch (action) {
    case "App Logs":
      this.handleGetLogs();
      break;
    case "Upload Input Data (Anonymized)":
      this.handleUploadToCloud();
      break;
    case "Delete":
      this.handleDeleteJob();
      break;
    case "Send To Dicom Routes":
      this.handleSendDicomRoutes();
      break;
    case "Replay":
      this.handleReplayJob();
      break;
    case "Download Input Data":
      this.handleGetInputDownloadUrl();
      break;
    case "View Case Details":
      this.handleViewCaseDetails();
      break;
    default:
      break;
    }
  }

  handleGetInputDownloadUrl = async () => {
    const { job } = this.state;
    this.showLoading();

    try {
      const result = await getJobInputDownloadURL(job.eventId);
      if (result.downloadUrl) {
        window.open(result.downloadUrl, "_self");
      }

      this.hideModal();
    } catch (e) {
      this.props.signalError(e.message ? e.message : e);
    }
  }

  handleReplayJob = async () => {

    const { job } = this.state;
    this.showLoading();

    try {
      // is an edge job?
      if (job.mode == "EDGE") {
        await replayJobOnEdge(job.deviceId, job.eventId);
      }

      this.hideModal();
    } catch (e) {
      this.props.signalError(e.message ? e.message : e);
    }

  }

  handleSendDicomRoutes = async () => {

    const { job } = this.state;
    this.showLoading();

    try {
      // did job originate from edge?
      if (job.deviceId) {
        await sendDicomRoutes(job.deviceId, job.eventId);
      }

      this.hideModal();
    } catch (e) {
      this.props.signalError(e.message ? e.message : e);
    }
  }

  handleUploadToCloud = async () => {
    const { job } = this.state;
    this.showLoading();

    try {
      // did job originate from edge?
      if (job.deviceId) {
        await uploadJobToCloud(job.deviceId, job.eventId);
      }

      this.hideModal();
    } catch (e) {
      this.props.signalError(e.message ? e.message : e);
    }
  }

  showError = (err) => {
    this.setState({
      modal: (
        <SweetAlert
          type="error"
          style={{ display: "block", marginTop: "-100px" }}
          title="Ohh No!"
          onConfirm={() => this.hideModal()}
          onCancel={() => this.hideModal()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div>
            {err}
          </div>
          <div style={{ marginTop: "40px" }}>
            <Button onClick={() => this.hideModal()}>Close</Button>
          </div>
        </SweetAlert>
      )
    });
  }

  defineDropdownActions () {
    const { user, result } = this.props;
    let actions = [];
    if (result && result.mode !== "CLOUD") {
      actions.push("App Logs");
    }

    if (result && result.deviceId) {
      actions.push(
        "Delete",
        "Send To Dicom Routes",
        "Replay"
      );
    }

    if (result && user.isHLXSupport) {
      if (actions.length > 0) {
        actions.push({ divider: true });
      }
      actions.push("View Case Details");
      if (!APPSSET.has(result.appId)) {
        actions.push(
          "Download Input Data",
          "Upload Input Data (Anonymized)"
        );
      }
    }
    return actions;
  }

  showJobErrors() {
    const { job } = this.state;
    if (job && job.logs && job.logs.length) {
      return  <div style={{ textAlign: "right" }}>
        {job.logs.map((log, index) => {
          return (
            <SnackbarContent
              message={log.message}
              color="danger"
              key={index}
              icon={AddAlert}
            />
          );
        })}
      </div>;
    }
    return null;
  }

  render() {
    const { job } = this.state;
    return (
      <React.Fragment>
        {this.state.modal}
        {this.showJobErrors()}
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={8} lg={8}>
            <StatsCard
              icon={Face}
              iconColor="orange"
              title="Patient"
              description={`${job.PatientsName || "Anonymized"}, ${job.PatientID}`}
              statIcon={DateRange}
              statText={`Study Date: ${job.StudyDate || ""} Accession #: ${job.AccessionNumber || ""}`}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={4} lg={4}>
            <StatsCard
              icon={job.mode === "CLOUD" ? CloudIcon : DeviceIcon}
              iconColor="blue"
              title="Application"
              description={job.appName}
              statIcon={SettingsEthernet}
              statText={`AE Title - ${job.appAETitle}`}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={8} lg={8}>
            <StatsCard
              icon={Vibration}
              iconColor={job.jobStatus === "Failed" ? "red" : "green"}
              title="Case Status"
              description={(
                <div>
                  <CustomDropdownSmall
                    buttonText="More Options"
                    dropdownList={this.defineDropdownActions()}
                    onAction={(action) => this.handleCaseAction(action)}
                  />

                  {job.jobStatus}</div>)}
              statIcon={InfoOutline}
              statText={job.eventId}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={4} lg={4}>
            <StatsCard
              icon={Group}
              iconColor="blue"
              title="Consumer"
              description={job.consumerAE}
              statIcon={SettingsEthernet}
              statText={job.consumerIP}
            />
          </ItemGrid>
        </GridContainer>
      </React.Fragment>
    );
  }
}

GeneralCase.propTypes = {
  patient: pt.shape({
    firstName: pt.string,
    lastName: pt.string,
    dob: pt.string,
    sex: pt.string,
  }).isRequired,
  user: pt.instanceOf(User),
  result: pt.shape({
    orgId: pt.string.isRequired,
    jobStatus: pt.oneOf(["Finished Processing", "Running", "Failed"]).isRequired,
    received: pt.string.isRequired,
    eventId: pt.string.isRequired,
  }).isRequired,
  classes: pt.shape({
    success: pt.string.isRequired,
    button: pt.string.isRequired,
    danger: pt.string.isRequired,
  }).isRequired,
  history: pt.shape({
    push: pt.func.isRequired,
  }).isRequired,
  signalError: pt.func.isRequired,
  clearError: pt.func.isRequired,
  getWebPreviewToken: pt.func.isRequired,
  updateWebPreviewToken: pt.func.isRequired,
  webPreviewToken: pt.shape({
    expired: pt.bool,
    AccessKeyId: pt.string,
    SecretAccessKey: pt.string,
    SessionToken: pt.string,
  }),
  accessControl: pt.shape({
    hidePHI: pt.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, props) => ({
  webPreviewToken: state.user.webPreviewToken,
  user: userSelector(state),
  accessControl: accessControlSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  getWebPreviewToken: getWebPreviewToken,
  updateWebPreviewToken: (token) => {
    dispatch(webPreviewTokenUpdate(token));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    WithNotifications(
      withStyles(style)(GeneralCase)
    )
  )
);
