// ##############################
// // // IconCard styles
// #############################

import {
	primaryColor,
	warningColor,
	dangerColor,
	successColor,
	infoColor,
	roseColor,
	grayColor
} from "assets/jss/material-dashboard-pro-react.jsx"

const chartsStyle = {
	legendTitle: {
		color: grayColor,
		margin: "10px 0 !important"
	},
	primary: {
		color: primaryColor
	},
	warning: {
		color: warningColor
	},
	danger: {
		color: dangerColor
	},
	success: {
		color: successColor
	},
	info: {
		color: infoColor
	},
	rose: {
		color: roseColor
	},
	gray: {
		color: grayColor
	},
	chartCenter: {
		display: "flex",
		justifyContent: "center"
	}
}

export default chartsStyle
