import React from "react";
import pt from "prop-types";
import moment from "moment";
import { orgStatsQuery } from "libs/apiLib";
import Reports from "models/Reports";
import { WithNotifications } from "components/HigherOrder";

export const WithOrgReport = (WrappedComponent, { getReport }) => {
  class WrapperComponent extends React.Component {
    static propTypes = {
      signalError: pt.func.isRequired,
      clearError: pt.func.isRequired,
      start: pt.instanceOf(moment).isRequired,
      end: pt.instanceOf(moment).isRequired,
      orgId: pt.string,
    }

    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        report: {},
        error: false,
      };
    }

    componentDidMount() {
      if (this.shouldGetReport()) {
        this.getReport();
      }
    }

    componentDidUpdate() {
      if (this.shouldGetReport()) {
        this.getReport();
      }
    }

    shouldGetReport = () => {
      if (!this.props.start || !this.props.end) {
        return false;
      }
      const { report, loading } = this.state;
      const { start, end, orgId } = this.props;
      const key = this.key(start, end);
      if (!orgId && !loading && !report[key]) {
        return true;
      }

      if (orgId && !loading && (!report[orgId] || !report[orgId][key])) {
        return true;
      }
      return false;
    }

    key = (start, end) => `${start.format("YYYY-MM-DD")}-${end.format("YYYY-MM-DD")}`

    getReport = async () => {
      const { start, end, orgId, signalError, clearError } = this.props;
      if (!start || !end) {
        return;
      }
      this.setState({ loading: true });
      const key = this.key(start, end);
      let results = [];
      try {
        const report = await getReport(start.format("YYYYMMDD"), end.format("YYYYMMDD"), orgId);
        if (report.success && report.stats && report.stats.Items) {
          results = report.stats.Items;
        }
      } catch (e) {
        signalError("Error getting stat report for this org");
        setTimeout(clearError, 5000);
        this.setState({ error: true });
      }
      this.updateReport(key, orgId, results);
      this.setState({ loading: false });
    }

    updateReport = (key, orgId, results) => {
      if (orgId) {
        this.setState(prevState => ({
          ...prevState,
          report: {
            ...prevState.report,
            [orgId]: {
              ...prevState.report[orgId],
              [key]: results,
            },
          }
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          report: {
            ...prevState.report,
            [key]: results,
          }
        }));
      }
    }

    render() {
      const { error, loading } = this.state;
      const { start, end, orgId } = this.props;
      let report = null;
      if (start && end) {
        const key = this.key(start, end);
        if (orgId) {
          report = this.state.report[orgId] && this.state.report[orgId][key] ? new Reports(start.format("YYYYMMDD"), end.format("YYYYMMDD"), this.state.report[orgId][key]) : null;
        } else {
          report = this.state.report[key] ? new Reports(start.format("YYYYMMDD"), end.format("YYYYMMDD"), this.state.report[key]) : null;
        }
      }
      return (
        <WrappedComponent
          {...this.props}
          error={error}
          loading={loading}
          report={report}
        />
      );
    }
  }

  return WrapperComponent;
};


export default (Component) => WithNotifications(WithOrgReport(Component, { getReport: orgStatsQuery }));