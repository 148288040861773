import React from "react";
import pt from "prop-types";
import { connect } from "react-redux";
import AceEditor from "react-ace";
import "brace/mode/json";
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "material-ui/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { isValidJSON } from "libs/utils";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import switchStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import Switch from "material-ui/Switch";
import FormControlLabel from "material-ui/Form/FormControlLabel";

import {
  getWebPreviewToken,
} from "libs/apiLib";
import { downloadFileFromS3, headObject } from "libs/awsLib";
import { webPreviewTokenUpdate } from "actions";

const editorStyle = {
  editor: {
    height: "200px",
    padding: "30px 30px 30px 10px",
    margin: "0",
    borderRadius: "6px",
    backgroundColor: "#F5F7F9",
    "& .ace-xcode": {
      backgroundColor: "#F5F7F9",
      color: "#5d5d5d",
    },
    "& .ace-xcode .ace_gutter": {
      backgroundColor: "#F5F7F9",
      color: "#c1c7cd",
    },
    "& .ace-xcode .ace_cursor": {
      color: "#5d5d5d",
    },
    "& .ace-xcode .ace_gutter-active-line": {
      backgroundColor: "inherit"
    }
  },
  "@media (min-height: 450px)": {
    editor: {
      height: "200px"
    }
  },
  "@media (min-height: 500px)": {
    editor: {
      height: "230px",
    }
  },
  "@media (min-height: 600px)": {
    editor: {
      height: "240px",
    }
  },
  "@media (min-height: 650px)": {
    editor: {
      height: "285px",
    }
  },
  "@media (min-height: 700px)": {
    editor: {
      height: "290px",
    }
  },
  "@media (min-height: 750px)": {
    editor: {
      height: "300px",
    }
  },
  "@media (min-height: 800px)": {
    editor: {
      height: "320px",
    }
  },
  "@media (min-height: 850px)": {
    editor: {
      height: "365px",
    }
  },
  "@media (min-height: 900px)": {
    editor: {
      height: "410px",
    }
  },
  "@media (min-height: 950px)": {
    editor: {
      height: "455px",
    }
  },
  "@media (min-height: 1000px)": {
    editor: {
      height: "500px",
    }
  }
};

const styles = {
  ...sweetAlertStyle,
  ...editorStyle,
  ...switchStyles
};

class ConfigManager extends React.Component {
  static propTypes = {
    webPreviewToken: pt.shape({
      AccessKeyId: pt.string
    }),
    device: pt.shape({
      orgId: pt.string.isRequired,
      deviceId: pt.string.isRequired,
    }).isRequired,
    updateWebPreviewToken: pt.func.isRequired,
    headObject: pt.func.isRequired,
    downloadFileFromS3: pt.func.isRequired,
    getWebPreviewToken: pt.func.isRequired,
    app: pt.shape({
      version: pt.string.isRequired,
      appId: pt.string.isRequired,
      appConfigs: pt.arrayOf(pt.shape({
        file: pt.string.isRequired,
        config: pt.shape({}).isRequired
      })).isRequired
    }).isRequired,
    onClose: pt.func.isRequired,
    onUpdate: pt.func.isRequired,
    onSave: pt.func.isRequired,
    classes: pt.shape({
    }).isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      config: "",
      index: null,
      disableSave: true,
      previousConfig: {
        fetched: false,
        exists: false,
        config: null,
        version: null,
        show: false,
      }
    };
    this.previousAppConfigPath = `orgs/${props.device.orgId}/devices/${props.device.deviceId}/apps/${props.app.appId}/config/previous.json`;
    this.handleClose = this.handleClose.bind(this);
    this.handleConfigClick = this.handleConfigClick.bind(this);
    this.editorChangeHandler = this.editorChangeHandler.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  async componentDidMount() {
    const { app } = this.props;
    if (app.appConfigs && app.appConfigs.length && app.appConfigs.length === 1) {
      // only 'config.json' may exist, update config state to show this by default
      const configJSON = app.appConfigs.find(config => config.file === "config.json");
      if (!configJSON) {
        return;
      }
      this.setState({ config: JSON.stringify(configJSON.config, undefined, "\t"), index: 0 });
    }
    await this.updateWebPreviewToken();
  }

  async updateWebPreviewToken() {
    try {
      const results = await this.props.getWebPreviewToken(this.props.device.orgId);
      if (results && results.success && results.token) {
        await this.props.updateWebPreviewToken(results.token);
      }
    } catch (error) {
      //  
    }
  }

  async componentDidUpdate() {
    if (!this.state.previousConfig.fetched && this.props.webPreviewToken.AccessKeyId) {
      await this.getPreviousAppConfig(this.props.webPreviewToken);
    }
  }

  async getPreviousAppConfig(token) {
    try {
      const resp = await this.props.downloadFileFromS3(this.previousAppConfigPath, token);
      if (!resp || !resp.Body) {
        throw new Error("Previously uninstall app config may not exist.");
      }
      const { version } = resp.Metadata;
      // parse the JSON first
      const appConfigObj = JSON.parse(resp.Body.toString());
      // stringify with tabs to enable pretty-print in the Ace JSON Editor, otherwise the JSON string will be a single line
      const appConfig = JSON.stringify(appConfigObj, undefined, "\t");
      this.setState(prevState => ({ ...prevState, previousConfig: { ...prevState.previousConfig, exists: true, fetched: true, config: appConfig, version }}));
    } catch (error) {
      // ignore error since the previous app config may not exist and that's okay.
      this.setState(prevState => ({ ...prevState, previousConfig: { ...prevState.previousConfig, exists: false, fetched: true }}));
      return;
    }
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleConfigClick = (config, index) => {
    this.setState({
      config: JSON.stringify(config.config, undefined, "\t"),
      index
    });
  }

  editorChangeHandler = (update) => {
    this.setState({ config: update }, () => {
      if (isValidJSON(update)) {
        let app = this.props.app;
        app.appConfigs[this.state.index].config = JSON.parse(update);
        this.props.onUpdate(app);
        this.setState({ disableSave: false });
      } else {
        this.setState({ disableSave: true });
      }
    });
  }

  handleSave = () => {
    this.props.onSave(this.props.app);
  }

  renderJSONEditor() {
    const { classes } = this.props;
    const { previousConfig, config } = this.state;
    if (previousConfig.exists && previousConfig.config && previousConfig.config.length && previousConfig.show) {
      return (
        <ItemGrid xs={12} container >
          <ItemGrid xs={6} style={{ textAlign: "left" }}>
            <p>{`Previously Installed Configuration ${previousConfig.version ? `(v${previousConfig.version})` : ""}`}</p>
            <div className={classes.editor}>
              <AceEditor
                value={previousConfig.config}
                mode="json"
                theme="xcode"
                name="config"
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={false}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                showLineNumbers
                editorProps={{ $blockScrolling: Infinity }}
                readOnly
              />
            </div>
          </ItemGrid>
          <ItemGrid xs={6} style={{ textAlign: "left" }}>
            <p>{`Current Configuration (v${this.props.app.version})`}</p>
            <div className={classes.editor}>
              <AceEditor
                value={config}
                mode="json"
                theme="xcode"
                name="config"
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={false}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                showLineNumbers
                editorProps={{ $blockScrolling: Infinity }}
                onChange={this.editorChangeHandler}
              />
            </div>
          </ItemGrid>
        </ItemGrid>
      );
    }
    return (
      <ItemGrid xs={12} style={{ textAlign: "left" }}>
        <p>{`Current Configuration (v${this.props.app.version})`}</p>
        <div className={classes.editor}>
          <AceEditor
            mode="json"
            theme="xcode"
            name="config"
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={false}
            value={this.state.config}
            style={{
              width: "100%",
              height: "100%",
            }}
            showLineNumbers
            editorProps={{ $blockScrolling: Infinity }}
            onChange={this.editorChangeHandler}
          />
        </div>
      </ItemGrid>
    );
  }

  handleToggleSwitch = event => {
    const toggle = event.target.checked;
    this.setState(prevState => ({ ...prevState, previousConfig: { ...prevState.previousConfig, show: toggle }}));
  }

  renderPreviouConfigSwitch() {
    const { classes } = this.props;
    const { previousConfig } = this.state;
    if (!previousConfig.exists) {
      return null;
    }
    return (
      <ItemGrid container xs={12} justify="flex-end">
        <FormControlLabel
          control={
            <Switch
              checked={previousConfig.show}
              onChange={this.handleToggleSwitch}
              value="showPrevConfig"
              classes={{
                checked: classes.switchChecked,
                bar: classes.switchBarChecked,
                icon: classes.switchIcon,
                default: classes.switchUnchecked,
                iconChecked: classes.switchIconChecked
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label={
            previousConfig.show
              ? "Show Previously Installed App Config"
              : "Hide Previously Installed App Config"
          }
        />
      </ItemGrid>
    );
  }

  render() {
    const { classes, app } = this.props;
    const aConfigExists = Boolean(app.appConfigs && app.appConfigs.length > 0);
    const multipleConfigsExists = Boolean(app.appConfigs && app.appConfigs.length > 1);
    return (
      app &&
      <SweetAlert
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          margin: "0",
          minWidth: "300px",
          width: "80%",
          height: "80%",
          minHeight: "500px"
        }}
        title=""
        onConfirm={() => this.handleClose()}
        onCancel={() => this.handleClose()}
        showConfirm={false}
        confirmBtnCssClass={
          classes.button + " " + classes.success
        }
        cancelBtnCssClass={
          classes.button + " " + classes.danger
        }
      >
        <GridContainer direction="column">
          <h3 style={{ margin: 0 }}>App Configuration</h3>
          <p>{app.name}</p>
          {this.renderPreviouConfigSwitch()}
          {multipleConfigsExists && (
            <React.Fragment>
              <ItemGrid xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: "10px"}}> 
                {app.appConfigs.map((config, index) => {
                  return (
                    <ItemGrid key={index} xs={12}>
                      <GridContainer style={{ 
                        paddingLeft: "40px",
                        paddingRight: "40px"
                      }}>
                        <ItemGrid xs={6}>
                          <h5 style={{ textAlign: "left" }}>{config.file}</h5>
                        </ItemGrid>
                        <ItemGrid xs={6}>
                          <GridContainer justify="flex-end">
                            <Button style={{ textAlign: "right" }} onClick={() => this.handleConfigClick(config, index)}>
                              Edit
                            </Button>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  );
                })}
              </ItemGrid>
            </React.Fragment>)
          }
          {aConfigExists && this.renderJSONEditor()}
          <ItemGrid xs={12} style={{ marginTop: "40px", position: "absolute", bottom: "15px", alignSelf: "center" }}>
            <Button style={{ marginRight: "10px" }}
              onClick={this.handleClose}
            >Close</Button>
            <Button color="primary"
              style={{ marginRight: "10px" }}
              onClick={this.handleSave}
              disabled={this.state.disableSave}
            >Save</Button>
          </ItemGrid>
        </GridContainer>
      </SweetAlert>
    );
  }
}

const mapStateToProps = (state) => ({
  webPreviewToken: state.user.webPreviewToken,
});

const mapDispatchToProp = (dispatch) => ({
  updateWebPreviewToken: (token) => {
    dispatch(webPreviewTokenUpdate(token));
  },
  headObject,
  downloadFileFromS3,
  getWebPreviewToken
});


export default connect(mapStateToProps, mapDispatchToProp)(withStyles(styles)(ConfigManager));