import React from "react";
import PropTypes from "prop-types";
import Chartist from "react-chartist";
import IconCard from "components/Cards/IconCard.jsx";
import Badge from "components/Badge/Badge.jsx";

import Reports from "models/Reports";

// Styles
import ThumbsUpDown from "material-ui-icons/ThumbsUpDown";
import withStyles from "material-ui/styles/withStyles";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle";

export const DeviceSuccessFailure = ({ classes, report }) => {
  let content = <h3><small>No devices reported for this date range</small></h3>;
  if (report) {
    let labels = [];
    let failures = [];
    let successes = [];
    const devices = report.edgeDeviceUsage();
    const IDs = Object.keys(devices);
    if (IDs.length) {
      IDs.forEach(id => {
        labels.push(devices[id].name);
        successes.push(devices[id].successful);
        failures.push(devices[id].failures);
        content = (
          <React.Fragment>
            <Chartist
              className={classes.chartCenter + " ct-double-octave"}
              type="Bar"
              data={{
                labels: labels,
                series: [
                  failures.map(n => ({ value: n, meta: { style: "stroke: #ef5350; stroke-width: 30px;" } })),
                  successes.map(n => ({ value: n, meta: { style: "stroke: #0fb8ad; stroke-width: 30px;" } })),
                ]
              }}
              options={{
                stackBars: true,
              }}
              listener={{
                draw: (context) => {
                  if (context.type === "bar" && context.meta && context.meta.style) {
                    context.element.attr(context.meta);
                  }
                }
              }}
            />
            <h5>Legend</h5>
            <Badge style={{ backgroundColor: "#ef5350", marginRight: "15px" }}>
              Failure
            </Badge>
            <Badge style={{ backgroundColor: "#0fb8ad" }}>
              Success
            </Badge>
          </React.Fragment>
        );
      });
    } else {
      content = <h3><small>No devices reported for this date range</small></h3>;
    }
  }

  return (
    <IconCard
      iconColor="orange"
      icon={ThumbsUpDown}
      title="Device Success & Failures"
      content={content}
    />
  );
};

DeviceSuccessFailure.propTypes = {
  classes: PropTypes.shape({
    chartCenter: PropTypes.string.isRequired
  }).isRequired,
  report: PropTypes.instanceOf(Reports)
};

export default withStyles(chartsStyle)(DeviceSuccessFailure);