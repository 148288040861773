import React from "react";
import pt from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import Dropdown from "components/CustomDropdown/CustomDropdown";
import ThumbUp from "material-ui-icons/ThumbUp";
import ThumbDown from "material-ui-icons/ThumbDown";
import IconButton from "components/CustomButtons/IconButton";
import MoreHoriz from "material-ui-icons/MoreHoriz";
import Close from "material-ui-icons/Close";
import Tooltip from "components/Tooltip";

const SuccessFailureToolbar = ({
  hide, active, toggleActive, dropdownData, dropdownOnChange, dropdownButtonText, successActive, successOnClick, failureActive, failureOnClick
}) => {
  if (hide) {
    return null;
  }
  return (
    <GridContainer justify="flex-end">
      <ItemGrid xs={12}>
        {
          active &&
          <React.Fragment>
            <Tooltip text="Close">
              <IconButton
                style={{ margin: 5, float: "right" }}
                color="defaultNoBackground"
                onClick={() => toggleActive(false)}
              >
                <Close />
              </IconButton>
            </Tooltip>
            <Tooltip text="Select App">
              <Dropdown
                style={{
                  float: "right"
                }}
                buttonColor="primary"
                buttonText={dropdownButtonText}
                dropdownList={dropdownData}
                onAction={dropdownOnChange}
              />
            </Tooltip>
            <Tooltip text="Toggle Failures">
              <IconButton
                style={{ margin: 8, float: "right" }}
                color={failureActive ? "primaryNoBackground" : "defaultNoBackground"}
                onClick={failureOnClick}
              >
                <ThumbDown />
              </IconButton>
            </Tooltip>
            <Tooltip text="Toggle Successes">
              <IconButton
                style={{ margin: 8, float: "right" }}
                color={successActive ? "primaryNoBackground" : "defaultNoBackground"}
                onClick={successOnClick}
              >
                <ThumbUp />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        }
        {
          !active &&
          <Tooltip text="Configure Chart">
            <IconButton
              style={{ margin: 8, float: "right" }}
              color="defaultNoBackground"
              onClick={() => toggleActive(true)}
            >
              <MoreHoriz />
            </IconButton>
          </Tooltip>
        }
      </ItemGrid>
    </GridContainer>
  );
};

SuccessFailureToolbar.propTypes = {
  dropdownData: pt.arrayOf(pt.string).isRequired,
  dropdownButtonText: pt.string.isRequired,
  dropdownOnChange: pt.func.isRequired,
  active: pt.bool.isRequired,
  successActive: pt.bool.isRequired,
  failureActive: pt.bool.isRequired,
  successOnClick: pt.func.isRequired,
  failureOnClick: pt.func.isRequired,
  toggleActive: pt.func.isRequired,
  hide: pt.bool,
};

export default SuccessFailureToolbar;