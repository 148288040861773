import React from "react";
import pt from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { getPatientSampleKits, getKitByBarcode, linkPatient, unlinkPatient } from "libs/apiLib";

import Button from "components/CustomButtons/Button";
import Input from "components/CustomInput/CustomInput";
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";
import HeaderCard from "components/Cards/HeaderCard";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Badge from "components/Badge/Badge";
import Pagination from "components/Pagination/ReactTablePagination";
import Modal from "components/Modal";
import { CircularProgress } from "material-ui/Progress";
import CancelIcon from "material-ui-icons/Cancel";

import withStyles from "material-ui/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";

export class SampleKits extends React.Component {
  static propTypes = {
    classes: pt.shape({
      wideHorizontalPadding: pt.string.isRequired,
    }).isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    getPatientSampleKits: pt.func.isRequired,
    getKitByBarcode: pt.func.isRequired,
    linkPatient: pt.func.isRequired,
    unlinkPatient: pt.func.isRequired,
    id: pt.string.isRequired,
    patient: pt.shape({
      dob: pt.string,
      sex: pt.string,
      height: pt.number,
      weight: pt.number,
    }).isRequired,
    orgId: pt.string,
    signalInfo: pt.func.isRequired,
    clearInfo: pt.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sampleKits: [],
      orderedKey: null,
      page: 0,
      allPagesFulfilled: false,
      showModal: null,
      barcode: "",
    };
  }

  componentDidMount() {
    this.getPatientSampleKits();
  }

  getPatientSampleKits = async () => {
    const { id, orgId, getPatientSampleKits, signalError, clearError } = this.props;
    const { orderedKey } = this.state;
    this.setState({ loading: true });
    try {
      const response = await getPatientSampleKits(id, orgId, orderedKey);
      if (response.success && response.sampleKits.Items) {
        if (response.sampleKits.LastEvaluatedKey) {
          this.updatePaginationKey(response.sampleKits.LastEvaluatedKey);
        } else {
          this.setState({ allPagesFulfilled: true });
        }

        if (response.sampleKits.Items.length && this.state.sampleKits && this.state.page + 1 === Math.floor(this.state.sampleKits.length / 10)) {
          this.setState(prevState => ({ ...prevState, page: prevState.page + 1 }));
        }

        this.setState(prevState => {
          const sampleKits = (prevState.sampleKits ? [...prevState.sampleKits, ...response.sampleKits.Items] : response.sampleKits.Items).sort((a, b) => new Date(b.assignedOn) - new Date(a.assignedOn));
          return {
            ...prevState,
            sampleKits
          };
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError(`Error getting genetic kits for patient "${id}". Please try again later.`);
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  updatePaginationKey = ({ ordered }) => {
    if (!ordered) {
      return;
    }
    this.setState({
      orderedKey: ordered
    });
  }

  kitStatusColor = (status) => {
    switch (status) {
    case "PROCESSED":
      return "success";
    case "UNUSABLE":
      return "danger";
    case "USED":
      return "primary";
    default:
      return "primary";
    }
  }

  formatCell = (key) => (cell) => {
    switch (key) {
    case "barcode":
      return <Cell label="Barcode" value={cell.value || "None"} />;
    case "trackingNumber":
    {
      const value = !cell.value ? "None" : <a target="_blank" rel="noreferrer noopener" href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${cell.original.trackingNumber}`}>{cell.original.trackingNumber}</a>;
      return <Cell label="Tracking Number" value={value} />;
    }
    case "assignedOn":
      return <Cell label="Assigned" value={moment(cell.value).format("MMMM Do, YYYY")} />;
    case "processedOn":
      return <Cell label="Processed" value={cell.value ? moment(cell.value).format("MMMM Do, YYYY") : "Unavailable"} />;
    case "status":
      return <Cell style={{ marginTop: 0 }} label="Status" value={<Badge color={this.kitStatusColor(cell.value)}>{cell.value}</Badge>} />;
    case "releaseIcon":
      if (cell.original.status !== "USED") {
        return null;
      }
      return (
        <ItemGrid container xs={12} justify="flex-end">
          <a
            style={{
              background: "#FFFFFF",
              color: "#F04400",
              cursor: "pointer"
            }}
            onClick={() => this.handleModalStateChange({ type: "releaseKit", state: cell.original.sampleId })}
          >
            <CancelIcon />
          </a>
        </ItemGrid>
      );
    default:
      return cell.value;
    }
  }

  handleModalStateChange = (change) => {
    this.setState({ showModal: change });
  }

  renderModal = () => {
    if (!this.state.showModal) {
      return null;
    }
    switch (this.state.showModal.type) {
    case "assignKit":
      return this.renderSampleKitAssignModal();
    case "releaseKit":
      return this.renderSampleKitReleaseModal();
    default:
      return null;
    }
  }

  handleAssignSubmit = async () => {
    const { id, orgId, getKitByBarcode, linkPatient, signalError, clearError } = this.props;
    const { barcode } = this.state;
    if (!barcode.length) {
      return;
    }
    this.setState({ loading: true });
    try {
      const sampleKitResponse = await getKitByBarcode(barcode, orgId);
      if (!sampleKitResponse.sampleKit) {
        throw new Error("No genetic kit exist with this barcode. Confirm the barcode you entered is correct and try again.");
      }
      await linkPatient(sampleKitResponse.sampleKit.sampleId, id, orgId);
      window.location.reload();
    } catch (e) {
      signalError(e.message || "Error assigning kit to patient. Please try again later.");
      setTimeout(clearError, 5000);
      this.setState({ showModal: null, loading: false });
    }
  }

  handleReleaseSubmit = async () => {
    const { orgId, unlinkPatient, signalError, clearError } = this.props;
    const { showModal: { state: sampleId } } = this.state;
    this.setState({ loading: true });
    try {
      await unlinkPatient(sampleId, orgId);
      window.location.reload();
    } catch (e) {
      signalError(e.message || "Error releasing kit from patient. Please try again later.");
      setTimeout(clearError, 5000);
      this.setState({ showModal: null, loading: false });
    }
  }

  renderSampleKitAssignModal = () => {
    const { loading } = this.state;
    let content = (
      <ItemGrid container xs={12} justify="center" alignItems="center">
        <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />;
      </ItemGrid>
    );
    if (!loading) {
      content = (
        <React.Fragment>
          <ItemGrid xs={12}>
            <h4 style={{ marginBottom: 0, textAlign: "center" }}>Enter Barcode</h4>
            <Input
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.state.barcode,
                onChange: (e) => this.setState({ barcode: e.target.value }),
              }}
            />
          </ItemGrid>
        </React.Fragment>
      );
    }
    return (
      <Modal
        style={{
          height: 600,
          width: 600,
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer style={{ height: "100%" }}>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}>
            <h2>Assign Genetic Kit to Patient</h2>
          </ItemGrid>
          {content}
          <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
            <Button style={{ marginRight: 15 }} onClick={() => this.setState({ showModal: null })} disabled={loading}>Cancel</Button>
            <Button color="success" onClick={this.handleAssignSubmit} disabled={loading}>Assign</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }

  renderSampleKitReleaseModal = () => {
    const { loading } = this.state;
    let content = null;
    if (loading) {
      content = (
        <ItemGrid container xs={12} justify="center" alignItems="center">
          <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />;
        </ItemGrid>
      );
    }
    return (
      <Modal
        style={{
          height: 400,
          width: 500,
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer style={{ height: "100%" }}>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}>
            <h2>Remove Genetic Kit Associated with this Patient?</h2>
            <p>Confirm the genetic kit is not opened, used, or shipped.</p>
          </ItemGrid>
          {content}
          <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
            <Button style={{ marginRight: 15 }} onClick={() => this.setState({ showModal: null })} disabled={loading}>Cancel</Button>
            <Button color="success" onClick={this.handleReleaseSubmit} disabled={loading}>Remove</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }


  canAssignKit = () => {
    const { patient } = this.props;
    return !!patient.dob && !!patient.sex && !!patient.weight && !!patient.height;
  }

  signalAssignInfoNotification = () => {
    this.props.signalInfo("Patient must have a date of birth, sex, weight, and height to be assigned a genetic kit.");
    setTimeout(this.props.clearInfo, 5000);
  }

  fullRefresh = () => {
    this.setState({
      sampleKits: [],
      orderedKey: null,
      page: 0,
      allPagesFulfilled: false
    }, () => {
      this.getPatientSampleKits();
    });
  }

  render() {
    const { page, loading, allPagesFulfilled } = this.state;
    return (
      <HeaderCard
        cardTitle="Genetic Kits"
        cardSubtitle="All the Genetic Kits assigned to this patient"
        headerColor="rose"
        content={
          <GridContainer className={this.props.classes.wideHorizontalPadding}>
            {this.renderModal()}
            <ItemGrid xs={12}>
              <Button right onClick={this.fullRefresh}>Refresh</Button>
              <Button color="primary" right onClick={this.canAssignKit() ? () => this.handleModalStateChange({ type: "assignKit", state: null }) : this.signalAssignInfoNotification}>Assign</Button>
            </ItemGrid>
            <ItemGrid xs={12}>
              <ReactTable
                sortable={false}
                data={this.state.sampleKits}
                noDataText="No genetic kits"
                defaultPageSize={5}
                columns={[
                  { accessor: "assignedOn" },
                  { accessor: "processedOn" },
                  { accessor: "status" },
                  { accessor: "barcode" },
                  { accessor: "trackingNumber" },
                  { accessor: "releaseIcon" }
                ].map(c => ({ Header: "", Cell: this.formatCell(c.accessor), accessor: c.accessor }))
                }
                PaginationComponent={Pagination}
                minRows={5}
                canGetMorePages
                onGetMore={this.getPatientSampleKits}
                onPageChange={page => this.setState({ page })}
                refreshing={loading}
                page={page}
                allPagesFulfilled={allPagesFulfilled}
              />
            </ItemGrid>
          </GridContainer>
        }
      />
    );
  }
}

export default connect(undefined, () => ({
  getPatientSampleKits: (id, orgId, orderedKey) => getPatientSampleKits(id, orgId, orderedKey),
  getKitByBarcode: (barcode, orgId) => getKitByBarcode(barcode, orgId),
  linkPatient: (sampleId, patientId, orgId) => linkPatient(sampleId, patientId, orgId),
  unlinkPatient: (sampleId, orgId) => unlinkPatient(sampleId, orgId)
}))(withStyles(styles)(SampleKits));