import moment from "moment";
import Report from "models/Report";

/*
 * Reports is a class that represents app stats for one or more days
 * A report should be provided to the Reports constructor as an array objects with the keys specified by the Report class in "Report.js" 
*/
export default class Reports {
  constructor(start, end, data) {
    this.start = moment(start, "YYYYMMDD");
    this.end = moment(end, "YYYYMMDD");
    this.days = data.length;
    this.data = data.map(d => new Report(d));
    this.successful = this.data.reduce((total, report) => total + report.successful, 0);
    this.failures = this.data.reduce((total, report) => total + report.failures, 0);
  }

  calculateSuccessRate = (a, b) => Math.round((a / b) * 100);

  totalCases = () => {
    return this.data.reduce((total, report) => total + report.totalCases(), 0);
  }

  successRate = () => {
    let total = this.totalCases();
    if (this.days < 1 || total < 1) {
      return 0;
    }
    const successful = this.data.reduce((total, report) => total + report.successful, 0);
    return this.calculateSuccessRate(successful, total);
  }

  // key = "appName" | "appId"
  appStats = (key = "appName") => {
    const stats = this.data.reduce((result, report) => {
      const apps = report.appStats(key);
      let memo = { ...result };
      if (!apps) {
        return memo;
      }
      for (const appKey in apps) {
        if (!apps.hasOwnProperty(appKey)) {
          continue;
        }
        if (memo[appKey]) {
          const app = apps[appKey];
          memo = {
            ...memo,
            [appKey]: {
              ...memo[appKey],
              successful: memo[appKey].successful + app.successful,
              failures: memo[appKey].failures + app.failures,
            }
          };
        } else {
          memo = {
            ...memo,
            [appKey]: { ...apps[appKey] }
          };
        }
      }
      return memo;
    }, {});
    return Object.keys(stats).length > 0 ? stats : null;
  }

  appSuccessRates = (exclude) => {
    const appStats = this.appStats("appId");
    if (!appStats) {
      return {};
    }
    return Object.keys(appStats).reduce((results, id) => {
      const total = appStats[id].failures + appStats[id].successful;
      let successful = appStats[id].successful;
      if (exclude && exclude[id]) {
        successful += exclude[id];
      }
      return {
        ...results,
        [id]: this.calculateSuccessRate(successful, total),
      };
    }, {});
  }

  topAppBy = (metric = "usage") => {
    let result = null;
    let topDataPoint = 0;
    let cache = {};

    const calculateDataPoint = (app) => {
      if (!app) {
        return 0;
      }
      switch (metric) {
      case "usage":
        return app.successful + app.failures;
      case "successful":
      case "failures":
        return app[metric];
      default:
        return 0;
      }
    };

    this.data.forEach(report => {
      const currentTop = report.topAppBy(metric);
      if (!currentTop) {
        return;
      }
      const dataPoint = calculateDataPoint(currentTop) + calculateDataPoint(cache[currentTop.appName]);

      if (!cache[currentTop.appName]) {
        cache = {
          ...cache,
          [currentTop.appName]: currentTop,
        };
      } else {
        cache = {
          ...cache,
          [currentTop.appName]: {
            ...currentTop,
            successful: cache[currentTop.appName].successful + currentTop.successful,
            failures: cache[currentTop.appName].failures + currentTop.failures,
          },
        };
      }

      if (dataPoint > topDataPoint) {
        result = {
          ...currentTop,
          successful: cache[currentTop.appName].successful,
          failures: cache[currentTop.appName].failures,
        };
        topDataPoint = dataPoint;
      }
    });

    return result;
  }

  appsStatsBy = (metric) => {
    const appStats = this.appStats("appId");
    if (!appStats) {
      return [];
    }
    if (!Object.keys(appStats).length) {
      return [];
    }
    return Object.values(appStats).map(result => result[metric]);
  }

  appSuccesses = () => {
    return this.appsStatsBy("successful");
  }

  appFailures = () => {
    return this.appsStatsBy("failures");
  }

  appNames = () => {
    return this.appsStatsBy("appName");
  }

  appIDs = () => {
    return this.appsStatsBy("appId");
  }

  filterAppsBy = (cb) => {
    return this.data.reduce((results, report) => {
      return [
        ...results,
        ...report.filterAppsBy(cb),
      ];
    }, []);
  }

  mergedReports = () => {
    const topAppName = (r) => {
      const app = r.topAppBy("usage");
      return app ? app.appName : "N/A";
    };
    /**
     * [orgId]: [...reports]
     */
    const reports = this.data.reduce((cache, r) => {
      const thisReport = {
        orgID: r.orgID,
        orgName: r.orgName,
        successful: r.successful,
        failures: r.failures,
        successRate: r.successRate(),
        scannerAppUsage: r.scannerAppUsage(),
        failureCodes: r.exitCodesBy("exitCode"),
        mostEngagingApp: topAppName(r),
        uniqueJobsByApp: r.appUsageByStudyInstanceUID()
      };
      if (!cache[thisReport.orgID]) {
        return {
          ...cache,
          [thisReport.orgID]: [thisReport],
        };
      } else {
        return {
          ...cache,
          [thisReport.orgID]: [
            ...cache[thisReport.orgID],
            thisReport,
          ]
        };
      }
    }, {});
    /**
     * [...reports]
     */
    const results = Object.keys(reports).map(orgID => {
      const report = reports[orgID];
      let mostEngagingApp = "N/A";
      let top = 0;
      let cache = {};
      report.forEach(r => {
        if (cache[r.mostEngagingApp]) {
          cache[r.mostEngagingApp]++;
        } else {
          cache[r.mostEngagingApp] = 1;
        }
        if (cache[r.mostEngagingApp] > top) {
          top = cache[r.mostEngagingApp];
          mostEngagingApp = r.mostEngagingApp;
        }
      });
      const org = report.find(r => r.orgName !== undefined);
      const orgName = org ? org.orgName : "N/A";
      const successful = report.reduce((sum, n) => sum + n.successful, 0);
      const failures = report.reduce((sum, n) => sum + n.failures, 0);
      const successRate = Math.round((successful / (successful + failures)) * 100);
      const scanners = report.reduce((obj, n) => {
        for (const appId in n.scannerAppUsage) {
          for (const scannerId in n.scannerAppUsage[appId]) {
            obj[scannerId] = true;
          }
        }
        return obj;
      }, {});
      const uniqueCases = report.reduce((obj, n) => {
        for (const appId in n.uniqueJobsByApp) {
          for (const studyUID in n.uniqueJobsByApp[appId]) {
            obj[studyUID] = true;
          }
        }
        return obj;
      }, {});
      const failureCodes = report.reduce((obj, n) => {
        for (let exitCode in n.failureCodes) {
          if (!obj[exitCode]) {
            obj[exitCode] = n.failureCodes[exitCode].total;
            continue;
          }
          obj[exitCode] = obj[exitCode] + n.failureCodes[exitCode].total;
        }
        return obj;
      }, {});
      let mostFrequentFailureCodes = [];
      for (let exitCode in failureCodes) {
        mostFrequentFailureCodes.push({
          exitCode,
          total: failureCodes[exitCode]
        });
      }
      mostFrequentFailureCodes.sort((a, b) => this.descending(a.total, b.total));
      return {
        orgID,
        orgName,
        successful,
        failures,
        successRate,
        totalScanners: Object.keys(scanners).length,
        mostFrequentFailureCodes: mostFrequentFailureCodes.slice(0, 3),
        totalUniqueCases: Object.keys(uniqueCases).length,
        mostEngagingApp,
      };
    });
    return results;
  }

  usageByDimension = (cb) => {
    return this.data.reduce((results, report) => {
      const usageData = cb(report);
      if (!Object.keys(usageData).length) {
        return results;
      }
      for (const key in usageData) {
        if (!usageData.hasOwnProperty(key)) {
          continue;
        }
        const { name, failures, successful, usage } = usageData[key];
        if (!results[key]) {
          const data = {
            usage,
            successful,
            failures,
          };
          if (name) {
            data.name = name;
          }
          results[key] = data;
          continue;
        }
        const memo = results[key];
        const updatedUsage = { ...memo.usage };
        for (const appID in usage) {
          if (!usage.hasOwnProperty(appID)) {
            continue;
          }
          if (!updatedUsage[appID]) {
            updatedUsage[appID] = { ...usage[appID] };
          } else {
            updatedUsage[appID] = {
              ...updatedUsage[appID],
              failures: updatedUsage[appID].failures + usage[appID].failures,
              successful: updatedUsage[appID].successful + usage[appID].successful,
            };
          }
        }
        results[key] = {
          ...memo,
          usage: updatedUsage,
          failures: memo.failures + failures,
          successful: memo.successful + successful,
        };
      }
      return results;
    }, {});

  }

  edgeDeviceUsage = () => {
    return this.usageByDimension((report) => report.edgeDeviceUsage());
  }

  topDeviceBy = (metric = "usage") => {
    const devices = this.edgeDeviceUsage();
    let result = null;
    let topDataPoint = 0;
    for (const id in devices) {
      if (!devices.hasOwnProperty(id)) {
        continue;
      }
      let dataPoint = 0;
      switch (metric) {
      case "usage":
        dataPoint = devices[id].successful + devices[id].failures;
        break;
      case "successful":
      case "failures":
        dataPoint = devices[id][metric];
        break;
      default:
        continue;
      }
      if (result && dataPoint > topDataPoint) {
        result = { ...devices[id], deviceId: id };
        topDataPoint = dataPoint;
      } else if (!result && dataPoint > 0) {
        result = { ...devices[id], deviceId: id };
        topDataPoint = dataPoint;
      }
    }
    return result;
  }

  modalityUsageBy = (key = "id") => {
    const isIDKey = key === "id";
    return this.data.reduce((results, report) => {
      const modalities = isIDKey ? report.modalityUsageBy("id") : report.modalityStatsBy(key);
      if (!Object.keys(modalities).length) {
        return results;
      }
      for (const id in modalities) {
        if (!modalities.hasOwnProperty(id)) {
          continue;
        }
        const modality = modalities[id];
        if (!results[id]) {
          results[id] = modality;
          continue;
        }
        const memo = results[id];
        if (isIDKey) {
          memo.addToFailures(modality.failures);
          memo.addToSuccessful(modality.successful);
        } else {
          memo.failures += modality.failures;
          memo.successful += modality.successful;
        }
        results[id] = memo;
      }
      return results;
    }, {});
  }

  modalityStatsBy = (dimension = "id") => {
    return this.modalityUsageBy(dimension);
  }

  exitCodesBy = (dimension = "exitCode") => {
    const dimensionKey = dimension === "exitCode" ? "apps" : "failureCodes";
    return this.data.reduce((results, report) => {
      const exitCodeResults = report.exitCodesBy(dimension);
      if (!Object.keys(exitCodeResults).length) {
        return results;
      }
      for (const key in exitCodeResults) {
        if (!exitCodeResults.hasOwnProperty(key)) {
          continue;
        }
        if (!results[key]) {
          results[key] = exitCodeResults[key];
          continue;
        }
        const prevResults = results[key];
        const currResults = exitCodeResults[key];
        prevResults.total += currResults.total;
        for (const id in currResults[dimensionKey]) {
          if (!currResults[dimensionKey].hasOwnProperty(id)) {
            continue;
          }
          if (!prevResults[dimensionKey][id]) {
            prevResults[dimensionKey] = {
              ...prevResults[dimensionKey],
              [id]: currResults[dimensionKey][id]
            };
            continue;
          }
          prevResults[dimensionKey] = {
            ...prevResults[dimensionKey],
            [id]: dimension === "exitCode" ? {
              ...prevResults[dimensionKey][id],
              total: currResults[dimensionKey][id].total + prevResults[dimensionKey][id].total
            } :
              currResults[dimensionKey][id] + prevResults[dimensionKey][id]
          };
        }
      }
      return results;
    }, {});
  }

  appWithMostFailureCodes = () => {
    const exitCodes = this.exitCodesBy("appId");
    const IDs = Object.keys(exitCodes);
    if (!IDs.length) {
      return null;
    }
    let top = null;
    let topMetric = 0;
    IDs.forEach(id => {
      const exitCode = exitCodes[id];
      if (exitCode.total > topMetric) {
        topMetric = exitCode.total;
        top = { ...exitCode, appId: id };
      }
    });
    if (!top) {
      return null;
    }
    const apps = this.filterAppsBy(a => a.appId === top.appId);
    if (!apps || !apps.length) {
      return null;
    }
    return apps[0];
  }

  scannerAppUsage = () => {
    return this.data.reduce((results, report)  => {
      const monthScannerAppUsage = report.scannerAppUsage();
      for (const appId in monthScannerAppUsage) {
        if (!monthScannerAppUsage.hasOwnProperty(appId)) {
          continue;
        }
        if (!results[appId]) {
          results[appId] = monthScannerAppUsage[appId];
          continue;
        }
        const scannerAppStats = monthScannerAppUsage[appId];
        for (const scannerId in scannerAppStats) {
          if (!scannerAppStats.hasOwnProperty(scannerId)) {
            continue;
          }
          if (!results[appId][scannerId]) {
            results[appId] = {
              ...results[appId],
              [scannerId]: scannerAppStats[scannerId]
            };
          } else {
            results[appId] = {
              ...results[appId],
              [scannerId]: {
                successful: results[appId][scannerId].successful + scannerAppStats[scannerId].successful,
                failures: results[appId][scannerId].failures + scannerAppStats[scannerId].failures
              }
            };
          }
        }
       
      }
      return results;
    }, {});
  }

  appUsageByStudyInstanceUID = () => {
    return this.data.reduce((results, report)  => {
      const monthStudyUsage = report.appUsageByStudyInstanceUID();
      for (const appId in monthStudyUsage) {
        if (!monthStudyUsage.hasOwnProperty(appId)) {
          continue;
        }
        if (!results[appId]) {
          let successful = 0;
          let failures = 0;
          for (const studyInstanceUID in monthStudyUsage[appId]) {
            if (!monthStudyUsage[appId].hasOwnProperty(studyInstanceUID)) {
              continue;
            }
            successful += monthStudyUsage[appId][studyInstanceUID].successful;
            failures += monthStudyUsage[appId][studyInstanceUID].failures;
          }
          results[appId] = {
            successful,
            failures,
            ...monthStudyUsage[appId]
          };
          continue;
        }
        const studyAppStats = monthStudyUsage[appId];
        for (const studyInstanceUID in studyAppStats) {
          if (!studyAppStats.hasOwnProperty(studyInstanceUID)) {
            continue;
          }
          if (!results[appId][studyInstanceUID]) {
            results[appId] = {
              ...results[appId],
              successful: results[appId].successful + studyAppStats[studyInstanceUID].successful,
              failures: results[appId].failures + studyAppStats[studyInstanceUID].failures,
              [studyInstanceUID]: studyAppStats[studyInstanceUID]
            };
          } else {
            results[appId] = {
              ...results[appId],
              successful: results[appId].successful + studyAppStats[studyInstanceUID].successful,
              failures: results[appId].failures + studyAppStats[studyInstanceUID].failures,
              [studyInstanceUID]: {
                successful: results[appId][studyInstanceUID].successful + studyAppStats[studyInstanceUID].successful,
                failures: results[appId][studyInstanceUID].failures + studyAppStats[studyInstanceUID].failures
              }
            };
          }
        }
      }
      return results;
    }, {});
  }

  uniqueJobsByApp = () => {
    const studyAppUsage = this.appUsageByStudyInstanceUID();
    const result = {};
    for (const appId in studyAppUsage) {
      if (!studyAppUsage.hasOwnProperty(appId)) {
        continue;
      }
      let total = 0;
      for (const key in studyAppUsage[appId]) {
        if (!studyAppUsage[appId].hasOwnProperty(key) || ["successful", "failures"].includes(key)) {
          continue;
        }
        total++;
      }
      if (!result[appId]) {
        result[appId] = total;
        continue;
      }
      result[appId] = result[appId] + total;
    }
    return result;
  }

  descending = (a, b) => {
    return a > b ? -1 : 1;
  }

  mostFrequentFailureCodes = (args) => {
    const amount = args && args.amount || 3;
    const exitCodes = this.exitCodesBy("exitCode");
    let arr = [];
    for (const exitCode in exitCodes) {
      arr.push({
        exitCode,
        total: exitCodes[exitCode].total
      });
    }

    return arr.sort((a, b) => a.total > b.total ? -1 : 1).slice(0, amount);
  }
}