import React from "react";
import AceEditor from "react-ace";
import 'brace/mode/yaml';

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core 
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { isValidYAML } from "libs/utils";

// styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const editorStyle = {
  editor: {
		height: "200px",
		padding: "30px 30px 30px 10px",
		margin: "0 30px",
		borderRadius: "6px",
		backgroundColor: "#F5F7F9",
		"& .ace-xcode": {
			backgroundColor: "#F5F7F9",
			color: "#5d5d5d",
		},
		"& .ace-xcode .ace_gutter": {
			backgroundColor: "#F5F7F9",
			color: "#c1c7cd",
		},
		"& .ace-xcode .ace_cursor": {
			color: "#5d5d5d",
		},
		"& .ace-xcode .ace_gutter-active-line": {
			backgroundColor: "inherit"
		}
	},
	"@media (min-height: 450px)": {
		editor: {
			height: "300px"
		}
	},
	"@media (min-height: 500px)": {
		editor: {
			height: "330px",
		}
	},
	"@media (min-height: 600px)": {
		editor: {
			height: "340px",
		}
	},
	"@media (min-height: 650px)": {
		editor: {
			height: "385px",
		}
	},
	"@media (min-height: 700px)": {
		editor: {
			height: "390px",
		}
	},
	"@media (min-height: 750px)": {
		editor: {
			height: "400px",
		}
	},
	"@media (min-height: 800px)": {
		editor: {
			height: "420px",
		}
	},
	"@media (min-height: 850px)": {
		editor: {
			height: "465px",
		}
	},
	"@media (min-height: 900px)": {
		editor: {
			height: "510px",
		}
	},
	"@media (min-height: 950px)": {
		editor: {
			height: "555px",
		}
	},
	"@media (min-height: 1000px)": {
		editor: {
			height: "600px",
		}
	}
}

const styles = {
  ...sweetAlertStyle,
  ...editorStyle,
}

class EdgeConfig extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      config: "",
      disableSave: true
    }
    this.handleClose = this.handleClose.bind(this);
    this.editorChangeHandler = this.editorChangeHandler.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleClose = () => {
    this.props.onClose();
  }
  
  componentDidMount () {
    if (this.props.config) {
      this.setState({
        config: this.props.config
      });
    }
  }

  editorChangeHandler = (update) => {
    this.setState({ config: update }, () => {
      if (isValidYAML(update)) {
        this.setState({ disableSave: false });
      } else {
        this.setState({ disableSave: true });
      }
    });
  }

  handleSave = () => {
    this.props.onSave(this.state.config)
  }

  render() {
    const { classes, config, title } = this.props;

    return (
      config &&
      <SweetAlert
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          margin: "0",
          minWidth: "300px",
          width: "80%",
          height: "80%",
          minHeight: "500px"
        }}
        title=""
        onConfirm={() => this.handleClose()}
        onCancel={() => this.handleClose()}
        showConfirm={false}
        confirmBtnCssClass={
          classes.button + " " + classes.success
        }
        cancelBtnCssClass={
          classes.button + " " + classes.danger
        }
      >
        <GridContainer direction="column">
          <h3 style={{ margin: 0, paddingBottom:"20px" }}>{title}</h3>
          {config ? (
            <React.Fragment>
              <ItemGrid xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
                <ItemGrid>
                  <div className={classes.editor}>
                    <AceEditor
                      mode="yaml"
                      theme="xcode"
                      name="license"
                      fontSize={13}
                      showPrintMargin={true}
                      showGutter={true}
                      highlightActiveLine={false}
                      value={this.state.config}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      showLineNumbers
                      editorProps={{ $blockScrolling: Infinity }}
                      onChange={this.editorChangeHandler}
                    />
                  </div>
                </ItemGrid>
              </ItemGrid>
            </React.Fragment>) : (
              <h4>No Valid Config Found!</h4>
            )}
          <ItemGrid xs={12} style={{ marginTop: "40px", position: "absolute", bottom: "15px", alignSelf: "center" }}>
            <Button style={{ marginRight: "10px" }}
              onClick={this.handleClose}
            >Close</Button>
            <Button color="primary"
              style={{ marginRight: "10px" }}
              onClick={this.handleSave}
              disabled={this.state.disableSave}
            >Save</Button>
          </ItemGrid>
        </GridContainer>
      </SweetAlert>
    )
  }
}

export default withStyles(styles)(EdgeConfig);