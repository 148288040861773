import React from "react";
import pt from "prop-types";
import cx from "classnames";
import withStyles from "material-ui/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";

export const Cell = ({
  value, label, classes, className, style, overflowXScroll
}) => {
  const cellClass = cx({
    [classes.label]: className === undefined,
    [className]: className !== undefined,
  });
  let cellValueClass = cx({
    [classes.xScroll]: overflowXScroll,
  });
  cellValueClass = cellValueClass.length ? cellValueClass : undefined;
  return (
    <div>
      <div className={cellValueClass}>{value}</div>
      <div className={cellClass} style={style}>{label}</div>
    </div>
  );
};

Cell.propTypes = {
  value: pt.oneOfType([pt.string, pt.element]).isRequired,
  label: pt.string.isRequired,
  classes: pt.shape({
    label: pt.string.isRequired
  }).isRequired,
  className: pt.string,
  style: pt.shape({}),
  overflowXScroll: pt.bool,
};

export default withStyles(styles)(Cell);