import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import InputLabel from "material-ui/Input/InputLabel";
import FormControl from "material-ui/Form/FormControl";
import { CircularProgress } from 'material-ui/Progress';

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Danger from "components/Typography/Danger.jsx";

// additional components
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

// utils
import { verifyLength, verifyEmail } from 'libs/utils';
import { updateMyOrg } from 'libs/apiLib';
import { storeOrg } from "actions/index";
import countries from "constants/Countries";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...sweetAlertStyle,
  ...customSelectStyle
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      error: "",
      orgUpdated: false,
      orgName: "",
      orgNameState: "",
      country: "",
      countryState: "",
      streetName: "",
      streetNameState: "",
      streetNum: "",
      streetNumState: "",
      addressLine: "",
      suite: "",
      city: "",
      cityState: "",
      state: "",
      stateState: "",
      postalCode: "",
      postalCodeState: "",
      phone: "",
      phoneState: "",
      billingContact: "",
      billingContactState: "",
      billingEmail: "",
      billingEmailState: ""
    };

    this.updateOrg = this.updateOrg.bind(this);
  }

  change(event, stateName, type, stateNameEqualTo) {
    if (event.target) {
      switch (type) {
        case "email":
          if (verifyEmail(event.target.value)) {
            this.setState({ [stateName + "State"]: "success" });
          } else {
            this.setState({ [stateName + "State"]: "error" });
          }
          break;
        case "length":
          if (verifyLength(event.target.value, stateNameEqualTo)) {
            this.setState({ [stateName + "State"]: "success" });
          } else {
            this.setState({ [stateName + "State"]: "error" });
          }
          break;
        default:
          break;
      }
      this.setState({ [stateName]: event.target.value });
    }
  }

  componentDidUpdate() {
    if (this.props.org && !this.state.orgName) {
      if (this.props.org.orgName) {
        this.setState({
          orgName: this.props.org.orgName,
          orgNameState: "success"
        });
      }
    }
  }

  validateForm() {
    if (
      this.state.orgNameState === "success" &&
      this.state.countryState === "success" &&
      this.state.streetNameState === "success" &&
      this.state.streetNumState === "success" &&
      this.state.cityState === "success" &&
      this.state.stateState === "success" &&
      this.state.postalCodeState === "success" &&
      this.state.phoneState === "success" &&
      this.state.billingContactState === "success" &&
      this.state.billingEmailState === "success"
    ) {
      return true;
    } else {
      if (this.state.orgNameState !== "success") {
        this.setState({ orgNameState: "error" });
      }
      if (this.state.countryState !== "success") {
        this.setState({ countryState: "error" });
      }
      if (this.state.streetNameState !== "success") {
        this.setState({ streetNameState: "error" });
      }
      if (this.state.streetNumState !== "success") {
        this.setState({ streetNumState: "error" });
      }
      if (this.state.cityState !== "success") {
        this.setState({ cityState: "error" });
      }
      if (this.state.stateState !== "success") {
        this.setState({ stateState: "error" });
      }
      if (this.state.postalCodeState !== "success") {
        this.setState({ postalCodeState: "error" });
      }
      if (this.state.phoneState !== "success") {
        this.setState({ phoneState: "error" });
      }
      if (this.state.billingContactState !== "success") {
        this.setState({ billingContactState: "error" });
      }
      if (this.state.billingEmailState !== "success") {
        this.setState({ billingEmailState: "error" });
      }
    }
    return false;
  }

  isValidated() {
    if (this.state.orgUpdated || !this.needToSetupOrg())
      this.props.history.push('/dashboard');
    else if (this.validateForm()) {
      this.updateOrg();
      return false;
    } else {
      return false;
    }
  }

  async updateOrg() {
    this.showLoadingAlert();

    const { updateOrgOnStore } = this.props;

    try {
      // update the org
      const org = await updateMyOrg({
        orgName: this.state.orgName,
        country: this.state.country,
        streetNumber: this.state.streetNum,
        addressLineOne: this.state.streetName,
        addressLineTwo: this.state.addressLine,
        city: this.state.city,
        state: this.state.state,
        suite: this.state.suite,
        postalCode: this.state.postalCode,
        phone: this.state.phone,
        billingContact: this.state.billingContact,
        billingEmail: this.state.billingEmail,
        setupDone: true
      });

      if (!org)
        throw new Error("Failed to get org");
      else if (!org.org)
        throw new Error("Failed to get org");

      updateOrgOnStore(org.org);

      this.setState({
        orgUpdated: true,
        alert: null
      }, () => {
        this.setState({
          alert: (
            <SweetAlert
              success
              closeOnClickOutside={false}
              style={{ display: "block", marginTop: "-100px" }}
              title="Done!"
              onConfirm={() => this.props.history.push('/dashboard')}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              Registration Completed!
            </SweetAlert>
          )
        });
      });

    } catch (e) {
      this.setState({
        alert: null,
        error: e.message
      });
    }
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  showLoadingAlert() {
    this.setState({
      alert: (
        <SweetAlert
          closeOnClickOutside={false}
          style={{ display: "block", marginTop: "-100px" }}
          title="Please wait..."
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
        >
          <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />
        </SweetAlert>
      )
    });
  }

  needToSetupOrg() {
    const { org, user } = this.props;
    if (!org.setupDone && user.admin)
      return true
    else
      return false
  }

  render() {
    const { classes, org, user } = this.props;
    return (
      !this.needToSetupOrg() ? (
        <GridContainer justify="center">
          {this.state.alert}
          <ItemGrid xs={12} sm={10}>
            <h4 className={classes.infoText}>Seems like your organization is already setup. Click <strong>Finish</strong></h4>
          </ItemGrid>
          {this.state.error &&
            <ItemGrid xs={12} sm={10} md={10} lg={10}>
              <div style={{ textAlign: "center" }}>
                <br />
                <Danger>
                  {this.state.error}
                </Danger>
              </div>
            </ItemGrid>
          }
        </GridContainer>) : (
          <GridContainer justify="center">
            {this.state.alert}
            <ItemGrid xs={12} sm={12}>
              <h4 className={classes.infoText}>Tell us about your Organization</h4>
            </ItemGrid>
            <ItemGrid xs={12} sm={10}>
              <CustomInput
                id="orgName"
                success={this.state.orgNameState === "success"}
                error={this.state.orgNameState === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                labelText={
                  <span>
                    Organization <small>(required)</small>
                  </span>
                }
                inputProps={{
                  value: this.state.orgName,
                  disabled: this.state.disabled,
                  onChange: event => this.change(event, "orgName", "length", 2),
                }}
              />
            </ItemGrid>
            <ItemGrid xs={12} sm={3}>
              <CustomInput
                id="streetNum"
                success={this.state.streetNumState === "success"}
                error={this.state.streetNumState === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                labelText={
                  <span>
                    Street No. <small>(required)</small>
                  </span>
                }
                inputProps={{
                  disabled: this.state.disabled,
                  onChange: event => this.change(event, "streetNum", "length", 1),
                }}
              />
            </ItemGrid>
            <ItemGrid xs={12} sm={7}>
              <CustomInput
                id="streetName"
                success={this.state.streetNameState === "success"}
                error={this.state.streetNameState === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                labelText={
                  <span>
                    Street Name <small>(required)</small>
                  </span>
                }
                inputProps={{
                  disabled: this.state.disabled,
                  onChange: event => this.change(event, "streetName", "length", 3),
                }}
              />
            </ItemGrid>
            <ItemGrid xs={12} sm={7}>
              <CustomInput
                id="addressLine"
                formControlProps={{
                  fullWidth: true
                }}
                labelText={
                  <span>
                    Address Line <small>(optional)</small>
                  </span>
                }
                inputProps={{
                  disabled: this.state.disabled,
                  onChange: event => this.change(event, "addressLine"),
                }}
              />
            </ItemGrid>
            <ItemGrid xs={12} sm={3}>
              <CustomInput
                id="suite"
                formControlProps={{
                  fullWidth: true
                }}
                labelText={
                  <span>
                    Suite No. <small>(optional)</small>
                  </span>
                }
                inputProps={{
                  disabled: this.state.disabled,
                  onChange: event => this.change(event, "suite"),
                }}
              />
            </ItemGrid>
            <ItemGrid xs={12} sm={3}>
              <CustomInput
                id="city"
                success={this.state.cityState === "success"}
                error={this.state.cityState === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                labelText={
                  <span>
                    City <small>(required)</small>
                  </span>
                }
                inputProps={{
                  disabled: this.state.disabled,
                  onChange: event => this.change(event, "city", "length", 3),
                }}
              />
            </ItemGrid>
            <ItemGrid xs={12} sm={2}>
              <CustomInput
                id="state"
                success={this.state.stateState === "success"}
                error={this.state.stateState === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                labelText={
                  <span>
                    State <small>Province</small>
                  </span>
                }
                inputProps={{
                  disabled: this.state.disabled,
                  onChange: event => this.change(event, "state", "length", 2),
                }}
              />
            </ItemGrid>
            <ItemGrid xs={12} sm={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                  Choose Country
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={this.state.country}
                  onChange={event => this.change(event, "country", "length", 3)}
                  inputProps={{
                    name: "country",
                    id: "country"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Country
                  </MenuItem>
                  {
                    countries.map((c, i) => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={c}
                          key={i}
                        >
                          {c}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </ItemGrid>
            <ItemGrid xs={12} sm={2}>
              <CustomInput
                id="postalCode"
                success={this.state.postalCodeState === "success"}
                error={this.state.postalCodeState === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                labelText={
                  <span>
                    Postal Code
                  </span>
                }
                inputProps={{
                  disabled: this.state.disabled,
                  onChange: event => this.change(event, "postalCode", "length", 3),
                }}
              />
            </ItemGrid>
            <ItemGrid xs={12} sm={3}>
              <CustomInput
                id="phone"
                success={this.state.phoneState === "success"}
                error={this.state.phoneState === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                labelText={
                  <span>
                    Phone <small>(required)</small>
                  </span>
                }
                inputProps={{
                  disabled: this.state.disabled,
                  onChange: event => this.change(event, "phone", "length", 9),
                }}
              />
            </ItemGrid>
            <ItemGrid xs={12} sm={3}>
              <CustomInput
                id="billingContact"
                success={this.state.billingContactState === "success"}
                error={this.state.billingContactState === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                labelText={
                  <span>
                    Billing Contact <small>(required)</small>
                  </span>
                }
                inputProps={{
                  disabled: this.state.disabled,
                  onChange: event => this.change(event, "billingContact", "length", 4),
                }}
              />
            </ItemGrid>
            <ItemGrid xs={12} sm={4}>
              <CustomInput
                id="billingEmail"
                success={this.state.billingEmailState === "success"}
                error={this.state.billingEmailState === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                labelText={
                  <span>
                    Billing Contact's Email <small>(required)</small>
                  </span>
                }
                inputProps={{
                  disabled: this.state.disabled,
                  onChange: event => this.change(event, "billingEmail", "email"),
                }}
              />
            </ItemGrid>
            <ItemGrid xs={12} sm={12}>
              < br />
            </ItemGrid>
            {this.state.error &&
              <ItemGrid xs={12} sm={10} md={10} lg={10}>
                <div style={{ textAlign: "center" }}>
                  <br />
                  <Danger>
                    {this.state.error}
                  </Danger>
                </div>
              </ItemGrid>
            }
          </GridContainer>)
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuthenticating: state.user.isAuthenticating,
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  org: state.user.org
});

const mapDispatchToProps = dispatch => ({
  updateOrgOnStore: (org) => {
    dispatch(storeOrg(org))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(Step2)));
