import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { WithNotifications } from "components/HigherOrder";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import GeneticKitsBarChart from "views/Organization/components/GeneticKits";
import { storeSampleKitsReport } from "actions/index";
import { sampleKitsStatsQuery } from "libs/apiLib";

export class GeneticKits extends React.Component {
  static propTypes = {
    report: PropTypes.arrayOf(PropTypes.shape({
      orgId: PropTypes.string,
      ORDERED: PropTypes.number,
      SHIPPED: PropTypes.number,
      USED: PropTypes.number,
      RECEIVED: PropTypes.number,
      PROCESSED: PropTypes.number,
      UNUSABLE: PropTypes.number,
      date: PropTypes.string.isRequired
    })),
    user: PropTypes.shape({
      orgId: PropTypes.string.isRequired,
    }).isRequired,
    reports: PropTypes.shape({
      sampleKits: PropTypes.shape({
        global: PropTypes.shape({}).isRequired,
      }).isRequired,
    }).isRequired,
    start: PropTypes.instanceOf(moment),
    end: PropTypes.instanceOf(moment),
  };

  componentDidMount() {
    if (!this.sampleKitReport()) {
      this.fetchSampleKitsReport(this.props.start, this.props.end);
    }
  }

  componentDidUpdate(prevProps) {
    const prevKey = this.key(prevProps.start, prevProps.end);
    const key = this.key(this.props.start, this.props.end);
    const { reports } = this.props;
    const sampleKitReport = reports && reports.sampleKits && reports.sampleKits.global && reports.sampleKits.global[key];
    if (prevKey !== key && !sampleKitReport) {
      this.fetchSampleKitsReport(this.props.start, this.props.end);
    }
  }

  key = (start, end) => {
    if (!start || !end) { return undefined; }
    return `${start.format("YYYY-MM-DD")}:${end.format("YYYY-MM-DD")}`;
  }

  fetchSampleKitsReport = async (start, end) => {
    const { storeSampleKitsReport, fetchSampleKitsReport, signalError, clearError } = this.props;
    const key = this.key(start, end);
    this.setState({ isLoading: true });
    try {
      const report = await fetchSampleKitsReport(start, end);
      if (report.success && report.stats && report.stats.Items.length) {
        storeSampleKitsReport(report.stats.Items, key);
      }
    } catch (e) {
      signalError("Error getting sample kit report. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  sampleKitReport = () => {
    const key = this.key(this.props.start, this.props.end);
    const { reports } = this.props;
    return reports && reports.sampleKits && reports.sampleKits.global && reports.sampleKits.global[key];
  }

  render() {
    const report = this.sampleKitReport();
    return (
      <GridContainer>
        <ItemGrid xs={12}>
          <GeneticKitsBarChart report={report} />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.reports,
});

const mapDispatchToProps = (dispatch) => ({
  // uses dispatch
  storeSampleKitsReport: (report, key) => dispatch(storeSampleKitsReport(report, key)),

  // does not use dispatch
  fetchSampleKitsReport: (start, end) => sampleKitsStatsQuery(start.format("YYYYMMDD"), end.format("YYYYMMDD")),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithNotifications(GeneticKits));