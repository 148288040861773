import React from "react";

// views
import Dashboard from "views/Dashboard/Dashboard.jsx";
import MyApps from "views/MyApps/MyApps.jsx";
import AppStore from "views/AppStore/AppStore.jsx";
import MyDevices from "views/Devices/MyDevices.jsx";
import Users from "views/Organization/Users.jsx";
import OrgSettings from "views/Organization/OrgSettings.jsx";
import Audit from "views/Organization/Audit.jsx";
import Reports from "views/Organization/Reports.jsx";
import Developers from "views/Developers/Developers.jsx";
import Apps from "views/Admin/Apps.jsx";
import Devices from "views/Admin/Devices.jsx";
import OrgsUsers from "views/Admin/OrgsUsers.jsx";
import AdminReports from "views/Admin/Reports.jsx";
import AdminAudit from "views/Admin/Audit.jsx";
import ALM from "views/Admin/AccessLicenseManager.jsx";
import MyProfile from "views/Profile/Profile.jsx";
import Case from "views/Case/Case.jsx";
import Device from "views/Device/Device.jsx";
import Invoices from "views/Organization/Invoices";
import AdminInvoices, { Create as CreateInvoice, Edit as EditInvoice } from "views/Admin/Invoices";
import Patients from "views/Patients/AllPatients";
import Patient from "views/Patients/Patient";
import PatientRecord from "views/Patients/Patient/Record";
import PatientResult from "views/Patients/Patient/Result";
import Kits from "views/Kits";

// material-ui-icons
import DashboardIcon from "material-ui-icons/Dashboard";
import Group from "material-ui-icons/Group";
import Store from "material-ui-icons/Store";
import DevicesOther from "material-ui-icons/DevicesOther";
import Domain from "material-ui-icons/Domain";
import WidgetsIcon from "material-ui-icons/Widgets";
import SupervisorAccount from "material-ui-icons/SupervisorAccount";
import Code from "material-ui-icons/Code";
import Settings from "material-ui-icons/Settings";
import History from "material-ui-icons/History";
import Assessment from "material-ui-icons/Assessment";
import Extension from "material-ui-icons/Extension";
import DevicesIcon from "material-ui-icons/Devices";
import AccountBox from "material-ui-icons/AccountBox";
import CreditCard from "material-ui-icons/CreditCard";
import People from "material-ui-icons/Wc";
import WorkIcon from "material-ui-icons/Work";

/**
 *  IMPORTANT! 
 *  Add All Routes that need to be Authenticated HERE!!!!
 */
var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    redirectUrl: "/pages/login"
  },
  {
    path: "/profile",
    name: "My Profile",
    component: MyProfile,
    redirectUrl: "/pages/login",
    hide: true
  },
  {
    path: "/app/case",
    name: "Case",
    component: Case,
    redirectUrl: "/pages/login",
    hide: true
  },
  {
    path: "/edge/device",
    name: "Device",
    component: Device,
    redirectUrl: "/pages/login",
    hide: true,
    protected: true,
    protectionRule: "AllAdminsAndSupport",
  },
  // {
  //   path: "/myapps",
  //   name: "My Apps",
  //   icon: WidgetsIcon,
  //   component: MyApps,
  //   redirectUrl: "/pages/login",
  // },
  {
    path: "/store",
    name: "App Store",
    icon: Store,
    component: AppStore,
    redirectUrl: "/pages/login",
    hide: true
  },
  {
    path: "/edge/devices",
    name: "My Devices",
    icon: DevicesOther,
    component: MyDevices,
    protected: true,
    protectionRule: "Edge",
    redirectUrl: "/dashboard"
  },
  {
    collapse: true,
    path: "/myorg",
    name: "My Organization",
    state: "openTables",
    icon: Domain,
    protected: true,
    protectionRule: "AllAdminsAndSupport",
    views: [
      {
        path: "/myorg/users",
        name: "Users",
        mini: (<Group />),
        component: Users,
        protected: true,
        protectionRule: "AllAdminsAndSupport",
        redirectUrl: "/dashboard"
      },
      {
        path: "/myorg/settings",
        name: "Settings",
        mini: (<Settings />),
        component: OrgSettings,
        protected: true,
        protectionRule: "AllAdminsAndSupport",
        redirectUrl: "/dashboard"
      },
      {
        path: "/myorg/audit",
        name: "Audit",
        mini: (<History />),
        component: Audit,
        protected: true,
        protectionRule: "AllAdminsAndSupport",
        redirectUrl: "/dashboard"
      },
      {
        path: "/myorg/insights",
        name: "Insights",
        mini: (<Assessment />),
        component: Reports,
        protected: true,
        protectionRule: "AllAdminsAndSupport",
        redirectUrl: "/dashboard"
      },
      {
        path: "/myorg/invoices",
        name: "Invoices",
        mini: (<CreditCard />),
        component: Invoices,
        protected: true,
        protectionRule: "AllAdminsAndSupport",
        redirectUrl: "/dashboard",
      }
    ]
  },
  {
    path: "/developers",
    name: "Developer",
    icon: Code,
    component: Developers,
    protected: true,
    protectionRule: "Developer",
    redirectUrl: "/pages/login"
  },
  {
    exact: true,
    path: "/patients",
    name: "Patients",
    icon: People,
    component: Patients,
    views: [
      {
        path: "/patients/:id",
        name: "Patient",
        component: Patient,
        hide: true,
        views: [
          {
            path: "/patients/:id/records/:record_id",
            name: "Patient Record",
            component: PatientRecord,
            hide: true
          },
          {
            path: "/patients/:id/:appId/:eventId",
            name: "Patient Result",
            component: PatientResult,
            hide: true
          },
        ]
      }
    ]
  },
  {
    path: "/kits",
    name: "Genetic Kits",
    icon: WorkIcon,
    component: Kits,
    protected: true,
    protectionRule: "Genetics",
    redirectUrl: "/dashboard"
  },
  {
    collapse: true,
    path: "/admin",
    name: "Admin",
    state: "openMaps",
    icon: SupervisorAccount,
    protected: true,
    protectionRule: "AllHytx",
    views: [
      {
        path: "/admin/insights",
        name: "Dashboard",
        mini: (<DashboardIcon />),
        component: AdminReports,
        protected: true,
        protectionRule: "AllHytxAnalytics",
        redirectUrl: "/dashboard"
      },
      {
        path: "/admin/apps",
        name: "Apps",
        mini: (<Extension />),
        component: Apps,
        protected: true,
        protectionRule: "AllHytxAppAdmin",
        redirectUrl: "/dashboard"
      },
      {
        path: "/admin/devices",
        name: "Devices",
        mini: (<DevicesIcon />),
        component: Devices,
        protected: true,
        protectionRule: "AllHytxSupport",
        redirectUrl: "/dashboard"
      },
      {
        path: "/admin/orgs",
        exact: true,
        name: "Organizations",
        mini: (<AccountBox />),
        component: OrgsUsers,
        protected: true,
        protectionRule: "AllHytxSupport",
        redirectUrl: "/dashboard",
        views: [
          {
            path: "/admin/orgs/:orgID/alm",
            name: "Access & License Manager",
            component: ALM,
            protected: true,
            protectionRule: "AllHytxSupport",
            redirectUrl: "/dashboard",
            hide: true,
          },
        ]
      },
      {
        path: "/admin/audit",
        name: "Audit",
        mini: (<History />),
        component: AdminAudit,
        protected: true,
        protectionRule: "AllHytxSupport",
        redirectUrl: "/dashboard"
      },
      {
        path: "/admin/invoices",
        name: "Invoices",
        mini: (<CreditCard />),
        component: AdminInvoices,
        protected: true,
        protectionRule: "AllHytxBilling",
        redirectUrl: "/dashboard",
        views: [{
          path: "/admin/invoices/create",
          name: "Create Invoice",
          component: CreateInvoice,
          protected: true,
          protectionRule: "AllHytxBilling",
          redirectUrl: "/dashboard",
          hide: true,
        },
        {
          path: "/admin/invoices/edit",
          name: "Edit Invoice",
          component: EditInvoice,
          protected: true,
          protectionRule: "AllHytxBilling",
          redirectUrl: "/dashboard",
          hide: true,
        }]
      }
    ]
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/dashboard",
    name: "Dashboard"
  },
];
export default dashRoutes;
