/**
 * 
 * HLX Platform Internal API
 * with Core Cloud Services
 * 
 * Always refer to latest platform docs for 
 * latest updates to API
 * 
 * Author: Jorge Guzman
 * 
 * Copyright Multimodal Imaging Services Corp
 *          dba HealthLytix 2018
 * 
 */

// get the aws invoke function
// this function already has the required
// wrappers to talk back to backend with 
// correct aws cognito tokens
import { invokeApig } from "libs/awsLib";

/**
 *  Identity Service API
 */

/**
 * Retrieves the org object for the signed in 
 * user.
 */
export function getMyOrg(orgId) {
  const params = {
    path: "/id/getMyOrgDetails",
    method: "GET",
  };
  if (orgId) {
    params.queryParams = { orgId };
  }
  return invokeApig(params);
}

/**
 * Updates the user acknowledging the current
 * date as the latest date of T&Cs acceptance
 */
export function acceptTermsAndConditions() {
  return invokeApig({
    path: "/id/acceptTerms",
    method: "POST",
    body: {}
  });
}

/**
 * Retrieves all orgs in HLX
 * REQUIRES: Requestor must be SuperAdmin/SupportEngineer.
 */
export function getAllOrgs() {
  return invokeApig({
    path: "/id/getAllOrgs",
    method: "GET",
  });
}

/**
 * Get all users in HLX
 * REQUIRES: Requestor must be SuperAdmin/SupportEngineer.
 */
export function getAllUsers() {
  return invokeApig({
    path: "/id/getAllUsers",
    method: "GET",
  });
}

/**
 * Get all the open invites to the HLX 
 * Platform
 * REQUIRES: Requestor must be SuperAdmin/SupportEngineer.
 */
export function getAllInvites() {
  return invokeApig({
    path: "/id/getAllInvites",
    method: "GET",
  });
}

/**
 * Invite an organization to join the Healthcare & Vendors platform
 * REQUIRES: Requestor must be SuperAdmin/SupportEngineer.
 * @param {string} email email of user to invite. Invited user will need to use this email when signing up
 * @param {string} orgName name of the organization being invited
 */
export function inviteOrg(email, orgName) {
  return invokeApig({
    path: "/id/inviteOrg",
    method: "POST",
    body: {
      userName: email,
      orgName
    }
  });
}

/**
 * Update your organization. Must be admin to do this.
 * REQUIRES: Requestor must be Admin.
 * @param {object} param0 Object with all details of org. Send object with updates to be updated on the cloud db.           
 */
export function updateMyOrg({
  orgId,
  orgName,
  country,
  streetNumber,
  addressLineOne,
  addressLineTwo,
  city,
  state,
  suite,
  postalCode,
  phone,
  billingContact,
  billingEmail,
  setupDone,
  referringPhysician
}) {
  return invokeApig({
    path: "/id/updateMyOrg",
    method: "POST",
    body: {
      orgId,
      orgName,
      country,
      streetNumber,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      suite,
      postalCode,
      phone,
      billingContact,
      billingEmail,
      setupDone,
      referringPhysician
    }
  });
}
/**
 * Update a user profile.
 * @param {object} param0 Object with all details of a user.
 * @returns {object} Object with all details of the updated user.
 */
export function updateMyProfile({
  firstName,
  lastName,
}) {
  return invokeApig({
    path: "/id/updateUser",
    method: "POST",
    body: {
      firstName,
      lastName,
    }
  });
}

/**
 * Get all users from a particular org. If no orgId sent, will fetch users from the logged in user.
 * To retrieve users, must be an Admin for org of logged in user. For other orgs, must be a SupportEngineer or
 * a SuperAdmin
 * 
 * REQUIRES: Requestor must be Admin/SupportEngineer/SuperAdmin
 * @param {string} orgId organization id
 */
export function fetchUsers(orgId) {
  let params = {
    path: "/id/usersFromMyOrg",
    method: "GET"
  };
  if (orgId) { params.queryParams = { orgId }; }

  return invokeApig(params);
}

/**
 * @param  {string} userName - Required
 * @param  {string} role - Required
 * @param  {boolean} enabled
 */
export function updateUserRole(userName, role, enabled) {
  return invokeApig({
    path: "/id/user/roles",
    method: "POST",
    body: {
      userName,
      role,
      enabled,
    }
  });
}

/**
 * Delete a user. This will remove the user completely from the platform, including deleting their AWS cognito credentials
 * TODO: Probably don't need to delete AWS cognito once we expand to Direct-To-Consumer web and iOS apps.
 * REQUIRES: Requestor must be Admin/SupportEngineer/SuperAdmin.
 * @param {object} user User object retrieved from the cloud backend
 */
export function deleteUser(user) {
  return invokeApig({
    path: "/id/user/delete",
    method: "POST",
    body: {
      userName: user.userName
    }
  });
}

/**
 * Invite a user and add them to the specified organization.
 * REQUIRES: Requestor must be Admin/SuperAdmin or SupportEngineer.    
 * @param {string} email email of user to invite to established organization
 * @param {string} orgId organization id to add user to
 */
export function inviteUser(email, orgId) {
  return invokeApig({
    path: "/id/inviteUserToOrg",
    method: "POST",
    body: {
      userName: email,
      orgId
    }
  });
}

/**
 * Update an organization's app and platform licenses.      
 * @param {string} orgId organization id to update
 * @param {object} update Object containing license details to be allowed for organization. Refer to docs for more information.
 */
export function updateOrgLicenses(orgId, update) {
  return invokeApig({
    path: "/id/org/updateLicenses",
    method: "POST",
    body: { orgId, update }
  });
}

/**
 * Get all API Tokens for a user's org
 * REQUIRES: Requestor must be an Admin. If request is submitted by a HLX user they must have the admin, support engineer, or superadmin role.
 */
export function getOrgAPItokens(orgId) {
  const params = {
    path: "/id/getOrgAPItokens",
    method: "GET"
  };
  if (orgId) {
    params.queryParams = { orgId };
  }
  return invokeApig(params);
}

/**
 * Generate an API token for the requestor's organization.
 * REQUIRES: Requestor must be an Admin. If request is submitted by a HLX user they must have the support engineer or superadmin role.
 * @param {string} apiIdentifier Name for API token to generate
 */
export function generateToken(apiIdentifier, orgId) {
  return invokeApig({
    path: "/id/createApiToken",
    method: "POST",
    body: {
      apiIdentifier,
      orgId: orgId || undefined
    }
  });
}

/**
 * Delete an API Token
 * REQUIRES: Requestor must be an Admin.
 * @param {string} apiId ID of API Token
 */
export function deleteApiToken(apiId) {
  return invokeApig({
    path: "/id/deleteApiToken",
    method: "POST",
    body: { apiId }
  });
}

/**
 *  App Service API
 */

/**
 * Get all the cases/jobs for the given org.
 * @param {string} orgId Optional. The organization ID to retrieve the cases/jobs from. 
 */
export function getJobs(orgId, key) {
  let params = {
    path: "/apps/getJobsByOrg",
    method: "GET",
  };

  if (orgId) {
    params.queryParams = {
      orgId
    };
  }
  if (key && params.queryParams) { params.queryParams.key = key; }
  else if (key) {
    params.queryParams = {
      key
    };
  }

  return invokeApig(params);
}

/**
 * Get a case/job 
 * @param {string} eventId The eventId of the job/case to retrieve
 */
export function getJob(eventId) {
  return invokeApig({
    path: "/apps/getJob",
    method: "GET",
    queryParams: {
      eventId
    }
  });
}

/**
 * Gets a Pre-signed URL to download a case/job input data.
 * Note that if the case was processed on an edge device, the input data 
 * must first be uploaded using the appropriate API methods.
 * REQUIRES: Requestor must be SuperAdmin/SupportEngineer.
 * @param {string} eventId The id of the job/case to get a DownloadURL
 */
export function getJobInputDownloadURL(eventId) {
  return invokeApig({
    path: "/apps/getJobInputDownloadURL",
    method: "GET",
    queryParams: {
      eventId
    }
  });
}

/**
 * Get all the apps available to the requestor, based on their role, and org's license
 */
export function getAvailableApps() {
  return invokeApig({
    path: "/apps/getAllApps",
    method: "GET",
  });
}

/**
 * Retrieve all allowed apps for a given organization
 * @param {string} orgId organization id 
 */
export function getAllowedApps(orgId) {
  return invokeApig({
    path: "/apps/getAllowedApps",
    method: "GET",
    queryParams: {
      orgId
    }
  });
}

/**
 * Get all apps in the backend
 */
export function getAllApps() {
  return invokeApig({
    path: "/apps/getAllApps",
    method: "GET",
  });
}

export function getAppBuilds(appId) {
  return invokeApig({
    path: "/apps/getAppBuilds",
    method: "GET",
    queryParams: {
      appId
    }
  });
}

/**
 * Registers an app on the HLX Platform
 * REQUIRES: Requestor must be a SuperAdmin/AppAdmin.
 * @param {object} param0 Object of app to create    
 */
export function newApp({
  appId,
  name,
  type,
  aeTitle,
  description,
  cloudType,
  edgeType
}) {
  return invokeApig({
    path: "/apps/newApp",
    method: "POST",
    body: {
      appId,
      name,
      type,
      aeTitle,
      description,
      cloud: { type: cloudType },
      edge: { type: edgeType }
    }
  });
}

/**
 * Delete a case from the cloud backend, and the edge device if applicable.
 * REQUIREMENTS: Requestor must be Admin/SuperAdmin/SupportEngineer.
 * @param {string} eventId ID of job to delete
 */
export function deleteJobOnCloud(eventId) {
  return invokeApig({
    path: "/apps/deleteJob",
    method: "POST",
    body: { eventId }
  });
}

/**
 * Retrieves source org jobs
 * @param  {string} orgId- optional. If orgId is provided it must be from provided by a super admin or support engineer
 * @param {string} key- optional. The pagination key
 */
export function getSourceOrgJobs(orgId, key) {
  let params = {
    path: "/apps/getSourceOrgJobs",
    method: "GET",
  };

  if (orgId && orgId.length) {
    params.queryParams = { ...params.queryParams, orgId };
  }

  if (key && key.length) {
    params.queryParams = { ...params.queryParams, key };
  }

  return invokeApig(params);
}

/**
 * Runs Integrated or Genetics Docker application in the cloud.
 * @param  {string} appId- Id of the app to run
 * @param  {string} patientId - patient ID to run app on
 * @param  {string?} appVersion- If undefined, server will assume latest version
 * @param  {string?} s3KeyGenetics- full s3 key to genetics file
 * @param  {string?} s3KeyImaging- full s3 key to imaging file
 * @param  {string?} orgId- If requester is HLX super admin or support, provide orgId to run on behalf
 * @param  {any?} options
 */
export function runCloudCoreApp({ appId, appVersion, patientId, s3KeyGenetics, s3KeyImaging, orgId, options }) {
  return invokeApig({
    path: "/apps/runAppByWebPortal",
    method: "POST",
    body: {
      appId,
      appVersion,
      patientId,
      s3KeyGenetics,
      s3KeyImaging,
      orgId,
      options,
    }
  });
}

export function searchJobs(obj) {
  const queryParams = {};
  for (const key in obj) {
    if (!obj.hasOwnProperty(key) || obj[key] === undefined || obj[key] === null) {
      continue;
    }
    queryParams[key] = obj[key];
  }
  return invokeApig({
    path: "/apps/searchJobs",
    method: "GET",
    queryParams
  });
}


/**
 *  Datalake Service API
 */
/**
 * Get a AWS S3 Token with the access token in relation to the organization specified.
 * NOTE: SuperAdmin/SupportEngineers can request tokens from other orgs
 * @param {string} orgId Optional. ID of organization to get the context of permissions to access s3 objects on HLX Platform
 */
export function getWebPreviewToken(orgId) {
  let params = {
    path: "/data/getWebPreviewToken",
    method: "GET",
  };

  if (orgId) { params.queryParams = { orgId }; }

  return invokeApig(params);
}

/**
 *  Audit Service API
 */

/**
* Get all audit trails on the HLX platform
* REQUIRES: Requestor must be SuperAdmin/SupportEngineer.
* @param {string} date Optional. The eventDate from LastEvaluatedKey. Used for pagination. Both date and key are required for pagination.
* @param {string} key Optional. The eventId from LastEvaluatedKey. Used for pagination
*/
export function getAllAuditLogs(key, date) {
  const params = {
    path: "/audit/getAllLogs",
    method: "GET",
  };
  if (key && !date || !key && date) {
    return Promise.reject(new Error("key & date param must be both provided. Only one was provided"));
  }

  if (key && date) { params.queryParams = { ...params.queryParams, key, date }; }

  return invokeApig(params);
}

/**
* Get audit trails for a specific org. If no orgId query parameter is provided, the orgId will be the one from the requestor. Limited to 30 records per API call.
* REQUIRES: Requestor must be SuperAdmin/SupportEngineer.
* @param {string} orgId Optional. The orgId used to retrieve logs from a specific organization. Used by SuperAdmins or SupportEngineers only.
* @param {string} date Optional. The eventDate from LastEvaluatedKey. Used for pagination. Both date and key are required for pagination.
* @param {string} key Optional. The eventId from LastEvaluatedKey. Used for pagination
*/
export function getOrgAuditLogs(orgID, key, date) {
  const params = {
    path: "/audit/getOrgLogs",
    method: "GET",
  };
  if (orgID) { params.queryParams = { orgId: orgID }; }

  if (key && !date || !key && date) {
    return Promise.reject(new Error("key & date param must be both provided. Only one was provided"));
  }

  if (key && date) { params.queryParams = { ...params.queryParams, key, date }; }

  return invokeApig(params);
}

/**
 * Publishes an audit trail with the details in the data object
 * @param  {object} data
 * @param  {string} data.type - the predefined event type
 * @param  {string} data.description - the description of the event
 * @param  {boolean} data.PHI - whether the event involves or is related to PHI
 * @param  {string} data.orgId (optional) - the orgId this event applies to. Only super admin or support admin producing events on behalf of other orgs should provide this. Otherwise it should remain undefined
 */
export function publishAuditTrail(data) {
  return invokeApig({
    path: "/audit/publish",
    method: "POST",
    body: data
  });
}

/**
 *  Edge Service API
 */

/**
 * Requests to uninstall an app from an Edge Device
 * 
 * @param {string} id The Installed App ID Instance
 * @param {string} deviceId The deviceId of the Edge Device
 */
export function edgeUninstallApp(id, deviceId) {
  return invokeApig({
    path: "/edge/uninstallApp",
    method: "POST",
    body: {
      deviceId,
      installedAppId: id
    }
  });
}

/**
 * Get all edge devices registered on the HLX platform
 * REQUIRES: Requestor must be SuperAdmin/SupportEngineer.
 */
export function getAllEdgeDevices() {
  return invokeApig({
    path: "/edge/adminGetAllDevices",
    method: "GET",
  });
}

/**
 * Retrieve all edge devices from a given org
 * REQUIRES: Requestor must be an Admin/SuperAdmin/SupportEngineer.
 * @param {string} orgId Optional. Organization id to get all devices from.
 */
export function fetchDevices(orgId) {
  let params = {
    path: "/edge/getAllMyDevices",
    method: "GET",
  };
  if (orgId) { params.queryParams = { orgId }; }

  return invokeApig(params);
}

/**
 * Delete an edge device from the cloud backend, including revoking all permissions, API tokens, and related
 * CA certificates. 
 * REQUIRES: Requestor must be an Admin/SuperAdmin/SupportEngineer.
 * @param {object} device Edge device object as retrieved from the cloud backend.
 */
export function deleteDevice(device) {
  return invokeApig({
    path: "/edge/deleteDevice",
    method: "POST",
    body: {
      deviceId: device.deviceId
    }
  });
}

/**
 * Get an Edge Device
 * @param {string} deviceId ID of device to get.
 */
export function getDevice(deviceId) {
  return invokeApig({
    path: "/edge/getDevice",
    method: "GET",
    queryParams: {
      deviceId
    }
  });
}

/**
 * Retrieves all devices on the platform. Need SuperAdmin or SupportEngineer access roles to execute this endpoint.
 */
export function getAllDevices() {
  return invokeApig({
    path: "/edge/adminGetAllDevices",
    method: "GET",
  });
}

export function getEdgeVersions(version) {
  return invokeApig({
    path: "/edge/versions",
    method: "GET",
    ...(version ? {
      queryParams: {
        version: version || undefined
      }
    } : {})
  })
}

/**
 * Get all dicom nodes declared on a specific Edge Device
 * @param {string} deviceId ID of edge device
 */
export function getDicomNodes(deviceId) {
  return invokeApig({
    path: "/edge/getEdgeDicomNodes",
    method: "GET",
    queryParams: {
      deviceId
    }
  });
}

/**
 * Adds a dicom node to the Edge Device. Dicom nodes must be declared on the Edge devices
 * prior to invoking apps, otherwise the apps will fail.
 * @param {string} deviceId ID of edge device
 * @param {object} param1 Object representing dicom node to be added to Edge Device
 */
export function addDicomNode(deviceId, {
  name,
  aeTitle,
  ipAddress,
  port
}) {
  return invokeApig({
    path: "/edge/addDicomNode",
    method: "POST",
    body: {
      deviceId,
      dicomNode: {
        name,
        aeTitle,
        ipAddress,
        port: parseInt(port)
      }
    }
  });
}

/**
 * Delete a dicom node from a device    
 * @param {string} deviceId Id of edge device   
 * @param {string} nodeId Id of node id installed on Edge Device
 */
export function deleteDicomNode(deviceId, nodeId) {
  return invokeApig({
    path: "/edge/deleteDicomNode",
    method: "POST",
    body: {
      deviceId,
      nodeId
    }
  });
}

/**
 * Deletes a case that originated from an Edge device. The case is deleted on the cloud backend,
 * edge device, and all files removed from S3.
 * @param {string} deviceId ID of edge device
 * @param {string} eventId ID of job/case
 */
export function deleteJobOnEdge(deviceId, eventId) {
  return invokeApig({
    path: "/edge/deleteJob",
    method: "POST",
    body: {
      deviceId,
      eventId
    }
  });
}

/**
 * Replays a job that originated from an edge device on the edge device itself. 
 * @param {string} deviceId ID of edge device
 * @param {string} eventId ID of job/case
 */
export function replayJobOnEdge(deviceId, eventId) {
  return invokeApig({
    path: "/edge/replayJob",
    method: "POST",
    body: {
      deviceId,
      eventId
    }
  });
}

/**
 * Resends dicoms to all declared dicom routes on Edge Device for given job
 * @param {string} deviceId ID of edge device
 * @param {string} eventId ID of job/case
 */
export function sendDicomRoutes(deviceId, eventId) {
  return invokeApig({
    path: "/edge/sendDicomRoutes",
    method: "POST",
    body: {
      deviceId,
      eventId
    }
  });
}

/**
 * If edge job, uploads the input, config and output data from a job to HLX cloud. 
 * @param {string} deviceId ID of edge device
 * @param {string} eventId ID of job/case
 */
export function uploadJobToCloud(deviceId, eventId) {
  return invokeApig({
    path: "/edge/uploadJobToCloud",
    method: "POST",
    body: {
      deviceId,
      eventId
    }
  });
}

/**
 * Requests the Edge Device to upload all EdgeOS logs
 * @param {string} deviceId ID of edge device
 */
export function uploadEdgeLogs(deviceId) {
  return invokeApig({
    path: "/edge/uploadEdgeLogs",
    method: "POST",
    body: {
      deviceId
    }
  });
}

/**
 * Send MQTT Message to Device
 * @param {string} deviceId ID of edge device
 * @param {string} mqttEndpoint Endpoint to send message to on device MQTT
 * @param {Object} payload Object to send (this will depend on mqttEndpoint)
 */
export function sendMqttMessage(deviceId, mqttEndpoint, payload) {
  return invokeApig({
    path: "/edge/mqtt/send",
    method: "POST",
    body: { deviceId, mqttEndpoint, payload }
  });
}

/**
 * Gets all apps installed on Edge Device
 * @param {string} deviceId ID of edge device
 */
export function getInstalledApps(deviceId) {
  return invokeApig({
    path: "/edge/getAllInstalledApps",
    method: "GET",
    queryParams: {
      deviceId
    }
  });
}

/**
 * Requests the edge device to download/install an HLX application again
 * @param {object} app App object as retrieved from cloud backend
 * @param {string} deviceId ID of edge device
 */
export function reDownloadDockerApp(app, deviceId) {
  return invokeApig({
    path: "/edge/reDownloadImage",
    method: "POST",
    body: {
      app,
      deviceId
    }
  });
}

/**
 * Requests the edge device to download/install an HLX application
 * @param {string} deviceId ID of edge device
 * @param {object} param1 Object containing the App object to install, the type and the version.
 */
export function requestAppInstall(deviceId, {
  app,
  installType,
  installVersion
}) {
  return invokeApig({
    path: "/edge/newAppInstallRequest",
    method: "POST",
    body: {
      app,
      installType,
      installVersion,
      deviceId
    }
  });
}

/**
 * Deletes a route from the application on the Edge Device
 * @param {string} deviceId ID of edge device
 * @param {object} param1 Object containing app to modify, the specific route and the index to use when deleting the route.
 */
export function removeRouteFromApp(deviceId, {
  app,
  route,
  index
}) {
  return invokeApig({
    path: "/edge/removeRouteFromApp",
    method: "POST",
    body: {
      index,
      route,
      app,
      deviceId
    }
  });
}
/**
 * Enables or disables HLX admin notifications on this device
 * Only HLX super admins and support engineers should be able to call this function
 * @param  {string} deviceId
 * @param  {boolean} enable
 */
export function toggleNotifications(deviceId, enable) {
  return invokeApig({
    path: "/edge/toggleNotifications",
    method: "POST",
    body: {
      deviceId,
      enable,
    }
  });
}

/**
 * Retrieve the stats for today for the logged in user or by specifying an orgId as a query parameter.
 * @param {string} orgId Optional orgId if getting stats for an org other than the logged in user's org.
 */
export function todaysOrgStats(orgId) {
  const params = {
    path: "/analytics/stats/org/today",
    method: "GET",
  };

  if (orgId) {
    params.queryParams = { orgId };
  }

  return invokeApig(params);
}

/**
 * Retrieves an array of stats for the inferred or specified org using the range specified.
 * @param {string} start Required. The date you want to start collecting stats for your query in the format of YYYYMMDD
 * @param {string} end Required. The date you want to stop collecting stats for your query in the format of YYYYMMDD
 * @param {string} orgId Optional orgId if getting stats for an org other than the logged in user's org.
 */
export function orgStatsQuery(start, end, orgId) {
  const params = {
    path: "/analytics/stats/org/query",
    method: "GET"
  };

  if (!start || !end) {
    return Promise.reject(new Error("Must provide start and end time"));
  }

  params.queryParams = { start, end };

  if (orgId) {
    params.queryParams = { ...params.queryParams, orgId };
  }

  return invokeApig(params);
}

/**
 * Retrieves all the OrgStats from all active organizations for a specific day. Only to be used by SuperAdmins or SupportEngineers.
 * @param {string} start Optional. The start date you want to start the query in format of YYYYMMDD. Must use end as well.
 * @param {string} end Optional. The end date you want to query in format of YYYYMMDD. If not specified the response will return results only for that day
 */
export function allOrgStatsQuery(start, end) {
  const params = {
    path: "/analytics/stats/orgs/all/query",
    method: "GET"
  };

  if (start && end) {
    params.queryParams = { start, end };
  } else if (start) {
    params.queryParams = { day: start };
  } else {
    return Promise.reject("Must specify start or start and end date");
  }

  return invokeApig(params);
}

/**
 * Retrieves platform wide analytics. Must be SuperAdmin or SupportEngineer to perform this.
 * Optional queryParameters can be used to query for a specific range.
 * If no queryParamters are provided with the request, the only the current day's stats will be returned.
 * @param  {string} start The month-day combination to start the query from in the format of MMDD.
 * @param {string} end The month-day combination to end the query in the format of MMDD.
 * @param {string} year Year to query in the format of YYYY. Only results from this year will be returned. No current ability to query and get results from different years in one request.
 */
export function globalOrgStatsQuery(start, end, year) {
  const params = {
    path: "/analytics/stats/global",
    method: "GET"
  };

  if (start) {
    params.queryParams = { ...params.queryParams, start };
  }

  if (end) {
    params.queryParams = { ...params.queryParams, end };
  }

  if (year) {
    params.queryParams = { ...params.queryParams, year };
  }

  return invokeApig(params);
}

/**
 * Retrieves sample kit stats for organization from start to end date. If no orgId is provided,
 * global stats are retrieved
 * @param  {string} start - in format YYYYMMDD
 * @param  {string} end - in format YYYYMMDD
 * @param  {string} orgId? - orgId of stats, otherwise GLOBAL
 */
export function sampleKitsStatsQuery(start, end, orgId = "GLOBAL") {
  if (!start || !end) {
    return Promise.reject("Must specify start and end date");
  }

  return invokeApig({
    path: "/analytics/samplekits",
    method: "GET",
    queryParams: {
      start,
      end,
      orgId: orgId
    }
  });
}

/**
 * Retrieves all patient stats for an organization
 * @param  {string} orgId
 * @param  {string} start
 * @param  {string} end
 */
export function orgPatientStatsQuery(orgId, start, end) {
  return invokeApig({
    path: "/analytics/org/patients",
    method: "GET",
    queryParams: {
      orgId,
      start,
      end
    }
  });
}

/**
 * Adds a route to an application on the Edge Device
 * @param {string} deviceId ID of edge device
 * @param {object} param1 Object containing app to modify and the route to be added
 */
export function addRouteToApp(deviceId, {
  app,
  route
}) {
  return invokeApig({
    path: "/edge/addRouteToApp",
    method: "POST",
    body: {
      route,
      app,
      deviceId
    }
  });
}

/**
 * Retrieve licenses from database. Optionally you can specify which organization to retrieve from. Results limited to 30 records.
 * @param  {string} keyOne - Optional but requires keyTwo
 * @param  {string} keyTwo - Optional but requires keyOne
 * @param  {string} orgId - Optional
 */
export function getLicenses(keyOne, keyTwo, orgId) {
  const params = {
    path: "/licenses/query",
    method: "GET"
  };

  if (keyOne && keyTwo) {
    params.queryParams = { keyOne, keyTwo };
  }

  if (orgId) {
    params.queryParams = { ...params.queryParams, orgId };
  }

  return invokeApig(params);
}

/**
 * @param {string} expirationDate - Required. in format MM/DD/YYYY including the forward slashes
 * @param {string} appName - Required
 * @param {string} appId - Required
 * @param {string} orgId - Required
 */
export function generateLicense(expirationDate, appName, appId, orgId) {
  return invokeApig({
    path: "/licenses/generate",
    method: "POST",
    body: {
      expirationDate,
      appName,
      appId,
      orgId,
    }
  });
}

/**
 * @param  {string} serial - Required. Serial number of the license
 * @param  {string} orgId - Required.
 */
export function uninstallLicense(serial, orgId) {
  return invokeApig({
    path: "/licenses/deinstall",
    method: "POST",
    body: {
      serial,
      orgId,
    }
  });
}

/**
 * @param  {string} serial - Required. Serial number of the license
 */
export function getLicenseBy(serial) {
  return invokeApig({
    path: "/licenses/search",
    method: "GET",
    queryParams: {
      serial,
    }
  });
}

/**
 * Modifies an app's configurations on the Edge Device
 * @param {string} deviceId ID of edge device  
 * @param {object} app App object as retrieved from cloud backend.  
 */
export function requestAppUpdate(deviceId, app) {
  return invokeApig({
    path: "/edge/updateApp",
    method: "POST",
    body: {
      app,
      deviceId
    }
  });
}
/**
 * Retrieve the latest 60 invoices from an organization. Must be Admin of Org, or SupportEngineer/SuperAdmin.
 * @param {string} orgId - Optional
 */
export function getInvoices(orgId) {
  const params = {
    path: "/billing/invoices/org/get",
    method: "GET"
  };

  if (orgId) {
    params.queryParams = { orgId };
  }

  return invokeApig(params);
}
/**
 * @param  {object} - of the following keys
  * @param  {string} billingStart - Required. The date in ISP formatted string on which the billing period starts.
  * @param  {string} billingEnd - Required. The date in ISP formatted string on which the billing period ends.
  * @param  {string} orgId - Required
  * @param  {string} paymentTerms  - Optional. Net 30 Days, Net 60 Days, or Net 90 Days
  * @param  {string} poNumber - Customer PO number
 */
export function createInvoice({ billingStart, billingEnd, orgId, paymentTerms, poNumber }) {
  const params = {
    path: "/billing/invoices/org/new",
    method: "POST",
    body: {
      billingStart,
      billingEnd,
      orgId
    }
  };

  if (paymentTerms) {
    params.body = { ...params.body, paymentTerms };
  }

  if (poNumber) {
    params.body = { ...params.body, poNumber };
  }

  return invokeApig(params);
}

/**
 * Edits a single invoice
 * @param {object} invoice - Required.
 */
export function editInvoice(invoice) {
  return invokeApig({
    path: "/billing/invoices/update",
    method: "POST",
    body: {
      invoice
    }
  });
}

/**
 * Marks a single invoice as billed
 * @param {string} invoiceNumber - Required.
 */
export function billInvoice(invoiceNumber) {
  return invokeApig({
    path: "/billing/invoices/bill",
    method: "POST",
    body: {
      invoiceNumber
    }
  });
}

/**
 * Marks a single invoice as paid
 * @param {string} invoiceNumber - Required.
 * @param {string} paymentConfirmation
 */
export function payInvoice(invoiceNumber, paymentConfirmation) {
  let body = { invoiceNumber };
  if (paymentConfirmation) {
    body = { ...body, paymentConfirmation };
  }
  return invokeApig({
    path: "/billing/invoices/payed",
    method: "POST",
    body,
  });
}

export function getPatients(orgId, patientIdKey, orgIdKey) {
  const params = {
    path: "/patients/v1/patients",
    method: "GET"
  };

  if (orgId) {
    params.queryParams = { orgId };
  }

  if (patientIdKey && orgIdKey) {
    params.queryParams = { ...params.queryParams, patientIdKey, orgIdKey };
  }

  return invokeApig(params);
}

export function getPatient(patientId, orgId) {
  const params = {
    path: "/patients/v1/patient",
    method: "GET",
    queryParams: {
      patientId,
    }
  };

  if (orgId) {
    params.queryParams = { ...params.queryParams, orgId };
  }

  return invokeApig(params);
}

export function updatePatient(patient, orgId) {
  const params = {
    path: "/patients/v1/patient",
    method: "POST",
    body: {
      patient,
    }
  };
  if (orgId) {
    params.body = { ...params.body, orgId };
  }
  return invokeApig(params);
}

export function getPatientJobs(patientId, key) {
  const params = {
    path: "/apps/getJobsByPatient",
    method: "GET",
    queryParams: {
      patientId
    }
  };

  if (key && key.eventId && key.received) {
    params.queryParams = { ...params.queryParams, eventIdKey: key.eventId, receivedKey: key.received };
  }

  return invokeApig(params);
}

export function getPatientRecords(patientId, ownerId, patientIdKey, ownerIdKey, recordIdKey) {
  const params = {
    path: "/patients/v1/patient/records",
    method: "GET",
    queryParams: {
      patientId,
    }
  };

  if (ownerId) {
    params.queryParams = { ...params.queryParams, ownerId };
  }

  if (patientIdKey && ownerIdKey && recordIdKey) {
    params.queryParams = { ...params.queryParams, patientIdKey, ownerIdKey, recordIdKey };
  }

  return invokeApig(params);
}

export function getPatientRecord(ownerId, recordId) {
  return invokeApig({
    path: `/patients/v1/patient/${ownerId}/record/${recordId}`,
    method: "GET"
  });
}

export function deletePatientRecord(recordId, orgId) {
  return invokeApig({
    path: "/patients/v1/patient/record/delete",
    method: "POST",
    body: {
      recordId,
      orgId
    }
  });
}

export function createPatientRecord({
  patientId, type, description, typeOfOwner, orgId, isDirectory,
}) {
  const params = {
    path: "/patients/v1/patient/record",
    method: "POST",
    body: {
      patientId,
      type,
      description,
      typeOfOwner,
      orgId,
      isDirectory
    }
  };
  return invokeApig(params);
}

export function updatePatientRecord({
  recordId, type, description, orgId
}) {
  const params = {
    path: "/patients/v1/patient/record/update",
    method: "PUT",
    body: {
      recordId,
      type,
      description,
      orgId: orgId || undefined
    }
  };
  return invokeApig(params);
}

/**
 * Merges two patients into one
 * @param  {string} p1Id The source patient ID that is being merging from
 * @param  {string} p2Id The target patient ID that is being merging into
 * @param  {string} orgId (optional)
 */
export function mergePatients(p1Id, p2Id, orgId) {
  const params = {
    path: "/patients/v1/merge/patients",
    method: "POST",
    body: {
      p1Id,
      p2Id,
    }
  };

  if (orgId) {
    params.body = { ...params.body, orgId };
  }

  return invokeApig(params);
}

/*
App Services
*/
export function runAlzIntegrated({ patientId, patientOrgId, neuroQuantKey, geneticsKey, mci }) {
  if (!patientId || !patientOrgId || !neuroQuantKey || !geneticsKey) {
    return Promise.reject("Missing required POST body parameters");
  }
  return invokeApig({
    path: "/app-alz-integrated/v1/run",
    method: "POST",
    body: {
      patientId,
      patientOrgId,
      neuroQuantKey,
      geneticsKey,
      mci: mci || false
    }
  });
}

export function runAlzGenetic({ patientId, orgId, geneticsKey }) {
  if (!patientId || !orgId || !geneticsKey) {
    return Promise.reject("Missing required POST body parameters");
  }
  return invokeApig({
    path: "/app-alzgenic/runGeneticRisk",
    method: "POST",
    body: {
      patientId,
      orgId,
      geneticsKey,
    }
  });
}

/*
  Genetic Kits
 */
export function getKit(sampleId, orgId) {
  const params = {
    path: "/integrations/diagnomics/v1/getKit",
    method: "GET",
    queryParams: {
      sampleId,
    }
  };

  if (orgId) {
    params.queryParams = { ...params.queryParams, orgId };
  }

  return invokeApig(params);
}

export function getKits(orgId, orgIdKey, sampleIdKey) {
  const params = {
    path: "/integrations/diagnomics/v1/getKits",
    method: "GET",
  };

  if (orgId) {
    params.queryParams = { orgId };
  }

  if (orgIdKey && sampleIdKey) {
    params.queryParams = { orgIdKey, sampleIdKey };
  }

  return invokeApig(params);
}

export function orderKits(data) {
  if (!data.amount || data.amount < 1) {
    return Promise.reject(new Error("Order amount must be greater than zero"));
  }

  const params = {
    path: "/integrations/diagnomics/v1/orderKits",
    method: "POST",
    body: {
      amount: data.amount,
      orgId: data.orgId,
      streetNumber: data.streetNumber,
      addressLineOne: data.addressLineOne,
      addressLineTwo: data.addressLineTwo,
      city: data.city,
      state: data.state,
      suite: data.suite,
      country: "US",
      postalCode: data.postalCode,
    }
  };

  return invokeApig(params);
}

export function getPatientSampleKits(patientId, orgId, orderedKey) {
  const params = {
    path: "/integrations/diagnomics/v1/getKitsByPatientId",
    method: "GET",
    queryParams: {
      patientId
    }
  };
  if (orgId) {
    params.queryParams = { ...params.queryParams, orgId };
  }
  if (orderedKey) {
    params.queryParams = { ...params.queryParams, orderedKey };
  }
  return invokeApig(params);
}

export function getKitByBarcode(barcode, orgId) {
  const params = {
    path: "/integrations/diagnomics/v1/getKitByBarcode",
    method: "GET",
    queryParams: {
      barcode
    }
  };

  if (orgId) {
    params.queryParams = { ...params.queryParams, orgId };
  }

  return invokeApig(params);
}

export function linkPatient(sampleId, patientId, orgId) {
  const params = {
    path: "/integrations/diagnomics/v1/linkPatient",
    method: "POST",
    body: {
      sampleId,
      patientId,
    }
  };

  if (orgId) {
    params.body = { ...params.body, orgId };
  }

  return invokeApig(params);
}

export function unlinkPatient(sampleId, orgId) {
  const params = {
    path: "/integrations/diagnomics/v1/unlinkPatient",
    method: "POST",
    body: {
      sampleId,
    }
  };

  if (orgId) {
    params.body = { ...params.body, orgId };
  }

  return invokeApig(params);
}

export function deletePatient(patientId, orgId) {
  const params = {
    path: "/patients/v1/patient/delete",
    method: "POST",
    body: {
      patientId
    }
  };

  if (orgId) {
    params.body = { ...params.body, orgId };
  }

  return invokeApig(params);

}

export function regenerateGeneticRiskReport({ patientId, orgId, eventId }) {
  return invokeApig({
    method: "POST",
    path: "/genetic-risk-pdf-reports/run",
    body: { patientId, orgId, eventId }
  });
}

export function regenerateIntegratedRiskReport({ patientId, orgId, eventId }) {
  return invokeApig({
    method: "POST",
    path: "/integrated-risk-pdf-reports/run",
    body: { patientId, orgId, eventId }
  });
}