import React from "react";
import pt from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

import Select from "components/Select";
import SearchIcon from "material-ui-icons/Search";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { storeNotificationError, removeNotificationError } from "actions";
import { getJob, getDevice, getLicenseBy } from "libs/apiLib";

export class Search extends React.Component {
  static propTypes = {
  	rtlActive: pt.bool,
  	user: pt.shape({
  		superAdmin: pt.bool,
  		supportEngineer: pt.bool,
  	}).isRequired,
  	clearError: pt.func.isRequired,
  	signalError: pt.func.isRequired,
  	getJob: pt.func.isRequired,
  	getDevice: pt.func.isRequired,
  	getLicense: pt.func.isRequired,
  	history: pt.shape({
  		push: pt.func.isRequired,
  	}).isRequired,
  	classes: pt.shape({
  		top: pt.string.isRequired,
  		searchButton: pt.string.isRequired,
  		searchRTL: pt.string.isRequired,
  		searchIcon: pt.string.isRequired,
  		search: pt.string.isRequired,
  		searchInput: pt.string.isRequired,
  	}).isRequired,
  }

  constructor(props) {
  	super(props);
  	this.state = {
  		submitting: false,
  		resource: "Resource",
  		query: ""
  	};
  }

  handleSubmit = async () => {
  	const { resource, query } = this.state;
  	const { history, signalError, clearError, getDevice, getJob, getLicense } = this.props;
  	this.setState({ submitting: true });
    let errMsg = `"${query}" is an invalid ${resource} ID`;
  	try {
  		let search = `?id=${query}`;
  		let pathname = "";
  		let fn;
      let key;
  		switch (resource) {
  		case "case":
  			pathname = "/app/case";
  			fn = getJob;
        key = "job";
  			break;
  		case "device":
  			pathname = "/edge/device";
  			fn = getDevice;
  			key = "device";
  			break;
  		case "license":
  			if (!this.userHasPermission()) {
          errMsg = "Sorry you don't have permissions to search for licenses";
  				throw new Error(errMsg);
  			}
  			fn = getLicense;
  			key = "license";
  			search = "";
  			break;
  		default:
  			throw new Error("Invalid resource type");
  		}
  		const result = await fn(query);
  		if (result.success && result[key]) {
  			if (resource === "license") {
  				pathname = `/admin/orgs/${result.license.orgId}/alm`;
        }
  			history.push({ pathname, search, state: { [key]: result[key] } });
  			this.setState({ query: "", resource: "Resource" });
  		} else {
  			throw new Error(`"${query}" is an invalid ${resource} ID`);
  		}
  	} catch (e) {
  		signalError(errMsg);
  		setTimeout(clearError, 5000);
  	}
  	this.setState({ submitting: false });
  }

  userHasPermission = () => {
  	const { user } = this.props;
  	return user.superAdmin || user.supportEngineer;
  }

  searchResources = () => {
  	const results = [
  		{ display: "Resource", value: "Resource", disabled: true },
  		{ display: "Case", value: "case" },
  		{ display: "Device", value: "device" }
  	];
  	if (this.userHasPermission()) {
  		results.push({
  			display: "License",
  			value: "license"
  		});
  	}
  	return results;
  }

  shouldBeDisabled = () => {
  	const { submitting, query, resource } = this.state;
  	return submitting || query.length === 0 || resource === "Resource";
  }

  render() {
  	const { submitting, resource, query } = this.state;
  	const { rtlActive, classes } = this.props;
  	const searchButtonClassnames = classNames({
  		[classes.top]: true,
  		[classes.searchButton]: true,
  		[classes.searchRTL]: rtlActive
  	});
  	const searchIconClassnames = classNames({
  		[classes.searchIcon]: true,
  	});
  	const inputFormControlClassnames = classNames({
  		[classes.top]: true,
  		[classes.search]: true,
  	});
  	return (
  		<React.Fragment>
  			<Select
  				inputProps={{
  					name: "resource",
  					onChange: (e) => this.setState({ resource: e.target.value }),
  					value: resource,
  					disabled: submitting,
  				}}
  				values={this.searchResources()}
  				formControlProps={{
  					className: inputFormControlClassnames,
  					style: {
  						marginRight: "10px",
  						minWidth: "100px",
  					}
  				}}
  			/>
  			<CustomInput
  				rtlActive={rtlActive}
  				formControlProps={{
  					className: inputFormControlClassnames
  				}}
  				inputProps={{
  					onChange: (e) => this.setState({ query: e.target.value }),
  					value: query,
  					placeholder: rtlActive ? "بحث" : "Search",
  					style: {
  						height: "36px",
  					},
  					inputProps: {
  						"aria-label": rtlActive ? "بحث" : "Search",
  						className: classes.searchInput
  					}
  				}}
  			/>
  			<Button
  				color="white"
  				aria-label="search"
  				justIcon
  				round
  				className={searchButtonClassnames}
  				style={{
  					padding: "8px 12px"
  				}}
  				disabled={this.shouldBeDisabled()}
  				onClick={this.handleSubmit}
  			>
  				<SearchIcon className={searchIconClassnames} />
  			</Button>
  		</React.Fragment>
  	);
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
  signalError: (message) => dispatch(storeNotificationError(message)),
  clearError: () => dispatch(removeNotificationError()),

  getJob: (id) => getJob(id),
  getDevice: (id) => getDevice(id),
  getLicense: (id) => getLicenseBy(id),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));