import React from "react";
import moment from "moment";
import pt from "prop-types";
import Reports from "models/Reports";
import Table from "react-table";

// core components
import { WithOrgReport } from "components/HigherOrder";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard";
// import Button from "components/CustomButtons/Button"
import { DeviceUsageContent } from "views/Organization/components/DeviceUsage";
import Badge from "components/Badge/Badge";
import Dropdown from "components/CustomDropdown/CustomDropdown";

// styles
import withStyles from "material-ui/styles/withStyles";
import tableStyles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";

let DeviceUsageTable = ({ report, classes, metric }) => {
  let data = [];
  if (report) {
    const devices = report.edgeDeviceUsage();
    data = Object.keys(devices).map((id) => ({ name: devices[id].name, metric: metric === "total" ? devices[id].successful + devices[id].failures : devices[id][metric] }));
  }

  const badgeColor = (metric) => {
    switch (metric) {
    case "successful":
      return "success";
    case "failures":
      return "danger";
    default:
      return "primary";
    }
  };
  return (
    <Table
      noDataText="No Devices Reported for this Date Range"
      data={data}
      columns={[
        {
          Header: "",
          accessor: "name",
          Cell: (cell) => (
            <div>
              <div>{cell.value}</div>
              <div className={classes.label}>Name</div>
            </div>
          )
        },
        {
          Header: "",
          accessor: "metric",
          Cell: (cell) => <div className={classes.right}><Badge color={badgeColor(metric)}>{cell.value}</Badge></div>
        },
      ]
      }
      sortable={false}
      resizable={false}
      pageSize={10}
      minRows={1}
      showPagination={false}
    />
  );
};

DeviceUsageTable.propTypes = {
  report: pt.instanceOf(Reports),
  classes: pt.shape({
    label: pt.string.isRequired,
  }).isRequired,
  metric: pt.string.isRequired,
};

DeviceUsageTable = withStyles(tableStyles)(DeviceUsageTable);

class DeviceStats extends React.Component {
  state = {
    metric: "Total"
  }

  handleMetricSelect = (metric) => this.setState({ metric })

  render() {
    const { start, end, report } = this.props;
    const { metric } = this.state;
    let subtitle = "";
    if (start && end) {
      subtitle = `From ${start.format("MMMM Do YYYY")} To ${end.format("MMMM Do YYYY")}`;
    }
    let total = 0;
    if (report) {
      const devices = report.edgeDeviceUsage();
      total = Object.keys(devices).reduce((sum, id) => {
        return metric.toLowerCase() === "total" ? sum + devices[id].successful + devices[id].failures : sum + devices[id][metric.toLowerCase()];
      }, 0);
    }
    return (
      <HeaderCard
        headerColor="orange"
        cardTitle="Device Usage"
        cardSubtitle={subtitle}
        content={
          <GridContainer>
            <ItemGrid>
              <h3>Device Usage</h3>
            </ItemGrid>
            <ItemGrid xs={12}>
              <DeviceUsageContent report={report} />
            </ItemGrid>
            <ItemGrid xs={6} md={6}>
              <h3>Device Statistics</h3>
            </ItemGrid>
            <ItemGrid xs={6} md={6} container justify="flex-end">
              <Dropdown
                style={{
                  marginTop: 10
                }}
                buttonColor="primary"
                buttonText={metric}
                dropdownList={[
                  "Total",
                  "Successful",
                  "Failures",
                ]}
                onAction={this.handleMetricSelect}
              />
            </ItemGrid>
            <ItemGrid xs={12}>
              <DeviceUsageTable report={report} metric={metric.toLowerCase()} />
            </ItemGrid>
            <ItemGrid xs={12}>
              <h4 style={{ fontWeight: "bold", textAlign: "right", marginBottom: 0 }}>{total}</h4>
              <p style={{ textAlign: "right" }}>Total</p>
            </ItemGrid>
          </GridContainer>
        }
      />
    );
  }
}

DeviceStats.propTypes = {
  report: pt.instanceOf(Reports),
  start: pt.instanceOf(moment),
  end: pt.instanceOf(moment),
};

export {
  DeviceUsageTable,
  DeviceStats,
};
export default WithOrgReport(DeviceStats);