export default class LengthConverter {
  static errUnsupportedUnit = new Error("Unsupported unit type");
  static errNaN = new Error("Number provided is not a valid number");
  constructor(data) {
    this.unit = this.parseUnit(data.unit);
    this.number = this.parseValue(data.value);
  }

  parseUnit(unit) {
    if (!this.isValidUnit(unit)) {
      throw new Error(`Unsupported unit type ${unit}. Only feet, cm, and inches are supported.`);
    }
    return unit;
  }

  parseValue(unit) {
    const value = Number(unit);
    if (!unit || Number.isNaN(value) || value < 0) {
      return 0;
      // throw LengthConverter.errNaN;
    }
    return value;
  }

  add(n) {
    return new LengthConverter({ value: this.number + n < 0 ? 0 : this.number + n, unit: this.unit });
  }

  to(unit) {
    if (!this.isValidUnit(unit)) {
      throw new Error(`Unsupported unit type ${unit}. Only feet, cm, and inches are supported.`);
    }
    if (unit === "in") {
      return this.inches();
    }
    return this[unit]();
  }

  isValidUnit(unit) {
    switch (unit) {
    case "cm":
    case "ft":
    case "in":
      return true;
    default:
      return false;
    }
  }

  value() {
    return this.number;
  }

  inches() {
    if (this.unit === "in") {
      return this;
    }
    switch (this.unit) {
    case "ft":
      // ft to in
      return new LengthConverter({ value: this.number * 12, unit: "in" });
    case "cm":
      // cm to in;
      return new LengthConverter({ value: this.number / 2.54, unit: "cm" });
    default:
      throw LengthConverter.errUnsupportedUnit;
    }
  }

  ft() {
    if (this.unit === "ft") {
      return this;
    }
    switch (this.unit) {
    case "in":
      // in to ft
      return new LengthConverter({ value: this.number / 12, unit: "ft" });
    case "cm":
      // cm to ft;
      return new LengthConverter({
        value: Math.floor((this.number / 2.54) / 12),
        unit: "ft"
      });
    default:
      throw LengthConverter.errUnsupportedUnit;
    }
  }

  cm() {
    if (this.unit === "cm") {
      return this;
    }
    switch (this.unit) {
    case "ft":
      // ft to cm
      return new LengthConverter({
        value: (this.number * 12) * 2.54,
        unit: "cm"
      });
    case "in":
      // in to cm;
      return new LengthConverter({ value: this.number * 2.54, unit: "ft" });
    default:
      throw LengthConverter.errUnsupportedUnit;
    }

  }
}