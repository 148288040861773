const appsIntegrated = {
  airr: "appId-92821", // alz integrated risk genpop
  airrMci: "appId-84634", // alz integrated risk MCI
};
const appsGenetic = {
  agrr: "appId-25202", // alz genetic risk
  pgr: "appId-27968" // prostate cancer genetic-risk
};
export const APPS = {
  ...appsIntegrated,
  ...appsGenetic
};

export const APPSSET = new Set([
  APPS.airr,
  APPS.airrMci,
  APPS.agrr,
  APPS.pgr
]);

export const APPSINTEGRATEDSET = new Set([
  APPS.airr,
  APPS.airrMci,
]);

export const APPSGENETICSET = new Set([
  APPS.agrr,
  APPS.pgr
]);

export const APPIDTONAME = {
  "HY-UPLOAD": "Patient Record Upload (HY-UPLOAD)",
  "appId-18064": "RSI-MRI+ for Prostate",
  "appId-55316": "CTCoPilot",
  "appId-62299": "RSI-MRI+ for Breast",
  "appId-41605": "RSI-MRI+ for Brain",
  "appId-10405": "RSI-MRI+ for Whole Body",
  "appId-12578": "Lesion Quant",
  "appId-86906": "FreeSurfer",
  [appsIntegrated.airr]: "Alzheimer's IntegratedRisk Report",
  [appsIntegrated.airrMci]: "Alzheimer's IntegratedRisk Report: MCI",
  [appsIntegrated.agrr]: "Alzheimer's GeneticRisk Report",
  [appsIntegrated.pgr]: "Prostate GeneticRisk Report",
};