import React from "react"
import pt from "prop-types"
import { connect } from "react-redux"

// models
import Reports from "models/Reports"

// HOC
import { WithOrgReport } from "components/HigherOrder"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import ItemGrid from "components/Grid/ItemGrid.jsx"
import StatsCard from "components/Cards/StatsCardSmall.jsx"

// material-ui icons
import ContentCopy from "material-ui-icons/ContentCopy"
import InfoOutline from "material-ui-icons/InfoOutline"
import DateRange from "material-ui-icons/DateRange"
import Queue from "material-ui-icons/Queue"
import DirectionsRun from "material-ui-icons/DirectionsRun"

import { getTodaysCases, getRunningCases, getQueuedCases } from "libs/utils"

export class Stats extends React.Component {
  static propTypes = {
  	report: pt.instanceOf(Reports),
  	orgId: pt.string,
  	cases: pt.shape({
  		data: pt.array,
  	}),
  }

  render() {
  	const { cases, report } = this.props
  	let total, today, failed, queue, running = 0
  	if (cases && cases.data) {
  		total = cases.data.length
  		const todayCases = getTodaysCases(cases.data)
  		queue = getQueuedCases(todayCases).length
  		running = getRunningCases(todayCases).length
  	}

  	if (report) {
  		const failedApps = report.appFailures()
  		const successfulApps = report.appSuccesses()
  		today = failedApps.length + successfulApps.length
  		failed = failedApps.length
  	}
  	return (
  		<GridContainer>
  			<ItemGrid xs={12} sm={6} md={3} lg={3}>
  				<StatsCard
  					icon={ContentCopy}
  					iconColor="green"
  					title="Latest Cases"
  					description={today}
  					statIcon={DateRange}
  					statText="Today"
  				/>
  			</ItemGrid>
  			<ItemGrid xs={12} sm={6} md={3} lg={3}>
  				<StatsCard
  					icon={Queue}
  					iconColor="blue"
  					title="Queue"
  					description={queue}
  					statIcon={DateRange}
  					statText={`Last ${total} Cases`}
  				/>
  			</ItemGrid>
  			<ItemGrid xs={12} sm={6} md={3} lg={3}>
  				<StatsCard
  					icon={DirectionsRun}
  					iconColor="orange"
  					title="Running"
  					description={running}
  					statIcon={DateRange}
  					statText={`Last ${total} Cases`}
  				/>
  			</ItemGrid>
  			<ItemGrid xs={12} sm={6} md={3} lg={3}>
  				<StatsCard
  					icon={InfoOutline}
  					iconColor="red"
  					title="Failed Cases"
  					description={failed}
  					statIcon={DateRange}
  					statText="Today"
  				/>
  			</ItemGrid>
  		</GridContainer>
  	)
  }
}

const mapStateToProps = (state, props) => {
	const { orgId } = props
	let { cases } = state
	if (orgId && state.cases[orgId]) {
		cases = state.cases[orgId]
	} else if (state.cases.data) {
		cases = state.cases
	}
	return {
		cases,
	}
}

export default connect(mapStateToProps)(WithOrgReport(Stats))