import React from "react";
import pt from "prop-types";
import { connect } from "react-redux";
import { getPatient } from "libs/apiLib";
import { WithNotifications } from "components/HigherOrder";

import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoadingModal from "components/LoadingModal/LoadingModal";

import Profile from "./Profile";
import Results from "./Results";
import Records from "./Records";
import SampleKits from "./SampleKits";

export class Patient extends React.Component {
  static propTypes = {
    getPatient: pt.func.isRequired,
    location: pt.shape({
      state: pt.shape({
        patient: pt.shape({})
      }),
      search: pt.string.isRequired,
    }),
    match: pt.shape({
      params: pt.shape({
        id: pt.string.isRequired
      }).isRequired
    }).isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    signalInfo: pt.func.isRequired,
    clearInfo: pt.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      patient: props.location && props.location.state && props.location.state.patient || null,
      isLoading: false,
    };
  }

  componentDidMount() {
    if (!this.state.patient) {
      this.getPatient();
    }
  }

  getPatient = async () => {
    const { getPatient, signalError, clearError, match: { params: { id } }, location: { search } } = this.props;
    const orgId = new URLSearchParams(search).get("orgId");
    this.setState({ isLoading: true });
    try {
      const response = await getPatient(id, orgId);
      if (response.success && response.patient) {
        this.setState({ patient: response.patient });
      }
    } catch (e) {
      signalError("Error getting patient. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  render() {
    const { patient } = this.state;
    if (!patient) {
      return <LoadingModal />;
    }
    const { signalError, clearError, signalInfo, clearInfo, match: { params: { id } }, location: { search } } = this.props;
    const orgId = new URLSearchParams(search).get("orgId");
    return (
      <GridContainer>
        <ItemGrid xs={12}>
          <Profile patient={patient} orgId={orgId} signalError={signalError} clearError={clearError} onPatientUpdate={patient => this.setState({ patient })} refreshResults={() => this.resultsComp.fullRefresh()} />
        </ItemGrid>
        <ItemGrid xs={12}>
          <Results onRef={ref => (this.resultsComp = ref)} id={id} orgId={orgId} patient={patient} signalError={signalError} clearError={clearError} />
        </ItemGrid>
        <ItemGrid xs={12}>
          <Records id={id} orgId={orgId} signalError={signalError} clearError={clearError} signalInfo={signalInfo} clearInfo={clearInfo} />
        </ItemGrid>
        <ItemGrid xs={12}>
          <SampleKits id={id} patient={patient} orgId={orgId} signalError={signalError} clearError={clearError} signalInfo={signalInfo} clearInfo={clearInfo} />
        </ItemGrid>
      </GridContainer>
    );
  }
}

export default connect(undefined, () => ({
  getPatient: (id, orgId) => getPatient(id, orgId),
}))(WithNotifications(Patient));