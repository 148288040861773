import React from "react";
import PropTypes from "prop-types";
import IconCard from "components/Cards/IconCard.jsx";
import Reports from "models/Reports";
import { Pie } from "components/Charts";

// Styles
import Computer from "material-ui-icons/Computer";

export const ModalityUsageContent = ({ report }) => {
  let data;
  let legend;
  if (report) {
    const modalities = report.modalityStatsBy("modality");
    const IDs = Object.keys(modalities);
    legend = IDs;
    data = IDs.map(modality => ({ value: modalities[modality].successful + modalities[modality].failures }));
  }
  return (
    <Pie
      data={data}
      noDataRender={() => <h3><small>No report</small></h3>}
      emptyDataRender={() => <h3><small>No modalities reported for this date range</small></h3>}
      legend={legend}
      legendTitle="Legend"
    />
  );
};

ModalityUsageContent.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export const ModalityUsage = ({ report }) => (
  <IconCard
    iconColor="orange"
    icon={Computer}
    title="Modality Usage"
    content={<ModalityUsageContent report={report} />}
  />
);

ModalityUsage.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export default ModalityUsage;