import React from "react";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// libs
import { isValidEmail } from 'libs/utils';

class InviteOrg extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            orgName: ''
        }
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleConfirm = (e) => {
        e.preventDefault();
        if(this.validateForm())
            this.props.onInvite(this.state.email, this.state.orgName);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.id === "email" ? event.target.value.toLowerCase() : event.target.value
        });
    }

    validateForm() {
        return (isValidEmail(this.state.email) &&
                this.state.orgName.length > 0);
    }

    render() {
        return (
            <SweetAlert
                style={{ display: "block" }}
                title="Invite an Organization"
                onConfirm={() => this.handleConfirm()}
                onCancel={() => this.handleCancel()}
                showConfirm={false}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
            >
                <div>
                    <form style={{ paddingLeft: "20px", paddingRight: "20px"}} onSubmit={this.handleConfirm}>
                        <CustomInput
                            labelText="Email of account owner"
                            id="email"
                            error={!isValidEmail(this.state.email)}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                id: "email",
                                value: this.state.email,
                                onChange: this.handleChange
                            }}
                        />
                        <CustomInput
                            labelText="Organization"
                            id="orgName"
                            error={this.state.orgName.length === 0}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                id: "orgName",
                                value: this.state.orgName,
                                onChange: this.handleChange
                            }}
                        />
                        <div style={{ marginTop: "40px"}}>
                            <Button color="danger" style={{ marginRight: "10px"}}
                                    onClick={this.handleCancel}>Cancel</Button>
                            <Button color="success"
                                    type="submit"
                                    onClick={this.handleConfirm}
                                   disabled={!this.validateForm()}>Invite</Button>
                        </div>
                    </form>
                </div>
            </SweetAlert>
        )
    }
}

export default withStyles(sweetAlertStyle)(InviteOrg);