import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Card from "material-ui/Card";

import { card } from "assets/jss/material-dashboard-pro-react";

function PlainCard({ ...props }) {
  const {
    classes,
    children,
    customClasses,
    style,
  } = props;
  const cardClasses =
    classes.card + " " +
    classes.base + 
    cx({
    	[" " + customClasses]: customClasses !== undefined
    });
  return (
    <Card className={cardClasses} style={style}>
      {children}
    </Card>
  );
}

PlainCard.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  customClasses: PropTypes.string,
};

export default withStyles({
  card: {
    ...card,
    padding: "15px 20px !important",
    position: "relative"
  },
  base: {
    textAlign: "center",
    color: "#FFFFFF",
    background: "linear-gradient(to right, #0fb8ad, #1fc8db)",
  }
})(PlainCard);
