import LoginPage from "views/Pages/LoginPage.jsx"
import RegisterPage from "views/Pages/RegisterPage.jsx"
import ForgotPasswordPage from "views/Pages/ForgotPasswordPage.jsx"
import ConfirmationPage from "views/Pages/ConfirmationPage.jsx"

// material-ui-icons
import PersonAdd from "material-ui-icons/PersonAdd"
import Fingerprint from "material-ui-icons/Fingerprint"

const pagesRoutes = [
	{
		path: "/pages/register",
		name: "Register",
		short: "Register",
		mini: "RP",
		icon: PersonAdd,
		component: RegisterPage
	},
	{
		path: "/pages/login",
		name: "Login",
		short: "Login",
		mini: "LP",
		icon: Fingerprint,
		component: LoginPage
	},
	{
		path: "/pages/forgot-password",
		component: ForgotPasswordPage,
		hide: true
	},
	{
		path: "/pages/confirm",
		component: ConfirmationPage,
		hide: true
	}
]

export default pagesRoutes
