import React from "react";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import { CircularProgress } from 'material-ui/Progress';

// styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

class LoadingModal extends React.Component {

    render() {
        return (
            <SweetAlert
                closeOnClickOutside={false}
                style={{ display: "block", marginTop: "-100px" }}
                title="Please wait..."
                onConfirm={() => { }}
                showConfirm={false}
            >
                <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />
            </SweetAlert>
        )
    }
}

export default withStyles(sweetAlertStyle)(LoadingModal);