import React from "react";
import pt from "prop-types";

import IconCard from "components/Cards/IconCard";
import Select from "components/Select";
import SearchIcon from "material-ui-icons/Search";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/IconButton.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import { debounce } from "libs/utils";

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: props.query,
      dimension: props.dimension,
    };
    if (props.debounce) {
      this.inputOnChange = debounce(props.inputOnChange, props.debounce);
      this.selectOnChange = debounce(props.selectOnChange, props.debounce);
    } else {
      this.inputOnChange = props.inputOnChange;
      this.selectOnChange = props.selectOnChange;
    }
  }

  handleInputChange = (e) => {
    this.setState({ query: e.target.value }, () => this.inputOnChange(this.state.query));
  }
  handleSelectChange = (e) => {
    this.setState({ dimension: e.target.value }, () => this.selectOnChange(this.state.dimension));
  }

  render() {
    const {
      onSubmit, submitting,
    } = this.props;
    const { query, dimension } = this.state;
    return (
      <IconCard
          icon={SearchIcon}
          title="Search"
          iconColor="rose"
          content={
            <GridContainer>
              <ItemGrid xs={3}>
                <Select
                  inputProps={{
                    name: "dimension",
                    onChange: this.handleSelectChange,
                    value: dimension,
                    disabled: submitting,
                  }}
                  values={[
                    { display: "Patient ID", value: "patientId" },
                    // { display: "First Name", value: "firstName" },
                    // { display: "Last Name", value: "lastName" }
                  ]}
                  formControlProps={{
                    fullWidth: true,
                    style: {
                      marginTop: 16
                    }
                  }}
                />
              </ItemGrid>
              <ItemGrid xs={8}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: this.handleInputChange,
                    value: query,
                  }}
                />
              </ItemGrid>
              <ItemGrid xs={1} container justify="flex-end" alignItems="center">
                <Button
                  color="defaultNoBackground"
                  aria-label="search"
                  disabled={submitting || !query.length}
                  onClick={onSubmit}
                  style={{
                    margin: "inherit"
                  }}
                >
                  <SearchIcon />
                </Button>
              </ItemGrid>
            </GridContainer>}
          />
    );
  }
}

SearchBar.propTypes = {
  submitting: pt.bool.isRequired,
  query: pt.string.isRequired,
  dimension: pt.string.isRequired,
  selectOnChange: pt.func.isRequired,
  inputOnChange: pt.func.isRequired,
  onSubmit: pt.func.isRequired,
  debounce: pt.number,
};

export default SearchBar;