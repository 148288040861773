import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import IconCard from "components/Cards/IconCard";
import People from "material-ui-icons/Wc";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";
import DropDown from "components/CustomDropdown/CustomDropdown";

export class OrgPatientStats extends React.Component {
  static propTypes = {
    report: PropTypes.arrayOf(PropTypes.shape({
      orgId: PropTypes.string.isRequired,
      processedOn: PropTypes.string.isRequired,
      events: PropTypes.shape({
        // patientId: PropTypes.string.isRequired,
        // status: PropTypes.string.isRequired,
        // eventId: PropTypes.string.isRequired,
        // appId: PropTypes.string.isRequired,
        // appName: PropTypes.string.isRequired,
      }).isRequired,
    }))
  };

  state = {
    category: "day"
  }

  total = () => {
    const { report } = this.props;
    return report ? report.reduce((sum, day) => {
      return sum + Object.keys(day.events).length;
    }, 0) : 0;
  }

  /**
   * Returns data categorized by each patient
   * Each event within each patient is categorized by eventId
   */
  patientBreakdown = () => {
    const { report } = this.props;
    return !report ? undefined : report.reduce((obj, dayEvents) => {
      const newObj = { ...obj };
      for (const eventId in dayEvents.events) {
        if (!dayEvents.events.hasOwnProperty(eventId)) {
          continue;
        }
        const stat = dayEvents.events[eventId];
        newObj[stat.patientId] = [
          ...(newObj[stat.patientId] || []),
          stat
        ];
      }
      return newObj;
    }, {});
  }

  /**
   * Returns data categorized by each day
   */
  dayBreakdown = () => {
    const { report } = this.props;
    return !report ? undefined : report.reduce((obj, dayEvents) => {
      const newObj = { ...obj };
      for (const eventId in dayEvents.events) {
        if (!dayEvents.events.hasOwnProperty(eventId)) {
          continue;
        }
        const stat = dayEvents.events[eventId];
        newObj[dayEvents.processedOn] = [
          ...(newObj[dayEvents.processedOn] || []),
          stat
        ];
      }
      return newObj;
    }, {});
  }

  /**
   * Returns data categorized by each day
   * Each day categorizes events under each patient
   */
  dayAndPatientBreakdown = () => {
    const byDay = this.dayBreakdown();
    return !byDay ? undefined : Object.keys(byDay).reduce((obj, day) => {
      const newObj = { ...obj };
      const dayStats = byDay[day];
      for (const stat of dayStats) {
        const prevDay = newObj[day] || {};
        newObj[day] = {
          ...prevDay,
          [stat.patientId]: [
            ...(prevDay[stat.patientId] || []),
            stat,
          ]
        };
      }

      return newObj;
    }, {});
  }

  series = (fromData) => {
    if (!fromData) {
      return [];
    }
    const { category } = this.state;
    const isByDay = category === "day";
    return Object.keys(fromData).map(key => ({
      [isByDay ? "processedOn" : "patientId"]: isByDay ? moment(key).format("MM-DD-YYYY") : key,
      total: isByDay ? Object.keys(fromData[key]).length : fromData[key].length,
    }));
  }

  formatCell = (key) => (cell) => {
    switch (key) {
    case "patientId":
      return <Cell label="Patient" value={cell.original.patientId} />;
    case "processedOn":
      return <Cell label="Date" value={moment(cell.original.processedOn, "YYYYMMDD").format("MM-DD-YYYY")} />;
    case "status":
      return <Cell label="Result" value={cell.original.status === "success" ? "Successful" : "Failed"} />;
    case "appName":
      return <Cell label="Application" value={cell.original.appName} />;
    case "id":
      return <Cell label="ID" value={cell.original.eventId} />;
    default:
      return null;
    }
  }

  render() {
    const { category } = this.state;
    const isByDay = category === "day";
    const byPatient = this.patientBreakdown();
    const byDayPatient = this.dayAndPatientBreakdown();
    const data = isByDay ? byDayPatient : byPatient;
    const subColumns = isByDay ?
      [
        { accessor: "patientId" },
        { accessor: "appName" },
        { accessor: "id" },
        { accessor: "status", maxWidth: 100 },
      ] :
      [
        { accessor: "processedOn", maxWidth: 200 },
        { accessor: "appName" },
        { accessor: "id" },
        { accessor: "status", maxWidth: 100 },
      ];
    return (
      <IconCard
        iconColor="orange"
        icon={People}
        title="Patient Insights"
        content={(
          <GridContainer>
            <ItemGrid xs={12} container justify="flex-end">
              <ItemGrid xs={12} md={10}>
                <p>View all applications run on each patient within the time frame selected above. Sort and filter by date or patient by selecting the appropriate item in the dropdown to the right. Click on the arrow next to each row to view more information.</p>
              </ItemGrid>
              <ItemGrid container xs={12} md={2} justify="flex-end">
                <DropDown
                  buttonColor="primary"
                  buttonText={isByDay ? "Date" : "Patient"}
                  onAction={(category) => this.setState({ category: category === "Date" ? "day" : "patient" })}
                  dropdownList={["Date", "Patient"]}
                />
              </ItemGrid>
            </ItemGrid>
            <ItemGrid xs={12}>
              <ReactTable
                sortable={false}
                resizable={false}
                data={this.series(data)}
                noDataText="No Patient Insights"
                columns={
                  [
                    { header: isByDay ? "Date" : "Patient", accessor: isByDay ? "processedOn" : "patientId" },
                    { header: `Total: ${this.total()}`, accessor: "total", maxWidth: 150, style: { textAlign: "right" } },
                  ].map(c => ({
                    Header: c.header,
                    accessor: c.accessor,
                    maxWidth: c.maxWidth,
                    style: c.style,
                  }))
                }
                SubComponent={row => {
                  const index = isByDay ? moment(row.original.processedOn).format("YYYYMMDD") : row.original.patientId;
                  const subData = !isByDay ? data[index] : Object.keys(data[index]).reduce((arr, patientId) => {
                    return [
                      ...arr,
                      ...data[index][patientId]
                    ];
                  }, []);
                  return (
                    <ReactTable
                      sortable={false}
                      resizable={false}
                      data={subData}
                      minRows={0}
                      showPagination={false}
                      columns={
                        subColumns.map(c => ({
                          Header: "",
                          Cell: this.formatCell(c.accessor),
                          maxWidth: c.maxWidth
                        }))
                      }
                    />
                  );
                }}
                showPaginationBottom
                className="-highlight"
                pageSize={10}
              />
            </ItemGrid>
          </GridContainer>
        )}
      />
    );
  }
}

export default OrgPatientStats;