import {
  STORE_CASES,
  REFRESH_CASES,
  PAGE_CHANGE_CASES,
  STORE_CONSUMER_ORG_CASES,
  REFRESH_CONSUMER_ORG_CASES,
  PAGE_CHANGE_CONSUMER_ORG_CASES
} from "actions/ActionTypes";

export const initialState = {
  data: null,
  total: 0,
  key: null,
  page: 0,
  allPagesFulfilled: false,
  consumerOrgs: {
    data: undefined,
    key: null,
    page: 0,
    allPagesFulfilled: false
  },
};

export const getContext = (state, action) => action.orgID ? state[action.orgID] : state;

export const startIndexFromKey = (state, action) => {
  if (!state.key || !action.cases) {
    return -1;
  }
  let i = state.key.findIndex(k => k === action.key);
  if (i === -1 || i === 0) {
    return i;
  }
  return (i * action.cases.length);
};

export default (state = initialState, action) => {
  switch (action.type) {
  case STORE_CASES:
  {
    const context = getContext(state, action);
    const data = context && context.data ? [...context.data, ...action.cases] : action.cases;
    const total = context && context.total ? context.total + action.total : action.total;
    let key = context && context.key ? context.key : initialState.key;
    let allPagesFulfilled = false;
    if (action.key && context && context.key) {
      key = [...context.key, action.key];
    } else if (action.key && (!context || !context.key)) {
      key = ["", action.key];
    } else if (!action.key) {
      allPagesFulfilled = true;
    }

    if (action.orgID) {
      return { ...state, [action.orgID]: { ...context, data, total, key, allPagesFulfilled } };
    }
    return { ...state, data, total, key, allPagesFulfilled };
  }

  case STORE_CONSUMER_ORG_CASES:
  {
    const context = getContext(state, action);
    const data = context && context.consumerOrgs && context.consumerOrgs.data ? [...context.consumerOrgs.data, ...action.cases] : action.cases;
    let key = context && context.consumerOrgs && context.consumerOrgs.key ? context.consumerOrgs.key : initialState.consumerOrgs.key;
    let allPagesFulfilled = false;
    if (action.key && context && context.consumerOrgs && context.consumerOrgs.key) {
      key = [...context.consumerOrgs.key, action.key];
    } else if (action.key && (!context || !context.consumerOrgs || !context.consumerOrgs.key)) {
      key = ["", action.key];
    } else if (!action.key) {
      allPagesFulfilled = true;
    }

    if (action.orgID) {
      return { ...state, [action.orgID]: { ...context, consumerOrgs: { data, key, allPagesFulfilled } } };
    }
    return { ...state, consumerOrgs: { data, key, allPagesFulfilled } };
  }
  case REFRESH_CASES:
  {
    const context = getContext(state, action);
    const i = startIndexFromKey(context, action);
    if (i === -1) {
      return { ...state };
    }

    const data = [
      ...context.data.slice(0, i),
      ...action.cases,
      ...context.data.slice(i + action.cases.length)
    ];
    if (action.orgID) {
      return { ...state, [action.orgID]: { ...context, data } };
    }
    return { ...state, data };
  }
  case REFRESH_CONSUMER_ORG_CASES:
  {
    const context = getContext(state, action);
    const i = startIndexFromKey(context.consumerOrgs, action);
    if (i === -1) {
      return { ...state };
    }

    const data = [
      ...context.consumerOrgs.data.slice(0, i),
      ...action.cases,
      ...context.consumerOrgs.data.slice(i + action.cases.length)
    ];
    if (action.orgID) {
      return { ...state, [action.orgID]: { ...context, consumerOrgs: { ...context.consumerOrgs, data } } };
    }
    return { ...state, consumerOrgs: { ...context.consumerOrgs, data } };
  }
  case PAGE_CHANGE_CASES:
  {
    const context = getContext(state, action);

    if (!context || !context.data) {
      return { ...state };
    }

    if (action.page < 0 || (Math.ceil(context.data.length / 10) < action.page)) {
      return { ...state };
    }

    if (action.orgID) {
      return { ...state, [action.orgID]: { ...context, page: action.page } };
    }
    return {
      ...state,
      page: action.page
    };
  }
  case PAGE_CHANGE_CONSUMER_ORG_CASES:
  {
    const context = getContext(state, action);

    if (!context || !context.consumerOrgs || !context.consumerOrgs.data) {
      return { ...state };
    }

    if (action.page < 0 || (Math.ceil(context.consumerOrgs.data.length / 10) < action.page)) {
      return { ...state };
    }

    if (action.orgID) {
      return { ...state, [action.orgID]: { ...context, consumerOrgs: { ...context.consumerOrgs, page: action.page } } };
    }
    return {
      ...state,
      consumerOrgs: {
        ...state.consumerOrgs,
        page: action.page
      }
    };

  }
  default:
    return state;
  }
};