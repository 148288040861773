import {
  STORE_PATIENTS, STORE_ORG_PATIENTS,
  PAGE_CHANGE_PATIENTS, PAGE_CHANGE_ORG_PATIENTS,
  REMOVE_PATIENT, REMOVE_ORG_PATIENT,
} from "actions/ActionTypes";

const initialState = {
  page: 0,
  key: null,
  data: [],
  orgs: {},
};

const reducer = (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  const key = action.patientIdKey && action.orgIdKey && {
    patientIdKey: action.patientIdKey,
    orgIdKey: action.orgIdKey,
  };
  switch (action.type) {
  case STORE_PATIENTS:
    return {
      ...state,
      data: !state.data.length ? action.patients : [...state.data, ...action.patients],
      key: key && !state.key ? [key] : key ? [...state.key, key] : state.key,
    };
  case STORE_ORG_PATIENTS:
    return {
      ...state,
      orgs: {
        ...state.orgs,
        [action.orgId]: {
          ...(state.orgs[action.orgId] ? state.orgs[action.orgId] : {
            page: 0
          }),
          data: state.orgs[action.orgId] ? [...state.orgs[action.orgId].data, ...action.patients] : action.patients,
          key: key && state.orgs[action.orgId] ? [...state.orgs[action.orgId].key, key] : key ? [key] : null,
        }
      }
    };
  case PAGE_CHANGE_PATIENTS:
    return {
      ...state,
      page: action.page
    };
  case PAGE_CHANGE_ORG_PATIENTS:
    return {
      ...state,
      orgs: {
        ...state.orgs,
        [action.orgId]: {
          ...state.orgs[action.orgId],
          page: action.page,
        }
      }
    };
  case REMOVE_PATIENT:
    return {
      ...state,
      data: state.data.filter(p => p.patientId !== action.patientId)
    };
  case REMOVE_ORG_PATIENT:
    return {
      ...state,
      orgs: {
        ...state.orgs,
        [action.orgId]: {
          ...state.orgs[action.orgId],
          data: state.orgs[action.orgId].data.filter(p => p.patientId !== action.patientId),
        }
      }
    };
  default:
    return state;
  }
};

export default reducer;