import React, { Component } from "react";
import pt from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard.jsx";
import Button from "components/CustomButtons/Button";
import DatePickerButton from "components/DatePickerButtons/DatePickerButton";
import Dropdown from "components/CustomDropdown/CustomDropdown";
import Input from "components/CustomInput/CustomInput";
import { WithNotifications } from "components/HigherOrder";

import { createInvoice } from "libs/apiLib";

export class Create extends Component {
  static propTypes = {
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    start: pt.instanceOf(moment),
    end: pt.instanceOf(moment),
    createInvoice: pt.func.isRequired,
    history: pt.shape({
      push: pt.func.isRequired,
    }).isRequired,
    location: pt.shape({
      search: pt.string.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      start: props.start || moment(),
      end: props.end || moment().add("30", "d"),
      orgId: new URLSearchParams(props.location.search).get("orgId"),
      submitting: false,
      paymentTerms: "Net 90 Days",
      poNumber: "",
    };
  }

  componentDidMount() {
    if (!this.state.orgId) {
      this.props.signalError("No \"orgId\" exists within the URL. Please make sure you're navigating to this page from the invoice page");
    }
  }

  handleSubmit = async () => {
    const { start, end, paymentTerms, poNumber, orgId } = this.state;
    const { createInvoice, signalError, clearError, history, location } = this.props;
    this.setState({ submitting: true });
    try {
      const results = await createInvoice({ orgId, billingStart: start.toISOString(), billingEnd: end.toISOString(), paymentTerms, poNumber: poNumber.length && poNumber });
      if (results.success && results.invoice) {
        const search = new URLSearchParams(location.search);
        search.set("id", results.invoice.invoiceNumber);
        history.push({
          pathname: "/admin/invoices/edit",
          state: {
            invoice: results.invoice,
          },
          search: search.toString(),
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error creating invoice. Please try again later.");
      setTimeout(clearError, 5000);
      this.setState({ submitting: false });
    }
  }

  render() {
    const { start, end, poNumber, paymentTerms } = this.state;
    return (
      <GridContainer>
        <ItemGrid xs={12}>
          <HeaderCard
            headerColor="green"
            cardTitle="Create Invoice"
            content={
              <GridContainer>
                <ItemGrid xs={12}>
                  <h3>Billing Cycle</h3>
                  <DatePickerButton
                    inline
                    closeOnSelect
                    label="Start"
                    disableFutureDays={false}
                    justify="flex-start"
                    color="primary"
                    start={start}
                    defaultValue={start}
                    viewDate={start}
                    onChange={start => this.setState({ start })}
                    preventSelectionPast={end}
                  />
                  <DatePickerButton
                    inline
                    closeOnSelect
                    label="End"
                    disableFutureDays={false}
                    justify="flex-start"
                    color="primary"
                    start={end}
                    defaultValue={end}
                    viewDate={end}
                    onChange={end => this.setState({ end })}
                  />
                </ItemGrid>
                <ItemGrid xs={12}>
                  <h3>Options</h3>
                </ItemGrid>
                <ItemGrid xs={12} style={{ marginBottom: "30px" }}>
                  <p>Payment Term</p>
                  <Dropdown
                    buttonColor="primary"
                    buttonText={paymentTerms}
                    dropdownList={[
                      "Net 30 Days",
                      "Net 60 Days",
                      "Net 90 Days",
                    ]}
                    onAction={(paymentTerms) => this.setState({ paymentTerms })}
                  />
                </ItemGrid>
                <ItemGrid xs={12}>
                  <p>Customer PO Number</p>
                  <Input
                    labelText="Enter Number"
                    inputProps={{
                      name: "poNumber",
                      value: poNumber,
                      onChange: (e) => this.setState({ poNumber: e.target.value })
                    }}
                  />
                </ItemGrid>
                <ItemGrid xs={12}><Button right color="primary" onClick={this.handleSubmit}>Submit</Button></ItemGrid>
              </GridContainer>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

export default connect(undefined, () => ({
  createInvoice: (args) => createInvoice(args),
}))(withRouter(WithNotifications(Create)));