export default class Job {
  static prefix = "*"
  static anonyimizers = {
    AccessionNumber: Job.lastFour,
    PatientID: Job.makeAnonymizedTag,
    PatientsName: Job.makeAnonymizedTag,
    PatientName: Job.makeAnonymizedTag,
    PatientsSex: Job.makeAnonymizedTag,
    PatientSex: Job.makeAnonymizedTag,
    PatientsAge: Job.makeAnonymizedTag,
    PatientAge: Job.makeAnonymizedTag,
    PatientsBirthDate: Job.makeAnonymizedTag,
    PatientBirthDate: Job.makeAnonymizedTag,
    PatientsWeight: Job.makeAnonymizedTag,
    PatientWeight: Job.makeAnonymizedTag
  }

  static shouldAnonymize(tag) {
    return Job.anonyimizers[tag] !== undefined;
  }

  static anonymize(data) {
    if (Job.isNotObject(data)) {
      return data;
    }
    const result = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (Job.shouldAnonymize(key)) {
          if (value === undefined || value === null) {
            result[key] = Job.makeAnonymizedTag({ tag: key })
            continue
          }
          const anonymizer = Job.anonyimizers[key];
          result[key] = anonymizer({ value, tag: key });
          continue;
        }
        if (Job.isNotObject(value)) {
          result[key] = value;
          continue;
        }
        result[key] = Job.anonymize(value);
      }
    }

    return result;
  }

  static isNotObject(data) {
    return data === null ||
    data === undefined ||
    ["string", "number", "boolean"].some(t => typeof data === t) ||
    Array.isArray(data);
  }

  static lastFour({ value, tag }) {
    if (!value || value.length === 0) {
      return Job.makeAnonymizedTag({ tag });
    }
    if (value.length <= 4) {
      return `${Job.prefix}${value.slice(Math.ceil(value.length / 2))}`;
    }
    return `${Job.prefix}${value.slice(-4)}`;
  }

  static makeAnonymizedTag({ tag }) {
    return `${Job.prefix}${tag}`;
  }
}