import React from "react";
import moment from "moment";
import pt from "prop-types";
import { connect } from "react-redux";
import PlainCard from "components/Cards/PlainCard";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import SuccessModal from "components/SuccessModal";
import ErrorModal from "components/ErrorModal";
import withStyles from "material-ui/styles/withStyles";
import Button from "components/CustomButtons/Button";
import AppWizard from "./AppWizard";
import { updatePatient, mergePatients, deletePatient } from "libs/apiLib";
import { removePatient } from "actions";
import Dropdown from "components/CustomDropdown/CustomDropdown";
import Modal from "components/Modal";
import { CircularProgress } from "material-ui/Progress";
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";
import Pagination from "components/Pagination/ReactTablePagination";
import Radio from "components/Radio";
import { WithPatients } from "components/HigherOrder";
import PatientEditModal from "components/Modal/PatientEdit";

export let Label = ({ children, classes: { base }, className, ...rest }) => <p {...rest} className={base + " " + className}>{children}</p>;
Label.styles = {
  base: {
    color: "grey",
    marginTop: 0
  }
};
Label.propTypes = {
  classes: pt.shape({
    base: pt.string.isRequired,
  }).isRequired,
  children: pt.node.isRequired,
};

Label = withStyles(Label.styles)(Label);

export class Profile extends React.Component {
  static patientMergePageLength = 30
  static styles = {
    inputText: {
      fontSize: 14,
      color: "#555",
      fontWeight: 400,
    },
    label: {
      color: "#AAA",
      fontWeight: 400,
      lineHeight: 1.42857,
      fontSize: 11,
    },
    icon: {
      color: "grey",
      cursor: "pointer",
    },
    zeroTopMargin: {
      marginTop: 0
    },
    zeroBottomMargin: {
      marginBottom: 0
    },
    gridBottomMargin: {
      marginBottom: 30
    },
    gridBox: {
      marginTop: 30,
      marginBottom: 30,
      paddingLeft: 30,
      paddingRight: 30
    },
    wideHorizontalPadding: {
      paddingLeft: 40,
      paddingRight: 40
    }
  }

  static propTypes = {
    day: pt.instanceOf(moment),
    patient: pt.shape({
      firstName: pt.string,
      lastName: pt.string,
      dob: pt.string,
      sex: pt.string,
      weight: pt.number,
      height: pt.number,
      avlt: pt.number,
      mmse: pt.number,
    }),
    user: pt.shape({
      admin: pt.bool,
      supportEngineer: pt.bool,
      superAdmin: pt.bool,
    }),
    classes: pt.shape({
      inputText: pt.string.isRequired,
      label: pt.string.isRequired,
      icon: pt.string.isRequired,
      zeroTopMargin: pt.string.isRequired,
      zeroBottomMargin: pt.string.isRequired,
      gridBottomMargin: pt.string.isRequired,
      gridBox: pt.string.isRequired,
      wideHorizontalPadding: pt.string.isRequired,
    }).isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    updatePatient: pt.func.isRequired,
    orgId: pt.string,
    onPatientUpdate: pt.func.isRequired,
    mergePatients: pt.func.isRequired,
    deletePatient: pt.func.isRequired,
    removePatientFromStore: pt.func.isRequired,
    location: pt.shape({
      search: pt.string
    }).isRequired,
    history: pt.shape({
      push: pt.func.isRequired,
    }).isRequired,

    // from WithPatients HOC
    loading: pt.bool.isRequired,
    allPagesFulfilled: pt.bool.isRequired,
    getPatients: pt.func.isRequired,
    page: pt.number.isRequired,
    patients: pt.shape({
      page: pt.number.isRequired,
      key: pt.arrayOf(pt.oneOfType([pt.shape({}), pt.shape({ patientIdKey: pt.string.isRequired, orgIdKey: pt.string.isRequired })])),
      data: pt.arrayOf(pt.shape({})).isRequired,
      orgs: pt.shape({}).isRequired
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.day = props.day || moment();
    this.state = {
      loading: false,
      patient: props.patient || null,
      modal: null,
      modalState: null,
    };
  }

  componentDidUpdate() {
    if (!this.state.patient && this.props.patient) {
      this.setState({ patient: this.props.patient });
    }
  }

  handleSubmit = async (patient) => {
    const { onPatientUpdate, updatePatient, signalError, clearError, orgId } = this.props;
    this.setState({ loading: true });
    try {
      const response = await updatePatient(patient, orgId);
      if (response.success && response.patient) {
        this.setState({
          patient: response.patient
        });
        onPatientUpdate(response.patient);
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error updating patient. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false, modal: null });
  }

  sexLabel = (sex) => {
    switch (sex) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    case "O":
      return "Intersex";
    default:
      return "";
    }
  }

  patientName = () => {
    const { patient } = this.state;
    if (patient.lastName && patient.firstName && patient.lastName.length && patient.firstName.length) {
      return `${patient.lastName}, ${patient.firstName}`;
    } else if (!patient.firstName && patient.lastName && patient.lastName.length) {
      return patient.lastName;
    } else if (!patient.lastName && patient.firstName && patient.firstName.length) {
      return patient.firstName;
    } else {
      return "";
    }
  }

  patientDOB = () => {
    const { patient } = this.state;
    if (!patient.dob || !moment(new Date(patient.dob).toUTCString()).isValid()) {
      return "";
    }
    return moment(new Date(patient.dob).toUTCString()).format("MM-DD-YYYY");
  }

  patientAge = () => {
    const { patient } = this.state;
    if (!patient.dob || !moment(new Date(patient.dob).toUTCString()).isValid()) {
      return "";
    }
    return Math.abs(moment(new Date(patient.dob).toUTCString()).diff(this.day, "years")) + "";
  }

  onOptionsClick = (action) => {
    switch (action) {
    case "Edit":
      return this.setState({ modal: "editingPatient" });
    case "Merge With Another Patient":
      return this.setState({ modal: "mergingPatient" });
    case "Delete":
      return this.setState({ modal: "deletePatient" });
    default:
      return;
    }
  }

  handleMergeSubmit = async () => {
    const { modalState, patient } = this.state;
    const { signalError, clearError, mergePatients, location: { search }, history: { push } } = this.props;
    if (!modalState || !modalState.patientId) {
      return;
    }
    const orgId = new URLSearchParams(search).get("orgId");
    try {
      await mergePatients(modalState.patientId, patient.patientId, orgId);
      push({
        pathname: "/patients",
        search,
      });
    } catch (error) {
      signalError(`Error merging patient "${modalState.patientId}" with "${patient.patientId}". Please try again later.`);
      setTimeout(clearError, 5000);
    }
  }

  onPatientSelect = (patientId) => this.setState(prevState => ({
    ...prevState,
    modalState: {
      ...prevState.modalState,
      patientId
    }
  }))

  formatCell = (key) => (cell) => {
    switch (key) {
    case "firstName":
      return <Cell label="First Name" value={cell.value || ""} />;
    case "lastName":
      return <Cell label="Last Name" value={cell.value || ""} />;
    case "patientId":
      return <Cell label="Patient ID" value={cell.value} />;
    case "select":
      return (
        <GridContainer justify="center">
          <Radio
            onClick={() => this.onPatientSelect(cell.original.patientId)}
            checked={this.state.modalState ? this.state.modalState.patientId === cell.original.patientId : false}
          />
        </GridContainer>
      );
    default:
      return null;
    }
  };

  patientsWithoutCurrentPatient = () => {
    return this.props.patients && this.props.patients.data.filter(p => p.patientId !== this.state.patient.patientId) || [];
  }

  changePage = (page) => {
    this.setState(prevState => ({ ...prevState, modalState: { ...prevState.modalState, page } }));
  }

  renderPatientMergeModal = () => {
    const { loading, modalState } = this.state;
    let content = (
      <ItemGrid container xs={12} justify="center" alignItems="center">
        <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />;
      </ItemGrid>
    );
    if (!loading && !this.props.loading) {
      const p = this.state.modalState && this.state.modalState.page !== undefined ? this.state.modalState.page : this.props.page;
      content = (
        <ItemGrid xs={12}>
          <h6><b>Instructions</b></h6>
          <p>{"Select the target patient to merge from. Please note that this will merge the selected patient into the source patient you're currently viewing. The patient you select will be deleted."}</p>
          <ReactTable
            data={this.patientsWithoutCurrentPatient()}
            noDataText="No Patients"
            columns={
              [
                { accessor: "lastName" },
                { accessor: "firstName" },
                { accessor: "patientId" },
                { accessor: "select", maxWidth: 100 }
              ].map(c => ({ Header: "", maxWidth: c.maxWidth, Cell: this.formatCell(c.accessor), accessor: c.accessor }))
            }
            filterable
            showPaginationBottom
            loading={loading || this.props.loading}
            className="-highlight"
            defaultPageSize={Profile.patientMergePageLength}
            minRows={5}
            page={p}
            refreshing={loading || this.props.loading}
            PaginationComponent={Pagination}
            canGetMorePages
            onPageChange={this.changePage}
            onGetMore={this.props.getPatients}
            allPagesFulfilled={this.props.allPagesFulfilled}
          />
        </ItemGrid>
      );
    }
    return (
      <Modal
        style={{
          width: "60%",
          height: "75%",
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer style={{ height: "100%" }} className={this.props.classes.wideHorizontalPadding}>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}><h2>Select Patient to Merge</h2></ItemGrid>
          {content}
          <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
            <Button style={{ marginRight: 15 }} onClick={() => this.setState({ modal: null })} disabled={loading || this.props.loading}>Cancel</Button>
            <Button color="success" onClick={this.handleMergeSubmit} disabled={loading || this.props.loading || !modalState || !modalState.patientId}>Merge</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }

  renderPatientDeleteModal = () => {
    const { loading } = this.state;
    let content = (
      <ItemGrid container xs={12} justify="center" alignItems="center">
        <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />;
      </ItemGrid>
    );
    if (!loading && !this.props.loading) {
      content = (
        <ItemGrid xs={12}>
          <h6><b>Details</b></h6>
          <p>{"Deleting a patient is irreversible. All application results and external records associated with this patient will also be deleted. Any genetic kits unused and assigned to this patient should be removed from this patient before deleting them. Do you still want to proceed?"}</p>
        </ItemGrid>
      );
    }
    return (
      <Modal
        style={{
          width: "40%",
          height: "50%",
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer style={{ height: "100%" }} className={this.props.classes.wideHorizontalPadding}>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}><h2>Delete Patient?</h2></ItemGrid>
          {content}
          <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
            <Button style={{ marginRight: 15 }} onClick={() => this.setState({ modal: null })} disabled={loading || this.props.loading}>Cancel</Button>
            <Button color="danger" onClick={this.handlePatientDelete} disabled={loading || this.props.loading}>Delete</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }

  renderPatientEditModal = () => {
    const { patient, loading } = this.state;
    return <PatientEditModal
      title="Edit Patient"
      loading={loading}
      submitButtonText="Save"
      patient={patient}
      onSubmit={this.handleSubmit}
      onClose={() => this.setState({ modal: null })}
    />;
  }

  handlePatientDelete = async () => {
    const { patient } = this.state;
    const { signalError, clearError, deletePatient, removePatientFromStore, location: { search }, history: { push } } = this.props;
    const orgId = new URLSearchParams(search).get("orgId");
    this.setState({ loading: true });
    try {
      await deletePatient(patient.patientId, orgId);
      removePatientFromStore(patient.patientId, orgId);
      push({
        pathname: "/patients",
        search,
      });
    } catch (error) {
      signalError("Error deleting patient. Please try again later.");
      setTimeout(clearError, 5000);
      this.setState({ loading: false, modal: null });
    }
  }

  renderModal = () => {
    const { modal, patient } = this.state;
    const { orgId } = this.props;
    switch (modal) {
    case "executingApp":
      return <AppWizard id={patient.patientId} patient={patient} orgId={orgId} onCancel={() => this.setState({ modal: null })} onSuccess={this.handleRunAppSuccess} onFailure={this.handleRunAppFailure} />;
    case "mergingPatient":
      return this.renderPatientMergeModal();
    case "runAppSuccess":
      return <SuccessModal title="Submitted" msg="Alzheimer's Integrated Risk Report submitted successfully." onClose={() => this.setState({ modal: null }, () => this.props.refreshResults())} />;
    case "runAppFailure":
      return <ErrorModal msg="Alzheimer's Integrated Risk Report failed to execute. Try again. If error continues, please email support@healthlytix.com for support." onClose={() => this.setState({ modal: null })} />;
    case "deletePatient":
      return this.renderPatientDeleteModal();
    case "editingPatient":
      return this.renderPatientEditModal();
    default:
      return null;
    }
  }

  handleRunAppSuccess = () => {
    this.setState({ modal: "runAppSuccess" });
  }

  handleRunAppFailure = () => {
    this.setState({ modal: "runAppFailure" });
  }

  handleRunApp = () => {
    this.setState({ modal: "executingApp" });
  }

  canEditUser = () => {
    const { user } = this.props;
    if (!user) {
      return false;
    }
    if (
      user.admin ||
      user.supportEngineer ||
      user.superAdmin
    ) {
      return true;
    }
    return false;
  }

  render() {
    if (!this.state.patient) {
      return null;
    }
    const { patient } = this.state;
    const { classes: { gridBox, zeroTopMargin, gridBottomMargin, zeroBottomMargin } } = this.props;
    return (
      <React.Fragment>
        {this.renderModal()}
        <PlainCard>
          <GridContainer className={gridBox}>
            <ItemGrid xs={12}>
              <GridContainer className={gridBottomMargin} direction="row-reverse">
                <ItemGrid xs={12} sm={12} container justify="flex-end">
                  <ItemGrid xs={12} container justify="flex-end">
                    <Button color="primary" onClick={this.handleRunApp}>
                      Run App
                    </Button>
                    {
                      this.canEditUser() &&
                      <Dropdown
                        buttonText="Options"
                        dropdownList={[
                          "Edit",
                          "Merge With Another Patient",
                          "Delete"
                        ]}
                        onAction={this.onOptionsClick}
                      />
                    }
                  </ItemGrid>
                </ItemGrid>
                <ItemGrid xs={12} sm={12}>
                  <h2 className={zeroBottomMargin + " " + zeroTopMargin}>{this.patientName()}</h2>
                  <Label>Full Name</Label>
                </ItemGrid>
              </GridContainer>
              <GridContainer>
                <ItemGrid xs={12}>
                  <GridContainer className={gridBottomMargin} justify="flex-start">
                    <ItemGrid xs={12} sm={6} md={2} lg={2}>
                      <h4 className={zeroBottomMargin}>{this.patientDOB()}</h4>
                      <Label>Date of Birth</Label>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={2} lg={2}>
                      <h4 className={zeroBottomMargin}>{this.patientAge()}</h4>
                      <Label>Age</Label>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={2} lg={2}>
                      <h4 className={zeroBottomMargin}>{this.sexLabel(patient.sex)}</h4>
                      <Label>Sex</Label>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={2} lg={2}>
                      <h4 className={zeroBottomMargin}>{patient.PatientID || ""}</h4>
                      <Label>Customer Patient ID</Label>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={2} lg={2}>
                      <h4 className={zeroBottomMargin}>{patient.height || ""}</h4>
                      <Label>Height (cm)</Label>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={2} lg={2}>
                      <h4 className={zeroBottomMargin}>{patient.weight || ""}</h4>
                      <Label>Weight (kg)</Label>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12}>
                  <GridContainer justify="flex-start">
                    <ItemGrid xs={12} sm={6} md={2} lg={2}>
                      <h4 className={zeroBottomMargin}>{patient.avlt || ""}</h4>
                      <Label>AVLT</Label>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={2} lg={2}>
                      <h4 className={zeroBottomMargin}>{patient.mmse || ""}</h4>
                      <Label>MMSE</Label>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
              </GridContainer>
            </ItemGrid>
          </GridContainer>
        </PlainCard>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  user: state.user.user
}), (dispatch) => ({
  removePatientFromStore: (pId, orgId) => dispatch(removePatient(pId, orgId)),
  updatePatient: (patient, orgId) => updatePatient(patient, orgId),
  mergePatients: (p1Id, p2Id, orgId) => mergePatients(p1Id, p2Id, orgId),
  deletePatient: (id, orgId) => deletePatient(id, orgId)
}))(WithPatients(withStyles(Profile.styles)(Profile), Profile.patientMergePageLength));