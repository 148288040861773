import React from "react";
import PropTypes from "prop-types";
import Chartist from "react-chartist";
import withStyles from "material-ui/styles/withStyles";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle";
import Legend from "./Legend";

const Bar = ({ classes, noXSeriesRender, emptyXSeriesRender, xSeries, legend, hideLegend, xLabels, legendTitle, options }) => {
  if (!xSeries) {
    return noXSeriesRender();
  }
  if (!xSeries.length) {
    return emptyXSeriesRender();
  }
  return (
    <React.Fragment>
      <Chartist
        className={classes.chartCenter + " ct-double-octave"}
        type="Bar"
        data={{
          labels: xLabels,
          series: xSeries,
        }}
        options={options}
        listener={{
          draw: (context) => {
            if (context.type === "bar" && context.meta && context.meta.style) {
              context.element.attr(context.meta);
            }
          }
        }}
      />
      <Legend title={legendTitle} legend={legend} hideLegend={hideLegend} />
    </React.Fragment>
  );
};

Bar.propTypes = {
  classes: PropTypes.shape({
    chartCenter: PropTypes.string.isRequired
  }).isRequired,
  noXSeriesRender: PropTypes.func.isRequired,
  emptyXSeriesRender: PropTypes.func.isRequired,
  legendTitle: PropTypes.string,
  legend: Legend.propTypes.legend,
  hideLegend: PropTypes.bool,
  xLabels: PropTypes.arrayOf(PropTypes.string),
  xSeries: PropTypes.arrayOf(PropTypes.any),
  options: PropTypes.shape({}),
};

export default withStyles(chartsStyle)(Bar);