import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import pt from "prop-types";
import { getPatients } from "libs/apiLib";
import { storePatients } from "actions";
import { WithNotifications } from "./index";
import { calculatePageIndex } from "libs/utils";

export const WithPatients = (WrappedComponent, pageLength = 10) => {
  class WrapperComponent extends React.Component {
    static propTypes = {
      signalError: pt.func.isRequired,
      clearError: pt.func.isRequired,
      id: pt.string.isRequired,
      orgId: pt.string,
      location: pt.shape({
        search: pt.string,
      }),
      getPatients: pt.func.isRequired,
      storePatients: pt.func.isRequired,
      patients: pt.shape({
        page: pt.number.isRequired,
        key: pt.arrayOf(pt.oneOfType([pt.shape({}), pt.shape({ patientIdKey: pt.string.isRequired, orgIdKey: pt.string.isRequired })])),
        data: pt.arrayOf(pt.shape({})).isRequired,
        orgs: pt.shape({}).isRequired
      }).isRequired,
    }

    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        allPagesFulfilled: false,
        page: 0,
      };
    }

    componentDidMount() {
      const patients = this.patientsContext();
      if (!patients.data.length) {
        this.getPatients();
      }
    }

    getPatients = async () => {
      const { getPatients, storePatients, location: { search }, signalError, clearError } = this.props;
      const orgId = new URLSearchParams(search).get("orgId");
      const key = this.getPaginationKey();
      this.setState({ loading: true });
      try {
        const response = await getPatients(orgId, key.patientIdKey, key.orgIdKey);
        if (response.success && response.patients.Items) {
          if (!response.patients.LastEvaluatedKey) {
            this.setState({ allPagesFulfilled: true });
          }
          if (this.shouldChangePage(response.patients.Items.length)) {
            this.setState(prevState => ({ ...prevState, page: prevState.page + 1 }));
          }
          storePatients(
            response.patients.Items,
            response.patients.LastEvaluatedKey && response.patients.LastEvaluatedKey.orgId,
            response.patients.LastEvaluatedKey && response.patients.LastEvaluatedKey.patientId,
            orgId,
          );
        }
      } catch (e) {
        signalError("Error getting patients. Please try again later.");
        setTimeout(clearError, 5000);
      }
      this.setState({ loading: false });
    }

    getPaginationKey = () => {
      const patients = this.patientsContext();
      if (!patients.key) {
        return {};
      }

      let index = calculatePageIndex(this.state.page + 1, 3);

      try {
        const key = patients.key[index];
        return key;
      } catch (e) {
        return {};
      }
    }

    shouldChangePage = (n) => n && this.state.page + 1 === this.totalPages();

    totalPages = () => {
      const patients = this.patientsContext();
      return Math.floor(patients.data.length / pageLength);
    }

    patientsContext = () => {
      const { patients, location } = this.props;
      const orgId = location.search && new URLSearchParams(location.search).get("orgId");
      if (!orgId) {
        return patients;
      }
      return patients.orgs[orgId] ? patients.orgs[orgId] : { page: 0, data: [], key: null };
    }

    render() {
      const { loading, allPagesFulfilled, page } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          patients={this.patientsContext()}
          getPatients={this.getPatients}
          allPagesFulfilled={allPagesFulfilled}
          page={page}
          loading={loading}
        />
      );
    }
  }


  return WrapperComponent;
};

export default (Component, pageLength) => connect(
  state => ({ patients: state.patients }),
  (dispatch) => ({
    getPatients: (orgId, patientIdKey, orgIdKey) => getPatients(orgId, patientIdKey, orgIdKey),
    storePatients: (patients, orgIdKey, patientIdKey, orgId) => dispatch(storePatients(patients, orgIdKey, patientIdKey, orgId)),
  })
)(withRouter(WithNotifications(WithPatients(Component, pageLength))));