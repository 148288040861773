import moment from "moment";
export default class AlzheimersIntegratedRisk {
  constructor(report) {
    this.report = report;
    this.screen = report.screen;
    this.patient = {
      age: report.patient.age,
      sex: report.patient.sex
    };
    this.meta = {
      studyDate: this.parseStringValue(report.meta.studyDate),
      referringPhysician: this.parseStringValue(report.meta.referringPhysician),
      accessionNumber: this.parseStringValue(report.meta.accessionNumber),
      barcode: this.parseStringValue(report.meta.barcode),
      version: report.meta.version
    };
    this.integrated = {
      risk: report.integrated.risk,
      yearRange: report.integrated.yearRange,
      percentile20: report.integrated.percentile20,
      percentile50: report.integrated.percentile50,
      percentile80: report.integrated.percentile80,
      percentilePatient: report.integrated.percentilePatient,
      riskCategory: this.riskCategory(report.integrated.riskCategory),
      errors: report.integrated.errors,
      warnings: report.integrated.warnings,
    };
    this.genetics = {
      percentile: report.genetics.percentile,
      riskCategory: this.riskCategory(report.genetics.riskCategory),
      apoeStatus: this.parseStringValue(report.genetics.apoeStatus),
      errors: report.genetics.errors,
      warnings: report.genetics.warnings,
    };
    this.imaging = {
      percentile: report.imaging.percentile,
      riskCategory: this.riskCategory(report.imaging.riskCategory),
      errors: report.imaging.errors,
      warnings: report.imaging.warnings,
    };
    this.hasErrors = report.integrated.errors.length > 0 || report.genetics.errors.length > 0 || report.imaging.errors.length > 0;
  }

  parseStringValue(s) {
    const str = (s || "").trim();
    return str.length > 0 ? str : "N/A";
  }

  riskCategory = (risk) => {
    switch (risk) {
    case 0:
      return "Below Average";
    case 1:
      return "Average";
    case 2:
      return "Above Average";
    default:
      return "N/A";
    }
  }

  static validScreen = (screen) => screen && ["mci", "genpop"].includes(screen);
  static validSex = (sex) => sex && ["m", "f", "o"].includes(sex);
  static validDate = (date) => date && moment(new Date(date), "MM-DD-YYYY").isValid();
  static validArray = (array) => array && Array.isArray(array);
  static hasLength = (array) => AlzheimersIntegratedRisk.validArray(array) && array.length > 0;
  static validRiskCategory = (n) => n !== undefined && [0, 1, 2].includes(n);
  static validPatient = (patient) => patient && patient.age && AlzheimersIntegratedRisk.validSex(patient.sex);
  static validMeta = (meta) => meta && AlzheimersIntegratedRisk.validDate(meta.studyDate);
  static validNumber = (n) => Number.isFinite(n) && n >= 0;
  static validIntegrated = (integrated) => {
    return (
      integrated &&
      AlzheimersIntegratedRisk.validArray(integrated.yearRange) &&
      AlzheimersIntegratedRisk.validArray(integrated.percentile20) &&
      AlzheimersIntegratedRisk.validArray(integrated.percentile50) &&
      AlzheimersIntegratedRisk.validArray(integrated.percentile80) &&
      AlzheimersIntegratedRisk.validArray(integrated.percentilePatient) &&
      integrated.risk &&
      AlzheimersIntegratedRisk.validRiskCategory(integrated.riskCategory)
    );
  }
  static validGenetics = (genetics) => {
    return (
      genetics &&
      AlzheimersIntegratedRisk.validNumber(genetics.percentile) &&
      AlzheimersIntegratedRisk.validRiskCategory(genetics.riskCategory)
    );
  }
  static validImaging = (imaging) => {
    return (
      imaging &&
      AlzheimersIntegratedRisk.validNumber(imaging.percentile) &&
      AlzheimersIntegratedRisk.validRiskCategory(imaging.riskCategory)
    );
  }

  static validReport(report) {
    if (
      !report ||
      !AlzheimersIntegratedRisk.validScreen(report.screen) ||
      !AlzheimersIntegratedRisk.validPatient(report.patient) ||
      !AlzheimersIntegratedRisk.validMeta(report.meta) ||
      !AlzheimersIntegratedRisk.validIntegrated(report.integrated) ||
      !AlzheimersIntegratedRisk.validGenetics(report.genetics) ||
      !AlzheimersIntegratedRisk.validImaging(report.imaging)
    ) {
      return false;
    }
    return true;
  }
}