import React from "react";
import pt from "prop-types";
import { getPatientRecords } from "libs/apiLib";
import { WithNotifications } from "./index";

export const WithPatientRecords = (WrappedComponent, { getPatientRecords }) => {
  class WrapperComponent extends React.Component {
    static propTypes = {
      signalError: pt.func.isRequired,
      clearError: pt.func.isRequired,
      id: pt.string.isRequired,
      orgId: pt.string,
    }

    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        records: [],
        paginationKey: {},
        allPagesFulfilled: false,
        page: 0,
      };
    }

    componentDidMount() {
      this.getPatientRecords();
    }

    getPatientRecords = async () => {
      const { id, orgId, signalError, clearError } = this.props;
      const { paginationKey: { patientIdKey, ownerIdKey, recordIdKey } } = this.state;
      this.setState({ loading: true });
      try {
        const response = await getPatientRecords(id, orgId, patientIdKey, ownerIdKey, recordIdKey);
        if (response.success && response.records.Items) {
          this.updateStateWithAPIResponse(response.records);
        } else {
          throw new Error();
        }
      } catch (e) {
        signalError(`Error getting records for patient "${id}". Please try again later.`);
        setTimeout(clearError, 5000);
      }
      this.setState({ loading: false });
    }

    handlePaginationKey = (key) => {
      if (!key || (!key.patientId && !key.ownerId && !key.recordId)) {
        return { allPagesFulfilled: true };
      }
      return {
        paginationKey: {
          patientIdKey: key.patientId,
          ownerIdKey: key.ownerId,
          recordIdKey: key.recordId,
        }
      };
    }

    shouldChangePage = (state, records) => {
      return records.length && state.page + 1 === Math.floor(state.records.length / 10);
    }

    updateStateWithAPIResponse = (records) => {
      this.setState(prevState => {
        return ({
          ...prevState,
          records: prevState.records.length ? [...prevState.records, ...records.Items] : records.Items,
          page: this.shouldChangePage(prevState, records.Items) ? prevState.page + 1 : prevState.page,
          ...(this.handlePaginationKey(records.LastEvaluatedKey)),
        });
      });
    }

    render() {
      const { records, allPagesFulfilled, page, loading } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          records={records}
          onGetMorePatientRecords={this.getPatientRecords}
          onPageChange={page => this.setState({ page })}
          allPagesFulfilled={allPagesFulfilled}
          page={page}
          loading={loading}
        />
      );
    }
  }


  return WrapperComponent;
};

export default (Component) => WithNotifications(WithPatientRecords(Component, { getPatientRecords }));