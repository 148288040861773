
// aetitle-series.log
export class Sculog {
  constructor(filepath) {
    this.filepath = filepath;
    const [aetitle, seriesFolderName] = this.parseMetadatFromFilename(filepath);
    this.aetitle = aetitle;
    this.seriesFolderName = seriesFolderName;
    this.log = "";
  }

  basename(filepath) {
    const split = filepath.split("/");
    return split[split.length-1];
  }

  parseMetadatFromFilename(filepath) {
    const metadata = [undefined, undefined];
    if (!filepath.endsWith(".log")) { // aetitle-series.log
      return metadata;
    }
    const split = this.basename(filepath).split("."); // [some-aetitle-series, log]
    if (split.length !== 2) {
      return metadata;
    }
    const i = split[0].lastIndexOf("-");
    if (i === -1) {
      return metadata;
    }
    return [split[0].slice(0, i), split[0].slice(i+1)];
  }

  setData(log) {
    this.log = log;
  }
}