import React from "react";
import pt from "prop-types";
import Reports from "models/Reports";

export const WithSuccessFailureToolbarState = (WrappedComponent) => {
  class WrapperComponent extends React.Component {
    static propTypes = {
      report: pt.instanceOf(Reports),
    };

    state = {
      toolbarActive: false,
      successActive: true,
      failureActive: true,
    }

    componentDidUpdate(prevProps) {
      if (
        !prevProps.report && this.props.report ||
        prevProps.report && this.props.report && (!this.props.report.start.isSame(prevProps.report.start) || !this.props.report.end.isSame(prevProps.report.end))
      ) {
        this.resetState();
      }
    }

    resetState = () => this.setState({ successActive: true, failureActive: true, toolbarActive: false })

    onToolbarMetricClick = (key) => {
      this.setState(prevState => {
        switch (key) {
        case "successActive":
          if (!prevState.failureActive && prevState[key]) {
            return;
          }
          break;
        case "failureActive":
          if (!prevState.successActive && prevState[key]) {
            return;
          }
          break;
        default:
          break;
        }
        return {
          [key]: !prevState[key],
        };
      });
    }

    onToggleToolbar = (toolbarActive) => {
      this.setState({ toolbarActive });
    }

    render() {
      return (
        <WrappedComponent
          onToolbarMetricClick={this.onToolbarMetricClick}
          onToggleToolbar={this.onToggleToolbar}
          {...this.props}
          {...this.state}
        />
      );
    }
  }

  return WrapperComponent;
};


export default WithSuccessFailureToolbarState;