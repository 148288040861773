import React from "react";
import moment from "moment";
import pt from "prop-types";
import Reports from "models/Reports";
import Table from "react-table";

// core components
import { WithOrgReport } from "components/HigherOrder";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard";
import Badge from "components/Badge/Badge";
import Dropdown from "components/CustomDropdown/CustomDropdown";
import { ModalityUsageContent } from "views/Organization/components/ModalityUsage";

// styles
import withStyles from "material-ui/styles/withStyles";
import tableStyles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";

let ModalityTable = ({ report, classes, metric }) => {
  let data = [];
  const cellValue = (value) => value ? value : "N/A";

  if (report) {
    const modalities = report.modalityUsageBy("id");
    data = Object.keys(modalities).map((id) => {
      const item = modalities[id];
      const { modality, model, manufacturer, hostName, serialNumber, failures, successful } = item;
      return {
        modality: cellValue(modality),
        manufacturer: cellValue(manufacturer),
        model: cellValue(model),
        hostName: cellValue(hostName),
        serialNumber: cellValue(serialNumber),
        metric: metric === "total" ? failures + successful : item[metric]
      };
    });
  }

  const badgeColor = (metric) => {
    switch (metric) {
    case "successful":
      return "success";
    case "failures":
      return "danger";
    default:
      return "primary";
    }
  };


  return (
    <Table
      noDataText="No Modalities Reported For This Date Range"
      data={data}
      columns={[
        {
          Header: "",
          accessor: "modality",
          Cell: (cell) => (
            <div>
              <div>{cell.value}</div>
              <div className={classes.label}>Modality</div>
            </div>
          )
        },
        {
          Header: "",
          accessor: "model",
          Cell: (cell) => (
            <div>
              <div>{cell.value}</div>
              <div className={classes.label}>{cell.original.manufacturer}</div>
            </div>
          )
        },
        {
          Header: "",
          accessor: "serialNumber",
          Cell: (cell) => (
            <div>
              <div>{cell.value}</div>
              <div className={classes.label}>{cell.original.hostName}</div>
            </div>
          )
        },
        {
          Header: "",
          accessor: "metric",
          Cell: (cell) => <div className={classes.right}><Badge color={badgeColor(metric)}>{cell.value}</Badge></div>
        },
      ]
      }
      sortable={false}
      resizable={false}
      pageSize={10}
      minRows={1}
      showPagination={false}
    />
  );
};

ModalityTable.propTypes = {
  report: pt.instanceOf(Reports),
  classes: pt.shape({
    label: pt.string.isRequired,
  }).isRequired,
  metric: pt.string.isRequired,
};

ModalityTable = withStyles(tableStyles)(ModalityTable);

class ModalityStats extends React.Component {
  state = {
    metric: "Total"
  }

  handleMetricSelect = (metric) => this.setState({ metric })

  render() {
    const { start, end, report } = this.props;
    const { metric } = this.state;
    let subtitle = "";
    if (start && end) {
      subtitle = `From ${start.format("MMMM Do YYYY")} To ${end.format("MMMM Do YYYY")}`;
    }
    return (
      <HeaderCard
        headerColor="orange"
        cardTitle="Modality Usage"
        cardSubtitle={subtitle}
        content={
          <GridContainer>
            <ItemGrid>
              <h3>Modality Usage</h3>
            </ItemGrid>
            <ItemGrid xs={12}>
              <ModalityUsageContent report={report} />
            </ItemGrid>
            <ItemGrid xs={6} md={6}>
              <h3>Modality Statistics</h3>
            </ItemGrid>
            <ItemGrid xs={6} md={6} container justify="flex-end">
              <Dropdown
                style={{
                  marginTop: 10
                }}
                buttonColor="primary"
                buttonText={metric}
                dropdownList={[
                  "Total",
                  "Successful",
                  "Failures",
                ]}
                onAction={this.handleMetricSelect}
              />
            </ItemGrid>
            <ItemGrid xs={12}>
              <ModalityTable report={report} metric={metric.toLowerCase()} />
            </ItemGrid>
          </GridContainer>
        }
      />
    );
  }
}

ModalityStats.propTypes = {
  report: pt.instanceOf(Reports),
  start: pt.instanceOf(moment),
  end: pt.instanceOf(moment),
};

export {
  ModalityTable,
  ModalityStats,
};
export default WithOrgReport(ModalityStats);