import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import cx from "classnames";
import PropTypes from "prop-types";
import IdleTimer from "react-idle-timer";
import APPCONFIG from "constants/Config";
import { WithNotifications } from "components/HigherOrder";
import { searchParamsSelector } from "selectors";
import AccessToken from "models/AccessToken";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Snackbar from "components/Snackbar/Snackbar";
import Error from "material-ui-icons/Error";
import Info from "material-ui-icons/Info";

// core components
import Header from "views/Header/Header.jsx";
import Footer from "views/Footer/Footer.jsx";
import Sidebar from "views/Sidebar/Sidebar.jsx";
import TermsAndConditions from "views/TermsAndConditions/TermsAndConditions";
import SupportModal from "views/SupportModal";

import dashboardRoutes from "routes/dashboard.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

// import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo-white.svg";

// libs and api
import { authUser, invokeApig, getCurrentUserAccessToken } from "libs/awsLib";
import { authenticate, authenticating, logout, storeOrg, removeNotificationError, removeNotificationInfo, assumeOrg, updateDatalakeAccessToken } from "actions/index";
import { getMyOrg, publishAuditTrail, getWebPreviewToken } from "libs/apiLib";

var ps;

export const Routes = ({ user, routes }) => {
  if (!routes || !user) {
    return null;
  }
  const results = [];
  for (const route of routes) {
    if (route.redirect) {
      results.push(<Redirect from={route.path} to={route.pathTo} key={route.path} />);
      continue;
    }
    results.push(
      <Route exact={route.exact === true} path={route.path} component={route.component} key={route.path} />
    );
  }
  return <Switch>{results}</Switch>;
};

Routes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.component,
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    views: PropTypes.array,
  })).isRequired,
  user: PropTypes.shape({}).isRequired,
};

export class Dashboard extends React.Component {
  state = {
    idleTimer: null,
    sidebarRoutes: null,
    appRoutes: null,
    miniActive: true,
    mobileOpen: true,
    showTC: false,
    showSupport: false,
    refreshingAssumedOrg: false,
    datalakeAccessTokens: {
      refreshingUserAccessToken: false,
      refreshingAssumedOrgAccessToken: false,
    },
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  async componentDidMount() {

    const {
      authUser, invokeApig, getCurrentUserAccessToken, getMyOrg,
      isUserAuthenticated, isUserAuthenticating, updateOrgOnStore
    } = this.props;

    // update is authenticating flag
    isUserAuthenticating(true);

    try {
      if (!(await authUser())) {
        throw new Error("Failed to authenticate");
      }

      // get user from platform context
      const accessToken = await getCurrentUserAccessToken();
      const result = await invokeApig({
        headers: {
          "access-token": accessToken
        },
        path: "/id/myUser",
      });

      if (!result) { throw new Error("Failed to get user"); }
      else if (!result.user) { throw new Error("Failed to get user"); }

      // update store
      isUserAuthenticated(true, result.user);
      isUserAuthenticating(false);

      const user = result.user;

      // get viewing user's org
      const resp = await getMyOrg();
      if (resp && resp.org) {
        updateOrgOnStore(resp.org);
        await this.refreshDatalakeAccessToken(resp.org.orgId, "userAccessToken");
        const appRoutes = this.filterRoutes(dashboardRoutes, resp.org, user);
        const sidebarRoutes = this.sidebarRoutes(dashboardRoutes, resp.org, user);
        this.setState({ appRoutes, sidebarRoutes });
      }
      // if orgId exists in search query get that org and the datalake access token
      if (this.props.searchParams.orgId) {
        await this.refreshAssumedOrgIfNeeded();
        await this.refreshAssumedOrgAccessTokenIfNeeded();
      }
    }
    catch (e) {
      this.logOut();
    }

    if (navigator.platform.indexOf("Win") > -1) {
      // eslint-disable-next-line
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1 && ps !== undefined) {
      ps.destroy();
    }
  }

  async componentDidUpdate() {
    await this.refreshAssumedOrgIfNeeded();
    await this.refreshAssumedOrgAccessTokenIfNeeded();
  }

  async refreshAssumedOrgIfNeeded() {
    // return if no orgId search query exists or we've already refreshed the assumed org state
    if (!this.props.searchParams.orgId || this.props.searchParams.orgId && this.props.assumedOrg || this.state.refreshingAssumedOrg) {
      return;
    }
    try {
      this.setState({ refreshingAssumedOrg: true });
      // get assumed org when the orgId search query exists but no assumed org object exists
      const { orgId } = this.props.searchParams;
      const resp = await getMyOrg(orgId);
      if (!resp || !resp.org) {
        throw new Error(`Failed to retrieve org ${orgId}`);
      }
      this.props.assumeOrg(resp.org);
    } catch (error) {
      this.props.signalError(error.message);
      setTimeout(this.props.clearError, 5000);
    }
    this.setState({ refreshingAssumedOrg: false });
  }

  async refreshAssumedOrgAccessTokenIfNeeded() {
    const accessToken = this.props.datalakeAccessTokens.assumedOrgAccessToken;
    const refreshing = this.state.datalakeAccessTokens.refreshingAssumedOrgAccessToken;
    const { orgId } = this.props.searchParams;
    if (!orgId) {
      return;
    }
    // Return if no token exists but it is currently being fetched
    if (!accessToken && refreshing) {
      return;
    }
    // No token exists and it has not been fetched yet
    if (!accessToken) {
      await this.refreshDatalakeAccessToken(orgId, "assumedOrgAccessToken");
      return;
    }
    // Return if token exists and is not expired
    if (accessToken && !accessToken.expired()) {
      return;
    }
    // Refresh token if it's expired
    if (accessToken && accessToken.expired()) {
      await this.refreshDatalakeAccessToken(orgId, "assumedOrgAccessToken");
      return;
    }
  }

  async refreshDatalakeAccessToken(orgId, type) {
    const refreshStateKey = type === "userAccessToken" ? "refreshingUserAccessToken" : "refreshingAssumedOrgAccessToken";
    try {
      const refreshing = type === "userAccessToken" ? this.state.datalakeAccessTokens.refreshingUserAccessToken : this.state.datalakeAccessTokens.refreshingAssumedOrgAccessToken;
      if (refreshing) {
        return;
      }
      this.setState(prevState => ({ ...prevState, datalakeAccessTokens: { ...prevState.datalakeAccessTokens, [refreshStateKey]: true } }));
      const results = await this.props.refreshDatalakeAccessToken(orgId);
      if (results && results.success && results.token) {
        this.props.updateDatalakeAccessToken(orgId, results.token);
      }
    } catch (error) {
      this.props.signalError("Failed to refresh access tokens.");
      setTimeout(this.props.clearError, 5000);
    }
    this.setState(prevState => ({ ...prevState, datalakeAccessTokens: { ...prevState.datalakeAccessTokens, [refreshStateKey]: false } }));
  }

  // Returns true if user and org has correct rule permission, false otherwise
  hasRulePermission = (rule, org, user) => {
    switch (rule) {
    case "Edge":
      if (org.edgeAllowed && (user.admin || user.supportEngineer || user.superAdmin)) { return true; }
      break;
    case "Developer":
      if (org.apiAllowed && (user.admin || user.supportEngineer || user.superAdmin)) { return true; }
      break;
    case "AllAdminsAndSupport":
      if (user.admin || user.supportEngineer || user.superAdmin) { return true; }
      break;
    case "AllHytx":
      if (user.appAdmin || user.superAdmin || user.supportEngineer || user.billingAdmin || user.analyticsRole) { return true; }
      break;
    case "AllHytxAppAdmin":
      if (user.appAdmin || user.superAdmin) { return true; }
      break;
    case "AllHytxSupport":
      if (user.supportEngineer || user.superAdmin) { return true; }
      break;
    case "AllHytxBilling":
      if (user.billingAdmin || user.superAdmin) { return true; }
      break;
    case "AllHytxAnalytics":
      if (user.analyticsRole || user.superAdmin) { return true; }
      break;
    case "SuperAdmin":
      if (user.superAdmin) { return true; }
      break;
    case "Genetics":
      if (org.geneticKitsAllowed) { return true; }
      break;
    default:
      return false;
    }
    return false;
  };

  // Returns array of routes only one deep
  sidebarRoutes = (routes, org, user) => {
    return routes.reduce((memo, route) => {
      const r = { ...route };
      if (route.protected && route.protectionRule && !this.hasRulePermission(route.protectionRule, org, user)) {
        return memo;
      }
      if (r.views) {
        r.views = r.views.filter(nestedRoute => {
          if (nestedRoute.protected && nestedRoute.protectionRule) {
            return this.hasRulePermission(nestedRoute.protectionRule, org, user);
          } else {
            return true;
          }
        });
      }
      return [...memo, r];
    }, []);
  }

  // Returns a flat array of all routes for the viewing user & the viewing organization
  filterRoutes = (routes, org, user) => {
    const results = [];
    routes.forEach((route) => {
      if (route.redirect) {
        results.push(route);
        return;
      }
      if (route.protected && route.protectionRule && !this.hasRulePermission(route.protectionRule, org, user)) {
        return null;
      } else if (route.protected && !route.protectionRule) {
        // if for some reason a rule is not applied, still do not render the route
        return null;
      }
      if (route.views) {
        results.push(...this.filterRoutes(route.views, org, user));
      }
      results.push(route);
    });
    return results;
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  closeTCs = () => {
    this.setState({ showTC: false });
  }

  handleOpenTerms = () => {
    this.setState({ showTC: true });
  }

  logOut() {
    const { logOut, isUserAuthenticating } = this.props;
    isUserAuthenticating(false);
    logOut();
    this.props.history.push("/pages/login");
  }

  async logOutForcefully() {
    const { user: { firstName, lastName, userName } } = this.props;
    try {
      await publishAuditTrail({
        type: "deleteUserAuthForce",
        description: `${firstName} ${lastName} (${userName}) was forcefully logged out due to ${this.msToMinutes(APPCONFIG.sessions.inactivityThreshold)} minutes of inactivity.`,
        PHI: false
      });
    } catch (e) {
      //
    }
    this.logOut();
  }

  msToMinutes(ms) {
    return Math.floor(ms / 60000);
  }

  onIdle = () => {
    const { signalInfo, clearInfo } = this.props;
    signalInfo(`Session has been inactive for ${this.msToMinutes(APPCONFIG.sessions.inactivityThreshold)} minutes. Your session will be forcefully logged out in ten seconds.`);
    setTimeout(clearInfo, 8000);
    setTimeout(this.logOutForcefully.bind(this), 1000 * 10);
  }

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      this.state.appRoutes && this.state.sidebarRoutes && this.props.org &&
      <div className={classes.wrapper}>
        <IdleTimer
          ref={ref => { this.idleTimer = ref; }}
          element={document}
          onIdle={this.onIdle}
          debounce={500}
          timeout={APPCONFIG.sessions.inactivityThreshold}
        />
        <TermsAndConditions onClose={this.closeTCs} modal={this.state.showTC} />
        <SupportModal onClose={() =>  this.setState({ showSupport: false })} modal={this.state.showSupport} />
        <Sidebar
          routes={this.state.sidebarRoutes}
          logoText={this.props.org.orgName}
          logo={logo}
          image={null}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          bgColor="white"
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={this.state.appRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              <Snackbar
                icon={Error}
                open={!!this.props.error}
                message={this.props.error ? this.props.error : ""}
                color="danger"
                place="tr"
                close
                closeNotification={this.props.removeNotificationError}
              />
              <Snackbar
                icon={Info}
                open={!!this.props.info}
                message={this.props.info ? this.props.info : ""}
                color="info"
                place="tl"
                close
                closeNotification={this.props.removeNotificationInfo}
              />
              <Routes
                routes={this.state.appRoutes}
                user={this.props.user}
              />
            </div>
          </div>

          <Footer fluid onTC={this.handleOpenTerms} onSupport={() => this.setState({ showSupport: true })}/>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  authUser: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  error: PropTypes.string,
  info: PropTypes.string,
  getCurrentUserAccessToken: PropTypes.func.isRequired,
  getMyOrg: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  invokeApig: PropTypes.func.isRequired,
  isUserAuthenticated: PropTypes.func.isRequired,
  isUserAuthenticating: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  removeNotificationError: PropTypes.func.isRequired,
  removeNotificationInfo: PropTypes.func.isRequired,
  updateOrgOnStore: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  signalInfo: PropTypes.func.isRequired,
  clearInfo: PropTypes.func.isRequired,
  signalError: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  refreshDatalakeAccessToken: PropTypes.func.isRequired,
  updateDatalakeAccessToken: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    orgId: PropTypes.string
  }).isRequired,
  datalakeAccessTokens: PropTypes.shape({
    userAccessToken: PropTypes.instanceOf(AccessToken),
    assumedOrgAccessToken: PropTypes.instanceOf(AccessToken),
  }).isRequired,
  assumedOrg: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({
  error: state.notifications.error,
  info: state.notifications.info,
  isAuthenticated: state.user.isAuthenticated,
  isAuthenticating: state.user.isAuthenticating,
  user: state.user.user,
  org: state.user.org,
  searchParams: searchParamsSelector(state),
  assumedOrg: state.assumedOrg,
  datalakeAccessTokens: {
    userAccessToken: state.user.user && state.datalake[state.user.user.orgId] ? new AccessToken(state.datalake[state.user.user.orgId]) : null,
    assumedOrgAccessToken: state.assumedOrg && state.datalake[state.assumedOrg.orgId] ? new AccessToken(state.datalake[state.assumedOrg.orgId]) : null,
  }
});

const mapDispatchToProps = dispatch => ({
  authUser: () => authUser(),
  getCurrentUserAccessToken: () => getCurrentUserAccessToken(),
  getMyOrg: (orgId) => getMyOrg(orgId),
  invokeApig: (args) => invokeApig(args),
  isUserAuthenticated: (isAuthenticated, user) => {
    dispatch(authenticate(isAuthenticated, user));
  },
  isUserAuthenticating: (isAuthenticating) => {
    dispatch(authenticating(isAuthenticating));
  },
  logOut: () => {
    dispatch(logout());
  },
  removeNotificationError: () => dispatch(removeNotificationError()),
  removeNotificationInfo: () => dispatch(removeNotificationInfo()),
  updateOrgOnStore: (org) => {
    dispatch(storeOrg(org));
  },
  assumeOrg: (org) => dispatch(assumeOrg(org)),
  refreshDatalakeAccessToken: (orgId) => getWebPreviewToken(orgId),
  updateDatalakeAccessToken: (orgId, token) => {
    dispatch(updateDatalakeAccessToken(orgId, token));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  WithNotifications(
    withRouter(
      withStyles(appStyle)(Dashboard)
    )
  )
);
