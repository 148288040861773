import React from "react";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AppCard from "components/Cards/AppCard.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

// material-ui icons
import CloudIcon from "material-ui-icons/CloudQueue";
import DeviceIcon from "material-ui-icons/Devices";
import GetApp from "material-ui-icons/GetApp";
import Build from "material-ui-icons/Build";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class EdgeAppsList extends React.Component {

    constructor(props) {
        super(props);

        this.getAppIcon = this.getAppIcon.bind(this);
        this.getAppColor = this.getAppColor.bind(this);
        this.onConfigureApp = this.onConfigureApp.bind(this);
        this.onInstallApp = this.onInstallApp.bind(this);
    }

    getAppIcon(mode) {
        if (mode === "CLOUD")
            return CloudIcon;
        else
            return DeviceIcon;
    }

    getAppColor(installed) {
        if (installed)
            return "primary";
        else
            return "gray";
    }

    onConfigureApp(app, action) {
        this.props.onConfigureApp(app, action);
    }

    onInstallApp(app) {
        this.props.onInstallApp(app);
    }

    render() {

        let appItems = null;

        if (this.props.apps) {
            appItems = this.props.apps.map((prop, key) => {
                const version = prop.version || "-";
                const icon = prop.installed ? this.getAppIcon(prop.mode) : GetApp;
                let actionOptions = [
                    "Manage Routes",
                    "Config Manager",
                    { divider: true },
                    "Uninstall"
                ];

                if(prop.mode === "EDGE")
                    actionOptions.push("Re-Download");

                return (
                    <ItemGrid xs={12} sm={12} md={3} lg={3} key={key}>
                        <AppCard
                            key={key}
                            plain={!prop.installed}
                            whitePrice={false}
                            version={version}
                            title={prop.appStatus ? prop.appStatus : "Uninstalled"}
                            price={prop.name}
                            description={prop.description}
                            icon={icon}
                            aeTitle={prop.aeTitle}
                            iconColor={this.getAppColor(prop.installed)}
                            footer={prop.installed ? (
                                    <CustomDropdown
                                        onAction={(action) => this.onConfigureApp(prop, action)}
                                        buttonColor="primary"
                                        buttonIcon={Build}
                                        buttonText="Configure"
                                        buttonProps={{
                                            round: true
                                        }}
                                        dropdownList={actionOptions}
                                    /> ) : (
                                    <Button round color="white"
                                            onClick={() => this.onInstallApp(prop)}>
                                        Install
                                </Button>)}
                        />
                    </ItemGrid>
                )
            })
        }

        return (
            appItems &&
            <GridContainer>
                {appItems}
            </GridContainer>
        );
    }
}

export default withStyles(dashboardStyle)(EdgeAppsList);
