import React from "react";
import pt from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { getWebPreviewToken, getJob, regenerateGeneticRiskReport } from "libs/apiLib";
import { getSignedUrl, headObject, downloadFileFromS3 } from "libs/awsLib";
import { userSelector } from "selectors";
import AIRRWarnings from "./AIRRWarnings";

import { WithNotifications } from "components/HigherOrder";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import IconCard from "components/Cards/IconCard";
import IconPuzzle from "material-ui-icons/Extension";
import Table from "components/Table/Table";
import Badge from "components/Badge/Badge";
import Button from "components/CustomButtons/Button";
import IconButton from "components/CustomButtons/IconButton";
import RefreshIcon from "material-ui-icons/Refresh";
import Tooltip from "material-ui/Tooltip";
import CustomDropdownSmall from "components/CustomDropdown/CustomDropdownSmall";
import Modal from "components/Modal/Code";

export class AlzGenetic extends React.Component {
  static propTypes = {
    regenerateGeneticRiskReport: pt.func.isRequired,
    user: pt.shape({
      isHLXSupport: pt.bool.isRequired,
    }).isRequired,
    getJob: pt.func.isRequired,
    getWebPreviewToken: pt.func.isRequired,
    getSignedUrl: pt.func.isRequired,
    signalError: pt.func.isRequired,
    result: pt.shape({
      jobStatus: pt.oneOf(["Finished Processing", "Running", "Failed"]).isRequired,
      received: pt.string.isRequired,
      eventId: pt.string.isRequired,
      output: pt.shape({
        default: pt.shape({
          status: pt.oneOf(["Success", "Failed", "Pending"]),
          location: pt.string,
          date: pt.string,
        }),
        supplemental: pt.arrayOf(pt.shape({
          id: pt.string.isRequired,
          name: pt.string.isRequired,
          type: pt.oneOf(["Genetics", "Integrated"]).isRequired,
        }))
      }),
      results: pt.shape({
        report: pt.shape({
          patient: pt.shape({
            age: pt.number.isRequired,
          }).isRequired,
          meta: pt.shape({
            orderingPhysician: pt.string,
            orderingOrgAddress: pt.string,
            orderingOrgName: pt.string,
            dateProcessed: pt.string.isRequired,
            dateReceived: pt.string.isRequired,
            barcode: pt.string,
            version: pt.string,
          }).isRequired,
          genetics: pt.shape({
            apoeStatus: pt.string.isRequired,
            score: pt.number.isRequired,
            percentile: pt.number.isRequired,
            currentRisk: pt.string.isRequired,
            lifetimeRisk: pt.string.isRequired,
            riskDifference: pt.number.isRequired,
            riskValues: pt.arrayOf(pt.number).isRequired,
            averageRiskValues: pt.arrayOf(pt.number).isRequired,
            errors: pt.arrayOf(pt.string).isRequired,
            warngings: pt.arrayOf(pt.string).isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    appId: pt.string.isRequired,
    patient: pt.shape({
      firstName: pt.string,
      lastName: pt.string,
      dob: pt.string,
      sex: pt.string,
    }).isRequired,
    classes: pt.shape({
      footer: pt.string.isRequired,
      report: pt.string.isRequired,
      highlightTitle: pt.string.isRequired,
      bottomMargin: pt.string.isRequired,
      sectionBorder: pt.string.isRequired,
      textCenter: pt.string.isRequired,
    }).isRequired,
    today: pt.instanceOf(moment),
    downloadFileFromS3: pt.func.isRequired
  }

  constructor(props) {
    super(props);
    const { patient } = props;
    this.today = props.today || moment();
    this.patientAge = Math.abs(moment(Date.parse(patient.dob)).diff(this.today, "years"));
    this.patient = patient;
    this.state = {
      modal: null,
      token: null,
      loading: false,
      outputs: {
        geneticRisk: {
          status: null,
          exists: false,
          checkRetries: 0,
          url: null
        }
      }
    };
  }

  componentDidMount() {
    const { result } = this.props;
    const jobFinished = result.jobStatus === "Finished Processing";
    const outputIsInDB = Boolean(result.output) && Boolean(result.output.default);
    const outputIsInS3 = Boolean(result.jobDir) && Boolean(result.inputDir) && Boolean(result.outputDir);
    if (jobFinished && outputIsInDB) {
      this.getJobOutputFromDB(result.eventId);
    } else if (jobFinished && outputIsInS3) {
      this.getJobOutputFromS3(result);
    }
  }

  getJobOutputFromS3 = async (job) => {
    const { signalError, clearError } = this.props;
    try {
      const token = await this.getS3Token();
      const configResp = await this.props.downloadFileFromS3(`${job.inputDir}/config.json`, token);
      if (!configResp || !configResp.Body) {
        throw new Error("Invalid input data. No config exists within the jobs input directory.");
      }
      const config = JSON.parse(configResp.Body.toString());
      const pdfConfig = config.output.find(c => c.type === "pdf");
      if (!pdfConfig || !pdfConfig.filename) {
        throw new Error("Invalid input data. No pdf config exists within the output config JSON.");
      }
      const url = await this.getPDFDownloadURL(`${job.outputDir}/${pdfConfig.filename}`);
      this.setState(prevState => ({
        ...prevState,
        outputs: {
          ...prevState.outputs,
          geneticRisk: {
            ...prevState.outputs.geneticRisk,
            exists: true,
            status: "Success",
            url
          }
        }
      }));
    } catch (error) {
      signalError("Error getting GeneticRisk PDF report. Please contact support@healthlytix.com");
      setTimeout(clearError, 5000);
    }
  }

  getJobOutputFromDB = async (eventId) => {
    const { getJob, signalError, clearError } = this.props;
    this.setState({ loading: true });
    try {
      if (this.state.outputs.geneticRisk.checkRetries === 3) {
        return;
      }
      const response = await getJob(eventId);
      if (response.success && response.job) {
        const { job } = response;
        if (job.output && job.output.default) {
          const { status } = job.output.default;
          if (status === "Pending") {
            this.setState(prevState => ({
              ...prevState,
              outputs: {
                ...prevState.outputs,
                geneticRisk: {
                  ...prevState.outputs.geneticRisk,
                  checkRetries: prevState.outputs.geneticRisk.checkRetries + 1,
                  status,
                  exists: false,
                  url: null
                }
              }
            }),
            () => {
              setTimeout(this.getJobOutputFromDB.bind(this, eventId), 30000); // wait 30 sec then call this function again
            });
          } else if (status === "Success") {
            const url = await this.getPDFDownloadURL(job.output.default.location);
            this.setState(prevState => ({
              ...prevState,
              outputs: {
                ...prevState.outputs,
                geneticRisk: {
                  ...prevState.outputs.geneticRisk,
                  exists: true,
                  status,
                  url
                }
              }
            }));
          } else {
            this.setState(prevState => ({
              ...prevState,
              outputs: {
                ...prevState.outputs,
                geneticRisk: {
                  ...prevState.outputs.geneticRisk,
                  exists: false,
                  status: "Failed",
                  url: null
                }
              }
            }));
          }
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error getting GeneticRisk PDF report. Please contact support@healthlytix.com");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  async getS3Token() {
    const { clearError, signalError, getWebPreviewToken, patient: { orgId } } = this.props;
    this.setState({ loading: true });
    let token;
    try {
      let results;
      if (!this.state.token) {
        results = await getWebPreviewToken(orgId);
        if (results && results.success && results.token) {
          token = results.token;
          this.setState({ token });
        } else {
          throw new Error("Couldn't not get token to download PDF");
        }
      } else {
        token = this.state.token;
      }
    } catch (e) {
      signalError("Error getting PDF reports. Please contact support@healthlytix.com");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
    return token;
  }

  getPDFDownloadURL = async (key) => {
    const token = await this.getS3Token();
    const url = this.props.getSignedUrl(token, key, {
      ResponseContentDisposition: "attachment",
      ResponseContentType: "application/pdf"
    });
    return url;
  }

  statusBadge = (status) => {
    let color = "primary";
    switch (status) {
    case "Running":
      break;
    case "Finished Processing":
      color = "success";
      break;
    case "Failed":
      color = "danger";
      break;
    case "Queued":
      color = "info";
      break;
    default:
      return "N/A";
    }
    return <Badge color={color}>{status}</Badge>;
  }

  regenerateGeneticRiskReport = async () => {
    const { result, signalError, clearError } = this.props;
    try {
      this.setState({ loading: true });
      await this.props.regenerateGeneticRiskReport({ patientId: result.patientId, orgId: result.orgId, eventId: result.eventId });
      window.location.reload();
    } catch (error) {
      signalError("Error regenerating report. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  reportStatusButton = (report) => {
    const { user } = this.props;
    const size = "sm";
    let color = "primary";
    let text = report.status || "";
    let url = "#";
    switch (report.status) {
    case "Pending":
      color = "info";
      break;
    case "Success":
      color = "success";
      text = "Download";
      url = report.exists && report.url || "#";
      break;
    case "Failed":
      color = "danger";
      if (user.isHLXSupport) {
        return (
          <React.Fragment>
            <Button onClick={this.regenerateGeneticRiskReport} size={size} color="danger">Failed! Regenerate?</Button>
            <Tooltip title="Regenerate?" placement="right">
              <IconButton
                size={size}
                color="info"
              >
                <RefreshIcon/>
              </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      }
      return <Button href={url} size={size} color={color}>{text}</Button>;
    default:
      return "";
    }
    if (user.isHLXSupport) {
      return (
        <React.Fragment>
          <Button href={url} size={size} color={color}>{text}</Button>
          <Tooltip title="Regenerate?" placement="right">
            <IconButton
              onClick={this.regenerateGeneticRiskReport}
              size={size}
              color="info"
            >
              <RefreshIcon/>
            </IconButton>
          </Tooltip>
        </React.Fragment>
      );
    }
    return <Button href={url} size={size} color={color}>{text}</Button>;
  }

  geneticInputData = (report) => {
    if (!report || !report.input || !report.input.genetics || !report.input.genetics.id) {
      return "N/A";
    }
    return report.input.genetics.id;
  }

  handleCaseAction = (action) => {
    switch (action) {
    case "App Logs":
      // TODO:
      break;
    case "View Report Details":
      this.handleViewReportDetails();
      break;
    default:
      break;
    }
  }

  removeModal = () => this.setState({ modal: null });

  handleViewReportDetails = () => {
    if (!this.props.result) {
      return;
    }
    this.setState({
      modal: (
        <Modal
          title={<h2>Report Details</h2>}
          value={JSON.stringify(this.props.result, undefined, "\t")}
          style={{
            height: 900,
            width: 800,
            overflow: "hidden",
            overflowY: "scroll",
          }}
          footerRender={() => (
            <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
              <Button onClick={this.removeModal}>Close</Button>
            </ItemGrid>
          )}
        />
      )
    });
  }

  showOptions() {
    if (!this.props.user.isHLXSupport) {
      return null;
    }
    return (
      <ItemGrid xs={12} md={2} container justify="flex-end" alignItems="center">
        <CustomDropdownSmall
          buttonText="Options"
          dropdownList={["View Report Details"]}
          onAction={(action) => this.handleCaseAction(action)}
        />
      </ItemGrid>
    );
  }
  
  render() {
    const { result } = this.props;
    const { loading, outputs: { geneticRisk } } = this.state;
    const jobSucceeded = result.jobStatus === "Finished Processing";
    const showPDFDownloadButtons = !loading && jobSucceeded;
    return (
      <GridContainer justify="center">
        {this.state.modal}
        <ItemGrid xs={12} lg={6}>
          <AIRRWarnings report={this.props.result && this.props.result.results && this.props.result.results.report || null} appError={result.error} />
        </ItemGrid>

        <IconCard
          iconColor="green"
          icon={IconPuzzle}
          title="Alzheimer's GeneticRisk Report"
          content={
            <GridContainer>
              <ItemGrid xs={12} md={10}>
                <h2 style={{ display: "inline-block" }}>Application Details</h2>
              </ItemGrid>
              {this.showOptions()}
              <ItemGrid xs={12}>
                <Table
                  tableData={[
                    ["Status", this.statusBadge(result.jobStatus)],
                    ["Genetics Input Record Used", this.geneticInputData(result)],
                    ["Submitted", moment(Date.parse(result.received)).format("MM-DD-YYYY")],
                    ["HealthLytix Patient ID", result.patientId],
                    ["Event ID", result.eventId],
                    ["GeneticRisk PDF Report", showPDFDownloadButtons ? this.reportStatusButton(geneticRisk)  : ""],
                  ]}
                />
              </ItemGrid>
            </GridContainer>
          }
        />
      </GridContainer>
    );
  }
}

export default connect((state) => ({
  user: userSelector(state)
}), () => ({
  getSignedUrl,
  getWebPreviewToken,
  headObject,
  getJob,
  regenerateGeneticRiskReport,
  downloadFileFromS3
}))(WithNotifications(AlzGenetic));