import React from "react";
import { connect } from "react-redux";
import pt from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";

import SearchBar from "./SearchBar";
import IconCard from "components/Cards/IconCard";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button";
import Pagination from "components/Pagination/ReactTablePagination";
import { getPatient, getPatients, updatePatient } from "libs/apiLib";
import { calculatePageIndex } from "libs/utils";
import { WithNotifications } from "components/HigherOrder";
import PatientEditModal from "components/Modal/PatientEdit";

import People from "material-ui-icons/Wc";
import Help from "material-ui-icons/HelpOutline";
import ChevronRight from "material-ui-icons/ChevronRight";
import { storePatients, changePatientsPage } from "actions";

export class AllPatients extends React.Component {
  static propTypes = {
    getPatient: pt.func.isRequired,
    getPatients: pt.func.isRequired,
    updatePatient: pt.func.isRequired,
    location: pt.shape({
      search: pt.string,
    }),
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    day: pt.instanceOf(moment),
    patients: pt.shape({
      page: pt.number.isRequired,
      key: pt.arrayOf(pt.oneOfType([pt.shape({}), pt.shape({ patientIdKey: pt.string.isRequired, orgIdKey: pt.string.isRequired })])),
      data: pt.arrayOf(pt.shape({})).isRequired,
      orgs: pt.shape({}).isRequired
    }).isRequired,
    storePatients: pt.func.isRequired,
    changePatientsPage: pt.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.day = props.day || moment();
    this.state = {
      query: "",
      dimension: "patientId",
      isLoading: false,
      allPagesFulfilled: false,
    };
  }

  componentDidMount() {
    const patients = this.patientsContext();
    if (!patients.data.length) {
      this.getPatients();
    }
  }

  componentDidUpdate(prevProps) {
    const prevOrgId = new URLSearchParams(prevProps.location.search).get("orgId");
    const orgId = new URLSearchParams(this.props.location.search).get("orgId");
    if (prevOrgId && !orgId) {
      window.location.reload();
    }
  }

  searchPatient = async () => {
    const { query } = this.state;
    const { getPatient, history, location: { search }, signalError, clearError } = this.props;
    const orgId = new URLSearchParams(this.props.location.search).get("orgId");
    this.setState({ isLoading: true });
    try {
      const response = await getPatient(query, orgId);
      if (!response.success || !response.patient || !Object.keys(response.patient).length) {
        throw new Error();
      }
      return history.push({
        pathname: `/patients/${response.patient.patientId}`,
        state: {
          patient: response.patient
        },
        search,
      });
    } catch (error) {
      signalError(`Error getting patient with id "${query}". Please confirm the patient's id is valid or try again later.`);
      setTimeout(clearError, 5000);
      this.setState({ isLoading: false });
    }
  }

  patientsContext = (props) => {
    props = props || this.props;
    const { patients, location } = props;
    const orgId = location.search && new URLSearchParams(location.search).get("orgId");
    if (!orgId) {
      return patients;
    }
    return patients.orgs[orgId] ? patients.orgs[orgId] : { page: 0, data: [], key: null };
  }

  tableData = () => {
    const patientsData = this.patientsContext();
    return patientsData.data;
  }

  getPatients = async () => {
    const { getPatients, storePatients, location: { search }, signalError, clearError } = this.props;
    const orgId = new URLSearchParams(search).get("orgId");
    const key = this.getPaginationKey();
    this.setState({ isLoading: true });
    try {
      const response = await getPatients(orgId, key.patientIdKey, key.orgIdKey);
      if (response.success && response.patients.Items) {
        if (!response.patients.LastEvaluatedKey) {
          this.setState({ allPagesFulfilled: true });
        }
        if (response.patients.Items.length && this.page() + 1 === this.totalPages()) {
          this.changePage(this.page() + 1);
        }
        storePatients(
          response.patients.Items,
          response.patients.LastEvaluatedKey && response.patients.LastEvaluatedKey.orgId,
          response.patients.LastEvaluatedKey && response.patients.LastEvaluatedKey.patientId,
          orgId,
        );
      }
    } catch (e) {
      signalError("Error getting patients. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  sexLabel = (sex) => {
    switch (sex) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    case "O":
      return "Intersex";
    default:
      return "";
    }
  }

  formatCell = (key) => (cell) => {
    switch (key) {
    case "patientId":
    case "PatientID":
    case "firstName":
    case "lastName":
      return cell.value ? cell.value : null;
    case "dob":
      return cell.value && moment(cell.value).isValid() ? moment(cell.value).format("MM-DD-YYYY") : null;
    case "sex":
      return cell.value ? this.sexLabel(cell.value) : null;
    case "age":
      return cell.original.dob && moment(cell.original.dob).isValid() ? Math.abs(moment(cell.original.dob).diff(this.day, "years")) : null;
    case "more":
      return (
        <ItemGrid container xs={12} justify="flex-end">
          <Link to={{
            pathname: `/patients/${cell.original.patientId}`,
            state: { patient: cell.original },
            search: this.props.location.search,
          }}><ChevronRight /></Link>
        </ItemGrid>
      );
    default:
      return null;
    }
  }

  handleSelectChange = (dimension) => this.setState({ dimension })
  handleInputChange = (query) => this.setState({ query });

  handleCreatePatientRequest = () => {
    this.setState({
      modal: "createPatient"
    });
  }

  handleCreatePatientSubmit = async (patient) => {
    const { updatePatient, location: { search }, signalError, clearError } = this.props;
    const orgId = new URLSearchParams(search).get("orgId");
    this.setState({ isLoading: true });
    try {
      if (!patient) {
        throw new Error();
      }
      const response = await updatePatient(patient, orgId);
      if (response.success && response.patient) {
        window.location.reload();
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error creating patient. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false, modal: null });
  }

  changePage = (page) => {
    const { changePatientsPage, location: { search } } = this.props;
    const orgId = new URLSearchParams(search).get("orgId");
    return changePatientsPage(page, orgId);
  }

  getPaginationKey = () => {
    const patients = this.patientsContext();
    if (!patients.key) {
      return {};
    }

    let index = calculatePageIndex(patients.page + 1, 3);

    try {
      const key = patients.key[index];
      return key;
    } catch (e) {
      return {};
    }
  }

  page = () => {
    const patients = this.patientsContext();
    return patients.page;
  }

  totalPages = () => {
    const patients = this.patientsContext();
    return Math.floor(patients.data.length / 10);
  }

  formatHeader = (key) => {
    switch (key) {
    case "PatientID":
      return "Customer Patient ID";
    case "patientId":
      return "HealthLytix Patient ID";
    case "lastName":
      return "Last Name";
    case "firstName":
      return "First Name";
    case "dob":
      return "Date of Birth";
    case "sex":
      return "Sex";
    case "age":
      return "Age";
    default:
      return "";
    }
  }

  sortMethod = (key) => {
    if (["dob"].includes(key)) {
      return (a, b) => {
        switch (key) {
        case "dob":
          if (!a && !b) {
            return 0;
          } else if (!moment(a).isValid() && moment(b).isValid()) {
            return -1;
          } else if (!moment(b).isValid() && moment(a).isValid()) {
            return 1;
          }
          return moment(a).isBefore(moment(b)) ? 1 : -1;
        default:
          return undefined;
        }
      };
    }
    return undefined;
  }

  renderModal = () => {
    const { modal } = this.state;
    switch (modal) {
    case "createPatient":
      return this.renderPatientCreateModal();
    default:
      return null;
    }
  }

  renderPatientCreateModal = () => {
    const { isLoading } = this.state;
    return <PatientEditModal
      title="Create Patient"
      loading={isLoading}
      submitButtonText="Create"
      onSubmit={this.handleCreatePatientSubmit}
      onClose={() => this.setState({ modal: null })}
    />;
  }

  render() {
    const { query, dimension, isLoading, allPagesFulfilled } = this.state;
    return (
      <React.Fragment>
        {this.renderModal()}
        <GridContainer style={{ alignSelf: "stretch" }}>
          <ItemGrid xs={12}>
            <IconCard
              icon={Help}
              title="Information"
              iconColor="blue"
              content={<div>
                <h6><b>Patient View</b></h6>
                <p>List of all your patients in the HealthLytix Platform</p>
                <h6><b>Tip!</b> Create Patients Automatically</h6>
                <p>By sending Medical Studies to an EdgeGateway using the AE Title <b>HY-UPLOAD</b>, a new patient will be created if it doesn't exist, and the dicom study will be automatically attached and uploaded to the corresponding patient.</p>
              </div>}
            />
          </ItemGrid>
          <ItemGrid xs={12}>
            <SearchBar
              submitting={isLoading}
              query={query}
              dimension={dimension}
              selectOnChange={this.handleSelectChange}
              inputOnChange={this.handleInputChange}
              onSubmit={this.searchPatient}
              debounce={500}
            />
          </ItemGrid>
        </GridContainer>
        <IconCard
          icon={People}
          title="All Patients"
          iconColor="orange"
          content={
            <GridContainer>
              <ItemGrid xs={12}>
                {/* <Button right onClick={this.getPatients}>Refresh</Button> */}
                <Button right color="primary" onClick={this.handleCreatePatientRequest}>Create</Button>
              </ItemGrid>
              <ItemGrid xs={12}>
                <ReactTable
                  data={this.tableData()}
                  noDataText="No Patients"
                  columns={
                    [
                      { accessor: "patientId", maxWidth: 350 },
                      { accessor: "lastName" },
                      { accessor: "firstName" },
                      { accessor: "PatientID" },
                      { accessor: "dob", maxWidth: 140 },
                      { accessor: "sex", maxWidth: 120 },
                      { accessor: "age", maxWidth: 120, disableSort: true },
                      { accessor: "more", maxWidth: 60, disableSort: true }
                    ].map(c => ({ Header: this.formatHeader(c.accessor), maxWidth: c.maxWidth, Cell: this.formatCell(c.accessor), accessor: c.accessor, sortMethod: this.sortMethod(c.accessor), sortable: c.disableSort ? false : true }))
                  }
                  showPaginationBottom
                  loading={isLoading}
                  className="-highlight"
                  defaultPageSize={10}
                  minRows={10}
                  page={this.page()}
                  refreshing={isLoading}
                  PaginationComponent={Pagination}
                  canGetMorePages
                  onPageChange={this.changePage}
                  onGetMore={this.getPatients}
                  allPagesFulfilled={allPagesFulfilled}
                />
              </ItemGrid>
            </GridContainer>

          }
        />
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    patients: state.patients,
  }),
  (dispatch) => ({
    getPatient: (id, orgId) => getPatient(id, orgId),
    getPatients: (orgId, patientIdKey, orgIdKey) => getPatients(orgId, patientIdKey, orgIdKey),
    updatePatient: (patient, orgId) => updatePatient(patient, orgId),
    storePatients: (patients, orgIdKey, patientIdKey, orgId) => dispatch(storePatients(patients, orgIdKey, patientIdKey, orgId)),
    changePatientsPage: (page, orgId) => dispatch(changePatientsPage(page, orgId)),
  })
)(WithNotifications(AllPatients));