import React from "react";
import pt from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// material-ui-icons
import Face from "material-ui-icons/Face";
import RecordVoiceOver from "material-ui-icons/RecordVoiceOver";
import Email from "material-ui-icons/Email";
import LockOutline from "material-ui-icons/LockOutline";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputAdornment from "material-ui/Input/InputAdornment";
import Checkbox from "material-ui/Checkbox";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Check from "material-ui-icons/Check";
import { CircularProgress } from "material-ui/Progress";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Danger from "components/Typography/Danger.jsx";

// additional components
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

// api & utils
import { signup, confirm, login, authUser, invokeApig, getCurrentUserAccessToken } from "libs/awsLib";
import { authenticate, authenticating, logout, storeOrg } from "actions/index";
import { validatePassword, timeout, verifyLength, verifyEmail } from "libs/utils";
import { acceptTermsAndConditions, getMyOrg, publishAuditTrail } from "libs/apiLib";
import TermsAndConditions from "views/TermsAndConditions/TermsAndConditions";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    top: "3px",
    position: "relative",
  },
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch
};

class Step1 extends React.Component {
  static propTypes = {
    updateOrgOnStore: pt.func.isRequired,
    classes: pt.object.isRequired,
    isUserAuthenticating: pt.func.isRequired,
    isUserAuthenticated: pt.func.isRequired,
    isAuthenticated: pt.bool.isRequired,
    isAuthenticating: pt.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      firstnameState: "",
      lastname: "",
      lastnameState: "",
      email: "",
      emailState: "",
      password: "",
      passwordState: "",
      confirmPassword: "",
      confirmPasswordState: "",
      confirmationCode: null,
      alert: null,
      userConfirmed: false,
      userCreated: false,
      newUser: null,
      checked: [],
      modal: false,
      isLoading: false,
      disabled: false,
      error: ""
    };

    this.isValidated = this.isValidated.bind(this);
    this.confirmUserAlert = this.confirmUserAlert.bind(this);
    this.change = this.change.bind(this);
    this.receiveCode = this.receiveCode.bind(this);
  }

  change(event, stateName, type, stateNameEqualTo) {
    if (event.target) {
      switch (type) {
      case "email":
        if (verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        if (event.target.value)
        {event.target.value = event.target.value.toLowerCase();}
        break;
      case "length":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (verifyLength(event.target.value, stateNameEqualTo)
            && !validatePassword(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
      }
      this.setState({ [stateName]: event.target.value });
    }
  }

  validateForm() {
    if (
      this.state.firstnameState === "success" &&
      this.state.lastnameState === "success" &&
      this.state.emailState === "success" &&
      this.state.passwordState === "success" &&
      this.state.checked[0] === 1
    ) {
      return true;
    } else {
      if (this.state.firstnameState !== "success") {
        this.setState({ firstnameState: "error" });
      }
      if (this.state.lastnameState !== "success") {
        this.setState({ lastnameState: "error" });
      }
      if (this.state.emailState !== "success") {
        this.setState({ emailState: "error" });
      }
      if (this.state.passwordState !== "success") {
        this.setState({ passwordState: "error" });
      }
      if (this.state.confirmPasswordState !== "success") {
        this.setState({ confirmPasswordState: "error" });
      }
    }
    return false;
  }

  /**
   * Check if ready to move to the next registration step
   * This function is called by the Wizard when the user 
   * clicks on the "Next Button".
   * This functions triggers the creation of the new user, 
   * the confirmation of the new user, the login of the user and finally
   * the move to the next step
   */
  isValidated() {
    if (this.state.userConfirmed && this.validateForm()) {
      // user created and confirmed, plus logged in
      // move to step 2
      return true;
    } else if (this.state.userCreated && this.validateForm()) {
      // user created but not confirmed...
      // show confirmation prompt
      this.confirmUserAlert();
    } else if (this.validateForm()) {

      // create user
      this.showLoadingAlert();
      this.signUpUser();
      return false;

    } else {
      return false;
    }
  }

  async signUpUser() {
    this.setState({ isLoading: true });

    try {
      const newUser = await signup(this.state.email, this.state.password,
        this.state.firstname, this.state.lastname);
      this.setState({
        isLoading: false,
        newUser: newUser,
        email: this.state.email,
        password: this.state.password,
        userCreated: true,
        disabled: true
      }, () => {
        this.confirmUserAlert();
      });
    } catch (e) {
      this.setState({
        isLoading: false,
        error: e.message,
        alert: null
      });
    }
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  showLoadingAlert() {
    this.setState({
      alert: (
        <SweetAlert
          closeOnClickOutside={false}
          style={{ display: "block", marginTop: "-100px" }}
          title="Please wait..."
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
        >
          <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />
        </SweetAlert>
      )
    });
  }

  async receiveCode(confirmationCode) {

    this.showLoadingAlert();

    const { isUserAuthenticated, updateOrgOnStore } = this.props;

    try {
      await confirm(this.state.newUser, confirmationCode);
      await timeout(10000);
      await login(
        this.state.email,
        this.state.password
      );

      if (await authUser()) {

        // get user from platform context
        const accessToken = await getCurrentUserAccessToken();
        const result = await invokeApig({
          path: "/id/myUser",
          headers: {
            "access-token": accessToken
          }
        });

        if (!result)
        {throw new Error("Failed to get user (Code 1)");}
        else if (!result.user)
        {throw new Error("Failed to get user (Code 2)");}

        // update store
        isUserAuthenticated(true, result.user);
        const { user: { firstName, lastName, userName } } = result;

        // Publish audit that user logged in succesfully
        await publishAuditTrail({
          type: "addUserAuth",
          description: `${firstName} ${lastName} (${userName}) successfully logged in.`,
          PHI: false
        });

        // update User Acceptance
        await acceptTermsAndConditions();
        const org = await getMyOrg();

        if (!org)
        {throw new Error("Failed to get user (Code 3)");}
        else if (!org.org)
        {throw new Error("Failed to get user (Code 4)");}

        updateOrgOnStore(org.org);

        this.setState({
          userConfirmed: true,
          isLoading: false,
          alert: null
        }, () => {
          this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Done!"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
                Your user has been created. Click <strong>Next</strong> to continue.
              </SweetAlert>
            )
          });
        });
      } else {
        throw new Error("Failed to authenticate");
      }
    } catch (e) {
      this.setState({
        isLoading: false,
        alert: null,
        error: e.message
      });
    }
  }

  confirmUserAlert() {
    this.setState({
      alert: (
        <SweetAlert
          input
          closeOnClickOutside={false}
          style={{ display: "block", marginTop: "-100px" }}
          title={"Confirmation"}
          onConfirm={e => this.receiveCode(e)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.info
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        >
          <div>Enter the confirmation code that has just been sent to your email address</div>
        </SweetAlert>
      )
    });
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  closeTerms = () => {
    this.setState({ modal: false });
  }

  handleOpenTerms = (e) => {
    e.preventDefault();
    this.setState({ modal: true });
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        {this.state.alert}
        <TermsAndConditions onClose={this.closeTerms} modal={this.state.modal} />
        <ItemGrid xs={10} sm={10}>
          <h4 className={classes.infoText}>
            Let&apos;s start with the basic information
          </h4>
          <p className={classes.infoText}>In order to register, you need to be invited. For more information, please email support@healthlytix.com</p>
        </ItemGrid>
        {/* <ItemGrid xs={12} sm={4}>
          <PictureUpload />
        </ItemGrid> */}
        <ItemGrid xs={12} sm={10}>
          <CustomInput
            success={this.state.firstnameState === "success"}
            error={this.state.firstnameState === "error"}
            labelText={
              <span>
                First Name <small>(required)</small>
              </span>
            }
            id="firstname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: this.state.disabled,
              onChange: event => this.change(event, "firstname", "length", 2),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.lastnameState === "success"}
            error={this.state.lastnameState === "error"}
            labelText={
              <span>
                Last Name <small>(required)</small>
              </span>
            }
            id="lastname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: this.state.disabled,
              onChange: event => this.change(event, "lastname", "length", 2),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <RecordVoiceOver className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </ItemGrid>
        <ItemGrid xs={12} sm={10} md={10} lg={10}>
          <CustomInput
            success={this.state.emailState === "success"}
            error={this.state.emailState === "error"}
            labelText={
              <span>
                Email <small>(required)</small>
              </span>
            }
            id="email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: this.state.disabled,
              onChange: event => this.change(event, "email", "email"),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </ItemGrid>
        <ItemGrid xs={12} sm={6} md={6} lg={6}>
          <CustomInput
            success={this.state.passwordState === "success"}
            error={this.state.passwordState === "error"}
            labelText={
              <span>
                Password <small>(required)</small>
              </span>
            }
            id="password"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "password",
              id: "password",
              disabled: this.state.disabled,
              onChange: event => this.change(event, "password", "password", 8),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <LockOutline className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </ItemGrid>
        <ItemGrid xs={12} sm={4} md={4} lg={4}>
          <CustomInput
            success={this.state.confirmPasswordState === "success"}
            error={this.state.confirmPasswordState === "error"}
            labelText={
              <span>
                Confirm Password <small>(required)</small>
              </span>
            }
            id="confirmPassword"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: this.state.disabled,
              type: "password",
              id: "confirmPassword",
              onChange: event => this.change(event, "confirmPassword", "password", 8),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <LockOutline className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          
        </ItemGrid>
        <ItemGrid xs={12} sm={10}>
          <p style={{ fontSize: "12px"}}>Password must be at least 8 characters long, include an uppercase, lowercase and a number</p>
        </ItemGrid>
        <ItemGrid xs={12} sm={10}>
          <FormControlLabel
            classes={{
              root: classes.checkboxLabelControl,
              label: classes.checkboxLabel
            }}
            control={
              <Checkbox
                disabled={this.state.disabled}
                tabIndex={-1}
                onClick={() => this.handleToggle(1)}
                checkedIcon={
                  <Check className={classes.checkedIcon} />
                }
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked
                }}
              />
            }
            label={
              <span>
                I have read and agree to the{" "}
                <a href=""
                  onClick={this.handleOpenTerms}>Terms and Conditions and Privacy Policy</a> of this website and service.
              </span>
            }
          />
        </ItemGrid>
        {this.state.error &&
          <ItemGrid xs={12} sm={10} md={10} lg={10}>
            <div style={{ textAlign: "center" }}>
              <br />
              <Danger>
                {this.state.error}
              </Danger>
            </div>
          </ItemGrid>
        }
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticating: state.user.isAuthenticating,
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  org: state.user.org
});

const mapDispatchToProps = dispatch => ({
  isUserAuthenticated: (isAuthenticated, user) => {
    dispatch(authenticate(isAuthenticated, user));
  },
  isUserAuthenticating: (isAuthenticating) => {
    dispatch(authenticating(isAuthenticating));
  },
  logOut: () => {
    dispatch(logout());
  },
  updateOrgOnStore: (org) => {
    dispatch(storeOrg(org));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(Step1)));
