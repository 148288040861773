import {
  ASSUME_ORG,
  SURRENDER_ORG
} from "actions/ActionTypes";

export const initialState = null;

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
  case ASSUME_ORG:
    return action.org;
  case SURRENDER_ORG:
    return initialState;
  default:
    return state;
  }
};