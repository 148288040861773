import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

// react table
import ReactTable from "react-table";
import Pagination from "components/Pagination/ReactTablePagination";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button";
import IconCard from "components/Cards/IconCard";
import Badge from "components/Badge/Badge";
import Modal from "components/Modal";

// local components
import LicenseGenerate from "./LicenseGenerate";
import LicenseViewer from "./LicenseViewer";

// styles & icons
import withStyles from "material-ui/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";
import Offline from "material-ui-icons/LocationDisabled";

import { getLicenses } from "libs/apiLib";

import { storeNotificationError, removeNotificationError } from "actions";

const EDGEOS_APP_ID = "EDGEOS";
const EDGEOS_APP_NAME = "EdgeOS Gateway";

export class Licenses extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      label: PropTypes.string,
    }).isRequired,
    clearError: PropTypes.func.isRequired,
    getLicenses: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        orgID: PropTypes.string,
      }).isRequired,
    }).isRequired,
    signalError: PropTypes.func.isRequired,
    apps: PropTypes.arrayOf(PropTypes.shape({
      appId: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    })),
    edgeAllowed: PropTypes.bool,
    location: PropTypes.shape({
      state: PropTypes.shape({
        license: PropTypes.shape({})
      })
    })
  }

  constructor(props) {
    super(props);
    const { location, match } = props;
    this.state = {
      keyOne: "",
      keyTwo: "",
      licenses: null,
      loading: false,
      page: 0,
      modal: location && location.state && location.state.license && this.renderLicenseViewer(location.state.license) || null,
      showGenerateForm: false,
      orgID: match.params.orgID,
      allPagesFulfilled: false,
    };
  }

  async componentDidMount() {
    await this.getLicenses();
  }

  getLicenses = async () => {
    const { getLicenses, signalError, clearError } = this.props;
    const { keyOne, keyTwo, orgID } = this.state;
    this.setState({
      loading: true
    });
    try {
      const result = await getLicenses(keyOne, keyTwo, orgID);
      if (result && result.success && result.licenses.Items.length) {
        this.setState(prevState => {
          return {
            ...prevState,
            allPagesFulfilled: result.licenses.LastEvaluatedKey === undefined,
            keyOne: result.licenses.LastEvaluatedKey ? result.licenses.LastEvaluatedKey.orgId : "",
            keyTwo: result.licenses.LastEvaluatedKey ? result.licenses.LastEvaluatedKey.SerialNumber : "",
            licenses: prevState.licenses ? [...prevState.licenses, ...result.licenses.Items] : result.licenses.Items,
            page: prevState.licenses && prevState.page + 1 === Math.floor(prevState.licenses.length / 10) ? prevState.page + 1 : prevState.page,
          };
        });
      }
    } catch (e) {
      signalError("Error getting licenses. Try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  formatData = (data) => data.map(d => ({
    app: d.App,
    appID: d.appId,
    created: d.createdOn,
    deinstalledOn: d.deinstalledOn,
    expiration: d.Expiration,
    installed: d.installed,
    serialNumber: d.SerialNumber,
  }))

  formatCell = (key) => (cell) => {
    const { classes } = this.props;
    const dateFormat = "dddd, MMMM Do YYYY";
    let value = cell.value;
    let label;
    switch (key) {
    case "appID":
      label = "App ID";
      break;
    case "app":
      label = "App";
      value = <a style={{ cursor: "pointer" }} onClick={() => this.handleLicenseClick(cell.original.serialNumber)}>{value}</a>;
      break;
    case "expiration":
      value = moment(value).format(dateFormat);
      label = "Expires";
      break;
    case "installed":
      if (!value && cell.original.deinstalledOn) {
        value = moment(cell.original.deinstalledOn).format(dateFormat);
        label = "Uninstalled";
      } else {
        value = <Badge color="success">Installed</Badge>;
        label = null;
      }
      break;
    case "created":
      value = moment(value).format(dateFormat);
      label = "Created";
      break;
    default:
      return null;
    }
    return (
      <div>
        <div>{value}</div>
        {label && <div className={classes.label}>{label}</div>}
      </div>
    );
  }

  handleLicenseClick = (serialNumber) => this.setState({
    modal: (
      <Modal style={{ width: "775px", height: "900px" }}>
        <LicenseViewer
          serialNumber={serialNumber}
          onCancel={this.removeModal}
          onUninstalled={this.handleUninstalledLicense}
        />
      </Modal>
    )
  })

  getPages = () => this.state.licenses ? Math.floor(this.state.licenses.length / 10) : 0

  changePage = (page) => {
    this.setState({ page });
  }

  renderLicenseViewer = (license) => (
    <Modal style={{ width: "775px", height: "900px" }}>
      <LicenseViewer
        license={license}
        onCancel={this.removeModal}
        onUninstalled={this.handleUninstalledLicense}
      />
    </Modal>
  )

  handleOnComplete = (license) => this.setState(prevState => ({
    ...prevState,
    licenses: prevState.licenses ? [...prevState.licenses, license] : [license],
    showGenerateForm: false,
    modal: this.renderLicenseViewer(license)
  }))

  handleUninstalledLicense = (serialNumber) => this.setState(prevState => ({
    ...this.uninstallLicense(prevState, serialNumber)
  }))

  uninstallLicense = (state, serialNumber) => {
    const i = state.licenses.findIndex(l => l.SerialNumber === serialNumber);
    if (i === -1) {
      return state;
    }
    const licenses = [...state.licenses];
    licenses[i].installed = false;
    licenses[i].deinstalledOn = moment().format();
    return {
      ...state,
      licenses,
    };
  }

  removeModal = () => this.setState({ modal: null })

  enabledApps = () => {
    const { apps, edgeAllowed } = this.props;
    const results = apps ? apps.reduce((memo, a) => {
      if (!a.enabled) {
        return memo;
      }
      return [...memo, a];
    }, []) : [];
    if (edgeAllowed) {
      results.push({ name: EDGEOS_APP_NAME, appId: EDGEOS_APP_ID, enabled: true });
    }
    return results;
  }

  render() {
    const { licenses, loading, page, modal, showGenerateForm, allPagesFulfilled } = this.state;
    const pages = this.getPages();
    const formColumn = showGenerateForm ? 12 : 8;
    const data = licenses ? this.formatData(licenses) : [];
    return (
      <IconCard
        iconColor="blue"
        icon={Offline}
        title="Offline Licenses"
        horizontalPadding="medium"
        content={
          <GridContainer>
            {modal}
            {!showGenerateForm && <ItemGrid xs={12} sm={4}><p style={{ color: "gray", fontSize: "small" }}>View, Generate, and Delete Offline Licenses</p></ItemGrid>}
            <ItemGrid xs={12} sm={formColumn}>
              {
                showGenerateForm ?
                  <LicenseGenerate
                    apps={this.enabledApps()}
                    orgID={this.state.orgID}
                    onCancel={() => this.setState({ showGenerateForm: false })}
                    onComplete={this.handleOnComplete}
                    onUninstalled={this.handleUninstalledLicense}
                  /> :
                  <Button right color="primary" onClick={() => this.setState({ showGenerateForm: true })}>Generate</Button>
              }
            </ItemGrid>
            <ItemGrid xs={12}>
              <ReactTable
                sortable={false}
                data={data}
                noDataText="No Licenses"
                minRows={1}
                defaultPageSize={10}
                showPaginationBototom
                className="-highlight"
                columns={[
                  {
                    accessor: "app"
                  },
                  {
                    accessor: "appID"
                  },
                  {
                    accessor: "created"
                  },
                  {
                    accessor: "expiration"
                  },
                  {
                    accessor: "installed"
                  }]
                  .map(c => ({ Cell: this.formatCell(c.accessor), Header: "", accessor: c.accessor }))
                }
                canGetMorePages
                onPageChange={this.changePage}
                onGetMore={this.getLicenses}
                page={page}
                loading={loading}
                refreshing={loading}
                PaginationComponent={Pagination}
                allPagesFulfilled={allPagesFulfilled}
              />
            </ItemGrid>
          </GridContainer>
        }
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearError: () => dispatch(removeNotificationError()),
  signalError: (msg) => dispatch(storeNotificationError(msg)),

  // does not use dispatch
  getLicenses: (keyOne, keyTwo, orgId) => getLicenses(keyOne, keyTwo, orgId),
});

export default connect(undefined, mapDispatchToProps)(withRouter(withStyles(styles)(Licenses)));