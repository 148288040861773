import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import AccountBox from "material-ui-icons/AccountBox";
import InputLabel from "material-ui/Input/InputLabel";
import { CircularProgress } from 'material-ui/Progress';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "material-ui/Form/FormControl";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

// utils
import { verifyLength, verifyEmail } from 'libs/utils';
import { storeUser } from 'actions/index';
import { updateMyProfile } from 'libs/apiLib';

// styles
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";

const style = {
  ...sweetAlertStyle,
  ...dashboardStyle,
  ...customInputStyle,
}

export class MyProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: null,
      error: "",
      admin: false,
      isViewer: false,
      isResearch: false,
      firstName: "",
      firstNameState: "",
      lastName: "",
      lastNameState: "",
      userName: "",
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.setState({
      firstName: user.firstName || "",
      firstNameState: user.firstName ? "success" : "error",
      lastName: user.lastName || "",
      lastNameState: user.lastName ? "success" : "error",
      userName: user.userName || "",
      admin: user.admin || false,
      isViewer: user.isViewer || false,
      isResearch: user.isResearch || false,
      })
    }
  }
  
  change(event, stateName, type, stateNameEqualTo) {
    if (event.target) {
      switch (type) {
        case "email":
          if (verifyEmail(event.target.value)) {
            this.setState({ [stateName + "State"]: "success" });
          } else {
            this.setState({ [stateName + "State"]: "error" });
          }
          break;
        case "length":
          if (verifyLength(event.target.value, stateNameEqualTo)) {
            this.setState({ [stateName + "State"]: "success" });
          } else {
            this.setState({ [stateName + "State"]: "error" });
          }
          break;
        default:
          break;
      }
      this.setState({ [stateName]: event.target.value });
    }
  }

  isFormValid() {
    if (
      ['firstNameState', 'lastNameState'].every(key => this.state[key] === "success")
    ) {
      return true
    }
    return false
  }

  validateForm() {
    if (this.isFormValid()) 
      return true;
    else {
      if (this.state.firstNameState !== "success") {
        this.setState({ firstNameState: "error" });
      }
      if (this.state.lastNameState !== "success") {
        this.setState({ lastNameState: "error" });
      }
    }
    return false;
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  showLoadingAlert() {
    this.setState({
      alert: (
        <SweetAlert
          closeOnClickOutside={false}
          style={{ display: "block", marginTop: "-100px" }}
          title="Please wait..."
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
        >
          <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />
        </SweetAlert>
      )
    });
  }
  
  updateProfile = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.showLoadingAlert();

      const { updateUserOnStore } = this.props;
      const { firstName, lastName } = this.state;

      try {
        // update the user
        const response = await updateMyProfile({
          firstName,
          lastName,
        });

        if (!response || !response.updatedUser || !response.success)
          throw new Error("Failed to update user");

        updateUserOnStore(response.updatedUser);

        this.setState({
          alert: (
            <SweetAlert
              success
              closeOnClickOutside={false}
              style={{ display: "block", marginTop: "-100px" }}
              title="Done!"
              onConfirm={() => this.props.history.push('/dashboard')}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              Profile Updated!
            </SweetAlert>
          )
        });
      } catch (e) {
        this.setState({
          alert: null,
          error: e.message
        });
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          {this.state.alert}
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <IconCard
              icon={AccountBox}
              iconColor="orange"
              title="My Profile - "
              category="Settings and Preferences"
              content={
                <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <CustomInput
                        id="userName"
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Username
                          </span>
                        }
                        inputProps={{
                          value: this.state.userName,
                          disabled: true,
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={4}>
                      <CustomInput
                        id="firstName"
                        success={this.state.firstNameState === "success"}
                        error={this.state.firstNameState === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            First Name <small>(required)</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.firstName,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "firstName"),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={4}>
                      <CustomInput
                        id="lastName"
                        success={this.state.lastNameState === "success"}
                        error={this.state.lastNameState === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Last Name <small>(required)</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.lastName,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "lastName"),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12}>
                    <FormControl
                      className={classes.formControl}
                    >
                      <InputLabel
                        shrink
                        className={classes.labelRoot}
                        htmlFor="accessLevel"
                       >
                       Access Level
                      </InputLabel>
                      <div style={{ marginTop: "16px" }}>
                        {this.state.admin && <Button id='admin' size="xs" round>Admin</Button>}
                        {this.state.isViewer && <Button id='viewer' size="xs" style={{ marginLeft: "5px" }} round color="info">Viewer</Button>}
                        {this.state.isResearch && <Button id='research' size="xs" style={{ marginLeft: "5px" }} round color="success">Research</Button>}
                      </div>
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                  <Button
                    color="primary" 
                    disabled={!this.isFormValid()}
                    right
                    onClick={this.updateProfile}
                  >
                    Update Profile
                  </Button>
                  <Clearfix />
                </div>
              }
            />
          </ItemGrid>
        </GridContainer>
      </div>
    );
  }
}

MyProfile.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
  updateUserOnStore: (user) => {
    dispatch(storeUser(user))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(MyProfile));
