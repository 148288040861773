import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";
import { APPSSET } from "constants/Apps";
import { WithNotifications } from "components/HigherOrder";
import Job from "models/Job";
import { userSelector, accessControlSelector } from "selectors";
import DatePickerButton from "components/DatePickerButtons/DatePickerButton";
import Dropdown from "components/CustomDropdown/CustomDropdown";
import Input from "components/CustomInput/CustomInput";

// material ui
import withStyles from "material-ui/styles/withStyles";
import Switch from "material-ui/Switch";
import FormControlLabel from "material-ui/Form/FormControlLabel";

// material-ui-icons
import InfoOutline from "material-ui-icons/InfoOutline";
import CloudIcon from "material-ui-icons/CloudQueue";
import DeviceIcon from "material-ui-icons/Devices";
import SearchIcon from "material-ui-icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "material-ui/IconButton";
import Modal from "components/Modal";

import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

import Pagination from "components/Pagination/ReactTablePagination";

// actions and util functions
import { getJobs, getAllowedApps, searchJobs } from "libs/apiLib";
import { storeCases, refreshCases, changeCasesPage, replaceSearchedJobs, updateJobSearchFilters  } from "actions/index";
import { calculatePageIndex } from "libs/utils";

export class Cases extends React.Component {
  static propTypes = {
    searchFilters: PropTypes.shape({
      start: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
      end: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
      jobStatus: PropTypes.string,
      appName: PropTypes.string,
      StudyInstanceUID: PropTypes.string,
      AccessionNumber: PropTypes.string,
      StationName: PropTypes.string,
      consumerAE: PropTypes.string,
      PatientID: PropTypes.string,
    }).isRequired,
    searchedJobs: PropTypes.arrayOf(PropTypes.shape({
    })).isRequired,
    updateJobSearchFilters: PropTypes.func.isRequired,
    replaceSearchedJobs: PropTypes.func.isRequired,
    searchJobs: PropTypes.func.isRequired,
    getAllowedApps: PropTypes.func.isRequired,
    orgID: PropTypes.string,
    cases: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      key: PropTypes.arrayOf(PropTypes.string),
      page: PropTypes.number,
    }).isRequired,
    classes: PropTypes.shape({
      label: PropTypes.string.isRequired,
      switchChecked: PropTypes.string.isRequired,
      switchBarChecked: PropTypes.string.isRequired,
      switchIcon: PropTypes.string.isRequired,
      switchUnchecked: PropTypes.string.isRequired,
      switchIconChecked: PropTypes.string.isRequired,
    }),
    getJobs: PropTypes.func.isRequired,
    storeCases: PropTypes.func.isRequired,
    refreshCases: PropTypes.func.isRequired,
    changeCasesPage: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    signalError: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      autoRefresh: false,
      timer: null,
      isLoading: false,
      refreshing: false,
      lastRefresh: null,
      modal: false,
      apps: {
        fetched: false,
        data: []
      }
    };
  }

  async componentDidMount() {
    const { cases } = this.props;
    let { orgID } = this.props;

    // fetch jobs if this is first mount & no cases exists yet
    if (orgID && !cases[orgID]) {
      await this.updateJobs();
    } else if (!orgID && !cases.data) {
      await this.updateJobs();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // fetch jobs if this is first mount & if moving from org dashboard to user dash
    if (prevProps.orgID && !this.props.orgID && !this.props.cases.data) {
      await this.updateJobs();
    }

    // fetch jobs if orgID has been passed down, previous prop orgID didnt exist, and no cases exist yet
    if (!prevProps.orgID && this.props.orgID && !this.props.cases[this.props.orgID]) {
      await this.updateJobs();
    }

    // fetch licensed apps for org on open of modal and when request has not been performed yet
    if (this.state.modal && !prevState.apps.fetched && !this.state.apps.fetched) {
      await this.getAllowedApps();
    }
  }

  async getAllowedApps() {
    const { getAllowedApps, signalError, orgID, user } = this.props;
    this.setState(prevState => ({ ...prevState, apps: { ...prevState.apps, fetched: true } }));
    try {
      const results = await getAllowedApps(orgID || user.orgId);
      if (results.success && results.apps) {
        this.setState(prevState => ({ ...prevState, apps: { ...prevState.apps, data: results.apps } }));
      }
    } catch (e) {
      signalError("Error getting list of apps for job search. Please try again later.");
    }
  }

  changePage = (page) => this.props.changeCasesPage(page, this.props.orgID);

  getContext = () => {
    const { orgID, cases } = this.props;
    if (orgID && cases[orgID]) {
      return cases[orgID];
    } else if (orgID) {
      return null;
    } else {
      return cases;
    }
  }

  getPage = () => {
    const context = this.getContext();
    return context ? context.page : 0;
  }

  updateJobs = async (refresh) => {
    this.setState({ isLoading: true });

    const { orgID, getJobs, refreshCases, storeCases, accessControl } = this.props;
    const key = this.getPaginationKey(refresh);

    try {
      // get the jobs
      if (refresh) {
        this.setState({ refreshing: true });
        const result = await getJobs(orgID, key);
        if (result.result.Items) {
          const cases = accessControl.hidePHI ? result.result.Items.map(Job.anonymize) : result.result.Items;
          refreshCases(cases, key, orgID);
        }
        this.setState({ isLoading: false, refreshing: false, lastRefresh: moment() });
        return;
      }

      const result = await getJobs(orgID, key);
      if (result.result.Items) {
        const page = this.getPage();
        const pages = this.getPages();
        if (page + 1 === pages) {
          this.changePage(page + 1);
        }
        let k = null;
        if (
          result.result.LastEvaluatedKey &&
          result.result.LastEvaluatedKey.eventId
        ) {
          k = result.result.LastEvaluatedKey.eventId;
        }
        let count = result.result.Count || 0;
        const cases = accessControl.hidePHI ? result.result.Items.map(Job.anonymize) : result.result.Items;
        storeCases(cases, count, k, orgID);
      }
    } catch (e) {
      this.props.signalError(`Error getting jobs: ${e.message ? e.message : e}.`);
      setTimeout(this.props.clearError, 10000);
    }

    this.setState({ isLoading: false, refreshing: false });
  }

  getPaginationKey = (refresh) => {
    const context = this.getContext();
    if (!context || !context.key) {
      return "";
    }

    const page = this.getPage() + 1;
    let index = calculatePageIndex(page, 3);
    if (!refresh) {
      index++;
    }

    try {
      const key = context.key[index];
      return key;
    } catch (e) {
      return "";
    }
  }

  handleAutoRefreshToggle = event => {
    const toggle = event.target.checked;
    if (toggle) {
      this.setState({
        autoRefresh: toggle,
        timer: setInterval(this.updateJobs.bind(this, true), 300000) // five minutes
      });
    } else if (this.state.timer) {
      clearInterval(this.state.timer);
      this.setState({
        timer: null,
        autoRefresh: false
      });
    }
  }

  getColor(jobStatus) {
    if (jobStatus === "Finished Processing") {
      return "success";
    } else if (jobStatus === "Failed") {
      return "danger";
    } else if (jobStatus === "Queued" || jobStatus === "Receiving Dicoms" || jobStatus === "Waiting for Dicoms") {
      return "primary";
    } else if (jobStatus === "Pending" || jobStatus === "Waiting") {
      return "rose";
    } else if (jobStatus === "Invalid Input" || jobStatus === "Invalid") {
      return "";
    } else {
      return "warning";
    }
  }

  getJobStatus(jobStatus) {
    if (jobStatus === "Finished Processing") {
      return "Finished";
    } else if (jobStatus === "Receiving Dicoms") {
      return "Receiving";
    } else if (jobStatus === "Downloading To Edge") {
      return "Downloading";
    } else {
      return jobStatus;
    }
  }

  getCases = () => {
    const context = this.getContext();
    if (!context || !context.data) {
      return [];
    }
    return context.data;
  }

  getPages = () => {
    const cases = this.getCases();
    if (!cases.length) { return 1; }
    return Math.ceil(cases.length / 10);
  }

  allPagesFulfilled = () => {
    const { orgID, cases } = this.props;
    if (orgID && cases[orgID]) {
      return cases[orgID].allPagesFulfilled;
    } else if (orgID) {
      return false;
    } else {
      return cases.allPagesFulfilled;
    }
  }

  columns = () => {
    return [
      {
        accessor: "mode",
        maxWidth: 50
      },
      {
        accessor: "PatientID",
        maxWidth: 400
      },
      {
        accessor: "AccessionNumber",
        maxWidth: 200
      },
      {
        accessor: "appName",
        maxWidth: 150
      },
      {
        accessor: "consumerAE"
      },
      {
        accessor: "StudyInstanceUID"
      },
      {
        accessor: "StationName"
      },
      {
        accessor: "withPrior",
        maxWidth: 100
      },
      {
        accessor: "received",
        maxWidth: 125
      },
      {
        accessor: "status",
        maxWidth: 150
      }
    ].map(c => ({ Header: "", accessor: c.accessor, maxWidth: c.maxWidth, Cell: this.formatCell(c.accessor) }));
  }

  formatCell = (key) => (cell) => {
    const isCloudJob = cell.original.mode === "CLOUD" || APPSSET.has(cell.original.appId);
    switch (key) {
    case "PatientID":
      return cell.original.PatientID ? <Cell label="PatientID" value={cell.original.PatientID} /> : <Cell label="HealthLytix Patient ID" value={cell.original.patientId || ""} overflowXScroll />;
    case "received":
      return <Cell label={`Started ${moment.duration(moment().diff(moment(cell.original.received))).humanize()} ago`} value={moment(cell.original.received).format("MM-DD-YYYY")} />;
    case "status":
      return (
        <Button
          color={this.getColor(cell.original.jobStatus)}
          round
          disabled={this.state.refreshing}
          onClick={() => {
            const urlsp = new URLSearchParams(this.props.location.search);
            urlsp.append("id", cell.original.eventId);
            const search = urlsp.toString();
            this.props.history.push({
              pathname: "/app/case",
              search,
              state: {
                job: cell.original
              }
            });
          }}
        >
          {this.getJobStatus(cell.original.jobStatus)}
        </Button>
      );
    case "appName":
      return <Cell label="Application" value={cell.original.appName} />;
    case "appId":
      return <Cell label="Application" value={this.parseAppNameFromId(cell.original.appName)} />;
    case "mode":
      if (isCloudJob) {
        return <CloudIcon style={{ color: "gray" }} />;
      }
      return <DeviceIcon style={{ color: "gray" }} />;
    case "AccessionNumber":
      return <Cell label="Accession #" value={cell.original.AccessionNumber || ""} overflowXScroll />;
    case "withPrior":
      return  cell.original.priorDir &&
        (
          <Button size="xs" round color="primary">
          With Prior
          </Button>
        );
    case "consumerAE":
      return <Cell label="Consumer" value={cell.original.consumerAE || ""} overflowXScroll />;
    case "StudyInstanceUID":
      return <Cell label="Study Instance ID" value={cell.original.StudyInstanceUID || ""} overflowXScroll />;
    case "StationName":
      return <Cell label="Scanner" value={cell.original.StationName || ""} overflowXScroll />;
    default:
      return null;
    }
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.props.updateJobSearchFilters(name, value);
  }

  appDropdownList() {
    return [{ name: "None" }, ...this.state.apps.data].map(app => app.name);
  }

  handleAppDropdownClick = (appName) => {
    this.props.updateJobSearchFilters("appName", appName);
  }

  parseAppIdFromName(appName) {
    const app = this.state.apps.data.find(app => app.name === appName);
    let appId;
    if (app) {
      appId = app.appId;
    }
    return appId;
  }

  parseAppNameFromId(id) {
    const app = this.state.apps.data.find(app => app.appId === id);
    let name;
    if (app) {
      name = app.name;
    }
    return name;
  }

  searchedJobColumns = () => {
    return [
      {
        accessor: "appName",
        maxWidth: 150
      },
      {
        accessor: "PatientID",
        maxWidth: 200
      },
      {
        accessor: "AccessionNumber",
        maxWidth: 200
      },
      {
        accessor: "consumerAE"
      },
      {
        accessor: "StudyInstanceUID"
      },
      {
        accessor: "StationName"
      },
      {
        accessor: "received",
        maxWidth: 125
      },
      {
        accessor: "status",
        maxWidth: 150
      }
    ].map(c => ({ Header: "", accessor: c.accessor, maxWidth: c.maxWidth, Cell: this.formatCell(c.accessor) }));
  }

  handleSearchSubmit = async () => {
    const { signalError, searchJobs, orgID, searchFilters } = this.props;
    try {
      const queryParams = {
        orgId: orgID,
        start: moment(searchFilters.start).format(),
        end: moment(searchFilters.end).format(),
        appId: this.parseAppIdFromName(searchFilters.appName),
        jobStatus: searchFilters.jobStatus === "None" ? undefined : searchFilters.jobStatus,
        StudyInstanceUID: searchFilters.StudyInstanceUID && searchFilters.StudyInstanceUID.length ? searchFilters.StudyInstanceUID : undefined,
        AccessionNumber: searchFilters.AccessionNumber && searchFilters.AccessionNumber.length ? searchFilters.AccessionNumber : undefined,
        StationName: searchFilters.StationName && searchFilters.StationName.length ? searchFilters.StationName : undefined,
        consumerAE: searchFilters.consumerAE && searchFilters.consumerAE.length ? searchFilters.consumerAE : undefined,
        PatientID: searchFilters.PatientID && searchFilters.PatientID.length ? searchFilters.PatientID : undefined,
      };
      const results = await searchJobs(queryParams);
      if (results.success && results.jobs) {
        this.props.replaceSearchedJobs(results.jobs);
      }
    } catch (error) {
      signalError(`Error performing search. ${error}`);
    }
  }

  handleDateChange = (dimension, date) => {
    this.props.updateJobSearchFilters(dimension, moment(date).format("YYYY-MM-DD"));
  }

  renderSearchModal() {
    return (
      <Modal
        style={{
          height: "90%",
          width: "90%",
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer direction="row" style={{ height: "100%", padding: "0 40px" }}>
          <ItemGrid xs={12} style={{ alignSelf: "flex-start" }}><h2 style={{ textAlign: "center" }}>Job Search</h2></ItemGrid>
          <ItemGrid xs={6}>
            <p>Start Date</p>
            <DatePickerButton
              color="primary"
              pickerAlign="center"
              onChange={(date) => this.handleDateChange("start", date)}
              defaultValue={moment(this.props.searchFilters.start)}
              viewDate={moment()}
              start={moment(this.props.searchFilters.start)}
              dateFormat="MM-DD-YYYY"
              closeOnSelect
            />
          </ItemGrid>
          <ItemGrid xs={6}>
            <p>End Date</p>
            <DatePickerButton
              color="primary"
              pickerAlign="center"
              onChange={(date) => this.handleDateChange("end", date)}
              defaultValue={moment(this.props.searchFilters.end)}
              viewDate={moment()}
              start={moment(this.props.searchFilters.end)}
              dateFormat="MM-DD-YYYY"
              closeOnSelect
            />
          </ItemGrid>
          <ItemGrid xs={3}>
            <p>Status</p>
            <Dropdown
              buttonColor="primary"
              buttonText={this.props.searchFilters.jobStatus || "Choose"}
              dropdownList={[
                "None",
                "Success",
                "Failed",
                "Running",
                "Pending",
                "Queued",
                "Receiving Dicoms",
                "Invalid Input"
              ]}
              onAction={action => this.props.updateJobSearchFilters("jobStatus", action)}
            />
          </ItemGrid>
          <ItemGrid xs={3}>
            <p>App</p>
            <Dropdown
              buttonColor="primary"
              buttonText={this.props.searchFilters.appName || "Choose"}
              dropdownList={this.appDropdownList()}
              onAction={this.handleAppDropdownClick}
            />
          </ItemGrid>
          <ItemGrid xs={3}>
            <p>Study Instance ID</p>
            <Input
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "StudyInstanceUID",
                value: this.props.searchFilters.StudyInstanceUID || "",
                onChange: e => this.handleInputChange(e)
              }}
            />
          </ItemGrid>
          <ItemGrid xs={3}>
            <p>Accession Number</p>
            <Input
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "AccessionNumber",
                value: this.props.searchFilters.AccessionNumber || "",
                onChange: this.handleInputChange
              }}
            />
          </ItemGrid>
          <ItemGrid xs={3}>
            <p>Scanner Name</p>
            <Input
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "StationName",
                value: this.props.searchFilters.StationName || "",
                onChange: this.handleInputChange
              }}
            />
          </ItemGrid>
          <ItemGrid xs={3}>
            <p>Consumer AE Title</p>
            <Input
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "consumerAE",
                value: this.props.searchFilters.consumerAE || "",
                onChange: this.handleInputChange
              }}
            />
          </ItemGrid>
          <ItemGrid xs={3}>
            <p>Patient ID (DICOM)</p>
            <Input
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "PatientID",
                value: this.props.searchFilters.PatientID || "",
                onChange: this.handleInputChange
              }}
            />
          </ItemGrid>
          <ItemGrid xs={3} style={{marginTop: 20 }}>
            <Button
              fullWidth
              color="success"
              onClick={this.handleSearchSubmit}
            >
                Search
            </Button>
          </ItemGrid>
          <ItemGrid xs={12}>
            <ReactTable
              sortable={false}
              data={this.props.searchedJobs}
              noDataText="No results"
              columns={this.searchedJobColumns()}
              pageSize={10}
              showPaginationBottom
              className="-highlight"
            />
          </ItemGrid>
          <ItemGrid container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }} xs={12}>
            <Button onClick={() => this.setState({ modal: false })}>Close</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }

  render() {
    const { classes } = this.props;
    const { autoRefresh, refreshing, isLoading } = this.state;

    return (
      <GridContainer>
        {this.state.modal && this.renderSearchModal()}
        <ItemGrid xs={12}>
          <IconCard
            icon={InfoOutline}
            title="Latest Cases"
            iconColor="green"
            content={
              <React.Fragment>
                <ItemGrid xs={12}>
                  <div style={{ textAlign: "right" }}>
                    <p
                      style={{
                        fontSize: "small",
                        color: "gray",
                        margin: 0,
                      }}
                    >
                      {this.state.lastRefresh && `Last refreshed at ${moment(this.state.lastRefresh).format("h:m:s a")}`}
                    </p>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={refreshing}
                          checked={autoRefresh}
                          onChange={e => this.handleAutoRefreshToggle(e)}
                          value="autoRefresh"
                          classes={{
                            checked: classes.switchChecked,
                            bar: classes.switchBarChecked,
                            icon: classes.switchIcon,
                            default: classes.switchUnchecked,
                            iconChecked: classes.switchIconChecked
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={
                        autoRefresh
                          ? "Auto Refresh is On"
                          : "Auto Refresh is Off"
                      }
                    />
                    <Button
                      color="primary"
                      disabled={refreshing}
                      onClick={() => this.updateJobs(true)}
                    >
                        Refresh
                    </Button>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => this.setState({ modal: true })}
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                </ItemGrid>
                <ReactTable
                  sortable={false}
                  data={this.getCases()}
                  noDataText="No Cases!"
                  columns={this.columns()}
                  refreshing={refreshing}
                  page={this.getPage()}
                  pageSize={10}
                  showPaginationBottom
                  loading={isLoading}
                  className="-highlight"
                  onGetMore={this.updateJobs}
                  onPageChange={this.changePage}
                  PaginationComponent={Pagination}
                  allPagesFulfilled={this.allPagesFulfilled()}
                  canGetMorePages
                />
              </React.Fragment>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  cases: state.cases,
  user: userSelector(state),
  accessControl: accessControlSelector(state),
  searchedJobs: state.search.jobs.data, 
  searchFilters: state.search.jobs.filters,
});

const mapDispatchToProps = dispatch => ({
  storeCases: (cases, total, key, orgID) => {
    dispatch(storeCases(cases, total, key, orgID));
  },
  refreshCases: (cases, key, orgID) => {
    dispatch(refreshCases(cases, key, orgID));
  },
  changeCasesPage: (page, orgID) => {
    dispatch(changeCasesPage(page, orgID));
  },
  getJobs: (orgID, key) => getJobs(orgID, key),
  replaceSearchedJobs: jobs => dispatch(replaceSearchedJobs(jobs)),
  updateJobSearchFilters: (key, value) => dispatch(updateJobSearchFilters(key, value)),
  getAllowedApps,
  searchJobs
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  WithNotifications(
    withRouter(
      withStyles(styles)(Cases)
    )
  )
);
