import React from "react";
import pt from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { WithNotifications } from "components/HigherOrder";
import User from "models/User";
import Job from "models/Job";
import AccessToken from "models/AccessToken";
import { Sculog } from "models/Sculog";
import { userSelector, accessControlSelector, searchParamsSelector, accessTokenSelector } from "selectors";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard";
import Button from "components/CustomButtons/Button.jsx";
import LoadingModal from "components/LoadingModal/LoadingModal.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Table from "components/Table/Table";
import Badge from "components/Badge/Badge";

// material-ui icons
import AddAlert from "material-ui-icons/AddAlert";
import DescriptionIcon from "material-ui-icons/Description";
import BuildIcon from "material-ui-icons/Build";

// local components
import LogViewer from "./components/LogViewer";

// api & libs
import { UInt8ArrToString } from "libs/utils";
import { downloadFileFromS3, listFilesFromS3, headObject } from "libs/awsLib";
import {
  getJob, getWebPreviewToken,
  deleteJobOnEdge, deleteJobOnCloud, replayJobOnEdge,
  sendDicomRoutes, uploadJobToCloud,
  getJobInputDownloadURL
} from "libs/apiLib";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const style = {
  ...dashboardStyle,
  ...sweetAlertStyle
};

class Case extends React.Component {
  constructor(props) {
    super(props);
    let job = props.location && props.location.state && props.location.state.job || null;
    if (props.accessControl && props.accessControl.hidePHI && job) {
      job = Job.anonymize(job);
    }
    this.state = {
      job,
      isLoading: false,
      modal: null,
      scuLogs: {},
      appLogs: { exists: false, log: null }
    };

    this.handleGetLogs = this.handleGetLogs.bind(this);
    this.handleViewCaseDetails = this.handleViewCaseDetails.bind(this);
  }

  async componentDidUpdate(prevProps) {
    const { location: { state }, accessControl } = this.props;
    if (state && state.job && this.state.job && state.job.eventId !== this.state.job.eventId) {
      // another job is replacing this job (possibly from a job/case search)
      this.setState({
        job: accessControl.hidePHI ? Job.anonymize(state.job) : state.job,
        modal: null,
        scuLogs: {},
        appLogs: { fetched: false, exists: false, log: null }
      });
    }

    if (!prevProps.accessToken && this.props.accessToken && this.state.job) {
      // access token exists now, try checking if app & scu logs exists
      this.setState(prevState => ({ ...prevState, appLogs: { ...prevState.appLogs, fetched: true }}));
      this.checkIfAppLogsExist(this.props.accessToken, this.state.job);
      this.getSCULogs();
    } else if (this.props.accessToken && !this.state.appLogs.fetched && this.state.job) {
      // access token exists but we haven't tried fetching app logs yet, try now
      this.setState(prevState => ({ ...prevState, appLogs: { ...prevState.appLogs, fetched: true }}));
      this.checkIfAppLogsExist(this.props.accessToken, this.state.job);
      this.getSCULogs();
    }
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.showLoading();

    try {
      const { accessControl, searchParams  } = this.props;
      const eventId = searchParams.id;

      let job;
      if (this.state.job) {
        job = this.state.job;
      } else if (eventId) {
        const result = await getJob(eventId);
        if (result && result.job) {
          job = result.job;
          this.setState({ job: accessControl.hidePHI ? Job.anonymize(job) : job });
        } else {
          return;
        }
      } else {
        return;
      }

    } catch (e) {
      this.props.signalError(e.message ? e.message : e);
    }

    this.setState({ isLoading: false });
    this.hideModal();
  }

  async getSCULogs() {
    const { accessToken } = this.props;
    const { job } = this.state;
    const prefix = `orgs/${job.orgId}/${job.appId}/${job.eventId}/output/sculogs`;
    const items = await this.props.listFilesFromS3(accessToken, prefix);
    const scuLogs = {};
    for (let item of items.Contents) {
      if (item.Key.endsWith(".log")) {
        const sculog = new Sculog(item.Key);
        scuLogs[sculog.filepath] = sculog;
      }
    }
    this.setState({ scuLogs });
  }

  async checkIfAppLogsExist(token, job) {
    try {
      const key = this.appLogPath(job);
      await this.props.headObject(token, key);
      this.setState(prevState => ({ ...prevState, appLogs: { ...prevState.appLogs, exists: true }}));
    } catch (error) {
      // ignore error since the app log may not exist and that's okay.
      this.setState(prevState => ({ ...prevState, appLogs: { ...prevState.appLogs, exists: false }}));
      return;
    }
  }

  appLogPath = (job) => {
    return `orgs/${job.orgId}/${job.appId}/${job.eventId}/output/app.log`;
  }

    handleGetLogs = async () => {
      if (this.state.appLogs.exists && this.state.appLogs.log) {
        this.setState({
          modal: (
            <LogViewer log={this.state.appLogs.log} onClose={() => this.setState({ modal: null })} />
          )
        });
        return;
      }
      this.showLoading();
      const { accessToken } = this.props;
      const { job } = this.state;

      try {
        const key = `orgs/${job.orgId}/${job.appId}/${job.eventId}/output/app.log`;
        let log = await downloadFileFromS3(key, accessToken);
        log = await UInt8ArrToString(log.Body);
        this.setState(prevState => ({
          ...prevState,
          appLogs: {
            ...prevState.appLogs,
            log
          },
          modal: (
            <LogViewer log={log} onClose={() => this.setState({ modal: null })} />
          )
        }));
      } catch (e) {
        this.setState(prevState => ({ ...prevState, appLogs: { ...prevState.appLogs, log: null } }));
        this.showError("Failed to retrieve application logs. The app may have not run due to a configuration issue or it failed before the log was created.");
      }
    }

    handleViewCaseDetails = async () => {
      try {
        if (this.state.job) {
          this.setState({
            modal: (
              <LogViewer log={JSON.stringify(this.state.job, undefined, "\t")}
                onClose={() => this.setState({ modal: null })}
              />
            )
          });
        }
      } catch (e) {
        this.props.signalError(e.message ? e.message : e);
      }
    }

    handleDeleteJob = async () => {
      this.setState({
        modal: (<SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => this.hideModal()}
          onCancel={() => this.hideModal()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          showConfirm={false}
        >
          <div>
                    Are you sure you want to delete this case?
          </div>
          <div style={{ marginTop: "40px" }}>
            <Button color="danger"
              style={{ marginRight: "10px" }}
              onClick={() => this.hideModal()}
            >Cancel</Button>
            <Button color="success"
              onClick={() => this.deleteJob()}
            >Delete</Button>
          </div>
        </SweetAlert>
        )
      });
    }

    deleteJob = async () => {
      const { job } = this.state;
      this.showLoading();

      try {
        try {
          // delete on edge if originated from edge
          if (job.deviceId) {
            await deleteJobOnEdge(job.deviceId, job.eventId);
          }
        } catch (e) {
          const err = e.message ? e.message : e;
          this.props.signalError(`Failed to delete job on Edge device: ${err}.`);
        }

        // delete from cloud db and its s3 files
        await deleteJobOnCloud(job.eventId);

      } catch (e) {
        const err = e.message ? e.message : e;
        this.props.signalError(`Failed to delete job in cloud: ${err}.`);
      }

      this.props.history.push({ pathname: "/dashboard" });
    }

    showLoading() {
      this.setState({
        modal: <LoadingModal />
      });
    }

    hideModal() {
      this.setState({
        modal: null
      });
    }

    handleCaseAction = async (action) => {
      switch (action) {
      case "Go To Device":
        this.handleGoToDevice();
        break;
      case "App Logs":
        this.handleGetLogs();
        break;
      case "Upload Input Data (Anonymized)":
        this.handleUploadToCloud();
        break;
      case "Delete":
        this.handleDeleteJob();
        break;
      case "Send To Dicom Routes":
        this.handleSendDicomRoutes();
        break;
      case "Replay":
        this.handleReplayJob();
        break;
      case "Download Input Data":
        this.handleGetInputDownloadUrl();
        break;
      case "View Case Details":
        this.handleViewCaseDetails();
        break;
      default:
        break;
      }
    }

    handleGoToDevice() {
      const { job } = this.state;
      if (!job || !job.deviceId) {
        return;
      }
      const url = new URLSearchParams(this.props.location.search);
      url.set("id", job.deviceId);
      this.props.history.push({
        pathname: "/edge/device",
        search: url.toString()
      });
    }

    handleGetInputDownloadUrl = async () => {
      const { job } = this.state;
      this.showLoading();

      try {
        const result = await getJobInputDownloadURL(job.eventId);
        if (result.downloadUrl) {
          window.open(result.downloadUrl, "_self");
        }

        this.hideModal();
      } catch (e) {
        this.props.signalError(e.message ? e.message : e);
      }
    }

    handleReplayJob = async () => {

      const { job } = this.state;
      this.showLoading();

      try {
        // is an edge job?
        if (job.mode == "EDGE") {
          await replayJobOnEdge(job.deviceId, job.eventId);
        }

        this.hideModal();
      } catch (e) {
        this.props.signalError(e.message ? e.message : e);
      }

    }

    handleSendDicomRoutes = async () => {

      const { job } = this.state;
      this.showLoading();

      try {
        // did job originate from edge?
        if (job.deviceId) {
          await sendDicomRoutes(job.deviceId, job.eventId);
        }

        this.hideModal();
      } catch (e) {
        this.props.signalError(e.message ? e.message : e);
      }
    }

    handleUploadToCloud = async () => {
      const { job } = this.state;
      this.showLoading();

      try {
        // did job originate from edge?
        if (job.deviceId) {
          await uploadJobToCloud(job.deviceId, job.eventId);
        }

        this.hideModal();
      } catch (e) {
        this.props.signalError(e.message ? e.message : e);
      }
    }

    showError = (err) => {
      this.setState({
        modal: (
          <SweetAlert
            type="error"
            style={{ display: "block", marginTop: "-100px" }}
            title="Ohh No!"
            onConfirm={() => this.hideModal()}
            onCancel={() => this.hideModal()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            showConfirm={false}
          >
            <div>
              {err}
            </div>
            <div style={{ marginTop: "40px" }}>
              <Button onClick={() => this.hideModal()}>Close</Button>
            </div>
          </SweetAlert>
        )
      });
    }

    handleScuLogViewButtonClick = async (sculog) => {
      try {
        this.setState({ isLoading: true });
        // if log already exists, open the modal with it.
        if (this.state.scuLogs[sculog.filepath] && this.state.scuLogs[sculog.filepath].log && this.state.scuLogs[sculog.filepath].log.length) {
          this.setState({
            modal: <LogViewer log={this.state.scuLogs[sculog.filepath].log} onClose={() => this.setState({ modal: null })} />
          });
          return;
        }
        // this is the first request to get the log. Fetch it now and save it to state.
        const { accessToken } = this.props;
        const resp = await downloadFileFromS3(sculog.filepath, accessToken);
        const log = await UInt8ArrToString(resp.Body);
        const statesculog = this.state.scuLogs[sculog.filepath];
        statesculog.setData(log);
        this.setState(prevState => ({
          ...prevState,
          sculogs: {
            ...prevState.scuLogs,
            [sculog.filepath]: statesculog
          },
          modal: (
            <LogViewer log={log} onClose={() => this.setState({ modal: null })} />
          )
        }));
      } catch (e) {
        const msg = `Failed to retrieve the DICOM SCU log: ${e.message ? e.message : e}`;
        this.props.signalError(msg);
      }
      this.setState({ isLoading: false });
    }

    appConfig() {
      const { job } = this.state;
      return job && job.appConfig ? job.appConfig : {};
    }

    outputsByFolderName() {
      const appConfig = this.appConfig();
      const outputs = {};
      if (!appConfig.outputs) {
        return outputs;
      }

      for (const outputName in appConfig.outputs) {
        outputs[appConfig.outputs[outputName].folderName] = appConfig.outputs[outputName];
      }

      return outputs;
    }

    scuLogsTableData() {
      const outputConfig = this.outputsByFolderName();
      const data = [];
      for (const seriesS3Path in this.state.scuLogs) {
        const sculog = this.state.scuLogs[seriesS3Path];
        data.push([
          sculog.aetitle,
          outputConfig[sculog.seriesFolderName] ? outputConfig[sculog.seriesFolderName].description : sculog.seriesFolderName,
          <Button key={sculog.seriesFolderName} onClick={() => this.handleScuLogViewButtonClick(sculog)} size="sm" color="primary" right disabled={this.state.isLoading}>View</Button>
        ]);
      }
      return data;
    }

    jobStatusColor = (status) => {
      switch (status) {
      case "Finished Processing":
        return "success";
      case "Failed":
        return "danger";
      case "Queued":
      case "Receiving Dicoms":
      case "Waiting for Dicoms":
        return "primary";
      case "Pending":
      case "Waiting":
        return "rose";
      default:
        return "gray";
      }
    }

    caseIconColor = (status) => {
      switch (status) {
      case "Finished Processing":
        return "green";
      case "Failed":
        return "red";
      case "Queued":
      case "Receiving Dicoms":
      case "Waiting for Dicoms":
        return "blue";
      case "Pending":
      case "Waiting":
        return "orange";
      default:
        return "green";
      }
    }

    caseDetailsTableData() {
      const { job } = this.state;
      if (!job) {
        return [];
      }
      const data = [
        ["ID", job.eventId],
        ["Application", job.appName],
        ["Status", <Badge key={job.eventId} color={this.jobStatusColor(job.jobStatus)}>{job.jobStatus}</Badge>],
        ["Received", moment(job.received).format("MMM DD, YYYY hh:mm:ssaZ")],
      ];

      if (job.completionTime) {
        data.push(["Completed", moment(job.completionTime).format("MMM DD, YYYY hh:mm:ssaZ")]);
      }

      // Add versions
      if (job.appVersion) {
        data.push(["Application Version", job.appVersion]);
      }
      if (job.edgeVersion) {
        data.push(["EdgeGateway Version", job.edgeVersion]);
      }

      if (job.exitCode) {
        data.push(["Exit Code", job.exitCode]);
      }
      if (job.SourceApplicationEntityTitle) {
        data.push(["Source AE Title", job.SourceApplicationEntityTitle]);
      }

      // Add DICOM related rows
      if (job.StudyDescription) {
        data.push(["Study Description", job.StudyDescription]);
      }
      if (job.StudyInstanceUID) {
        data.push(["Study Instance UID", job.StudyInstanceUID]);
      }
      if (job.AccessionNumber) {
        data.push(["Accession Number", job.AccessionNumber]);
      }
      if (job.series && job.series.length) {
        data.push(["Series Descriptions", job.series.reduce((arr, s) => {
          return s.SeriesDescription ? [...arr, s.SeriesDescription] : arr;
        }, []).join(", ")]);
      }
      if (job.Modality) {
        data.push(["Modality", job.Modality]);
      }
      if (job.StudyDescription) {
        data.push(["Study Description", job.StudyDescription]);
      }
      if (job.Manufacturer) {
        data.push(["Scanner Manufacturer", job.Manufacturer]);
      }
      if (job.ManufacturersModelName) {
        data.push(["Scanner Model", job.ManufacturersModelName]);
      }
      if (job.StationName) {
        data.push(["Scanner Name", job.StationName]);
      }

      // Add patient related rows
      if (job.PatientID) {
        data.push(["Patient ID", job.PatientID]);
      }

      return data;
    }

    optionsDropdownList() {
      const { user } = this.props;
      const { job, appLogs } = this.state;
      const actions = [];

      if (appLogs.exists) {
        actions.push("App Logs");
      }

      if (job) {
        actions.push("Delete");
      }

      if (job && job.deviceId) {
        actions.push(
          "Go To Device",
          "Send To Dicom Routes"
        );
      }

      // for now, replaying jobs only supported for edge-executed jobs
      if (job.mode == "EDGE") {
        actions.push("Replay");
      }

      if (job && user.isHLXSupport) {
        if (actions.length > 0) {
          actions.push({ divider: true });
        }
        actions.push("View Case Details");
        if (job.mode !== "CLOUD") {
          actions.push(
            "Download Input Data",
            "Upload Input Data (Anonymized)"
          );
        }
      }

      return actions;
    }

    render() {
      const job = this.state.job;
      let logs;

      let showError = false;

      if (job) {
        logs = job.logs;
        if (logs ? logs.length > 0 : false) {
          showError = true;
        }
      }

      const scuLogsTableData = this.scuLogsTableData();

      return (
        job &&
            <div>
              {this.state.modal}
              <div style={{ textAlign: "right" }}>
                {showError &&
                        logs.map((log, index) => {
                          return (
                            <SnackbarContent
                              message={log.message}
                              color="danger"
                              key={index}
                              icon={AddAlert}
                            />
                          );
                        })
                }
              </div>
              <GridContainer>
                <ItemGrid xs={12}>
                  <IconCard
                    iconColor={this.caseIconColor(job.jobStatus)}
                    icon={BuildIcon}
                    title="Case"
                    content={
                      <GridContainer>
                        <ItemGrid xs={12} container justify="flex-end">
                          <CustomDropdown
                            buttonColor="primary"
                            buttonText="Options"
                            dropdownList={this.optionsDropdownList()}
                            onAction={(action) => this.handleCaseAction(action)}
                          />
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <Table
                            tableData={this.caseDetailsTableData()}
                          />
                        </ItemGrid>
                      </GridContainer>
                    }
                  />
                </ItemGrid>
                {scuLogsTableData.length !== 0 &&
                <ItemGrid xs={12}>
                  <IconCard
                    iconColor="green"
                    icon={DescriptionIcon}
                    title="SCU Logs"
                    content={
                      <Table
                        tableData={scuLogsTableData}
                      />
                    }
                  />
                </ItemGrid>
                }
              </GridContainer>
            </div>
      );
    }
}

Case.propTypes = {
  headObject: pt.func.isRequired,
  listFilesFromS3: pt.func.isRequired,
  user: pt.instanceOf(User),
  location: pt.shape({
    search: pt.string,
    state: pt.shape({
      job: pt.shape({
        orgId: pt.string.isRequired,
      })
    }),
  }).isRequired,
  classes: pt.shape({
    success: pt.string.isRequired,
    button: pt.string.isRequired,
    danger: pt.string.isRequired,
  }).isRequired,
  history: pt.shape({
    push: pt.func.isRequired,
  }).isRequired,
  signalError: pt.func.isRequired,
  clearError: pt.func.isRequired,
  accessControl: pt.shape({
    hidePHI: pt.bool.isRequired,
  }).isRequired,
  searchParams: pt.shape({
    id: pt.string,
  }).isRequired,
  accessToken: pt.instanceOf(AccessToken)
};

const mapStateToProps = (state, props) => ({
  user: userSelector(state),
  accessControl: accessControlSelector(state, props),
  searchParams: searchParamsSelector(state, props),
  accessToken: accessTokenSelector(state, props),
});

const mapDispatchToProps = () => ({
  listFilesFromS3,
  headObject
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    WithNotifications(
      withStyles(style)(Case)
    )
  )
);
