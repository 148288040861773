import moment from "moment";

export default class AccessToken {
  constructor(token) {
    this.AccessKeyId = token.AccessKeyId;
    this.SessionToken = token.SessionToken;
    this.SecretAccessKey = token.SecretAccessKey;
    this.Expiration = token.Expiration;
  }

  expired(from = moment()) {
    return from.isAfter(moment(this.Expiration));
  }
}