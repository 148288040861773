import React from "react";
import pt from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getPatientRecords, getWebPreviewToken, createPatientRecord } from "libs/apiLib";
import { uploadToS3 } from "libs/awsLib";

import Button from "components/CustomButtons/Button";
import Input from "components/CustomInput/CustomInput";
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";
import HeaderCard from "components/Cards/HeaderCard";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Pagination from "components/Pagination/ReactTablePagination";
import Modal from "components/Modal";

import withStyles from "material-ui/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";
import ChevronRight from "material-ui-icons/ChevronRight";
import { CircularProgress } from "material-ui/Progress";

export class Records extends React.Component {
  static styles = {
    ...styles,
    fileButton: {
      minWidth: "auto",
      minHeight: "auto",
      backgroundColor: "#00ACEF",
      color: "#FFFFFF",
      boxShadow: "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)",
      border: "none",
      borderRadius: "3px",
      padding: "12px 30px",
      margin: "10px 1px",
      fontSize: "12px",
      fontWeight: "400",
      textTransform: "uppercase",
      letterSpacing: "0",
      willChange: "box-shadow, transform",
      transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
      lineHeight: "1.3",
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)"
      }
    }
  }
  static propTypes = {
    classes: pt.shape({
      wideHorizontalPadding: pt.string.isRequired,
      fileButton: pt.string.isRequired,
    }).isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    signalInfo: pt.func.isRequired,
    clearInfo: pt.func.isRequired,
    getPatientRecords: pt.func.isRequired,
    id: pt.string.isRequired,
    orgId: pt.string,
    createPatientRecord: pt.func.isRequired,
    getS3Token: pt.func.isRequired,
    uploadToS3: pt.func.isRequired,
    location: pt.shape({
      search: pt.string
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      records: null,
      paginationKey: {},
      allPagesFulfilled: false,
      page: 0,
      uploadModal: null,
    };
    this.fileReader = new FileReader();
    this.fileReader.onload = this.handleFileOnLoad;
  }

  componentDidMount() {
    if (this.props.id) {
      this.getPatientRecords();
    }
  }

  handleFileOnLoad = (e) => this.setState(prevState => ({
    ...prevState,
    uploadModal: {
      ...prevState.uploadModal,
      file: e.target.result,
    }
  }));

  getPatientRecords = async () => {
    const { id, orgId, getPatientRecords, signalError, clearError } = this.props;
    const { paginationKey: { patientIdKey, ownerIdKey, recordIdKey } } = this.state;
    this.setState({ loading: true });
    try {
      const response = await getPatientRecords(id, orgId, patientIdKey, ownerIdKey, recordIdKey);
      if (response.success && response.records.Items) {
        // update pagination key
        if (response.records.LastEvaluatedKey) {
          this.updatePaginationKey({
            patientIdKey: response.records.LastEvaluatedKey.patientId,
            ownerIdKey: response.records.LastEvaluatedKey.ownerId,
            recordIdKey: response.records.LastEvaluatedKey.recordId,
          });
        } else {
          // no pagination key exists = no more data to fetch
          this.setState({ allPagesFulfilled: true });
        }

        // change page if on last page and new records were returned
        if (response.records.Items.length && this.state.records && this.state.page + 1 === Math.floor(this.state.records.length / 10)) {
          this.setState(prevState => ({ ...prevState, page: prevState.page + 1 }));
          return;
        }

        this.setState(prevState => {
          const records = (prevState.records ? [...prevState.records, ...response.records.Items] : response.records.Items).sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
          return ({ ...prevState, records });
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError(`Error getting records for patient "${id}". Please try again later.`);
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  updatePaginationKey = ({ patientIdKey, ownerIdKey, recordIdKey }) => {
    if (!patientIdKey || !ownerIdKey || !recordIdKey) {
      return;
    }
    this.setState({
      paginationKey: {
        patientIdKey,
        ownerIdKey,
        recordIdKey
      }
    });
  }

  formatCell = (key) => (cell) => {
    switch (key) {
    case "createdOn":
      return <Cell label="Created" value={moment(cell.value).format("dddd, MMMM Do YYYY")} />;
    case "type":
      return <Cell label="Type" value={cell.value} />;
    case "description":
      return <Cell label="Record" value={cell.value} />;
    case "more":
      return (
        <ItemGrid container xs={12} justify="flex-end">
          <Link to={{
            pathname: `/patients/${this.props.id}/records/${cell.original.recordId}`,
            search: this.props.location.search,
            state: { record: cell.original },
          }}><ChevronRight /></Link>
        </ItemGrid>
      );
    default:
      return cell.value;
    }
  }

  setupFileNode = (node) => {
    if (!node) {
      return;
    }
    node.addEventListener("change", this.handleFile);
    this.fileNode = node;
  }

  handleFile = (e) => {
    const file = e.target.files[0];
    this.fileReader.readAsArrayBuffer(file);
    if (file.name.endsWith("gz") || file.name.endsWith("zip")) {
      this.props.signalInfo("Please upload an unzipped file. The file you selected to upload is gzipped or zipped. Unzip it and upload the file within it.");
      return;
    }
    this.setState(prevState => ({
      ...prevState,
      uploadModal: {
        ...prevState.uploadModal,
        filename: file.name,
      }
    }));
  }

  handleInputChange = e => {
    const { value } = e.target;
    this.setState(prevState => ({ ...prevState, uploadModal: { ...prevState.uploadModal, description: value } }));
  }

  renderUploadModal = () => {
    const { uploadModal, loading } = this.state;
    const { classes: { fileButton } } = this.props;
    let content = (
      <ItemGrid container xs={12} justify="center" alignItems="center">
        <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />;
      </ItemGrid>
    );
    if (!loading) {
      content = (
        <React.Fragment>
          <ItemGrid xs={12}>
            <h4>Record Type</h4>
            <Button onClick={() => this.setState(prevState => ({ ...prevState, uploadModal: { ...prevState.uploadModal, type: "Dicom Medical Study" } }))} color={this.state.uploadModal.type === "Dicom Medical Study" ? "primary" : undefined}>Dicom Medical Study</Button>
            <Button onClick={() => this.setState(prevState => ({ ...prevState, uploadModal: { ...prevState.uploadModal, type: "Genotype" } }))} color={this.state.uploadModal.type === "Genotype" ? "primary" : undefined}>Genotype</Button>
          </ItemGrid>
          <ItemGrid xs={12}>
            <h4 style={{ marginBottom: 20 }}>Choose File</h4>
            <label htmlFor="file" className={fileButton}>
              File
            </label>
            <input ref={this.setupFileNode} type="file" id="file" style={{ display: "none" }} />
            {this.state.uploadModal.filename && <p style={{ marginTop: 20 }}>{this.state.uploadModal.filename}</p>}
          </ItemGrid>
          <ItemGrid xs={12}>
            <h4 style={{ marginBottom: 0 }}>Description</h4>
            <Input
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "Required",
                value: uploadModal.description,
                onChange: this.handleInputChange,
              }}
            />
          </ItemGrid>
        </React.Fragment>
      );
    }
    return (
      <Modal
        style={{
          height: 750,
          width: 600,
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer style={{
          height: "100%",
          paddingLeft: "30px",
          paddingRight: "30px"
        }}>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}><h2>Upload Record</h2></ItemGrid>
          <ItemGrid xs={12} style={{ alignSelf: "flex-start" }}>
            <p>Upload an external record for this patient. You can upload either a Dicom Medical Study or a Genotype file.</p>
            <h6><b>Dicom Medical Study</b></h6>
            <p>A DICOM conforming file (.dcm) file for a patient</p>
            <h6><b>Genotype</b></h6>
            <p>A 23andMe, Ancestry.com, or VCF file containing the patient's Genotype Data. VCF files must follow standard VCF file schemas.</p>
          </ItemGrid>
          {content}
          <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
            <Button style={{ marginRight: 15 }} onClick={() => this.setState({ uploadModal: null })} disabled={loading}>Cancel</Button>
            <Button color="success" onClick={this.handleUploadSubmit} disabled={loading || !uploadModal.file || !uploadModal.description.length}>Upload</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }

  handleUploadClick = () => this.setState({
    uploadModal: {
      type: "Dicom Medical Study", // or 'Genotype'
      file: null,
      filename: null,
      description: "",
    }
  })

  handleUploadSubmit = async () => {
    this.setState({ loading: true });
    const { id, orgId, getS3Token, createPatientRecord, uploadToS3, signalError, clearError } = this.props;
    const { uploadModal } = this.state;
    try {
      const tokenResponse = await getS3Token(orgId);
      if (!tokenResponse.success || !tokenResponse.token) {
        throw new Error();
      }
      const recordResponse = await createPatientRecord({
        patientId: id,
        orgId,
        type: uploadModal.type,
        description: uploadModal.description,
        typeOfOwner: "ORG",
        isDirectory: false,
      });
      if (!recordResponse.record.location) {
        throw new Error();
      }
      await uploadToS3(tokenResponse.token, recordResponse.record.location, uploadModal.file);
      window.location.reload();
    } catch (err) {
      signalError("Error getting creating record. Please try again later.");
      setTimeout(clearError, 5000);
      this.setState({ loading: false, uploadModal: null });
    }
  }

  fullRefresh = () => {
    this.setState({
      records: null,
      paginationKey: {},
      allPagesFulfilled: false,
      page: 0
    }, () => {
      this.getPatientRecords();
    });
  }

  render() {
    const { allPagesFulfilled, page, loading, uploadModal } = this.state;
    return (
      <HeaderCard
        cardTitle="External Records"
        cardSubtitle="All externally uploaded records for patient. This includes genetic and medical imaging files"
        headerColor="blue"
        content={
          <GridContainer className={this.props.classes.wideHorizontalPadding}>
            {uploadModal && this.renderUploadModal()}
            <ItemGrid xs={12}>
              <Button right onClick={this.fullRefresh}>Refresh</Button>
              <Button color="primary" right onClick={this.handleUploadClick}>Upload</Button>
            </ItemGrid>
            <ItemGrid xs={12}>
              <ReactTable
                sortable={false}
                data={this.state.records || []}
                noDataText="No records"
                defaultPageSize={5}
                columns={[
                  { accessor: "createdOn" },
                  { accessor: "type" },
                  { accessor: "description" },
                  { accessor: "more", maxWidth: 50 },
                ].map(c => ({ Header: "", Cell: this.formatCell(c.accessor), accessor: c.accessor, maxWidth: c.maxWidth }))
                }
                PaginationComponent={Pagination}
                showPaginationBottom
                canGetMorePages
                minRows={5}
                page={page}
                refreshing={loading}
                onGetMore={this.getPatientRecords}
                onPageChange={page => this.setState({ page })}
                allPagesFulfilled={allPagesFulfilled}
              />
            </ItemGrid>
          </GridContainer>
        }
      />
    );
  }
}

export default connect(undefined, () => ({
  getPatientRecords: (id, ownerId, patientIdKey, ownerIdKey, recordIdKey) => getPatientRecords(id, ownerId, patientIdKey, ownerIdKey, recordIdKey),
  createPatientRecord: (args) => createPatientRecord(args),
  getS3Token: (id) => getWebPreviewToken(id),
  uploadToS3: (token, key, file) => uploadToS3(token, key, file),
}))(
  withRouter(
    withStyles(Records.styles)(Records)
  )
);