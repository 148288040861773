import React from "react"
import PropTypes from "prop-types"
import withStyles from "material-ui/styles/withStyles"
import PlainCard from "components/Cards/PlainCard.jsx"
import Reports from "models/Reports"

const style = {
	totalCases: {
		textAlign: "center",
		color: "#FFFFFF",
		background: "linear-gradient(to right, #0fb8ad, #1fc8db)",
	}
}

export const TotalCases = ({ classes, report }) => {
	let total = 0
	if (report) {
		total = report.totalCases()
	}
	return (
		<PlainCard
			customClasses={classes.totalCases}
		>
			<h1 style={{ fontSize: "3em" }}>{total}</h1>
			<h3 style={{ margin: 0 }}>Total Cases</h3>
		</PlainCard>
	)
}

TotalCases.propTypes = {
	classes: PropTypes.shape({}),
	report: PropTypes.instanceOf(Reports)
}

export default withStyles(style)(TotalCases)