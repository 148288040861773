import React from "react";
import pt from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import AceEditor from "react-ace";
import Modal from "components/Modal";

export default class CodeModal extends React.Component {
  static propTypes = {
    style: pt.shape({}),
    title: pt.element.isRequired,
    value: pt.oneOfType([pt.string, pt.shape({})]),
    footerRender: pt.func.isRequired,
  }

  renderValue() {
    const { value } = this.props;
    return typeof value === "string" ? value : JSON.stringify(value, undefined, "\t");
  }

  render() {
    if (this.props.value === undefined) {
      return null;
    }
    return (
      <Modal
        style={{
          height: 900,
          width: 800,
          overflow: "hidden",
          overflowY: "scroll",
          ...this.props.style
        }}
      >
        <GridContainer style={{ height: "100%" }}>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}>
            {this.props.title}
          </ItemGrid>
          <ItemGrid xs={12}>
            <AceEditor
              mode="json"
              theme="xcode"
              fontSize={12}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={this.renderValue()}
              wrapEnabled={true}
              readOnly={true}
              width={"100%"}
              showLineNumbers
            />
          </ItemGrid>
          {this.props.footerRender()}
        </GridContainer>
      </Modal>
    );
  }
}