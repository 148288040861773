import React from "react";
import cx from "classnames";
import pt from "prop-types";
import InputLabel from "material-ui/Input/InputLabel";
import withStyles from "material-ui/styles/withStyles";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";

const Label = ({
  children,
  className,
  success,
  error,
  classes: { labelRoot, labelRootError, labelRootSuccess },
  ...rest
}) => {
  var labelClasses = cx({
    [labelRoot]: true,
    [className]: className !== undefined,
    [" " + labelRootError]: error,
    [" " + labelRootSuccess]: success && !error
  });
  return (
    <InputLabel className={labelClasses} {...rest}>{children}</InputLabel>
  );
};

Label.propTypes = {
  children: pt.node.isRequired,
  classes: pt.shape({
    labelRootError: pt.string.isRequired,
    labelRootSuccess: pt.string.isRequired,
  }).isRequired,
  className: pt.string,
  success: pt.bool,
  error: pt.bool,
};

export default withStyles(customInputStyle)(Label);