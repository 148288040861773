import React from "react";
import pt from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { WithNotifications } from "components/HigherOrder";
import { accessControlSelector } from "selectors";

import IconCard from "components/Cards/IconCard";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import ReactTable from "react-table";
import Pagination from "components/Pagination/ReactTablePagination";
import Cell from "components/Table/ReactTableCell";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import Modal from "components/Modal";
import Bannercard from "components/Cards/BannerCard.jsx";
import { CircularProgress } from "material-ui/Progress";
import { getKits, orderKits } from "libs/apiLib";

import Tabs from "material-ui/Tabs";
import Tab from "material-ui/Tabs/Tab";

import KitIcon from "material-ui-icons/Work";
import ChevronRight from "material-ui-icons/ChevronRight";

export class Index extends React.Component {
  static propTypes = {
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    orderKits: pt.func.isRequired,
    getKits: pt.func.isRequired,
    location: pt.shape({
      search: pt.string,
    }).isRequired,
    accessControl: pt.shape({
      hidePHI: pt.bool.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = this.initialState(props);
  }

  componentDidMount() {
    this.getKits();
  }

  componentDidUpdate(prevProps) {
    if (this.movedFromHLXViewingToOrgViewing(prevProps, this.props)) {
      this.setState({
        ...this.initialState(this.props)
      }, this.getKits);
    }
  }

  movedFromHLXViewingToOrgViewing = (prevProps, thisProps) => prevProps.location.search && thisProps.location.search.length === 0

  initialState = (props) => ({
    loading: false,
    tab: "processed",
    kits: [],
    page: {
      processed: 0,
      used: 0,
      shipped: 0,
      ordered: 0,
      unusable: 0,
      received: 0,
    },
    allPagesFulfilled: false,
    orgIdKey: null,
    sampleIdKey: null,
    showModal: false,
    order: {
      streetNumber: props.org.streetNumber || "",
      addressLineOne: props.org.addressLineOne || "",
      addressLineTwo: props.org.addressLineTwo || "",
      city: props.org.city || "",
      state: props.org.state || "",
      suite: props.org.suite || "",
      postalCode: props.org.postalCode || "",
      amount: 1,
    }
  })

  updateTab = (_, tab) => {
    this.setState({ tab });
  }

  getKits = async () => {
    const { orgIdKey, sampleIdKey, page, tab } = this.state;
    const { getKits, clearError, signalError, location: { search } } = this.props;
    this.setState({ loading: true });
    const orgId = new URLSearchParams(search).get("orgId");
    try {
      const response = await getKits(orgId, orgIdKey, sampleIdKey);
      if (response.success && response.sampleKits && response.sampleKits.Items) {
        const currentPage = page[tab];
        const tabKits = this.tabKits(tab, this.state.kits);
        const newTabKits = this.tabKits(tab, response.sampleKits.Items);
        if (this.shouldChangePage(currentPage, tabKits, newTabKits)) {
          this.changePage(currentPage + 1);
        }

        this.setState({
          kits: this.state.kits.length ? [...this.state.kits, ...response.sampleKits.Items] : response.sampleKits.Items,
          orgIdKey: response.sampleKits.LastEvaluatedKey && response.sampleKits.LastEvaluatedKey.orgId ? response.sampleKits.LastEvaluatedKey.orgId : null,
          sampleIdKey: response.sampleKits.LastEvaluatedKey && response.sampleKits.LastEvaluatedKey.sampleId ? response.sampleKits.LastEvaluatedKey.sampleId : null,
          allPagesFulfilled: !response.sampleKits.LastEvaluatedKey ? true : false,
        });

      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error getting genetic kits. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  fullRefresh = () => {
    this.setState({
      kits: [],
      page: {
        processed: 0,
        used: 0,
        shipped: 0,
        ordered: 0,
        unusable: 0,
      },
      allPagesFulfilled: false,
      orgIdKey: null,
      sampleIdKey: null
    }, () => {
      this.getKits();
    });
  }

  shouldChangePage = (currentPage, currentItems, newItems) => {
    if (!newItems.length) {
      return false;
    }
    const total = currentItems.length + newItems.length;
    return currentPage + 1 === Math.floor(total / 10);
  }

  titleize = (str) => str[0].toUpperCase() + str.toLowerCase().slice(1);

  dateCellValues = (sample) => {
    let value = sample.ordered;
    switch (sample.status) {
    case "ORDERED":
      break;
    case "SHIPPED":
      value = sample.shipped || sample.ordered;
      break;
    case "USED":
      value = sample.assignedOn || sample.ordered;
      break;
    case "PROCESSED":
      value = sample.processedOn || sample.ordered;
      break;
    case "RECEIVED":
      value = sample.receivedOn || sample.ordered;
      break;
    default:
      break;
    }
    return { label: "Date", value: moment(value).format("MMMM Do YYYY, h:mm:ss a") };
  }

  formatCell = (key) => (cell) => {
    const { tab } = this.state;
    const { accessControl } = this.props;
    switch (key) {
    case "id":
      return <Cell label={tab === "ordered" ? "Sample ID" : "Barcode"} value={tab === "ordered" ? cell.original.sampleId : cell.original.barcode} />;
    case "date":
      return <Cell {...this.dateCellValues(cell.original)} />;
    case "status":
      return <Cell label="Status" value={this.titleize(cell.value)} />;
    case "patientId":
      return <Cell label="Patient" value={!accessControl.hidePHI ? <Link to={{ pathname: `/patients/${cell.value}` }}>{cell.value}</Link> : cell.value} />;
    case "trackingNumber":
      return <Cell label="Tracking Number" value={<a target="_blank" rel="noreferrer noopener" href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${cell.original.trackingNumber}`}>{cell.original.trackingNumber}</a>} />;
    case "more":
      return (
        <ItemGrid container xs={12} justify="flex-end">
          <Link to={{
            pathname: `/kits/${cell.original.sampleId}`,
            state: { kit: cell.original },
          }}><ChevronRight /></Link>
        </ItemGrid>
      );
    default:
      return cell.value;
    }
  }

  tabKits = (tab, kits) => {
    tab = tab || this.state.tab;
    kits = kits || this.state.kits;
    switch (tab) {
    case "processed":
      return kits.filter(k => k.status === "PROCESSED");
    case "used":
      return kits.filter(k => k.status === "USED");
    case "shipped":
      return kits.filter(k => k.status === "SHIPPED");
    case "ordered":
      return kits.filter(k => k.status === "ORDERED");
    case "unusable":
      return kits.filter(k => k.status === "UNUSABLE");
    case "received":
      return kits.filter(k => k.status === "RECEIVED");
    default:
      return kits;
    }
  }

  changePage = (page) => this.setState(prevState => ({ ...prevState, page: { ...prevState.page, [prevState.tab]: page } }))

  addressChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      ...prevState,
      order: {
        ...prevState.order,
        [name]: value
      }
    }));
  }

  orderKits = async () => {
    const { order } = this.state;
    const { signalError, clearError, orderKits, location: { search } } = this.props;
    this.setState({ loading: true });
    const orgId = new URLSearchParams(search).get("orgId");
    try {
      await orderKits({
        ...order,
        amount: Number(order.amount),
        orgId: orgId || undefined,
      });
      window.location.reload();
    } catch (e) {
      signalError(e && e.message ? e.message : `Error ordering ${order.amount} kits. Please try again later.`);
      setTimeout(clearError, 5000);
      this.setState({ loading: false, showModal: false });
    }
  }

  orderErrors = () => {
    return [
      "streetNumber", "addressLineOne", "city", "state", "postalCode"
    ].some(n => !this.valid("length", n)) || !this.valid("amount", "amount");
  }

  valid = (input, n) => {
    const { order } = this.state;
    const key = order[n];
    switch (input) {
    case "amount":
      if (typeof key === "string") {
        return key.length > 0 && key > 0 && key < 11;
      }
      return key > 0 && key < 11;
    case "length":
      return key.length > 0;
    default:
      return false;
    }
  }

  orderModal = () => {
    const { loading } = this.state;
    let content = (
      <ItemGrid container xs={12} justify="center" alignItems="center">
        <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />;
      </ItemGrid>
    );
    if (!loading) {
      content = (
        <React.Fragment>
          <ItemGrid xs={12}>
            <h3>Shipping Address</h3>
          </ItemGrid>
          <ItemGrid xs={12} sm={4}>
            <CustomInput
              formControlProps={{
                fullWidth: true
              }}
              error={!this.state.order.streetNumber.length}
              labelText={
                <span>
                  Street Number <small>(required)</small>
                </span>
              }
              inputProps={{
                name: "streetNumber",
                value: this.state.order.streetNumber,
                onChange: this.addressChange,
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={8}>
            <CustomInput
              formControlProps={{
                fullWidth: true
              }}
              error={!this.state.order.addressLineOne.length}
              labelText={
                <span>
                  Street Name <small>(required)</small>
                </span>
              }
              inputProps={{
                name: "addressLineOne",
                value: this.state.order.addressLineOne,
                onChange: this.addressChange,
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={8}>
            <CustomInput
              formControlProps={{
                fullWidth: true
              }}
              labelText={
                <span>
                  Address line <small>(optional)</small>
                </span>
              }
              inputProps={{
                name: "addressLineTwo",
                value: this.state.order.addressLineTwo,
                onChange: this.addressChange,
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={4}>
            <CustomInput
              formControlProps={{
                fullWidth: true
              }}
              labelText={
                <span>
                  Suite <small>(optional)</small>
                </span>
              }
              inputProps={{
                name: "suite",
                value: this.state.order.suite,
                onChange: this.addressChange,
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={6}>
            <CustomInput
              formControlProps={{
                fullWidth: true
              }}
              error={!this.state.order.city.length}
              labelText={
                <span>
                  City <small>(required)</small>
                </span>
              }
              inputProps={{
                name: "city",
                value: this.state.order.city,
                onChange: this.addressChange,
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={3}>
            <CustomInput
              formControlProps={{
                fullWidth: true
              }}
              error={!this.state.order.state.length}
              labelText={
                <span>
                  State <small>(required)</small>
                </span>
              }
              inputProps={{
                name: "state",
                value: this.state.order.state,
                onChange: this.addressChange,
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={3}>
            <CustomInput
              formControlProps={{
                fullWidth: true
              }}
              error={!this.state.order.postalCode.length}
              labelText={
                <span>
                  Postal Code <small>(required)</small>
                </span>
              }
              inputProps={{
                name: "postalCode",
                value: this.state.order.postalCode,
                onChange: this.addressChange,
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12}>
            <h3>Quantity</h3>
          </ItemGrid>
          <ItemGrid xs={12}>
            <CustomInput
              formControlProps={{
                fullWidth: true
              }}
              error={!this.valid("amount", "amount")}
              labelText={
                this.valid("amount", "amount") ?
                  (
                    <span>
                      Number of Genetic Kits <small>(required)</small>
                    </span>
                  ) :
                  (
                    <span>
                      Number of Genetic Kits should be between 1 and 10
                    </span>
                  )
              }
              inputProps={{
                name: "amount",
                value: this.state.order.amount,
                onChange: this.addressChange,
              }}
            />
          </ItemGrid>
        </React.Fragment>
      );
    }
    return (
      <Modal
        style={{
          height: 700,
          width: 700,
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer style={{ height: "100%", paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}><h2>Order Genetic Kits</h2></ItemGrid>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}>
            <p>Use this form to place an order for Genetic Kits. You can order up to 10 kits per order.</p>
          </ItemGrid>
          {content}
          <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
            <Button style={{ marginRight: 15 }} onClick={() => this.setState({ showModal: false })} disabled={this.state.loading}>Cancel</Button>
            <Button color="success" onClick={this.orderKits} disabled={this.state.loading || this.orderErrors()}>Order</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }

  tableColumns = () => {
    const { tab } = this.state;
    const columns = [
      { accessor: "date" },
      { accessor: "id" },
    ];
    if (["processed", "used"].includes(tab)) {
      columns.push({ accessor: "patientId" });
    } else if (tab === "shipped") {
      columns.push({ accessor: "trackingNumber" });
    }
    return columns;
  }

  getDescription = () => {
    switch (this.state.tab) {
    case "processed":
      return "These are kits that have been assigned to patients, have been processed, and the processed results are ready to be used with HealthLytix Apps. Head to a patient record to execute a HealthLytix app using the results.";
    case "received":
      return "These are kits that have been used, delivered for processing, but the samples have not been processed yet. The next step is for these kits to be processed.";
    case "used":
      return "These kits have been assigned to a patient, in the process of being shipped back for processing, but the samples have not been processed yet. Please wait for these kits to be processed. If you feel a kit has been stuck in this status for more than usual, please email us at support@healthlytix.com.";
    case "shipped":
      return "These are kits that have been ordered and shipped for your clinic but they haven't been used or assigned to a patient. You can track them using the provided tracking number.";
    case "ordered":
      return "These are kits that have been ordered but they haven't been shipped yet to your clinic. No tracking number available yet.";
    case "unusable":
      return "These are kits that have been used but are unusable for some reason. Kits in this section will not have processed results.";
    }
  }

  render() {
    const { showModal, loading, sorted, page, tab, allPagesFulfilled } = this.state;
    return (
      <div>
        <Bannercard
          title="Genetic Kits"
          subtitle={<span>Manage and order <b>Genetic Kits</b> here and have them shipped to your clinic. Start by clicking on <b>Order Kits</b>. Click on each tab below to find out the status of your already ordered kits.</span>}
          callback={{
            text: "Order Kits",
            onSubmit: () => {
              this.setState({ showModal: true });
            }
          }}
        />
        <IconCard
          icon={KitIcon}
          title="Genetic Kits"
          iconColor="orange"
          content={
            <GridContainer style={{ padding: "20px" }}>
              {showModal && this.orderModal()}
              <ItemGrid xs={12} sm={9} style={{ margin: "30px 0" }}>
                <Tabs
                  value={tab}
                  indicatorColor="#00ACEF"
                  textColor="#3C4858"
                  onChange={this.updateTab}
                  scrollable
                >
                  <Tab label="Processed" value="processed" />
                  <Tab label="Received" value="received" />
                  <Tab label="Used" value="used" />
                  <Tab label="Shipped" value="shipped" />
                  <Tab label="Ordered" value="ordered" />
                  <Tab label="Unusable" value="unusable" />
                </Tabs>
              </ItemGrid>
              <ItemGrid xs={12} sm={3} style={{ margin: "24px 0" }} container justify="flex-end">
                <Button onClick={this.fullRefresh}>Refresh</Button>
                <Button color="primary" onClick={() => this.setState({ showModal: true })}>Order</Button>
              </ItemGrid>
              <ItemGrid xs={12}>
                <h6><b>Status Description</b></h6>
                <p>{this.getDescription()}</p>
              </ItemGrid>
              <ItemGrid xs={12}>
                <ReactTable
                  sortable
                  data={this.tabKits()}
                  noDataText="No Genetic Kits"
                  columns={
                    this.tableColumns().map(c => ({
                      Header: "",
                      Cell: this.formatCell(c.accessor),
                      accessor: c.accessor,
                      sortable: c.disableSort ? false : true,
                      maxWidth: c.maxWidth,
                    }))
                  }
                  sorted={sorted}
                  page={page[tab]}
                  showPaginationBottom
                  loading={loading}
                  className="-highlight"
                  pageSize={10}
                  onSortedChange={sorted => this.setState({ sorted })}
                  onPageChange={this.changePage}
                  PaginationComponent={Pagination}
                  canGetMorePages
                  refreshing={loading}
                  allPagesFulfilled={allPagesFulfilled}
                  onGetMore={this.getKits}
                />
              </ItemGrid>
            </GridContainer>
          }
        />
      </div>
    );
  }

}

export default connect(
  (state) => ({
    org: state.user.org,
    accessControl: accessControlSelector(state)
  }),
  () => ({
    getKits: (orgId) => getKits(orgId),
    orderKits: (data) => orderKits(data),
  })
)(WithNotifications(Index));