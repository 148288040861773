import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.jsx";

const styles = {
  ...wizardStyle,
  activeTab: {
    color: "white"
  },
  stepsAnchor: {
    ...wizardStyle.stepsAnchor,
    cursor: "default"
  },
  stepsAnchorActive: {
    ...wizardStyle.stepsAnchor,
    cursor: "default",
    color: "white"
  },
  nav: {
    margin: "0 -30px",
    padding: 0,
    backgroundColor: "rgba(200, 200, 200, 0.2)",
  },
  background: {
    backgroundColor: "rgba(0,0,0,0.4)",
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 9998,
    width: "100%",
    height: "100%",
  },
  foreground: {
    backgroundColor: "#FFF",
    position: "fixed",
    height: "600px",
    width: "500px",
    borderRadius: "4px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    margin: "0",
    maxWidth: "90%",
    maxHeight: "90%",
    zIndex: 9999,
  }
};

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    let width;
    const steps = this.props.steps.length;
    if (steps === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (steps !== 3) {
          width = "50%";
        } else {
          width = 100 / steps + "%";
        }
      } else {
        if (steps === 2) {
          width = "50%";
        } else {
          width = 100 / steps + "%";
        }
      }
    }
    this.state = {
      currentStep: 0,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      movingTabStyle: {
        transition: "transform 0s"
      }
    };
  }

  componentDidUpdate(_, prevState) {
    if (prevState.currentStep !== this.state.currentStep && this.props.onStepChange) {
      this.props.onStepChange(this.state.currentStep);
    }
  }

  nextButtonClick = () => {
    const key = this.state.currentStep + 1;
    this.setState({
      currentStep: key,
      nextButton: this.props.steps.length > key + 1 ? true : false,
      previousButton: key > 0 ? true : false,
      finishButton: this.props.steps.length === key + 1 ? true : false
    });
  }

  previousButtonClick = () => {
    const key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
    }
  }

  activityClassnames = (step) => {
    const { currentStep } = this.state;
    const { color, classes } = this.props;
    if (currentStep === step) {
      return classes.steps + " " + classes[color] + " " + classes.activeTab;
    }
    return classes.steps;
  }

  activeTabClassnames = (step) => {
    const { currentStep } = this.state;
    const { classes } = this.props;
    if (currentStep === step) {
      return classes.stepsAnchorActive;
    }
    return classes.stepsAnchor;
  }

  stepTitle = () => {
    const { currentStep } = this.state;
    const { steps, title } = this.props;
    return steps[currentStep].stepTitle || title;
  }

  stepSubtitle = () => {
    const { currentStep } = this.state;
    const { steps, title } = this.props;
    return steps[currentStep].stepSubtitle || title;
  }

  render() {
    const { classes, title, subtitle, steps, backgroundStyle, style, disableNext, loading } = this.props;
    return (
      <GridContainer>
        <ItemGrid xs={12} className={classes.background} style={backgroundStyle}></ItemGrid>
        <ItemGrid container xs={12} className={classes.foreground} style={style}>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}>
            {title && <h2>{this.stepTitle()}</h2>}
            {subtitle && <h5>{this.stepSubtitle()}</h5>}
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <li
                    className={this.activityClassnames(key)}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <div
                      className={this.activeTabClassnames(key)}
                    >
                      {prop.stepName}
                    </div>
                  </li>
                );
              })}
            </ul>
          </ItemGrid>
          <ItemGrid xs={12}>
            {steps.map((prop, key) => {
              const stepContentClasses = cx({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key
              });
              return (
                <div className={stepContentClasses} key={key}>
                  <prop.stepComponent
                    innerRef={node => (this[prop.stepId] = node)}
                  />
                </div>
              );
            })}
          </ItemGrid>
          <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
            {
              this.state.currentStep === 0 &&
              <Button onClick={this.props.onCancel} style={{ marginRight: 15 }} disabled={loading}>{this.props.cancelButtonText}</Button>
            }
            {
              this.state.previousButton &&
              <Button onClick={this.previousButtonClick} style={{ marginRight: 15 }} disabled={loading}>{this.props.previousButtonText}</Button>
            }
            {
              this.state.nextButton &&
              <Button color="primary" onClick={this.nextButtonClick} disabled={disableNext || loading}>{this.props.nextButtonText}</Button>
            }
            {
              this.state.finishButton &&
              <React.Fragment>
                <Button color="danger" onClick={this.props.onCancel} style={{ marginRight: 15 }}>{this.props.cancelButtonText}</Button>
                <Button color="success" onClick={this.props.finishButtonClick} disabled={disableNext || loading}>{this.props.finishButtonText}</Button>
              </React.Fragment>
            }
          </ItemGrid>
        </ItemGrid>
      </GridContainer>
    );
  }
}

Wizard.defaultProps = {
  color: "primary",
  cancelButtonText: "Cancel",
  previousButtonText: "Previous",
  nextButtonText: "Next",
  finishButtonText: "Finish"
};

Wizard.propTypes = {
  backgroundStyle: PropTypes.shape({}),
  style: PropTypes.shape({}),
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepTitle: PropTypes.string,
      stepSubtitle: PropTypes.string,
      stepComponent: PropTypes.func.isRequired,
      stepId: PropTypes.string.isRequired
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  cancelButtonText: PropTypes.string,
  validate: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  disableNext: PropTypes.bool,
  onStepChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default withStyles(styles)(Wizard);
