import React from "react";
import pt from "prop-types";
import withStyles from "material-ui/styles/withStyles";
import Tt from "material-ui/Tooltip";
import {
  tooltip
} from "assets/jss/material-dashboard-pro-react.jsx";

const Tooltip = ({ classes, children, text, placement }) => (
  <Tt
    id="tooltip-top"
    title={text}
    placement={placement}
    classes={{ tooltip: classes.tooltip }}
  >
    {children}
  </Tt>
);

Tooltip.propTypes = {
  classes: pt.shape({
    tooltip: pt.string.isRequired
  }).isRequired,
  children: pt.element.isRequired,
  text: pt.string.isRequired,
  placement: pt.string,
};

Tooltip.defaultProps = {
  placement: "top"
};

export default withStyles({ tooltip })(Tooltip);