const colors = [
  "#00bcd4",
  "#f44336",
  "#ff9800",
  "#9c27b0",
  "#4caf50",
  "#9C9B99",
  "#dd4b39",
  "#35465c",
  "#e52d27",
  "#55acee",
  "#cc2127",
  "#1769ff",
  "#6188e2",
  "#a748ca"
];

const failureColor = "#ef5350";
const successColor = "#0fb8ad";

export { colors, failureColor, successColor };
export { default as Pie } from "./Pie";
export { default as BarStacked } from "./BarStacked";
export { default as Bar } from "./Bar";
export { default as Line } from "./Line";
export { default as Legend } from "./Legend";