import React from "react";
import pt from "prop-types";
import moment from "moment";
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import Checkbox from "components/Checkbox";
import Pagination from "components/Pagination/ReactTablePagination";
import { APPS } from "constants/Apps";

export class DataStep extends React.Component {
  static propTypes = {
    selectedRecords: pt.arrayOf(pt.shape({
      recordId: pt.string.isRequired,
    })),
    onSelect: pt.func.isRequired,
    records: pt.arrayOf(pt.shape({
      createdOn: pt.string.isRequired,
      recordId: pt.string.isRequired,
      location: pt.string,
      patientId: pt.string.isRequired,
      type: pt.string.isRequired,
      description: pt.string.isRequired,
      ownerId: pt.string.isRequired,
    })),
    loading: pt.bool.isRequired,
    onGetMorePatientRecords: pt.func.isRequired,
    onPageChange: pt.func.isRequired,
    allPagesFulfilled: pt.bool.isRequired,
    page: pt.number.isRequired,
    selectedApp: pt.shape({
      appId: pt.string.isRequired
    })
  }

  constructor(props) {
    super(props);
    this.state = {
      page: props.page
    };
  }

  isASelectedRecord = (record) => {
    const { selectedRecords } = this.props;
    if (!selectedRecords.length) {
      return false;
    }
    return selectedRecords.some(r => r.recordId === record.recordId);
  }

  formatCell = (key) => (cell) => {
    const { onSelect } = this.props;
    switch (key) {
    case "type":
      return <Cell label="Record Type" value={cell.value === "Dicom Medical Study" ? "NeuroQuant DICOM" : cell.value} />;
    case "description":
      return <Cell label="Description" value={cell.value} />;
    case "createdOn":
      return <Cell label="Creation Date" value={moment(cell.original.createdOn).format("MMMM-DD-YYYY")} />;
    case "select":
      return (
        <GridContainer justify="flex-end">
          <Checkbox
            onClick={() => onSelect(cell.original)}
            checked={this.isASelectedRecord(cell.original)}
            disabled={true}
          />
        </GridContainer>
      );
    default:
      return null;
    }
  };

  renderRecordDescription = () => {
    if (!this.props.selectedApp) {
      return null;
    }
    let content;
    switch (this.props.selectedApp.appId) {
    case APPS.airr:
    case APPS.airrMci:
      content = (
        <React.Fragment>
          <p>In order to run the Alzheimer&apos;s Integrated Risk Report, you must select the following input records:</p>
          <ul>
            <li>1 NeuroQuant DICOM report</li>
            <li>1 genotype record (VCF format)</li>
          </ul>
        </React.Fragment>
      );
      break;
    case APPS.agrr:
      content = (
        <React.Fragment>
          <p>In order to run the Alzheimer&apos;s GeneticRisk Report, you must select the following input records:</p>
          <ul>
            <li>1 genotype record (VCF format)</li>
          </ul>
        </React.Fragment>
      );
      break;
    case APPS.pgr:
      content = (
        <React.Fragment>
          <p>In order to run the Prostate Cancer GeneticRisk Report, you must select the following input records:</p>
          <ul>
            <li>1 genotype record (VCF format)</li>
          </ul>
        </React.Fragment>
      );
      break;
    default:
      return null;
    }
    return (
      <ItemGrid xs={12} style={{ marginBottom: "30px" }}>
        {content}
      </ItemGrid>
    );
  }

  hasError = () => {
    const { selectedRecords, selectedApp } = this.props;
    if (!selectedApp) {
      return false;
    }
    switch (selectedApp.appId) {
    case APPS.airr:
    case APPS.airrMci:
      if (!selectedRecords.length || selectedRecords.length !== 2) {
        return false;
      }
      return !(
        selectedRecords.length === 2 &&
          Boolean(selectedRecords.find((s) => s.type === "Dicom Medical Study")) &&
          Boolean(selectedRecords.find((s) => s.type === "Genotype"))
      );
    case APPS.pgr:
    case APPS.agrr:
      if (!selectedRecords.length || selectedRecords.length !== 1) {
        return false;
      }
      return !(
        selectedRecords.length === 1 &&
        Boolean(selectedRecords.find((s) => s.type === "Genotype"))
      );
    default:
      return false;
    }
  }

  errorMessage = () => {
    const { selectedApp } = this.props;
    if (!selectedApp) {
      return null;
    }
    switch (selectedApp.appId) {
    case APPS.pgr:
    case APPS.agrr:
      return "Error: You must select one genotype record.";
    case APPS.airr:
    case APPS.airrMci:
      return "Error: You must select one NeuroQuant DICOM report and one genotype record.";
    default:
      return null;
    }
  }

  render() {
    const { page, records, loading, onGetMorePatientRecords, onPageChange, allPagesFulfilled } = this.props;
    return (
      <GridContainer style={{ paddingRight: 30, paddingLeft: 30, paddingTop: 30 }}>
        {this.renderRecordDescription()}
        <ItemGrid xs={12}>
          <ReactTable
            noDataText="No Records"
            sortable
            defaultSorted={[
              {
                id: "createdOn",
                desc: true,
              }
            ]}
            loading={loading}
            page={page}
            data={records || []}
            minRows={records.length > 5 ? 5 : records.length}
            defaultPageSize={records.length > 5 ? 5 : records.length}
            showPagination={records.length > 5}
            columns={
              [
                { accessor: "description" },
                { accessor: "createdOn", maxWidth: 200 },
                { accessor: "type", maxWidth: 200 },
                { accessor: "select" },
              ].map(r => ({ accessor: r.accessor, Cell: this.formatCell(r.accessor), Header: "", maxWidth: r.maxWidth }))
            }
            PaginationComponent={Pagination}
            showPaginationBottom
            canGetMorePages
            refreshing={loading}
            onGetMore={onGetMorePatientRecords}
            onPageChange={onPageChange}
            allPagesFulfilled={allPagesFulfilled}
          />
        </ItemGrid>
        {this.hasError() && <ItemGrid xs={12}><p style={{ color: "red", marginTop: 30, textAlign: "center" }}>{this.errorMessage()}</p></ItemGrid>}
      </GridContainer>
    );
  }
}

export default DataStep;