import React from "react";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

import Button from "components/CustomButtons/Button.jsx";

// styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

class AcceptTerms extends React.Component {

    constructor(props) {
        super(props);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleReadTerms = this.handleReadTerms.bind(this);
    }

    handleConfirm = (e) => {
        e.preventDefault();
        this.props.onConfirm();
    }

    handleReadTerms = (e) => {
        e.preventDefault();
        this.props.onReadTerms();
    }

    handleCancel = (e) => {
        e.preventDefault();
        this.props.onCancel();
    }

    render() {
        return (
            <SweetAlert
                warning
                closeOnClickOutside={false}
                allowEscape={false}
                style={{ display: "block", marginTop: "-100px" }}
                title="Terms and Conditions"
                onConfirm={() => this.handleConfirm()}
                onCancel={() => this.handleCancel()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
                showConfirm={false}
            >
                <div>
                    We have updated the <a href=""
                onClick={this.handleReadTerms}>Terms and Conditions and Privacy Policy</a> of this website and service. Please accept before continuing.
                      </div>
                <div style={{ marginTop: "40px" }}>
                    <Button onClick={this.handleCancel} style={{ marginRight: "10px"}}
                    >Don't Agree</Button>
                    <Button color="primary"
                            onClick={this.handleConfirm}
                    >Agree</Button>
                </div>
            </SweetAlert>
        )
    }
}

export default withStyles(sweetAlertStyle)(AcceptTerms);