/**
 * User class
 */
export default class User {
  constructor(user) {
    Object.assign(this, user);

    // basic
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.userName = user.userName;
    this.userId = user.userId;
    this.orgId = user.orgId;

    // roles
    this.admin = user.admin || false;
    this.superAdmin = user.superAdmin || false;
    this.appAdmin = user.appAdmin || false;
    this.supportEngineer = user.supportEngineer || false;
    this.isResearch = user.isResearch || false;
    this.isViewer = user.isViewer || false;
    this.billingAdmin = user.billingAdmin || false;
    this.analyticsRole = user.analyticsRole || false;
    this.isHLXEmployee = user.userName ? (user.userName.endsWith("@healthlytix.com") || user.userName.endsWith("@cortechslabs.com")) : false;
    this.isHLXSupport = Boolean((user.superAdmin || user.supportEngineer) && this.isHLXEmployee);

    // behavior
    this.acceptedTerms = user.acceptedTerms || false;
    this.acceptedTermsDate = user.acceptedTermsDate;
    this.lastLoggedIn = user.lastLoggedIn;
  }
}