import isEmail from "validator/lib/isEmail";
import isIP from "validator/lib/isIP";
import isInt from "validator/lib/isInt";
import isURL from "validator/lib/isURL";
import moment from "moment";
import yamljs from "yamljs";

var ValidatePassword = require("validate-password");
var options = {
  enforce: {
    lowercase: true,
    uppercase: true,
    specialCharacters: false,
    numbers: true
  }
};
var passValidator = new ValidatePassword(options);

export function timeout(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export function validatePassword(password) {
  if (password.length > 0) {
    if (!passValidator.checkPassword(password).isValid || password.length < 8) {
      return `Password must contain letters, numbers, a 
                    lowercase, an uppercase, and minimum length of 8.`;
    }
  }
}

export function validateEmail(email) {
  if (!isEmail(email) && email.length > 0) { return "Please enter a valid email"; }
}

export function isValidEmail(email) {
  return isEmail(email);
}

export function validateIP(ipAddress) {
  if (isIP(ipAddress) && ipAddress.length > 0) { return true; }
  else { return false; }
}

export function validateTcpPort(port) {
  if (isInt(port, {
    min: 0,
    max: 65535,
    allow_leading_zeroes: false
  }) && port.length > 0) { return true; }
  else { return false; }
}

export function validateLength(string) {
  if (string.length > 16) { return "Must be less or equal to 16 characters"; }
}

export function isLengthLessThan(string, length) {
  if (string.length < length) { return true; }
  else { return false; }
}

export function validateUrl(string) {
  if (!isURL(string) && string.length > 0) { return "Enter a valid URL"; }
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export function getTodaysCases(jobs) {

  let casesToday = jobs.filter((job) => {
    if (moment.duration(moment().diff(moment(job.received))).asHours() < 24) { return job; }
  });

  return casesToday;
}

export function getRunningCases(jobs) {
  const running = jobs.filter((job) => {
    if (job.jobStatus === "Running") { return job; }
  });
  return running;
}

export function getQueuedCases(jobs) {
  const queued = jobs.filter((job) => {
    if (job.jobStatus === "Queued") { return job; }
  });
  return queued;
}

export function getFailedCases(jobs) {
  const failed = jobs.filter((job) => {
    if (job.jobStatus === "Failed") { return job; }
  });
  return failed;
}

// function that returns true if value is email, false otherwise
export function verifyEmail(value) {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}

// function that verifies if a string has a given length or not
export function verifyLength(value, length) {
  if (value.length >= length) {
    return true;
  }
  return false;
}

export function isDateOlderThanHours(date, hoursToCompare) {
  if (moment.duration(moment().diff(moment(date))).asHours() > hoursToCompare) { return true; }
  else { return false; }
}

export function isDateOlderThanMins(date, minutesToCompare) {
  if (moment.duration(moment().diff(moment(date))).asMinutes() > minutesToCompare) { return true; }
  else { return false; }
}

export function UInt8ArrToString(uint8arr) {
  var bb = new Blob([uint8arr]);
  var f = new FileReader();

  return new Promise((resolve, reject) => {
    f.onload = function (e) {
      resolve(e.target.result);
    };

    f.onerror = function (e) {
      reject(e);
    };

    f.readAsText(bb);
  });

}

export const calculatePageIndex = (n, pages = 3) => Math.floor(n % pages === 0 ? (n - 1) / pages : n / pages);

export function isValidJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function isValidYAML(str) {
  try {
    yamljs.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}

export function debounce(func, wait = 0) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

export function downloadAsCSV(fileName, data) {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}