import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputAdornment from "material-ui/Input/InputAdornment";
import { CircularProgress } from "material-ui/Progress";

// material-ui-icons
import Email from "material-ui-icons/Email";
import LockOutline from "material-ui-icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoginCard from "components/Cards/LoginCard.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Danger from "components/Typography/Danger.jsx";

// additional components & styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

// libs and api
import {
  authUser, invokeApig, getCurrentUserAccessToken, login
} from "libs/awsLib";
import { publishAuditTrail } from "libs/apiLib";
import { authenticate, authenticating, logout } from "actions/index";

const style = {
  ...loginPageStyle,
  ...sweetAlertStyle
};

const errorUserNotConfirmed = "User is not confirmed.";

const Error = ({ error }) => {
  if (!error || !error.length) {
    return null;
  }
  return (
    <Danger>
      {error}
    </Danger>
  );
};

Error.propTypes = {
  error: PropTypes.string
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password: "",
      isLoading: false,
      error: "",
      alert: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  }

  async componentDidMount() {
    document.body.addEventListener("keyup", this.handleEnterKeyPress);

    const { isUserAuthenticated, isUserAuthenticating } = this.props;

    // update is authenticating flag
    isUserAuthenticating(true);

    try {
      if (await authUser()) {

        // get user from platform context
        const accessToken = await getCurrentUserAccessToken();
        const result = await invokeApig({
          path: "/id/myUser",
          headers: {
            "access-token": accessToken
          }
        });

        if (!result)
        {throw new Error("Failed to get user");}
        else if (!result.user)
        {throw new Error("Failed to get user");}

        // update store
        isUserAuthenticated(true, result.user);
        isUserAuthenticating(false);

        // redirect
        this.props.history.push("/dashboard");
      } else
      {throw new Error("Failed to authenticate");}
    }
    catch (e) {
      const { logOut } = this.props;
      isUserAuthenticating(false);
      logOut();
    }

    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      200
    );
  }

  componentWillUnmount = () => {
    document.body.removeEventListener("keyup", this.handleEnterKeyPress);
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { email, password } = this.state;
    const { isUserAuthenticated, history: { push } } = this.props;
    if (this.validateForm()) {
      try {
        this.setState({ isLoading: true });
        // login
        await login(email, password);
        // get user from platform context
        const accessToken = await getCurrentUserAccessToken();
        const result = await invokeApig({
          path: "/id/myUser",
          headers: {
            "access-token": accessToken
          }
        });

        if (!result) {
          throw new Error("Failed to get user");
        } else if (!result.user) {
          throw new Error("Failed to get user");
        }

        // update store
        isUserAuthenticated(true, result.user);
        const { user: { firstName, lastName, userName } } = result;

        // Publish audit that user logged in succesfully
        await publishAuditTrail({
          type: "addUserAuth",
          description: `${firstName} ${lastName} (${userName}) successfully logged in.`,
          PHI: false
        });

        this.setState({ isLoading: false });
        // redirect to dashboard
        push("/dashboard");

      } catch (e) {
        if (e.message === errorUserNotConfirmed) {
          push(`/pages/confirm?email=${this.state.email}`);
        } else {
          this.setState({
            isLoading: false,
            error: e.message
          });
        }
      }
    } else {
      this.setState({
        isLoading: false,
        error: "Confirm both email and password are provided."
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.content}>
        {this.state.alert}
        <div className={classes.container}>
          <GridContainer justify="center">
            <ItemGrid xs={12} sm={6} md={4}>
              <form onSubmit={this.handleSubmit}>
                <LoginCard
                  customCardClass={classes[this.state.cardAnimaton]}
                  headerColor="blue"
                  cardTitle={
                    <div>
                      <h5>Health<span style={{ fontWeight: "300", fontStyle: "light" }}>Lytix</span></h5>
                    </div>
                  }
                  cardSubtitle="Transforming Precision Health"
                  footerAlign="center"
                  footer={
                    this.state.isLoading ? (
                      <div>
                        <br />
                        <CircularProgress style={{ color: "#00ACEF" }} size={60} thickness={2} />
                        <br />
                        <br />
                      </div>
                    ) : (
                      <div>
                        <Button
                          color="primaryNoBackground" wd size="lg"
                          onClick={this.handleSubmit}>
                            Login
                        </Button>
                        <br />
                        <Button
                          color="defaultNoBackground" wd size="xs"
                          onClick={() => this.props.history.push("/pages/forgot-password")}>
                            Forgot Password
                        </Button>
                      </div>)
                  }
                  content={
                    <div>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          id: "email",
                          value: this.state.email,
                          onChange: this.handleChange,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          )
                        }}
                      />
                      <CustomInput
                        labelText="Password"
                        id="password"
                        type="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          id: "password",
                          value: this.state.password,
                          onChange: this.handleChange,
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                      <Error error={this.state.error} />
                    </div>
                  }
                />
              </form>

            </ItemGrid>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isUserAuthenticated: PropTypes.func.isRequired,
  isUserAuthenticating: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticating: state.user.isAuthenticating,
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  isUserAuthenticated: (isAuthenticated, user) => {
    dispatch(authenticate(isAuthenticated, user));
  },
  isUserAuthenticating: (isAuthenticating) => {
    dispatch(authenticating(isAuthenticating));
  },
  logOut: () => {
    dispatch(logout());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(LoginPage)));
