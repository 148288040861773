import React from "react";
import pt from "prop-types";
import withStyles from "material-ui/styles/withStyles";
import MaterialRadio from "material-ui/Radio";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import FiberManualRecord from "material-ui-icons/FiberManualRecord";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";

const Radio = ({ classes, label, onClick, checked, value }) => {
  const radio = (
    <MaterialRadio
      checked={checked}
      onChange={onClick}
      value={value}
      name="radio"
      icon={<FiberManualRecord className={classes.radioUnchecked} />}
      checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
      classes={{
        checked: classes.radio
      }}
    />
  );
  if (!label) {
    return radio;
  }
  return (
    <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
      <FormControlLabel
        control={radio}
        classes={{
          label: classes.label
        }}
        label={label}
      />
    </div>
  );
};

Radio.defaultProps = {
  value: "true",
};

Radio.propTypes = {
  classes: pt.shape({
  }).isRequired,
  label: pt.string,
  onClick: pt.func.isRequired,
  checked: pt.bool,
  value: pt.string.isRequired,
};

export default withStyles(styles)(Radio);