import React from "react";
import pt from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { accessControlSelector } from "selectors";
import Job from "models/Job";
import { WithNotifications } from "components/HigherOrder";

// material ui
import withStyles from "material-ui/styles/withStyles";
import Switch from "material-ui/Switch";
import FormControlLabel from "material-ui/Form/FormControlLabel";

// material-ui-icons
import InfoOutline from "material-ui-icons/InfoOutline";
import CloudIcon from "material-ui-icons/CloudQueue";
import DeviceIcon from "material-ui-icons/Devices";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Cell from "components/Table/ReactTableCell";

import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

import Pagination from "components/Pagination/ReactTablePagination";

// actions and util functions
import { getSourceOrgJobs } from "libs/apiLib";
import { storeConsumerOrgCases, refreshConsumerOrgCases, changeConsumerOrgCasesPage } from "actions/index";
import { calculatePageIndex } from "libs/utils";

export class ConsumerOrgCases extends React.Component {
  static propTypes = {
    orgID: pt.string,
    cases: pt.shape({
      consumerOrgs: pt.shape({
        data: pt.arrayOf(pt.shape({})),
        key: pt.arrayOf(pt.string),
        page: pt.number,
      }).isRequired
    }).isRequired,
    org: pt.shape({
      orgUploadConfig: pt.arrayOf(pt.shape({
        orgName: pt.string.isRequired,
        orgId: pt.string.isRequired,
        id: pt.string.isRequired,
      }))
    }).isRequired,
    classes: pt.shape({
      label: pt.string.isRequired,
      switchChecked: pt.string.isRequired,
      switchBarChecked: pt.string.isRequired,
      switchIcon: pt.string.isRequired,
      switchUnchecked: pt.string.isRequired,
      switchIconChecked: pt.string.isRequired,
    }),
    getJobs: pt.func.isRequired,
    storeCases: pt.func.isRequired,
    refreshCases: pt.func.isRequired,
    changeCasesPage: pt.func.isRequired,
    history: pt.shape({
      push: pt.func.isRequired,
    }).isRequired,
    accessControl: pt.shape({
      hidePHI: pt.bool.isRequired,
    }).isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      autoRefresh: false,
      timer: null,
      isLoading: false,
      refreshing: false,
      lastRefresh: null,
    };
  }

  async componentDidMount() {
    const { cases } = this.props;
    let { orgID } = this.props;

    // fetch jobs if this is first mount & no cases exists yet
    if (orgID && !cases[orgID]) {
      await this.updateJobs();
    } else if (!orgID && !cases.consumerOrgs.data) {
      await this.updateJobs();
    }
  }

  async componentDidUpdate(prevProps) {
    // fetch jobs if this is first mount & if moving from org dashboard to user dash
    if (prevProps.orgID && !this.props.orgID && !this.props.cases.consumerOrgs.data) {
      await this.updateJobs();
    }

    // fetch jobs if orgID has been passed down, previous prop orgID didnt exist, and no cases exist yet
    if (!prevProps.orgID && this.props.orgID && !this.props.cases[this.props.orgID]) {
      await this.updateJobs();
    }
  }

  changePage = (page) => this.props.changeCasesPage(page, this.props.orgID);

  getContext = () => {
    const { orgID, cases } = this.props;
    if (orgID && cases[orgID]) {
      return cases[orgID].consumerOrgs || null;
    } else if (orgID) {
      return null;
    } else {
      return cases.consumerOrgs;
    }
  }

  getPage = () => {
    const context = this.getContext();
    return context ? context.page : 0;
  }

  updateJobs = async (refresh) => {
    this.setState({ isLoading: true });

    const { orgID, getJobs, refreshCases, storeCases, accessControl } = this.props;
    const key = this.getPaginationKey(refresh);

    try {
      // get the jobs
      if (refresh) {
        this.setState({ refreshing: true });
        const result = await getJobs(orgID, key);
        if (result.result.Items) {
          const cases = accessControl.hidePHI ? result.result.Items.map(Job.anonymize) : result.result.Items;
          refreshCases(cases, key, orgID);
        }
        this.setState({ isLoading: false, refreshing: false, lastRefresh: moment() });
        return;
      }

      const result = await getJobs(orgID, key);
      if (result.result.Items) {
        const page = this.getPage();
        const pages = this.getPages();
        if (page + 1 === pages) {
          this.changePage(page + 1);
        }
        let k = null;
        if (
          result.result.LastEvaluatedKey &&
          result.result.LastEvaluatedKey.eventId
        ) {
          k = result.result.LastEvaluatedKey.eventId;
        }
        const cases = (accessControl.hidePHI ? result.result.Items.map(Job.anonymize) : result.result.Items).filter(job => job.sourceOrgId !== job.orgId);
        storeCases(cases, k, orgID);
      }
    } catch (e) {
      this.props.signalError(`Error getting jobs: ${e.message ? e.message : e}.`);
      setTimeout(this.props.clearError, 10000);
    }

    this.setState({ isLoading: false, refreshing: false });
  }

  getPaginationKey = (refresh) => {
    const context = this.getContext();
    if (!context || !context.key) {
      return "";
    }

    const page = this.getPage() + 1;
    let index = calculatePageIndex(page, 3);
    if (!refresh) {
      index++;
    }

    try {
      const key = context.key[index];
      return key;
    } catch (e) {
      return "";
    }
  }

  handleAutoRefreshToggle = event => {
    const toggle = event.target.checked;
    if (toggle) {
      this.setState({
        autoRefresh: toggle,
        timer: setInterval(this.updateJobs.bind(this, true), 300000) // five minutes
      });
    } else if (this.state.timer) {
      clearInterval(this.state.timer);
      this.setState({
        timer: null,
        autoRefresh: false
      });
    }
  }

  getColor(jobStatus) {
    if (jobStatus === "Finished Processing") {
      return "success";
    } else if (jobStatus === "Failed") {
      return "danger";
    } else if (jobStatus === "Queued" || jobStatus === "Receiving Dicoms" || jobStatus === "Waiting for Dicoms") {
      return "primary";
    } else if (jobStatus === "Pending" || jobStatus === "Waiting") {
      return "rose";
    } else if (jobStatus === "Invalid Input" || jobStatus === "Invalid") {
      return "";
    } else {
      return "warning";
    }
  }

  getJobStatus(jobStatus) {
    if (jobStatus === "Finished Processing") {
      return "Finished";
    } else if (jobStatus === "Receiving Dicoms") {
      return "Receiving";
    } else if (jobStatus === "Downloading To Edge") {
      return "Downloading";
    } else {
      return jobStatus;
    }
  }

  getCases = () => {
    const context = this.getContext();
    if (!context || !context.data) {
      return [];
    }
    return context.data;
  }

  getPages = () => {
    const cases = this.getCases();
    if (!cases.length) { return 1; }
    return Math.ceil(cases.length / 10);
  }

  allPagesFulfilled = () => {
    const { orgID, cases } = this.props;
    if (orgID && cases[orgID] && cases[orgID].consumerOrgs) {
      return cases[orgID].consumerOrgs.allPagesFulfilled;
    } else if (orgID) {
      return false;
    } else {
      return cases.consumerOrgs.allPagesFulfilled;
    }
  }

  orgName = (id) => {
    const { org: { orgUploadConfig } } = this.props;
    const config = (orgUploadConfig || []).find(c => id.endsWith(c.id));
    return config ? config.orgName : "";
  }

  render() {
    const { classes } = this.props;
    const { autoRefresh, refreshing, isLoading } = this.state;
    let tableData = [];
    const data = this.getCases();
    const page = this.getPage();
    const allPagesFulfilled = this.allPagesFulfilled();

    if (data.length) {
      tableData = data.map((prop) => {
        return {
          mode: (
            <div style={{ marginLeft: "20px", marginRight: "20px" }}>
              {prop.mode === "CLOUD" ? (
                <CloudIcon style={{ color: "gray" }} />
              ) : (
                <DeviceIcon style={{ color: "gray" }} />
              )}
            </div>
          ),
          org: (
            <Cell
              label="Organization"
              value={this.orgName(prop.appAETitle)}
            />
          ),
          appAETitle: (
            <Cell
              label="AETitle"
              value={prop.appAETitle}
            />
          ),
          received: (
            <Cell
              label={`Started ${moment
                .duration(moment().diff(moment(prop.received)))
                .humanize()} ago`}
              value={moment(prop.received).format("MM-DD-YYYY")}
            />
          ),
          status: (
            <div className="actions-center" style={{ marginRight: "30px" }}>
              <Button
                color={this.getColor(prop.jobStatus)}
                round
                disabled={refreshing}
                onClick={() => {
                  this.props.history.push({
                    pathname: "/app/case",
                    search: `?id=${prop.eventId}`,
                    state: {
                      job: prop
                    }
                  });
                }}
              >
                {this.getJobStatus(prop.jobStatus)}
              </Button>
            </div>
          )
        };
      });
    }

    return (
      <GridContainer>
        <ItemGrid xs={12}>
          <IconCard
            icon={InfoOutline}
            title="Latest Multi-Organization Uploads"
            iconColor="green"
            content={
              tableData && (
                <div>
                  <ItemGrid xs={12}>
                    <div style={{ textAlign: "right" }}>
                      <p
                        style={{
                          fontSize: "small",
                          color: "gray",
                          margin: 0,
                        }}
                      >
                        {this.state.lastRefresh && `Last refreshed at ${moment(this.state.lastRefresh).format("h:m:s a")}`}
                      </p>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={refreshing}
                            checked={autoRefresh}
                            onChange={e => this.handleAutoRefreshToggle(e)}
                            value="autoRefresh"
                            classes={{
                              checked: classes.switchChecked,
                              bar: classes.switchBarChecked,
                              icon: classes.switchIcon,
                              default: classes.switchUnchecked,
                              iconChecked: classes.switchIconChecked
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={
                          autoRefresh
                            ? "Auto Refresh is On"
                            : "Auto Refresh is Off"
                        }
                      />
                      <Button
                        color="primary"
                        disabled={refreshing}
                        onClick={() => this.updateJobs(true)}
                      >
                        Refresh
                      </Button>
                    </div>
                  </ItemGrid>
                  <ReactTable
                    sortable={false}
                    data={tableData}
                    noDataText="No Cases!"
                    columns={[
                      {
                        Header: "",
                        accessor: "mode",
                        maxWidth: 100
                      },
                      {
                        Header: "",
                        accessor: "org"
                      },
                      {
                        Header: "",
                        accessor: "appAETitle"
                      },
                      {
                        Header: "",
                        accessor: "received",
                      },
                      {
                        Header: "",
                        accessor: "status"
                      }
                    ]}
                    refreshing={refreshing}
                    page={page}
                    pageSize={10}
                    showPaginationBottom
                    loading={isLoading}
                    className="-highlight"
                    onGetMore={this.updateJobs}
                    onPageChange={this.changePage}
                    PaginationComponent={Pagination}
                    allPagesFulfilled={allPagesFulfilled}
                    canGetMorePages
                  />
                </div>
              )
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  org: state.user.org,
  cases: state.cases,
  accessControl: accessControlSelector(state)
});

const mapDispatchToProps = dispatch => ({
  storeCases: (cases, key, orgID) => {
    dispatch(storeConsumerOrgCases(cases, key, orgID));
  },
  refreshCases: (cases, key, orgID) => {
    dispatch(refreshConsumerOrgCases(cases, key, orgID));
  },
  changeCasesPage: (page, orgID) => {
    dispatch(changeConsumerOrgCasesPage(page, orgID));
  },
  getJobs: (orgID, key) => getSourceOrgJobs(orgID, key),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  WithNotifications(
    withRouter(
      withStyles(styles)(ConsumerOrgCases)
    )
  )
);
