import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoadingModal from "components/LoadingModal/LoadingModal.jsx";
import DatePickerModal from "components/DatePickerModal";
import Button from "components/CustomButtons/Button";
import { WithNotifications } from "components/HigherOrder";

// local components
import SuccessRate from "./components/SuccessRate";
import TotalCases from "./components/TotalCases";
import MostEngagingApp from "./components/MostEngagingApp";
import AppSuccessFailure from "./components/AppSuccessFailure";
import AppUsage from "./components/AppUsage";
import SingleAppSuccessFailure from "./components/SingleAppSuccessFailure";
import MostEngagingDevice from "./components/MostEngagingDevice";
import DeviceSuccessFailure from "./components/DeviceSuccessFailure";
import DeviceUsage from "./components/DeviceUsage";
import ModalityUsage from "./components/ModalityUsage";
import ModalitySuccessFailure from "./components/ModalitySuccessFailure";
import ScannerUsage from "./components/ScannerUsage";
import ScannerSuccessFailure from "./components/ScannerSuccessFailure";
import ScannerAppUsage from "./components/ScannerAppUsage";
import UniqueJobsByApp from "./components/UniqueJobsByApp";
import AppSuccessRates from "./components/AppSuccessRates";
import AppFailureCodes from "./components/AppFailureCodes";
import GeneticKits from "./components/GeneticKits";
import TotalActiveDevices from "views/Organization/components/TotalActiveDevices";
import OrgPatientStats from "./components/OrgPatientStats";

import ReportsModel from "models/Reports";

// api functions
import { storeReport, storeSampleKitsReport, storeOrgPatientsReport } from "actions/index";
import { orgStatsQuery, sampleKitsStatsQuery, orgPatientStatsQuery } from "libs/apiLib";

export class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.today = moment();
    this.week = moment().startOf("week");
    this.month = moment().startOf("month");
    this.year = moment().startOf("year");
    this.state = {
      start: props.start || moment(),
      end: props.end || moment(),
      isLoading: false,
      modal: null,
    };
  }

  componentDidMount() {
    if (!this.report()) {
      this.fetchReports(this.state.start, this.state.end);
    }
    if (!this.sampleKitReport()) {
      this.fetchSampleKitsReport(this.state.start, this.state.end);
    }
    if (!this.orgPatientsReport()) {
      this.fetchOrgPatientsStats(this.state.start, this.state.end);
    }
    const { start, end } = this.getDatesInURLSearchParams();
    if (this.validDates({ start, end })) {
      this.updateDatesInURLSearchParams({ start, end });
      this.setState({
        start,
        end,
      });
    } else {
      this.updateDatesInURLSearchParams(this.state);
    }
  }

  getDatesInURLSearchParams = () => {
    const url = new URLSearchParams(this.props.location.search);
    return {
      start: url.has("start") ? moment(url.get("start")) : undefined,
      end: url.has("end") ? moment(url.get("end")) : undefined
    };
  }

  validDates = ({ start, end }) => {
    if (!start || !end) {
      return false;
    }
    const s = moment(start);
    const e = moment(end);
    return s.isValid() && e.isValid();
  }

  updateDatesInURLSearchParams = ({ start, end }) => {
    if (!this.validDates({ start, end })) {
      return;
    }
    const url = new URLSearchParams(this.props.location.search);
    url.set("start", start.format("YYYY-MM-DD"));
    url.set("end", end.format("YYYY-MM-DD"));
    const search = url.toString();
    this.props.history.push({
      pathname: this.props.location.pathname,
      search
    });
  }

  componentDidUpdate(_, prevState) {
    const prevKey = this.key(prevState.start, prevState.end);
    const key = this.key(this.state.start, this.state.end);
    const { reports } = this.props;
    const orgID = this.orgID();
    const report = reports && reports[orgID] && reports[orgID][key];
    if (prevKey !== key && !report) {
      this.fetchReports(this.state.start, this.state.end);
    }
    const sampleKitReport = reports && reports.sampleKits && reports.sampleKits[orgID] && reports.sampleKits[orgID][key];
    if (prevKey !== key && !sampleKitReport) {
      this.fetchSampleKitsReport(this.state.start, this.state.end);
    }
    const patientStatReport = reports && reports.patients && reports.patients[orgID] && reports.patients[orgID][key];
    if (prevKey !== key && !patientStatReport) {
      this.fetchOrgPatientsStats(this.state.start, this.state.end);
    }
  }

  key = (start, end) => {
    if (!start || !end) { return undefined; }
    return `${start.format("YYYY-MM-DD")}:${end.format("YYYY-MM-DD")}`;
  }

  handleDateChange = (start, end) => {
    this.updateDatesInURLSearchParams({ start, end });
    this.setState({ start, end, modal: null });
  }

  report = () => {
    const { reports } = this.props;
    const { start, end } = this.state;
    const key = this.key(start, end);
    const orgID = this.orgID();
    return reports[orgID] && reports[orgID][key] && new ReportsModel(this.state.start.format("YYYYMMDD"), this.state.end.format("YYYYMMDD"), reports[orgID][key]) || undefined;
  }

  sampleKitReport = () => {
    const { reports } = this.props;
    const { start, end } = this.state;
    const key = this.key(start, end);
    const orgID = this.orgID();
    return reports.sampleKits && reports.sampleKits[orgID] && reports.sampleKits[orgID][key] || undefined;
  }

  orgPatientsReport = () => {
    const { reports } = this.props;
    const { start, end } = this.state;
    const key = this.key(start, end);
    const orgID = this.orgID();
    return reports.patients && reports.patients[orgID] && reports.patients[orgID][key] || undefined;
  }

  orgID = () => {
    const orgID = new URLSearchParams(this.props.location.search).get("orgId");
    return orgID ? orgID : this.props.user.orgId;
  }

  fetchReports = async (start, end) => {
    const { storeReport, fetchReports, signalError, clearError, user, location: { search } } = this.props;
    start = start || this.state.start;
    end = end || this.state.end;
    const orgId = new URLSearchParams(search).get("orgId");
    const key = this.key(start, end);
    this.setState({ isLoading: true });
    try {
      const report = await fetchReports(start, end, orgId);
      if (report.success && report.stats && report.stats.Items.length) {
        storeReport(report.stats.Items, key, orgId || user.orgId);
      }
    } catch (e) {
      signalError("Error getting report. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  fetchSampleKitsReport = async (start, end) => {
    const { storeSampleKitsReport, fetchSampleKitsReport, signalError, clearError, user, location: { search } } = this.props;
    start = start || this.state.start;
    end = end || this.state.end;
    const orgId = new URLSearchParams(search).get("orgId");
    const key = this.key(start, end);
    this.setState({ isLoading: true });
    try {
      const report = await fetchSampleKitsReport(start, end, orgId || user.orgId);
      if (report.success && report.stats && report.stats.Items.length) {
        storeSampleKitsReport(report.stats.Items, key, orgId || user.orgId);
      }
    } catch (e) {
      signalError("Error getting sample kit report. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  fetchOrgPatientsStats = async (start, end) => {
    const { storeOrgPatientsReport, fetchOrgPatientsReport, signalError, clearError } = this.props;
    start = start || this.state.start;
    end = end || this.state.end;
    const key = this.key(start, end);
    this.setState({ isLoading: true });
    try {
      const orgID = this.orgID();
      const report = await fetchOrgPatientsReport(orgID, start, end);
      if (report.success && report.stats && report.stats.length) {
        storeOrgPatientsReport(report.stats, key, orgID);
      }
    } catch (error) {
      signalError("Error getting patients report. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  openDatePickerModal = () => {
    this.setState({
      modal: <DatePickerModal
        start={this.state.start}
        end={this.state.end}
        onConfirm={this.handleDateChange}
        onCancel={() => this.setState({ modal: null })}
      />
    });
  }

  render() {
    const { start, end, isLoading, modal } = this.state;
    const report = this.report();
    const geneticKitsReport = this.sampleKitReport();
    const orgPatientsReport = this.orgPatientsReport();
    return (
      <React.Fragment>
        {isLoading && <LoadingModal />}
        {modal}
        <GridContainer style={{ marginTop: "3em" }}>
          <ItemGrid xs={12} md={6}>
            <h2 style={{ margin: 0 }}>Insights</h2>
          </ItemGrid>
          <ItemGrid xs={12} md={6}>
            <GridContainer justify="flex-end">
              <ItemGrid>
                <Button color="white" onClick={this.openDatePickerModal}>{`${start.format("MMMM Do YYYY")} - ${end.format("MMMM Do YYYY")}`}</Button>
              </ItemGrid>
            </GridContainer>
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid container xs={12} sm={4}>
            <TotalCases report={report} />
          </ItemGrid>
          <ItemGrid container xs={12} sm={4}>
            <SuccessRate report={report} />
          </ItemGrid>
          <ItemGrid container xs={12} sm={4}>
            <TotalActiveDevices report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid container xs={12} sm={6}>
            <MostEngagingApp report={report} />
          </ItemGrid>
          <ItemGrid container xs={12} sm={6}>
            <MostEngagingDevice report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid container xs={12} sm={12} md={6}>
            <AppSuccessFailure report={report} />
          </ItemGrid>
          <ItemGrid container xs={12} sm={12} md={6}>
            <AppUsage report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid container xs={12} md={6}>
            <DeviceSuccessFailure report={report} />
          </ItemGrid>
          <ItemGrid container xs={12} md={6}>
            <DeviceUsage report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid container xs={12} md={6}>
            <ModalitySuccessFailure report={report} />
          </ItemGrid>
          <ItemGrid container xs={12} md={6}>
            <ModalityUsage report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid container xs={12} md={6}>
            <ScannerSuccessFailure report={report} />
          </ItemGrid>
          <ItemGrid container xs={12} md={6}>
            <ScannerUsage report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12}>
            <UniqueJobsByApp report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12}>
            <ScannerAppUsage report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12}>
            <AppSuccessRates report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12}>
            <SingleAppSuccessFailure report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12}>
            <AppFailureCodes report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12}>
            <GeneticKits report={geneticKitsReport} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12}>
            <OrgPatientStats report={orgPatientsReport} />
          </ItemGrid>
        </GridContainer>
      </React.Fragment>
    );
  }
}

Reports.propTypes = {
  clearError: PropTypes.func.isRequired,
  signalError: PropTypes.func.isRequired,
  start: PropTypes.instanceOf(moment),
  end: PropTypes.instanceOf(moment),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  user: PropTypes.shape({
    orgId: PropTypes.string.isRequired
  }).isRequired,
  reports: PropTypes.objectOf(PropTypes.objectOf(PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      appStats: PropTypes.shape({
        successful: PropTypes.number.isRequired,
        failures: PropTypes.number.isRequired,
        stats: PropTypes.arrayOf(
          PropTypes.shape({
            appName: PropTypes.string.isRequired,
            successful: PropTypes.number.isRequired,
            failures: PropTypes.number.isRequired,
          }).isRequired,
        ).isRequired,
      }).isRequired,
    })),
  )),
  storeReport: PropTypes.func.isRequired,
  fetchReports: PropTypes.func.isRequired,
  fetchSampleKitsReport: PropTypes.func.isRequired,
  storeSampleKitsReport: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  reports: state.reports,
});

const mapDispatchToProps = (dispatch) => ({
  // uses dispatch
  storeReport: (report, key, orgID) => dispatch(storeReport(report, key, orgID)),
  storeSampleKitsReport: (report, key, orgID) => dispatch(storeSampleKitsReport(report, key, orgID)),
  storeOrgPatientsReport: (report, key, orgID) => dispatch(storeOrgPatientsReport(report, key, orgID)),

  // does not use dispatch
  fetchReports: (start, end, orgID) => orgStatsQuery(start.format("YYYYMMDD"), end.format("YYYYMMDD"), orgID),
  fetchSampleKitsReport: (start, end, orgId) => sampleKitsStatsQuery(start.format("YYYYMMDD"), end.format("YYYYMMDD"), orgId),
  fetchOrgPatientsReport: (orgId, start, end) => orgPatientStatsQuery(orgId, start.format("YYYYMMDD"), end.format("YYYYMMDD"))
});

export default connect(mapStateToProps, mapDispatchToProps)(WithNotifications(Reports));
