import React from "react";
import pt from "prop-types";
import { AppConfigFactory } from "models/AppConfig";

// react table
import ReactTable from "react-table";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputLabel from "material-ui/Input/InputLabel";
import FormControl from "material-ui/Form/FormControl";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import Switch from "material-ui/Switch";
import Tooltip from "material-ui/Tooltip";

// core 
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Modal from "components/Modal";

// styles
import tableStyle from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyleModal.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const style = {
  ...tableStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  selectMaxHeight: {
    maxHeight: 150
  },
  toolTipZIndex: {
    zIndex: 6000,
  }
};

export class RouteManager extends React.Component {
  static propTypes = {
    classes: pt.shape({}).isRequired,
    onDeleteRoute: pt.func.isRequired,
    onAddRoute: pt.func.isRequired,
    onCancel: pt.func.isRequired,
    app: pt.shape({}).isRequired,
    dcmDevices: pt.arrayOf(pt.shape({})).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      cloudUpload: false,
      cloudIndex: null,
      newRouteSrcDevice: "All",
      output: ["All"],
      newRouteDestDevice: "",
      newDicomRoute: false,
      routes: null
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.validForm = this.validForm.bind(this);
    this.handleCloudToggle = this.handleCloudToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleNewDicomRoute = this.toggleNewDicomRoute.bind(this);
    this.onAddRoute = this.onAddRoute.bind(this);
    this.handleNewDicomRoute = this.handleNewDicomRoute.bind(this);
    this.onDeleteRoute = this.onDeleteRoute.bind(this);
  }

  componentDidMount() {
    this.updateAppRoutes();
  }

  updateAppRoutes = () => {
    const { app } = this.props;

    if (app.routes) {
      if (app.routes.length > 0) {
        app.routes.forEach((r, index) => {
          if (r.type === "CLOUD") { this.setState({ cloudUpload: true, cloudIndex: index }); }
        });
      }
      this.setState({ routes: app.routes });
    }
  }

  handleUpdate = () => {
  }

  validForm = () => {
    return this.state.newRouteDestDevice.length > 0;
  }

  handleCloudToggle = () => event => {
    // check if route already exists
    let route;
    if (this.state.routes) {
      route = this.state.routes.find((r) => {
        if (r.type === "CLOUD") { return true; }
      });
    }

    // true if user wants to create the cloud route
    // false if they want to delete it
    let enableCloud = event.target.checked;

    if (enableCloud && route) {
      // already exists...ignore...possibly show an aler
    } else if (enableCloud && !route) {
      // create it
      this.onAddRoute({
        type: "CLOUD",
        label: "Cloud Storage",
        filesDir: ""
      });
    } else if (route && !enableCloud) {
      this.onDeleteRoute(
        route,
        this.state.cloudIndex
      );
    }
  };

  isExistingRoute = () => {
    const { newRouteDestDevice, newRouteSrcDevice, output, routes } = this.state;
    if (!routes) {
      return false;
    }
    const srcDevice = this.findDevice("nodeId", newRouteSrcDevice);
    const srcAETitle = srcDevice ? srcDevice.aeTitle : undefined;
    const destDevice = this.findDevice("nodeId", newRouteDestDevice);
    const destAETitle = destDevice ? destDevice.aeTitle : undefined;
    return routes.some(route => {
      const { aeTitle, dicomDir, fromCallerAE } = route;
      return aeTitle === destAETitle &&
        (fromCallerAE ? fromCallerAE === srcAETitle : newRouteSrcDevice === "All") &&
        (dicomDir ? this.containSameElements(Array.isArray(dicomDir) ? dicomDir : dicomDir.split(","), output) : this.containSameElements(output, ["All"]));
    });
  }

  containSameElements = (a, b) => {
    const c = [...a].sort();
    const d = [...b].sort();
    if (c.length !== d.length) {
      return false;
    }
    for (let i = 0; i < c.length; i++) {
      if (c[i] !== d[i]) {
        return false;
      }
    }
    return true;
  }

  findDevice = (key, value) => {
    const { dcmDevices } = this.props;
    if (!dcmDevices) {
      return undefined;
    }
    return dcmDevices.find(dcm => dcm[key] === value);
  }


  handleNewDicomRoute = () => {

    // get the device, return if not found
    const device = this.findDevice("nodeId", this.state.newRouteDestDevice);
    if (!device) {
      return;
    }

    // return if already exists on routes
    if (this.isExistingRoute()) {
      return;
    }

    const srcDevice = this.findDevice("nodeId", this.state.newRouteSrcDevice);

    this.onAddRoute({
      type: "DICOM",
      label: "Dicom",
      dicomDir: !this.state.output.includes("All") ? this.state.output : undefined,
      destDeviceId: device.localNodeId,
      destDeviceName: device.name,
      ipAddress: device.ipAddress,
      aeTitle: device.aeTitle,
      port: device.port,
      fromCallerAE: srcDevice && this.state.newRouteSrcDevice !== "All" ? srcDevice.aeTitle : undefined,
    });
  }

  handleDropdownChange = (event, key) => {
    if (key === "output") {
      const values = event.target.value;
      if (values.includes("All") && !this.state.output.includes("All")) {
        this.setState({ output: ["All"] });
      } else {
        this.setState({ output: values.filter(v => v !== "All") });
      }
      return;
    }
    this.setState({
      [key]: event.target.value
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  toggleNewDicomRoute = () => {
    this.setState({
      newDicomRoute: !this.state.newDicomRoute
    });
  }

  onAddRoute = (route) => {
    this.props.onAddRoute(this.props.app, route);
  }

  onDeleteRoute = (route, index) => {
    this.props.onDeleteRoute(
      this.props.app, route, index);
  }

  getOutputs = () => {
    const { app } = this.props;
    const config = app.appConfigs && app.appConfigs.find(conf => conf.file === "config.json");
    if (!config) {
      return [];
    }
    let results = [{ description: "All", value: "All" }];
    try {
      const appConfig = AppConfigFactory.build(config.config);
      for (const output in appConfig.outputs) {
        if (!appConfig.outputs.hasOwnProperty(output)) {
          continue;
        }
        const outputConfig = appConfig.outputs[output];
        if (outputConfig.type && outputConfig.type.includes("group")) {
          for (const subOutput in appConfig.outputs[output]) {
            if (!appConfig.outputs[output].hasOwnProperty(subOutput) || subOutput === "type") {
              continue;
            }
            const subOutputConfig = appConfig.outputs[output][subOutput];
            if (subOutputConfig.enabled) {
              results.push({ description: subOutputConfig.description, value: subOutputConfig.folderName });
            }
          }
        } else if (outputConfig.enabled) {
          results.push({ description: outputConfig.description, value: outputConfig.folderName });
        }
      }
    } catch (error) {
      return [];
    }
    return results;
  }

  render() {
    const { classes, app, dcmDevices } = this.props;
    const { newDicomRoute } = this.state;

    let dicomRoutes = [];
    let dcmDevicesItems = [];
    let srcItems = [
      <MenuItem value="All" key={-1}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}>All</MenuItem>
    ];
    const outputs = this.getOutputs();
    let outputItems;
    let columns = 6;
    if (outputs) {
      columns = 4;
      outputItems = outputs.map((output, i) => (
        <MenuItem value={output.value} key={i}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}>{output.description}</MenuItem>
      ));
    }

    if (app.routes) {
      app.routes.forEach((route, key) => {
        if (route.type === "DICOM") {
          const routeOutputs = route.dicomDir ? Array.isArray(route.dicomDir) ? route.dicomDir.map(r => {
            const result = outputs.find(o => o.value === r);
            return result ? result.description : r;
          }).join(",\n") : route.dicomDir.split(",").map(r => {
            const result = outputs.find(o => o.value === r);
            return result ? result.description : r;
          }).join(",\n") : null;
          dicomRoutes.push({
            from: (
              <div>
                <div>
                  {route.fromCallerAE || "All"}
                </div>
                <div className={classes.label}>From</div>
              </div>
            ),
            to: (
              <div>
                <div>
                  {route.destDeviceName}
                </div>
                <div className={classes.label}>To</div>
              </div>
            ),
            output: (
              <div>
                <div>
                  {routeOutputs ?
                    <Tooltip classes={{ popper: classes.toolTipZIndex }} id="tooltip-icon" title={routeOutputs} placement="top">
                      <p>Custom</p>
                    </Tooltip> :
                    "Everything"}
                </div>
                <div className={classes.label}>Output</div>
              </div>
            ),
            options: (
              <div className={classes.right}>
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => {
                    this.onDeleteRoute(route, key);
                  }}
                >Delete</Button>
              </div>
            )
          });
        }
      });
    }

    let allowNewDicom = true;

    // JGuzman - HOTFIX
    // 11/15/2018
    // Removing check due to need for more than 5 routes.
    // No effect on backend or edgegateway
    // if (dicomRoutes.length > 5) { allowNewDicom = false; }

    if (dcmDevices) {
      if (dcmDevices.length > 0) {
        dcmDevicesItems = dcmDevices.map((dcm, key) => {
          return (
            <MenuItem value={dcm.nodeId} key={key}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}>{dcm.name}</MenuItem>
          );
        });
        srcItems = [
          ...srcItems,
          ...dcmDevicesItems,
        ];
      }
    }

    return (
      app &&
      <Modal
        backgroundStyle={{
          zIndex: 5998,
        }}
        style={{
          height: 800,
          width: 1000,
          zIndex: 5999,
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer className={classes.wideHorizontalPadding}>
          <ItemGrid xs={12}>
            <h2 className={classes.center}>{app.name}</h2>
          </ItemGrid>
          <ItemGrid xs={12}>
            <p>Configure how the results of this app are routed or sent to other devices, or systems.</p>
          </ItemGrid>
          <ItemGrid xs={12}>
            <h3>Upload Types</h3>
          </ItemGrid>
          <ItemGrid xs={12}>
            <GridContainer>
              <ItemGrid xs={6}>
                <h5>Cloud Upload</h5>
              </ItemGrid>
              <ItemGrid xs={6} className={classes.right}>
                <Switch
                  checked={this.state.cloudUpload}
                  onChange={this.handleCloudToggle()}
                  value="cloudUpload"
                  classes={{
                    checked: classes.switchChecked,
                    bar: classes.switchBarChecked,
                    icon: classes.switchIcon,
                    default: classes.switchUnchecked,
                    iconChecked: classes.switchIconChecked
                  }}
                />
              </ItemGrid>
            </GridContainer>
          </ItemGrid>
          <ItemGrid xs={12}>
            <GridContainer style={{ marginTop: 20 }}>
              <ItemGrid xs={12} sm={6}>
                <h3>Dicom Routes</h3>
              </ItemGrid>
              <ItemGrid xs={12} sm={6} className={classes.right}>
                {
                  this.state.newDicomRoute ? (
                    <div style={{ marginTop: 12 }}>
                      <Button color="danger"
                        size="sm"
                        style={{ marginRight: "10px" }}
                        onClick={this.toggleNewDicomRoute}>
                        Cancel
      								</Button>
                      <Button color="success"
                        disabled={!this.validForm()}
                        size="sm"
                        onClick={this.handleNewDicomRoute}>
                        Add
      								</Button>
                    </div>
                  ) : (allowNewDicom ? (
                    <Button color="success"
                      size="sm"
                      style={{ marginTop: 25 }}
                      onClick={this.toggleNewDicomRoute}>
                      New
      							</Button>
                  ) : (
                    <Button color="danger"
                      size="sm"
                      style={{ marginTop: 25 }}
                      disabled
                    >
                        Max Dicom Routes
      							</Button>)
                  )
                }

              </ItemGrid>
              {newDicomRoute && dcmDevicesItems &&
                <ItemGrid xs={12}>
                  <GridContainer>
                    <ItemGrid xs={columns}>
                      <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel htmlFor="new-route-device" className={classes.selectLabel}>
                          From
                				</InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu + " " + "dialog-dropdown",
                            classes: {
                              paper: classes.selectMaxHeight
                            }
                          }}
                          classes={{
                            select: classes.select
                          }}
                          inputProps={{
                            name: "newRouteSrcDevice",
                            id: "newRouteSrcDevice"
                          }}
                          onChange={e => this.handleDropdownChange(e, "newRouteSrcDevice")}
                          value={this.state.newRouteSrcDevice}
                        >
                          {srcItems}
                        </Select>
                      </FormControl>
                    </ItemGrid>
                    <ItemGrid xs={columns}>
                      <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel htmlFor="new-route-device" className={classes.selectLabel}>
                          Send To
                				</InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu + " " + "dialog-dropdown",
                            classes: {
                              paper: classes.selectMaxHeight
                            }
                          }}
                          classes={{
                            select: classes.select
                          }}
                          inputProps={{
                            name: "newRouteDestDevice",
                            id: "new-route-device"
                          }}
                          onChange={e => this.handleDropdownChange(e, "newRouteDestDevice")}
                          value={this.state.newRouteDestDevice}
                        >
                          {dcmDevicesItems}
                        </Select>
                      </FormControl>
                    </ItemGrid>
                    {
                      outputItems && (
                        <ItemGrid xs={4}>
                          <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel htmlFor="new-route-device" className={classes.selectLabel}>
                              Output
                						</InputLabel>
                            <Select
                              multiple
                              MenuProps={{
                                className: classes.selectMenu + " " + "dialog-dropdown",
                                classes: {
                                  paper: classes.selectMaxHeight
                                }
                              }}
                              classes={{
                                select: classes.select
                              }}
                              inputProps={{
                                name: "output",
                                id: "output",
                              }}
                              onChange={e => this.handleDropdownChange(e, "output")}
                              value={this.state.output}
                            >
                              {outputItems}
                            </Select>
                          </FormControl>
                        </ItemGrid>
                      )
                    }
                  </GridContainer>
                </ItemGrid>}
            </GridContainer>
          </ItemGrid>
          {newDicomRoute && outputItems &&
            <ItemGrid xs={12}>
              <GridContainer direction="column" alignItems="flex-end" className={classes.wideVerticalPadding}>
                <ItemGrid xs={12}>
                  <div className={classes.label}>Selected Output</div>
                </ItemGrid>
                {this.state.output.map((o, i) => {
                  const item = outputs.find(p => p.value === o);
                  return (
                    <ItemGrid xs={12} key={i}><p>{item ? item.description : "All"}</p></ItemGrid>
                  );
                })}
              </GridContainer>
            </ItemGrid>
          }
          <ItemGrid xs={12}>
            <ReactTable
              sortable={false}
              showPaginationBottom
              data={dicomRoutes}
              noDataText="No Dicom Routes!"
              columns={[
                {
                  Header: "",
                  accessor: "from",
                },
                {
                  Header: "",
                  accessor: "to",
                },
                {
                  Header: "",
                  accessor: "output",
                },
                {
                  Header: "",
                  accessor: "options",
                },
              ]}
              minRows={5}
              defaultPageSize={5}
              className="-highlight"
            />
          </ItemGrid>
          <ItemGrid container justify="center" xs={12}>
            <Button onClick={this.props.onCancel}>Close</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }
}

export default withStyles(style)(RouteManager);