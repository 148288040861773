import React from "react";

import AceEditor from 'react-ace';
import 'brace/mode/java';
import 'brace/theme/xcode';

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core 
import Button from "components/CustomButtons/Button.jsx";

// styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const editorStyle = {
    overflow: {
        overflow: "auto",
        overflowY: "scroll",
        maxHeight: "126px",
        minHeight: "126px",
        border: "solid 1px #eee",
        borderRadius: "6px",
        padding: "15px"
    },
    "@media (min-height: 568px)": {
        overflow: {
            maxHeight: "130px",
            minHeight: "130px"
        }
    },
    "@media (min-height: 640px)": {
        overflow: {
            maxHeight: "140px",
            minHeight: "140px"
        }
    },
    "@media (min-height: 667px)": {
        overflow: {
            maxHeight: "174px",
            minHeight: "174px"
        }
    },
    "@media (min-height: 700px)": {
        overflow: {
            maxHeight: "194px",
            minHeight: "194px"
        }
    },
    "@media (min-height: 736px)": {
        overflow: {
            maxHeight: "216px",
            minHeight: "216px"
        }
    },
    "@media (min-height: 770px)": {
        overflow: {
            maxHeight: "248px",
            minHeight: "248px"
        }
    },
    "@media (min-height: 796px)": {
        overflow: {
            maxHeight: "266px",
            minHeight: "266px"
        }
    },
    "@media (min-height: 812px)": {
        overflow: {
            maxHeight: "275px",
            minHeight: "275px"
        }
    },
    "@media (min-height: 848px)": {
        overflow: {
            maxHeight: "305px",
            minHeight: "305px"
        }
    },
    "@media (min-height: 904px)": {
        overflow: {
            maxHeight: "350px",
            minHeight: "350px"
        }
    },
    "@media (min-height: 986px)": {
        overflow: {
            maxHeight: "414px",
            minHeight: "414px"
        }
    },
    "@media (min-height: 1024px)": {
        overflow: {
            maxHeight: "446px",
            minHeight: "446px"
        }
    },
    "@media (min-height: 1060px)": {
        overflow: {
            maxHeight: "482px",
            minHeight: "482px"
        }
    },
    "@media (min-height: 1092px)": {
        overflow: {
            maxHeight: "500px",
            minHeight: "500px"
        }
    },
    "@media (min-height: 1148px)": {
        overflow: {
            maxHeight: "550px",
            minHeight: "550px"
        }
    },
    "@media (min-height: 1228px)": {
        overflow: {
            maxHeight: "612px",
            minHeight: "612px"
        }
    },
    "@media (min-height: 1276px)": {
        overflow: {
            maxHeight: "650px",
            minHeight: "650px"
        }
    },
    "@media (min-height: 1320px)": {
        overflow: {
            maxHeight: "690px",
            minHeight: "690px"
        }
    },
    "@media (min-height: 1366px)": {
        overflow: {
            maxHeight: "720px",
            minHeight: "720px"
        }
    }
}

const styles = {
    ...sweetAlertStyle,
    ...editorStyle,
}

class LogViewer extends React.Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose = () => {
        this.props.onClose();
    }

    render() {
        const { classes, log } = this.props;

        return (
            log &&
            <SweetAlert
                style={{
                    position: "fixed",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    margin: "0",
                    minWidth: "300px",
                    width: "80%",
                    height: "80%",
                    minHeight: "600px"
                }}
                title="Logs"
                onConfirm={() => this.handleClose()}
                onCancel={() => this.handleClose()}
                showConfirm={false}
                confirmBtnCssClass={
                    classes.button + " " + classes.success
                }
                cancelBtnCssClass={
                    classes.button + " " + classes.danger
                }
            >
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <AceEditor
                        mode="java"
                        theme="xcode"
                        name="logs"
                        fontSize={12}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={log}
                        wrapEnabled={true}
                        readOnly={true}
                        width={"100%"}
                        height={"500px"}
                        showLineNumbers
                    />
                    <div style={{ marginTop: "40px" }}>
                        <Button onClick={this.handleClose}>Close</Button>
                    </div>
                </div>
            </SweetAlert>
        )
    }
}

export default withStyles(styles)(LogViewer);