import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import Button from "components/CustomButtons/Button.jsx";
import DateRangePicker from "components/DatePickerButtons/DateRangePicker";
import withStyles from "material-ui/styles/withStyles";

import SweetAlert from "react-bootstrap-sweetalert";

const style = {
  base: {
    minHeight: "600px"
  },
  toBottom: {
    position: "absolute",
    bottom: "15px",
    left: "0",
  }
};

/**
 * January, February, and March (Q1)
    April, May, and June (Q2)
    July, August, and September (Q3)
    October, November, and December (Q4)
 */
export class DatePickerModal extends React.Component {
  static propTypes = {
  	firstDayOfYear: PropTypes.instanceOf(moment),
  	day: PropTypes.instanceOf(moment),
  	week: PropTypes.instanceOf(moment),
  	month: PropTypes.instanceOf(moment),
  	quarter: PropTypes.instanceOf(moment),
  	start: PropTypes.instanceOf(moment).isRequired,
  	end: PropTypes.instanceOf(moment).isRequired,
  	onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired
  }

  static quarters = {
    q1Start: moment().startOf("year").startOf("quarter"),
    q1End: moment().startOf("year").endOf("quarter"),
    q2Start: moment().startOf("year").add(1, "quarter").startOf("quarter"),
    q2End: moment().startOf("year").add(1, "quarter").endOf("quarter"),
    q3Start: moment().startOf("year").add(2, "quarter").startOf("quarter"),
    q3End: moment().startOf("year").add(2, "quarter").endOf("quarter"),
    q4Start: moment().startOf("year").add(3, "quarter").startOf("quarter"),
    q4End: moment().startOf("year").add(3, "quarter").endOf("quarter")
  }

  constructor(props) {
    super(props);
    this.firstDayOfYear = props.firstDayOfYear || moment().startOf("year");
  	this.day = props.day || moment().startOf("day");
  	this.week = props.week || moment().startOf("week");
    this.month = props.month || moment().startOf("month");
    this.quarter = props.quarter || moment().startOf("quarter");
  	this.state = {
  		start: props.start,
  		end: props.end,
  		active: this.calculateActive(props.start, props.end),
  	};
  }

  key = (start, end) => `${start.format("YYYY-MM-DD")}:${end.format("YYYY-MM-DD")}`

  calculateActive = (start, end) => {
  	const key = this.key(start, end);
  	switch (key) {
  	case this.key(this.day, this.day):
  		return 0;
  	case this.key(this.week, this.day):
  		return 1;
  	case this.key(this.month, this.day):
  		return 2;
  	case this.key(this.quarter, this.day):
  		return 3;
  	default:
  		return -1;
  	}
  }

  handleDateChange = (key, date) => {
  	switch (key) {
  	case "end":
  		// if end is selected before start make start to 'date' and set end to start plus one day
  		if (date.isBefore(this.state.start)) {
  			this.setState({
          active: -1,
  				start: moment(date),
  				end: moment(date).add(1, "d"),
  			});
  			return;
  		}
  		break;
  	case "start":
  		break;
  	default:
  		return;
  	}
  	this.setState({ [key]: date, active: -1 });
  }

  handlePredefinedDateChange = (n) => {
  	let start;
  	switch (n) {
  	case 0:
  		start = this.day;
  		break;
  	case 1:
  		start = this.week;
  		break;
  	case 2:
  		start = this.month;
      break;
    case 3:
      start = this.quarter;
      break;
    case 4:
      start = DatePickerModal.quarters.q1Start;
      break;
    case 5:
      start = DatePickerModal.quarters.q2Start;
      break;
    case 6:
      start = DatePickerModal.quarters.q3Start;
      break;
    case 7:
      start = DatePickerModal.quarters.q4Start;
      break;
    case 8:
      start = this.firstDayOfYear;
      break;
  	default:
  		return;
  	}
  	this.setState({
  		active: n,
  		end: moment(),
  		start,
  	});
  }

  isDisabled = (n) => {
    switch (n) {
    case 4:
      return moment(this.day).isBefore(DatePickerModal.quarters.q1Start);
    case 5:
      return moment(this.day).isBefore(DatePickerModal.quarters.q2Start);
    case 6:
      return moment(this.day).isBefore(DatePickerModal.quarters.q3Start);
    case 7:
      return moment(this.day).isBefore(DatePickerModal.quarters.q4Start);
    default:
      return false;
    }
  }

  render() {
  	const { start, end } = this.state;
  	const { onConfirm, onCancel, classes } = this.props;
  	const calculateBtnColor = (n) => this.state.active === n ? "primary" : "white";
  	return (
  		<SweetAlert
  			customClass={classes.base}
  			title="Select Date Range"
  			showConfirm={false}
  			showCancel={false}
  			onConfirm={() => onConfirm(start, end)}
  		>
  			<GridContainer justify="center">
  				<DateRangePicker
  					color={calculateBtnColor(-1)}
  					onStartChange={(date) => this.handleDateChange("start", date)}
  					onEndChange={(date) => this.handleDateChange("end", date)}
  					defaultValue={start}
  					viewDate={start}
  					start={start}
  					end={end}
  					closeOnSelect
  				/>
  			</GridContainer>
  			<GridContainer>
  				<ItemGrid xs={6}>
  					<Button fullWidth color={calculateBtnColor(0)} onClick={() => this.handlePredefinedDateChange(0)}>Today</Button>
  				</ItemGrid>
  				<ItemGrid xs={6}>
  					<Button fullWidth color={calculateBtnColor(1)} onClick={() => this.handlePredefinedDateChange(1)}>This Week</Button>
  				</ItemGrid>
  			</GridContainer>
  			<GridContainer>
  				<ItemGrid xs={6}>
  					<Button fullWidth color={calculateBtnColor(2)} onClick={() => this.handlePredefinedDateChange(2)}>This Month</Button>
  				</ItemGrid>
  				<ItemGrid xs={6}>
  					<Button fullWidth color={calculateBtnColor(3)} onClick={() => this.handlePredefinedDateChange(3)}>This Quarter</Button>
  				</ItemGrid>
  			</GridContainer>
  			<GridContainer>
  				<ItemGrid xs={6}>
  					<Button fullWidth color={calculateBtnColor(4)} onClick={() => this.handlePredefinedDateChange(4)} disabled={this.isDisabled(4)}>1st Quarter</Button>
  				</ItemGrid>
  				<ItemGrid xs={6}>
  					<Button fullWidth color={calculateBtnColor(5)} onClick={() => this.handlePredefinedDateChange(5)} disabled={this.isDisabled(5)}>2nd Quarter</Button>
  				</ItemGrid>
  			</GridContainer>
  			<GridContainer>
  				<ItemGrid xs={6}>
  					<Button fullWidth color={calculateBtnColor(6)} onClick={() => this.handlePredefinedDateChange(6)} disabled={this.isDisabled(6)}>3rd Quarter</Button>
  				</ItemGrid>
  				<ItemGrid xs={6}>
  					<Button fullWidth color={calculateBtnColor(7)} onClick={() => this.handlePredefinedDateChange(7)} disabled={this.isDisabled(7)}>4th Quarter</Button>
  				</ItemGrid>
  			</GridContainer>
  			<GridContainer>
  				<ItemGrid xs={12}>
  					<Button fullWidth color={calculateBtnColor(8)} onClick={() => this.handlePredefinedDateChange(8)}>Year-To-Date</Button>
  				</ItemGrid>
  			</GridContainer>
  			<GridContainer justify="center" className={classes.toBottom}>
  				<ItemGrid xs={6}>
  					<Button onClick={onCancel}>Cancel</Button>
  				</ItemGrid>
  				<ItemGrid xs={6}>
  					<Button color="primary" onClick={() => onConfirm(start, end)}>Update</Button>
  				</ItemGrid>
  			</GridContainer>
  		</SweetAlert>
  	);
  }
}

export default withStyles(style)(DatePickerModal);