import {
  UPDATE_DATALAKE_ACCESS_TOKEN
} from "actions/ActionTypes";

export const initialState = {};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
  case UPDATE_DATALAKE_ACCESS_TOKEN:
    return {
      ...state,
      [action.orgId]: action.token
    };
  default:
    return state;
  }
};