import React from "react";
import { connect } from "react-redux";
import pt from "prop-types";
import { Link } from "react-router-dom";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import { WithNotifications } from "components/HigherOrder";
import ReactTable from "react-table";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Tooltip from "material-ui/Tooltip";
import WifeTethering from "material-ui-icons/WifiTethering";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import tableStyles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";

import { isDateOlderThanMins } from "libs/utils";
import { getAllDevices } from "libs/apiLib";

const styles = {
  ...tooltip,
  ...tableStyles,
  ...buttonsStyle,
};

export class Devices extends React.Component {
  static propTypes = {
  	classes: pt.shape({
  		label: pt.string.isRequired,
  		center: pt.string.isRequired,
  	}).isRequired,
  	devices: pt.shape({
  		gpu: pt.bool.isRequired,
  		locationDetails: pt.shape({
  			city: pt.string,
  			region_code: pt.string,
  			country_name: pt.string,
  		}),
  		name: pt.string.isRequired,
  		checkIn: pt.string.isRequired,
  		deviceId: pt.string.isRequired,
  		orgId: pt.string.isRequired,
  		orgName: pt.string.isRequired,
  		softwareVersion: pt.string.isRequired,
  	}),
  	getDevices: pt.func.isRequired,
  	signalError: pt.func.isRequired,
  	clearError: pt.func.isRequired,
  	history: pt.shape({
  		push: pt.func.isRequired,
  	}).isRequired,
  }

  state = {
  	isLoading: false,
  	devices: null,
  }

  componentDidMount() {
  	this.getDevices();
  }

  getDevices = async () => {
  	const { getDevices, signalError, clearError } = this.props;
  	this.setState({ isLoading: true });
  	try {
  		const results = await getDevices();
  		if (results.success && results.devices) {
  			this.setState({ devices: results.devices });
  		} else if (!results.success) {
  			throw new Error();
  		}
  	} catch (e) {
  		signalError("Error getting devices. Please try again later.");
  		setTimeout(clearError, 5000);
  	}
  	this.setState({ isLoading: false });
  }

  getConnectivityStatus(checkIn) {
  	if (!checkIn) { return "Offline"; }

  	if (isDateOlderThanMins(checkIn, 20)) { return "Offline"; }
  	else if (isDateOlderThanMins(checkIn, 11)) { return "Weak"; }
  	else { return "Connected"; }
  }

  getEdgeStatusColor(checkIn) {
  	if (!checkIn) { return "default"; }

  	if (isDateOlderThanMins(checkIn, 20)) { return "default"; }
  	else if (isDateOlderThanMins(checkIn, 11)) { return "warning"; }
  	else { return "success"; }
  }

  pluckVersion = (str) => {
	  if (str)
	  	{return str.replace(/[-a-zA-Z]/g, "");}
  }

  formatRow = (row) => {
  	const { history, classes } = this.props;
  	const { label, center } = classes;
  	return {
  		status: (
  			<div style={{ margin: "10px" }}>
  				<Tooltip id="tooltip-icon" title={this.getConnectivityStatus(row.checkIn)} placement="bottom-end">
  					<IconButton size="xs" aria-label={this.getConnectivityStatus(row.checkIn)}
  						color={this.getEdgeStatusColor(row.checkIn)}>
  						<WifeTethering className={classes.icons} />
  					</IconButton>
  				</Tooltip>
  			</div>
  		),
  		name: (
  			<div>
  				<div>{row.name}</div>
  				<div className={label}>Device Name</div>
  			</div>
  		),
  		orgName: (
  			<div>
          <Link
            to={{
              pathname: "/dashboard",
              search: `?orgId=${row.orgId}`
            }}
          >{row.orgName}</Link>
  				<div className={label}>Organization</div>
  			</div>
  		),
  		type: (
  			<div className={center}>
  				<Button disabled round>{row.gpu === "yes" ? "GPU Device" : "CPU Device"}</Button>
  				<div className={label}>Version {this.pluckVersion(row.softwareVersion)}</div>
  			</div>
  		),
  		location: (
  			<div>
  				{row.locationDetails &&
            <div>
            	<div>
            		{row.locationDetails.city && row.locationDetails.region_code && (row.locationDetails.city + ", " + row.locationDetails.region_code)}
            	</div>
            	<div className={label}>
            		{row.locationDetails.country_name && row.locationDetails.country_name}
            	</div>
            </div>
  				}
  			</div>
  		),
  		options: (
  			<div className={label + " " + classes.right}>
  				<Button color="primary"
  					onClick={() => {
              const url = new URLSearchParams();
              url.set("id", row.deviceId);
              url.set("orgId", row.orgId);
              const search = url.toString();
  						history.push({
  							pathname: "/edge/device",
  							search,
  							state: { device: row }
  						});
  					}}
  				>Manage</Button>
  			</div>
  		)
  	};
  }

  render() {
  	const { isLoading, devices } = this.state;
  	return (
  		<GridContainer>
  			<ItemGrid xs={12} sm={12} md={12} lg={12}>
  				<HeaderCard
  					headerColor="orange"
  					cardTitle="Devices"
  					cardSubtitle="Manage All Devices"
  					content={
  						<div
  							style={{ padding: "0 40px" }}
  						>
  							<ReactTable
  								defaultSorted={[
  									{
  										id: "eventDate",
  										desc: false,
  									}
  								]}
  								noDataText="No Devices"
  								data={devices && devices.map(this.formatRow) || []}
  								columns={[
  									{
  										Header: "",
  										accessor: "status",
  										maxWidth: 120
  									},
  									{
  										Header: "",
  										accessor: "name",
  									},
  									{
  										Header: "",
  										accessor: "orgName"
  									},
  									{
  										Header: "",
  										accessor: "type",
  										maxWidth: 250
  									},
  									{
  										Header: "",
  										accessor: "location",
  									},
  									{
  										Header: "",
  										accessor: "options",
  									}
  								]
  								}
  								sortable={false}
  								resizable={false}
  								className="-highlight"
  								loading={isLoading}
  								pageSize={10}
  								showPaginationBottom
  							/>
  						</div>
  					}
  				/>
  			</ItemGrid>
  		</GridContainer>
  	);
  }
}

export default connect(undefined, () => ({
  getDevices: () => getAllDevices(),
}))(WithNotifications(withStyles(styles)(Devices)));
