import React from "react";
import pt from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import { CircularProgress } from "material-ui/Progress";
import { APPS } from "constants/Apps";
import Radio from "components/Radio";
import CustomDropdownSmall from "components/CustomDropdown/CustomDropdownSmall";

export class OptionsStep extends React.Component {
  static propTypes = {
    selectedApp: pt.shape({
      appId: pt.string.isRequired,
      versions: pt.arrayOf(pt.string).isRequired
    }).isRequired,
    selectedOptions: pt.shape({
      mci: pt.bool,
      appVersion: pt.string
    }),
    onSelect: pt.func.isRequired,
    errorMessage: pt.string,
    loading: pt.bool,
    userIsHLXSupport: pt.bool.isRequired,
  }

  versionsList = () => {
    const { selectedApp: { versions }} = this.props;
    let hasProdVersion = false;
    if (!versions.length) {
      return ["Latest"];
    }
    const list = versions.map(v => {
      if (this.isProdSemanticVersion(v)) {
        hasProdVersion = true;
      }
      if (v === "dev") {
        return "Dev";
      }
      return v;
    });
    return hasProdVersion ? ["Latest", ...list] : list;
  }

  // prod semver should only match x.y.z, with no a-z chars
  isProdSemanticVersion = (t) => t && (t.search(/^([0-9]+)\.([0-9]+)\.([0-9]+)/) !== -1) && (t.search(/[a-zA-Z]+/) === -1);

  renderVersionSelect = () => {
    if (!this.props.userIsHLXSupport) {
      return null;
    }
    return (
      <React.Fragment>
        <ItemGrid xs={12} style={{ marginTop: 45 }}>
          <p>Which version of the application would you like to run?</p>
        </ItemGrid>
        <ItemGrid xs={12}>
          <CustomDropdownSmall
            buttonColor="primary"
            buttonText={this.props.selectedOptions && this.props.selectedOptions.appVersion || "Version"}
            dropdownList={this.versionsList()}
            onAction={(action) => this.props.onSelect("appVersion", action)}
          />
        </ItemGrid>
      </React.Fragment>
    );
  }

  renderAIRRMCI = () => {
    const { onSelect, selectedOptions } = this.props;
    return (
      <React.Fragment>
        <ItemGrid xs={12}>
          <p>Which Alzheimer&apos;s Integrated Risk Report screen do you want to run for this patient?</p>
        </ItemGrid>
        <ItemGrid xs={12}>
          <p><span>
            <Radio onClick={() => onSelect("mci", true)} checked={selectedOptions && selectedOptions.mci} />
          </span>
            MCI Screen
          </p>

          <p style={{ marginLeft: 50 }}>
            This patient has been diagnosed with and/or is experiencing symptoms of mild cognitive impairment (MCI). Please calculate their risk relative to the MCI population.
          </p>

          <p><span>
            <Radio onClick={() => onSelect("mci", false)} checked={selectedOptions && selectedOptions.mci !== undefined && selectedOptions.mci === false} />
          </span>
            General Population Screen
          </p>

          <p style={{ marginLeft: 50 }}>
            This patient is undergoing routine screening for late-onset Alzheimer&apos;s disease risk. Please calculate their risk relative to the general population.
          </p>
        </ItemGrid>
        {this.renderVersionSelect()}
      </React.Fragment>
    );
  }

  renderNoOptions = () => {
    if (!this.props.userIsHLXSupport) {
      return <p>No options exist for this app. Proceed to the next step.</p>;
    }
    return this.renderVersionSelect();
  }

  renderOptionsByApp = () => {
    if (!this.props.selectedApp) {
      return null;
    }
    const { appId } = this.props.selectedApp;
    switch (appId) {
    case APPS.airr:
    case APPS.airrMci:
      return this.renderAIRRMCI();
    default:
      return this.renderNoOptions();
    }
  }

  render() {
    const { errorMessage } = this.props;
    if (this.props.loading) {
      return (
        <GridContainer justify="center">
          <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />
        </GridContainer>
      );
    }
    return (
      <GridContainer style={{ paddingRight: 30, paddingLeft: 30, paddingTop: 30 }}>
        {this.renderOptionsByApp()}
        {errorMessage && <ItemGrid xs={12}><p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p></ItemGrid>}
      </GridContainer>
    );
  }
}

export default OptionsStep;