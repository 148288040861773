import React from "react";
import pt from "prop-types";
import Chartist from "chartist";
import ReactChartist from "react-chartist";
import Legend from "./Legend";

const Line = ({ noXSeriesRender, emptyXSeriesRender, xSeries, xLabels, legend, hideLegend, legendTitle, options }) => {
  if (!xSeries) {
    return noXSeriesRender();
  }
  if (!xSeries.length) {
    return emptyXSeriesRender();
  }
  return (
    <React.Fragment>
      <ReactChartist
        className="ct-double-octave"
        type="Line"
        data={{
          labels: xLabels,
          series: xSeries,
        }}
        options={options}
        listener={{
          draw: (context) => {
            if (context.type === "point") {
              if (context.series.replaceCallback) {
                const attr = context.series.replaceCallback(context);
                const circle = new Chartist.Svg("circle", attr);
                context.element.replace(circle);
              } else if (context.series.styleCallback) {
                const style = context.series.styleCallback(context.value);
                context.element.attr(style);
              } else if (context.series.meta && context.series.meta.style) {
                context.element.attr(context.series.meta);
              }
            }
            if (context.type === "line" && context.seriesMeta && context.seriesMeta.style) {
              context.element.attr(context.seriesMeta);
            }
          }
        }}
      />
      <Legend title={legendTitle} legend={legend} hideLegend={hideLegend} />
    </React.Fragment>
  );
};

Line.propTypes = {
  xLabels: pt.arrayOf(pt.string),
  xSeries: pt.arrayOf(pt.any),
  options: pt.shape({}),
  noXSeriesRender: pt.func.isRequired,
  emptyXSeriesRender: pt.func.isRequired,
  legendTitle: pt.string,
  legend: Legend.propTypes.legend,
  hideLegend: pt.bool,
};

export default Line;