import React from "react";
import pt from "prop-types";
import withStyles from "material-ui/styles/withStyles";
import MaterialCheckbox from "material-ui/Checkbox";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Check from "material-ui-icons/Check";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const Checkbox = ({ classes, label, onClick, checked }) => {
  return (
    <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
      <FormControlLabel
        control={
          <MaterialCheckbox
            onClick={onClick}
            checked={checked || false}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{ checked: classes.checked }}
          />
        }
        classes={{ label: classes.label }}
        label={label}
      />
    </div>
  );
};

Checkbox.propTypes = {
  classes: pt.shape({
  }).isRequired,
  label: pt.string,
  onClick: pt.func.isRequired,
  checked: pt.bool,
};

export default withStyles(styles)(Checkbox);