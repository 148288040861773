import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import Datetime from "react-datetime"
import cx from "classnames"

// components
import GridContainer from "components/Grid/GridContainer.jsx"
import ItemGrid from "components/Grid/ItemGrid.jsx"
import Button from "components/CustomButtons/Button.jsx"

// material-ui components
import withStyles from "material-ui/styles/withStyles"

const style = {
	end: {
		"& .rdtPicker": {
			left: "-36px"
		},
		"& .rdtPicker:after": {
			left: "249px"
		},
		"& .rdtPicker:before": {
			left: "249px"
		}
	},
	center: {
		"& .rdtPicker": {
			left: "20%"
		},
		"& .rdtPicker:after": {
			left: "130px"
		},
		"& .rdtPicker:before": {
			left: "130px"
		}
	},
	inline: {
		display: "inline-block"
	}
}

const DatePickerButton = ({ start, end, classes, inline, pickerAlign, color, defaultValue, viewDate, timeFormat, onChange, justify, closeOnSelect, disableFutureDays, disablePastDays, dateFormat, dateRangeFormat, preventSelectionPast, label, buttonProps }) => {
	let classNames = cx({
		[classes.inline]: inline,
		[classes[pickerAlign]]: pickerAlign !== undefined
	})
	return (
		<Datetime
			className={classNames}
			isValidDate={(date) => {
				if (disableFutureDays) {
					return date.isBefore(moment())
				}
				if (preventSelectionPast) {
					if (disablePastDays) {
						return date.isAfter(moment()) && date.isBefore(preventSelectionPast)
					}
					return date.isBefore(preventSelectionPast)
				}
				if (disablePastDays) {
					return date.isAfter(moment())
				}
				return true
			}}
			closeOnSelect={closeOnSelect}
			defaultValue={defaultValue}
			viewDate={viewDate}
			timeFormat={timeFormat}
			onChange={onChange}
			dateFormat={dateFormat}
			renderInput={(inputProps, openCalendar) => {
				return (
					<GridContainer justify={justify}>
						<ItemGrid container direction="column" xs={12} justify={justify}>
							{label && <p style={{ marginBottom: 0 }}>{label}</p>}
							<Button {...inputProps} {...buttonProps} color={color} onClick={openCalendar}>{!end || start.isSame(end) ? start.format(dateFormat) : `${start.format(dateRangeFormat)} - ${end.format(dateRangeFormat)}`}</Button>
						</ItemGrid>
					</GridContainer>
				)
			}}
		/>
	)
}

DatePickerButton.defaultProps = {
	dateRangeFormat: "MMMM Do YYYY",
	dateFormat: "dddd, MMMM Do YYYY",
	disableFutureDays: true,
	disablePastDays: false,
	closeOnSelect: false,
	justify: "center",
	color: "blackWhite",
	timeFormat: false,
	defaultValue: moment(),
	viewDate: moment(),
	start: moment(),
}

DatePickerButton.propTypes = {
	classes: PropTypes.shape({
		end: PropTypes.string,
		center: PropTypes.string,
	}),
	inline: PropTypes.bool,
	pickerAlign: PropTypes.oneOf(["center", "end"]),
	dateRangeFormat: PropTypes.string,
	dateFormat: PropTypes.string,
	disableFutureDays: PropTypes.bool,
	disablePastDays: PropTypes.bool,
	preventSelectionPast: PropTypes.instanceOf(moment),
	closeOnSelect: PropTypes.bool,
	justify: PropTypes.oneOf(["center", "flex-end", "flex-start"]),
	color: PropTypes.string,
	timeFormat: PropTypes.bool,
	onChange: PropTypes.func,
	defaultValue: PropTypes.instanceOf(moment),
	viewDate: PropTypes.instanceOf(moment),
	start: PropTypes.instanceOf(moment).isRequired,
	end: PropTypes.instanceOf(moment),
	label: PropTypes.string,
	buttonProps: PropTypes.shape({}),
}

export default withStyles(style)(DatePickerButton)