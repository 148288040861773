import React from 'react';
// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Slide from "material-ui/transitions/Slide";
import Dialog from "material-ui/Dialog";
import DialogTitle from "material-ui/Dialog/DialogTitle";
import DialogContent from "material-ui/Dialog/DialogContent";
import DialogActions from "material-ui/Dialog/DialogActions";
import IconButton from "material-ui/IconButton";
// material-ui-icons
import Close from "material-ui-icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

class Modal extends React.Component {
    constructor(props) {
        super(props);
    }

    handleClose(modal) {
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modalWide
                }}
                open={this.props.modal}
                transition={Transition}
                keepMounted
                onClose={() => this.handleClose("modal")}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description">
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <IconButton
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => this.handleClose("modal")}>
                        <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 className={classes.modalTitle}>Terms and Conditions</h4>
                </DialogTitle>
                <DialogContent
                    id="modal-slide-description"
                    className={classes.modalBody}>
                    <h1>Terms and Conditions</h1>


                    <p>Last updated: April 16, 2018</p>


                    <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the https://cloud.healthlytix.com website (the "Service") operated by Multimodal Imaging Services Corporation ("us", "we", or "our").</p>

                    <p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Service.</p>

                    <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the Service.</p>


                    <h2>Communications</h2>

                    <p>By creating an Account on our service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>


                    <h2>Purchases</h2>

                    <p>If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</p>

                    <p>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</p>

                    <p>The service may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.</p>

                    <p>We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.</p>

                    <p>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</p>


                    <h2>Availability, Errors and Inaccuracies</h2>

                    <p>We are constantly updating product and service offerings on the Service. We may experience delays in updating information on the Service and in our advertising on other web sites. The information found on the Service may contain errors or inaccuracies and may not be complete or current. Products or services may be mispriced, described inaccurately, or unavailable on the Service and we cannot guarantee the accuracy or completeness of any information found on the Service.</p>

                    <p>We therefore reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</p>




                    <h2>Subscriptions</h2>

                    <p>Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.</p>

                    <p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Multimodal Imaging Services Corporation cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting Multimodal Imaging Services Corporation customer support team.</p>

                    <p>A valid payment method, including credit card, is required to process the payment for your Subscription. You shall provide Multimodal Imaging Services Corporation with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Multimodal Imaging Services Corporation to charge all Subscription fees incurred through your account to any such payment instruments.</p>

                    <p>Should automatic billing fail to occur for any reason, Multimodal Imaging Services Corporation will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>


                    <h2>Free Trial</h2>

                    <p>Multimodal Imaging Services Corporation may, at its sole discretion, offer a Subscription with a free trial for a limited period of time ("Free Trial").</p>

                    <p>You may be required to enter your billing information in order to sign up for the Free Trial.</p>

                    <p>If you do enter your billing information when signing up for the Free Trial, you will not be charged by Multimodal Imaging Services Corporation until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.</p>

                    <p>At any time and without notice, Multimodal Imaging Services Corporation reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.</p>


                    <h2>Fee Changes</h2>

                    <p>Multimodal Imaging Services Corporation, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>

                    <p>Multimodal Imaging Services Corporation will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</p>

                    <p>Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>


                    <h2>Refunds</h2>

                    <p>Except when required by law, paid Subscription fees are non-refundable.</p>



                    <h2>Content</h2>

                    <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness.</p>

                    <p>By posting Content on or through the Service, You represent and warrant that: (i) the Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>

                    <p>You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through the Service. However, by posting Content using the Service you grant us the right and license to use, modify, perform, display, reproduce, and distribute such Content on and through the Service.</p>

                    <p>Multimodal Imaging Services Corporation has the right but not the obligation to monitor and edit all Content provided by users.</p>

                    <p>In addition, Content found on or through this Service are the property of Multimodal Imaging Services Corporation or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>


                    <h2>Accounts</h2>

                    <p>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.</p>

                    <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>

                    <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</p>


                    <p>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p>




                    <h2>Intellectual Property</h2>

                    <p>The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Multimodal Imaging Services Corporation and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Multimodal Imaging Services Corporation.</p>


                    <h2>Links To Other Web Sites</h2>

                    <p>Our Service may contain links to third party web sites or services that are not owned or controlled by Multimodal Imaging Services Corporation</p>

                    <p>Multimodal Imaging Services Corporation has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>

                    <p>You acknowledge and agree that Multimodal Imaging Services Corporation shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third party web sites or services.</p>

                    <p>We strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services that you visit.</p>


                    <h2>Termination</h2>

                    <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>

                    <p>If you wish to terminate your account, you may simply discontinue using the Service.</p>

                    <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>


                    <h2>Indemnification</h2>

                    <p>You agree to defend, indemnify and hold harmless Multimodal Imaging Services Corporation and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms, or c) Content posted on the Service.</p>



                    <h2>Limitation Of Liability</h2>

                    <p>In no event shall Multimodal Imaging Services Corporation, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>


                    <h2>Disclaimer</h2>

                    <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>

                    <p>Multimodal Imaging Services Corporation its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>


                    <h2>Exclusions</h2>

                    <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.</p>



                    <h2>Governing Law</h2>

                    <p>These Terms shall be governed and construed in accordance with the laws of California, United States, without regard to its conflict of law provisions.</p>

                    <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.</p>


                    <h2>Changes</h2>

                    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

                    <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>


                    <h2>Contact Us</h2>

                    <p>If you have any questions about these Terms, please contact us at support@healthlytix.com</p>

                    <h1>Privacy Policy</h1>


                    <p>Effective date: April 16, 2018</p>


                    <p>Multimodal Imaging Services Corporation ("us", "we", or "our") operates the https://cloud.healthlytix.com website and the HealthLytix Cloud mobile application (the "Service").</p>

                    <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

                    <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>


                    <h2>Definitions</h2>

                    <h3>Personal Data</h3>
                    <p>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>

                    <h3>Usage Data</h3>
                    <p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>

                    <h3>Cookies</h3>
                    <p>Cookies are small pieces of data stored on a User’s device.</p>

                    <h3>Data Controller</h3>
                    <p>Data Controller means a person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed.</p>
                    <p>For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>

                    <h3>Data Processor (or Service Providers)</h3>
                    <p>Data Processor (or Service Provider) means any person (other than an employee of the Data Controller) who processes the data on behalf of the Data Controller.</p>
                    <p>We may use the services of various Service Providers in order to process your data more effectively.</p>

                    <h3>Data Subject</h3>
                    <p>Data Subject is any living individual who is the subject of Personal Data.</p>

                    <h3>User</h3>
                    <p>The User is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>


                    <h2>Information Collection And Use</h2>

                    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                    <h3>Types of Data Collected</h3>

                    <h4>Personal Data</h4>

                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>

                    <ul>
                        <li>Email address</li><li>First name and last name</li><li>Cookies and Usage Data</li>
                    </ul>

                    <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by contacting us.</p>

                    <h4>Usage Data</h4>

                    <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data").</p>
                    <p>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                    <p>When you access the Service by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>


                    <h4>Tracking &amp; Cookies Data</h4>
                    <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                    <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                    <p>Examples of Cookies we use:</p>
                    <ul>
                        <li><strong>Session Cookies.</strong> We use Session Cookies to operate our Service.</li>
                        <li><strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.</li>
                        <li><strong>Security Cookies.</strong> We use Security Cookies for security purposes.</li>
                    </ul>

                    <h2>Use of Data</h2>

                    <p>Multimodal Imaging Services Corporation uses the collected data for various purposes:</p>
                    <ul>
                        <li>To provide and maintain our Service</li>
                        <li>To notify you about changes to our Service</li>
                        <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                        <li>To provide customer support</li>
                        <li>To gather analysis or valuable information so that we can improve our Service</li>
                        <li>To monitor the usage of our Service</li>
                        <li>To detect, prevent and address technical issues</li>
                        <li>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</li></ul>


                    <h2>Retention of Data</h2>
                    <p>Multimodal Imaging Services Corporation will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                    <p>Multimodal Imaging Services Corporation will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>

                    <h2>Transfer Of Data</h2>
                    <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
                    <p>If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</p>
                    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                    <p>Multimodal Imaging Services Corporation will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

                    <h2>Disclosure Of Data</h2>
                    <h3>Business Transaction</h3>
                    <p>If Multimodal Imaging Services Corporation is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

                    <h3>Disclosure for Law Enforcement</h3>
                    <p>Under certain circumstances, Multimodal Imaging Services Corporation may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>

                    <h3>Legal Requirements</h3>
                    <p>Multimodal Imaging Services Corporation may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                    <ul>
                        <li>To comply with a legal obligation</li>
                        <li>To protect and defend the rights or property of Multimodal Imaging Services Corporation</li>
                        <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                        <li>To protect the personal safety of users of the Service or the public</li>
                        <li>To protect against legal liability</li>
                    </ul>

                    <h2>Security Of Data</h2>
                    <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                    <h2>"Do Not Track" Signals</h2>
                    <p>We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>
                    <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>

                    <h2>Your Rights</h2>
                    <p>Multimodal Imaging Services Corporation aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
                    <p>Whenever made possible, you can update your Personal Data directly within your account settings section. If you are unable to change your Personal Data, please contact us to make the required changes.</p>
                    <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
                    <p>In certain circumstances, you have the right:</p>
                    <ul>
                        <li>To access and receive a copy of the Personal Data we hold about you</li>
                        <li>To rectify any Personal Data held about you that is inaccurate</li>
                        <li>To request the deletion of Personal Data held about you</li>
                    </ul>
                    <p>You have the right to data portability for the information you provide to Multimodal Imaging Services Corporation. You can request to obtain a copy of your Personal Data in a commonly used electronic format so that you can manage and move it.</p>
                    <p>Please note that we may ask you to verify your identity before responding to such requests.</p>

                    <h2>Service Providers</h2>
                    <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
                    <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                    <h3>Analytics</h3>
                    <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
                    <ul>
                        <li>
                            <p><strong>Google Analytics</strong></p>
                            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>
                            <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</p>
                            <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a></p>
                        </li>
                    </ul>



                    <h3>Behavioral Remarketing</h3>
                    <p>Multimodal Imaging Services Corporation uses remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.</p>
                    <ul>
                        <li>
                            <p><strong>Google AdWords</strong></p>
                            <p>Google AdWords remarketing service is provided by Google Inc.</p>
                            <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a></p>
                            <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>
                            <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a></p>

                        </li>
                        <li>
                            <p><strong>Twitter</strong></p>
                            <p>Twitter remarketing service is provided by Twitter Inc.</p>
                            <p>You can opt-out from Twitter's interest-based ads by following their instructions: <a href="https://support.twitter.com/articles/20170405">https://support.twitter.com/articles/20170405</a></p>
                            <p>You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: <a href="https://twitter.com/privacy">https://twitter.com/privacy</a></p>
                        </li>
                        <li>
                            <p><strong>Facebook</strong></p>
                            <p>Facebook remarketing service is provided by Facebook Inc.</p>
                            <p>You can learn more about interest-based advertising from Facebook by visiting this page: <a href="https://www.facebook.com/help/164968693837950">https://www.facebook.com/help/164968693837950</a></p>
                            <p>To opt-out from Facebook's interest-based ads follow these instructions from Facebook: <a href="https://www.facebook.com/help/568137493302217">https://www.facebook.com/help/568137493302217</a></p>
                            <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>, the Digital Advertising Alliance of Canada in Canada <a href="http://youradchoices.ca/">http://youradchoices.ca/</a> or the European Interactive Digital Advertising Alliance in Europe <a href="http://www.youronlinechoices.eu/">http://www.youronlinechoices.eu/</a>, or opt-out using your mobile device settings.</p>
                            <p>For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: <a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a></p>
                        </li>
                    </ul>

                    <h3>Payments</h3>
                    <p>We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
                    <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
                    <p>The payment processors we work with are:</p>
                    <ul>
                        <li>
                            <p><strong>Apple Store In-App Payments</strong></p>
                            <p>Their Privacy Policy can be viewed at <a href="https://www.apple.com/legal/privacy/en-ww/">https://www.apple.com/legal/privacy/en-ww/</a></p>
                        </li>
                        <li>
                            <p><strong>Google Play In-App Payments</strong></p>
                            <p>Their Privacy Policy can be viewed at <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></p>
                        </li>
                        <li>
                            <p><strong>Stripe</strong></p>
                            <p>Their Privacy Policy can be viewed at <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a></p>
                        </li>
                        <li>
                            <p><strong>PayPal or Braintree</strong></p>
                            <p>Their Privacy Policy can be viewed at <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">https://www.paypal.com/webapps/mpp/ua/privacy-full</a></p>
                        </li>
                        <li>
                            <p><strong>FastSpring</strong></p>
                            <p>Their Privacy Policy can be viewed at <a href=" http://fastspring.com/privacy/">http://fastspring.com/privacy/</a></p>
                        </li>
                        <li>
                            <p><strong>Authorize.net</strong></p>
                            <p>Their Privacy Policy can be viewed at <a href="https://www.authorize.net/company/privacy/">https://www.authorize.net/company/privacy/</a></p>
                        </li>
                        <li>
                            <p><strong>2Checkout</strong></p>
                            <p>Their Privacy Policy can be viewed at <a href="https://www.2checkout.com/policies/privacy-policy">https://www.2checkout.com/policies/privacy-policy</a></p>
                        </li>
                        <li>
                            <p><strong>Sage Pay</strong></p>
                            <p>Their policies can be viewed at <a href="https://www.sagepay.co.uk/policies">https://www.sagepay.co.uk/policies</a></p>
                        </li>
                        <li>
                            <p><strong>Square</strong></p>
                            <p>Their Privacy Policy can be viewed at <a href="https://squareup.com/legal/privacy-no-account">https://squareup.com/legal/privacy-no-account</a></p>
                        </li>
                        <li>
                            <p><strong>Go Cardless</strong></p>
                            <p>Their Privacy Policy can be viewed at <a href="https://gocardless.com/en-eu/legal/privacy/">https://gocardless.com/en-eu/legal/privacy/</a></p>
                        </li>
                        <li>
                            <p><strong>Elavon</strong></p>
                            <p>Their Privacy Policy can be viewed at <a href="https://www.elavon.com/privacy-pledge.html">https://www.elavon.com/privacy-pledge.html</a></p>
                        </li>
                        <li>
                            <p><strong>Verifone</strong></p>
                            <p>Their Privacy Policy can be viewed at <a href="https://www.verifone.com/en/us/legal">https://www.verifone.com/en/us/legal</a></p>
                        </li>
                    </ul>


                    <h2>Links To Other Sites</h2>
                    <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>


                    <h2>Children's Privacy</h2>
                    <p>Our Service does not address anyone under the age of 13 ("Children").</p>
                    <p>We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>


                    <h2>Changes To This Privacy Policy</h2>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                    <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>


                    <h2>Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, please contact us:</p>
                    <ul>
                        <li>By email: support@healthlytix.com</li>

                    </ul>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}>
                    <Button
                        onClick={() => this.handleClose("modal")}
                        color="successNoBackground">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(modalStyle)(Modal);