import React from "react";
import moment from "moment";
import pt from "prop-types";
import Reports from "models/Reports";
import Table from "react-table";

// core components
import { WithOrgReport } from "components/HigherOrder";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard";
import Button from "components/CustomButtons/Button";
import { AppUsageContent } from "views/Organization/components/AppUsage";
import Badge from "components/Badge/Badge";
import Dropdown from "components/CustomDropdown/CustomDropdown";

// styles
import withStyles from "material-ui/styles/withStyles";
import tableStyles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";

let AppSuccesses = ({ report, classes, metric }) => {
  let data = [];
  if (report) {
    const apps = report.appStats();
    if (apps) {
      data = Object.keys(apps).map((name) => ({ name, metric: metric === "total" ? apps[name].successful + apps[name].failures : apps[name][metric] }));
    }
  }

  const badgeColor = (metric) => {
    switch (metric) {
    case "successful":
      return "success";
    case "failures":
      return "danger";
    default:
      return "primary";
    }
  };
  return (
    <Table
      noDataText="No Report"
      data={data}
      columns={[
        {
          Header: "",
          accessor: "name",
          Cell: (cell) => (
            <div>
              <div>{cell.value}</div>
              <div className={classes.label}>Name</div>
            </div>
          )
        },
        {
          Header: "",
          accessor: "metric",
          Cell: (cell) => <div className={classes.right}><Badge color={badgeColor(metric)}>{cell.value}</Badge></div>
        },
      ]
      }
      sortable={false}
      resizable={false}
      pageSize={10}
      minRows={1}
      showPagination={false}
    />
  );
};

AppSuccesses.propTypes = {
  report: pt.instanceOf(Reports),
  classes: pt.shape({
    label: pt.string.isRequired,
  }).isRequired,
  metric: pt.string.isRequired,
};

AppSuccesses = withStyles(tableStyles)(AppSuccesses);

class AppStats extends React.Component {
  state = {
    metric: "Total"
  }

  handleMetricSelect = (metric) => this.setState({ metric })

  onApply = () => {
    const { report, onApply } = this.props;
    const { metric } = this.state;
    return onApply(report, metric.toLowerCase());
  }

  render() {
    const { start, end, report } = this.props;
    const { metric } = this.state;
    let subtitle = "";
    if (start && end) {
      subtitle = `From ${start.format("MMMM Do YYYY")} To ${end.format("MMMM Do YYYY")}`;
    }
    return (
      <HeaderCard
        headerColor="orange"
        cardTitle="App Usage"
        cardSubtitle={subtitle}
        content={
          <GridContainer>
            <ItemGrid>
              <h3>App Usage</h3>
            </ItemGrid>
            <ItemGrid xs={12}>
              <AppUsageContent report={report} />
            </ItemGrid>
            <ItemGrid xs={6} md={6}>
              <h3>App Statistics</h3>
            </ItemGrid>
            <ItemGrid xs={6} md={6} container justify="flex-end">
              <Dropdown
                style={{
                  marginTop: 10
                }}
                buttonColor="primary"
                buttonText={metric}
                dropdownList={[
                  "Total",
                  "Successful",
                  "Failures",
                ]}
                onAction={this.handleMetricSelect}
              />
            </ItemGrid>
            <ItemGrid xs={12}>
              <AppSuccesses report={report} metric={metric.toLowerCase()} />
            </ItemGrid>
            <ItemGrid xs={12}>
              <Button right color="success" onClick={this.onApply} disabled={!start || !end || !report}>Apply To Invoice</Button>
            </ItemGrid>
          </GridContainer>
        }
      />
    );
  }
}

AppStats.propTypes = {
  report: pt.instanceOf(Reports),
  start: pt.instanceOf(moment),
  end: pt.instanceOf(moment),
  onApply: pt.func.isRequired,
};

export {
  AppSuccesses,
  AppStats,
};
export default WithOrgReport(AppStats);