import React from "react";
import pt from "prop-types";
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import Radio from "components/Radio";
import AceEditor from "react-ace";
import yamljs from "yamljs";
import withStyles from "material-ui/styles/withStyles";

export class VersionSelectStep extends React.Component {
  static propTypes = {
    versions: pt.arrayOf(pt.string).isRequired,
    selectedVersion: pt.string,
    onSelect: pt.func.isRequired,
  }

  static defaultProps = {
    selectedVersion: ""
  }

  formatCell = (key) => (cell) => {
    const { selectedVersion, onSelect } = this.props;
    switch (key) {
    case "version":
      return <Cell label="Version" value={cell.original} />;
    case "select":
      return (
        <GridContainer justify="flex-end">
          <Radio onClick={() => onSelect(cell.original)} checked={selectedVersion && selectedVersion === cell.original || false} />
        </GridContainer>
      );
    default:
      return null;
    }
  };

  render() {
    const { versions } = this.props
    return (
      <GridContainer style={{ paddingRight: 30, paddingLeft: 30, paddingTop: 30 }}>
        <ItemGrid xs={12}>
          <ReactTable
              noDataText="Loading"
              sortable={false}
              loading={versions.length === 0}
              data={versions}
              minRows={versions.length > 5 ? 5 : versions.length}
              defaultPageSize={versions.length > 5 ? 5 : versions.length}
              showPagination={versions.length > 5}
              columns={
                [
                  { accessor: "version" },
                  { accessor: "select" },
                ].map(r => ({ accessor: r.accessor, Cell: this.formatCell(r.accessor), Header: "" }))
              }
            />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const editorStyle = {
  editor: {
    height: "200px",
    padding: "30px 30px 30px 10px",
    margin: "0",
    borderRadius: "6px",
    backgroundColor: "#F5F7F9",
    "& .ace-xcode": {
      backgroundColor: "#F5F7F9",
      color: "#5d5d5d",
    },
    "& .ace-xcode .ace_gutter": {
      backgroundColor: "#F5F7F9",
      color: "#c1c7cd",
    },
    "& .ace-xcode .ace_cursor": {
      color: "#5d5d5d",
    },
    "& .ace-xcode .ace_gutter-active-line": {
      backgroundColor: "inherit"
    }
  },
  "@media (min-height: 450px)": {
    editor: {
      height: "200px"
    }
  },
  "@media (min-height: 500px)": {
    editor: {
      height: "230px",
    }
  },
  "@media (min-height: 600px)": {
    editor: {
      height: "240px",
    }
  },
  "@media (min-height: 650px)": {
    editor: {
      height: "285px",
    }
  },
  "@media (min-height: 700px)": {
    editor: {
      height: "290px",
    }
  },
  "@media (min-height: 750px)": {
    editor: {
      height: "300px",
    }
  },
  "@media (min-height: 800px)": {
    editor: {
      height: "320px",
    }
  },
  "@media (min-height: 850px)": {
    editor: {
      height: "365px",
    }
  },
  "@media (min-height: 900px)": {
    editor: {
      height: "410px",
    }
  },
  "@media (min-height: 950px)": {
    editor: {
      height: "455px",
    }
  },
  "@media (min-height: 1000px)": {
    editor: {
      height: "500px",
    }
  }
}

class ConfigMergeStep extends React.Component {
  static propTypes = {
    selectedVersion: pt.shape({
      version: pt.string.isRequired,
      config: pt.string.isRequired,
    }),
    currentVersion: pt.shape({
      version: pt.string.isRequired,
      config: pt.string.isRequired,
    }).isRequired,
    onUpdate: pt.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      original: props.currentVersion,
      current: props.currentVersion
    }
  }

  validYAML = (str) => {
    try {
      const yml = yamljs.parse(str)
      return yml;
    } catch (e) {
      return false;
    }
  }

  editorChangeHandler = (yml) => {
    this.setState(prevState => ({
      ...prevState,
      current: {
        ...prevState,
        config: yml
      }
    }))
  }

  onBlur = () => {
    this.props.onUpdate(this.state.current.config);
  }

  render() {
    const { classes, currentVersion, selectedVersion } = this.props;
    return (
      <GridContainer>
      <ItemGrid xs={12}>
        <p style={{ fontWeight: 'bold', paddingLeft: 30, paddingRight: 30 }}>Use the configuration file to the left to merge any changes into your current configuration file on the right. The configuration to the right will be applied to the updated version.</p>
      </ItemGrid>
      <ItemGrid xs={12} container >
          <ItemGrid xs={6} style={{ textAlign: "left" }}>
            {selectedVersion && selectedVersion.version && <p>{`Configuration of selected version v${selectedVersion.version}`}</p>}
            <div className={classes.editor}>
              <AceEditor
                value={selectedVersion && selectedVersion.config || ""}
                mode="yaml"
                theme="xcode"
                name="config"
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={false}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                showLineNumbers
                editorProps={{ $blockScrolling: Infinity }}
                readOnly
              />
            </div>
          </ItemGrid>
          <ItemGrid xs={6} style={{ textAlign: "left" }}>
            {currentVersion && currentVersion.version && <p>{`Current Configuration v${currentVersion.version}`}</p>}
            <div className={classes.editor}>
              <AceEditor
                onBlur={this.onBlur}
                value={this.state.current.config && this.state.current.config || ""}
                mode="yaml"
                theme="xcode"
                name="config"
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={false}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                showLineNumbers
                editorProps={{ $blockScrolling: Infinity }}
                onChange={this.editorChangeHandler}
              />
            </div>
          </ItemGrid>
        </ItemGrid>
      </GridContainer>
    )
  }
}

ConfigMergeStep = withStyles(editorStyle)(ConfigMergeStep)

export { ConfigMergeStep }

class ConfirmStep extends React.Component {
  static propTypes = {
    selectedVersion: pt.shape({
      version: pt.string.isRequired,
      // config: pt.string.isRequired,
    })
  }

  render() {
    const { selectedVersion, classes } = this.props;
    if (
      !selectedVersion.version.length //||
      // !selectedVersion.config.length ||
      // !selectedVersion.dcm_anon.length
    ) {
      return null;
    }
    return (
      <GridContainer style={{ paddingRight: 30, paddingLeft: 30, paddingTop: 30 }}>
        <ItemGrid xs={12}>
          <p>Please confirm the following details are correct.</p>
          {/* <p>{`After you submit, the device will update itself to version ${selectedVersion.version} and apply the device and anonymization configurations.`}</p> */}
          <p>{`After you submit, the device will update itself to version ${selectedVersion.version}. Configuration files will not be modified. Please update them after the device updates.`}</p>
        </ItemGrid>
        <ItemGrid xs={12}><h4 style={{ color: "#00ACEF" }}>Version</h4></ItemGrid>
        <ItemGrid xs={12}><Cell label="Version" value={selectedVersion.version} /></ItemGrid>
          {/* <ItemGrid xs={6} style={{ textAlign: "left" }}>
            <h4 style={{ color: "#00ACEF" }}>Device Configuration</h4>
            <div className={classes.editor}>
              <AceEditor
                value={selectedVersion && selectedVersion.config || ""}
                mode="yaml"
                theme="xcode"
                name="config"
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={false}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                showLineNumbers
                editorProps={{ $blockScrolling: Infinity }}
                readOnly
              />
            </div>
          </ItemGrid>
          <ItemGrid xs={6} style={{ textAlign: "left" }}>
            <h4 style={{ color: "#00ACEF" }}>DICOM Anonymization Configuration</h4>
            <div className={classes.editor}>
              <AceEditor
                value={selectedVersion && selectedVersion.dcm_anon || ""}
                mode="yaml"
                theme="xcode"
                name="config"
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={false}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                showLineNumbers
                editorProps={{ $blockScrolling: Infinity }}
                readOnly
              />
            </div>
          </ItemGrid> */}
      </GridContainer>
    );
  }
}

ConfirmStep = withStyles(editorStyle)(ConfirmStep)

export { ConfirmStep }