import React from "react";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

class NewApiToken extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            label: ''
        }
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleConfirm = (e) => {
        e.preventDefault();
        if(this.validateForm())
            this.props.onCreate(this.state.label)
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    validateForm() {
        return (this.state.label.length > 0);
    }

    render() {
        return (
            <SweetAlert
                style={{ display: "block" }}
                title="Create API Token"
                onConfirm={() => this.handleConfirm()}
                onCancel={() => this.handleCancel()}
                showConfirm={false}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
            >
                <div>
                    <form style={{ paddingLeft: "20px", paddingRight: "20px"}} onSubmit={this.handleConfirm}>
                        {!this.props.apiId && !this.props.apiSecret &&
                        <CustomInput
                            labelText="Name for Token"
                            id="label"
                            error={!this.validateForm()}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                id: "label",
                                value: this.state.label,
                                onChange: this.handleChange
                            }}
                        />}
                        {this.props.apiId && this.props.apiSecret &&
                            <div>
                                <p>Please save this information. For security purposes, we won't be able to display 
                                    this token again.
                                </p>
                                <div>
                                    <h5>API ID</h5>
                                    <p>{this.props.apiId}</p>
                                </div>
                                <div>
                                    <h5>API Secret</h5>
                                    <p>{this.props.apiSecret}</p>
                                </div>
                            </div>
                        }
                        <div style={{ marginTop: "40px"}}>
                            <Button style={{ marginRight: "10px"}}
                                    onClick={this.handleCancel}>Close</Button>
                            {!this.props.apiId && !this.props.apiSecret &&
                            <Button color="success"
                                    type="submit"
                                    onClick={this.handleConfirm}
                                   disabled={!this.validateForm()}>Create</Button>}
                        </div>
                    </form>
                </div>
            </SweetAlert>
        )
    }
}

export default withStyles(sweetAlertStyle)(NewApiToken);