import {
    USER_AUTHENTICATION,
    USER_AUTHENTICATING,
    LOGOUT,
    STORE_ORG,
    WEB_PREVIEW_TOKEN,
    STORE_USER,
} from 'actions/ActionTypes';

const initialState = { 
    isAuthenticated: false,
    isAuthenticating: true,
    user: {},
    org: {},
    webPreviewToken: {}
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case USER_AUTHENTICATING:
            return {
                ...state,
                isAuthenticating: action.isAuthenticating
            };
        case USER_AUTHENTICATION:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                user: action.user
            };
        case LOGOUT:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated
            };
        case STORE_ORG:
            return {
                ...state,
                org: action.org
            };
        case WEB_PREVIEW_TOKEN:
            return {
                ...state,
                webPreviewToken: action.webPreviewToken
            };
        case STORE_USER:
            return {
                ...state,
                user: action.user,
            }
        default:
            return state;
    };
}

export default user;
