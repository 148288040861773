import React from "react"
import pt from "prop-types"
import Input from "./CustomInput"

class NumberInput extends React.Component {
  static propTypes = {
  	hideError: pt.bool,
  	hideSuccess: pt.bool,
  	numberType: pt.oneOf(["int", "float"]),
  	validate: pt.shape({
  		min: pt.number,
  		max: pt.number,
  	}),
  	inputProps: pt.shape({
  		value: pt.oneOfType([pt.string, pt.number]),
  		onChange: pt.func,
  	}),
  	onError: pt.func,
  	onSuccess: pt.func,
  }

  constructor(props) {
  	super(props)
  	this.state = {
  		value: props.inputProps && props.inputProps.value || "0",
  		error: false,
  		success: true,
  	}
  }
  componentDidUpdate() {
  	if (this.props.inputProps && this.props.inputProps.value !== this.state.value) {
  		this.setState({ value: this.props.inputProps.value })
  	}
  }

  hasOtherThanNumbers = (str) => /[^0-9.]/g.test(str)

  isValidNumber = (value) => {
  	if (this.hasOtherThanNumbers(value)) {
  		return false
  	}
  	const { numberType, validate } = this.props
  	const n = Number.parseFloat(value)
  	switch (numberType) {
  	case "int":
  		if (Number.isNaN(n) || n % 1 !== 0) {
  			return false
  		}
  		break
  	case "float":
  		if (Number.isNaN(n)) {
  			return false
  		}
  		break
  	default:
  		return false
  	}
  	if (validate) {
  		for (const key in validate) {
  			if (!validate.hasOwnProperty(key) || !validate[key]) {
  				continue
  			}
  			let isValid = true
  			switch (key) {
  			case "max":
  				if (n > validate[key]) {
  					isValid = false
  				}
  				break
  			case "min":
  				if (n < validate[key]) {
  					isValid = false
  				}
  				break
  			default:
  				continue
  			}
  			if (!isValid) {
  				return false
  			}
  		}
  	}
  	return true
  }

  onBlur = (e) => {
  	const { onError, onSuccess, inputProps: { onBlur } } = this.props
  	if (!this.isValidNumber(e.target.value)) {
  		this.setState({ error: true, success: false })
  		if (onError) {
  			onError()
  		}
  	} else {
  		this.setState({ error: false, success: true })
  		if (onSuccess) {
  			onSuccess()
  		}
  	}
  	if (onBlur) {
  		onBlur(e)
  	}
  }

  onChange = (e) => {
  	const { inputProps: { onChange } } = this.props
  	this.setState({ value: e.target.value })
  	if (onChange) {
  		onChange(e)
  	}
  }

  render() {
  	const { value, success, error } = this.state
  	const { inputProps, hideError, hideSuccess } = this.props
  	return (
  		<Input
  			{...this.props}
  			success={hideSuccess ? false : success}
  			error={hideError ? false : error}
  			inputProps={{
  				...inputProps,
  				type: "number",
  				onBlur: this.onBlur,
  				onChange: this.onChange,
  				value,
  			}}
  		/>
  	)
  }
}

export default NumberInput