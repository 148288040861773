// ##############################
// // // React Table component style
// #############################

export default {
  label: {
    fontSize: "x-small",
    color: "gray",
    marginTop: "-5px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  wideHorizontalPadding: {
    padding: "0 40px"
  },
  wideVerticalPadding: {
    padding: "40px 0"
  },
  wideVerticalMargin: {
    margin: "40px 0"
  },
  editableCell: {
    background: "rgba(200, 200, 200, 0.2)",
    borderRadius: "2px",
    padding: "4px 6px",
    margin: "16px 0",
  },
  headerDefault: {
    background: "#fafafa"
  },
  cellDefault: {
    textAlign: "center",
  },
  xScroll: {
    overflowX: "scroll"
  },
  tableBorderDefault: {
    "& .rt-table": {
      border: "solid 1px #fafafa"
    }
  }
};