import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Badge from "components/Badge/Badge";
import { surrenderOrg } from "actions/index";
import { searchParamsSelector } from "selectors";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import AppBar from "material-ui/AppBar";
import Toolbar from "material-ui/Toolbar";
import IconButton from "material-ui/IconButton";
import Hidden from "material-ui/Hidden";

// material-ui icons
import Menu from "material-ui-icons/Menu";
import MoreVert from "material-ui-icons/MoreVert";
import ViewList from "material-ui-icons/ViewList";
import Exit from "material-ui-icons/ExitToApp";
import Tooltip from "material-ui/Tooltip";

// core components
import HeaderLinks from "./HeaderLinks";
import CustomIconButton from "components/CustomButtons/IconButton.jsx";

import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

export class Header extends React.Component {
  componentDidMount() {
    this.removeAssumedOrgIfNeeded();
  }

  componentDidUpdate(prevProps) {
    this.removeAssumedOrgIfNeeded(prevProps);
  }

  removeAssumedOrgIfNeeded(prevProps) {
    // If orgId search query no longer exists but assumedOrg still exists in state, remove it
    if (prevProps && prevProps.searchParams.orgId !== null && this.props.searchParams.orgId === null && Boolean(this.props.assumedOrg)) {
      this.props.surrenderOrg();
    } else if (this.props.searchParams.orgId === null && Boolean(this.props.assumedOrg)) {
      this.props.surrenderOrg();
    }
  }

  discontinueViewingOrg = () => {
    this.props.history.push({
      pathname: "/admin/orgs"
    });
    this.props.surrenderOrg();
  }

  render() {
    const { classes, color, rtlActive, assumedOrg, searchParams } = this.props;
    const isViewingAnotherOrg = Boolean(assumedOrg) && searchParams.orgId !== null;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });
    const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
    	[classes.sidebarMinimizeRTL]: rtlActive
    });
    return (
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden smDown>
            <div className={sidebarMinimize}>
              {this.props.miniActive ? (
                <CustomIconButton color="white" onClick={this.props.sidebarMinimize}>
                  <ViewList className={classes.sidebarMiniIcon} />
                </CustomIconButton>
              ) : (
                <CustomIconButton color="white" onClick={this.props.sidebarMinimize}>
                  <MoreVert className={classes.sidebarMiniIcon} />
                </CustomIconButton>
              )}
            </div>
          </Hidden>
          <div style={{ marginLeft: 15 }} className={classes.flex}>
            {isViewingAnotherOrg &&
            <React.Fragment>
              <Tooltip id="surrender-org-button" title="Return to HealthLytix" placement="bottom">
                <CustomIconButton color="warning" onClick={this.discontinueViewingOrg}>
                  <Exit className={classes.sidebarMiniIcon} />
                </CustomIconButton>
              </Tooltip>
              <Badge color="warning" style={{ display: "inline-block", marginLeft: 15 }}>Currently viewing another organization</Badge>
            </React.Fragment>
            }
          </div>
          <Hidden smDown implementation="css">
            <HeaderLinks rtlActive={rtlActive} />
          </Hidden>
          <Hidden mdUp>
            <IconButton
              className={classes.appResponsive}
              color="inherit"
              aria-label="open drawer"
              onClick={this.props.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
  miniActive: PropTypes.bool.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    views: PropTypes.array,
  })).isRequired,
  rtlActive: PropTypes.bool,
  sidebarMinimize: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  surrenderOrg: PropTypes.func.isRequired,
  assumedOrg: PropTypes.shape({}),
  searchParams: PropTypes.shape({
    orgId: PropTypes.string,
    id: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  searchParams: searchParamsSelector(state),
  assumedOrg: state.assumedOrg,
});

const mapDispatchToProps = (dispatch) => ({
  surrenderOrg: () => dispatch(surrenderOrg())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(headerStyle)(withRouter(Header)));
