import React from "react"
import PropTypes from "prop-types"
import PlainCard from "components/Cards/PlainCard.jsx"
import withStyles from "material-ui/styles/withStyles"
import Reports from "models/Reports"

const style = {
	engagingCases: {
		textAlign: "center",
		color: "#FFFFFF",
		background: "linear-gradient(to right, #0fb8ad, #1fc8db)",
	}
}

export const MostEngagingApp = ({ classes, report }) => {
	let total = 0
	let appName = "N/A"
	if (report) {
		const app = report.topAppBy("usage")
		total = app.successful + app.failures
		appName = app.appName
	}

	return (
		<PlainCard
			customClasses={classes.engagingCases}
		>
			<h1 style={{ fontSize: "3em" }}>{appName}</h1>
			<h3 style={{ margin: 0 }}>Most Engaging App</h3>
			<h4 style={{ marginTop: 0 }}>{`${total} Cases`}</h4>
		</PlainCard>
	)
}

MostEngagingApp.propTypes = {
	classes: PropTypes.shape({}),
	report: PropTypes.instanceOf(Reports)
}

export default withStyles(style)(MostEngagingApp)