import React, { Component } from "react";
import pt from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Table from "react-table";
import numeral from "numeral";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard.jsx";
import Button from "components/CustomButtons/Button";
import DatePickerButton from "components/DatePickerButtons/DatePickerButton";
import Dropdown from "components/CustomDropdown/CustomDropdown";
import Input from "components/CustomInput/CustomInput";
import NumberInput from "components/CustomInput/NumberInput";
import Modal from "components/Modal";
import { WithNotifications } from "components/HigherOrder";
import AppStats from "./AppStats";
import ModalityStats from "./ModalityStats";
import DeviceStats from "./DeviceStats";

import withStyles from "material-ui/styles/withStyles";
import tableStyles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";
import { CircularProgress } from "material-ui/Progress";
import AddIcon from "material-ui-icons/Add";
import RemoveIcon from "material-ui-icons/Remove";

import { getInvoices, editInvoice, getAllowedApps, billInvoice, payInvoice } from "libs/apiLib";

const styles = {
  ...tableStyles,
  sectionMarginTop: {
    marginTop: "40px"
  },
  marginBottom: {
    marginBottom: "30px"
  },
  marginTop: {
    marginTop: "30px"
  },
  bold: {
    fontWeight: "bold"
  },
  noBottomMargin: {
    marginBottom: 0
  },
};

export class Edit extends Component {
  static propTypes = {
    classes: pt.shape({
      wideHorizontalPadding: pt.string.isRequired,
      wideVerticalPadding: pt.string.isRequired,
      noBottomMargin: pt.string.isRequired,
      bold: pt.string.isRequired,
      center: pt.string.isRequired,
      label: pt.string.isRequired,
      right: pt.string.isRequired,
      sectionMarginTop: pt.string.isRequired,
      marginBottom: pt.string.isRequired,
      marginTop: pt.string.isRequired,
    }).isRequired,
    getInvoices: pt.func.isRequired,
    editInvoice: pt.func.isRequired,
    payInvoice: pt.func.isRequired,
    billInvoice: pt.func.isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    invoice: pt.shape({
      paymentTerms: pt.string.isRequired,
      orgId: pt.string,
      orgName: pt.string,
      userId: pt.string,
      date: pt.string.isRequired,
      billingStart: pt.string,
      billingEnd: pt.string,
      poNumber: pt.string.isRequired,
      items: pt.arrayOf(pt.shape({
        item: pt.string.isRequired,
        quantity: pt.number.isRequired,
        price: pt.number.isRequired,
        discounts: pt.number.isRequired,
        credits: pt.number.isRequired,
        taxRate: pt.number.isRequired,
        totalAmount: pt.number.isRequired,
      })).isRequired,
      invoiceNumber: pt.string.isRequired,
      dueDate: pt.string.isRequired,
      invoiceStatus: pt.string.isRequired,
      payedDate: pt.string,
      totalAmount: pt.number.isRequired,
      totalTax: pt.number.isRequired,
      subTotal: pt.number.isRequired,
      paymentConfirmation: pt.string,
    }),
    history: pt.shape({
      push: pt.func.isRequired,
      goBack: pt.func.isRequired,
    }).isRequired,
    location: pt.shape({
      search: pt.string.isRequired,
      state: pt.shape({
        invoice: pt.shape({})
      }),
    }).isRequired,
  }

  constructor(props) {
    super(props);
    const { state, search } = props.location;
    const params = new URLSearchParams(search);
    let invoice = null;
    if (state && state.invoice) {
      invoice = {
        ...state.invoice,
        items: this.lineItemTaxToPercent(state.invoice.items)
      };
    }
    this.state = {
      modal: null,
      orgId: params.get("orgId") || null,
      id: params.get("id") || null,
      submitting: false,
      apps: null,
      errors: {},
      invoice,
    };
    if (!this.state.orgId || !this.state.id) {
      props.signalError("Error. Please make sure \"orgId\" and \"id\" exists within the URL");
    }
  }

  componentDidMount() {
    if (!this.state.invoice) {
      this.getInvoice();
    }
    this.getAllowedApps();
  }

  anyErrors = () => Object.keys(this.state.errors).some(err => this.state.errors[err])

  getAllowedApps = async () => {
    const { orgId } = this.state;
    const { getAllowedApps, signalError } = this.props;
    this.setState({ submitting: true });
    try {
      const results = await getAllowedApps(orgId);
      if (results.success && results.apps) {
        this.setState({ apps: results.apps });
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error getting line item apps. Please try again later.");
    }
    this.setState({ submitting: false });
  }

  getInvoice = async () => {
    const { orgId, id } = this.state;
    const { getInvoices, signalError } = this.props;
    this.setState({ submitting: true });
    try {
      const results = await getInvoices(orgId);
      if (results.success && results.result.Items.length) {
        const invoice = results.result.Items.find(inv => inv.invoiceNumber === id);
        if (!invoice) {
          throw new Error();
        } else {
          this.setState({ invoice: { ...invoice, items: this.lineItemTaxToPercent(invoice.items) } });
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError(`Error getting invoice with ID "${id}". Please make sure this is a valid invoice number and select an invoice from the Invoice page`);
    }
    this.setState({ submitting: false });
  }

  lineItemTaxToPercent = (items) => items.map(item => ({ ...item, taxRate: this.taxToPercent(item.taxRate) }))
  percentToTax = (percent) => percent / 100
  taxToPercent = (tax) => tax * 100

  transformLineItems = () => {
    const { invoice: { items } } = this.state;
    const results = [];
    for (const item of items) {
      if (!item.item || !item.item.length) {
        throw new Error("Please confirm all line items have an app selected");
      }
      const result = { ...item };
      const appName = item.item;
      const createErrorMsg = (key, expected) => `${key} under line item "${appName}" should be ${expected}`;
      for (const key in item) {
        let value = item[key];
        if (!item.hasOwnProperty(key)) {
          continue;
        }
        switch (key) {
        case "quantity":
          value = Number.parseFloat(value);
          if (Number.isNaN(value) || value <= 0) {
            throw new Error(createErrorMsg(this.titilize(key), "greater than zero"));
          }
          result[key] = value;
          break;
        case "credits":
        case "discounts":
        case "price":
          value = Number.parseFloat(value);
          if (Number.isNaN(value) || value < 0) {
            throw new Error(createErrorMsg(this.titilize(key), "greater than or equal to zero"));
          }
          result[key] = value;
          break;
        case "taxRate":
          value = Number.parseFloat(value);
          if (Number.isNaN(value) || value < 0 || value > 100) {
            throw new Error(createErrorMsg("Tax Rate", "greater than or equal to zero and less than or equal to one hundred"));
          }
          result[key] = value;
          break;
        default:
          break;
        }
      }
      // recalculate tax rate & total amount here
      result.totalAmount = this.total(result);
      result.taxRate = this.percentToTax(result.taxRate);
      results.push(result);
    }
    return results;
  }

  sumLineItems = (items) => {
    return items.reduce((totals, item) => {
      const total = this.total(item);
      const tax = this.tax(item);
      const subTotal = this.subTotal(item);
      return {
        totalAmount: totals.totalAmount + (Number.isNaN(total) ? 0 : total),
        totalTax: totals.totalTax + (Number.isNaN(tax) ? 0 : tax),
        subTotal: totals.subTotal + (Number.isNaN(subTotal) ? 0 : subTotal),
      };
    }, { totalAmount: 0, totalTax: 0, subTotal: 0 });
  }

  editInvoice = () => {
    const { invoice } = this.state;
    const { editInvoice } = this.props;
    const items = this.transformLineItems();
    const updatedInvoice = {
      ...invoice,
      ...this.sumLineItems(this.lineItemTaxToPercent(items)),
      items,
    };
    return editInvoice(updatedInvoice);
  }

  billInvoice = (invoiceNumber) => this.props.billInvoice(invoiceNumber);

  payInvoice = (invoiceNumber, paymentConfirmation) => this.props.payInvoice(invoiceNumber, paymentConfirmation);

  saveInvoice = async () => {
    this.setState({ submitting: true });
    const { signalError, clearError, history, location } = this.props;
    const defaulErrMsg = "Error saving invoice. Please try again later.";
    try {
      const results = await this.editInvoice();
      if (results.success && results.invoice) {
        history.push({
          pathname: "/admin/invoices",
          search: location.search,
          state: {
            invoice: results.invoice,
          }
        });
      } else {
        throw new Error(defaulErrMsg);
      }
    } catch (e) {
      const msg = e && e.message || defaulErrMsg;
      signalError(msg);
      setTimeout(clearError, 5000);
    }
    this.setState({ submitting: false, modal: null });
  }

  saveAndBillInvoice = async () => {
    this.setState({ submitting: true });
    const { signalError, clearError, history, location } = this.props;
    const defaulErrMsg = "Error marking invoice as billed. Please try again later.";
    try {
      let results = await this.editInvoice();
      if (!results.success || !results.invoice) {
        throw new Error(defaulErrMsg);
      }
      results = await this.billInvoice(results.invoice.invoiceNumber);
      if (!results.success || !results.invoice) {
        throw new Error(defaulErrMsg);
      }
      history.push({
        pathname: "/admin/invoices",
        search: location.search,
        state: {
          invoice: results.invoice
        }
      });
    } catch (e) {
      const msg = e && e.message || defaulErrMsg;
      signalError(msg);
      setTimeout(clearError, 5000);
    }
    this.setState({ submitting: false, modal: null });
  }

  saveAndPayInvoice = async () => {
    this.setState({ submitting: true });
    const { signalError, clearError, history, location } = this.props;
    const defaulErrMsg = "Error marking invoice as paid. Please try again later.";
    try {
      let results = await this.editInvoice();
      if (!results.success || !results.invoice) {
        throw new Error(defaulErrMsg);
      }
      results = await this.payInvoice(results.invoice.invoiceNumber, results.invoice.paymentConfirmation && results.invoice.paymentConfirmation.length ? results.invoice.paymentConfirmation : null);
      if (!results.success || !results.invoice) {
        throw new Error(defaulErrMsg);
      }
      history.push({
        pathname: "/admin/invoices",
        search: location.search,
        state: {
          invoice: results.invoice
        }
      });
    } catch (e) {
      const msg = e && e.message || defaulErrMsg;
      signalError(msg);
      setTimeout(clearError, 5000);
    }
    this.setState({ submitting: false, modal: null });
  }

  handleSave = (status) => {
    let message = null;
    let input = null;
    let onClick = null;
    const { invoice, submitting } = this.state;
    let { invoiceStatus, paymentConfirmation } = invoice;
    switch (status) {
    case "Bill Invoice":
      message = "Marking this invoice as billed will save the current invoice details, display this invoice as billed in the organization's list of invoices, and send an email to the organization's account owner.";
      onClick = this.saveAndBillInvoice;
      break;
    case "Mark as Paid":
      message = "Marking this invoice as paid will save the current invoice details and display this invoice as paid in the organization's list of invoices. Additionally, you can provide any payment confirmation details below.";
      input = (
        <ItemGrid xs={12}>
          <Input
            labelText="Payment Confirmation"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "paymentConfirmation",
              value: paymentConfirmation,
              onChange: (e) => this.handleInputChange("paymentConfirmation", e.target.value)
            }}
          />
        </ItemGrid>
      );
      onClick = this.saveAndPayInvoice;
      break;
    case "Mark as Inactive":
      message = "Marking this invoice as inactive will update the status of this invoice to inactive.";
      invoiceStatus = "INACTIVE";
      onClick = this.saveInvoice;
      break;
    case "Mark as Active":
      message = "Marking this invoice as active will update the status of this invoice to active.";
      invoiceStatus = "ACTIVE";
      onClick = this.saveInvoice;
      break;
    case "Save":
      message = "Saving this invoice will update all invoice details including each line item.";
      onClick = this.saveInvoice;
      break;
    default:
      return;
    }
    const { classes } = this.props;
    message += " Click Confirm to continue or Cancel to go back.";
    const modal = (
      <Modal
        style={{
          height: 600,
          width: 500,
        }}
      >
        <GridContainer className={classes.wideHorizontalPadding}>
          <ItemGrid xs={12}>
            <h2 className={classes.center}>{status}</h2>
          </ItemGrid>
          <ItemGrid xs={12}>
            <h4>{message}</h4>
          </ItemGrid>
          {input}
          <ItemGrid container justify="center" xs={12} style={{ position: "absolute", bottom: 15, left: 0 }}>
            <Button disabled={submitting} style={{ marginRight: 15 }} onClick={() => this.setState({ modal: null })}>Cancel</Button>
            <Button disabled={submitting} onClick={onClick} color="success">Confirm</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
    this.setState(state => ({
      ...state,
      modal,
      invoice: {
        ...state.invoice,
        invoiceStatus,
      }
    })
    );
  }

  titilize = (str) => str[0].toUpperCase() + str.substring(1).toLowerCase()

  handleInputChange = (key, value) => {
    this.setState(state => ({
      ...state,
      invoice: {
        ...state.invoice,
        [key]: value
      }
    }));
  }

  handleDateChange = (key, date) => this.handleInputChange(key, moment(date).toISOString())

  statusColor = (status) => {
    switch (status) {
    case "CREATED":
      return "primary";
    case "BILLED":
      return "warning";
    case "PAYED":
      return "success";
    case "INACTIVE":
      return undefined;
    default:
      return "primary";
    }
  }

  handleLineItemChange = (itemName, key, value) => {
    this.setState(state => ({
      ...state,
      invoice: {
        ...state.invoice,
        items: state.invoice.items.map(item => {
          if (item.item === itemName) {
            return {
              ...item,
              [key]: value
            };
          }
          return item;
        })
      }
    }));
  }

  handleAppLineItemChange = (itemToReplace, value) => {
    this.setState(state => ({
      ...state,
      invoice: {
        ...state.invoice,
        items: state.invoice.items.map(item => {
          if (item.item === itemToReplace) {
            return {
              ...item,
              item: value
            };
          }
          return item;
        })
      }
    }));
  }

  filterUnselectedApps = (exclude) => {
    const { apps, invoice: { items } } = this.state;
    if (!apps) {
      return [];
    }
    const selected = {};
    if (exclude) {
      selected[exclude.toLowerCase()] = true;
    }
    for (const item of items) {
      if (item.item) {
        selected[item.item.toLowerCase()] = true;
      }
    }
    return apps.reduce((result, app) => {
      if (!selected[app.name.toLowerCase()]) {
        return [...result, app.name];
      }
      return result;
    }, []);
  }

  subTotal = (item) => (Number.parseInt(item.price) * Number.parseInt(item.quantity)) - Number.parseInt(item.credits) - Number.parseInt(item.discounts)
  tax = (item) => !Number.isNaN((Number.parseFloat(item.taxRate) / 100)) ? (Number.parseFloat(item.taxRate) / 100) * this.subTotal(item) : 1
  total = (item) => this.tax(item) + this.subTotal(item)

  formatLineItemCell = (key) => (cell) => {
    const { label, right } = this.props.classes;
    let value = cell.value;
    let labelText;
    let className;
    let numberType = "float";
    const minimum = (key) => {
      switch (key) {
      case "quantity":
        return 1;
      default:
        return 0;
      }
    };
    switch (key) {
    case "remove":
      return <Button right color="danger" size="xs" onClick={() => this.removeLineItem(cell.original.item)}><RemoveIcon /></Button>;
    case "item":
      labelText = "Item";
      return (
        <div>
          <Dropdown
            style={{ marginLeft: 0, paddingTop: 20 }}
            buttonColor="primary"
            buttonText={value}
            buttonProps={{
              style: { minHeight: 21, width: 175, maxWidth: 175 }
            }}
            dropdownList={this.filterUnselectedApps(value)}
            onAction={(v) => this.handleAppLineItemChange(value, v)}
          />
          <div className={label}>{labelText}</div>
        </div>
      );
    case "quantity":
      labelText = "Quantity";
      numberType = "int";
      break;
    case "price":
      labelText = "Price";
      break;
    case "discounts":
      labelText = "Discounts";
      break;
    case "credits":
      labelText = "Credits";
      break;
    case "taxRate":
      labelText = "Tax Rate";
      break;
    case "totalAmount":
    {
      labelText = "Total";
      value = this.total(cell.original);
      if (Number.isNaN(value)) {
        value = 0;
      }
      className = right;
      return (
        <div className={className}>
          <div style={{ fontSize: 18 }}>{value}</div>
          <div className={label}>{labelText}</div>
        </div>
      );
    }
    default:
      return null;
    }
    return (
      <div className={className}>
        <NumberInput
          hideSuccess
          enlargeFontSize
          numberType={numberType}
          validate={{
            max: key === "taxRate" ? 100 : undefined,
            min: minimum(key),
          }}
          onError={() => this.setState(prevState => ({ ...prevState, errors: { ...prevState.errors, [cell.original.item + key]: true } }))}
          onSuccess={() => this.setState(prevState => ({ ...prevState, errors: { ...prevState.errors, [cell.original.item + key]: false } }))}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: e => this.handleLineItemChange(cell.original.item, key, e.target.value),
            value,
          }}
        />
        <div className={label}>{labelText}</div>
      </div>
    );
  }

  addLineItem = () => {
    this.setState(prevState => ({
      ...prevState,
      invoice: {
        ...prevState.invoice,
        items: [
          ...prevState.invoice.items,
          this.blankLineItem(),
        ]
      }
    }));
  }

  removeLineItem = (item) => {
    this.setState(prevState => {
      const i = prevState.invoice.items.findIndex(lineItem => lineItem.item === item);
      if (i === -1) {
        return prevState;
      }
      return {
        ...prevState,
        invoice: {
          ...prevState.invoice,
          items: [
            ...prevState.invoice.items.slice(0, i),
            ...prevState.invoice.items.slice(i + 1),
          ]
        }
      };
    });
  }

  blankLineItem = () => ({
    item: undefined,
    quantity: 1,
    price: 0,
    discounts: 0,
    credits: 0,
    taxRate: 0,
    totalAmount: 0,
  })

  handleOnApply = (report, byMetric = "total") => {
    const apps = report.appStats();
    if (!apps) {
      return;
    }
    this.setState(state => ({
      ...state,
      invoice: {
        ...state.invoice,
        items: Object.keys(apps || []).reduce((items, name) => {
          return [
            ...items,
            {
              ...this.blankLineItem(),
              item: name,
              quantity: byMetric === "total" ? apps[name].successful + apps[name].failures : apps[name][byMetric],
            }
          ];
        }, [])
      }
    }));
  }

  statusDropdownValues = () => {
    const { invoice } = this.state;
    return [
      "Save",
      "Bill Invoice",
      "Mark as Paid",
      invoice.invoiceStatus === "INACTIVE" ? "Mark as Active" : "Mark as Inactive",
    ];
  }

  statusText = (invoice) => {
    const titlized = invoice.invoiceStatus[0] + invoice.invoiceStatus.substring(1).toLowerCase();
    switch (invoice.invoiceStatus) {
    case "PAYED":
      if (!invoice.payedDate || !moment(invoice.payedDate).isValid()) {
        return "Paid";
      }
      return `Paid ${moment(invoice.payedDate).format("dddd MMMM Do YYYY")}`;
    case "BILLED":
      return `Due ${moment(invoice.dueDate).format("dddd MMMM Do YYYY")}`;
    default:
      return titlized;
    }
  }

  render() {
    const { invoice, submitting, orgId, modal } = this.state;
    const { classes: { wideVerticalPadding, wideHorizontalPadding, marginBottom, marginTop, bold, noBottomMargin, right } } = this.props;
    let content = <ItemGrid xs={12}><CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} /></ItemGrid>;
    let subtitle;
    let start;
    let end;
    if (invoice) {
      const {
        invoiceNumber, dueDate,
        billingStart, billingEnd, poNumber, paymentTerms,
      } = invoice;
      subtitle = invoiceNumber;
      start = moment(billingStart);
      end = moment(billingEnd);
      const { totalAmount, totalTax, subTotal } = this.sumLineItems(invoice.items);
      content = (
        <React.Fragment>
          <ItemGrid xs={12}>
            <h3>Status</h3>
            <h5>{this.statusText(invoice)}</h5>
          </ItemGrid>
          <ItemGrid xs={12} className={marginTop}>
            <h3>Due Date</h3>
            <DatePickerButton
              inline
              closeOnSelect
              disableFutureDays={false}
              justify="flex-start"
              color="primary"
              start={moment(dueDate)}
              defaultValue={moment(dueDate)}
              viewDate={moment(dueDate)}
              onChange={date => this.handleDateChange("dueDate", date)}
            />
          </ItemGrid>
          <ItemGrid xs={12} className={marginTop}>
            <h3>Billing Cycle</h3>
            <DatePickerButton
              inline
              closeOnSelect
              label="Start"
              disableFutureDays={false}
              justify="flex-start"
              color="primary"
              start={moment(billingStart)}
              defaultValue={moment(billingStart)}
              viewDate={moment(billingStart)}
              onChange={date => this.handleDateChange("billingStart", date)}
              preventSelectionPast={moment(billingEnd)}
              buttonProps={{
                style: {
                  marginRight: 15
                }
              }}
            />
            <DatePickerButton
              inline
              closeOnSelect
              label="End"
              disableFutureDays={false}
              justify="flex-start"
              color="primary"
              start={moment(billingEnd)}
              defaultValue={moment(billingEnd)}
              viewDate={moment(billingEnd)}
              onChange={date => this.handleDateChange("billingEnd", date)}
            />
          </ItemGrid>
          <ItemGrid xs={12} md={6} className={marginTop}>
            <GridContainer direction="column">
              <ItemGrid xs={12}>
                <h3>Options</h3>
              </ItemGrid>
              <ItemGrid xs={6} className={marginBottom}>
                <p>Payment Term</p>
                <Dropdown
                  buttonColor="primary"
                  buttonText={paymentTerms}
                  dropdownList={[
                    "Net 30 Days",
                    "Net 60 Days",
                    "Net 90 Days",
                  ]}
                  onAction={(v) => this.handleInputChange("paymentTerms", v)}
                />
              </ItemGrid>
              <ItemGrid xs={12}>
                <p>Customer PO Number</p>
                <Input
                  labelText="Enter Number"
                  inputProps={{
                    name: "poNumber",
                    value: poNumber,
                    onChange: (e) => this.handleInputChange("poNumber", e.target.value)
                  }}
                />
              </ItemGrid>
            </GridContainer>
          </ItemGrid>
          <ItemGrid xs={12}>
            <Button right size="sm" color="success" onClick={this.addLineItem}><AddIcon /></Button>
          </ItemGrid>
          <ItemGrid xs={12}>
            <Table
              noDataText="No Line Items"
              data={invoice.items || []}
              columns={[
                {
                  accessor: "item",
                  minWidth: 150,
                },
                {
                  accessor: "price"
                },
                {
                  accessor: "quantity"
                },
                {
                  accessor: "discounts"
                },
                {
                  accessor: "credits"
                },
                {
                  accessor: "taxRate"
                },
                {
                  accessor: "totalAmount"
                },
                {
                  accessor: "remove",
                  maxWidth: 75,
                },
              ].map(c => ({ Cell: this.formatLineItemCell(c.accessor), Header: "", accessor: c.accessor, maxWidth: c.maxWidth, minWidth: c.minWidth || 100 }))
              }
              sortable={false}
              resizable={false}
              className={wideVerticalPadding}
              pageSize={10}
              minRows={1}
              showPagination={false}
            />
          </ItemGrid>
          <ItemGrid xs={12} className={right}>
            <h4 className={cx(bold, noBottomMargin)}>{numeral(subTotal).format("$ 0,0.00")}</h4>
            <p>Subtotal</p>
            <h4 className={cx(bold, noBottomMargin)}>{numeral(totalTax).format("$ 0,0.00")}</h4>
            <p>Tax</p>
            <h2 className={cx(bold, noBottomMargin)}>{numeral(totalAmount).format("$ 0,0.00")}</h2>
            <p>Total</p>
          </ItemGrid>
          <ItemGrid xs={12}>
            <Dropdown
              style={{
                float: "right"
              }}
              buttonColor="success"
              buttonText="Save"
              dropdownList={this.statusDropdownValues()}
              onAction={this.handleSave}
            />
            <Button right onClick={this.props.history.goBack} disabled={submitting} style={{ marginRight: 15 }}>Cancel</Button>
          </ItemGrid>
        </React.Fragment>
      );
    }
    return (
      <GridContainer direction="row-reverse" >
        {modal}
        <ItemGrid xs={12} md={4}>
          <AppStats start={start} end={end} orgId={orgId} onApply={this.handleOnApply} />
          <ModalityStats start={start} end={end} orgId={orgId} />
          <DeviceStats start={start} end={end} orgId={orgId} />
        </ItemGrid>
        <ItemGrid xs={12} md={8}>
          <HeaderCard
            headerColor="green"
            cardTitle="Invoice Details"
            cardSubtitle={subtitle}
            content={
              <GridContainer className={wideHorizontalPadding}>
                {content}
              </GridContainer>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

export default connect(undefined, () => ({
  getAllowedApps: (orgId) => getAllowedApps(orgId),
  getInvoices: (id) => getInvoices(id),
  editInvoice: (args) => editInvoice(args),
  billInvoice: (id) => billInvoice(id),
  payInvoice: (id, details) => payInvoice(id, details),
}))(withRouter(WithNotifications(withStyles(styles)(Edit))));