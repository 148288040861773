import React from "react";
import PropTypes from "prop-types";
import PlainCard from "components/Cards/PlainCard.jsx";
import withStyles from "material-ui/styles/withStyles";
import Reports from "models/Reports";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

const style = {
  bg: {
    textAlign: "center",
    color: "#FFFFFF",
    background: "linear-gradient(to right, #0fb8ad, #1fc8db)",
  }
};

export const MostEngagingDevice = ({ classes, report }) => {
  let total = 0;
  let deviceName = "N/A";
  let subTitle = null;
  if (report) {
    const topDevice = report.topDeviceBy("usage");
    if (topDevice) {
      const { successful, failures, name } = topDevice;
      total = successful + failures;
      deviceName = name;
      subTitle = `Across ${total} cases`;
    }
  }

  return (
    <PlainCard
      customClasses={classes.bg}
    >
      <GridContainer direction="column" justify="center">
        <ItemGrid xs={12}>
          <h1 style={{ fontSize: "3em" }}>{deviceName}</h1>
          <h3 style={{ margin: 0 }}>Most Engaging Device</h3>
          {subTitle && <h4 style={{ marginTop: 0 }}>{subTitle}</h4>}
        </ItemGrid>
      </GridContainer>
    </PlainCard>
  );
};

MostEngagingDevice.propTypes = {
  classes: PropTypes.shape({
    bg: PropTypes.string.isRequired
  }).isRequired,
  report: PropTypes.instanceOf(Reports)
};

export default withStyles(style)(MostEngagingDevice);