import React from "react";
import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
import { createHashHistory } from "history";
import { Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { ConnectedRouter, routerMiddleware } from "react-router-redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import indexRoutes from "routes/index.jsx";
import reducers from "./reducers";

import "assets/scss/material-dashboard-pro-react.css";

const hist = createHashHistory();
const middleware = routerMiddleware(hist);

const store = createStore(
  reducers,
  undefined,
  composeWithDevTools(applyMiddleware(middleware))
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={hist}>
      <Switch>
        {indexRoutes.map((prop, key) => {
          return <Route path={prop.path} component={prop.component} key={key} />;
        })}
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
