import React from "react";
import pt from "prop-types";
import { connect } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import PlainStatsCard from "components/Cards/PlainStatsCard";

// api functions
import { storeTotalOrgs, storeTotalOrgsWithDevices, storeNotificationError, removeNotificationError } from "actions/index";
import { getAllOrgs, getAllEdgeDevices } from "libs/apiLib";

export class OrgsMetadata extends React.Component {
  static propTypes = {
    totalOrgs: pt.number.isRequired,
    totalOrgsWithDevices: pt.number.isRequired,
    storeTotalOrgs: pt.func.isRequired,
    storeTotalOrgsWithDevices: pt.func.isRequired,
    getAllOrgs: pt.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
  componentDidMount() {
    if (this.props.totalOrgs === 0) {
      this.getTotalOrgs();
    }
    if (this.props.totalOrgsWithDevices === 0) {
      this.getTotalOrgsWithDevices();
    }
  }

  getTotalOrgs = async () => {
    const { getAllOrgs, storeTotalOrgs, signalError, clearError } = this.props;
    this.setState({ isLoading: true });
    try {
      const result = await getAllOrgs();
      if (result.success && result.orgs && result.orgs.Items && result.orgs.Items.length) {
        storeTotalOrgs(result.orgs.Items.length);
      }
    } catch (e) {
      signalError("Error getting orgs");
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  getTotalOrgsWithDevices = async () => {
    const { getAllEdgeDevices, storeTotalOrgsWithDevices, signalError, clearError } = this.props;
    this.setState({ isLoading: true });
    try {
      const result = await getAllEdgeDevices();
      if (result.success && result.devices && result.devices && result.devices.length) {
        const cache = result.devices.reduce((cache, device) => {
          if (cache[device.orgId]) {
            return {
              ...cache,
              [device.orgId]: cache[device.orgId] + 1
            };
          }
          return {
            ...cache,
            [device.orgId]: 1
          };
        }, {});
        storeTotalOrgsWithDevices(Object.keys(cache).length);
      }
    } catch (e) {
      signalError("Error getting orgs with devices");
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <React.Fragment>
        <GridContainer>
          <ItemGrid container xs={12} sm={6}>
            <PlainStatsCard>
              <h1 style={{ fontSize: "3em" }}>{this.props.totalOrgs}</h1>
              <h3 style={{ margin: 0 }}>Total Organizations</h3>
            </PlainStatsCard>
          </ItemGrid>
          <ItemGrid container xs={12} sm={6}>
            <PlainStatsCard>
              <h1 style={{ fontSize: "3em" }}>{this.props.totalOrgsWithDevices}</h1>
              <h3 style={{ margin: 0 }}>Total Organizations</h3>
              <h4 style={{ margin: 0 }}>With Devices</h4>
            </PlainStatsCard>
          </ItemGrid>
        </GridContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  totalOrgs: state.reports.totalOrgs,
  totalOrgsWithDevices: state.reports.totalOrgsWithDevices,
});

const mapDispatchToProps = (dispatch) => ({
  // uses dispatch
  storeTotalOrgs: (n) => dispatch(storeTotalOrgs(n)),
  storeTotalOrgsWithDevices: (n) => dispatch(storeTotalOrgsWithDevices(n)),
  signalError: (msg) => dispatch(storeNotificationError(msg)),
  clearError: () => dispatch(removeNotificationError()),

  // does not use dispatch
  getAllOrgs,
  getAllEdgeDevices
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgsMetadata);
