import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Badge from "components/Badge/Badge";
import LooksOne from "material-ui-icons/LooksOne";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

// React table & pagination
import ReactTable from "react-table";
import Pagination from "components/Pagination/ReactTablePagination";

// models
import Reports from "models/Reports";

// api functions
import { storeOrgsReport, storeNotificationError, removeNotificationError } from "actions/index";
import { allOrgStatsQuery } from "libs/apiLib";

export class DailyOrganizationInsights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      isLoading: false,
      fetchingReportsForKeys: {}
    };
  }

  componentDidMount() {
    this.fetchDailyReport();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldFetchReport(prevProps, prevState)) {
      this.fetchDailyReport();
    }
  }

  shouldFetchReport = (prevProps, prevState) => {
    // only fetch a report if:
    // - not already fetching a report under this key
    // - key doesn't exist in the report (this is the first request for this key)
    // - no reports exists but date has changed from previous render 
    const { reports, start, end } = this.props;
    const key = this.key(start, end);
    const isStillFetchingReport = prevState.fetchingReportsForKeys[key];
    const isCurrentlyFetchingReport = this.state.fetchingReportsForKeys[key];
    return (
      !isStillFetchingReport &&
      !isCurrentlyFetchingReport &&
      (
        (reports && !reports[key]) ||
        (!reports && (!prevProps.start.isSame(start) || !prevProps.end.isSame(end)))
      )
    );
  }

  fetchDailyReport = async () => {
    const { start, end, storeOrgsReport, fetchDailyReport, signalError, clearError } = this.props;
    const key = this.key(start, end);
    this.setState({ isLoading: true, fetchingReportsForKeys: { ...this.state.fetchingReportsForKeys, [key]: true } });
    try {
      const report = await fetchDailyReport(start, end);
      if (report.success && report.stats && report.stats.Items) {
        storeOrgsReport(report.stats.Items, key);
      }
    } catch (e) {
      signalError("Error getting insights");
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false, fetchingReportsForKeys: { ...this.state.fetchingReportsForKeys, [key]: false } });
  }

  formatCell = (key) => (cell) => {
    const defaultStyle = {
      fontSize: "x-small",
      color: "gray",
      marginTop: "-5px"
    };
    switch (key) {
    case "orgName":
    {
      const { orgID } = cell.original;
      const { location: { search }, start, end } = this.props;
      const s = new URLSearchParams(search);
      s.set("orgId", orgID);
      s.set("start", start);
      s.set("end", end);
      return (
        <div>
          <div><Link to={{
            pathname: "/myorg/insights",
            search: s.toString()
          }}>{cell.value}</Link></div>
          <div style={defaultStyle}>Organization</div>
        </div>
      );
    }
    case "successful":
      return (
        <div>
          <div><Badge color="success">{cell.value}</Badge></div>
          <div style={{ ...defaultStyle, marginTop: "0" }}>Successful</div>
        </div>
      );
    case "failures":
      return (
        <div>
          <div><Badge color="danger">{cell.value}</Badge></div>
          <div style={{ ...defaultStyle, marginTop: "0" }}>Failures</div>
        </div>
      );
    case "totalUniqueCases":
      return (
        <div>
          <div><Badge color="primary">{cell.value && cell.value !== 0 ? cell.value : "N/A"}</Badge></div>
          <div style={{ ...defaultStyle, marginTop: "0" }}>Unique Imaging Cases</div>
        </div>
      );
    case "successRate":
      return (
        <div style={{ padding: "0 15px" }}>
          <CustomLinearProgress
            variant="determinate"
            color="success"
            value={cell.value}
          />
          <div style={{ ...defaultStyle, marginTop: "-15px" }}>{`${cell.value}% Success Rate`}</div>
        </div>
      );
    case "mostEngagingApp":
      return (
        <div>
          <div>{cell.value}</div>
          <div style={defaultStyle}>Most Engaging App</div>
        </div>
      );
    case "totalScanners":
      return (
        <div>
          <div>{cell.value}</div>
          <div style={defaultStyle}>Total Scanners</div>
        </div>
      );
    case "mostFrequentFailureCodes":
      return (
        <div>
          <div>{cell.value && Array.isArray(cell.value) && cell.value.length ? cell.value.map(c => `${c.exitCode} (${c.total})`).join(", ") : "N/A"}</div>
          <div style={defaultStyle}>Most Frequent Failure Codes</div>
        </div>
      );
    default:
      return null;
    }
  }

  key = (start, end) => {
    if (!start || !end) {
      return undefined;
    }
    return `${start.format("YYYY-MM-DD")}:${end.format("YYYY-MM-DD")}`;
  }

  dailyReport = () => {
    const { reports, start, end } = this.props;
    const key = this.key(start, end);
    const report = reports && reports[key] && reports[key].length && new Reports(start.format("YYYYMMDD"), end.format("YYYYMMDD"), reports[key]);
    return report ? report : undefined;
  }

  render() {
    const { page } = this.state;
    const report = this.dailyReport();
    const count = report ? report.data.length : 0;
    const data = report ? report.mergedReports() : [];
    return (
      <React.Fragment>
        <GridContainer>
          <ItemGrid xs={12}>
            <IconCard
              iconColor="orange"
              icon={LooksOne}
              title="Organization Breakdown"
              content={
                <React.Fragment>
                  <div style={{ margin: "0 40px" }}>
                    <ReactTable
                      defaultSorted={[
                        {
                          id: "successRate",
                          desc: true,
                        }
                      ]}
                      noDataText="No report for this date range"
                      data={data}
                      columns={[
                        {
                          accessor: "orgName"
                        },
                        {
                          accessor: "successful",
                          maxWidth: 150,
                        },
                        {
                          accessor: "failures",
                          maxWidth: 150,
                        },
                        {
                          accessor: "successRate",
                        },
                        {
                          accessor: "totalUniqueCases",
                          maxWidth: 150,
                        },
                        {
                          accessor: "totalScanners",
                          maxWidth: 150,
                        },
                        {
                          accessor: "mostFrequentFailureCodes",
                        },
                        {
                          accessor: "mostEngagingApp",
                        }]
                        .map(c => ({ Header: "", accessor: c.accessor, maxWidth: c.maxWidth, Cell: this.formatCell(c.accessor) }))
                      }
                      className="-highlight"
                      refreshing={false}
                      minRows={0}
                      page={page}
                      showPaginationBottom
                      count={count}
                      PaginationComponent={Pagination}
                      canGetMorePages={false}
                    />
                  </div>
                </React.Fragment>
              }
            />
          </ItemGrid>
        </GridContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.reports.orgs,
});

const mapDispatchToProps = (dispatch) => ({
  // uses dispatch
  storeOrgsReport: (report, key) => dispatch(storeOrgsReport(report, key)),
  signalError: (msg) => dispatch(storeNotificationError(msg)),
  clearError: () => dispatch(removeNotificationError()),

  // does not use dispatch
  fetchDailyReport: (start, end) => allOrgStatsQuery(start.format("YYYYMMDD"), end.format("YYYYMMDD")),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DailyOrganizationInsights));
