import React from "react";
import PropTypes from "prop-types";
import IconCard from "components/Cards/IconCard.jsx";
import { Bar } from "components/Charts";
import Reports from "models/Reports";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button";
import Modal from "components/Modal";
import Checkbox from "components/Checkbox";

// Styles
import BarChart from "material-ui-icons/InsertChart";
import withStyles from "material-ui/styles/withStyles";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle";

class AppSuccessRates extends React.Component {
  static propTypes = {
    report: PropTypes.instanceOf(Reports)
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalState: null,
      exitCodes: null,
      report: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { report } = this.props;
    if (
      (!prevProps.report && report) ||
      (prevProps.report && report && (!prevProps.report.start.isSame(report.start) || !prevProps.report.end.isSame(report.end)))
    ) {
      this.setState({ report });
      const exitCodes = report.exitCodesBy("appId");
      this.setState({ exitCodes });
      if (Object.keys(exitCodes).length) {
        this.updateModalState(exitCodes);
      }
    }
  }

  updateModalState = (exitCodes) => {
    const modalState = {};
    for (const id in exitCodes) {
      if (!exitCodes.hasOwnProperty(id)) {
        continue;
      }
      modalState[id] = {
        appName: exitCodes[id].appName,
        failureCodes: {
          ...Object.keys(exitCodes[id].failureCodes).reduce((result, code) => ({
            ...result,
            [code]: true
          }), {})
        }
      };
    }
    this.setState({ modalState });
  }

  toggleFailureCodeCheckbox = (appId, failureCode) => {
    this.setState(prevState => {
      return {
        ...prevState,
        modalState: {
          ...prevState.modalState,
          [appId]: {
            ...prevState.modalState[appId],
            failureCodes: {
              ...prevState.modalState[appId].failureCodes,
              [failureCode]: !prevState.modalState[appId].failureCodes[failureCode]
            }
          }
        }
      };
    });
  }

  modal = () => {
    return (
      <Modal
        style={{
          height: 800,
          width: 700,
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer direction="row" style={{ height: "100%", padding: "0 40px" }}>
          <ItemGrid xs={12} style={{ alignSelf: "flex-start" }}><h2 style={{ textAlign: "center" }}>Toggle App Failure Codes</h2></ItemGrid>
          <ItemGrid xs={12}>
            <GridContainer>
              {this.state.modalState && Object.keys(this.state.modalState).map((id, key) => {
                return (
                  <ItemGrid xs={12} key={key}>
                    <GridContainer>
                      <ItemGrid xs={12}><h4 style={{ marginBottom: 0 }}>{this.state.modalState[id].appName}</h4></ItemGrid>
                      {Object.keys(this.state.modalState[id].failureCodes).map((code, key2) => (
                        <ItemGrid xs={2} key={key2}>
                          <Checkbox label={code} checked={this.state.modalState[id].failureCodes[code]} onClick={() => this.toggleFailureCodeCheckbox(id, code)} />
                        </ItemGrid>
                      ))}
                    </GridContainer>
                  </ItemGrid>
                );
              })}
            </GridContainer>
          </ItemGrid>
          <ItemGrid container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }} xs={12}>
            <Button onClick={() => this.setState({ showModal: null })}>Close</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }

  appSuccessRates = () => {
    const { modalState, exitCodes, report } = this.state;
    if (!report) {
      return null;
    }
    const appIDs = Object.keys(exitCodes);
    if (!appIDs.length) {
      return null;
    }
    const exclude = {};
    appIDs.forEach(id => {
      Object.keys(modalState[id].failureCodes).forEach(code => {
        if (!modalState[id].failureCodes[code]) {
          if (exclude[id]) {
            exclude[id] = exitCodes[id].failureCodes[code] + exclude[id];
          } else {
            exclude[id] = exitCodes[id].failureCodes[code];
          }
        }
      });
    });
    const results = report.appSuccessRates(exclude);
    return Object.keys(results).length ? results : null;
  }

  render() {
    const { report } = this.state;
    let labels = [];
    let xSeries = null;
    let configureButton = null;
    if (report) {
      labels = report.appNames();
      const rates = this.appSuccessRates();
      if (rates) {
        xSeries = [
          Object.values(rates).map(n => ({ value: n, meta: { style: "stroke: #0fb8ad; stroke-width: 30px;" } }))
        ];
      }
      configureButton = (
        <GridContainer justify="flex-end">
          <ItemGrid xs={12}>
            <Button
              right
              color="primary"
              onClick={() => this.setState({ showModal: true })}
            >Configure</Button>
          </ItemGrid>
        </GridContainer>
      );
    }

    return (
      <IconCard
        iconColor="orange"
        icon={BarChart}
        title="App Success Rates"
        sub
        content={(
          <React.Fragment>
            {this.state.showModal && this.modal()}
            {configureButton}
            <Bar
              noXSeriesRender={() => <h3><small>No report</small></h3>}
              emptyXSeriesRender={() => <h3><small>No report</small></h3>}
              legendTitle="Legend"
              legend={["Success Rate"]}
              xLabels={labels}
              xSeries={xSeries}
              options={{
                axisY: {
                  high: 100
                }
              }}
            />
          </React.Fragment>
        )}
      />
    );
  }
}

export default withStyles(chartsStyle)(AppSuccessRates);