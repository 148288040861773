import React from "react";
import PropTypes from "prop-types";
import IconCard from "components/Cards/IconCard.jsx";
import Reports from "models/Reports";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";
import { APPIDTONAME } from "constants/Apps";

// Styles
import Scanner from "material-ui-icons/Scanner";

const formatCell = (key) => (cell) => {
  switch (key) {
  case "scannerId":
    return <Cell label="Scanner" value={cell.original.scannerId} />;
  case "appId":
    return <Cell label="Application" value={cell.original.appId} />;
  case "successful":
    return <Cell label="Successful" value={cell.original.successful} />;
  case "failures":
    return <Cell label="Failures" value={cell.original.failures} />;
  case "total":
    return <Cell label="Total" value={cell.original.total} />;
  case "percent":
    return <Cell label="Percent" value={cell.original.percent} />;
  case "scannerTotal":
    return <Cell label="Total" value={cell.original.successful + cell.original.failures} />;
  default:
    return null;
  }
};

export const ScannerAppUsageContent = ({ report }) => {
  if (!report) {
    return <h3><small>No report</small></h3>;
  }
  const scannerAppUsage = report.scannerAppUsage();
  const keys = Object.keys(scannerAppUsage);
  if (!keys.length) {
    return <h3><small>No scanner usage reported for this date range</small></h3>;
  }
  const series = keys.reduce((arr, appId) => {
    let total = 0;
    const scanners = [];
    for (const scannerId in scannerAppUsage[appId]) {
      if (!scannerAppUsage[appId].hasOwnProperty(scannerId)) {
        continue;
      }
      scanners.push({
        scannerId,
        ...scannerAppUsage[appId][scannerId]
      });
      total += scannerAppUsage[appId][scannerId].successful + scannerAppUsage[appId][scannerId].failures;
    }
    return [
      ...arr,
      {
        appId: APPIDTONAME[appId] || appId,
        total,
        scanners: scanners.map(s => ({
          ...s,
          percent: (((s.successful + s.failures) / total) * 100).toFixed(2)
        })).sort((a, b) => (a.successful + a.failures) > (b.successful + b.failures) ? -1 : 1)
      }
    ];
  }, []);
  return (
    <GridContainer>
      <ItemGrid xs={12}>
        <ReactTable
          sortable={false}
          resizable={false}
          data={series}
          noDataText="No scanner usage reported for this date range"
          columns={
            [
              { header: "", accessor: "appId"},
              { header: "", accessor: "total", maxWidth: 100 },
            ].map(c => ({
              Header: c.header,
              Cell: formatCell(c.accessor),
              maxWidth: c.maxWidth,
            }))
          }
          SubComponent={row => {
            return (
              <ReactTable
                sortable={false}
                resizable={false}
                data={row.original.scanners}
                minRows={0}
                showPagination={false}
                columns={
                  [
                    { accessor: "scannerId" },
                    { accessor: "failures", maxWidth: 100 },
                    { accessor: "successful", maxWidth: 100 },
                    { accessor: "percent", maxWidth: 100 },
                    { accessor: "scannerTotal", maxWidth: 100 }
                  ].map(c => ({
                    Header: "",
                    Cell: formatCell(c.accessor),
                    maxWidth: c.maxWidth
                  }))
                }
              />
            );
          }}
          showPaginationBottom
          className="-highlight"
          pageSize={10}
        />
      </ItemGrid>
    </GridContainer>
  );
};

ScannerAppUsageContent.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export const ScannerAppUsage = ({ report }) => (
  <IconCard
    iconColor="orange"
    icon={Scanner}
    title="App Usage By Scanner"
    content={<ScannerAppUsageContent report={report} />}
  />
);

ScannerAppUsage.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export default ScannerAppUsage;