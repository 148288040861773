import React from "react";
import pt from "prop-types";
import IconCard from "components/Cards/IconCard.jsx";
import Reports from "models/Reports";
import { Bar } from "components/Charts";
import ThumbsUpDown from "material-ui-icons/ThumbsUpDown";
import SuccessFailureToolbar from "./SuccessFailureToolbar";
import { WithSuccessFailureToolbarState } from "components/HigherOrder";

export class AppSuccessFailure extends React.Component {
  state = {
    selected: null,
  }

  onToolbarDropdownChange = (selected) => {
    if (this.state.selected === selected) {
      return;
    }
    if (selected === "All") {
      this.setState({ selected: null });
      return;
    }
    const apps = this.props.report.appStats();
    if (apps) {
      this.setState({ selected: apps[selected] });
    }
  }

  legendLabels = () => {
    const { successActive, failureActive } = this.props;
    const labels = [];
    if (failureActive) {
      labels.push("Failure");
    }
    if (successActive) {
      labels.push("Success");
    }
    return labels;
  }

  chartLabels = (appNames) => {
    const { selected } = this.state;
    return appNames.reduce((result, name) => {
      const appMatched = selected && selected.appName === name;
      if (!selected || appMatched) {
        return [...result, name];
      }
      return result;
    }, []);
  }

  chartMetric = (apps, metric) => {
    const appNames = Object.keys(apps);
    const { selected } = this.state;
    let activeMetric;
    switch (metric) {
    case "successful":
      activeMetric = this.props.successActive;
      break;
    case "failures":
      activeMetric = this.props.failureActive;
      break;
    default:
      break;
    }
    return appNames.reduce((result, name) => {
      const appMatched = selected && selected.appName === name;
      if (activeMetric && (appMatched || !selected)) {
        return [...result, apps[name][metric]];
      }
      return result;
    }, []);
  }

  chartSeries = (failures, successes) => {
    const { successActive, failureActive } = this.props;
    const series = [];
    if (successActive) {
      series.push(successes.map(n => ({ value: n, meta: { style: "stroke: #0fb8ad; stroke-width: 30px;" } })));
    }
    if (failureActive) {
      series.push(failures.map(n => ({ value: n, meta: { style: "stroke: #ef5350; stroke-width: 30px;" } })));
    }
    return series;
  }

  render() {
    const { report, toolbarActive, onToggleToolbar, successActive, failureActive, onToolbarMetricClick } = this.props;
    const { selected } = this.state;
    let xSeries = null;
    let labels = [];
    let dropdownData = ["All"];
    if (report) {
      const apps = report.appStats();
      if (apps) {
        const appNames = Object.keys(apps || []);
        dropdownData.push(...appNames);
        labels = this.chartLabels(appNames);
        xSeries = this.chartSeries(this.chartMetric(apps, "failures"), this.chartMetric(apps, "successful"));
      }
    }

    return (
      <IconCard
        iconColor="orange"
        icon={ThumbsUpDown}
        title="App Success & Failures"
        content={(
          <React.Fragment>
            <SuccessFailureToolbar
              hide={!report}
              dropdownOnChange={this.onToolbarDropdownChange}
              dropdownButtonText={selected ? selected.appName : "All"}
              dropdownData={dropdownData}
              failureActive={failureActive}
              successActive={successActive}
              successOnClick={() => onToolbarMetricClick("successActive")}
              failureOnClick={() => onToolbarMetricClick("failureActive")}
              active={toolbarActive}
              toggleActive={onToggleToolbar}
            />
            <Bar
              noXSeriesRender={() => <h3><small>No report</small></h3>}
              emptyXSeriesRender={() => <h3><small>No report</small></h3>}
              legendTitle="Legend"
              legend={this.legendLabels()}
              xLabels={labels}
              xSeries={xSeries}
              options={{
                stackBars: successActive && failureActive,
              }}
            />
          </React.Fragment>
        )}
      />
    );
  }
}

AppSuccessFailure.propTypes = {
  report: pt.instanceOf(Reports),
  successActive: pt.bool.isRequired,
  failureActive: pt.bool.isRequired,
  toolbarActive: pt.bool.isRequired,
  onToolbarMetricClick: pt.func.isRequired,
  onToggleToolbar: pt.func.isRequired,
};

export default WithSuccessFailureToolbarState(AppSuccessFailure);
