import React from "react";

import withStyles from "material-ui/styles/withStyles";
import LinearProgress from "material-ui/Progress/LinearProgress";
import HeaderCard from "components/Cards/HeaderCard";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";

import dwv from 'dwv';
// import "./DwvComponent.css"

// progress
dwv.gui.displayProgress = function () { };
// get element
dwv.gui.getElement = dwv.gui.base.getElement;
// refresh element
dwv.gui.refreshElement = dwv.gui.base.refreshElement;

// Image decoders (for web workers)
dwv.image.decoderScripts = {
    "jpeg2000": "assets/dwv/decoders/pdfjs/decode-jpeg2000.js",
    "jpeg-lossless": "assets/dwv/decoders/rii-mango/decode-jpegloss.js",
    "jpeg-baseline": "assets/dwv/decoders/pdfjs/decode-jpegbaseline.js"
};

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        flex: '0 0 auto',
    },
    tagsDialog: {
        minHeight: '90vh', maxHeight: '90vh',
        minWidth: '90vw', maxWidth: '90vw',
    },
    iconSmall: {
        fontSize: 20,
    },
});

class DwvComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            versions: {
                dwv: dwv.getVersion(),
                react: React.version
            },
            tools: ['Scroll', 'ZoomAndPan', 'WindowLevel', 'Draw'],
            selectedTool: 'Select Tool',
            loadProgress: 0,
            dataLoaded: false,
            dwvApp: null,
            tags: [],
            showDicomTags: false,
            toolMenuAnchorEl: null
        };
    }

    changeTool = tool => {
        if (this.state.dwvApp) {
            this.setState({ selectedTool: tool });
            this.state.dwvApp.onChangeTool({ currentTarget: { value: tool } });
        }
    }

    render() {
        const { loadProgress } = this.state;

        return (
            <HeaderCard
                cardTitle="Viewer"
                headerColor="blue"
                content={
                    <div style={{ padding: "30px" }}>
                        <GridContainer 
                            className={this.props.classes.wideHorizontalPadding} 
                            style={{ padding: "30px", backgroundColor: "#000", borderRadius: "5px" }}
                        >
                            <ItemGrid xs={12} container justify="center">
                                <Button right onClick={() => this.changeTool('Scroll')}>Scroll</Button>
                                <Button right onClick={() => this.changeTool('WindowLevel')}>W/L</Button>
                                <Button right onClick={() => this.changeTool('ZoomAndPan')}>Zoom/Pan</Button>
                                {/* <Button right onClick={() => this.changeTool('Draw')}>Draw</Button> */}
                            </ItemGrid>
                            <ItemGrid xs={12} container justify="center">
                                <div id="dwv">
                                    {loadProgress !== 100 && <LinearProgress variant="determinate" value={loadProgress} />}
                                    <div className="layerContainer">
                                        <canvas className="imageLayer" style={{ height: "60vh" }}>Only for HTML5 compatible browsers...</canvas>
                                        <div className="drawDiv" style={{
                                             position: "absolute",
                                             pointerEvents: "none"
                                        }}></div>
                                    </div>
                                </div>
                            </ItemGrid>
                        </GridContainer>
                    </div>
                }
            />

        );
    }

    componentDidMount() {
        // create app
        var app = new dwv.App();
        // initialise app
        app.init({
            "containerDivId": "dwv",
            "tools": this.state.tools,
            "shapes": ["Ruler"],
            "isMobile": true
        });
        // progress
        var self = this;
        app.addEventListener("load-progress", function (event) {
            self.setState({ loadProgress: event.loaded });
        });
        app.addEventListener("load-end", function (event) {
            // set data loaded flag
            self.setState({ dataLoaded: true });
            // set dicom tags
            self.setState({ tags: app.getTags() });
            // set the selected tool
            if (app.isMonoSliceData() && app.getImage().getNumberOfFrames() === 1) {
                self.setState({ selectedTool: 'ZoomAndPan' });
            } else {
                self.setState({ selectedTool: 'Scroll' });
            }
        });
        // store
        this.setState({ dwvApp: app });

        // load dicom data
        try {
            app.loadURLs(this.props.images);
        } catch (e) { console.log(e) }


    }

    onChangeTool = tool => {
        if (this.state.dwvApp) {
            this.setState({ selectedTool: tool });
            this.state.dwvApp.onChangeTool({ currentTarget: { value: tool } });
        }
    }

    onReset = tool => {
        if (this.state.dwvApp) {
            this.state.dwvApp.onDisplayReset();
        }
    }

    handleTagsDialogOpen = () => {
        this.setState({ showDicomTags: true });
    };

    handleTagsDialogClose = () => {
        this.setState({ showDicomTags: false });
    };

    handleMenuButtonClick = event => {
        this.setState({ toolMenuAnchorEl: event.currentTarget });
    };

    handleMenuClose = event => {
        this.setState({ toolMenuAnchorEl: null });
    };

    handleMenuItemClick = tool => {
        this.setState({ toolMenuAnchorEl: null });
        this.onChangeTool(tool);
    };

}

export default withStyles(styles)(DwvComponent);

