import React from "react";
import pt from "prop-types";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";

// styles
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const style = {
  ...dashboardStyle,
  ...buttonsStyle,
  ...sweetAlertStyle
};

class SuccessModal extends React.Component {
  static propTypes = {
    onClose: pt.func.isRequired,
    msg: pt.string.isRequired,
    classes: pt.shape({
      button: pt.string.isRequired,
      success: pt.string.isRequired,
    }).isRequired,
    title: pt.string,
    buttonText: pt.string
  }
  static defaultProps = {
    title: "Done",
    buttonText: "Done",
  }

  render() {
    const { onClose, msg, classes, title, buttonText } = this.props;
    return (
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => onClose()}
        onCancel={() => onClose()}
        confirmBtnCssClass={
          classes.button + " " + classes.success
        }
        showConfirm={false}
      >
        <div>
          {msg}
        </div>
        <div style={{ marginTop: "40px" }}>
          <Button color="success" onClick={() => onClose()}>{buttonText}</Button>
        </div>
      </SweetAlert>
    );
  }
}

export default withStyles(style)(SuccessModal);