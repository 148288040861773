import React from "react";
import pt from "prop-types";
import { connect } from "react-redux";
import { getJob, getPatient } from "libs/apiLib";
import { WithNotifications } from "components/HigherOrder";

import LoadingModal from "components/LoadingModal/LoadingModal";
import withStyles from "material-ui/styles/withStyles";
import reactTableStyles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";

import * as ResultReports from "./ResultReports";
import { APPS } from "constants/Apps";

const styles = {
  ...reactTableStyles,
  cardPadding: {
    padding: "40px !important"
  },
  dataPointCard: {
    textAlign: "center",
    color: "#FFFFFF",
    background: "linear-gradient(to right, #0fb8ad, #1fc8db)"
  },
  avatar: {
    height: "5em",
    width: "5em",
    color: "grey",
  },
  gridBottomMargin: {
    marginBottom: 30
  },
  zeroBottomMargin: {
    marginBottom: 0
  },
  zeroTopMargin: {
    marginTop: 0
  },
  zeroMargin: {
    margin: 0
  }
};

export const ResultPropTypes = {
  classes: pt.shape({
    cardPadding: pt.string.isRequired,
    dataPointCard: pt.string.isRequired,
    wideHorizontalPadding: pt.string.isRequired,
    avatar: pt.string.isRequired,
    gridBottomMargin: pt.string.isRequired,
    zeroBottomMargin: pt.string.isRequired,
    zeroTopMargin: pt.string.isRequired,
    zeroMargin: pt.string.isRequired,
  }).isRequired,
  signalError: pt.func.isRequired,
  clearError: pt.func.isRequired,
  getJob: pt.func.isRequired,
  getPatient: pt.func.isRequired,
  location: pt.shape({
    state: pt.shape({
      result: pt.shape({
        eventId: pt.string.isRequired,
      }),
      patient: pt.shape({
        patientId: pt.string.isRequired,
      })
    }),
    search: pt.string,
  }),
  match: pt.shape({
    params: pt.shape({
      id: pt.string.isRequired,
      appId: pt.string.isRequired,
      eventId: pt.string.isRequired
    }).isRequired
  }).isRequired,
  history: pt.shape({
    push: pt.func.isRequired,
  }).isRequired,
};
export class Result extends React.Component {
  static propTypes = ResultPropTypes;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      patient: props.location.state && props.location.state.patient || null,
      result: props.location.state && props.location.state.result || null,
      patientID: props.location.state && props.location.state.patient && props.location.state.patient.patientId || props.match.params.id || null,
      id: props.location.state && props.location.state.result && props.location.state.result.eventId || props.match.params.eventId || null,
    };
  }

  componentDidMount() {
    if (!this.state.patient) {
      this.getPatient();
    }

    if (!this.state.result) {
      this.getJob();
    }
  }

  getPatient = async () => {
    const { getPatient, signalError, clearError, location: { search } } = this.props;
    const { patientID } = this.state;
    const orgId = new URLSearchParams(search).get("orgId");
    this.setState({ loading: true });
    try {
      const response = await getPatient(patientID, orgId);
      if (response.success && response.patient) {
        this.setState({ patient: response.patient });
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error getting patient. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  getJob = async () => {
    const { getJob, signalError, clearError } = this.props;
    const { id } = this.state;
    this.setState({ loading: true });
    try {
      if (!id || !id.length) {
        throw new Error();
      }
      const response = await getJob(id);
      if (response.success && response.job) {
        this.setState({ result: response.job });
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error getting patient result. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  render() {
    const { match: { params: { appId } } } = this.props;
    const { result, patient } = this.state;
    if (!result || !patient) {
      return <LoadingModal />;
    }
    switch (appId) {
    case APPS.airr:
    case APPS.airrMci:
      return <ResultReports.AlzIntegrated {...this.state} appId={appId} />;
    case APPS.agrr:
      return <ResultReports.AlzGenetic {...this.state} appId={appId} />;
    case APPS.pgr:
      return <ResultReports.ProstateGenetic {...this.state} appId={appId} />;
    default:
      return <ResultReports.GeneralCase {...this.state} />;
    }
  }
}

export default connect(undefined, () => ({
  getJob: (id) => getJob(id),
  getPatient: (id, orgId) => getPatient(id, orgId),
}))(WithNotifications(withStyles(styles)(Result)));