import React from "react";
import pt from "prop-types";
import { connect } from "react-redux";
import WizardModal from "components/Modal/Wizard";
import { userSelector } from "selectors";

import { WithPatientRecords, WithAppRunners } from "components/HigherOrder";
import AppStep from "./AppStep";
import DataStep from "./DataStep";
import ConfirmStep from "./ConfirmStep";
import OptionsStep from "./OptionsStep";
import { APPS, APPSSET } from "constants/Apps";
export class AppWizard extends React.Component {
  static propTypes = {
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    onCancel: pt.func.isRequired,
    orgId: pt.string.isRequired,
    patient: pt.shape({
      patientId: pt.string.isRequired,
      dob: pt.string,
      avlt: pt.number,
      mmse: pt.number,
    }).isRequired,
    loading: pt.bool,
    records: pt.arrayOf(pt.shape({})),
    onGetMorePatientRecords: pt.func.isRequired,
    onPageChange: pt.func.isRequired,
    allPagesFulfilled: pt.bool.isRequired,
    apps: pt.arrayOf(pt.shape({
      appId: pt.string.isRequired,
      name: pt.string.isRequired,
      description: pt.string.isRequired,
      type: pt.string.isRequired,
    })).isRequired,
  }

  state = {
    loading: false,
    apps: [],
    selectedApp: null,
    selectedRecords: [],
    selectedOptions: {},
    step: 0,
  }

  disableNextButton = () => {
    const { loading, step, selectedApp } = this.state;
    if (loading || this.props.loading) {
      return true;
    }
    switch (step) {
    case 0: // select app stage
      return selectedApp === null;
    case 1: // select data stage
      return !this.dataStepValid();
    case 2: // select options stage
      return !this.optionsStepValid();
    case 3: // confirmation stage
      return !this.validAppCriteria();
    default:
      return false;
    }
  }

  optionsStepValid = () => {
    const { selectedOptions, selectedApp } = this.state;
    switch (selectedApp.appId) {
    case APPS.pgr:
    case APPS.agrr:
      // geneticRisk does not have any options, return true
      return true;
    case APPS.airr:
    case APPS.airrMci:
      // integratedRisk does have any options, return true only if mci is not undefined
      return selectedOptions && selectedOptions.mci !== undefined;
    default:
      return true;
    }
  }

  dataStepValid = () => {
    const { selectedRecords, selectedApp } = this.state;
    switch (selectedApp.appId) {
    case APPS.pgr:
    case APPS.agrr:
      return (
        selectedRecords.length === 1 &&
        Boolean(selectedRecords.find((s) => s.type === "Genotype"))
      );
    case APPS.airr:
    case APPS.airrMci:
      return (
        selectedRecords.length === 2 &&
        Boolean(selectedRecords.find((s) => s.type === "Dicom Medical Study")) &&
        Boolean(selectedRecords.find((s) => s.type === "Genotype"))
      );
    default:
      return true;
    }
  }

  activeApps = () => this.props.apps
    .filter(app => [APPS.airr, APPS.airrMci, APPS.agrr, APPS.pgr].includes(app.appId))

  handleRecordSelect = (record) => {
    this.setState(prevState => {
      const i = prevState.selectedRecords.findIndex(r => r.recordId === record.recordId);
      if (i === -1) {
        return ({ ...prevState, selectedRecords: [...prevState.selectedRecords, record] });
      }
      return ({
        ...prevState, selectedRecords: [
          ...prevState.selectedRecords.slice(0, i),
          ...prevState.selectedRecords.slice(i + 1),
        ]
      });
    });
  }

  handleAppSelect = (selectedApp) => {
    if (this.state.selectedApp && this.state.selectedApp.appId !== selectedApp.appId) {
      this.setState(prevState => ({
        selectedApp,
        apps: prevState.apps || [],
        selectedRecords: [],
        selectedOptions: {},
        step: 0,
      }));
      return;
    }
    switch (selectedApp.appId) {
    case APPS.airr:
    case APPS.airrMci:
      this.setState({ selectedOptions: { mci: undefined } });
      break;
    case APPS.pgr:
    case APPS.agrr:
      break;
    default:
      return;
    }
    this.setState({ selectedApp });
  }

  handleOptionSelect = (key, value) => {
    this.setState(prevState => ({ ...prevState, selectedOptions: { ...prevState.selectedOptions, [key]: value } }));
  }

  validAppCriteria = () => {
    const { selectedApp } = this.state;
    const { patient } = this.props;
    if (!selectedApp || !APPSSET.has(selectedApp.appId)) {
      return true;
    }
    switch (selectedApp.appId) {
    case APPS.agrr:
    case APPS.airrMci:
    case APPS.airr:
    case APPS.pgr:
      if (patient.dob) {
        return true;
      }
      return false;
    default:
      return true;
    }
  }

  invalidAppCriteriaErrorMessage = () => {
    const { selectedApp } = this.state;
    let msg = "";
    switch (selectedApp.appId) {
    case APPS.pgr:
      return "App Prostate Cancer GeneticRisk requires patient to have a valid date of birth.";
    case APPS.agrr:
      return "App Alzheimer's GeneticRisk requires patient to have a valid date of birth.";
    case APPS.airr:
    case APPS.airrMci:
      return "App Alzheimer's IntegratedRisk requires patient to have a valid date of birth.";
    default:
      return msg;
    }
  }

  confirmStepErrorMessage = () => {
    return !this.validAppCriteria() ? this.invalidAppCriteriaErrorMessage() : null;
  }

  handleRunAppClick = async () => {
    const { runApp, patient } = this.props;
    const { selectedApp, selectedRecords, selectedOptions } = this.state;
    this.setState({ loading: true });
    try {
      await runApp(patient, selectedApp, selectedRecords, selectedOptions);
      this.props.onSuccess();
    } catch (e) {
      console.warn(e);
      this.props.onFailure();
    }
  }

  render() {
    const { loading, selectedApp, selectedRecords, selectedOptions } = this.state;
    const { user, page, records, onGetMorePatientRecords, onPageChange, allPagesFulfilled, patient } = this.props;
    const dataStepProps = {
      loading: this.props.loading,
      selectedApp,
      page,
      records,
      onGetMorePatientRecords,
      onPageChange,
      allPagesFulfilled
    };
    return (
      <WizardModal
        style={{
          width: "50%",
          height: "80%",
          overflow: "hidden",
          overflowY: "scroll",
        }}
        color="primary"
        title="Select App"
        steps={[
          { stepName: "Application", stepTitle: "Select Application", stepComponent: () => <AppStep apps={this.activeApps()} loading={loading || this.props.loading} onSelect={this.handleAppSelect} selectedApp={selectedApp} />, stepId: "app" },
          { stepName: "Records", stepTitle: "Select Records", stepComponent: () => <DataStep {...dataStepProps} id={this.props.patient.patientId} orgId={this.props.orgId} onSelect={this.handleRecordSelect} selectedRecords={selectedRecords} />, stepId: "record" },
          { stepName: "Options", stepTitle: "Select Options", stepComponent: () => <OptionsStep userIsHLXSupport={Boolean(user && user.isHLXSupport)} selectedApp={selectedApp} onSelect={this.handleOptionSelect} selectedOptions={selectedOptions} />, stepId: "options" },
          { stepName: "Confirmation", stepTitle: "Confirm Settings", stepComponent: () => <ConfirmStep userIsHLXSupport={Boolean(user && user.isHLXSupport)} patient={patient} app={selectedApp} records={selectedRecords} options={selectedOptions} errorMessage={this.confirmStepErrorMessage()} loading={loading || this.props.loading} />, stepId: "confirm" },
        ]}
        previousButtonText="Back"
        finishButtonText="Submit"
        onCancel={this.props.onCancel}
        disableNext={this.disableNextButton()}
        onStepChange={step => this.setState({ step })}
        finishButtonClick={this.handleRunAppClick}
        loading={loading || this.props.loading}
      />
    );
  }
}

export default connect(
  (state, props) => ({
    orgId: props.orgId || state.user.user.orgId,
    user: userSelector(state)
  })
)(WithPatientRecords(WithAppRunners(AppWizard)));