import React from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"

// react table
import ReactTable from "react-table"

// material-ui components
import withStyles from "material-ui/styles/withStyles"
import Tooltip from "material-ui/Tooltip"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import ItemGrid from "components/Grid/ItemGrid.jsx"
import Bannercard from "components/Cards/BannerCard.jsx"
import IconButton from "components/CustomButtons/IconButton.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Cards/RegularCard.jsx"

// icons
import WifeTethering from "material-ui-icons/WifiTethering"

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle"
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx"

// api
import { fetchDevices } from "libs/apiLib"

// libs
import { isDateOlderThanMins } from "libs/utils"

const style = {
	...dashboardStyle,
	...buttonsStyle
}

class MyDevices extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			error: null,
			devices: null
		}

		this.handleRefreshClick = this.handleRefreshClick.bind(this)
		this.getDevices = this.getDevices.bind(this)
	}

	async componentDidMount() {
		await this.getDevices()
	}

	async getDevices() {
		try {
			this.setState({ isLoading: true, error: null })

			const urlParams = new URLSearchParams(this.props.location.search)
			const orgId = urlParams.get("orgId")

			const results = await fetchDevices(orgId)

			if (results) {
				if (results.devices) { this.setState({ devices: results.devices.Items }) }
			}
		} catch (e) {
			this.setState({ isLoading: false, error: "Failed to retrieve edge devices." })
			console.error(e)
		}
	}

	getEdgeStatusColor(checkIn) {
		if (!checkIn) { return "default" }

		if (isDateOlderThanMins(checkIn, 20)) { return "default" }
		else if (isDateOlderThanMins(checkIn, 11)) { return "warning" }
		else { return "success" }
	}

	getConnectivityStatus(checkIn) {
		if (!checkIn) { return "Offline" }

		if (isDateOlderThanMins(checkIn, 20)) { return "Offline" }
		else if (isDateOlderThanMins(checkIn, 11)) { return "Weak" }
		else { return "Connected" }
	}

  handleRefreshClick = async () => {
  	await this.getDevices()
  }

  render() {
  	const { classes } = this.props

  	let tableData = []

  	if (this.state.devices) {
  		tableData = this.state.devices.map((prop) => {
  			return ({
  				status: (
  					<div style={{ margin: "10px" }}>
  						<Tooltip id="tooltip-icon" title={this.getConnectivityStatus(prop.checkIn)} placement="bottom-end">
  							<IconButton size="xs" aria-label={this.getConnectivityStatus(prop.checkIn)}
  								color={this.getEdgeStatusColor(prop.checkIn)}>
  								<WifeTethering className={classes.icons} />
  							</IconButton>
  						</Tooltip>
  					</div>),
  				name: (
  					<div>
  						<div>
  							{prop.name}
  						</div>
  						<div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                Device Name
  						</div>
  					</div>
  				),
  				type: (<Button disabled round>{prop.gpu === "yes" ? "GPU Device" : "CPU Device"}</Button>),
  				location: (
  					<div>
  						{prop.locationDetails &&
                <div>
                	<div>
                		{prop.locationDetails.city && prop.locationDetails.region_code && (prop.locationDetails.city + ", " + prop.locationDetails.region_code)}
                	</div>
                	<div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                		{prop.locationDetails.country_name && prop.locationDetails.country_name}
                	</div>
                </div>
  						}
  					</div>
  				),
  				options: (
  					<div style={{ textAlign: "right", paddingRight: "10px" }}>
  						<Button color={"primary"}
  							onClick={() => {
  								this.props.history.push({
  									pathname: "/edge/device",
  									search: `?id=${prop.deviceId}`,
  									state: { device: prop }
  								})
  							}}
  						>Manage</Button>
  					</div>
  				)
  			})
  		})
  	}
  	return (
  		<div>
  			<Bannercard
  				title="Edge Devices"
  				subtitle="Manage and learn about your edge devices here"
  				button={{
  					href: "https://docs.healthlytix.io/help/edgeos/intro-to-edgeos",
  					text: "View Documenation"
  				}}
  			/>
  			<GridContainer>
  				<ItemGrid xs={12} sm={12} md={12} lg={12}>
  					<Card
  						content={
  							tableData &&
                <div>
                	<ItemGrid xs={12}>
                		<div style={{ textAlign: "right" }}>
                			<Button color="success"
                				onClick={this.handleRefreshClick}
                			>
                        Refresh</Button>
                		</div>
                	</ItemGrid>
                	<ReactTable
                		sortable={false}
                		data={tableData}
                		noDataText="No Devices!"
                		columns={[
                			{
                				Header: "",
                				accessor: "status",
                				maxWidth: 120
                			},
                			{
                				Header: "",
                				accessor: "name",
                			},
                			{
                				Header: "",
                				accessor: "type",
                				maxWidth: 250
                			},
                			{
                				Header: "",
                				accessor: "location",
                			},
                			{
                				Header: "",
                				accessor: "options",
                			}
                		]}
                		minRows={2}
                		defaultPageSize={10}
                		showPaginationBottom
                		className="-highlight"
                	/>
                </div>}
  					/>
  				</ItemGrid>
  			</GridContainer>
  		</div>
  	)
  }
}

MyDevices.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.shape({
		search: PropTypes.string,
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
}


export default withRouter(withStyles(style)(MyDevices))
