import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import ItemGrid from "components/Grid/ItemGrid.jsx"
import Button from "components/CustomButtons/Button"
import CustomDropdown from "components/CustomDropdown/CustomDropdown"
import DatePickerButton from "components/DatePickerButtons/DatePickerButton"

// api & Redux action functions
import { generateLicense } from "libs/apiLib"
import { storeNotificationError, removeNotificationError } from "actions"

export class LicenseGenerate extends React.Component {
  static propTypes = {
  	date: PropTypes.instanceOf(moment),
  	onComplete: PropTypes.func.isRequired,
  	onCancel: PropTypes.func.isRequired,
  	generateLicense: PropTypes.func.isRequired,
  	orgID: PropTypes.string.isRequired,
  	apps: PropTypes.arrayOf(PropTypes.shape({
  		appId: PropTypes.string.isRequired,
  		enabled: PropTypes.bool.isRequired,
  		name: PropTypes.string.isRequired,
  		expiration: PropTypes.string,
  	})),
  }

  constructor(props) {
  	super(props)
  	this.state = {
  		date: props.date || moment().add(1, "y"),
  		loading: false,
  		license: null,
  		app: null,
  	}
  }

  componentDidUpdate(_, prevState) {
  	const { app, date } = this.state
  	// if a different app has been selected from previous state
  	if (!prevState.app && app || prevState.app && app && prevState.app.appId !== app.appId) {
  		// respect app.expiration, force date to app.expiration if state.date is after
  		if (app.expiration && date.isAfter(app.expiration)) {
  			this.setState({ date: moment(app.expiration) })
  		}
  	}
  }

  onConfirm = async () => {
  	const { date, app } = this.state
  	const { orgID, generateLicense, onComplete, onCancel, signalError, clearError } = this.props
  	this.setState({ loading: true })
  	try {
  		const results = await generateLicense(app.name, app.appId, date, orgID)
  		if (!results.success) {
  			if (results.error) {
  				throw new Error(results.error)
  			} else {
  				throw new Error("Error generating license. Try again later.")
  			}
  		}
  		this.setState({ loading: false, license: results.license })
  		onComplete(results.license)
  	} catch (e) {
  		signalError(e.message)
  		setTimeout(clearError, 5000)
  		onCancel()
  	}
  }

  handleDropdownSelection = (name) => {
  	const app = this.props.apps.find(app => app.name === name)
  	this.setState({ app })
  }

  latestDate = () => {
  	const { app } = this.state
  	if (!app || !app.expiration) {
  		return undefined
  	}
  	return moment(app.expiration).add(1, "d")
  }

  render() {
  	const { date, loading, app } = this.state
  	const { onCancel, apps } = this.props
  	const end = this.latestDate()
  	return (
  		<GridContainer>
  			<ItemGrid xs={12} sm={7}>
  				<CustomDropdown
  					buttonColor="primary"
  					buttonText={app ? app.name : "Select App"}
  					onAction={this.handleDropdownSelection}
  					dropdownList={apps.map(a => a.name)}
  				/>
  				<DatePickerButton
  					inline
  					closeOnSelect
  					disablePastDays
  					disableFutureDays={false}
  					pickerAlign="center"
  					justify="flex-start"
  					color="primary"
  					defaultValue={date}
  					viewDate={date}
  					start={date}
  					preventSelectionPast={end}
  					onChange={date => this.setState({ date })}
  				/>
  			</ItemGrid>
  			<ItemGrid container justify="flex-end" xs={12} md={5}>
  				<Button onClick={onCancel} disabled={loading}>Cancel</Button>
  				<Button color="success" onClick={this.onConfirm} disabled={loading || !app}>Submit</Button>
  			</ItemGrid>
  		</GridContainer>
  	)
  }
}

export default connect(undefined, (dispatch) => ({
	signalError: (msg) => dispatch(storeNotificationError(msg)),
	clearError: () => dispatch(removeNotificationError()),
	generateLicense: (name, id, date, orgID) => generateLicense(date.format("MM/DD/YYYY"), name, id, orgID)
}))(LicenseGenerate)
