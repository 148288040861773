import moment from "moment";
import Modality from "./Modality";

/*
 * Report is a class that represents a single days stats for an array of apps.
 * A single report should be provided to the Report constructor as an object with the following keys: 
 * @param {object} arg
 * > @param {string} day in format "YYYYMMDD"
 * > @param {string} orgName
 * > @param {string} orgId
 * > @param {number} successful
 * > @param {number} failures 
 * > @param {object} stats 
 * > > @param {string} appName 
 * > > @param {string} appId
 * > > @param {number} failures 
 * > > @param {number} successful 
*/
export default class Report {
  constructor(arg) {
    this.day = moment(arg.day, "YYYYMMDD");
    this.orgName = arg.orgName;
    this.orgID = arg.orgId;
    this.successful = arg.appStats.successful;
    this.failures = arg.appStats.failures;
    this.stats = arg.appStats.stats;
  }

  totalCases = () => {
    return this.successful + this.failures;
  }

  calculateSuccessRate = (a, b) => Math.round((a / b) * 100);

  successRate = () => {
    const total = this.totalCases();
    if (total < 1) {
      return 0;
    }
    return this.calculateSuccessRate(this.successful, total);
  }

  topAppBy = (metric = "usage") => {
    let result = null;
    let topDataPoint = 0;
    const apps = this.appStats();
    if (this.totalCases() < 1 || !apps) {
      return result;
    }
    for (const appName in apps) {
      if (!apps.hasOwnProperty(appName)) {
        continue;
      }
      let dataPoint = 0;
      switch (metric) {
      case "usage":
        dataPoint = apps[appName].successful + apps[appName].failures;
        break;
      case "successful":
      case "failures":
        dataPoint = apps[appName][metric];
        break;
      default:
        continue;
      }
      if (result && dataPoint > topDataPoint) {
        result = apps[appName];
        topDataPoint = dataPoint;
      } else if (!result && dataPoint > 0) {
        result = apps[appName];
        topDataPoint = dataPoint;
      }
    }
    return result;
  }

  // key = "appName" | "appId"
  appStats = (key = "appName") => {
    if (!this.stats || !this.stats.length) {
      return null;
    }
    return this.stats.reduce((apps, app) => {
      if (apps[app[key]]) {
        return {
          ...apps,
          [app[key]]: {
            ...app,
            successful: apps[app[key]].successful + app.successful,
            failures: apps[app[key]].failures + app.failures,
          }
        };
      }
      return {
        ...apps,
        [app[key]]: { ...app }
      };
    }, {});
  }

  appSuccessRates = () => {
    const apps = this.appStats("appId");
    for (const appId in apps) {
      if (!apps.hasOwnProperty(appId)) {
        continue;
      }
      apps[appId] = this.calculateSuccessRate(apps[appId].successful, apps[appId].successful + apps[appId].failures);
    }
    return apps;
  }

  appsStatsBy = (metric) => {
    const apps = this.appStats("appId");
    const result = [];
    for (const appId in apps) {
      if (!apps.hasOwnProperty(appId)) {
        continue;
      }
      result.push(apps[appId][metric]);
    }
    return result;
  }

  appSuccesses = () => {
    return this.appsStatsBy("successful");
  }

  appFailures = () => {
    return this.appsStatsBy("failures");
  }

  appNames = () => {
    return this.appsStatsBy("appName");
  }

  appIDs = () => {
    return this.appsStatsBy("appId");
  }

  filterAppsBy = (cb) => {
    const result = [];
    this.stats.forEach(app => {
      if (cb(app)) {
        result.push({
          ...app,
          date: this.day
        });
      }
    });
    return result;
  }

  usageByDimension = (dimension, cb) => {
    return this.stats.reduce((results, stat) => {
      if (!stat[dimension] || !stat[dimension].length) {
        return results;
      }
      for (const usage of stat[dimension]) {
        const { id, name, failures, successful } = cb(usage);
        if (!results[id]) {
          const data = {
            day: this.day.format("YYYYMMDD"),
            usage: {
              [stat.appId]: {
                appName: stat.appName,
                failures,
                successful,
              }
            },
            successful,
            failures
          };
          if (name) {
            data.name = name;
          }
          results[id] = data;
          continue;
        }
        const result = results[id];
        results[id] = {
          ...result,
          usage: {
            ...result.usage,
            [stat.appId]: {
              appName: stat.appName,
              failures,
              successful
            }
          },
          failures: result.failures + failures,
          successful: result.successful + successful,
        };
      }
      return results;
    }, {});
  }

  edgeDeviceUsage = () => {
    return this.usageByDimension("edgeDeviceUsage",
      (usage) => ({
        id: usage.deviceId,
        name: usage.deviceName,
        failures: usage.failures,
        successful: usage.successful
      })
    );
  }

  modalityUsageBy = (key = "id") => {
    const isIDKey = key === "id";
    return this.stats.reduce((results, stat) => {
      if (!stat.modalityUsage || !stat.modalityUsage.length) {
        return results;
      }
      for (const data of stat.modalityUsage) {
        const modality = new Modality(data);
        const { successful, failures } = modality;
        if (!results[modality[key]]) {
          results[modality[key]] = isIDKey ? modality : { successful, failures };
          continue;
        }
        const result = results[modality[key]];
        if (isIDKey) {
          result.addToFailures(modality.failures);
          result.addToSuccessful(modality.successful);
        } else {
          result.failures += failures;
          result.successful += successful;
        }
        results[modality[key]] = result;
      }
      return results;
    }, {});
  }

  modalityStatsBy = (dimension = "id") => {
    return this.modalityUsageBy(dimension);
  }

  exitCodesBy = (dimension = "exitCode") => {
    const key = (appStat, exitCode) => {
      switch (dimension) {
      case "appId":
        return appStat.appId;
      default:
        return exitCode;
      }
    };
    return this.stats.reduce((results, stat) => {
      if (!stat.failureCodes || !stat.failureCodes.length) {
        return results;
      }
      for (const failure of stat.failureCodes) {
        const { exitCode, total } = failure;
        const returnKey = key(stat, exitCode);
        if (!results[returnKey]) {
          results[returnKey] = dimension === "exitCode" ? { apps: { [stat.appId]: { appName: stat.appName, total } }, total } : { appName: stat.appName, failureCodes: { [exitCode]: total }, total };
          continue;
        }
        const prevValue = results[returnKey];
        results[returnKey] = dimension === "exitCode" ?
          {
            ...prevValue,
            total: prevValue.total + total,
            apps: {
              ...prevValue.apps,
              [stat.appId]: prevValue.apps[stat.appId] ? {
                ...prevValue.apps[stat.appId],
                total: prevValue.apps[stat.appId].total + total
              } :
                { appName: stat.appName, total }
            }
          } :
          {
            ...prevValue,
            total: prevValue.total + total,
            failureCodes: {
              ...prevValue.failureCodes,
              [exitCode]: prevValue.failureCodes[exitCode] ? prevValue.failureCodes[exitCode] + total : total
            }
          };
      }
      return results;
    }, {});
  }

  scannerAppUsage = () => {
    return this.stats.reduce((results, stat) => {
      if (!stat.modalityUsage || !stat.modalityUsage.length) {
        return results;
      }
      for (const usage of stat.modalityUsage) {
        const { appId } = stat;
        const modality = new Modality(usage);
        if (!results[appId]) {
          results[appId] = {
            [modality.hostName]: {
              successful: modality.successful,
              failures: modality.failures
            }
          };
          continue;
        }
        const result = results[appId];
        const scannerAppStat = result[modality.hostName];
        if (scannerAppStat) {
          results[appId] = {
            ...result,
            [modality.hostName]: {
              successful: modality.successful + scannerAppStat.successful,
              failures: modality.failures + scannerAppStat.failures
            }
          };
          continue;
        } else {
          results[appId] = {
            ...result,
            [modality.hostName]: {
              successful: modality.successful,
              failures: modality.failures
            }
          };
          continue;
        }
      }
      return results;
    }, {});
  }

  totalScanners = () => {
    const scannerAppUsage = this.scannerAppUsage();
    const scanners = {};
    for (const appId in scannerAppUsage) {
      for (const scannerId in scannerAppUsage[appId]) {
        scanners[scannerId] = true;
      }
    }
    return Object.keys(scanners).length;
  }

  appUsageByStudyInstanceUID = () => {
    return this.stats.reduce((results, stat) => {
      if (!stat.studyUsage || !stat.studyUsage.length) {
        return results;
      }
      for (const study of stat.studyUsage) {
        const { appId } = stat;
        if (!results[appId]) {
          results[appId] = {
            [study.studyInstanceUID]: {
              successful: study.successful,
              failures: study.failures
            }
          };
          continue;
        }
        const result = results[appId];
        const { studyInstanceUID, successful, failures } = study;
        const studyAppStat = result[studyInstanceUID];
        if (studyAppStat) {
          results[appId] = {
            ...result,
            [studyInstanceUID]: {
              successful: successful + studyAppStat.successful,
              failures: failures + studyAppStat.failures
            }
          };
          continue;
        } else {
          results[appId] = {
            ...result,
            [studyInstanceUID]: {
              successful: successful,
              failures: failures
            }
          };
          continue;
        }
      }
      return results;
    }, {});
  }
}