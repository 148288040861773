import React from "react";
import PropTypes from "prop-types";
import Chartist from "react-chartist";
import { colors } from "./index";
import Legend from "./Legend";

const Pie = ({ noDataRender, emptyDataRender, data, legend, hideLegend, legendTitle }) => {
  if (!data) {
    return noDataRender();
  }
  if (!data.length) {
    return emptyDataRender();
  }
  return (
    <React.Fragment>
      <Chartist
        className="ct-double-octave"
        type="Pie"
        data={{
          series: data.map((d, i) => ({ value: d.value, meta: { style: `stroke: ${d.color ? d.color : colors[i]}; stroke-width: 30px;` } })),
        }}
        options={{
          donutWidth: 30,
          donut: true,
          donutSolid: true,
        }}
        listener={{
          draw: (context) => {
            if (context.type === "slice" && context.meta && context.meta.style) {
              context.element.attr(context.meta);
            }
          }
        }}
      />
      <Legend title={legendTitle} legend={legend} hideLegend={hideLegend} />
    </React.Fragment>
  );
};

Pie.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    color: PropTypes.string,
  })),
  noDataRender: PropTypes.func.isRequired,
  emptyDataRender: PropTypes.func.isRequired,
  legendTitle: PropTypes.string,
  legend: Legend.propTypes.legend,
  hideLegend: PropTypes.bool,
};

export default Pie;