import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import pt from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Tooltip from "material-ui/Tooltip";

// core components
import { WithNotifications } from "components/HigherOrder";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard.jsx";
import Pagination from "components/Pagination/ReactTablePagination";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import tableStyles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";

// util, api, & Redux action funcs
import { getAllAuditLogs } from "libs/apiLib";
import { storeOrgsAudits, changeOrgsAuditsPage } from "actions/index";
import { calculatePageIndex } from "libs/utils";

const styles = {
  ...dashboardStyle,
  ...tooltip,
  ...tableStyles,
};

export class Audit extends React.Component {
  static propTypes = {
    classes: pt.shape({}).isRequired,
    audits: pt.shape({
      page: pt.number.isRequired,
      total: pt.number.isRequired,
      key: pt.arrayOf(pt.shape({
        id: pt.string.isRequired,
        date: pt.string.isRequired,
      })),
      data: pt.arrayOf(pt.shape({
        PHI: pt.bool,
        eventDate: pt.string.isRequired,
        eventDescription: pt.string.isRequired,
        eventId: pt.string.isRequired,
        ipAddress: pt.string.isRequired,
        orgId: pt.string.isRequired,
        orgName: pt.string,
        user: pt.string.isRequired,
      }))
    }),
    getAudits: pt.func.isRequired,
    changePage: pt.func.isRequired,
    storeAudits: pt.func.isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
  }

  state = {
    isLoading: false,
    refreshing: false,
    allPagesFulfilled: false,
  }

  componentDidMount() {
    const { audits } = this.props;
    if (!audits.data) {
      this.getAudits();
    }
  }

  getAudits = async () => {
    const { allPagesFulfilled } = this.props;
    const { getAudits, storeAudits, signalError, clearError } = this.props;
    if (allPagesFulfilled) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      const key = this.getPaginationKey();
      const results = await getAudits(key.id, key.date);
      if (results.success && results.logs.Items.length) {
        let key;
        if (results.logs.LastEvaluatedKey) {
          key = { id: results.logs.LastEvaluatedKey.eventId, date: results.logs.LastEvaluatedKey.eventDate };
        } else {
          this.setState({ allPagesFulfilled: true });
        }
        storeAudits(results.logs.Items, results.logs.Count, key);
      } else if (!results.success) {
        throw new Error("Error: Pleas try again later");
      }
    } catch (e) {
      const msg = e && e.message || "Error: Please try again later.";
      signalError(msg);
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  getPaginationKey = () => {
    const { audits } = this.props;
    if (!audits.key) {
      return {};
    }

    let index = calculatePageIndex(audits.page + 1, 3);

    try {
      const key = audits.key[index];
      return key;
    } catch (e) {
      return {};
    }
  }

  formatRow = (row) => {
    const { classes } = this.props;
    return {
      eventDate: (
        <div>
          <div>
            <Tooltip id="tooltip-icon" title={moment(row.eventDate).format("MMMM Do YYYY [at] h:mm a")} placement="top" enterDelay={250} classes={{ tooltip: this.props.classes.tooltip }}>
              <p>{`${moment.duration(moment().diff(moment(row.eventDate))).humanize()} ago`}</p>
            </Tooltip>
          </div>
          <div className={classes.label}>Date</div>
        </div>
      ),
      user: (
        <div>
          <div>{row.user}</div>
          <div className={classes.label}>User</div>
        </div>
      ),
      orgName: (
        <div>
          <div>{row.orgName}</div>
          <div className={classes.label}>Organization</div>
        </div>
      ),
      eventDescription: (
        <div>
          <div>{row.eventDescription}</div>
          <div className={classes.label}>Description</div>
        </div>
      ),
      PHI: row.PHI && <Button round size="sm">PHI</Button>
    };
  }

  render() {
    const { refreshing, isLoading, allPagesFulfilled } = this.state;
    const { audits } = this.props;
    return (
      <div>
        <GridContainer>
          <ItemGrid xs={12}>
            <HeaderCard
              headerColor="orange"
              cardTitle="All Organization's Activities"
              cardSubtitle="History of all activity across all organizations"
              content={
                <div style={{ padding: "0 40px" }}>
                  <ReactTable
                    defaultSorted={[
                      {
                        id: "eventDate",
                        desc: false,
                      }
                    ]}
                    noDataText="No History"
                    data={audits.data && audits.data.map(this.formatRow) || []}
                    columns={[
                      {
                        name: "eventDate",
                        maxWidth: 200,
                      },
                      {
                        name: "orgName",
                        maxWidth: 250,
                      },
                      {
                        name: "user",
                        maxWidth: 250,
                      },
                      {
                        name: "eventDescription",
                        minWidth: 400,
                      },
                      {
                        name: "PHI",
                        maxWidth: 200,
                      }]
                      .map(c => ({ Header: "", accessor: c.name, maxWidth: c.maxWidth }))
                    }
                    sortable={false}
                    resizable={false}
                    className="-highlight"
                    refreshing={refreshing}
                    loading={isLoading}
                    page={audits.page}
                    defaultPageSize={10}
                    onPageChange={this.props.changePage}
                    onGetMore={this.getAudits}
                    allPagesFulfilled={allPagesFulfilled}
                    PaginationComponent={Pagination}
                    canGetMorePages
                    showPaginationBottom
                  />
                </div>
              }
            />
          </ItemGrid>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  audits: state.audits,
});

const mapDispatchToProps = (dispatch) => ({
  storeAudits: (audits, count, key) => dispatch(storeOrgsAudits(audits, count, key)),
  changePage: (page) => dispatch(changeOrgsAuditsPage(page)),

  // No dispatch, useful for injecting testing mocks. See __tests__
  getAudits: (id, date) => getAllAuditLogs(id, date),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithNotifications(withStyles(styles)(Audit)));
