import React from "react";
import pt from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { getWebPreviewToken } from "libs/apiLib";
import { getSignedUrl, downloadFileFromS3 } from "libs/awsLib";
import { userSelector } from "selectors";
import AIRRWarnings from "./AIRRWarnings";
import { UInt8ArrToString } from "libs/utils";
import { WithNotifications } from "components/HigherOrder";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import IconCard from "components/Cards/IconCard";
import IconPuzzle from "material-ui-icons/Extension";
import Table from "components/Table/Table";
import Badge from "components/Badge/Badge";
import Button from "components/CustomButtons/Button";
import CustomDropdownSmall from "components/CustomDropdown/CustomDropdownSmall";
import Modal from "components/Modal/Code";
import LoadingModal from "components/LoadingModal/LoadingModal";

export class ProstateGenetic extends React.Component {
  static propTypes = {
    user: pt.shape({
      isHLXSupport: pt.bool.isRequired,
    }).isRequired,
    getWebPreviewToken: pt.func.isRequired,
    getSignedUrl: pt.func.isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    result: pt.shape({
      jobStatus: pt.oneOf(["Finished Processing", "Running", "Failed"]).isRequired,
      received: pt.string.isRequired,
      eventId: pt.string.isRequired,
      jobDir: pt.string.isRequired,
      inputDir: pt.string.isRequired,
      outputDir: pt.string.isRequired,
    }).isRequired,
    appId: pt.string.isRequired,
    patient: pt.shape({
      firstName: pt.string,
      lastName: pt.string,
      dob: pt.string,
      sex: pt.string,
    }).isRequired,
    classes: pt.shape({
      footer: pt.string.isRequired,
      report: pt.string.isRequired,
      highlightTitle: pt.string.isRequired,
      bottomMargin: pt.string.isRequired,
      sectionBorder: pt.string.isRequired,
      textCenter: pt.string.isRequired,
    }).isRequired,
    today: pt.instanceOf(moment),
    downloadFileFromS3: pt.func.isRequired
  }

  constructor(props) {
    super(props);
    const { patient } = props;
    this.today = props.today || moment();
    this.patientAge = Math.abs(moment(Date.parse(patient.dob)).diff(this.today, "years"));
    this.patient = patient;
    this.state = {
      modal: null,
      token: null,
      loading: false,
      pdfUrl: null,
      config: null,
      appResults: null,
      logs: null
    };
  }

  async componentDidMount() {
    try {
      if (this.props.result.jobStatus !== "Finished Processing") {
        return;
      }
      const config = await this.getAppConfig(this.props.result);
      await this.getPDF(this.props.result.outputDir, config);
      await this.getJSON(this.props.result.outputDir, config);
    } catch (error) {
      this.props.signalError("Error processing Prostate Cancer GeneticRisk report. Please contact support@healthlytix.com");
      setTimeout(this.props.clearError, 5000);
    }
  }

  async download(key) {
    const token = await this.getS3Token();
    const resp = await this.props.downloadFileFromS3(key, token);
    if (!resp || !resp.Body) {
      throw new Error("Failed to download file from S3");
    }
    return resp.Body;
  }

  getAppConfig = async (job) => {
    if (this.state.config) {
      return this.state.config;
    }
    try {
      const configResp = await this.download(`${job.inputDir}/config.json`);
      const config = JSON.parse(configResp.toString());
      this.setState({ config });
      return config;
    } catch (error) {
      throw new Error(error);
    }
  }

  getPDF = async (outputDir, config) => {
    try {
      const pdfConfig = config.output.find(c => c.type === "pdf");
      if (!pdfConfig || !pdfConfig.filename) {
        throw new Error("Invalid input data. No pdf config exists within the output config JSON.");
      }
      const url = await this.getPDFDownloadURL(`${outputDir}/${pdfConfig.filename}`);
      this.setState({ pdfUrl: url });
    } catch (error) {
      throw new Error(error);
    }
  }

  getJSON = async (outputDir, config) => {
    try {
      const resultsConfig = config.output.find(c => c.type === "json");
      if (!resultsConfig || !resultsConfig.filename) {
        throw new Error("Invalid output data. No JSON output config exists within the output config JSON.");
      }
      const jsonResp = await this.download(`${outputDir}/${resultsConfig.filename}`);
      const json = JSON.parse(jsonResp.toString());
      this.setState(prevState => ({
        ...prevState,
        appResults: json
      }));
    } catch (error) {
      throw new Error(error);
    }
  }

  async getS3Token() {
    const { clearError, signalError, getWebPreviewToken, patient: { orgId } } = this.props;
    this.setState({ loading: true });
    let token;
    try {
      let results;
      if (!this.state.token) {
        results = await getWebPreviewToken(orgId);
        if (results && results.success && results.token) {
          token = results.token;
          this.setState({ token });
        } else {
          throw new Error("Couldn't not get token to download PDF");
        }
      } else {
        token = this.state.token;
      }
    } catch (e) {
      signalError("Error getting PDF reports. Please contact support@healthlytix.com");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
    return token;
  }

  getPDFDownloadURL = async (key) => {
    const token = await this.getS3Token();
    const url = this.props.getSignedUrl(token, key, {
      ResponseContentDisposition: "attachment",
      ResponseContentType: "application/pdf"
    });
    return url;
  }

  statusBadge = (status) => {
    let color = "primary";
    switch (status) {
    case "Running":
      break;
    case "Finished Processing":
      color = "success";
      break;
    case "Failed":
      color = "danger";
      break;
    case "Queued":
      color = "info";
      break;
    default:
      return "N/A";
    }
    return <Badge color={color}>{status}</Badge>;
  }

  reportStatusButton = () => {
    const { result } = this.props;
    const { pdfUrl } = this.state;
    if (result.jobStatus !== "Finished Processing" || !pdfUrl) {
      return "";
    }
    return <Button href={pdfUrl} size="sm" color="success">Download</Button>;
  }

  getAppLogs = async () => {
    try {
      if (this.state.logs) {
        this.showAppLogModal(this.state.logs);
        return;
      }
      this.setState({ loading: true, modal: <LoadingModal/> });
      const { result } = this.props;
      let resp = await this.download(`${result.outputDir}/app.log`);
      const logs = await UInt8ArrToString(resp);
      this.showAppLogModal(logs);
    } catch (e) {
      this.props.signalError("Failed to retrieve app logs. The application may still be processing or it may have failed before the logs were created.");
      this.props.clearError(5000);
      this.setState({ modal: null });
    }
    this.setState({ loading: false });
  }

  showAppLogModal(logs) {
    this.setState({
      logs,
      modal: logs ? (
        <Modal
          title={<h2>App Logs</h2>}
          value={logs}
          style={{
            height: 900,
            width: 800,
            overflow: "hidden",
            overflowY: "scroll",
          }}
          footerRender={() => (
            <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
              <Button onClick={this.removeModal}>Close</Button>
            </ItemGrid>
          )}
        />
      ) : null
    });
  }

  geneticInputData = () => {
    const { config } = this.state;
    if (!config || !config.input || !config.input.length) {
      return "N/A";
    }
    const geneticFile = config.input.find(i => i.type === "genotype");
    if (!geneticFile) {
      return "N/A";
    }
    return geneticFile.filename;
  }

  handleCaseAction = async (action) => {
    switch (action) {
    case "App Logs":
      this.getAppLogs();
      break;
    case "View Report Details":
      this.handleViewReportDetails();
      break;
    case "Regenerate PDF Report":
      // TODO:
      break;
    default:
      break;
    }
  }

  removeModal = () => this.setState({ modal: null });

  handleViewReportDetails = () => {
    if (!this.props.result) {
      return;
    }
    this.setState({
      modal: (
        <Modal
          title={<h2>Report Details</h2>}
          value={JSON.stringify(this.props.result, undefined, "\t")}
          style={{
            height: 900,
            width: 800,
            overflow: "hidden",
            overflowY: "scroll",
          }}
          footerRender={() => (
            <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
              <Button onClick={this.removeModal}>Close</Button>
            </ItemGrid>
          )}
        />
      )
    });
  }

  showOptions() {
    if (!this.props.user.isHLXSupport) {
      return null;
    }
    return (
      <ItemGrid xs={12} md={2} container justify="flex-end" alignItems="center">
        <CustomDropdownSmall
          buttonText="Options"
          dropdownList={[
            "App Logs",
            "View Report Details"
          ]}
          onAction={(action) => this.handleCaseAction(action)}
        />
      </ItemGrid>
    );
  }

  renderWarnings () {
    const { appResults } = this.state;
    if (!appResults) {
      return null;
    }
    return (
      <ItemGrid xs={12} lg={6}>
        <AIRRWarnings report={appResults} appError={appResults.error} />
      </ItemGrid>
    );
  }
  
  render() {
    const { result } = this.props;
    return (
      <GridContainer justify="center">
        {this.state.modal}
        {this.renderWarnings()}
        <IconCard
          iconColor="green"
          icon={IconPuzzle}
          title="Prostate Cancer GeneticRisk Report"
          content={
            <GridContainer>
              <ItemGrid xs={12} md={10}>
                <h2 style={{ display: "inline-block" }}>Application Details</h2>
              </ItemGrid>
              {this.showOptions()}
              <ItemGrid xs={12}>
                <Table
                  tableData={[
                    ["Status", this.statusBadge(result.jobStatus)],
                    ["Genetics Input Record Used", this.geneticInputData()],
                    ["Submitted", moment(Date.parse(result.received)).format("MM-DD-YYYY")],
                    ["HealthLytix Patient ID", result.patientId],
                    ["Event ID", result.eventId],
                    ["GeneticRisk PDF Report", this.reportStatusButton()],
                  ]}
                />
              </ItemGrid>
            </GridContainer>
          }
        />
      </GridContainer>
    );
  }
}

export default connect((state) => ({
  user: userSelector(state)
}), () => ({
  getSignedUrl,
  getWebPreviewToken,
  downloadFileFromS3
}))(WithNotifications(ProstateGenetic));