import pt from "prop-types"
import { connect } from "react-redux"
import {
	storeNotificationError, removeNotificationError,
	storeNotificationInfo, removeNotificationInfo,
} from "actions/index"

const WithNotifications = (WrappedComponent) => {
	WrappedComponent.propTypes = {
		signalError: pt.func,
		clearError: pt.func,
		signalInfo: pt.func,
		clearInfo: pt.func,
	}
	const mapDispatchToProps = (dispatch) => ({
		signalError: msg => dispatch(storeNotificationError(msg)),
		clearError: () => dispatch(removeNotificationError()),
		signalInfo: msg => dispatch(storeNotificationInfo(msg)),
		clearInfo: () => dispatch(removeNotificationInfo()),
	})
	return connect(undefined, mapDispatchToProps)(WrappedComponent)
}

export default WithNotifications