import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Tooltip from "material-ui/Tooltip";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Pagination from "components/Pagination/ReactTablePagination";
import ReactTable from "react-table";
import Modal from "components/Modal";
import Badge from "components/Badge/Badge";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";

// util, api, & Redux action funcs
import { getOrgAuditLogs } from "libs/apiLib";
import { storeAudits, changeAuditsPage } from "actions/index";
import { calculatePageIndex } from "libs/utils";

const styles = {
  ...dashboardStyle,
  ...tooltip,
  modalLabel: {
    fontSize: "small",
    color: "gray",
  },
  modalSection: {
    marginTop: 30,
    padding: "0 40px !important"
  }
};

export class Audit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoRefresh: false,
      timer: null,
      pageSize: 10,
      isLoading: false,
      orgID: null,
      refreshing: false,
      error: "",
      modal: null,
    };
  }

  componentDidMount() {
    const { location, audits, user } = this.props;

    const urlParams = new URLSearchParams(location.search);
    const orgID = urlParams.get("orgId");
    if (orgID && !this.state.orgID) {
      this.setState({ orgID });
    }

    // fetch initial audits if this is first mount since we're storing the audit data in Redux
    if (orgID && !audits[orgID]) {
      this.fetchAudits(orgID);
    } else if (user.orgId && !audits[user.orgId]) {
      this.fetchAudits(user.orgId);
    }
  }

  componentDidUpdate(_, prevState) {
    const urlParams = new URLSearchParams(this.props.location.search);
    const urlOrgID = urlParams.get("orgId");

    if (urlOrgID && !this.state.orgID) {
      // if moving to org dash add orgID to state
      this.setState({ orgID: urlOrgID });
    } else if (prevState.orgID && !urlOrgID) {
      // if moving from org dash to user dash get rid of orgID
      this.setState({ orgID: null });
    }
  }

  fetchAudits = async (orgID, refresh) => {
    const { getOrgAudits, storeAudits } = this.props;
    const id = orgID || this.getOrgID();
    this.setState({ isLoading: true });
    const key = this.getPaginationKey(refresh);
    const date = key && key.date;
    const pageID = key && key.id;

    try {
      const results = await getOrgAudits(id, pageID, date);
      if (results.logs.Items.length) {
        const page = this.getPage();
        if (page + 1 === this.getPages()) {
          this.changePage(page + 1);
        }
        let k = null;
        if (
          results.logs.LastEvaluatedKey &&
          results.logs.LastEvaluatedKey.eventId &&
          results.logs.LastEvaluatedKey.eventDate
        ) {
          k = { id: results.logs.LastEvaluatedKey.eventId, date: results.logs.LastEvaluatedKey.eventDate };
        }
        const count = results.logs.Count || results.logs.Items.length;
        storeAudits(results.logs.Items, count, k, id);
      }
    } catch (e) {
      this.setState({ error: e.message });
    }

    this.setState({ isLoading: false, refreshing: false });
  }

  getPaginationKey = (refresh) => {
    const { audits } = this.props;
    const id = this.getOrgID();
    if (!audits[id] || !audits[id].key) {
      return "";
    }

    const page = this.getPage() + 1;
    let index = calculatePageIndex(page, 3);
    if (!refresh) {
      index++;
    }

    try {
      const key = audits[id].key[index];
      return key;
    } catch (e) {
      return "";
    }
  }

  getOrgID = () => {
    const { user } = this.props;
    const { orgID } = this.state;
    return orgID || user.orgId;
  }

  getPage = () => {
    const id = this.getOrgID();
    const { audits } = this.props;
    return audits[id] ? audits[id].page : 0;
  }

  getAudits = () => {
    const { audits } = this.props;
    const id = this.getOrgID();
    if (!audits[id]) {
      return [];
    }
    return audits[id].data;
  }

  handleAuditClick = (audit) => {
    const { classes: { modalLabel, modalSection } } = this.props;
    this.setState({
      modal: (
        <Modal
          style={{
            height: 800,
            width: 700,
          }}
        >
          <GridContainer>
            <ItemGrid xs={12} className={modalSection}>
              <h2 style={{ textAlign: "center" }}>Audit</h2>
              <h4>{moment(audit.eventDate).format("dddd, MMMM Do YYYY")}</h4>
              <p className={modalLabel}>Date</p>
            </ItemGrid>
            <ItemGrid xs={12} className={modalSection}>
              <h4>{audit.orgName}</h4>
              <p className={modalLabel}>Organization</p>
            </ItemGrid>
            <ItemGrid xs={12} className={modalSection}>
              <h4>{audit.user}</h4>
              <p className={modalLabel}>User</p>
            </ItemGrid>
            <ItemGrid xs={12} className={modalSection}>
              <h4>{audit.eventType}</h4>
              <p className={modalLabel}>Type</p>
            </ItemGrid>
            {
              audit.PHI &&
              <ItemGrid xs={12} className={modalSection}>
                <Badge color="primary">PHI</Badge>
                <p className={modalLabel} style={{ marginTop: 10 }}>Related To</p>
              </ItemGrid>
            }
            <ItemGrid xs={12} className={modalSection}>
              <p>{audit.eventDescription}</p>
              <p className={modalLabel}>Description</p>
            </ItemGrid>
            <ItemGrid container justify="center" xs={12} style={{ position: "absolute", bottom: "15px" }}>
              <Button onClick={() => this.setState({ modal: null })}>Close</Button>
            </ItemGrid>
          </GridContainer>
        </Modal>
      )
    });
  }

  formatRow = (row) => {
    const defaultStyle = {
      fontSize: "x-small",
      color: "gray",
      marginTop: "-5px"
    };
    return {
      eventDate: (
        <div>
          <div>
            <Tooltip id="tooltip-icon" title={moment(row.eventDate).format("MMMM Do YYYY [at] h:mm a")} placement="top" enterDelay={250} classes={{ tooltip: this.props.classes.tooltip }}>
              <p>{`${moment.duration(moment().diff(moment(row.eventDate))).humanize()} ago`}</p>
            </Tooltip>
          </div>
          <div style={defaultStyle}>Date</div>
        </div>
      ),
      user: (
        <div>
          <div>{row.user}</div>
          <div style={defaultStyle}>User</div>
        </div>
      ),
      eventDescription: (
        <div>
          <div><a style={{ cursor: "pointer" }} onClick={() => this.handleAuditClick(row)}>{this.cutLongString(row.eventDescription, 150)}</a></div>
          <div style={defaultStyle}>Description</div>
        </div>
      ),
      PHI: row.PHI && <Button round size="sm">PHI</Button>
    };
  }

  cutLongString = (str, length) => str.length > length ? `${str.slice(0, length)} ...` : str

  getPages = (audits) => {
    if (!audits) {
      audits = this.getAudits();
    }
    if (!audits.length) { return 0; }
    return Math.floor(audits.length / 10);
  }

  changePage = (page) => this.props.changeAuditsPage(page, this.getOrgID())

  render() {
    const { pageSize, refreshing, isLoading, modal } = this.state;
    const page = this.getPage();
    const audits = this.getAudits();
    const pages = this.getPages(audits);
    return (
      <GridContainer>
        <ItemGrid xs={12} sm={12} md={12} lg={12}>
          <HeaderCard
            headerColor="orange"
            cardTitle="My Organization's Activities"
            cardSubtitle="All the history of your organization's activities"
            content={
              <div style={{ padding: "0 40px" }}>
                {modal}
                <ReactTable
                  defaultSorted={[
                    {
                      id: "eventDate",
                      desc: false
                    }
                  ]}
                  noDataText="No History"
                  data={audits.map(this.formatRow)}
                  columns={[
                    {
                      name: "eventDate",
                      maxWidth: 200,
                    },
                    {
                      name: "user",
                      maxWidth: 250,
                    },
                    {
                      name: "eventDescription",
                      minWidth: 400,
                    },
                    {
                      name: "PHI",
                      maxWidth: 200,
                    }]
                    .map(c => ({ Header: "", accessor: c.name, maxWidth: c.maxWidth }))
                  }
                  sortable={false}
                  resizable={false}
                  className="-highlight"
                  refreshing={refreshing}
                  loading={isLoading}
                  page={page}
                  pages={pages}
                  pageSize={pageSize}
                  showPaginationBottom
                  PaginationComponent={Pagination}
                  onGetMore={this.fetchAudits}
                  onPageChange={this.changePage}
                  canGetMorePages
                />
              </div>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

Audit.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.shape({
    orgId: PropTypes.string.isRequired
  }).isRequired,
  audits: PropTypes.shape({
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  changeAuditsPage: PropTypes.func.isRequired,
  storeAudits: PropTypes.func.isRequired,
  getOrgAudits: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  audits: state.audits
});

const mapDispatchToProps = (dispatch) => ({
  // dispatch
  storeAudits: (audits, count, key, orgID) => dispatch(storeAudits(audits, count, key, orgID)),
  changeAuditsPage: (page, orgID) => dispatch(changeAuditsPage(page, orgID)),

  // No dispatch, useful for injecting testing mocks. See __tests__
  getOrgAudits: (orgID, id, date) => getOrgAuditLogs(orgID, id, date),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Audit));
