import React from "react";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputLabel from "material-ui/Input/InputLabel";
import FormControl from "material-ui/Form/FormControl";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";

// core 
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyleModal.jsx";

// utils
import { getRandomInt } from "libs/utils";

const style = {
    ...sweetAlertStyle,
    ...customSelectStyle
}

class ManageApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            app:null,
            disableAppId: false,
            appId: "",
            name: "",
            description: "",
            type: "",
            aeTitle: "",
            cloudEnv: "",
            edgeEnv: ""
        }
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleType = this.handleType.bind(this);
        this.handleCloudEnv = this.handleCloudEnv.bind(this);
        this.handleEdgeEnv = this.handleEdgeEnv.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        if(this.props.app) {
            this.setState({
                app: this.props.app,
                disableAppId: true,
                appId: this.props.app.appId,
                name: this.props.app.name,
                description: this.props.app.description,
                type: this.props.app.type,
                cloudEnv: this.props.app.cloud ? this.props.app.cloud.type : "",
                edgeEnv: this.props.app.edge ? this.props.app.edge.type : "",
                aeTitle: this.props.app.aeTitle ? this.props.app.aeTitle : ""
            });
        }
    }

    handleConfirm = () => {
        this.props.onSave(this.state);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    handleType = (event, index, value) => {
        this.setState({
            type: event.target.value
        });
    }

    handleCloudEnv = (event, index, value) => {
        this.setState({
            cloudEnv: event.target.value
        });
    }

    handleEdgeEnv = (event, index, value) => {
        this.setState({
            edgeEnv: event.target.value
        });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    validateForm() {
        return (
            this.state.appId.length > 0 &&
            this.state.description.length > 0 &&
            this.state.name.length > 0 &&
            this.state.type.length > 0 &&
            this.state.cloudEnv.length > 0
        );
    }

    handleGenerateAppId = () => {
        this.setState({
            appId: `appId-${getRandomInt(10000, 99999)}`
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <SweetAlert
                style={{ 
                    maxHeight: "900px",
                    maxWidth: "600px",
                    width: "90%",
                    margin: "0",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }}
                title="Manage App"
                onConfirm={() => this.handleConfirm()}
                onCancel={() => this.handleCancel()}
                showConfirm={false}
                confirmBtnCssClass={
                    classes.button + " " + classes.success
                }
                cancelBtnCssClass={
                    classes.button + " " + classes.danger
                }
            >
                <div>
                    <p>Add or manage HealthLytix's apps here</p>
                    <form style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                        <CustomInput
                            labelText="App Id"
                            id="appId"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: this.state.disableAppId,
                                id: "appId",
                                value: this.state.appId,
                                onChange: this.handleChange
                            }}
                        />
                        {!this.state.disableAppId &&
                        <div>
                            <Button color="primary" 
                                    style={{ textAlign: "left" }} 
                                    onClick={this.handleGenerateAppId}>Generate AppId</Button>
                        </div>}
                        <CustomInput
                            labelText="Name of App"
                            id="name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                id: "name",
                                value: this.state.name,
                                onChange: this.handleChange
                            }}
                        />
                        <CustomInput
                            labelText="Description"
                            id="description"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                id: "description",
                                value: this.state.description,
                                onChange: this.handleChange
                            }}
                        />
                        <FormControl fullWidth className={classes.selectFormControl + " " + "dialog-dropdown"}>
                            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                App Type
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu + " " + "dialog-dropdown"
                                }}
                                classes={{
                                    select: classes.select
                                }}
                                onChange={this.handleType}
                                inputProps={{
                                    name: "type",
                                    id: "type",
                                    value: this.state.type
                                }}
                            >
                                <MenuItem value="Cloud"
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}>{`Cloud`}</MenuItem>
                                <MenuItem value={`Cloud & Edge`}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}>{`Cloud & Edge`}</MenuItem>
                                <MenuItem value="Edge"
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}>{`Edge`}</MenuItem>
                            </Select>
                        </FormControl>
                        <CustomInput
                            labelText="AE Title"
                            id="aeTitle"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                id: "aeTitle",
                                value: this.state.aeTitle,
                                onChange: this.handleChange
                            }}
                        />
                        <FormControl fullWidth className={classes.selectFormControl + " " + "dialog-dropdown"}>
                            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                Cloud Environment
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu + " " + "dialog-dropdown"
                                }}
                                classes={{
                                    select: classes.select
                                }}
                                onChange={this.handleCloudEnv}
                                inputProps={{
                                    name: "cloudEnv",
                                    id: "cloudEnv",
                                    value: this.state.cloudEnv
                                }}
                            >
                                <MenuItem value="Docker-CPU"
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}>{`Docker-CPU`}</MenuItem>
                                <MenuItem value={`Docker-GPU`}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}>{`Docker-GPU`}</MenuItem>
                                <MenuItem value="Lambda"
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}>{`Lambda`}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.selectFormControl + " " + "dialog-dropdown"}>
                            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                Edge Environment
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu + " " + "dialog-dropdown"
                                }}
                                classes={{
                                    select: classes.select
                                }}
                                onChange={this.handleEdgeEnv}
                                inputProps={{
                                    name: "edgeEnv",
                                    id: "egdeEnv",
                                    value: this.state.edgeEnv
                                }}
                            >
                                <MenuItem value="CPU"
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}>{`CPU`}</MenuItem>
                                <MenuItem value={`GPU`}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}>{`GPU`}</MenuItem>
                            </Select>
                        </FormControl>
                        <div style={{ marginTop: "40px" }}>
                            <Button color="danger" style={{ marginRight: "10px" }} onClick={this.handleCancel}>Cancel</Button>
                            <Button color="success"
                                onClick={() => this.handleConfirm()}
                                disabled={!this.validateForm()}>Save</Button>
                        </div>
                    </form>
                </div>
            </SweetAlert>
        )
    }
}

export default withStyles(style)(ManageApp);