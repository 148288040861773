import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// react table
import ReactTable from "react-table";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import LoadingModal from "components/LoadingModal/LoadingModal.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// local components
import InviteOrg from "./components/InviteOrg";
import { assumeOrg } from "actions/index";

// api
import { getAllOrgs, getAllInvites, inviteOrg, getAvailableApps } from "libs/apiLib";

const style = {
  ...dashboardStyle,
  ...buttonsStyle,
  ...sweetAlertStyle
};

class OrgsUsers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      apps: null,
      invites: null,
      modal: null,
      orgs: null,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleRefreshInvites = this.handleRefreshInvites.bind(this);
    this.userAction = this.userAction.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleInviteOrg = this.handleInviteOrg.bind(this);
    this.inviteOrg = this.inviteOrg.bind(this);
    this.showError = this.showError.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.retrieveInvites = this.retrieveInvites.bind(this);
    this.retrieveOrg = this.retrieveOrg.bind(this);
    this.showLoading = this.showLoading.bind(this);
    this.getAvailableApps = this.getAvailableApps.bind(this);
  }

  async componentDidMount() {
    this.showLoading();

    try {
      await this.retrieveOrg();
      await this.retrieveInvites();
      await this.getAvailableApps();
    } catch (e) {
    }

    this.hideModal();
  }

  showLoading() {
    this.setState({
      modal: <LoadingModal />
    });
  }

  handleRefresh = async () => {
    this.showLoading();
    await this.retrieveOrg();
    this.hideModal();
  }
  handleRefreshInvites = async () => {
    this.showLoading();
    await this.retrieveInvites();
    this.hideModal();
  }

  hideModal() {
    this.setState({
      modal: null
    });
  }

  async retrieveOrg() {
    const result = await getAllOrgs();
    if (result.success) { this.setState({ orgs: result.orgs.Items }); }
  }

  async retrieveInvites() {
    const result = await getAllInvites();
    if (result.success) { this.setState({ invites: result.invites.Items }); }
  }

  async userAction(org, action) {
    switch (action) {
    case "Licenses":
      this.props.history.push({
        pathname: `/admin/orgs/${org.orgId}/alm`,
        state: {
          apps: this.state.apps,
          org: this.state.orgs.find(o => o.orgId === org.orgId),
          orgs: this.state.orgs
        }
      });
      break;
    case "Devices":
      this.props.history.push({
        pathname: "/edge/devices",
        search: `?orgId=${org.orgId}`
      });
      break;
    case "Users":
      this.props.history.push({
        pathname: "/myorg/users",
        search: `?orgId=${org.orgId}`
      });
      break;
    case "Dashboard":
      this.props.history.push({
        pathname: "/dashboard",
        search: `?orgId=${org.orgId}`
      });
      break;
    case "Audits":
      this.props.history.push({
        pathname: "/myorg/audit",
        search: `?orgId=${org.orgId}`
      });
      break;
    case "Insights":
      this.props.history.push({
        pathname: "/myorg/insights",
        search: `?orgId=${org.orgId}`
      });
      break;
    case "Invoices":
      this.props.history.push({
        pathname: "/myorg/invoices",
        search: `?orgId=${org.orgId}`
      });
      break;
    case "Apps":
      this.props.history.push({
        pathname: "/myapps",
        search: `?orgId=${org.orgId}`
      });
      break;
    case "Genetic Kits":
      this.props.history.push({
        pathname: "/kits",
        search: `?orgId=${org.orgId}`
      });
      break;
    case "Developers":
      this.props.history.push({
        pathname: "/developers",
        search: `?orgId=${org.orgId}`
      });
      break;
    case "Settings":
      this.props.history.push({
        pathname: "/myorg/settings",
        search: `?orgId=${org.orgId}`
      });
      break;
    default:
      break;
    }
    this.props.assumeOrg(org);
  }

  handleOrgLinkClick = (org) => {
    this.props.history.push({
      pathname: "/dashboard",
      search: `?orgId=${org.orgId}`
    });
    this.props.assumeOrg(org);
  }

  // TODO: implement this
  async inviteAction(user, action) {
    switch (action) {
    case "Re-send Invite":
      break;
    case "Delete":
      break;
    default:
      break;
    }
  }

  // handleUpdateSuperAdminAccess = async (user) => {
  //   this.setState({
  //     modal: <LoadingModal />
  //   });

  //   try {

  //   } catch (e) {
  //     console.error(e);
  //   }

  //   this.hideModal();

  // }

  handleInviteOrg = () => {
    this.setState({
      modal: <InviteOrg
        onCancel={this.hideModal}
        onInvite={(email, orgName) => this.inviteOrg(email, orgName)}
      />
    });
  }

  async inviteOrg(email, orgName) {
    this.showLoading();
    try {
      await inviteOrg(email, orgName);
      this.showSuccess("Org Invited!");
    } catch (e) {
      this.showError("Failed to invite the organization");
    }

  }

  showSuccess(msg) {
    this.setState({
      modal: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Done!"
          onConfirm={() => this.hideModal()}
          onCancel={() => this.hideModal()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div>
            {msg}
          </div>
          <div style={{ marginTop: "40px" }}>
            <Button color="success" onClick={() => this.hideModal()}>Done</Button>
          </div>
        </SweetAlert>
      )
    });
  }

  showError(err) {
    this.setState({
      modal: (
        <SweetAlert
          type="error"
          style={{ display: "block", marginTop: "-100px" }}
          title="Ohh No!"
          onConfirm={() => this.hideModal()}
          onCancel={() => this.hideModal()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div>
            {err}
          </div>
          <div style={{ marginTop: "40px" }}>
            <Button onClick={() => this.hideModal()}>Close</Button>
          </div>
        </SweetAlert>
      )
    });
  }

  getAvailableApps = async () => {
    try {
      const result = await getAvailableApps();
      if (result.success) { this.setState({ apps: result.result.Items }); }
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    let tableData = [];

    if (this.state.orgs) {
      tableData = this.state.orgs.map((prop) => {

        let actions = [
          "Dashboard",
          "Devices",
          "Developers",
          "Licenses",
          "Users",
          "Audits",
          "Insights",
          "Invoices",
          // "Apps", // hide /myapps for now
          "Genetic Kits",
          "Settings"
        ];

        return ({
          options: (
            <div style={{ textAlign: "right", paddingRight: "10px" }}>
              <CustomDropdown
                buttonText="Manage"
                buttonColor="primary"
                dropdownList={actions}
                onAction={(action) => this.userAction(prop, action)}
              />
            </div>
          ),
          org: (
            <div>
              <div>
                <p style={{ cursor: "pointer", color: "#00ACEF" }} onClick={this.handleOrgLinkClick.bind(this, prop)}>{prop.orgName}</p>
              </div>
              <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                {prop.orgId}
              </div>
            </div>
          ),
          owner: (
            <div>
              <div>
                {prop.accountOwner ? prop.accountOwner : "No Owner"}
              </div>
              <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                Account Owner
  						</div>
            </div>
          ),
        });
      });
    }

    let inviteTableData = [];

    if (this.state.invites) {
      inviteTableData = this.state.invites.map((prop) => {

        let actions = [
          "Re-send Invite",
          "Delete"
        ];

        return ({
          user: (
            <div>
              <div>
                {prop.email}
              </div>
              <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                User's Email
  						</div>
            </div>
          ),
          org: (
            <div>
              <div>
                {prop.orgName}
              </div>
              <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                {prop.orgId ? "Invited by organization" : "Pending organization creation"}
              </div>
            </div>
          ),
          options: (
            <div style={{ textAlign: "right", paddingRight: "10px" }}>
              <CustomDropdown
                buttonText="Manage"
                buttonColor="primary"
                dropdownList={actions}
                onAction={(action) => this.userAction(prop, action)}
              />
            </div>
          )
        });
      });
    }


    return (
      <div>
        <GridContainer>
          {this.state.modal}
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              headerColor="orange"
              cardTitle="Organizations"
              cardSubtitle="All organizations in the HealthLytix Platform"
              content={
                tableData &&
                <div>
                  <ItemGrid xs={12}>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        onClick={this.handleRefresh}
                        style={{ marginRight: "10px" }}
                      >Refresh</Button>
                      <Button
                        onClick={this.handleInviteOrg}
                        color="success"
                      >Invite</Button>
                    </div>
                  </ItemGrid>
                  <ReactTable
                    sortable={false}
                    data={tableData}
                    noDataText="No Users!"
                    columns={[
                      {
                        Header: "",
                        accessor: "org"
                      },
                      {
                        Header: "",
                        accessor: "owner"
                      },
                      {
                        Header: "",
                        accessor: "options"
                      }
                    ]}
                    minRows={2}
                    defaultPageSize={10}
                    showPaginationBottom
                    className="-highlight"
                  />
                </div>}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              headerColor="purple"
              cardTitle="Invites"
              cardSubtitle="Open Invites to the Platform"
              content={
                inviteTableData &&
                <div>
                  <ItemGrid xs={12}>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        onClick={this.handleRefreshInvites}
                        style={{ marginRight: "10px" }}
                      >Refresh</Button>
                    </div>
                  </ItemGrid>
                  <ReactTable
                    sortable={false}
                    data={inviteTableData}
                    noDataText="No Invites!"
                    columns={[
                      {
                        Header: "",
                        accessor: "user"
                      },
                      {
                        Header: "",
                        accessor: "org"
                      },
                      {
                        Header: "",
                        accessor: "options"
                      }
                    ]}
                    minRows={2}
                    defaultPageSize={10}
                    showPaginationBottom
                    className="-highlight"
                  />
                </div>}
            />
          </ItemGrid>
        </GridContainer>
      </div>
    );
  }
}

OrgsUsers.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    success: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  org: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  assumeOrg: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  org: state.user.org,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  assumeOrg: (org) => dispatch(assumeOrg(org))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(style)(OrgsUsers)));
