import React from "react";
import PropTypes from "prop-types";
import Chartist from "react-chartist";
import IconCard from "components/Cards/IconCard.jsx";
import Badge from "components/Badge/Badge.jsx";
import Reports from "models/Reports";

// Styles
import Computer from "material-ui-icons/Computer";

const pieChartColors = [
  "#00bcd4",
  "#f44336",
  "#ff9800",
  "#9c27b0",
  "#4caf50",
  "#9C9B99",
  "#dd4b39",
  "#35465c",
  "#e52d27",
  "#55acee",
  "#cc2127",
  "#1769ff",
  "#6188e2",
  "#a748ca"
];

export const DeviceUsageContent = ({ report }) => {
  if (!report) {
    return <h3><small>No report</small></h3>;
  }
  const devices = report.edgeDeviceUsage();
  if (!Object.keys(devices).length) {
    return <h3><small>No devices reported for this date range</small></h3>;
  }
  return (
    <React.Fragment>
      <Chartist
        className="ct-double-octave"
        type="Pie"
        data={{
          series: Object.keys(devices).map((name, i) => ({ value: devices[name].successful + devices[name].failures, meta: { style: `stroke: ${pieChartColors[i]}; stroke-width: 30px;` } })),
        }}
        options={{
          donutWidth: 30,
          donut: true,
          donutSolid: true,
        }}
        listener={{
          draw: (context) => {
            if (context.type === "slice" && context.meta && context.meta.style) {
              context.element.attr(context.meta);
            }
          }
        }}
      />
      <h5>Legend</h5>
      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
        {
          Object.keys(devices).map((id, i) => {
            return (
              <li key={i} style={{ display: "inline-block", margin: "0 15px 15px 0" }}>
                <Badge style={{ backgroundColor: pieChartColors[i] }}>
                  {devices[id].name}
                </Badge>
              </li>
            );
          })
        }
      </ul>
    </React.Fragment>
  );
};

DeviceUsageContent.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export const DeviceUsage = ({ report }) => (
  <IconCard
    iconColor="orange"
    icon={Computer}
    title="Device Usage"
    content={<DeviceUsageContent report={report} />}
  />
);

DeviceUsage.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export default DeviceUsage;