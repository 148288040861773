import {
	STORE_NOTIFICATION_ERROR,
	REMOVE_NOTIFICATION_ERROR,
	STORE_NOTIFICATION_INFO,
	REMOVE_NOTIFICATION_INFO,
} from "actions/ActionTypes"

const initialState = {
	error: null,
	info: null,
}

const updateMessage = (state, type, msg) => ({
	...state,
	[type]: msg
})

const removeMessage = (state, type) => ({
	...state,
	[type]: null
})

export default (state = initialState, action) => {
	const { type, message } = action
	switch (type) {
	case STORE_NOTIFICATION_ERROR:
		return updateMessage(state, "error", message)
	case STORE_NOTIFICATION_INFO:
		return updateMessage(state, "info", message)
	case REMOVE_NOTIFICATION_ERROR:
		return removeMessage(state, "error")
	case REMOVE_NOTIFICATION_INFO:
		return removeMessage(state, "info")
	default:
		return state
	}
}