// ##############################
// // // BannerCard styles
// #############################

import { card, defaultFont } from "assets/jss/material-dashboard-pro-react.jsx"

const bannerCardStyle = {
	card: {
		...card,
		color: "#fff",
		background: "linear-gradient(to right, #0fb8ad, #1fc8db)",
	},
	cardTitle: {
		...defaultFont,
		color: "#fff",
		textDecoration: "none",
		marginTop: "0",
	},
	center: {
		textAlign: "center"
	},
	cardContent: {
		padding: "30px 20px !important",
		position: "relative"
	},
	cardButton: {
		backgroundColor: "#fff",
		color: "#000"
	}
}

export default bannerCardStyle
