import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import FormControl from "material-ui/Form/FormControl";
import { CircularProgress } from "material-ui/Progress";

// material-ui icons
import Domain from "material-ui-icons/Domain";
import Extension from "material-ui-icons/Extension";
import CloudUpload from "material-ui-icons/CloudUpload";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";

// additional components
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

// utils
import { verifyLength, verifyEmail } from "libs/utils";
import { updateMyOrg, getMyOrg } from "libs/apiLib";
import { storeOrg } from "actions/index";
import countries from "constants/Countries";
import { searchParamsSelector } from "selectors";

const style = {
  ...sweetAlertStyle,
  ...dashboardStyle,
  ...customSelectStyle
};

class OrgApps extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      expiration: PropTypes.string,
      enabled: PropTypes.bool,
      name: PropTypes.string,
    }))
  }

  formatRow(row) {
    let exp = null;
    if (row.enabled && row.expiration) {
      exp = moment(row.expiration).format("dddd, MMMM Do YYYY, h:mm a");
    } else if (row.enabled) {
      exp = "Unlimited";
    }
    return {
      name: (
        <div>
          <div>{row.name}</div>
          <div
            style={{
              fontSize: "x-small",
              color: "gray",
              marginTop: "-5px"
            }}
          >
            Name
          </div>
        </div>
      ),
      enabled: (
        <div>{row.enabled ? <Button round color="success">Enabled</Button> : <Button round>Disabled</Button>}</div>
      ),
      expiration: exp && (
        <div>
          <div>{exp}</div>
          <div
            style={{
              fontSize: "x-small",
              color: "gray",
              marginTop: "-5px"
            }}
          >
            Expires
          </div>
        </div>
      )
    };
  }

  render() {
    return (
      <IconCard
        icon={Extension}
        iconColor="blue"
        title="App Licenses"
        content={
          <div style={{ padding: "0 40px" }}>
            <p>Below are the HealthLytix Apps enabled for your account, along with their expiration dates if any. For help or questions please email us at <a href="mailto:support@healthlytix.com?subject=App&nbsp;Licenses">support@healthlytix.com</a></p>
            <ReactTable
              sortable={false}
              showPagination={false}
              noDataText="No Apps"
              data={this.props.data && this.props.data.map(this.formatRow) || []}
              columns={["name", "enabled", "expiration"].map(c => ({ Header: "", accessor: c }))}
              className="-highlight"
              minRows={1}
            />
          </div>
        }
      />
    );
  }
}

const MultiOrgUpload = ({ org }) => {
  const formatRow = (row) => {
    return {
      orgName: (<Cell value={row.orgName} label="Organization" />),
      id: (<Cell value={`HLXU:${row.id}`} label="AETitle" />)
    };
  };
  return (
    <IconCard
      icon={CloudUpload}
      iconColor="blue"
      title="Multi-Organization Upload"
      content={
        <GridContainer style={{ padding: "0 40px 20px" }}>
          <ItemGrid xs={12} md={8}>
            <p>View the organizations that your registered Edge devices can send data to. The AETitle&apos;s below should be configured in your systems to be able to send data to the organization it is assigned to. Please email <a href="mailto:support@healthlytix.com?subject=Multi-Organization&nbsp;Upload">support@healthlytix.com</a> to toggle this feature and to add and remove organizations and AETitles.</p>
          </ItemGrid>
          <ItemGrid xs={12} md={4}>
            {org && org.multiOrgUploadAllowed ? <Button right round color="success">Enabled</Button> : <Button right round>Disabled</Button>}
          </ItemGrid>
          {org && org.multiOrgUploadAllowed &&
            <ItemGrid xs={12}>
              <ReactTable
                sortable={false}
                defaultPageSize={5}
                noDataText="No multi-organization upload configuration"
                data={org && org.orgUploadConfig ? org.orgUploadConfig.map(formatRow) : []}
                columns={[
                  { Header: "", accessor: "orgName" },
                  { Header: "", accessor: "id", maxWidth: 200 },
                ]}
              />
            </ItemGrid>
          }
        </GridContainer>
      }
    />
  );
};

MultiOrgUpload.propTypes = {
  org: PropTypes.shape({
    multiOrgUploadAllowed: PropTypes.boolean,
    orgUploadConfig: PropTypes.arrayOf(PropTypes.shape({
      orgName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }))
  })
};

class OrgSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      error: "",
      orgUpdated: false,
      orgName: "",
      orgNameState: "",
      country: "",
      countryState: "",
      streetName: "",
      streetNameState: "",
      streetNum: "",
      streetNumState: "",
      addressLine: "",
      suite: "",
      city: "",
      cityState: "",
      state: "",
      stateState: "",
      postalCode: "",
      postalCodeState: "",
      phone: "",
      phoneState: "",
      billingContact: "",
      billingContactState: "",
      billingEmail: "",
      billingEmailState: "",
      referringPhysician: ""
    };

    this.updateOrg = this.updateOrg.bind(this);
  }

  componentDidMount() {
    const { org, searchParams } = this.props;
    if (searchParams && searchParams.orgId) {
      this.getOrg(searchParams.orgId);
    } else if (org) {
      this.setState({
        orgName: org.orgName || "",
        orgNameState: org.orgName ? "success" : "error",
        country: org.country || "",
        countryState: org.country ? "success" : "error",
        streetNum: org.streetNumber || "",
        streetNumState: org.streetNumber ? "success" : "error",
        streetName: org.addressLineOne || "",
        streetNameState: org.addressLineOne ? "success" : "error",
        addressLine: org.addressLineTwo || "",
        city: org.city || "",
        cityState: org.city ? "success" : "error",
        state: org.state || "",
        stateState: org.state ? "success" : "error",
        suite: org.suite || "",
        postalCode: org.postalCode || "",
        postalCodeState: org.postalCode ? "success" : "error",
        phone: org.phone || "",
        phoneState: org.phone ? "success" : "error",
        billingContact: org.billingContact || "",
        billingContactState: org.billingContact ? "success" : "error",
        billingEmail: org.billingEmail || "",
        billingEmailState: org.billingEmail ? "success" : "error",
        referringPhysician: org.referringPhysician || ""
      });
    }
  }

  async getOrg(orgId) {
    try {
      const response = await getMyOrg(orgId);
      if (response.success && response.org) {
        const { org } = response;
        this.setState({
          orgName: org.orgName || "",
          orgNameState: org.orgName ? "success" : "error",
          country: org.country || "",
          countryState: org.country ? "success" : "error",
          streetNum: org.streetNumber || "",
          streetNumState: org.streetNumber ? "success" : "error",
          streetName: org.addressLineOne || "",
          streetNameState: org.addressLineOne ? "success" : "error",
          addressLine: org.addressLineTwo || "",
          city: org.city || "",
          cityState: org.city ? "success" : "error",
          state: org.state || "",
          stateState: org.state ? "success" : "error",
          suite: org.suite || "",
          postalCode: org.postalCode || "",
          postalCodeState: org.postalCode ? "success" : "error",
          phone: org.phone || "",
          phoneState: org.phone ? "success" : "error",
          billingContact: org.billingContact || "",
          billingContactState: org.billingContact ? "success" : "error",
          billingEmail: org.billingEmail || "",
          billingEmailState: org.billingEmail ? "success" : "error",
          referringPhysician: org.referringPhysician || ""
        });
      }
    } catch (error) {
      this.setState({
        alert: null,
        error: error.message
      });
    }
  }

  change(event, stateName, type, stateNameEqualTo) {
    if (event.target) {
      switch (type) {
      case "email":
        if (verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
      }
      this.setState({ [stateName]: event.target.value });
    }
  }

  isFormValid() {
    if (
      this.state.orgNameState === "success" &&
      this.state.countryState === "success" &&
      this.state.streetNameState === "success" &&
      this.state.streetNumState === "success" &&
      this.state.cityState === "success" &&
      this.state.stateState === "success" &&
      this.state.postalCodeState === "success" &&
      this.state.phoneState === "success" &&
      this.state.billingContactState === "success" &&
      this.state.billingEmailState === "success"
    ) { return true; }
  }

  validateForm() {
    if (this.isFormValid()) { return true; }
    else {
      if (this.state.orgNameState !== "success") {
        this.setState({ orgNameState: "error" });
      }
      if (this.state.countryState !== "success") {
        this.setState({ countryState: "error" });
      }
      if (this.state.streetNameState !== "success") {
        this.setState({ streetNameState: "error" });
      }
      if (this.state.streetNumState !== "success") {
        this.setState({ streetNumState: "error" });
      }
      if (this.state.cityState !== "success") {
        this.setState({ cityState: "error" });
      }
      if (this.state.stateState !== "success") {
        this.setState({ stateState: "error" });
      }
      if (this.state.postalCodeState !== "success") {
        this.setState({ postalCodeState: "error" });
      }
      if (this.state.phoneState !== "success") {
        this.setState({ phoneState: "error" });
      }
      if (this.state.billingContactState !== "success") {
        this.setState({ billingContactState: "error" });
      }
      if (this.state.billingEmailState !== "success") {
        this.setState({ billingEmailState: "error" });
      }
    }
    return false;
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  showLoadingAlert() {
    this.setState({
      alert: (
        <SweetAlert
          closeOnClickOutside={false}
          style={{ display: "block", marginTop: "-100px" }}
          title="Please wait..."
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
        >
          <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />
        </SweetAlert>
      )
    });
  }

  updateOrg = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.showLoadingAlert();
      const { updateOrgOnStore, searchParams: { orgId } } = this.props;
      try {
        // update the org
        const org = await updateMyOrg({
          orgId: orgId || undefined,
          orgName: this.state.orgName,
          country: this.state.country,
          streetNumber: this.state.streetNum,
          addressLineOne: this.state.streetName,
          addressLineTwo: this.state.addressLine,
          city: this.state.city,
          state: this.state.state,
          suite: this.state.suite,
          postalCode: this.state.postalCode,
          phone: this.state.phone,
          billingContact: this.state.billingContact,
          billingEmail: this.state.billingEmail,
          setupDone: true,
          referringPhysician: this.state.referringPhysician
        });

        if (!org) { throw new Error("Failed to get org"); }
        else if (!org.org) { throw new Error("Failed to get org"); }

        if (!orgId) {
          // only update org on store if the viewing user is part of this org.
          // Otherwise org was just updated by a super admin.
          updateOrgOnStore(org.org);
        }

        this.setState({
          alert: (
            <SweetAlert
              success
              closeOnClickOutside={false}
              style={{ display: "block", marginTop: "-100px" }}
              title="Done!"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              Organization updated!
            </SweetAlert>
          )
        });

      } catch (e) {
        this.setState({
          alert: null,
          error: e.message
        });
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          {this.state.alert}
          <ItemGrid xs={12} sm={12} md={12}>
            <IconCard
              icon={Domain}
              iconColor="blue"
              title="Organization - "
              category="Complete your details"
              content={
                <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                  <GridContainer>
                    <ItemGrid xs={12}>
                      <CustomInput
                        id="orgName"
                        success={this.state.orgNameState === "success"}
                        error={this.state.orgNameState === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Organization <small>(required)</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.orgName,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "orgName", "length", 2),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={4}>
                      <CustomInput
                        id="streetNum"
                        success={this.state.streetNumState === "success"}
                        error={this.state.streetNumState === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Street No. <small>(required)</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.streetNum,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "streetNum", "length", 1),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <CustomInput
                        id="streetName"
                        success={this.state.streetNameState === "success"}
                        error={this.state.streetNameState === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Street Name <small>(required)</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.streetName,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "streetName", "length", 3),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <CustomInput
                        id="addressLine"
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Address Line <small>(optional)</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.addressLine,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "addressLine"),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={4}>
                      <CustomInput
                        id="suite"
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Suite No. <small>(optional)</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.suite,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "suite"),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={4}>
                      <CustomInput
                        id="city"
                        success={this.state.cityState === "success"}
                        error={this.state.cityState === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            City <small>(required)</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.city,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "city", "length", 3),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <CustomInput
                        id="state"
                        success={this.state.stateState === "success"}
                        error={this.state.stateState === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            State <small>Province</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.state,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "state", "length", 2),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={4}>
                      <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                          Choose Country
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.country}
                          onChange={event => this.change(event, "country", "length", 3)}
                          inputProps={{
                            name: "country",
                            id: "country"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Country
                          </MenuItem>
                          {
                            countries.map((c, i) => {
                              return (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={c}
                                  key={i}
                                >
                                  {c}
                                </MenuItem>
                              );
                            })
                          }
                        </Select>
                      </FormControl>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <CustomInput
                        id="postalCode"
                        success={this.state.postalCodeState === "success"}
                        error={this.state.postalCodeState === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Postal Code
                          </span>
                        }
                        inputProps={{
                          value: this.state.postalCode,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "postalCode", "length", 3),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={3}>
                      <CustomInput
                        id="phone"
                        success={this.state.phoneState === "success"}
                        error={this.state.phoneState === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Phone <small>(required)</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.phone,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "phone", "length", 9),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={3}>
                      <CustomInput
                        id="billingContact"
                        success={this.state.billingContactState === "success"}
                        error={this.state.billingContactState === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Billing Contact <small>(required)</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.billingContact,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "billingContact", "length", 4),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={3}>
                      <CustomInput
                        id="billingEmail"
                        success={this.state.billingEmailState === "success"}
                        error={this.state.billingEmailState === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Billing Contact&apos;s Email <small>(required)</small>
                          </span>
                        }
                        inputProps={{
                          value: this.state.billingEmail,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "billingEmail", "email"),
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={3}>
                      <CustomInput
                        id="referringPhysician"
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelText={
                          <span>
                            Referring Physician&apos;s Name and Credentials <small>(optional)</small>
                          </span>
                        }
                        helpText="This name will displayed on patient PDF reports"
                        inputProps={{
                          placeholder: "First Name Last Name, MD",
                          value: this.state.referringPhysician,
                          disabled: this.state.disabled,
                          onChange: event => this.change(event, "referringPhysician"),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                  <Button color="primary"
                    disabled={!this.isFormValid()}
                    right
                    onClick={this.updateOrg}>
                    Update Organization
                  </Button>
                  <Clearfix />
                </div>
              }
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={12}>
            <OrgApps data={this.props.org && this.props.org.appLicenses || []} />
          </ItemGrid>
          <ItemGrid xs={12}>
            <MultiOrgUpload org={this.props.org} />
          </ItemGrid>
        </GridContainer>
      </div>
    );
  }
}

OrgSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  org: PropTypes.shape({
    appLicenses: PropTypes.array
  }),
  searchParams: PropTypes.shape({
    orgId: PropTypes.string
  }).isRequired
};

const mapStateToProps = (state, props) => ({
  isAuthenticating: state.user.isAuthenticating,
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  org: state.user.org,
  searchParams: searchParamsSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  updateOrgOnStore: (org) => {
    dispatch(storeOrg(org));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(OrgSettings)));
