import moment from "moment";
import {
  REPLACE_SEARCHED_JOBS,
  UPDATE_JOB_SEARCH_FILTERS
} from "actions/ActionTypes";

const initialState = {
  jobs: {
    data: [],
    filters: {
      start: moment().subtract(1, "w").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD")
    }
  }
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
  case REPLACE_SEARCHED_JOBS:
    return {
      ...state,
      jobs: {
        ...state.jobs,
        data: payload
      }
    };
  case UPDATE_JOB_SEARCH_FILTERS:
    return {
      ...state,
      jobs: {
        ...state.jobs,
        filters: {
          ...state.jobs.filters,
          [payload.key]: payload.value
        }
      }
    };
  default:
    return state;
  }
};