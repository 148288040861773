import React from "react"
import PropTypes from "prop-types"

// material-ui components
import GridContainer from "components/Grid/GridContainer.jsx"
import ItemGrid from "components/Grid/ItemGrid.jsx"
import Card from "material-ui/Card"
import CardContent from "material-ui/Card/CardContent"
import Button from "components/CustomButtons/Button.jsx"
import withStyles from "material-ui/styles/withStyles"

// jss
import bannerCardStyle from "assets/jss/material-dashboard-pro-react/components/bannerCardStyle"

/*
  BannerCard is a full-width presentation component that renders a white title, subtitle, and CTA button on a gradient colored background.
  It's normally placed at the top of pages to describe the current page
*/
function BannerCard({ ...props }) {
	const {
		classes,
		title,
		subtitle,
		button,
		callback
	} = props
	return (
		<GridContainer justify="center">
			<ItemGrid xs={12}>
				<Card className={classes.card} >
					<CardContent
						className={classes.cardContent + " " + classes.center}
					>
						{title && <h2 className={classes.cardTitle}>{title}</h2>}
						{subtitle && <h4>{subtitle}</h4>}
						{button && <Button customClass={classes.cardButton} rel="nofollow" target="_blank" href={button.href}>{button.text}</Button>}
						{callback && <Button customClass={classes.cardButton} onClick={() => callback.onSubmit() }>{callback.text}</Button>}
					</CardContent>
				</Card>
			</ItemGrid>
		</GridContainer>
	)
}

BannerCard.propTypes = {
	classes: PropTypes.shape({}).isRequired,
	title: PropTypes.string,
	subtitle: PropTypes.any,
	button: PropTypes.shape({
		href: PropTypes.string.isRequired,
		text: PropTypes.string.isRequired
	}),
	callback: PropTypes.shape({
		onSubmit: PropTypes.func.isRequired,
		text: PropTypes.string.isRequired
	}),
}

export default withStyles(bannerCardStyle)(BannerCard)