import React from "react";
import pt from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { getPatientRecord, deletePatientRecord, getWebPreviewToken, updatePatientRecord } from "libs/apiLib";
import { listFilesFromS3, getSignedUrl } from "libs/awsLib";
import { WithNotifications } from "components/HigherOrder";
import PDF from "react-pdf-js";
import { searchParamsSelector } from "selectors";

import HeaderCard from "components/Cards/HeaderCard";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button";
import DicomViewer from "components/DwvDicomViewer";
import ConfirmationButtonGroup from "components/CustomButtons/ConfirmationButtonGroup";
import Input from "components/CustomInput/CustomInput";
import { Label } from "./Profile";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Modal from "components/Modal";

import withStyles from "material-ui/styles/withStyles";
import reactTableStyles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";

const styles = {
  ...reactTableStyles,
  avatar: {
    height: "5em",
    width: "5em",
    color: "grey",
  },
  gridBottomMargin: {
    marginBottom: 30
  },
  zeroBottomMargin: {
    marginBottom: 0
  },
  zeroMargin: {
    margin: 0
  }
};
export class Record extends React.Component {
  static propTypes = {
    classes: pt.shape({
      wideHorizontalPadding: pt.string.isRequired,
      avatar: pt.string.isRequired,
      gridBottomMargin: pt.string.isRequired,
      zeroBottomMargin: pt.string.isRequired,
      zeroMargin: pt.string.isRequired,
    }).isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    getPatientRecord: pt.func.isRequired,
    deletePatientRecord: pt.func.isRequired,
    location: pt.shape({
      search: pt.string,
      state: pt.shape({
        record: pt.shape({
          recordId: pt.string.isRequired,
        }),
      })
    }),
    match: pt.shape({
      params: pt.shape({
        record_id: pt.string.isRequired
      }).isRequired
    }).isRequired,
    history: pt.shape({
      push: pt.func.isRequired,
    }).isRequired,
    org: pt.shape({
      orgId: pt.string.isRequired
    }).isRequired,
    getWebPreviewToken: pt.func.isRequired,
    listFilesFromS3: pt.func.isRequired,
    getSignedUrl: pt.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      token: null,
      record: props.location.state && props.location.state.record || null,
      id: props.location.state && props.location.state.record && props.location.state.record.recordId || props.match.params.record_id || null,
      files: [],
      loadDicomViewer: false,
      loadPDFViewer: false,
      modal: null,
      newRecord: null
    };
  }

  componentDidMount = async () => {
    if (!this.state.record) {
      this.getPatientRecord();
    } else {
      this.loadViewer();
    }
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.record && this.state.record) {
      this.loadViewer();
    }
  }

  loadViewer = async () => {
    const { record } = this.state;
    if (!record) {
      return;
    }
    try {
      const token = await this.getWebToken();
      const items = await this.props.listFilesFromS3(token, record.location);

      let tmp = [];
      let loadDicomViewer = false;
      let loadPDFViewer = false;

      for (let i of items.Contents) {
        tmp.push(this.props.getSignedUrl(token, i.Key));
        if (i.Key.endsWith(".pdf")) {
          loadPDFViewer = true;
          loadDicomViewer = false;
        }
        if (i.Key.endsWith(".dcm") && !loadPDFViewer) { loadDicomViewer = true; }
      }

      if (loadPDFViewer) {
        tmp = tmp.filter((c) => c.split("?")[0].endsWith(".pdf") === true);
      } else if (loadDicomViewer) { tmp = tmp.filter((c) => c.split("?")[0].endsWith(".dcm") === true); }

      if (record.type === "Dicom Medical Study") {
        this.setState({ files: tmp, loadDicomViewer, loadPDFViewer });
      }
    } catch (e) {
    }
  }

  getWebToken = async () => {
    try {
      const { token } = await this.props.getWebPreviewToken(this.props.org.orgId);
      this.setState({ awsToken: token });
      return token;
    } catch (e) {
      console.log(e);
    }
  }

  getPatientRecord = async () => {
    const { getPatientRecord, signalError, clearError, org } = this.props;
    const { id } = this.state;
    this.setState({ loading: true });
    try {
      if (!id || !id.length) {
        throw new Error();
      }
      const response = await getPatientRecord(org.orgId, id);
      if (response.success && response.record) {
        this.setState({ record: response.record });
      } else {
        throw new Error();
      }
      this.setState({ record: response.record });
    } catch (e) {
      signalError("Error getting patient record. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  handleDelete = async () => {
    const { history, match, deletePatientRecord, signalError, clearError, location: { search }, searchParams: { orgId } } = this.props;
    const { id } = this.state;
    this.setState({ loading: true });
    try {
      const response = await deletePatientRecord(id, orgId);
      if (response.success) {
        return history.push({
          pathname: `/patients/${match.params.id}`,
          search,
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error deleting patient record. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false, modal: null });
  }

  handleUpdate = async () => {
    const { history, match, updatePatientRecord, signalError, clearError, location: { search }, searchParams: { orgId } } = this.props;
    const { record: { recordId }, newRecord: { description, type } } = this.state;
    this.setState({ loading: true });
    try {
      const response = await updatePatientRecord({
        recordId,
        description, 
        type,
        orgId
      });
      if (response.success) {
        return history.push({
          pathname: `/patients/${match.params.id}`,
          search,
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error updating patient record. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false, modal: null });
  }

  handleDropdownSelection = (action) => {
    switch (action) {
    case "Update":
      this.setState({ modal: "update", newRecord: { description: this.state.record.description, type: this.state.record.type } });
      return;
    case "Delete":
      this.setState({ modal: "delete" });
      break;
    default:
      return;
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({ ...prevState, newRecord: { ...prevState.newRecord, [name]: value } }));
  }

  renderUpdateModal = () => {
    const { newRecord, loading } = this.state;
    return (
      <Modal
        style={{
          height: 900,
          width: 800,
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer style={{ height: "100%", paddingLeft: 40, paddingRight: 40 }}>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}>
            <h2>Update Record</h2>
          </ItemGrid>
          <ItemGrid xs={12}>
            <h4>Record Type</h4>
            <Button onClick={() => this.setState(prevState => ({ ...prevState, newRecord: { ...prevState.newRecord, type: "Dicom Medical Study" } }))} color={newRecord.type === "Dicom Medical Study" ? "primary" : undefined}>Dicom Medical Study</Button>
            <Button onClick={() => this.setState(prevState => ({ ...prevState, newRecord: { ...prevState.newRecord, type: "Genotype" } }))} color={newRecord.type === "Genotype" ? "primary" : undefined}>Genotype</Button>
          </ItemGrid>
          <ItemGrid xs={12}>
            <h4 style={{ marginBottom: 0 }}>Description</h4>
            <Input
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "Required",
                name: "description",
                value: newRecord.description,
                onChange: this.handleInputChange,
              }}
            />
          </ItemGrid>
          <ItemGrid container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }} xs={12}>
            <Button onClick={this.removeModal} disabled={loading}>Cancel</Button>
            <Button
              style={{ marginRight: 15, marginLeft: 15 }}
              color="success"
              onClick={this.handleUpdate}
              disabled={loading || !newRecord.description.length || !["Dicom Medical Study", "Genotype"].includes(newRecord.type)}
            >Update</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }

  renderDeleteModal = () => {
    const { loading } = this.state;
    return (
      <Modal
        style={{
          height: 300,
          width: 500,
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer style={{ height: "100%", paddingLeft: 40, paddingRight: 40 }}>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}>
            <h2>Delete Record?</h2>
          </ItemGrid>
          <ItemGrid container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }} xs={12}>
            <Button onClick={this.removeModal} disabled={loading}>No</Button>
            <Button
              style={{ marginRight: 15, marginLeft: 15 }}
              color="danger"
              onClick={this.handleDelete}
              disabled={loading}
            >Yes</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }

  removeModal = () => this.setState({ modal: null, newRecord: null })

  renderModal = () => {
    if (!this.state.modal) {
      return null;
    }
    switch (this.state.modal) {
    case "update":
      return this.renderUpdateModal();
    case "delete":
      return this.renderDeleteModal();
    default:
      return null;
    }
  }

  render() {
    const { classes: { gridBottomMargin, zeroMargin, zeroBottomMargin } } = this.props;
    const { record } = this.state;
    if (!record) {
      return null;
    }
    return (
      <GridContainer>
        <ItemGrid xs={12}>
          {this.renderModal()}
          <HeaderCard
            cardTitle="Patient Record"
            headerColor="blue"
            content={
              <GridContainer className={this.props.classes.wideHorizontalPadding}>
                <ItemGrid xs={12}>
                  <GridContainer justify="flex-end">
                    <CustomDropdown
                      buttonColor="primary"
                      buttonText="Options"
                      onAction={this.handleDropdownSelection}
                      dropdownList={["Update", "Delete"]}
                    />
                  </GridContainer>
                  <GridContainer className={gridBottomMargin}>
                    <ItemGrid xs={12}>
                      <h2 className={zeroMargin}>{record.description}</h2>
                      <Label>Name</Label>
                    </ItemGrid>
                  </GridContainer>
                  <GridContainer className={gridBottomMargin}>
                    <ItemGrid xs={2}>
                      <h4 className={zeroBottomMargin}>{record.type}</h4>
                      <Label>Type</Label>
                    </ItemGrid>
                    <ItemGrid xs={3}>
                      <h4 className={zeroBottomMargin}>{moment(record.createdOn).format("dddd, MMMM Do YYYY")}</h4>
                      <Label>Created</Label>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
              </GridContainer>
            }
          />
        </ItemGrid>
        <ItemGrid xs={12}>
          {this.state.loadDicomViewer && this.state.files.length > 0 && <DicomViewer images={this.state.files} />}
        </ItemGrid>
        {this.state.loadPDFViewer && this.state.files.length > 0 &&
          <ItemGrid xs={12}>
            <HeaderCard
              cardTitle="PDF Viewer"
              headerColor="blue"
              content={
                <GridContainer className={this.props.classes.wideHorizontalPadding}>
                  <ItemGrid xs={12} container justify="center">
                    <PDF
                      file={this.state.files[0]}
                      page={1}
                      scale={2}
                    />
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>}
      </GridContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({
  org: state.user.org,
  searchParams: searchParamsSelector(state, props)
});

export default connect(mapStateToProps, () => ({
  getPatientRecord: (ownerId, id) => getPatientRecord(ownerId, id),
  deletePatientRecord: (id, orgId) => deletePatientRecord(id, orgId),
  getWebPreviewToken: (orgId) => getWebPreviewToken(orgId),
  getSignedUrl: (token, key) => getSignedUrl(token, key),
  listFilesFromS3: (token, location) => listFilesFromS3(token, location),
  updatePatientRecord
}))(WithNotifications(withStyles(styles)(Record)));