export default class Modality {
  static delimiter = "=====";

  constructor({ modalityId, successful, failures }) {
    this.failures = failures || 0;
    this.successful = successful || 0;
    const data = this.parseModalityId(modalityId);
    this.id = modalityId;
    this.modality = data[0];
    if (data.length === 5) {
      this.manufacturer = data[1];
      this.model = data[2];
      this.hostName = data[3];
      this.serialNumber = data[4];
    } else {
      this.manufacturer = "N/A";
      this.model = "N/A";
      this.hostName = "N/A";
      this.serialNumber = "N/A";
    }
  }

  parseModalityId = (id) => id.split(Modality.delimiter);

  addToFailures = (n) => this.failures += n;

  addToSuccessful = (n) => this.successful += n;
}