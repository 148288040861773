export class AppConfigFactory {
  static supportedSchemas = new Set(["1.0.0","1.1.0"])
  static isSupportedSchema (schema) {
    return AppConfigFactory.supportedSchemas.has(schema);
  }
  static build (ac) {
    if (!AppConfigFactory.isSupportedSchema(String(ac.schema))) {
      throw new Error(`Unsupported app config schema ${ac.schema} was provided.`);
    }
    switch (ac.schema) {
    case "1.0.0":
    case "1.1.0":
      return new AppConfigBase(ac);
    default:
      throw new Error(`Invalid app config schema ${ac.schema} was provided.`);
    }
  }
}

class AppConfigBase {
  constructor (ac) {
    this.meta = "HLX_APP_CONFIG";
    this.schema = ac.schema;
    this.inputs = ac.inputs;
    this.outputs = ac.outputs;
  }

  /**
   * Returns the series' compression mode if it exists, otherwise returns undefined
   * @param  {string} series?
   * @returns 'JPEG2000lossy' | 'JPEG2000lossless' | 'JPEGlossless' | 'JPEGlossy' | 'RLE' | 'None' | undefined
   */
  getTransferSyntaxProposal (series) {
    if (!series || !this.outputs || !this.outputs[series] || !this.outputs[series].compression) {
      return undefined;
    }
    return this.outputs[series].compression;
  }

  /**
   * Returns the object that will be used to serialize into a JSON string
   * @returns AppConfigSchema
   */
  toSerialized () {
    return {
      schema: this.schema,
      meta: this.meta,
      inputs: this.inputs,
      outputs: this.outputs
    };
  }
}
