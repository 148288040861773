import React from "react";
import PropTypes from "prop-types";
import AceEditor from "react-ace";
import { connect } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button";

import { getLicenseBy, uninstallLicense } from "libs/apiLib";
import { storeNotificationError, removeNotificationError } from "actions";

import withStyles from "material-ui/styles/withStyles";

const style = {
  editor: {
    height: "200px",
    padding: "30px 30px 30px 10px",
    margin: "0 30px",
    borderRadius: "6px",
    backgroundColor: "#F5F7F9",
    "& .ace-xcode": {
      backgroundColor: "#F5F7F9",
      color: "#5d5d5d",
    },
    "& .ace-xcode .ace_gutter": {
      backgroundColor: "#F5F7F9",
      color: "#c1c7cd",
    },
    "& .ace-xcode .ace_cursor": {
      color: "#5d5d5d",
    },
    "& .ace-xcode .ace_gutter-active-line": {
      backgroundColor: "inherit"
    }
  },
  "@media (min-height: 450px)": {
    editor: {
      height: "230px"
    }
  },
  "@media (min-height: 500px)": {
    editor: {
      height: "280px",
    }
  },
  "@media (min-height: 600px)": {
    editor: {
      height: "340px",
    }
  },
  "@media (min-height: 650px)": {
    editor: {
      height: "385px",
    }
  },
  "@media (min-height: 700px)": {
    editor: {
      height: "430px",
    }
  },
  "@media (min-height: 750px)": {
    editor: {
      height: "475px",
    }
  },
  "@media (min-height: 800px)": {
    editor: {
      height: "520px",
    }
  },
  "@media (min-height: 850px)": {
    editor: {
      height: "565px",
    }
  },
  "@media (min-height: 900px)": {
    editor: {
      height: "610px",
    }
  },
  "@media (min-height: 950px)": {
    editor: {
      height: "655px",
    }
  },
  "@media (min-height: 1000px)": {
    editor: {
      height: "700px",
    }
  },
};

export class LicenseViewer extends React.Component {
  static propTypes = {
  	onCancel: PropTypes.func.isRequired,
  	license: PropTypes.shape({
  		App: PropTypes.string.isRequired,
  		Expiration: PropTypes.string.isRequired,
  		LicenseKey: PropTypes.string.isRequired,
  		SerialNumber: PropTypes.string.isRequired,
  	}),
  	classes: PropTypes.shape({
  		editor: PropTypes.string.isRequired,
  	}).isRequired,
  	getLicenseBy: PropTypes.func.isRequired,
  	uninstallLicense: PropTypes.func.isRequired,
  	serialNumber: PropTypes.string,
  	clearError: PropTypes.func.isRequired,
  	signalError: PropTypes.func.isRequired,
  	onUninstalled: PropTypes.func.isRequired,
  }

  constructor(props) {
  	super(props);
  	this.state = {
  		loading: false,
  		serialNumber: props.serialNumber,
  		license: props.license,
  	};
  }

  componentDidMount() {
  	if (!this.state.license && this.state.serialNumber) {
  		this.getLicense();
  	}
  }

  componentDidUpdate() {
  	if (this.props.license && !this.state.license) {
  		this.setState({ license: this.props.license });
  	}
  }

  getLicense = async () => {
  	const { getLicenseBy, signalError, clearError } = this.props;
  	const { serialNumber } = this.state;
  	this.setState({ loading: true });
  	try {
  		const response = await getLicenseBy(serialNumber);
  		if (response.success && response.license) {
  			this.setState({ license: response.license });
  		} else {
  			throw new Error(`Error getting license with serial number "${serialNumber}". Please try again later.`);
  		}
  	} catch (e) {
  		signalError(e.message);
  		setTimeout(clearError, 5000);
  	}
  	this.setState({ loading: false });
  }

  uninstallLicense = async () => {
  	const { license, serialNumber } = this.state;
  	const { uninstallLicense, onCancel, signalError, clearError, onUninstalled } = this.props;
  	this.setState({ loading: true });
  	try {
  		const response = await uninstallLicense(license.SerialNumber || serialNumber, license.orgId);
  		if (response.success) {
  			onUninstalled(license.SerialNumber || serialNumber);
  			onCancel();
  		} else {
  			throw new Error("Error uninstalling license. Please try again later.");
  		}
  	} catch (e) {
  		signalError(e.message);
  		setTimeout(clearError, 5000);
  		this.setState({ loading: false });
  	}
  }

  onDownload = () => {
  	const { license } = this.state;
  	const filename = "license.json";
  	const contentType = "application/json;charset=utf-8;";
  	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  		var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(license)))], { type: contentType });
  		navigator.msSaveOrOpenBlob(blob, filename);
  	} else {
  		const a = document.createElement("a");
  		a.download = filename;
  		a.href = "data:" + contentType + "," + encodeURIComponent(JSON.stringify(license));
  		a.target = "_self";
  		document.body.appendChild(a);
  		a.click();
  		document.body.removeChild(a);
  	}
  }

  isInstalled = () => {
  	const { license } = this.state;
  	if (!license) {
  		return false;
  	}
  	if (license.installed === undefined) {
  		return true;
  	}
  	return license.installed;
  }

  stringifySource = () => {
    const { license } = this.state;
    if (!license) {
      return undefined;
    }
    if (!license.installed) {
      return JSON.stringify(license, undefined, "\t");
    }
    const result = {};
    for (const key in license) {
      if ([
        "SerialNumber",
        "App",
        "LicenseKey",
        "Expiration"
      ].includes(key)) {
        result[key] = license[key];
      }
    }
    return JSON.stringify(result, undefined, "\t");
  }

  render() {
  	const { onCancel, classes } = this.props;
  	const { loading } = this.state;
  	const isInstalled = this.isInstalled();
  	const columns = isInstalled ? 4 : 6;
  	return (
  		<GridContainer>
  			<ItemGrid xs={12}><h2 style={{ textAlign: "center" }}>View License</h2></ItemGrid>
  			<ItemGrid
  				xs={12}
  			>
  				<div
  					className={classes.editor}
  				>
  					<AceEditor
  						readOnly
  						mode="json"
  						theme="xcode"
  						name="license"
  						fontSize={14}
  						showPrintMargin={true}
  						showGutter={true}
  						highlightActiveLine={false}
  						value={this.stringifySource()}
  						style={{
  							width: "100%",
  							height: "100%",
  						}}
  						showLineNumbers
  						editorProps={{ $blockScrolling: Infinity }}
  					/>
  				</div>
  			</ItemGrid>
  			<ItemGrid container xs={12} style={{ position: "absolute", bottom: "15px" }}>
  				{isInstalled && <ItemGrid container justify="center" xs={columns}><Button color="success" onClick={this.onDownload} disabled={loading}>Download</Button></ItemGrid>}
  				{isInstalled && <ItemGrid container justify="center" xs={columns}><Button color="danger" onClick={this.uninstallLicense} disabled={loading}>Uninstall</Button></ItemGrid>}
  				<ItemGrid container justify="center" xs={isInstalled ? 4 : 12}><Button color="primary" onClick={onCancel} disabled={loading}>Close</Button></ItemGrid>
  			</ItemGrid>
  		</GridContainer>
  	);
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearError: () => dispatch(removeNotificationError()),
  signalError: (msg) => dispatch(storeNotificationError(msg)),
  getLicenseBy: (serial) => getLicenseBy(serial),
  uninstallLicense: (serial, orgId) => uninstallLicense(serial, orgId),
});

export default connect(undefined, mapDispatchToProps)(withStyles(style)(LicenseViewer));