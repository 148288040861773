import React from "react";
import pt from "prop-types";
import moment from "moment";
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import { CircularProgress } from "material-ui/Progress";
import { APPS } from "constants/Apps";

export class ConfirmStep extends React.Component {
  static propTypes = {
    records: pt.arrayOf(pt.shape({
      createdOn: pt.string.isRequired,
      recordId: pt.string.isRequired,
      location: pt.string,
      patientId: pt.string.isRequired,
      type: pt.string.isRequired,
      description: pt.string.isRequired,
      ownerId: pt.string.isRequired,
    })),
    app: pt.shape({
      name: pt.string.isRequired,
      type: pt.string.isRequired,
      description: pt.string.isRequired,
      appId: pt.string.isRequired,
    }),
    options: pt.shape({
      mci: pt.bool,
      appVersion: pt.string.isRequired,
    }),
    patient: pt.shape({
      firstName: pt.string,
      lastName: pt.string,
    }).isRequired,
    errorMessage: pt.string,
    loading: pt.bool,
    userIsHLXSupport: pt.bool.isRequired,
  }

  state = {
    loading: false,
  }

  formatCell = (key) => (cell) => {
    switch (key) {
    case "type":
      return <Cell label="Record Type" value={cell.value === "Dicom Medical Study" ? "NeuroQuant DICOM" : cell.value} />;
    case "description":
      return <Cell label="Description" value={cell.value} />;
    case "createdOn":
      return <Cell label="Creation Date" value={moment(cell.original.createdOn).format("MMMM-DD-YYYY")} />;
    default:
      return null;
    }
  };

  renderVersionOption = () => {
    if (!this.props.userIsHLXSupport) {
      return <p>None</p>;
    }
    return <Cell label="Version" value={this.props.options.appVersion || "Latest"} />;
  }

  renderOptions = () => {
    const { app, options } = this.props;
    if (!app) {
      return <p>None</p>;
    }
    switch (app.appId) {
    case APPS.airr:
    case APPS.airrMci:
      return (
        <React.Fragment>
          <Cell label="Screen" value={options && options.mci === true ? "Mild Cognitive Impairment" : "General Population"} />
          {this.renderVersionOption()}
        </React.Fragment>
      );
    default:
      return this.renderVersionOption();
    }
  }

  appName = (app) => {
    switch (app.appId) {
    case APPS.airr:
    case APPS.airrMci:
      return "Alzheimer's Integrated Risk Report";
    case APPS.agrr:
      return "Alzheimer's GeneticRisk Report";
    case APPS.pgr:
      return "Prostate Cancer GeneticRisk Report";
    default:
      return app.appName;
    }
  }

  patientName = () => {
    return `${this.props.patient.firstName} ${this.props.patient.lastName}`;
  }

  render() {
    const { records, app, errorMessage } = this.props;
    const { loading } = this.state;
    if (!records || !records.length || !app) {
      return null;
    }
    if (this.props.loading) {
      return (
        <GridContainer justify="center">
          <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />
        </GridContainer>
      );
    }
    return (
      <GridContainer style={{ paddingRight: 30, paddingLeft: 30, paddingTop: 30 }}>
        <ItemGrid xs={12}>
          <p>Please confirm the following settings are correct.</p>
          <p>After you submit, processing will take a few minutes to complete. Once ready, the report will be available for viewing in the patient&apos;s profile.</p>
        </ItemGrid>
        <ItemGrid xs={12}><h4 style={{ color: "#00ACEF" }}>Patient</h4></ItemGrid>
        <ItemGrid xs={12}><Cell label="Name" value={this.patientName()} /></ItemGrid>
        <ItemGrid xs={12}><h4 style={{ color: "#00ACEF" }}>Application</h4></ItemGrid>
        <ItemGrid xs={12}><Cell label="Name" value={this.appName(app)} /></ItemGrid>
        <ItemGrid xs={12}><h4 style={{ color: "#00ACEF", marginBottom: -5 }}>Records</h4></ItemGrid>
        <ItemGrid xs={12}>
          <ReactTable
            noDataText="No Records"
            sortable={false}
            loading={loading}
            data={records}
            minRows={records.length}
            showPagination={false}
            columns={
              [
                { accessor: "description" },
                { accessor: "createdOn", maxWidth: 200 },
                { accessor: "type", maxWidth: 200 },
              ].map(r => ({ accessor: r.accessor, Cell: this.formatCell(r.accessor), Header: "", style: { paddingLeft: 0 } }))
            }
          />
        </ItemGrid>
        <ItemGrid xs={12}><h4 style={{ color: "#00ACEF" }}>Options</h4></ItemGrid>
        <ItemGrid xs={12}>
          {this.renderOptions()}
        </ItemGrid>
        {errorMessage && <ItemGrid xs={12}><p style={{ color: "red", marginTop: 30, textAlign: "center" }}>{errorMessage}</p></ItemGrid>}
      </GridContainer>
    );
  }
}

export default ConfirmStep;