import React from "react";
import pt from "prop-types";
import Button from "./Button";

class Index extends React.Component {
  static propTypes = {
    openText: pt.string.isRequired,
    closeText: pt.string.isRequired,
    submitText: pt.string.isRequired,
    closeColor: pt.string,
    openColor: pt.string.isRequired,
    submitColor: pt.string.isRequired,
    onSubmit: pt.func.isRequired,
    loading: pt.bool,
  }

  static defaultProps = {
    closeText: "Cancel",
    submitText: "Confirm"
  }

  state = {
    submitted: false,
    open: false
  }

  componentDidUpdate = (prevProps, prevState) => {
    // went from !submitted to submitted, call onSubmit prop
    if (!prevState.submitted && this.state.submitted) {
      this.props.onSubmit();
      // reset submitted back to false
      this.setState({ submitted: false });
    }

    // loading went from true to false, ie, onSubmit prop finished running
    if (prevProps.loading && !this.props.loading) {
      // close button group back to original state
      this.open(false);
    }
  }

  open = (open) => this.setState({ open })

  handleSubmit = () => {
    this.setState({ submitted: true });
  }

  render = () => {
    const { open } = this.state;
    const { openText, openColor, closeText, closeColor, submitColor, submitText, loading } = this.props;
    if (!open) {
      return <Button color={openColor} onClick={() => this.open(true)}>{openText}</Button>;
    }
    return (
      <React.Fragment>
        <Button color={closeColor} style={{ marginRight: 15 }} onClick={() => this.open(false)} disabled={loading}>{closeText}</Button>
        <Button color={submitColor} onClick={this.handleSubmit} disabled={loading}>{submitText}</Button>
      </React.Fragment>
    );
  }
}

export default Index;