import React from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"

// react table
import ReactTable from "react-table"

// material-ui components
import withStyles from "material-ui/styles/withStyles"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import ItemGrid from "components/Grid/ItemGrid.jsx"
import HeaderCard from "components/Cards/HeaderCard.jsx"
import Button from "components/CustomButtons/Button.jsx"
import LoadingModal from "components/LoadingModal/LoadingModal.jsx"
import { WithNotifications } from "components/HigherOrder"

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle"
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx"

import ManageApp from "./components/ManageApp"

// api
import { getAllApps, newApp } from "libs/apiLib"

const style = {
	...dashboardStyle,
	...buttonsStyle
}

class Apps extends React.Component {
  static propTypes = {
  	signalError: PropTypes.func.isRequired,
  	clearError: PropTypes.func.isRequired,
  	classes: PropTypes.shape({}).isRequired,
  }

  constructor(props) {
  	super(props)
  	this.state = {
  		apps: null,
  		modal: null
  	}

  	this.getAllApps = this.getAllApps.bind(this)
  	this.handleNew = this.handleNew.bind(this)
  	this.hideModal = this.hideModal.bind(this)
  }

  async componentDidMount() {
  	await this.getAllApps()
  }

  async getAllApps() {
  	try {
  		const results = await getAllApps()
  		if (results.success) { this.setState({ apps: results.result.Items }) }
  	} catch (e) {
  		this.props.signalError("Error getting apps")
  		setTimeout(this.props.clearError, 5000)
  	}
  }

  handleNew = async () => {
  	this.setState({
  		modal: (
  			<ManageApp
  				onCancel={() => this.hideModal()}
  				onSave={(data) => this.updateApp(data)}
  			/>
  		)
  	})
  }

  updateApp = async (data) => {
  	this.showLoading()

  	try {
  		const { aeTitle, appId, cloudEnv,
  			description, edgeEnv, name, type } = data

  		let app = { appId, description, name, type }

  		if (aeTitle.length > 0) { app.aeTitle = aeTitle }

  		if (cloudEnv.length > 0) { app.cloudType = cloudEnv }

  		if (edgeEnv.length > 0) { app.edgeType = edgeEnv }

  		await newApp(app)
  		await this.getAllApps()
  	} catch (e) {
  		this.props.signalError("Error updating app")
  		setTimeout(this.props.clearError, 5000)
  	}

  	this.hideModal()
  }

  showLoading = () => {
  	this.setState({
  		modal: <LoadingModal />
  	})
  }

  hideModal = () => {
  	this.setState({ modal: null })
  }

  render() {
  	let tableData = []

  	if (this.state.apps) {
  		tableData = this.state.apps.map((prop) => {
  			let cloudEnv
  			let edgeEnv

  			if (prop.cloud) { cloudEnv = prop.cloud.type }

  			if (prop.edge) { edgeEnv = prop.edge.type }

  			return ({
  				name: (
  					<div>
  						<div>
  							{prop.name}
  						</div>
  						<div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
  							{prop.appId}
  						</div>
  					</div>
  				),
  				description: (
  					<div>
  						<div>
  							{prop.description}
  						</div>
  						<div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                Description
  						</div>
  					</div>
  				),
  				type: (
  					<div>
  						<div>
  							{prop.type === "Cloud" &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color={"primary"}>
                    Cloud
                  </Button>}
  							{prop.type === "Edge" &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color="info">
                    Edge
                  </Button>}
  							{prop.type === "Cloud & Edge" &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color="warning">
                  	{"Cloud & Edge"}
                  </Button>}
  							{cloudEnv &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color="success">
                  	{cloudEnv}
                  </Button>}
  							{edgeEnv &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color="primary">
                  	{edgeEnv}
                  </Button>}
  						</div>
  						<div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                App Type
  						</div>
  					</div>
  				),
  				aeTitle: (
  					<div>
  						<div>
  							{prop.aeTitle}
  						</div>
  						<div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                AE Title
  						</div>
  					</div>
  				),
  				options: (
  					<div style={{ textAlign: "right", paddingRight: "10px" }}>
  						<Button color={"primary"}
  							onClick={() => {
  								this.setState({
  									modal: (
  										<ManageApp onCancel={() => this.hideModal()}
  											app={prop}
  											onSave={data => this.updateApp(data)}
  										/>
  									)
  								})
  							}}
  						>Manage</Button>
  					</div>
  				)
  			})
  		})
  	}
  	return (
  		<React.Fragment>
  			{this.state.modal}
  			<GridContainer>
  				<ItemGrid xs={12} sm={12} md={12} lg={12}>
  					<HeaderCard
  						headerColor="blue"
  						cardTitle="Apps"
  						cardSubtitle="Configure and Add apps for the HealthLytix Platform"
  						content={
  							tableData &&
                <div>
                	<ItemGrid xs={12}>
                		<div style={{ textAlign: "right" }}>
                			<Button color="success"
                				onClick={this.handleNew}
                			>
                        New</Button>
                		</div>
                	</ItemGrid>
                	<ReactTable
                		sortable={false}
                		data={tableData}
                		noDataText="No Apps!"
                		columns={[
                			{
                				Header: "",
                				accessor: "name",
                				maxWidth: 200
                			},
                			{
                				Header: "",
                				accessor: "description",
                			},
                			{
                				Header: "",
                				accessor: "type"
                			},
                			{
                				Header: "",
                				accessor: "aeTitle",
                				maxWidth: 175
                			},
                			{
                				Header: "",
                				accessor: "options",
                			}
                		]}
                		minRows={2}
                		defaultPageSize={10}
                		showPaginationBottom
                		className="-highlight"
                	/>
                </div>}
  					/>
  				</ItemGrid>
  			</GridContainer>
  		</React.Fragment>
  	)
  }
}

export default WithNotifications(withRouter(withStyles(style)(Apps)))
