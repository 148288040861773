import {
  UPDATE_EDGE_VERSIONS
} from "actions/ActionTypes";

export const initialState = {
  edge: []
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
  case UPDATE_EDGE_VERSIONS:
    return {
      ...state,
      edge: action.versions
    };
  default:
    return state;
  }
};