import React, { Component } from "react";
import pt from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import numeral from "numeral";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import HeaderCard from "components/Cards/HeaderCard.jsx";
import Button from "components/CustomButtons/Button";
import { WithNotifications } from "components/HigherOrder";
import Badge from "components/Badge/Badge";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

import Table from "react-table";
import withStyles from "material-ui/styles/withStyles";
import tableStyles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";
import EditIcon from "material-ui-icons/Edit";
import DownloadIcon from "material-ui-icons/CloudDownload";

import { getInvoices, getAllOrgs } from "libs/apiLib";
import { downloadAsCSV } from "libs/utils";

export { default as Create } from "./Create";
export { default as Edit } from "./Edit";

const styles = {
  ...tableStyles,
  bold: {
    fontWeight: "bold"
  },
  noMargins: {
    margin: 0
  },
  noBottomMargin: {
    marginBottom: 0
  },
};

export class Invoices extends Component {
  static propTypes = {
    getOrgs: pt.func.isRequired,
    getInvoices: pt.func.isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    classes: pt.shape({
      label: pt.string.isRequired,
      bold: pt.string.isRequired,
      right: pt.string.isRequired,
      wideVerticalPadding: pt.string.isRequired,
      wideHorizontalPadding: pt.string.isRequired,
      noMargins: pt.string.isRequired,
      noBottomMargin: pt.string.isRequired,
    }).isRequired,
    invoices: pt.arrayOf(pt.shape({
      paymentTerms: pt.string.isRequired,
      orgId: pt.string.isRequired,
      orgName: pt.string.isRequired,
      userId: pt.string.isRequired,
      date: pt.string.isRequired,
      billingStart: pt.string.isRequired,
      billingEnd: pt.string.isRequired,
      poNumber: pt.string.isRequired,
      items: pt.arrayOf(pt.shape({
        item: pt.string.isRequired,
        quantity: pt.number.isRequired,
        price: pt.number.isRequired,
        discounts: pt.number.isRequired,
        credits: pt.number.isRequired,
        taxRate: pt.number.isRequired,
        totalAmount: pt.number.isRequired,
      })).isRequired,
      invoiceNumber: pt.string.isRequired,
      dueDate: pt.string.isRequired,
      invoiceStatus: pt.string.isRequired,
      payedDate: pt.string.isRequired,
      totalAmount: pt.number.isRequired,
      totalTax: pt.number.isRequired,
      subTotal: pt.number.isRequired,
      paymentConfirmation: pt.string.isRequired,
    })),
    location: pt.shape({
      search: pt.string,
      state: pt.shape({
        invoice: pt.shape({})
      })
    }).isRequired,
    history: pt.shape({
      push: pt.func.isRequired,
    }).isRequired,
    user: pt.shape({
      superAdmin: pt.bool,
      supportEngineer: pt.bool,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    const invoice = props.location.state && props.location.state.invoice;
    const search = new URLSearchParams(props.location.search);
    const orgId = search.get("orgId");
    this.state = {
      isLoading: false,
      invoices: {},
      invoice: invoice || null,
      orgId: orgId || null,
      orgs: [],
      allPagesFulfilled: false,
    };
  }

  componentDidMount() {
    // get invoices for orgId if orgId exists
    const { orgId } = this.state;
    if (orgId) {
      this.getInvoices(orgId);
    }
    this.getOrgs();
  }

  componentDidUpdate(_, prevState) {
    const { location } = this.props;
    const { invoices, invoice } = this.state;
    const search = new URLSearchParams(location.search);
    const id = search.get("id");
    const orgId = search.get("orgId");
    if (orgId && !this.state.orgId) {
      this.setState({ orgId });
    }

    // get invoices for orgId if no org invoices does not exist in state yet
    if (!prevState.isLoading && !this.state.isLoading && this.state.orgId && !invoices[this.state.orgId]) {
      this.getInvoices(orgId);
    }

    // orgId, id & invoices exist but invoice state does not, find it.
    if (this.state.orgId && id && invoices && invoices[orgId] && !invoice) {
      this.setState(prevState => ({
        ...prevState,
        invoice: prevState.invoices[orgId].find(i => i.invoiceNumber === id) || null,
      }));
    }

    // location.state has an invoice but local state does not, update it
    if (!invoice && location.state && location.state.invoice) {
      this.setState({ invoice: location.state.invoice });
    }

    // if id no longer exists in location.search, set invoice to null
    if (prevState.invoice && invoice && !id) {
      this.setState({ invoice: null });
    }

    // if orgId no longer exists in location.search, reset state
    if (!orgId && this.state.orgId) {
      this.setState({ orgId: null, invoice: null }, () => this.getInvoices());
    }
  }

  userHasEditPermissions = () => this.props.user && (this.props.user.superAdmin || this.props.user.supportEngineer)

  getOrgs = async () => {
    const { getOrgs, signalError, clearError } = this.props;
    this.setState({ isLoading: true });
    try {
      const results = await getOrgs();
      if (results.success && results.orgs.Items) {
        this.setState({ orgs: results.orgs.Items });
      } else if (!results.success) {
        throw new Error();
      }
    } catch (e) {
      const msg = e && e.message || "Error getting orgs. Please try again later";
      signalError(msg);
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  getInvoices = async (orgId) => {
    const { getInvoices, signalError, clearError } = this.props;
    this.setState({ isLoading: true });
    try {
      const results = await getInvoices(orgId);
      if (results.success && results.result.Items) {
        this.setState(prevState => {
          return {
            ...prevState,
            invoices: {
              ...prevState.invoices,
              [orgId]: prevState.invoices[orgId] ? [...prevState.invoices[orgId], ...results.result.Items] : results.result.Items
            },
            allPagesFulfilled: results.result.Items.length < 10
          };
        });
      } else if (!results.success) {
        throw new Error();
      }
    } catch (e) {
      const msg = e && e.message || "Error getting invoices. Please try again later";
      signalError(msg);
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false });
  }

  viewableInvoices = () => {
    return this.state.invoices && this.state.orgId ? this.state.invoices[this.state.orgId] : [];
  }

  handleInvoiceClick = (invoice) => {
    const param = new URLSearchParams();
    param.set("id", invoice.invoiceNumber);
    if (this.state.orgId) {
      param.set("orgId", this.state.orgId);
    }
    const search = param.toString();
    this.props.history.push({
      pathname: "/admin/invoices",
      search,
      state: {
        invoice
      }
    });
  }

  handleCreateInvoiceClick = (orgId) => this.props.history.push({
    pathname: "/admin/invoices/create",
    search: `?orgId=${orgId}`,
  })

  handleEditInvoiceClick = (invoice) => {
    const param = new URLSearchParams();
    param.set("id", invoice.invoiceNumber);
    if (this.state.orgId) {
      param.set("orgId", this.state.orgId);
    } else if (invoice.orgId) {
      param.set("orgId", invoice.orgId);
    }
    const search = param.toString();
    this.props.history.push({
      pathname: "/admin/invoices/edit",
      search,
      state: {
        invoice,
      },
    });
  }

  statusColor = (status) => {
    switch (status) {
    case "CREATED":
      return "primary";
    case "BILLED":
      return "warning";
    case "PAYED":
      return "success";
    case "INACTIVE":
      return "gray";
    default:
      return "primary";
    }
  }

  statusText = (row) => {
    const titlized = row.invoiceStatus[0] + row.invoiceStatus.substring(1).toLowerCase();
    switch (row.invoiceStatus) {
    case "PAYED":
      if (!row.payedDate || !moment(row.payedDate).isValid()) {
        return titlized;
      }
      return `${titlized} ${moment(row.payedDate).format("dddd MMMM Do YYYY")}`;
    case "BILLED":
      return `Due ${moment(row.dueDate).format("dddd MMMM Do YYYY")}`;
    default:
      return titlized;
    }
  }

  formatCell = (key) => (cell) => {
    const { label } = this.props.classes;
    let style;
    let value = cell.value;
    let labelText;
    switch (key) {
    case "invoiceNumber":
      value = <a style={{ cursor: "pointer" }} onClick={() => this.handleInvoiceClick(cell.original)}>{value}</a>;
      labelText = "Invoice ID";
      break;
    case "dueDate":
      value = moment(value).format("dddd MMMM Do YYYY");
      labelText = "Due Date";
      break;
    case "billingStart":
      labelText = "Billing Cycle";
      value = `${moment(value).format("MMMM Do[,] YYYY")} - ${moment(cell.original.billingEnd).format("MMMM Do[,] YYYY")}`;
      break;
    case "invoiceStatus":
      labelText = "Status";
      value = <Badge color={this.statusColor(value)}>{this.statusText(cell.original)}</Badge>;
      style = { marginTop: 0 };
      break;
    case "totalAmount":
      labelText = "Total";
      value = numeral(value).format("$ 0,0.00");
      break;
    case "subTotal":
      labelText = "Sub Total";
      value = numeral(value).format("$ 0,0.00");
      break;
    case "totalTax":
      labelText = "Tax";
      value = numeral(value).format("$ 0,0.00");
      break;
    default:
      return null;
    }
    return (
      <div>
        <div>{value}</div>
        <div className={label} style={style}>{labelText}</div>
      </div>
    );
  }

  renderAllInvoices = () => {
    const { isLoading } = this.state;
    const { classes: { wideHorizontalPadding } } = this.props;
    return (
      <GridContainer className={wideHorizontalPadding}>
        <ItemGrid xs={12}>
          <Table
            defaultSorted={[
              {
                id: "dueDate",
                desc: true
              }
            ]}
            noDataText="No Invoices For This Organization"
            data={this.viewableInvoices()}
            columns={[
              {
                accessor: "invoiceNumber",
              },
              {
                accessor: "dueDate",
                sortMethod: (a, b) => moment(a).isBefore(moment(b)) ? -1 : 1
              },
              {
                accessor: "billingStart",
                sortMethod: (a, b) => moment(a).isBefore(moment(b)) ? -1 : 1
              },
              {
                accessor: "invoiceStatus",
                maxWidth: 250,
              },
              {
                accessor: "subTotal",
                maxWidth: 150,
              },
              {
                accessor: "totalTax",
                maxWidth: 150,
              },
              {
                accessor: "totalAmount",
                maxWidth: 150,
              },
            ].map(c => ({ Cell: this.formatCell(c.accessor), Header: "", maxWidth: c.maxWidth, accessor: c.accessor, sortMethod: c.sortMethod }))
            }
            resizable={false}
            className="-highlight"
            loading={isLoading}
            pageSize={10}
            showPaginationBottom
          />
        </ItemGrid>
      </GridContainer>
    );
  }

  formatLineItemCell = (key) => (cell) => {
    const { label, right } = this.props.classes;
    let value = cell.value;
    let labelText;
    let className;
    switch (key) {
    case "item":
      labelText = "Item";
      break;
    case "quantity":
      labelText = "Quantity";
      value = numeral(value).format("0,0");
      break;
    case "price":
      labelText = "Price";
      value = numeral(value).format("$ 0,0.00");
      break;
    case "discounts":
      labelText = "Discounts";
      value = "- " + numeral(value).format("$ 0,0.00");
      break;
    case "credits":
      labelText = "Credits";
      value = "- " + numeral(value).format("$ 0,0.00");
      break;
    case "taxRate":
      labelText = "Tax Rate";
      value = numeral(value).format("0.00%");
      break;
    case "totalAmount":
      labelText = "Total";
      value = numeral(value).format("$ 0,0.00");
      className = right;
      break;
    default:
      return null;
    }
    return (
      <div className={className}>
        <div>{value}</div>
        <div className={label}>{labelText}</div>
      </div>
    );
  }

  handleDownload = () => {
    const { invoice } = this.state;
    const csv = invoice.items.reduce((csv, item) => {
      return [
        ...csv,
        `${item.item},${item.price},${item.quantity},${item.discounts},${item.credits},${item.taxRate},${item.totalAmount}`
      ];
    }, [
      "Item,Quantity,Price,Discounts,Credits,Tax Rate,Total"
    ]).join("\n");
    const fileName = `invoice-${invoice.invoiceNumber}.csv`;
    downloadAsCSV(fileName, csv);
  }

  renderSingleInvoice = () => {
    const { invoice } = this.state;
    const { classes: { bold, noMargins, noBottomMargin, wideHorizontalPadding, wideVerticalPadding, right } } = this.props;
    return (
      <GridContainer className={wideHorizontalPadding}>
        <ItemGrid xs={12}>
          {this.userHasEditPermissions() &&
            <Button right onClick={() => this.handleEditInvoiceClick(invoice)} color="transparent"><EditIcon /></Button>
          }
          <Button right onClick={this.handleDownload} color="transparent"><DownloadIcon /></Button>
          <Button size="lg" right color={this.statusColor(invoice.invoiceStatus)}>{this.statusText(invoice)}</Button>
          <h2 className={bold}>{invoice.orgName}</h2>
          <h4 className={cx(bold, noBottomMargin)}>{moment(invoice.date).format("MMMM Do[,] YYYY")}</h4>
          <p className={noMargins}>Generated</p>
          <h4 className={cx(bold, noBottomMargin)}>{moment(invoice.dueDate).format("MMMM Do[,] YYYY")}</h4>
          <p className={noMargins}>Due</p>
          <h4 className={cx(bold, noBottomMargin)}>{`${moment(invoice.billingStart).format("MMMM Do[,] YYYY")} - ${moment(invoice.billingEnd).format("MMMM Do[,] YYYY")}`}</h4>
          <p className={noMargins}>Billing Cycle</p>
          {
            invoice.paymentConfirmation &&
            <React.Fragment>
              <h4 className={cx(bold, noBottomMargin)}>Payment Confirmation Details</h4>
              <p className={noMargins}>{invoice.paymentConfirmation}</p>
            </React.Fragment>
          }
        </ItemGrid>
        <ItemGrid xs={12}>
          <Table
            defaultSorted={[
              {
                id: "item",
                desc: true
              }
            ]}
            noDataText="No Items"
            data={invoice.items || []}
            columns={[
              {
                accessor: "item",
              },
              {
                accessor: "price"
              },
              {
                accessor: "quantity"
              },
              {
                accessor: "discounts"
              },
              {
                accessor: "credits"
              },
              {
                accessor: "taxRate"
              },
              {
                accessor: "totalAmount"
              },
            ].map(c => ({ Cell: this.formatLineItemCell(c.accessor), Header: "", accessor: c.accessor }))
            }
            sortable={false}
            resizable={false}
            className={cx("-highlight", wideVerticalPadding)}
            pageSize={10}
            minRows={1}
            showPagination={false}
          />
        </ItemGrid>
        <ItemGrid xs={12} className={right}>
          <h4 className={cx(bold, noBottomMargin)}>{numeral(invoice.subTotal).format("$ 0,0.00")}</h4>
          <p>Subtotal</p>
          <h4 className={cx(bold, noBottomMargin)}>{numeral(invoice.totalTax).format("$ 0,0.00")}</h4>
          <p>Tax</p>
          <h2 className={cx(bold, noBottomMargin)}>{numeral(invoice.totalAmount).format("$ 0,0.00")}</h2>
          <p>Total</p>
        </ItemGrid>
      </GridContainer>
    );
  }

  handleViewInvoicesClick = (orgId) => this.props.history.push({
    pathname: "/admin/invoices",
    search: `?orgId=${orgId}`,
  })

  formatOrgCell = (key) => (cell) => {
    const { label, right } = this.props.classes;
    let value = cell.value;
    let labelText;
    let className;
    switch (key) {
    case "orgName":
      labelText = cell.original.orgId;
      break;
    case "accountOwner":
      labelText = "Account Owner";
      break;
    case "options":
      return (
        <div className={right}>
          <CustomDropdown
            buttonText="Manage"
            buttonColor="primary"
            dropdownList={["Create Invoice", "View Invoices"]}
            onAction={(action) => {
              switch (action) {
              case "Create Invoice":
                return this.handleCreateInvoiceClick(cell.original.orgId);
              case "View Invoices":
                return this.handleViewInvoicesClick(cell.original.orgId);
              default:
                return;
              }
            }}
          />
        </div>
      );
    default:
      return null;
    }
    return (
      <div className={className}>
        <div>{value}</div>
        <div className={label}>{labelText}</div>
      </div>
    );
  }

  renderOrgs = () => {
    const { isLoading, orgs } = this.state;
    const { classes: { wideHorizontalPadding } } = this.props;
    return (
      <GridContainer className={wideHorizontalPadding}>
        <ItemGrid xs={12}>
          <Table
            defaultSorted={[
              {
                id: "orgName",
                desc: true
              }
            ]}
            noDataText="No Orgs"
            data={orgs}
            columns={[
              {
                accessor: "orgName",
              },
              {
                accessor: "accountOwner",
              },
              {
                accessor: "options",
              },
            ].map(c => ({ Cell: this.formatOrgCell(c.accessor), Header: "", maxWidth: c.maxWidth, accessor: c.accessor }))
            }
            sortable={false}
            resizable={false}
            className="-highlight"
            loading={isLoading}
            pageSize={10}
            showPaginationBottom
          />
        </ItemGrid>
      </GridContainer>
    );
  }

  render() {
    const { invoice, orgId } = this.state;
    return (
      <GridContainer>
        <ItemGrid xs={12}>
          <HeaderCard
            headerColor="orange"
            cardTitle={invoice ? "Invoice" : "Invoices"}
            cardSubtitle={invoice ? invoice.invoiceNumber : "View and Manage All Invoices"}
            content={
              invoice ? this.renderSingleInvoice() : orgId ? this.renderAllInvoices() : this.renderOrgs()
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

export default connect((state) => ({
  user: state.user.user,
}),
() => ({
  getInvoices: (orgId) => getInvoices(orgId),
  getOrgs: () => getAllOrgs(),
}))(WithNotifications(withRouter(withStyles(styles)(Invoices))));