import React from "react";
import pt from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import User from "models/User";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// react table
import ReactTable from "react-table";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// material-ui icons
import AccountCircle from "material-ui-icons/AccountCircle";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import Button from "components/CustomButtons/Button.jsx";
import LoadingModal from "components/LoadingModal/LoadingModal.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// local components
import InviteUser from "./components/InviteUser";

// api
import {
  fetchUsers, updateUserRole,
  inviteUser, deleteUser
} from "libs/apiLib";

const style = {
  ...dashboardStyle,
  ...buttonsStyle,
  ...sweetAlertStyle
};

export class Users extends React.Component {
  static propTypes = {
    classes: pt.shape({
      button: pt.string,
      success: pt.string,
      danger: pt.string,
    }).isRequired,
    location: pt.shape({
      search: pt.string,
    }).isRequired,
    org: pt.shape({}).isRequired,
    user: pt.instanceOf(User).isRequired,
    updateUserRole: pt.func.isRequired,
    fetchUsers: pt.func.isRequired,
    inviteUser: pt.func.isRequired,
    deleteUser: pt.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      users: null,
      modal: null,
      orgId: null
    };

    this.getUsers = this.getUsers.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.userAction = this.userAction.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleInviteUser = this.handleInviteUser.bind(this);
    this.inviteUser = this.inviteUser.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.showError = this.showError.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const orgId = urlParams.get("orgId");

    if (orgId) {
      this.setState({ orgId }, async () => { await this.getUsers(); });
    } else {
      await this.getUsers();
    }
  }

  async getUsers() {
    try {
      const results = await this.props.fetchUsers(this.state.orgId);
      if (results) {
        if (results.users) { this.setState({ users: results.users.Items.map(u => new User(u)) }); }
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleRefresh = async () => {
    this.setState({
      modal: <LoadingModal />
    });

    await this.getUsers();
    this.hideModal();
  }

  hideModal() {
    this.setState({
      modal: null
    });
  }

  async userAction(user, action) {
    switch (action) {
    case "Add Admin Role":
      this.handleUserRoleChange(user, "Admin", true);
      break;
    case "Remove Admin Role":
      this.handleUserRoleChange(user, "Admin", false);
      break;
    case "Remove Viewer Role":
      this.handleUserRoleChange(user, "Viewer", false);
      break;
    case "Add Viewer Role":
      this.handleUserRoleChange(user, "Viewer", true);
      break;
    case "Remove App Admin Role":
      this.handleUserRoleChange(user, "AppAdmin", false);
      break;
    case "Add App Admin Role":
      this.handleUserRoleChange(user, "AppAdmin", true);
      break;
    case "Remove Research Role":
      this.handleUserRoleChange(user, "Research", false);
      break;
    case "Add Research Role":
      this.handleUserRoleChange(user, "Research", true);
      break;
    case "Delete User":
      this.handleDeleteUser(user);
      break;
    case "Remove Support Engineer Role":
      this.handleUserRoleChange(user, "SupportEngineer", false);
      break;
    case "Add Support Engineer Role":
      this.handleUserRoleChange(user, "SupportEngineer", true);
      break;
    case "Remove Super Admin Role":
      this.handleUserRoleChange(user, "SuperAdmin", false);
      break;
    case "Add Super Admin Role":
      this.handleUserRoleChange(user, "SuperAdmin", true);
      break;
    case "Remove Analytics Role":
      this.handleUserRoleChange(user, "AnalyticsRole", false);
      break;
    case "Add Analytics Role":
      this.handleUserRoleChange(user, "AnalyticsRole", true);
      break;
    case "Remove Billing Admin Role":
      this.handleUserRoleChange(user, "BillingAdmin", false);
      break;
    case "Add Billing Admin Role":
      this.handleUserRoleChange(user, "BillingAdmin", true);
      break;
    default:
      break;
    }
  }

  handleUserRoleChange = async (user, role, enabled) => {
    this.setState({
      modal: <LoadingModal />
    });

    try {
      await this.props.updateUserRole(user.userName, role, enabled);
      await this.getUsers();
    } catch (e) {
      console.error(e);
    }

    this.hideModal();

  }

  handleInviteUser = () => {
    this.setState({
      modal: <InviteUser
        onCancel={this.hideModal}
        onInvite={(email) => this.inviteUser(email)}
      />
    });
  }

  handleDeleteUser = (user) => {
    this.setState({
      modal: (<SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => this.hideModal()}
        onCancel={() => this.hideModal()}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        showConfirm={false}
      >
        <div>
          {`${user.firstName} will be completely removed from the HealthLytix platform and won't be able to sign in again. ${user.firstName} will need to be re-invited.`}
        </div>
        <div style={{ marginTop: "40px" }}>
          <Button color="danger" style={{ marginRight: "10px" }} onClick={() => this.hideModal()}>Cancel</Button>
          <Button color="success" onClick={() => this.deleteUser(user)}>{`Yes, delete ${user.firstName}!`}</Button>
        </div>
      </SweetAlert>
      )
    });
  }

  async deleteUser(user) {
    this.setState({
      modal: <LoadingModal />
    });

    try {

      await this.props.deleteUser(user);
      await this.getUsers();

      this.showSuccess(`${user.firstName} has been deleted!`);
    } catch (e) {
      console.error(e);
      this.showError(`Failed to delete ${user.firstName}`);
    }
  }

  async inviteUser(email) {
    this.setState({
      modal: <LoadingModal />
    });

    try {
      await this.props.inviteUser(email, this.state.orgId);
      this.showSuccess("User Invited!");
    } catch (e) {
      console.log(e);
      this.showError("Failed to invite the user");
    }

  }

  showSuccess(msg) {
    this.setState({
      modal: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Done!"
          onConfirm={() => this.hideModal()}
          onCancel={() => this.hideModal()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div>
            {msg}
          </div>
          <div style={{ marginTop: "40px" }}>
            <Button color="success" onClick={() => this.hideModal()}>Done</Button>
          </div>
        </SweetAlert>
      )
    });
  }

  showError(err) {
    this.setState({
      modal: (
        <SweetAlert
          type="error"
          style={{ display: "block", marginTop: "-100px" }}
          title="Ohh No!"
          onConfirm={() => this.hideModal()}
          onCancel={() => this.hideModal()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div>
            {err}
          </div>
          <div style={{ marginTop: "40px" }}>
            <Button onClick={() => this.hideModal()}>Close</Button>
          </div>
        </SweetAlert>
      )
    });
  }

  userActions = (orgUser) => {
    const { user } = this.props;
    const actions = [];

    if (!user.isHLXSupport && !user.admin) {
      return [];
    }
    if (user.isHLXEmployee && !user.isHLXSupport && !orgUser.isHLXEmployee) {
      return [];
    }

    if (orgUser.isViewer) { actions.push("Remove Viewer Role"); }
    else { actions.push("Add Viewer Role"); }

    if (orgUser.admin) {
      if (user.userId !== orgUser.userId) { actions.push("Remove Admin Role"); }
    } else { actions.push("Add Admin Role"); }

    if (user.isHLXSupport && orgUser.isHLXEmployee) {
      if (orgUser.isResearch) { actions.push("Remove Research Role"); }
      else { actions.push("Add Research Role"); }

      if (orgUser.analyticsRole) { actions.push("Remove Analytics Role"); }
      else { actions.push("Add Analytics Role"); }

      if (orgUser.appAdmin) { actions.push("Remove App Admin Role"); }
      else { actions.push("Add App Admin Role"); }

      if (orgUser.billingAdmin) { actions.push("Remove Billing Admin Role"); }
      else { actions.push("Add Billing Admin Role"); }

      if (user.superAdmin) {
        if (orgUser.supportEngineer && user.userId !== orgUser.userId) { actions.push("Remove Support Engineer Role"); }
        else { actions.push("Add Support Engineer Role"); }
      }

      if (user.superAdmin && user.userId !== orgUser.userId) {
        if (orgUser.superAdmin) { actions.push("Remove Super Admin Role"); }
        else { actions.push("Add Super Admin Role"); }
      }
    }

    if (user.userId !== orgUser.userId) {
      actions.push({ divider: true });
      actions.push("Delete User");
    }

    return actions;
  }

  render() {
    const { org } = this.props;

    let tableData = [];

    if (this.state.users) {
      tableData = this.state.users.map((user) => {

        let actions = this.userActions(user);

        return ({
          status: (
            <div>
              <IconButton>
                <AccountCircle style={{ marginTop: "-2px", marginLeft: "1px" }} />
              </IconButton>
            </div>),
          user: (
            <div>
              <div>
                {user.lastName + ", " + user.firstName}
              </div>
              <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                {user.userName}
              </div>
            </div>
          ),
          access: (
            <div>
              <div>
                {user.isViewer &&
                  <Button size="xs" round>
                    Viewer
                  </Button>}
                {user.admin &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color="warning">
                    Admin
                  </Button>}
                {user.appAdmin &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color="info">
                    App
                  </Button>}
                {user.isResearch &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color="rose">
                    Research
                  </Button>}
                {user.supportEngineer &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color="tumblr">
                    Support
                  </Button>}
                {user.analyticsRole &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color="twitter">
                    Analytics
                  </Button>}
                {user.billingAdmin &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color="success">
                    Billing
                  </Button>}
                {user.superAdmin &&
                  <Button size="xs" style={{ marginLeft: "5px" }} round color="danger">
                    Super
                  </Button>}
              </div>
              <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                Access Level
  						</div>
            </div>
          ),
          lastLoggedIn: (
            user.lastLoggedIn &&
            <div>
              <div>
                {`${moment.duration(moment().diff(moment(user.lastLoggedIn))).humanize()} ago`}
              </div>
              <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                Last Logged In
            	</div>
            </div>
          ),
          options: (
            <div style={{ textAlign: "right", paddingRight: "10px" }}>
              <CustomDropdown
                buttonText="Manage"
                buttonColor="primary"
                dropdownList={actions}
                onAction={(action) => this.userAction(user, action)}
              />
            </div>
          )
        });
      });
    }

    return (
      <div>
        <GridContainer>
          {this.state.modal}
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              headerColor="blue"
              cardTitle="Users"
              cardSubtitle={`In ${org.orgName}`}
              content={
                tableData &&
                <div>
                  <ItemGrid xs={12}>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        onClick={this.handleRefresh}
                        style={{ marginRight: "10px" }}
                      >Refresh</Button>
                      <Button
                        onClick={this.handleInviteUser}
                        color="success"
                      >Invite</Button>
                    </div>
                  </ItemGrid>
                  <ReactTable
                    sortable={false}
                    data={tableData}
                    noDataText="No Users!"
                    columns={[
                      {
                        Header: "",
                        accessor: "status",
                        maxWidth: 70
                      },
                      {
                        Header: "",
                        accessor: "user",
                        maxWidth: 250
                      },
                      {
                        Header: "",
                        accessor: "access"
                      },
                      {
                        Header: "",
                        accessor: "lastLoggedIn",
                        maxWidth: 150
                      },
                      {
                        Header: "",
                        accessor: "options",
                        maxWidth: 150
                      }
                    ]}
                    minRows={2}
                    defaultPageSize={10}
                    showPaginationBottom
                    className="-highlight"
                  />
                </div>}
            />
          </ItemGrid>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: new User(state.user.user),
  org: state.user.org
});

const mapDispatchToProps = () => ({
  updateUserRole: (userName, role, enabled) => updateUserRole(userName, role, enabled),
  fetchUsers: (orgId) => fetchUsers(orgId),
  inviteUser: (email, orgId) => inviteUser(email, orgId),
  deleteUser: (user) => deleteUser(user),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(style)(Users)));
