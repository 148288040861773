import React from "react";
import pt from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import IconCard from "components/Cards/IconCard";
import IconError from "material-ui-icons/Error";
import IconWarning from "material-ui-icons/Warning";

const errorUnknown = "Error running application. Please contact support@healthlytix.com for support.";
const nqWarningMessage = "Error in NeuroQuant DICOM file";
const warnings = {
  ageWarning: { key: "ageWarning", message: "Patient age is outside accepted range." },
  ancestralWarning: { key: "ancestralWarning", message: "An ancestry other than European was detected. Genetic risk assessment is only validated for use in individuals of European ancestry." },
  snpsWarning: { key: "snpsWarning", message: "Sample or uploaded genotype data did not provide enough high-quality genetic variants to perform genetic risk assessment." },
  apoeWarning: { key: "apoeWarning", message: "ApoE status cannot be determined." },
  oldDicomWarning: { key: "oldDicomWarning", message: "Imaging acquired >1 year ago and may not accurately reflect the patient's current health status." },
  nqSuccessWarning: { key: "nqSuccessWarning", message: nqWarningMessage },
  nqParcellationWarning: { key: "nqParcellationWarning", message: nqWarningMessage },
  nqHeaderWarning: { key: "nqHeaderWarning", message: nqWarningMessage },
  nqVersionWarning: { key: "nqVersionWarning", message: nqWarningMessage },
  invalidGenotypeWarning: { key: "invalidGenotypeWarning", message: "Error in genotype file" },
};

const geneticsErrors = [
  warnings.ancestralWarning,
  warnings.snpsWarning,
  warnings.apoeWarning,
  warnings.invalidGenotypeWarning
];
const geneticsWarnings = [
];
const imagingWarnings = [
  warnings.ageWarning,
  warnings.oldDicomWarning,
];
const imagingErrors = [
  warnings.nqSuccessWarning,
  warnings.nqParcellationWarning,
  warnings.nqHeaderWarning,
  warnings.nqVersionWarning
];

export const WarningItem = ({ message }) => <ListItem message={message} />;
export const ErrorItem = ({ message }) => <ListItem message={message} />;
const ListItem = ({ message }) => (
  <li><p>{message}</p></li>
);

WarningItem.propTypes = {
  message: pt.string.isRequired,
};
ErrorItem.propTypes = {
  message: pt.string.isRequired,
};
ListItem.propTypes = {
  message: pt.string.isRequired,
};

const getStatus = (report, type) => (key) => {
  if (!report) {
    return [];
  }
  return report[key] && report[key][type] || [];
};


const Index = ({ report, appError }) => {
  if (!report && !appError) {
    return null;
  }
  const errorStatus = getStatus(report, "errors");
  const warningStatus = getStatus(report, "warnings");
  const genetics = {
    errors: errorStatus("genetics"),
    warnings: warningStatus("genetics")
  };
  const imaging = {
    errors: errorStatus("imaging"),
    warnings: warningStatus("imaging")
  };
  const hasErrors = Boolean(genetics.errors.length || imaging.errors.length || appError !== undefined);
  const hasWarnings = Boolean(genetics.warnings.length || imaging.warnings.length); 
  const errorItems = [];
  if (appError) {
    const msg = warnings[appError] && warnings[appError].message || errorUnknown;
    errorItems.push(msg);
  }
  if (genetics.errors.length > 0) {
    errorItems.push(...geneticsErrors.filter(w => genetics.errors.some(x => x === w.key)).map(w => w.message));
  }
  if (imaging.errors.length > 0) {
    errorItems.push(...imagingErrors.filter(w => imaging.errors.some(x => x === w.key)).slice(0, 1).map(w => w.message));
  }
  const warningItems = [];
  if (genetics.warnings.length > 0) {
    warningItems.push(...geneticsWarnings.filter(w => genetics.warnings.some(x => x === w.key)).map(w => w.message));
  }
  if (imaging.warnings.length > 0) {
    warningItems.push(...imagingWarnings.filter(w => imaging.warnings.some(x => x === w.key)).slice(0, 1).map(w => w.message));
  }
  return (
    <GridContainer justify="center">
      {hasErrors &&
        <IconCard
          iconColor="red"
          icon={IconError}
          title="Application Errors"
          content={
            <React.Fragment>
              <h4>The PDF report could not be generated due to the following errors:</h4>
              <ul>
                {errorItems.map((message, i) => <ErrorItem key={i} message={message} />)}
              </ul>
            </React.Fragment>
          }
        />
      }
      {hasWarnings &&
        <IconCard
          iconColor="orange"
          icon={IconWarning}
          title="Application Warnings"
          content={
            <React.Fragment>
              <h4>The PDF report may have been generated but contains the following warnings:</h4>
              <ul>
                {warningItems.map((message, i) => <WarningItem key={i} message={message} />)}
              </ul>
            </React.Fragment>
          }
        />
      }
    </GridContainer>
  );
};

Index.propTypes = {
  report: pt.shape({
    imaging: pt.shape({
      errors: pt.arrayOf(pt.string).isRequired,
      warnings: pt.arrayOf(pt.string).isRequired,
    }).isRequired,
    genetics: pt.shape({
      errors: pt.arrayOf(pt.string).isRequired,
      warnings: pt.arrayOf(pt.string).isRequired,
    }).isRequired,
  }).isRequired,
  appError: pt.string,
};

export default Index;