import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// local components
import Stats from "./components/Stats.jsx";
import Cases from "./components/Cases.jsx";
import COCases from "./components/ConsumerOrgCases";
import SetOrgModal from "./components/SetOrgModal.jsx";
import AcceptTerms from "./components/AcceptTerms.jsx";
import TermsAndConditions from "views/TermsAndConditions/TermsAndConditions.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import { authUser, invokeApig, getCurrentUserAccessToken } from "libs/awsLib";
import { acceptTermsAndConditions } from "libs/apiLib";
import { authenticate, authenticating, logout } from "actions/index";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null
    };

    this.handleNeedOrgUpdate = this.handleNeedOrgUpdate.bind(this);
    this.handleNeedAcceptTerms = this.handleNeedAcceptTerms.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  async componentDidMount() {

    const { org, user } = this.props;

    if (user && !user.acceptedTerms) {
      this.handleNeedAcceptTerms();
    }

    if (user && org && user.admin && !org.setupDone) {
      this.handleNeedOrgUpdate();
    }
  }

  handleNeedOrgUpdate() {
    this.setState({
      modal: (
        <SetOrgModal onConfirm={() => {
          this.props.history.push({ pathname: "/myorg/settings" });
        }} />
      )
    });
  }

  handleNeedAcceptTerms() {
    const { logOut, isUserAuthenticating } = this.props;
    this.setState({
      modal: (
        <AcceptTerms onConfirm={async () => {
          await acceptTermsAndConditions();
          this.setState({ modal: null });
          await this.updateUser();
        }} onReadTerms={() => {
          this.setState({
            modal: (
              <TermsAndConditions modal={true}
                onClose={() => this.handleNeedAcceptTerms()}
              />)
          });
        }}
        onCancel={() => {
          isUserAuthenticating(false);
          logOut();
          this.props.history.push("/pages/login");
        }}
        />
      )
    });
  }

  async updateUser() {
    const { isUserAuthenticated, isUserAuthenticating } = this.props;

    // update is authenticating flag
    isUserAuthenticating(true);

    try {
      if (await authUser()) {

        // get user from platform context
        const accessToken = await getCurrentUserAccessToken();
        const result = await invokeApig({
          path: "/id/myUser",
          headers: {
            "access-token": accessToken
          }
        });

        if (!result) { throw new Error("Failed to get user"); }
        else if (!result.user) { throw new Error("Failed to get user"); }

        // update store
        isUserAuthenticated(true, result.user);
        isUserAuthenticating(false);
      } else { throw new Error("Failed to authenticate"); }
    } catch (e) {
      const { logOut } = this.props;
      isUserAuthenticating(false);
      logOut();
      this.props.history.push("/pages/login");
    }
  }

  render() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const orgId = urlParams.get("orgId");
    return (
      <div>
        {this.state.modal}
        <Stats
          orgId={orgId}
          start={moment()}
          end={moment()}
        />
        <Cases
          orgID={orgId}
        />
        {this.props.multiOrgUploadAllowed &&
          <COCases
            orgID={orgId}
          />
        }
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  isAuthenticating: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.shape({}),
  org: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  logOut: PropTypes.func.isRequired,
  isUserAuthenticating: PropTypes.func.isRequired,
  isUserAuthenticated: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  multiOrgUploadAllowed: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticating: state.user.isAuthenticating,
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  org: state.user.org,
  multiOrgUploadAllowed: Boolean(state.assumedOrg ? state.assumedOrg.multiOrgUploadAllowed : state.user.org.multiOrgUploadAllowed),
});

const mapDispatchToProps = dispatch => ({
  isUserAuthenticated: (isAuthenticated, user) => {
    dispatch(authenticate(isAuthenticated, user));
  },
  isUserAuthenticating: (isAuthenticating) => {
    dispatch(authenticating(isAuthenticating));
  },
  logOut: () => {
    dispatch(logout());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(dashboardStyle)(Dashboard)));
