import React from "react";
import PropTypes from "prop-types";
import Chartist from "react-chartist";
import IconCard from "components/Cards/IconCard.jsx";
import Badge from "components/Badge/Badge.jsx";
import Reports from "models/Reports";

// Styles
import Build from "material-ui-icons/Build";

const pieChartColors = [
  "#00bcd4",
  "#f44336",
  "#ff9800",
  "#9c27b0",
  "#4caf50",
  "#9C9B99",
  "#dd4b39",
  "#35465c",
  "#e52d27",
  "#55acee",
  "#cc2127",
  "#1769ff",
  "#6188e2",
  "#a748ca"
];

export const AppUsageContent = ({ report }) => {
  if (!report) {
    return <h3><small>No report</small></h3>;
  }
  const apps = report.appStats();
  if (!apps) {
    return <h3><small>No app usage reported for this date range</small></h3>;
  }
  return (
    <React.Fragment>
      <Chartist
        className="ct-double-octave"
        type="Pie"
        data={{
          series: Object.keys(apps).map((name, i) => ({ value: apps[name].successful + apps[name].failures, meta: { style: `stroke: ${pieChartColors[i]}; stroke-width: 30px;` } })),
        }}
        options={{
          donutWidth: 30,
          donut: true,
          donutSolid: true,
        }}
        listener={{
          draw: (context) => {
            if (context.type === "slice" && context.meta && context.meta.style) {
              context.element.attr(context.meta);
            }
          }
        }}
      />
      <h5>Legend</h5>
      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
        {
          Object.keys(apps).map((name, i) => {
            return (
              <li key={i} style={{ display: "inline-block", margin: "0 15px 15px 0" }}>
                <Badge style={{ backgroundColor: pieChartColors[i] }}>
                  {name}
                </Badge>
              </li>
            );
          })
        }
      </ul>
    </React.Fragment>
  );
};

AppUsageContent.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export const AppUsage = ({ report }) => (
  <IconCard
    iconColor="orange"
    icon={Build}
    title="App Usage"
    content={<AppUsageContent report={report} />}
  />
);

AppUsage.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export default AppUsage;