import React from "react";
import pt from "prop-types";
import withStyles from "material-ui/styles/withStyles";
import PlainCard from "components/Cards/PlainCard.jsx";
import Reports from "models/Reports";

const style = {
  base: {
    textAlign: "center",
    color: "#FFFFFF",
    background: "linear-gradient(to right, #0fb8ad, #1fc8db)",
  }
};

export const TotalActiveDevices = ({ classes, report }) => {
  let total = 0;
  if (report) {
    const devices = report.edgeDeviceUsage();
    total = Object.keys(devices).length;
  }
  return (
    <PlainCard
      customClasses={classes.base}
    >
      <h1 style={{ fontSize: "3em" }}>{total}</h1>
      <h3 style={{ margin: 0 }}>Active Devices</h3>
    </PlainCard>
  );
};

TotalActiveDevices.propTypes = {
  classes: pt.shape({
    base: pt.string.isRequired,
  }).isRequired,
  report: pt.instanceOf(Reports)
};

export default withStyles(style)(TotalActiveDevices);