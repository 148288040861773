import React from "react";
import PropTypes from "prop-types";

// react table
import ReactTable from "react-table";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";

const style = {
  ...dashboardStyle,
  ...buttonsStyle
}

class LogsTable extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    let tableData = [];
    if (this.props.data) {
      tableData = this.props.data.map((prop, key) => {
        return ({
            key: (
            <div>
                <div>
                    {prop.Key ? prop.Key.substring(prop.Key.lastIndexOf("/") + 1, prop.Key.length) : "" }
                </div>
                <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px"}}>
                    Log Name
                </div>
            </div>
            ),
            size: (
                <div>
                    <div>
                        {prop.Size}
                    </div>
                    <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px"}}>
                        Size
                    </div>
                </div>
                ),
            options: (
            <div style={{ textAlign: "right", paddingRight: "10px"}}>
              <Button onClick={(e) => { e.preventDefault(); this.props.onLoad(prop.Key) }}>View</Button>
            </div>
          )
        });
      });
    }
    return (
        tableData &&
        <ReactTable
        sortable={false}
        data={tableData}
        noDataText="No Logs!"
        columns={[
            {
            Header: "",
            accessor: "key",
            },
            {
            Header: "",
            accessor: "size",
            },
            {
            Header: "",
            accessor: "options",
            }
        ]}
        defaultSorted={[
            {
              id: "key",
              desc: true
            }
        ]}
        minRows={2}
        defaultPageSize={10}
        showPaginationBottom
        className="-highlight"
        />
    );
  }
}

LogsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(LogsTable);
