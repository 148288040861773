import {
  STORE_ORG_REPORT, STORE_ORGS_REPORT, STORE_GLOBAL_REPORT,
  STORE_SAMPLE_KITS_REPORT, STORE_GLOBAL_SAMPLE_KITS_REPORT,
  STORE_ORG_PATIENTS_REPORT, STORE_TOTAL_ORGS, STORE_TOTAL_ORGS_WITH_DEVICES
} from "actions/ActionTypes";

const initialState = {
  totalOrgs: 0,
  totalOrgsWithDevices: 0,
  orgs: null,
  global: null,
  sampleKits: {
    global: null
  },
  patients: {}
};

const reports = (state = initialState, action) => {
  const { orgID, report, key } = action;
  switch (action.type) {
  case STORE_TOTAL_ORGS_WITH_DEVICES:
    return {
      ...state,
      totalOrgsWithDevices: action.total
    };
  case STORE_TOTAL_ORGS:
    return {
      ...state,
      totalOrgs: action.total
    };
  case STORE_ORG_REPORT:
    return {
      ...state,
      [orgID]: {
        ...state[orgID],
        [key]: report
      }
    };
  case STORE_ORGS_REPORT:
    return {
      ...state,
      orgs: {
        ...state.orgs,
        [key]: report
      }
    };
  case STORE_GLOBAL_REPORT:
    return {
      ...state,
      global: {
        ...state.global,
        [key]: report
      }
    };
  case STORE_GLOBAL_SAMPLE_KITS_REPORT:
    return {
      ...state,
      sampleKits: {
        ...state.sampleKits,
        global: {
          ...state.sampleKits.global,
          [key]: report
        }
      }
    };
  case STORE_SAMPLE_KITS_REPORT:
    return {
      ...state,
      sampleKits: {
        ...state.sampleKits,
        [orgID]: {
          ...state.sampleKits[orgID],
          [key]: report
        }
      }
    };
  case STORE_ORG_PATIENTS_REPORT:
    return {
      ...state,
      patients: {
        ...state.patients,
        [orgID]: {
          ...state.patients[orgID],
          [key]: report
        }
      }
    };
  default:
    return state;
  }
};

export default reports;
