import React from "react";
import PropTypes from "prop-types";
import IconCard from "components/Cards/IconCard.jsx";
import { Bar } from "components/Charts";
import WorkIcon from "material-ui-icons/Work";

export class GeneticKits extends React.Component {
  static propTypes = {
    report: PropTypes.arrayOf(PropTypes.shape({
      orgId: PropTypes.string,
      ORDERED: PropTypes.number,
      SHIPPED: PropTypes.number,
      USED: PropTypes.number,
      RECEIVED: PropTypes.number,
      PROCESSED: PropTypes.number,
      UNUSABLE: PropTypes.number,
    }))
  };

  series = () => {
    const { report } = this.props;
    if (!report) {
      return [];
    }
    return report.reduce((series, report) => {
      const ordered = report.ORDERED || 0;
      const used = report.USED || 0;
      if (!series.length) {
        return [
          { value: ordered, meta: { style: "stroke-width: 30px;" } },
          { value: used, meta: { style: "stroke-width: 30px;" } },
        ];
      }
      return [
        { ...series[0], value: series[0].value + ordered },
        { ...series[1], value: series[1].value + used }
      ];
    }, []);
  }

  render() {
    const series = this.series();
    return (
      <IconCard
        iconColor="orange"
        icon={WorkIcon}
        title="Genetic Kit Usage"
        content={(
          <React.Fragment>
            <p>View how many genetic kits were ordered and used within the time frame selected above.</p>
            <Bar
              noXSeriesRender={() => <h3><small>No genetic kit analytics</small></h3>}
              emptyXSeriesRender={() => <h3><small>No genetic kit analytics</small></h3>}
              xLabels={[
                "Ordered",
                "Used"
              ]}
              xSeries={[series]}
            />
          </React.Fragment>
        )}
      />
    );
  }
}

export default GeneticKits;