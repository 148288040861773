import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// javascript plugin used to create scrollbars on windows
import { NavLink } from "react-router-dom";
import cx from "classnames";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Drawer from "material-ui/Drawer";
import List from "material-ui/List";
import ListItem from "material-ui/List/ListItem";
import ListItemIcon from "material-ui/List/ListItemIcon";
import ListItemText from "material-ui/List/ListItemText";
import Hidden from "material-ui/Hidden";
import Collapse from "material-ui/transitions/Collapse";

// core components
import HeaderLinks from "views/Header/HeaderLinks.jsx";

// components
import SidebarWrapper from "./SidebarWrapper";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import avatar from "assets/img/faces/avatar2.png";
import HytxLogo from "assets/img/logo.png";

// actions
import { logout } from "actions/index";
import { publishAuditTrail } from "libs/apiLib";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components"),
      openForms: this.activeRoute("/forms"),
      openTables: this.activeRoute("/tables"),
      openMaps: this.activeRoute("/maps"),
      openPages: this.activeRoute("-page"),
      miniActive: true
    };
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  handleLogout = async (event) => {
  	event.preventDefault();

  	const { logOut, authUser } = this.props;
    
    try {
      await publishAuditTrail({
        type: "deleteUserAuth",
        description: `${authUser.firstName} ${authUser.lastName} (${authUser.userName}) successfully logged out.`, 
        PHI: false
      });
    } catch (e) {
    }

    logOut();

  	this.props.history.push("/pages/login");
  }

  location(pathname) {
    const url = new URLSearchParams();
    const currentURL = new URLSearchParams(this.props.location.search);
    const removeOrgIDQueryString = pathname.startsWith("/admin");
    currentURL.forEach((value, key) => {
      if (key === "orgId" && !removeOrgIDQueryString) {
        url.set(key, value);
      }
    });
    return {
      ...this.props.location,
      pathname,
      search: url.toString()
    };
  }

  render() {
  	const {
  		classes,
  		color,
  		image,
  		logoText,
  		routes,
  		bgColor,
  		isAuthenticated,
  		authUser
  	} = this.props;
  	const itemText =
      classes.itemText +
      " " +
      cx({
      	[classes.itemTextMini]: this.props.miniActive && this.state.miniActive
      });
  	// const collapseItemText =
  	//   classes.collapseItemText +
  	//   " " +
  	//   cx({
  	//     [classes.collapseItemTextMini]:
  	//       this.props.miniActive && this.state.miniActive
  	//   });
  	const userWrapperClass =
      classes.user +
      " " +
      cx({
      	[classes.whiteAfter]: bgColor === "white"
      });
  	const caret = classes.caret;
  	// const collapseItemMini = classes.collapseItemMini;
  	const photo = classes.photo;
  	const userClassnames = cx({
  		[classes.collapseItemLink]: true,
  		[classes[color]]: this.activeRoute("/profile")
  	});
  	var user = (
  		<div className={userWrapperClass}>
  			<div className={photo}>
  				<img src={avatar} className={classes.avatarImg} alt="..." />
  			</div>
  			<List className={classes.list}>
  				<ListItem className={classes.item + " " + classes.userItem}>
  					<a
  						style={{
  							cursor: "pointer"
  						}}
  						className={classes.itemLink + " " + classes.userCollapseButton}
  						onClick={() => this.openCollapse("openAvatar")}
  					>
  						<ListItemText
  							primary={isAuthenticated ? `${authUser.lastName}, ${authUser.firstName}` : "Please login"}
  							disableTypography={true}
  							className={itemText + " " + classes.userItemText}
  							secondary={
  								<b
  									className={
  										caret +
                      " " +
                      (this.state.openAvatar ? classes.caretActive : "")
  									}
  								/>
  							}
  						/>
  					</a>
  					<Collapse in={!this.state.miniActive && this.state.openAvatar} unmountOnExit>
  						<List className={classes.list + " " + classes.collapseList}>
  							<ListItem className={classes.collapseItem}>
  								<NavLink to={this.location("/profile")} className={userClassnames}>
  									<ListItemText
  										primary={"My Profile"}
  										disableTypography={true}
  										className={classes.collapseItemText}
  									/>
  								</NavLink>
  							</ListItem>
  							<ListItem className={classes.collapseItem}>
  								<NavLink to={""} className={classes.collapseItemLink}>
  									<ListItemText
  										onClick={this.handleLogout}
  										primary={"Log Out"}
  										disableTypography={true}
  										className={classes.collapseItemText}
  									/>
  								</NavLink>
  							</ListItem>
  						</List>
  					</Collapse>
  				</ListItem>
  			</List>
  		</div>
  	);
  	var links = (
  		<List className={classes.list}>
  			{routes.map((prop, key) => {
  				if (prop.redirect) {
  					return null;
  				}

  				if (prop.hide) {
  					return null;
  				}

  				if (prop.collapse) {
  					const navLinkClasses =
              classes.itemLink +
              " " +
              cx({
              	[" " + classes.collapseActive]: this.activeRoute(prop.path)
              });
  					const itemText =
              classes.itemText +
              " " +
              cx({
              	[classes.itemTextMini]:
                  this.props.miniActive && this.state.miniActive
              });
  					const collapseItemText =
              classes.collapseItemText +
              " " +
              cx({
              	[classes.collapseItemTextMini]:
                  this.props.miniActive && this.state.miniActive
              });
  					const itemIcon = classes.itemIcon;
  					const caret = classes.caret;
  					return (
  						<ListItem key={key} className={classes.item}>
  							<a
  								style={{
  									cursor: "pointer"
  								}}
  								className={navLinkClasses}
  								onClick={() => this.openCollapse(prop.state)}
  							>
  								<ListItemIcon className={itemIcon}>
  									<prop.icon />
  								</ListItemIcon>
  								<ListItemText
  									primary={prop.name}
  									secondary={
  										<b
  											className={
  												caret +
                          " " +
                          (this.state[prop.state] ? classes.caretActive : "")
  											}
  										/>
  									}
  									disableTypography={true}
  									className={itemText}
  								/>
  							</a>
  							<Collapse in={this.state[prop.state]} unmountOnExit>
  								<List className={classes.list + " " + classes.collapseList}>
  									{prop.views.map((prop, key) => {
  										if (prop.redirect) {
  											return null;
  										}
  										const navLinkClasses =
                        classes.collapseItemLink +
                        " " +
                        cx({
                        	[" " + classes[color]]: this.activeRoute(prop.path)
                        });
  										const collapseItemMini = classes.collapseItemMini;
  										return (
  											<ListItem key={key} className={classes.collapseItem}>
  												<NavLink to={this.location(prop.path)} className={navLinkClasses}>
  													<span className={collapseItemMini}>
  														{prop.mini}
  													</span>
  													<ListItemText
  														primary={prop.name}
  														disableTypography={true}
  														className={collapseItemText}
  													/>
  												</NavLink>
  											</ListItem>
  										);
  									})}
  								</List>
  							</Collapse>
  						</ListItem>
  					);
  				}
  				const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
            	[" " + classes[color]]: this.activeRoute(prop.path)
            });
  				const itemText =
            classes.itemText +
            " " +
            cx({
            	[classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive
            });
  				const itemIcon = classes.itemIcon;
  				return (
  					<ListItem key={key} className={classes.item}>
  						<NavLink to={this.location(prop.path)} className={navLinkClasses}>
  							<ListItemIcon className={itemIcon}>
  								<prop.icon />
  							</ListItemIcon>
  							<ListItemText
  								primary={prop.name}
  								disableTypography={true}
  								className={itemText}
  							/>
  						</NavLink>
  					</ListItem>
  				);
  			})}
  		</List>
  	);

  	const logoNormal =
      classes.logoNormal +
      " " +
      cx({
      	[classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive
      });
  	const logoMini = classes.logoMini;
  	const logoClasses =
      classes.logo +
      " " +
      cx({
      	[classes.whiteAfter]: bgColor === "white"
      });
  	var brand = (
  		<div className={logoClasses}>
  			<a href="#" className={logoMini}>
  				<img src={HytxLogo} alt="logo" className={classes.img} />
  			</a>
  			<a href="#" className={logoNormal} style={{
          fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
          fontSize: "14px",
  				fontWeight: "300",
          marginTop: "5px",
          marginRight: "20px",
          textOverflow: "ellipsis"
  			}}>
  				{logoText}
  			</a>
  		</div>
  	);
  	const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
      	[classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive
      });
  	const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
      	[classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
      	[classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });

  	return (
  		<div ref="mainPanel">
  			<Hidden mdUp>
  				<Drawer
  					variant="temporary"
  					anchor={"right"}
  					open={this.props.open}
  					classes={{
  						paper: drawerPaper + " " + classes[bgColor + "Background"]
  					}}
  					onClose={this.props.handleDrawerToggle}
  					ModalProps={{
  						keepMounted: true // Better open performance on mobile.
  					}}
  				>
  					{brand}
  					<SidebarWrapper
  						className={sidebarWrapper}
  						user={user}
  						headerLinks={<HeaderLinks />}
  						links={links}
  					/>
  					{image !== undefined ? (
  						<div
  							className={classes.background}
  							style={{ backgroundImage: "url(" + image + ")" }}
  						/>
  					) : null}
  				</Drawer>
  			</Hidden>
  			<Hidden smDown>
  				<Drawer
  					onMouseOver={() => this.setState({ miniActive: false })}
  					onMouseOut={() => this.setState({ miniActive: true })}
  					anchor={"left"}
  					variant="permanent"
  					open
  					classes={{
  						paper: drawerPaper + " " + classes[bgColor + "Background"]
  					}}
  				>
  					{brand}
  					<SidebarWrapper
  						className={sidebarWrapper}
  						user={user}
  						links={links}
  					/>
  					{image !== undefined ? (
  						<div
  							className={classes.background}
  							style={{ backgroundImage: "url(" + image + ")" }}
  						/>
  					) : null}
  				</Drawer>
  			</Hidden>
  		</div>
  	);
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  color: PropTypes.oneOf(["white", "red", "orange", "green", "blue", "purple", "rose"]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  isAuthenticated: PropTypes.bool,
  authUser: PropTypes.shape({}),
  handleDrawerToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  miniActive: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  logOut: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  authUser: state.user.user
});

const mapDispatchToProps = dispatch => ({
  logOut: () => {
    dispatch(logout());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(sidebarStyle)(Sidebar)));
