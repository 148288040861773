import React from "react";
import pt from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import AIRR from "models/AlzheimersIntegratedRisk";
import { APPS } from "constants/Apps";
import AIRRWarnings from "./AIRRWarnings";
import { getWebPreviewToken, getJob, regenerateIntegratedRiskReport, regenerateGeneticRiskReport } from "libs/apiLib";
import { getSignedUrl, headObject } from "libs/awsLib";
import { userSelector } from "selectors";

import { WithNotifications } from "components/HigherOrder";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import IconCard from "components/Cards/IconCard";
import IconPuzzle from "material-ui-icons/Extension";
import Table from "components/Table/Table";
import Badge from "components/Badge/Badge";
import Button from "components/CustomButtons/Button";
import IconButton from "components/CustomButtons/IconButton";
import RefreshIcon from "material-ui-icons/Refresh";
import Tooltip from "material-ui/Tooltip";
import CustomDropdownSmall from "components/CustomDropdown/CustomDropdownSmall";
import Modal from "components/Modal/Code";

export class AlzIntegrated extends React.Component {
  static propTypes = {
    regenerateGeneticRiskReport: pt.func.isRequired,
    regenerateIntegratedRiskReport: pt.func.isRequired,
    user: pt.shape({
      isHLXSupport: pt.bool.isRequired,
    }).isRequired,
    getJob: pt.func.isRequired,
    getWebPreviewToken: pt.func.isRequired,
    getSignedUrl: pt.func.isRequired,
    signalError: pt.func.isRequired,
    result: pt.shape({
      jobStatus: pt.oneOf(["Finished Processing", "Running", "Failed"]).isRequired,
      received: pt.string.isRequired,
      eventId: pt.string.isRequired,
      output: pt.shape({
        default: pt.shape({
          status: pt.oneOf(["Success", "Failed", "Pending"]),
          location: pt.string,
          generated: pt.string,
        }),
        supplemental: pt.arrayOf(pt.shape({
          id: pt.string.isRequired,
          name: pt.string.isRequired,
          type: pt.oneOf(["Genetics", "Integrated"]).isRequired,
        }))
      }),
      results: pt.shape({
        report: pt.shape({
          screen: pt.oneOf(["mci", "genpop"]).isRequired,
          patient: pt.shape({
            age: pt.number.isRequired,
            sex: pt.oneOf(["m", "f", "o"]).isRequired
          }).isRequired,
          meta: pt.shape({
            referringPhysician: pt.string,
            accessionNumber: pt.string,
            studyDate: pt.string.isRequired,
            barcode: pt.string,
            version: pt.string,
          }).isRequired,
          integrated: pt.shape({
            yearRange: pt.arrayOf(pt.number).isRequired,
            percentile20: pt.arrayOf(pt.number).isRequired,
            percentile50: pt.arrayOf(pt.number).isRequired,
            percentile80: pt.arrayOf(pt.number).isRequired,
            percentilePatient: pt.arrayOf(pt.number).isRequired,
            risk: pt.number.isRequired,
            riskCategory: pt.oneOf([0, 1, 2]).isRequired,
            errors: pt.arrayOf(pt.string).isRequired,
            warnings: pt.arrayOf(pt.string).isRequired,
          }).isRequired,
          genetics: pt.shape({
            apoeStatus: pt.string,
            percentile: pt.number.isRequired,
            riskCategory: pt.oneOf([0, 1, 2]).isRequired,
            errors: pt.arrayOf(pt.string).isRequired,
            warnings: pt.arrayOf(pt.string).isRequired,
          }).isRequired,
          imaging: pt.shape({
            percentile: pt.number.isRequired,
            riskCategory: pt.oneOf([0, 1, 2]).isRequired,
            errors: pt.arrayOf(pt.string).isRequired,
            warnings: pt.arrayOf(pt.string).isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    appId: pt.string.isRequired,
    patient: pt.shape({
      firstName: pt.string,
      lastName: pt.string,
      dob: pt.string,
      sex: pt.string,
    }).isRequired,
    classes: pt.shape({
      footer: pt.string.isRequired,
      report: pt.string.isRequired,
      highlightTitle: pt.string.isRequired,
      bottomMargin: pt.string.isRequired,
      sectionBorder: pt.string.isRequired,
      textCenter: pt.string.isRequired,
    }).isRequired,
    today: pt.instanceOf(moment),
  }

  constructor(props) {
    super(props);
    const { patient, result } = props;
    this.today = props.today || moment();
    this.validReport = this.isValidReport(result.results);
    this.patientAge = Math.abs(moment(Date.parse(patient.dob)).diff(this.today, "years"));
    this.mci = result.appId === APPS.airrMci || (result.results && result.results.report && result.results.report.screen === "mci");
    this.airr = this.validReport && new AIRR(result.results.report) || null;
    this.patient = patient;
    this.state = {
      modal: null,
      token: null,
      loading: false,
      outputs: {
        integratedRisk: {
          status: null,
          exists: false,
          checkRetries: 0,
          url: null
        },
        geneticRisk: {
          id: null,
          status: null,
          exists: false,
          checkRetries: 0,
          url: null
        }
      }
    };
  }

  componentDidMount() {
    const { result } = this.props;
    if (result.jobStatus === "Finished Processing") {
      if (result.output && result.output.default) {
        this.getIntegratedJob(result.eventId);
      } else {
        this.checkIfReportExists();
      }

      if (result.output && result.output.supplemental && result.output.supplemental.length) {
        const geneticJob = result.output.supplemental[0];
        this.setState(prevState => ({
          ...prevState,
          outputs: {
            ...prevState.outputs,
            geneticRisk: {
              ...prevState.outputs.geneticRisk,
              id: geneticJob.id
            }
          }
        }));
        this.getGeneticJob(geneticJob.id);
      }
    }
  }

  componentDidUpdate(_, prevState) {
    if (
      !this.props.result.output &&
      this.props.result.jobStatus === "Finished Processing" &&
      !prevState.outputs.integratedRisk.exists &&
      this.state.outputs.integratedRisk.exists &&
      !this.state.outputs.integratedRisk.url
    ) {
      this.getPDFURL();
    }
  }

  async checkIfReportExists() {
    const { headObject } = this.props;
    this.setState({ loading: true });
    try {
      if (this.state.outputs.integratedRisk.checkRetries === 3) {
        throw new Error();
      }
      const token = await this.getS3Token();
      if (!token) {
        throw new Error();
      }
      const key = this.s3Key();
      await headObject(token, key);
      this.setState(prevState => ({ ...prevState, outputs: { ...prevState.outputs, integratedRisk: { ...prevState.outputs.integratedRisk, exists: true } } }));
    } catch (error) {
      if (this.state.outputs.integratedRisk.checkRetries < 3) {
        this.setState(prevState => ({
          ...prevState,
          outputs: {
            ...prevState.outputs,
            integratedRisk: {
              ...prevState.outputs.integratedRisk,
              status: "Pending",
              checkRetries: prevState.outputs.integratedRisk.checkRetries + 1
            }
          }
        }),
        () => {
          setTimeout(this.checkIfReportExists.bind(this), 20000); // wait 20 sec then call this function again
        });
        return;
      } else {
        this.setState(prevState => ({
          ...prevState,
          outputs: {
            ...prevState.outputs,
            integratedRisk: {
              ...prevState.outputs.integratedRisk,
              status: "Failed",
            }
          }
        }));
      }
    }
    this.setState({ loading: false });
  }

  getIntegratedJob = async (eventId) => {
    const { getJob, signalError, clearError } = this.props;
    this.setState({ loading: true });
    try {
      if (this.state.outputs.integratedRisk.checkRetries === 3) {
        return;
      }
      const response = await getJob(eventId);
      if (response.success && response.job) {
        const { job } = response;
        if (job.output && job.output.default) {
          const { status } = job.output.default;
          if (status === "Pending") {
            this.setState(prevState => ({
              ...prevState,
              outputs: {
                ...prevState.outputs,
                integratedRisk: {
                  ...prevState.outputs.integratedRisk,
                  status,
                  checkRetries: prevState.outputs.integratedRisk.checkRetries + 1,
                  exists: false,
                  url: null,
                }
              }
            }),
            () => {
              setTimeout(this.getIntegratedJob.bind(this, eventId), 30000); // wait 30 sec then call this function again
            });
          } else if (status === "Success") {
            const url = await this.getPDFDownloadURL(job.output.default.location);
            this.setState(prevState => ({
              ...prevState,
              outputs: {
                ...prevState.outputs,
                integratedRisk: {
                  ...prevState.outputs.integratedRisk,
                  exists: true,
                  status,
                  url
                }
              }
            }));
          } else {
            this.setState(prevState => ({
              ...prevState,
              outputs: {
                ...prevState.outputs,
                integratedRisk: {
                  ...prevState.outputs.integratedRisk,
                  exists: false,
                  status: "Failed",
                  url: null
                }
              }
            }));
          }
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error getting IntegratedRisk PDF report. Please contact support@healthlytix.com");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  getGeneticJob = async (eventId) => {
    const { getJob, signalError, clearError } = this.props;
    this.setState({ loading: true });
    try {
      if (this.state.outputs.geneticRisk.checkRetries === 3) {
        return;
      }
      const response = await getJob(eventId);
      if (response.success && response.job) {
        const { job } = response;
        if (job.output && job.output.default) {
          const { status } = job.output.default;
          if (status === "Pending") {
            this.setState(prevState => ({
              ...prevState,
              outputs: {
                ...prevState.outputs,
                geneticRisk: {
                  ...prevState.outputs.geneticRisk,
                  checkRetries: prevState.outputs.geneticRisk.checkRetries + 1,
                  status,
                  exists: false,
                  url: null
                }
              }
            }),
            () => {
              setTimeout(this.getGeneticJob.bind(this, eventId), 30000); // wait 30 sec then call this function again
            });
          } else if (status === "Success") {
            const url = await this.getPDFDownloadURL(job.output.default.location);
            this.setState(prevState => ({
              ...prevState,
              outputs: {
                ...prevState.outputs,
                geneticRisk: {
                  ...prevState.outputs.geneticRisk,
                  exists: true,
                  status,
                  url
                }
              }
            }));
          } else {
            this.setState(prevState => ({
              ...prevState,
              outputs: {
                ...prevState.outputs,
                geneticRisk: {
                  ...prevState.outputs.geneticRisk,
                  exists: false,
                  status: "Failed",
                  url: null
                }
              }
            }));
          }
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error getting GeneticRisk PDF report. Please contact support@healthlytix.com");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  s3Key() {
    const { patient: { orgId, patientId }, result: { appId, eventId } } = this.props;
    return `orgs/${orgId}/patients/${patientId}/reports/${appId}/${eventId}.pdf`;
  }

  async getS3Token() {
    const { clearError, signalError, getWebPreviewToken, patient: { orgId } } = this.props;
    this.setState({ loading: true });
    let token;
    try {
      let results;
      if (!this.state.token) {
        results = await getWebPreviewToken(orgId);
        if (results && results.success && results.token) {
          token = results.token;
          this.setState({ token });
        } else {
          throw new Error("Couldn't not get token to download PDF");
        }
      } else {
        token = this.state.token;
      }
    } catch (e) {
      signalError("Error getting PDF reports. Please contact support@healthlytix.com");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
    return token;
  }

  getPDFDownloadURL = async (key) => {
    const token = await this.getS3Token();
    const url = this.props.getSignedUrl(token, key, {
      ResponseContentDisposition: "attachment",
      ResponseContentType: "application/pdf"
    });
    return url;
  }

  async getPDFURL() {
    const { signalError, clearError, getSignedUrl } = this.props;
    this.setState({ loading: true });
    try {
      const token = await this.getS3Token();
      if (!token) {
        throw new Error();
      }
      const key = this.s3Key();
      const url = getSignedUrl(token, key, {
        ResponseContentDisposition: "attachment",
        ResponseContentType: "application/pdf"
      });
      this.setState(prevState => ({
        ...prevState,
        outputs: {
          ...prevState.outputs,
          integratedRisk: {
            ...prevState.outputs.integratedRisk,
            status: "Success",
            url
          }
        }
      }));
    } catch (e) {
      signalError("Error getting PDF report. Please contact support@healthlytix.com");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  isValidReport(result) {
    if (!result || !result.report) {
      return false;
    }
    return AIRR.validReport(result.report);
  }

  statusBadge = (status) => {
    let color = "primary";
    switch (status) {
    case "Running":
      break;
    case "Finished Processing":
      color = "success";
      break;
    case "Failed":
      color = "danger";
      break;
    case "Queued":
      color = "info";
      break;
    default:
      return "N/A";
    }
    return <Badge color={color}>{status}</Badge>;
  }

  regenerateReport = async (reportType, eventId) => {
    const { user, result, regenerateGeneticRiskReport, regenerateIntegratedRiskReport, signalError, clearError } = this.props;
    try {
      if (!eventId || !user.isHLXSupport) {
        return;
      }
      const regenerateReport = reportType === "integrated" ? regenerateIntegratedRiskReport : regenerateGeneticRiskReport;
      this.setState({ loading: true });
      await regenerateReport({ patientId: result.patientId, orgId: result.orgId, eventId });
      window.location.reload();
    } catch (error) {
      signalError("Error regenerating report. Please try again later.");
      setTimeout(clearError, 5000);

    }
    this.setState({ loading: false });
  }

  reportStatusButton = (report, reportType, id) => {
    const { user } = this.props;
    let color = "primary";
    const size = "sm";
    let text = report.status || "";
    let url = "#";
    switch (report.status) {
    case "Pending":
      color = "info";
      break;
    case "Success":
      color = "success";
      text = "Download";
      url = report.exists && report.url || "#";
      break;
    case "Failed":
      color = "danger";
      if (user.isHLXSupport) {
        return (
          <React.Fragment>
            <Button onClick={() => {}} size={size} color={color}>{text}</Button>
            <Tooltip title="Regenerate?" placement="right">
              <IconButton
                onClick={this.regenerateReport.bind(this, reportType, id)}
                size={size}
                color="info"
              >
                <RefreshIcon/>
              </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      }
      return <Button href={url} size={size} color={color}>{text}</Button>;
    default:
      return "";
    }
    if (user.isHLXSupport) {
      return (
        <React.Fragment>
          <Button href={url} size={size} color={color}>{text}</Button>
          <Tooltip title="Regenerate?" placement="right">
            <IconButton
              onClick={this.regenerateReport.bind(this, reportType, id)}
              size={size}
              color="info"
            >
              <RefreshIcon/>
            </IconButton>
          </Tooltip>
        </React.Fragment>
      );
    }
    return <Button href={url} size={size} color={color}>{text}</Button>;
  }

  geneticInputData = (report) => {
    if (!report || !report.input || !report.input.genetics || !report.input.genetics.id) {
      return "N/A";
    }
    return report.input.genetics.id;
  }
  
  imagingInputData = (report) => {
    if (!report || !report.input || !report.input.imaging || !report.input.imaging.id) {
      return "N/A";
    }
    return report.input.imaging.id;
  }

  handleCaseAction = (action) => {
    switch (action) {
    case "App Logs":
      // TODO:
      break;
    case "View Report Details":
      this.handleViewReportDetails();
      break;
    default:
      break;
    }
  }

  removeModal = () => this.setState({ modal: null });

  handleViewReportDetails = () => {
    if (!this.props.result) {
      return;
    }
    this.setState({
      modal: (
        <Modal
          title={<h2>Report Details</h2>}
          value={JSON.stringify(this.props.result, undefined, "\t")}
          style={{
            height: 900,
            width: 800,
            overflow: "hidden",
            overflowY: "scroll",
          }}
          footerRender={() => (
            <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
              <Button onClick={this.removeModal}>Close</Button>
            </ItemGrid>
          )}
        />
      )
    });
  }

  showOptions() {
    if (!this.props.user.isHLXSupport) {
      return null;
    }
    return (
      <ItemGrid xs={12} md={2} container justify="flex-end" alignItems="center">
        <CustomDropdownSmall
          buttonText="Options"
          dropdownList={["View Report Details"]}
          onAction={(action) => this.handleCaseAction(action)}
        />
      </ItemGrid>
    );
  }

  render() {
    const { result } = this.props;
    const { loading, outputs: { integratedRisk, geneticRisk } } = this.state;
    const jobSucceeded = result.jobStatus === "Finished Processing";
    const showPDFDownloadButtons = !loading && jobSucceeded;
    return (
      <GridContainer justify="center">
        {this.state.modal}
        <ItemGrid xs={12} lg={6}>
          <AIRRWarnings report={this.validReport ? this.props.result.results.report : null} appError={result.error} />
        </ItemGrid>

        <IconCard
          iconColor="green"
          icon={IconPuzzle}
          title="Alzheimer's Integrated Risk Report"
          content={
            <GridContainer>
              <ItemGrid xs={12} md={10}>
                <h2 style={{ display: "inline-block" }}>Application Details</h2>
              </ItemGrid>
              {this.showOptions()}
              <ItemGrid xs={12}>
                <Table
                  tableData={[
                    ["Status", this.statusBadge(result.jobStatus)],
                    ["Screen", !this.mci ? "General Population Screen" : "Mild Cognitive Impairment Screen"],
                    ["Genetics Input Record Used", this.geneticInputData(result)],
                    ["Imaging Input Record Used", this.imagingInputData(result)],
                    ["Submitted", moment(Date.parse(result.received)).format("MM-DD-YYYY")],
                    ["HealthLytix Patient ID", result.patientId],
                    ["Event ID", result.eventId],
                    ["IntegratedRisk PDF Report", showPDFDownloadButtons ? this.reportStatusButton(integratedRisk, "integrated", result.eventId) : ""],
                    ["GeneticRisk PDF Report", showPDFDownloadButtons ? this.reportStatusButton(geneticRisk, "genetic", geneticRisk.id)  : ""],
                  ]}
                />
              </ItemGrid>
            </GridContainer>
          }
        />
      </GridContainer>
    );
  }
}

export default connect(state => ({
  user: userSelector(state)
}), () => ({
  getSignedUrl,
  getWebPreviewToken,
  headObject,
  getJob,
  regenerateIntegratedRiskReport,
  regenerateGeneticRiskReport
}))(WithNotifications(AlzIntegrated));