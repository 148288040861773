import React from "react";
import { connect } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

// local components
import TotalCases from "views/Organization/components/TotalCases";
import SuccessRate from "views/Organization/components/SuccessRate";
import MostEngagingApp from "views/Organization/components/MostEngagingApp";
import AppUsage from "views/Organization/components/AppUsage";
import AppSuccessFailure from "views/Organization/components/AppSuccessFailure";
import AppFailureCodes from "views/Organization/components/AppFailureCodes";
import AppPerformance from "views/Organization/components/SingleAppSuccessFailure";

// api functions
import { storeGlobalReport, storeNotificationError, removeNotificationError } from "actions/index";
import { globalOrgStatsQuery } from "libs/apiLib";

import Reports from "models/Reports";

export class MonthlyOrganizationInsights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      fetchingReportsForKeys: {}
    };
  }
  componentDidMount() {
    this.fetchMonthlyReport();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldFetchReport(prevProps, prevState)) {
      this.fetchMonthlyReport();
    }
  }

  shouldFetchReport = (prevProps, prevState) => {
    // only fetch a report if:
    // - not already fetching a report under this key
    // AND
    // - key doesn't exist in the report (this is the first request for this key)
    // - OR
    // - no reports exists but date has changed from previous render 
    const { start, end } = this.props;
    const key = this.key(start, end);
    const isStillFetchingReport = prevState.fetchingReportsForKeys[key];
    const isCurrentlyFetchingReport = this.state.fetchingReportsForKeys[key];
    return (
      !isStillFetchingReport &&
      !isCurrentlyFetchingReport &&
      (
        (this.isFirstFetchUnderKey(key)) ||
        (!this.reportsExist() && (this.datesHaveChanged(prevProps, this.props)))
      )
    );
  }

  reportsExist() {
    return Boolean(this.props.reports);
  }

  isFirstFetchUnderKey(key) {
    return Boolean(this.props.reports && !this.props.reports[key]);
  }

  datesHaveChanged(prevDates, currentDates) {
    return Boolean(!prevDates.start.isSame(currentDates.start) || !prevDates.end.isSame(currentDates.end));
  }

  monthlyReport = () => {
    const { reports, start, end } = this.props;
    const key = this.key(start, end);
    if (reports && reports[key] && reports[key].length) {
      const data = reports[key].map(d => ({
        day: d.year + d.monthDay,
        orgName: undefined,
        orgId: undefined,
        appStats: d.providerStats.appStats,
      }));
      return new Reports(start.format("YYYYMMDD"), end.format("YYYYMMDD"), data);
    }
    return undefined;
  }

  fetchMonthlyReport = async () => {
    const { storeGlobalReport, fetchMonthlyReport, start, end, signalError, clearError } = this.props;
    const key = this.key(start, end);
    this.setState({ isLoading: true, fetchingReportsForKeys: { ...this.state.fetchingReportsForKeys, [key]: true } });
    try {
      const report = await fetchMonthlyReport(start, end);
      if (report.success && report.stats && report.stats.Items) {
        storeGlobalReport(report.stats.Items, key);
      }
    } catch (e) {
      signalError("Error getting insights");
      setTimeout(clearError, 5000);
    }
    this.setState({ isLoading: false, fetchingReportsForKeys: { ...this.state.fetchingReportsForKeys, [key]: false } });
  }

  key = (start, end) => {
    if (!start || !end) { return undefined; }
    return `${start.format("YYYY-MM-DD")}:${end.format("YYYY-MM-DD")}`;
  }

  render() {
    const report = this.monthlyReport();
    return (
      <React.Fragment>
        <GridContainer>
          <ItemGrid container xs={12} sm={4}>
            <TotalCases report={report} />
          </ItemGrid>
          <ItemGrid container xs={12} sm={4}>
            <SuccessRate report={report} />
          </ItemGrid>
          <ItemGrid container xs={12} sm={4}>
            <MostEngagingApp report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid container xs={6} >
            <AppSuccessFailure report={report} />
          </ItemGrid>
          <ItemGrid container xs={6} >
            <AppUsage report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12} >
            <AppPerformance report={report} />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid container xs={12} >
            <AppFailureCodes report={report} />
          </ItemGrid>
        </GridContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.reports.global,
});

const mapDispatchToProps = (dispatch) => ({
  // uses dispatch
  storeGlobalReport: (report, key) => dispatch(storeGlobalReport(report, key)),
  signalError: (msg) => dispatch(storeNotificationError(msg)),
  clearError: () => dispatch(removeNotificationError()),

  // does not use dispatch
  fetchMonthlyReport: (start, end) => {
    const year = end.format("YYYY");
    end = !end.isSame(start) ? end.format("MMDD") : undefined;
    return globalOrgStatsQuery(start.format("MMDD"), end, year);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyOrganizationInsights);
