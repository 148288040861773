import React from "react";
import PropTypes from "prop-types";
import IconCard from "components/Cards/IconCard.jsx";
import Reports from "models/Reports";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";
import { APPIDTONAME } from "constants/Apps";

// Styles
import Build from "material-ui-icons/Build";

const formatCell = (key) => (cell) => {
  switch (key) {
  case "appId":
    return <Cell label="Application" value={cell.original.appId} />;
  case "total":
    return <Cell label="Total" value={cell.original.total} />;
  default:
    return null;
  }
};

export const UniqueJobsByAppContent = ({ report }) => {
  if (!report) {
    return <h3><small>No report</small></h3>;
  }
  const uniqueJobsByApp = report.uniqueJobsByApp();
  const appIDs = Object.keys(uniqueJobsByApp);
  if (!appIDs.length) {
    return <h3><small>No unique imaging jobs reported for this date range</small></h3>;
  }
  const series = appIDs.reduce((arr, appId) => {
    return [
      ...arr,
      {
        appId: APPIDTONAME[appId] || appId,
        total: uniqueJobsByApp[appId]
      }
    ];
  }, []).sort((a, b) => (a.total) > (b.total) ? -1 : 1);
  return (
    <GridContainer>
      <ItemGrid xs={12}>
        <ReactTable
          sortable={false}
          resizable={false}
          data={series}
          noDataText="No unique imaging jobs reported for this date range"
          columns={
            [
              { header: "", accessor: "appId"},
              { header: "", accessor: "total", maxWidth: 100 },
            ].map(c => ({
              Header: c.header,
              Cell: formatCell(c.accessor),
              maxWidth: c.maxWidth,
            }))
          }
          showPaginationBottom
          className="-highlight"
          pageSize={10}
          minRows={0}
        />
      </ItemGrid>
    </GridContainer>
  );
};

UniqueJobsByAppContent.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export const UniqueJobsByApp = ({ report }) => (
  <IconCard
    iconColor="orange"
    icon={Build}
    title="Unique Jobs By Imaging App"
    content={<UniqueJobsByAppContent report={report} />}
  />
);

UniqueJobsByApp.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export default UniqueJobsByApp;