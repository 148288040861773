import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

// material-ui components
import withStyles from "material-ui/styles/withStyles"
import InputLabel from "material-ui/Input/InputLabel"
import Select from "material-ui/Select"
import MenuItem from "material-ui/Menu/MenuItem"
import FormControl from "material-ui/Form/FormControl"

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx"

const Index = ({ classes, inputLabel, inputProps, values, formControlProps }) => {
	return (
		<FormControl
			{...formControlProps}
			className={classes.selectFormControl}
		>
			{inputLabel && <InputLabel
				htmlFor="input-label"
				className={classes.lightSelectLabel}
			>{inputLabel}</InputLabel>}
			<Select
				MenuProps={{
					className: classes.selectMenu
				}}
				classes={{
					select: classes.select
				}}
				value={inputProps.value}
				inputProps={inputProps}
			>
				{
					values.map((v, i) => {
						let classNames = {
							root: classes.selectMenuItem
						}
						if (!v.disabled) {
							classNames.selected = classes.selectMenuItemSelected
						}
						return (
							<MenuItem
								key={i}
								value={v.value}
								classes={classNames}
								disabled={v.disabled}
							>
								{v.display !== undefined ? v.display : ""}
							</MenuItem>
						)
					})
				}
			</Select>
		</FormControl>
	)
}

Index.propTypes = {
	classes: PropTypes.shape({}).isRequired,
	inputLabel: PropTypes.string,
	inputProps: PropTypes.shape({
		name: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		disabled: PropTypes.bool,
	}).isRequired,
	values: PropTypes.arrayOf(PropTypes.shape({
		display: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
		disabled: PropTypes.bool,
	})),
	formControlProps: PropTypes.shape({
	})
}

export default withStyles(styles)(Index)