import React from "react";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// libs
import { validateIP, validateTcpPort, isLengthLessThan } from 'libs/utils';

class AddDicomDevice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            aeTitle: "",
            ipAddress: "",
            port: ""
        }
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleConfirm = (event) => {
        event.preventDefault();
        
        if(this.validateForm())
            this.props.onCreate(this.state)
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    validateForm() {
        return (
            validateIP(this.state.ipAddress) &&
            isLengthLessThan(this.state.aeTitle, 17) &&
            validateTcpPort(this.state.port) &&
            this.state.port.length > 0 &&
            this.state.ipAddress.length > 0 &&
            this.state.aeTitle.length > 0 &&
            this.state.name.length > 0
        );
    }

    render() {
        return (
            <SweetAlert
                style={{ display: "block" }}
                title="Add Dicom Device"
                onConfirm={() => this.handleConfirm()}
                onCancel={() => this.handleCancel()}
                showConfirm={false}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
            >
                <div>
                    <form style={{ paddingLeft: "20px", paddingRight: "20px"}} onSubmit={this.handleConfirm}>
                        <CustomInput
                            labelText="Name of Device"
                            id="name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                id: "name",
                                value: this.state.name,
                                onChange: this.handleChange
                            }}
                        />
                        <CustomInput
                            labelText="AE Title"
                            id="aeTitle"
                            error={!isLengthLessThan(this.state.aeTitle, 17)}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                id: "aeTitle",
                                value: this.state.aeTitle,
                                onChange: this.handleChange,
                            }}
                        />
                        <CustomInput
                            labelText="IP Address"
                            error={!validateIP(this.state.ipAddress)}
                            id="ipAddress"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                id: "ipAddress",
                                value: this.state.ipAddress,
                                onChange: this.handleChange,
                            }}
                        />
                        <CustomInput
                            labelText="Listening Port"
                            error={!validateTcpPort(this.state.port)}
                            id="port"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                id: "port",
                                value: this.state.port,
                                onChange: this.handleChange,
                            }}
                        />
                        <div style={{ marginTop: "40px"}}>
                            <Button color="danger" style={{ marginRight: "10px"}}
                                    onClick={this.handleCancel}>Cancel</Button>
                            <Button color="success"
                                    type="submit"
                                    onClick={this.handleConfirm}
                                   disabled={!this.validateForm()}>Add</Button>
                        </div>
                    </form>
                </div>
            </SweetAlert>
        )
    }
}

export default withStyles(sweetAlertStyle)(AddDicomDevice);