import React from "react";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";

// styles
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const style = {
    ...dashboardStyle,
    ...buttonsStyle,
    ...sweetAlertStyle
};

class ErrorModal extends React.Component {

    render() {
        const { onClose, err, classes } = this.props;
        return (
            <SweetAlert
                type="error"
                style={{ display: "block", marginTop: "-100px" }}
                title="Ohh No!"
                onConfirm={() => onClose()}
                onCancel={() => onClose()}
                confirmBtnCssClass={
                    classes.button + " " + classes.success
                }
                showConfirm={false}
            >
                <div>
                    {err}
                </div>
                <div style={{ marginTop: "40px" }}>
                    <Button onClick={() => onClose()}>Close</Button>
                </div>
            </SweetAlert>
        )
    }
}

export default withStyles(style)(ErrorModal);