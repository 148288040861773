import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import HeaderCard from "components/Cards/HeaderCard.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class AppStore extends React.Component {

  render() {
    return (
      <div>
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              headerColor="orange"
              cardTitle="App Store"
              cardSubtitle="Discover HealthLytix Apps"
              content={
                <div>
                    <h2>Coming Soon...</h2>
                    <p>Check later for updates on this area</p>
                </div>
              }
            />
          </ItemGrid>
        </GridContainer>
      </div>
    );
  }
}

AppStore.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(AppStore);
