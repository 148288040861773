import React from "react";
import pt from "prop-types";
import { connect } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import PlainCard from "components/Cards/PlainCard";
import Dropdown from "components/CustomDropdown/CustomDropdown";
import { WithNotifications } from "components/HigherOrder";

import Extension from "material-ui-icons/Extension";

import { getAllowedApps } from "libs/apiLib";

export class MyApps extends React.Component {
  static propTypes = {
    location: pt.shape({
      search: pt.string.isRequired,
    }).isRequired,
    getAllowedApps: pt.func.isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    user: pt.shape({
      orgId: pt.string.isRequired,
    }).isRequired,
  }

  componentDidMount() {
    this.getApps();
  }

  componentDidUpdate(prevProps) {
    const orgId = new URLSearchParams(this.props.location.search).get("orgId");
    const prevOrgId = new URLSearchParams(prevProps.location.search).get("orgId");
    if (prevOrgId && !orgId) {
      this.setState({ apps: null });
      this.getApps();
    }
  }

  getApps = async () => {
    const { getAllowedApps, location, signalError, clearError, user } = this.props;
    const orgId = new URLSearchParams(location.search).get("orgId");
    this.setState({ loading: true });
    try {
      const response = await getAllowedApps(orgId || user.orgId);
      if (response.success && response.apps) {
        this.setState({ apps: response.apps });
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError("Error getting apps. Please try again later.");
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      apps: null,
    };
  }

  handleDropdownChange = (app, action) => {
    // TODO: 
  }

  render() {
    const { apps } = this.state;
    if (!apps) {
      return null;
    }
    return (
      <GridContainer>
        {apps.map((app, i) => (
          <ItemGrid container key={i} xs={12} sm={6} md={4} lg={3}>
            <PlainCard>
              <GridContainer direction="column" align="center" style={{ paddingTop: 30 }}>
                <ItemGrid xs={12}>
                  <Extension style={{ width: "3em", height: "3em", color: "grey" }} />
                </ItemGrid>
                <ItemGrid xs={12}><h3 style={{ marginBottom: 0 }}>{app.name}</h3></ItemGrid>
                <ItemGrid xs={12}><p style={{ marginBottom: 30 }}>{app.type}</p></ItemGrid>
                <ItemGrid xs={12}><p style={{ marginBottom: 30 }}>{app.description}</p></ItemGrid>
                <ItemGrid container xs={12} justify="center">
                  <Dropdown
                    buttonColor="primary"
                    buttonText="Manage"
                    dropdownList={["Run Application", "View Cases", "Configure App"]}
                    onAction={(action) => this.handleDropdownChange(app, action)}
                  />
                </ItemGrid>
              </GridContainer>
            </PlainCard>
          </ItemGrid>
        ))}
      </GridContainer>
    );
  }
}

export default connect((state) => ({
  user: state.user.user,
}), () => ({
  getAllowedApps: (orgId) => getAllowedApps(orgId),
}))(WithNotifications(MyApps));
