import React from "react";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

import Button from "components/CustomButtons/Button.jsx";

// styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

class SetOrgModal extends React.Component {

    constructor(props) {
        super(props);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleConfirm = () => {
        this.props.onConfirm();
    }

    render() {
        return (
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Action Required"
                onConfirm={() => this.handleConfirm()}
                onCancel={() => this.handleConfirm()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
                showConfirm={false}
            >
                <div>
                    {`Seems like you are listed as an Administrator for this organization. Some details about your organization are still pending. 
                    You will need to update this before continuing. Click "Proceed" to continue.`}
                      </div>
                <div style={{ marginTop: "40px" }}>
                    <Button color="primary"
                            onClick={this.handleConfirm}
                    >Proceed</Button>
                </div>
            </SweetAlert>
        )
    }
}

export default withStyles(sweetAlertStyle)(SetOrgModal);