import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "material-ui/styles/withStyles";
import Search from "./Search";
import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";

const HeaderLinks = (props) => {
  const { classes, rtlActive } = props;
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  return (
    <div className={wrapper}>
      <Search
        {...props}
      />
    </div>
  );
};

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(headerLinksStyle)(HeaderLinks);
