import React from "react";
import PropTypes from "prop-types";
import IconCard from "components/Cards/IconCard.jsx";
import { BarStacked } from "components/Charts";
import Reports from "models/Reports";

// Styles
import ThumbsUpDown from "material-ui-icons/ThumbsUpDown";
import withStyles from "material-ui/styles/withStyles";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle";

export const ScannerSuccessFailure = ({ report }) => {
  let labels = [];
  let xSeries = null;
  if (report) {
    let failures = [];
    let successes = [];
    const modalities = report.modalityStatsBy("hostName");
    const hostNames = Object.keys(modalities);
    if (hostNames.length) {
      hostNames.forEach(hostName => {
        labels.push(hostName);
        successes.push(modalities[hostName].successful);
        failures.push(modalities[hostName].failures);
      });
      xSeries = [
        failures.map(n => ({ value: n, meta: { style: "stroke: #ef5350; stroke-width: 30px;" } })),
        successes.map(n => ({ value: n, meta: { style: "stroke: #0fb8ad; stroke-width: 30px;" } })),
      ];
    }
  }


  return (
    <IconCard
      iconColor="orange"
      icon={ThumbsUpDown}
      title="Scanner Success & Failures"
      content={<BarStacked
        noXSeriesRender={() => <h3><small>No report</small></h3>}
        emptyXSeriesRender={() => <h3><small>No scanners reported for this date range</small></h3>}
        legendTitle="Legend"
        legend={["Failure", "Success"]}
        xLabels={labels}
        xSeries={xSeries}
      />}
    />
  );
};

ScannerSuccessFailure.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export default withStyles(chartsStyle)(ScannerSuccessFailure);