import React from "react";
import PropTypes from "prop-types";
import Badge from "components/Badge/Badge.jsx";
import { colors, successColor, failureColor } from "./index";

const KeyText = ({ children }) => (
  <span style={{
    fontSize: 14,
    marginLeft: 10
  }}>{children}</span>
);

const Circle = ({
  fill,
  stroke,
}) => (
  <svg width="12px" height="12px" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
    <circle id="circle" cx="6" cy="6" r={stroke ? 5 : 6} fill={fill} stroke={stroke} strokeWidth={1}></circle>
  </svg>
);

Circle.propTypes = {
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string,
};

const Line = ({
  fill,
  dashed,
}) => (
  <svg width="14px" height="6px" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
    <rect id="rect" x="0" y="0" width={dashed ? 6 : 14} height="4" fill={fill}></rect>
    {dashed &&
        <rect id="rect 2" x="8" y="0" width="6" height="4" fill={fill}></rect>
    }
  </svg>
);

Line.propTypes = {
  fill: PropTypes.string.isRequired,
  dashed: PropTypes.bool,
};

const Pill = ({
  color,
  text,
}) => (
  <Badge style={{ backgroundColor: color }}>
    {text}
  </Badge>
);

Pill.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const Legend = ({ title, legend, hideLegend = false }) => {
  if (hideLegend || !legend) {
    return null;
  }
  const color = (key, backupColor) => {
    if (key.color) {
      return key.color;
    }
    switch (key) {
    case "Success Rate":
    case "Success":
      return successColor;
    case "Failure":
      return failureColor;
    default:
      return backupColor;
    }
  };
  const value = (str) => str.value ? str.value : str;
  const renderKey = (k, i) => {
    if (!k.type) {
      return (
        <Badge style={{ backgroundColor: color(k, colors[i]) }}>
          {value(k)}
        </Badge>
      );
    }
    switch (k.type) {
    case "circle":
      return (
        <React.Fragment>
          <Circle fill={k.color} stroke={k.stroke} />
          <KeyText>{k.value}</KeyText>
        </React.Fragment>
      );
    case "line":
    case "dashed-line":
      return (
        <React.Fragment>
          <Line fill={k.color} dashed={k.type === "dashed-line"} />
          <KeyText>{k.value}</KeyText>
        </React.Fragment>
      );
    default:
      return (
        <Badge style={{ backgroundColor: color(k, colors[i]) }}>
          {value(k)}
        </Badge>
      );
    }
  };
  return (
    <React.Fragment>
      {title && <h5>{title}</h5>}
      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
        {
          legend.map((key, i) => {
            return (
              <li key={i} style={{ display: "inline-block", margin: "0 15px 15px 0" }}>
                {renderKey(key, i)}
              </li>
            );
          })
        }
      </ul>
    </React.Fragment>
  );
};

Legend.propTypes = {
  title: PropTypes.string,
  legend: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      color: PropTypes.string,
      stroke: PropTypes.string,
      type: PropTypes.oneOf(["circle", "line", "dashed-line"]),
    })
  ])),
  hideLegend: PropTypes.bool,
};

export default Legend;