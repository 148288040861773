import React from "react";
import pt from "prop-types";
import moment from "moment";
import LengthConverter from "models/LengthConverter";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import Input from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Modal from "components/Modal";
import DatePickerButton from "components/DatePickerButtons/DatePickerButton";
import InputLabel from "components/CustomInput/Label";
import Select from "components/Select";

export default class PatientEditModal extends React.Component {
  static propTypes = {
    title: pt.string.isRequired,
    submitButtonText: pt.string.isRequired,
    patient: pt.shape({
    }),
    onSubmit: pt.func.isRequired,
    onClose: pt.func.isRequired,
    loading: pt.bool,
    unit: pt.oneOf(["imperial", "metric"]),
  };

  constructor(props) {
    super(props);
    this.state = {
      unit: props.unit || "metric",
      patient: this.formatPatientProp(props.patient),
    };
  }

  formatPatientProp = (patient) => {
    const newPatient = {
      firstName: "",
      lastName: "",
      sex: "M",
      dob: undefined,
      mmse: undefined,
      avlt: undefined,
      height: {
        ft: undefined,
        in: undefined,
        cm: undefined,
      },
      weight: {
        kg: undefined,
        lb: undefined,
      },
    };
    return {
      ...newPatient,
      ...patient,
      sex: patient && patient.sex || "M",
      height: {
        ft: undefined,
        in: undefined,
        cm: patient && patient.height,
      },
      weight: {
        kg: patient && patient.weight,
        lb: undefined,
      },
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({ ...prevState, patient: { ...prevState.patient, [name]: value } }));
  }

  formatPatient = (patient, unit) => ({
    ...patient,
    dob: patient.dob && moment(patient.dob).isValid() ? moment(patient.dob).format("MM/DD/YYYY") : undefined,
    avlt: this.parseNumber(patient.avlt),
    mmse: this.parseNumber(patient.mmse),
    height: this.parseNumber(this.convertHeight(patient.height, unit)),
    weight: this.parseNumber(this.convertWeight(patient.weight, unit)),
  })

  convertHeight = (value, unit) => {
    if (value == 0) {
      return 0;
    }
    if (unit === "imperial") {
      let inches = 0;
      let ftInches = 0;
      if (!value.ft && !value.in) {
        // try to use cm;
        return value && value.cm ? Number(value.cm) : undefined;
      } else if (!value.ft) {
        inches = new LengthConverter({ value: value.in, unit: "in" }).value();
      } else if (!value.in) {
        ftInches = new LengthConverter({ value: value.ft, unit: "ft" }).to("in").value();
      } else {
        inches = new LengthConverter({ value: value.in, unit: "in" }).value();
        ftInches = new LengthConverter({ value: value.ft, unit: "ft" }).to("in").value();
      }
      const cm = new LengthConverter({ value: inches + ftInches, unit: "in" }).to("cm").value();
      return cm;
    }
    return value && value.cm ? Number(value.cm) : undefined;
  }

  convertWeight = (value, unit) => {
    if (value == 0) {
      return 0;
    }
    if (unit === "imperial") {
      if (!value.lb) {
        // try to use kg
        return value && value.kg ? Number(value.kg) : undefined;
      }
      return value.lb / 2.205;
    }
    return value && value.kg ? Number(value.kg) : undefined;
  }

  parseNumber = (str) => {
    if (typeof str === "string" && str.length === 0) {
      return undefined;
    }
    const parsed = Number(str);
    if (Number.isNaN(parsed) || parsed < 0) {
      return undefined;
    }
    return parsed;
  }

  handlePatientSubmit = () => {
    const { patient, unit } = this.state;
    const p = this.formatPatient(patient, unit);
    this.props.onSubmit(p);
  }

  height = () => {
    const { unit, patient: { height } } = this.state;
    if (!height.cm && !height.ft && !height.in) {
      return {};
    }
    if (unit === "metric") {
      return { cm: height.cm };
    }
    return { ft: height.ft, in: height.in };
  }

  weight = () => {
    const { unit, patient: { weight } } = this.state;
    if (!weight.kg && !weight.lb) {
      return {};
    }
    if (unit === "metric") {
      return { kg: weight.kg };
    }
    return { lb: weight.lb };
  }

  handleUnitChange = (unit) => {
    this.setState({ unit });
  }

  handleHeightChange = (value, unit) => {
    if (!["cm", "ft", "in"].includes(unit) || value < 0) {
      return;
    }
    this.setState(prevState => {
      return {
        ...prevState,
        patient: {
          ...prevState.patient,
          height: {
            ...prevState.patient.height,
            [unit]: value
          }
        }
      };
    });
  }

  handleWeightChange = (e) => {
    const { value } = e.target;
    if (value <= 0) {
      return;
    }
    this.setState(prevState => {
      const unit = prevState.unit === "metric" ? "kg" : "lb";
      return {
        ...prevState,
        patient: {
          ...prevState.patient,
          weight: {
            ...prevState.patient.weight,
            [unit]: value
          }
        }
      };
    });
  }

  render() {
    const { patient, unit } = this.state;
    const { loading } = this.props;
    const isMetric = unit === "metric";
    const weight = this.weight()[isMetric ? "kg" : "lb"];
    const height = this.height()[isMetric ? "cm" : "ft"];
    const inches = this.height().in;
    const weightError = weight && !this.parseNumber(weight);
    const heightError = height && !this.parseNumber(height);
    const inError = inches && !this.parseNumber(inches);
    const avltError = patient.avlt && (this.parseNumber(patient.avlt) === undefined || this.parseNumber(patient.avlt) < 1 || this.parseNumber(patient.avlt) > 100);
    const mmseError = patient.mmse && (this.parseNumber(patient.mmse) === undefined || this.parseNumber(patient.mmse) < 0 || this.parseNumber(patient.mmse) > 30);
    const submitDisabled = (
      loading ||
      weightError ||
      heightError ||
      inError ||
      !patient.firstName.length ||
      !patient.lastName.length ||
      !moment(patient.dob).isValid() ||
      avltError ||
      mmseError
    );
    return (
      <Modal
        style={{
          height: 900,
          width: 700,
          overflow: "hidden",
          overflowY: "scroll",
          zIndex: 1299
        }}
        backgroundStyle={{
          zIndex: 1298
        }}
      >
        <GridContainer direction="row" style={{ height: "100%", padding: "0 40px" }}>
          <ItemGrid xs={12} style={{ alignSelf: "flex-start" }}><h2 style={{ textAlign: "center" }}>{this.props.title}</h2></ItemGrid>
          <ItemGrid xs={12}>
            <Input
              labelText="First Name (required)"
              success={!!patient.firstName.length}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: loading,
                name: "firstName",
                value: patient.firstName,
                onChange: this.handleInputChange
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12}>
            <Input
              labelText="Last Name (required)"
              success={!!patient.lastName.length}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: loading,
                name: "lastName",
                value: patient.lastName,
                onChange: this.handleInputChange
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12}>
            <InputLabel
              success={moment(patient.dob).isValid()}
            >Date of Birth (required)</InputLabel>
            <DatePickerButton
              color="primary"
              pickerAlign="center"
              onChange={dob => this.setState(prevState => ({ ...prevState, patient: { ...prevState.patient, dob } }))}
              defaultValue={moment(patient.dob)}
              viewDate={moment(patient.dob)}
              start={moment(patient.dob)}
              dateFormat="MM-DD-YYYY"
              closeOnSelect
            />
          </ItemGrid>
          <ItemGrid xs={12}>
            <InputLabel success>Sex (required)</InputLabel>
            <Select
              values={[{ display: "Male", value: "M" }, { display: "Female", value: "F" }, { display: "Intersex", value: "O" }]}
              inputProps={{
                name: "sex",
                value: patient.sex,
                onChange: this.handleInputChange,
              }}
              formControlProps={{
                fullWidth: true,
                style: {
                  marginBottom: 10
                }
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12}>
            <Input
              labelText="Customer Patient ID (optional)"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: loading,
                name: "PatientID",
                value: patient.PatientID,
                onChange: this.handleInputChange
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12} md={6}>
            <Button fullWidth color={isMetric ? "primary" : undefined} onClick={() => this.handleUnitChange("metric")}>Metric</Button>
          </ItemGrid>
          <ItemGrid xs={12} md={6}>
            <Button fullWidth color={!isMetric ? "primary" : undefined} onClick={() => this.handleUnitChange("imperial")}>Imperial</Button>
          </ItemGrid>
          <ItemGrid xs={12}>
            <h5>Height (optional)</h5>
          </ItemGrid>
          <ItemGrid xs={12} md={isMetric ? 12 : 6}>
            <Input
              labelText={isMetric ? "Centimeters" : "Feet"}
              error={heightError}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: loading,
                name: "height",
                value: height,
                onChange: (e) => this.handleHeightChange(e.target.value, isMetric ? "cm" : "ft")
              }}
            />
          </ItemGrid>
          {!isMetric && <ItemGrid xs={12} md={6}>
            <Input
              labelText="Inches"
              error={inError}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: loading,
                name: "height",
                value: inches,
                onChange: (e) => this.handleHeightChange(e.target.value, "in")
              }}
            />
          </ItemGrid>
          }
          <ItemGrid xs={12}>
            <h5>Weight (optional)</h5>
          </ItemGrid>
          <ItemGrid xs={12}>
            <Input
              labelText={isMetric ? "Kilograms" : "Pounds"}
              error={weightError}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: loading,
                name: "weight",
                value: weight,
                onChange: this.handleWeightChange,
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12}>
            <h5>Cognitive Tests (optional)</h5>
          </ItemGrid>
          <ItemGrid xs={12}>
            <Input
              labelText={avltError ? "AVLT - should be 1 to 100" : "AVLT"}
              error={avltError}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: loading,
                name: "avlt",
                value: patient.avlt,
                onChange: this.handleInputChange
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12}>
            <Input
              labelText={mmseError ? "MMSE - should be 0 to 30" : "MMSE"}
              error={mmseError}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: loading,
                name: "mmse",
                value: patient.mmse,
                onChange: this.handleInputChange
              }}
            />
          </ItemGrid>
          <ItemGrid container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }} xs={12}>
            <Button onClick={this.props.onClose} disabled={loading}>Close</Button>
            <Button
              style={{ marginRight: 15, marginLeft: 15 }}
              color="success"
              onClick={this.handlePatientSubmit}
              disabled={submitDisabled}
            >{this.props.submitButtonText}</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );

  }
}