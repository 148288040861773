import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Datetime from "react-datetime";

// core 
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard";
import Button from "components/CustomButtons/Button.jsx";
import LoadingModal from "components/LoadingModal/LoadingModal";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { WithNotifications } from "components/HigherOrder";

// local components
import Licenses from "./components/Licenses";

// material-ui components
import Switch from "material-ui/Switch";
import Tooltip from "material-ui/Tooltip";
import Close from "material-ui-icons/Close";

// icons & styles
import withStyles from "material-ui/styles/withStyles";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyleModal.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import VisibilityIcon from "material-ui-icons/Visibility";

import { getAllOrgs, getAvailableApps, updateOrgLicenses } from "libs/apiLib";

const style = {
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  clearIcon: {
    fontSize: "23px",
    height: ".6em",
    width: ".6em",
  },
  dateButton: {
    marginBottom: "8px",
  },
  dateLabel: {
    marginBottom: 0,
  },
  label: {
    color: "gray",
    fontSize: "small",
  },
  sectionHeader: {
    borderBottom: "1px solid #eee",
    marginTop: "30px",
    paddingBottom: "15px",
  },
  subtitle: {
    color: "gray",
    fontSize: "small",
    marginTop: "-10px",
  },
};

const TitleSubtitle = ({ classes, title, subtitle }) => (
  <React.Fragment>
    <h5>{title}</h5>
    <p className={classes.subtitle}>{subtitle}</p>
  </React.Fragment>
);

TitleSubtitle.propTypes = {
  classes: PropTypes.shape({
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const SwitchRow = ({ classes, title, subtitle, value, checked, onChange }) => (
  <React.Fragment>
    <ItemGrid xs={12} sm={10}>
      <TitleSubtitle classes={classes} title={title} subtitle={subtitle} />
    </ItemGrid>
    <ItemGrid xs={12} sm={2}>
      <Tooltip id="tooltip-icon" title={title} placement="top">
        <Switch
          checked={checked}
          onChange={onChange}
          value={value}
          classes={{
            bar: classes.switchBarChecked,
            checked: classes.switchChecked,
            default: classes.switchUnchecked,
            icon: classes.switchIcon,
            iconChecked: classes.switchIconChecked
          }}
        />
      </Tooltip>
    </ItemGrid>
  </React.Fragment>
);

SwitchRow.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.shape({}),
  classes: PropTypes.shape({
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const EdgeSwitchRow = ({ classes, title, subtitle, value, checked, onChange, edgeNumber, edgeNumberChange }) => {
  const titleSmColumn = checked ? 6 : 10;
  const switchXsColumn = checked ? 3 : 12;
  return (
    <React.Fragment>
      <ItemGrid xs={12} sm={titleSmColumn}>
        <TitleSubtitle classes={classes} title={title} subtitle={subtitle} />
      </ItemGrid>
      {
        checked &&
        <ItemGrid xs={9} sm={4}>
          <Button size="sm" color="danger" disabled={edgeNumber == 1} onClick={() => edgeNumberChange(-1)}>Subtract</Button>
          <Button size="sm" disabled>{edgeNumber}</Button>
          <Button size="sm" color="success" onClick={() => edgeNumberChange(1)}>Add</Button>
        </ItemGrid>
      }
      <ItemGrid xs={switchXsColumn} sm={2}>
        <Tooltip id="tooltip-icon" title={title} placement="top">
          <Switch
            checked={checked}
            onChange={onChange}
            value={value}
            classes={{
              bar: classes.switchBarChecked,
              checked: classes.switchChecked,
              default: classes.switchUnchecked,
              icon: classes.switchIcon,
              iconChecked: classes.switchIconChecked
            }}
          />
        </Tooltip>
      </ItemGrid>
    </React.Fragment>
  );
};

EdgeSwitchRow.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.shape({}),
  classes: PropTypes.shape({
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  edgeNumber: PropTypes.number.isRequired,
  edgeNumberChange: PropTypes.func.isRequired
};

export class ALM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiAllowed: false,
      appLicenses: undefined,
      apps: props.location.state && props.location.state.apps,
      edgeAllowed: false,
      loading: false,
      org: props.location.state && props.location.state.org,
      orgs: this.excludeOrg(props.location.state && props.location.state.orgs || undefined, props.location.state && props.location.state.org && props.location.state.org.orgId || undefined),
      orgID: props.match.params.orgID,
      researchAllowed: false,
      numOfEdgeDevices: 1,
      geneticKitsAllowed: false,
      multiOrgUploadAllowed: false,
      orgUploadConfig: []
    };
  }

  excludeOrg(orgs, orgId) {
    if (!orgs || !orgId) {
      return [];
    }
    return orgs.filter(o => o.orgId !== orgId);
  }

  async componentDidMount() {
    const { org, apps } = this.state;
    if (!org) {
      await this.getOrg();
    } else {
      this.updateAccess(org);
    }

    if (!apps) {
      await this.getApps();
    }

    if (apps && org) {
      this.updateAppLicenses();
    }
  }

  componentDidUpdate(_, prevState) {
    const { apps, org } = this.state;
    if (!prevState.apps && apps && org) {
      this.updateAppLicenses();
    }
    if (!prevState.org && org) {
      this.updateAccess(org);
    }
  }

  updateAccess(org) {
    if (!org) {
      return;
    }
    const { edgeAllowed, apiAllowed, researchAllowed, numOfEdgeDevices, geneticKitsAllowed, multiOrgUploadAllowed, orgUploadConfig } = org;
    this.setState({
      apiAllowed: apiAllowed || false,
      edgeAllowed: edgeAllowed || false,
      researchAllowed: researchAllowed || false,
      numOfEdgeDevices: numOfEdgeDevices || 1,
      geneticKitsAllowed: geneticKitsAllowed || false,
      multiOrgUploadAllowed: multiOrgUploadAllowed || false,
      orgUploadConfig: orgUploadConfig || []
    });
  }

  updateAppLicenses() {
    const { apps, org } = this.state;
    const { appLicenses } = org;
    const update = apps.reduce((memo, app) => {
      let licensedApp;
      if (appLicenses && appLicenses.length) {
        licensedApp = appLicenses.find(l => l && l.appId === app.appId);
      }
      let item = {
        appId: app.appId,
        enabled: false,
        name: app.name,
      };
      if (licensedApp) {
        item.enabled = licensedApp.enabled;
        item.expiration = licensedApp.expiration;
      }
      return [
        ...memo,
        item,
      ];
    }, []);
    this.setState({ appLicenses: update });
  }

  async getOrg() {
    this.setState({ loading: true });
    try {
      const { getAllOrgs } = this.props;
      const result = await getAllOrgs();
      if (result.success) {
        const org = result.orgs.Items.find(org => org.orgId === this.state.orgID);
        if (org) {
          this.setState({ org });
        }
        this.setState({ orgs: this.excludeOrg(result.orgs.Items, this.state.orgID) });
      }
    } catch (e) {
      this.props.signalError("Error retrieving organizations.");
      setTimeout(this.props.clearError, 5000);
    }
    this.setState({ loading: false });
  }

  getApps = async () => {
    this.setState({ loading: true });
    try {
      const { getApps } = this.props;
      const result = await getApps();
      if (result.success) { this.setState({ apps: result.result.Items }); }
    } catch (e) {
      this.props.signalError("Error retrieving applications.");
      setTimeout(this.props.clearError, 5000);
    }
    this.setState({ loading: false });
  }

  handleAppToggle = (i, toggle) => this.setState(prevState => this.toggleApp(prevState, i, toggle))

  toggleApp = (state, i, toggle) => {
    if (!state.appLicenses) {
      return state;
    }
    const { appLicenses } = state;
    appLicenses[i].enabled = toggle;
    if (toggle === false && appLicenses[i].expiration) {
      delete appLicenses[i].expiration;
    }
    return {
      ...state,
      appLicenses,
    };
  }

  handleAccessToggle = (accessType, toggle) => {
    this.setState({
      [accessType]: toggle
    });
  }

  handleAppDateChange = (i, date) => this.setState(prevState => this.changeDate(prevState, i, date))

  changeDate = (state, i, date) => {
    if (!state.appLicenses) {
      return state;
    }
    const { appLicenses } = state;
    appLicenses[i].expiration = moment(date).format();
    return {
      ...state,
      appLicenses,
    };
  }

  handleDeleteExpiration = (i) => this.setState(prevState => this.deleteExpiration(prevState, i))

  deleteExpiration = (state, i) => {
    if (!state.appLicenses) {
      return state;
    }
    const { appLicenses } = state;
    if (appLicenses[i].expiration) {
      delete appLicenses[i].expiration;
    }
    return {
      ...state,
      appLicenses,
    };
  }

  handleSubmit = async () => {
    this.setState({ loading: true });
    try {
      const { updateALSettings } = this.props;
      const {
        apiAllowed,
        appLicenses,
        edgeAllowed,
        orgID,
        researchAllowed,
        numOfEdgeDevices,
        geneticKitsAllowed,
        multiOrgUploadAllowed,
        orgUploadConfig,
      } = this.state;
      const response = await updateALSettings(orgID, {
        apiAllowed,
        appLicenses,
        edgeAllowed,
        researchAllowed,
        numOfEdgeDevices: edgeAllowed ? numOfEdgeDevices : undefined,
        geneticKitsAllowed,
        multiOrgUploadAllowed,
        orgUploadConfig
      });
      if (response.org) {
        this.updateAccess(response.org);
      }
    } catch (e) {
      this.props.signalError("Error updating organization platform access and licenses.");
      setTimeout(this.props.clearError, 5000);
    }
    this.setState({ loading: false });
  }

  edgeNumberChange = (n) => {
    const { numOfEdgeDevices } = this.state;
    if (numOfEdgeDevices + n == 0) {
      return;
    }
    this.setState(prevState => ({ ...prevState, numOfEdgeDevices: prevState.numOfEdgeDevices + n }));
  }

  handleEdgeAllowedToggle = (toggle) => {
    this.setState(prevState => ({
      edgeAllowed: toggle,
      numOfEdgeDevices: toggle ? prevState.numOfEdgeDevices : 1
    }));
  }

  multiOrgAvailableOrgs = () => {
    const { orgs, orgUploadConfig } = this.state;
    return orgs.reduce((arr, org) => {
      if (orgUploadConfig.some(config => config.orgName === org.orgName)) {
        return arr;
      }
      return [
        ...arr,
        org.orgName
      ];
    }, []);
  }

  handleOrgDropdownSelection = (selectedOrgName) => {
    this.setState(prevState => {
      const { orgId, orgName } = prevState.orgs.find(o => o.orgName === selectedOrgName);
      return {
        ...prevState,
        orgUploadConfig: [
          ...prevState.orgUploadConfig,
          {
            orgId,
            orgName
          }
        ]
      };
    });
  }

  handleOrgUploadRemove = (orgId) => {
    this.setState({
      orgUploadConfig: this.state.orgUploadConfig.filter(c => c.orgId !== orgId)
    });
  }

  multiOrgUploadAETitle = (suffix) => `HLXU:${suffix}`

  render() {
    const { classes } = this.props;
    const { loading, appLicenses, edgeAllowed, apiAllowed, researchAllowed, geneticKitsAllowed, multiOrgUploadAllowed, orgUploadConfig } = this.state;
    return (
      <GridContainer>
        {loading && <LoadingModal />}
        <ItemGrid xs={12}>
          <IconCard
            iconColor="blue"
            icon={VisibilityIcon}
            title="Access & Subscription Settings"
            horizontalPadding="medium"
            content={
              <GridContainer>
                <ItemGrid xs={12}><p className={classes.subtitle}>Configure what access this organization can have</p></ItemGrid>
                <ItemGrid xs={12}><h3 className={classes.sectionHeader}>Platform Access</h3></ItemGrid>
                <EdgeSwitchRow
                  title="Edge"
                  subtitle="Allow this organization to use Edge devices"
                  classes={classes}
                  checked={edgeAllowed}
                  onChange={(e) => this.handleEdgeAllowedToggle(e.target.checked)}
                  edgeNumberChange={this.edgeNumberChange}
                  edgeNumber={this.state.numOfEdgeDevices}
                  value="edgeAllowed"
                />
                <SwitchRow
                  title="API"
                  subtitle="Allow this organization to interact with the HealthLytix Platform using the Developer APIs"
                  classes={classes}
                  onChange={(e) => this.handleAccessToggle("apiAllowed", e.target.checked)}
                  value="apiAllowed"
                  checked={apiAllowed}
                />
                <SwitchRow
                  title="Research"
                  subtitle="Allow this organization to access pre-release software and research packages"
                  classes={classes}
                  onChange={(e) => this.handleAccessToggle("researchAllowed", e.target.checked)}
                  value="researchAllowed"
                  checked={researchAllowed}
                />
                <SwitchRow
                  title="Genetic Kits"
                  subtitle="Allow this organization to order Genetic Kits using the Diagnomics Integration"
                  classes={classes}
                  onChange={(e) => this.handleAccessToggle("geneticKitsAllowed", e.target.checked)}
                  value="geneticKitsAllowed"
                  checked={geneticKitsAllowed}
                />
                <SwitchRow
                  title="Multi-Organization Upload"
                  subtitle="Allow this organization to send Edge device data to other organizations"
                  classes={classes}
                  onChange={(e) => this.handleAccessToggle("multiOrgUploadAllowed", e.target.checked)}
                  value="multiOrgUploadAllowed"
                  checked={multiOrgUploadAllowed}
                />
                <ItemGrid xs={12}><h3 className={classes.sectionHeader}>Multi-Organization Upload Configuration</h3></ItemGrid>
                <ItemGrid sm={12} md={8}>
                  <p style={{ marginTop: 20 }}>View, add and remove the organizations that this organization can send data to. After adding a new organization and clicking the &quot;Submit&quot; button, an AETitle will be automatically generated and displayed next to each organization. Previously added organizations and the corresponding AETitle&apos;s cannot be edited but only be removed.</p>
                </ItemGrid>
                <ItemGrid sm={12} md={4} container justify="flex-end">
                  <CustomDropdown
                    disabled={!multiOrgUploadAllowed}
                    buttonColor="primary"
                    buttonText="Select Organization"
                    onAction={this.handleOrgDropdownSelection}
                    dropdownList={this.multiOrgAvailableOrgs()}
                  />
                </ItemGrid>
                {
                  orgUploadConfig.map(config => {
                    return (
                      <React.Fragment key={config.orgId}>
                        <ItemGrid xs={12} sm={6}>
                          <TitleSubtitle classes={classes} title={config.orgName} subtitle={config.orgId} />
                        </ItemGrid>
                        <ItemGrid xs={9} sm={4}>
                          <h5 style={{ color: config.id ? "inherit" : "grey" }}>{config.id ? this.multiOrgUploadAETitle(config.id) : "Pending"}</h5>
                          <p className={classes.subtitle}>AETitle</p>
                        </ItemGrid>
                        <ItemGrid xs={3} sm={2}>
                          <Button round size="sm" color="danger" disabled={!multiOrgUploadAllowed} onClick={() => this.handleOrgUploadRemove(config.orgId)}>x</Button>
                        </ItemGrid>
                      </React.Fragment>
                    );
                  })
                }
                <ItemGrid xs={12}><h3 className={classes.sectionHeader}>App Subscriptions</h3></ItemGrid>
                {
                  appLicenses && appLicenses.map((app, i) => {
                    const appLabelSmColumn = app.enabled ? 6 : 10;
                    const switchXsColumn = app.enabled ? 3 : 12;
                    return (
                      <React.Fragment key={i}>
                        <ItemGrid xs={12} sm={appLabelSmColumn}>
                          <h5>{app.name}</h5>
                          <p className={classes.subtitle}>{app.appId}</p>
                        </ItemGrid>
                        {
                          app.enabled &&
                          <ItemGrid xs={9} sm={4}>
                            <Datetime
                              isValidDate={date => date.isAfter(moment())}
                              onChange={date => this.handleAppDateChange(i, date)}
                              renderInput={(_, open) => {
                                if (!app.expiration) {
                                  return <Button size="sm" round color="primary" onClick={open} className={classes.dateButton}>Unlimited</Button>;
                                }
                                return (
                                  <React.Fragment>
                                    <Button size="sm" round color="warning" onClick={open} className={classes.dateButton}>{moment(app.expiration).format("lll")}</Button>
                                    <Button size="sm" round color="danger" onClick={() => this.handleDeleteExpiration(i)} className={classes.dateButton}><Close className={classes.clearIcon} /></Button>
                                  </React.Fragment>
                                );
                              }}
                            />
                            <p className={classes.label + " " + classes.dateLabel} style={{ marginBottom: "0" }}>Expiration</p>
                          </ItemGrid>
                        }
                        <ItemGrid xs={switchXsColumn} sm={2}>
                          <Switch
                            checked={app.enabled}
                            onChange={(e) => this.handleAppToggle(i, e.target.checked)}
                            classes={{
                              bar: classes.switchBarChecked,
                              checked: classes.switchChecked,
                              default: classes.switchUnchecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked
                            }}
                          />
                        </ItemGrid>
                      </React.Fragment>
                    );
                  })
                }
                <ItemGrid container justify="flex-end" xs={12}>
                  <Button color="primary" disabled={loading} onClick={this.handleSubmit}>Update</Button>
                </ItemGrid>
              </GridContainer>
            }
          />
        </ItemGrid>
        <ItemGrid xs={12}>
          <Licenses edgeAllowed={edgeAllowed} apps={this.state.appLicenses} />
        </ItemGrid>
      </GridContainer>
    );
  }
}

ALM.propTypes = {
  classes: PropTypes.shape({
    clearIcon: PropTypes.string.isRequired,
    dateButton: PropTypes.string.isRequired,
    dateLabel: PropTypes.string.isRequired,
    sectionHeader: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
  getAllOrgs: PropTypes.func.isRequired,
  getApps: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      apps: PropTypes.arrayOf(PropTypes.shape({
        appId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })),
      org: PropTypes.shape({
        orgId: PropTypes.string.isRequired,
        orgName: PropTypes.string.isRequired,
      }),
      orgs: PropTypes.arrayOf(PropTypes.shape({
        orgId: PropTypes.string.isRequired,
        orgName: PropTypes.string.isRequired,
      }))
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orgID: PropTypes.string.isRequired
    }).isRequired,
  }).isRequired,
  updateALSettings: PropTypes.func.isRequired,
  signalError: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired
};

const mapDispatchToProps = () => ({
  // does not use dispatch
  getAllOrgs: () => getAllOrgs(),
  getApps: () => getAvailableApps(),
  updateALSettings: (orgID, settings) => updateOrgLicenses(orgID, settings),
});

export default connect(undefined, mapDispatchToProps)(WithNotifications(withStyles(style)(ALM)));