import React from "react";
import PropTypes from "prop-types";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button";

export const PaginationButton = ({ hide, disabled, onPageChange, active, activeText, inactiveText }) => {
  if (hide) { return null; }
  return (
    <Button
      onClick={onPageChange}
      color={active ? "primary" : "defaultNoBackground"}
      disabled={disabled}
    >
      {active || !inactiveText ? activeText : inactiveText}
    </Button>
  );
};

PaginationButton.propTypes = {
  hide: PropTypes.bool,
  disabled: PropTypes.bool,
  onPageChange: PropTypes.func,
  active: PropTypes.bool,
  activeText: PropTypes.string,
  inactiveText: PropTypes.string,
};

export default class ReactTablePagination extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    canGetMorePages: PropTypes.bool.isRequired,
    page: PropTypes.number,
    canPrevious: PropTypes.bool.isRequired,
    refreshing: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onGetMore: PropTypes.func,
    pageSize: PropTypes.number,
    allPagesFulfilled: PropTypes.bool,
  }

  constructor(props) {
    super();
    this.state = {
      page: props.page || 0,
    };
  }

  componentDidUpdate() {
    // If prop page is different from state page, update state page
    if (Number.isInteger(this.props.page) && this.state.page !== this.props.page) {
      this.setState({
        page: this.props.page,
      });
    }
  }

  getVisiblePages = (page, total) => {
    if (total <= 5) {
      return [1, 2, 3, 4, 5].filter(page => page <= total);
    }
    if (page + 1 === total) {
      return [1, page - 2, page - 1, page, total];
    }
    if (page - 1 === 1) {
      return [1, page, page + 1, page + 2, total];
    }
    if (page === total) {
      return [1, page - 3, page - 2, page - 1, total];
    }
    if (page === 1) {
      return [1, 2, 3, 4, total];
    }
    return [1, page - 1, page, page + 1, total];
  }

  // TODO: add "preventMorePagination" bool prop and use "getSafePage" to prevent paginating out-of-pages
  // getSafePage (page) {
  // 	if (Number.isNaN(page)) {
  // 		page = this.props.page
  // 	}
  // 	return Math.min(Math.max(page, 0), this.props.pages - 1)
  // }

  changePage = (page) => {
    const pages = this.pages();
    if (this.state.page === page || this.props.page === page) {
      // return if state page or prop page equals page argument, return
      return;
    }

    // update page state
    this.setState({ page });

    // call 'onPageChange' callback prop with page, if defined
    if (page < pages && this.props.onPageChange) {
      this.props.onPageChange(page);
    }

    // call 'onGetMore' callback prop if page is on last page && 'allPagesFulfilled' prop is false
    if (this.props.onGetMore && !this.props.allPagesFulfilled && page === pages) {
      this.props.onGetMore();
    }
  }

  isJumpPage = (i, pages) => {
    if (pages.length < 5 || (i !== 1 && i !== 3)) {
      return false;
    }
    if (i === 1 && pages[1] - 1 !== 1) {
      return true;
    }
    if (i === 3 && pages[3] + 1 !== pages[4]) {
      return true;
    }
    return false;
  }

  pageSize = () => this.props.pageSize || 10
  pages = () => this.count() > 0 ? Math.ceil(this.count() / this.pageSize()) : 1
  count = () => this.props.data && this.props.data.length || 0

  render() {
    const {
      // ReactTable props- not used
      // data,
      // pageSize,
      // pages,
      // page,
      // canNext,
      // showPageSizeOptions,
      // pageSizeOptions,
      // showPageJump,
      // onPageSizeChange,
      // className,

      // custom props
      refreshing,
      canGetMorePages,
      allPagesFulfilled,
    } = this.props;

    const {
      page,
    } = this.state;

    const pages = this.pages();
    const count = this.count();
    const noPageData = count === 0;
    const onLastPage = page === pages - 1;
    const canPrevious = page > 0 && !refreshing;
    const disableNext = (onLastPage && allPagesFulfilled) || refreshing || noPageData;
    const canGetMore = canGetMorePages && !allPagesFulfilled;
    return (
      <GridContainer direction="row">
        <ItemGrid xs={12} sm container>
          <PaginationButton
            disabled={!canPrevious}
            onPageChange={() => {
              if (!canPrevious) { return; }
              this.changePage(page - 1);
            }}
            activeText="Previous"
          />
        </ItemGrid>
        <ItemGrid xs={12} sm={6} container justify="center">
          {this.getVisiblePages(page + 1, pages).map((p, i, array) => {
            return (
              <PaginationButton
                key={p + i + ""}
                disabled={refreshing}
                onPageChange={() => this.changePage(p - 1)}
                active={page === p - 1}
                activeText={this.isJumpPage(i, array) ? `... ${p}` : `${p}`}
              />
            );
          })}
        </ItemGrid>
        <ItemGrid xs={12} sm container justify="flex-end">
          <PaginationButton
            hide={onLastPage && allPagesFulfilled}
            disabled={disableNext}
            active={onLastPage && canGetMorePages}
            onPageChange={() => {
              if (disableNext) { return; }
              this.changePage(page + 1);
            }}
            activeText={canGetMore ? "More" : "Next"}
            inactiveText="Next"
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}