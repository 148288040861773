import React from "react";
import Bar from "./Bar";

const BarStacked = ({ options, ...rest }) => (
  <Bar
    {...rest}
    options={{
      ...options,
      stackBars: true,
    }}
  />
);

BarStacked.propTypes = {
  ...Bar.propTypes,
};

export default BarStacked;