import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import Datetime from "react-datetime"

// components
import GridContainer from "components/Grid/GridContainer.jsx"
import ItemGrid from "components/Grid/ItemGrid.jsx"
import Button from "components/CustomButtons/Button.jsx"

// material-ui components
import withStyles from "material-ui/styles/withStyles"

const style = {
	label: {
		fontSize: ".75em",
		margin: 0,
	},
	leftPicker: {
		"& .rdtPicker": {
			top: "75px",
			left: "29px",
		},
		"& .rdtPicker:after": {
			left: "0px"
		},
		"& .rdtPicker:before": {
			left: "0px"
		}
	},
	rightPicker: {
		"& .rdtPicker": {
			top: "75px",
			left: "-82px",
		},
		"& .rdtPicker:after": {
			left: "249px"
		},
		"& .rdtPicker:before": {
			left: "249px"
		}
	},
}

/*
  DateRangePicker displays two date picker buttons to provide a start and end date range
*/
const DateRangePicker = ({ start, end, classes, color, defaultValue, timeFormat, onStartChange, onEndChange, justify, closeOnSelect, disableFutureDays, startDateFormat, endDateFormat }) => {
	const today = moment()
	return (
		<React.Fragment>
			<ItemGrid xs={6}>
				<Datetime
					className={classes.leftPicker}
					isValidDate={(startDate) => {
						if (disableFutureDays) {
							return startDate.isBefore(today) && startDate.isBefore(end)
						}
						return startDate.isBefore(end)
					}}
					closeOnSelect={closeOnSelect}
					defaultValue={start || defaultValue}
					viewDate={start}
					timeFormat={timeFormat}
					onChange={onStartChange}
					dateFormat={startDateFormat}
					renderInput={(inputProps, openCalendar) => (
						<React.Fragment>
							<p className={classes.label}>From:</p>
							<Button {...inputProps} color={color} onClick={openCalendar}>{start.format(startDateFormat)}</Button>
						</React.Fragment>
					)}
				/>
			</ItemGrid>
			<ItemGrid xs={6}>
				<Datetime
					className={classes.rightPicker}
					isValidDate={(endDate) => {
						if (disableFutureDays) {
							return endDate.isBefore(today) && endDate.isAfter(start)
						}
						return endDate.isAfter(start)
					}}
					closeOnSelect={closeOnSelect}
					defaultValue={end || defaultValue.add(1, "d")}
					viewDate={end}
					timeFormat={timeFormat}
					onChange={onEndChange}
					dateFormat={endDateFormat}
					renderInput={(inputProps, openCalendar) => (
						<React.Fragment>
							<p className={classes.label}>To:</p>
							<Button {...inputProps} color={color} onClick={openCalendar}>{end.format(endDateFormat)}</Button>
						</React.Fragment>
					)}
				/>
			</ItemGrid>
		</React.Fragment>
	)
}

DateRangePicker.defaultProps = {
	startDateFormat: "MMMM Do YYYY",
	endDateFormat: "MMMM Do YYYY",
	disableFutureDays: true,
	closeOnSelect: false,
	justify: "center",
	color: "blackWhite",
	timeFormat: false,
	defaultValue: moment(),
	start: moment(),
	end: moment(),
}

DateRangePicker.propTypes = {
	startDateFormat: PropTypes.string,
	endDateFormat: PropTypes.string,
	disableFutureDays: PropTypes.bool,
	closeOnSelect: PropTypes.bool,
	justify: PropTypes.oneOf(["center", "flex-end", "flex-start"]),
	color: PropTypes.string,
	timeFormat: PropTypes.bool,
	onStartChange: PropTypes.func,
	onEndChange: PropTypes.func,
	defaultValue: PropTypes.instanceOf(moment),
	start: PropTypes.instanceOf(moment).isRequired,
	end: PropTypes.instanceOf(moment).isRequired,
}

export default withStyles(style)(DateRangePicker)