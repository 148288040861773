import "whatwg-fetch";
import Promise from "promise-polyfill";
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
  AuthenticationDetails
} from "amazon-cognito-identity-js";

import AWS from "aws-sdk";
import APPCONFIG from "constants/Config";
import sigV4Client from "./sigV4Client";

AWS.config.update({ region: APPCONFIG.cognito.REGION });

export async function authUser() {

  if (
    AWS.config.credentials &&
    Date.now() < AWS.config.credentials.expireTime - 60000
  ) {
    return true;
  }

  const currentUser = getCurrentUser();

  if (currentUser === null) {
    return false;
  }

  const userToken = await getUserToken(currentUser);

  await getAwsCredentials(userToken);

  return true;
}

export async function getCurrentUserAccessToken() {
  const currentUser = getCurrentUser();

  return new Promise((resolve, reject) => {
    currentUser.getSession(function (err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getAccessToken().getJwtToken());
    });
  });
}

export function signOutUser() {
  const currentUser = getCurrentUser();

  if (currentUser !== null) {
    currentUser.signOut();
  }

  if (AWS.config.credentials) {
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({});
  }
}

export function login(email, password) {
  const userPool = new CognitoUserPool({
    UserPoolId: APPCONFIG.cognito.USER_POOL_ID,
    ClientId: APPCONFIG.cognito.APP_CLIENT_ID
  });
  const user = new CognitoUser({ Username: email, Pool: userPool });
  const authenticationData = { Username: email, Password: password };
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  return new Promise((resolve, reject) =>
    user.authenticateUser(authenticationDetails, {
      onSuccess: () => { resolve(); },
      onFailure: err => reject(err)
    })
  );
}

export function getUserByEmail(email) {
  const userPool = new CognitoUserPool({
    UserPoolId: APPCONFIG.cognito.USER_POOL_ID,
    ClientId: APPCONFIG.cognito.APP_CLIENT_ID
  });
  return new CognitoUser({ Username: email, Pool: userPool });
}

export function resendConfirmation(email) {
  const user = getUserByEmail(email);
  return new Promise((res, rej) => {
    user.resendConfirmationCode((err, result) => {
      if (err) {
        rej(err);
      }
      res(result);
    });
  });
}

export function signup(email, password, firstName, lastName) {

  const userPool = new CognitoUserPool({
    UserPoolId: APPCONFIG.cognito.USER_POOL_ID,
    ClientId: APPCONFIG.cognito.APP_CLIENT_ID
  });

  let dataEmail = { Name: "email", Value: email };
  let dataFirstName = { Name: "given_name", Value: firstName };
  let dataLastName = { Name: "family_name", Value: lastName };

  let attrList = [];

  attrList.push(new CognitoUserAttribute(dataEmail));
  attrList.push(new CognitoUserAttribute(dataFirstName));
  attrList.push(new CognitoUserAttribute(dataLastName));

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attrList, null, (err, result) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(result.user);
    });
  });
}

export function forgotPassword(email) {
  const userPool = new CognitoUserPool({
    UserPoolId: APPCONFIG.cognito.USER_POOL_ID,
    ClientId: APPCONFIG.cognito.APP_CLIENT_ID
  });
  const user = new CognitoUser({ Username: email, Pool: userPool });

  return new Promise((resolve, reject) => {
    user.forgotPassword({
      onSuccess: (data) => resolve(user),
      onFailure: (err) => reject(err)
    });
  });
}

export function confirmForgotPasswordChange(
  verificationCode,
  cognitoUser,
  newPassword) {
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() { resolve(); },
      onFailure(err) { reject(err); }
    });
  });
}

export function changePassword(oldPassword, newPassword) {
  const user = getCurrentUser();

  if (user === null) { throw new Error("Failed to retrieve current user. Cannot change password"); }

  return new Promise((resolve, reject) => {
    user.changePassword(oldPassword, newPassword, (err, result) => {
      if (err) { reject(err); }
      else { resolve(); }
    });
  });
}

export function confirm(user, confirmationCode) {
  return new Promise((resolve, reject) =>
    user.confirmRegistration(confirmationCode, true, function (err, result) {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    })
  );
}

function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function (err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}

export function getCurrentUser() {
  const userPool = new CognitoUserPool({
    UserPoolId: APPCONFIG.cognito.USER_POOL_ID,
    ClientId: APPCONFIG.cognito.APP_CLIENT_ID
  });
  return userPool.getCurrentUser();
}

export async function getSignedInUser() {
  const currentUser = getCurrentUser();

  try {
    return await getUserAttributes(currentUser);
  } catch (e) {
    console.log(e);
    return null;
  }
}

function getUserAttributes(user) {
  return new Promise((resolve, reject) => {
    user.getSession(function (err, session) {
      if (err) {
        reject(err);
        return;
      }
      user.getUserAttributes((err, result) => {

        if (err) {
          reject(err);
          return;
        }

        let authenticatedUser = result.reduce((obj, item) => {
          obj[item.getName()] = item.getValue();
          return obj;
        }, {});

        resolve(authenticatedUser);

      });
    });
  });
}

let loadingCredentials = null;

async function getAwsCredentials(userToken) {
  if (loadingCredentials) {
    return loadingCredentials;
  }

  const authenticator = `cognito-idp.${APPCONFIG.cognito
    .REGION}.amazonaws.com/${APPCONFIG.cognito.USER_POOL_ID}`;

  AWS.config.update({ region: APPCONFIG.cognito.REGION });

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: APPCONFIG.cognito.IDENTITY_POOL_ID,
    Logins: {
      [authenticator]: userToken
    }
  });

  loadingCredentials = AWS.config.credentials.getPromise();

  await loadingCredentials;

  loadingCredentials = null;
}

export async function invokeApig({
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  body
}) {
  if (!await authUser()) {
    throw new Error("User is not logged in");
  }

  const secretKey = (() => {
    try {
      return AWS.config.credentials.data.Credentials.SecretKey;
    } catch (error) {
      return AWS.config.credentials.secretAccessKey;
    }
  })();

  const signedRequest = sigV4Client
    .newClient({
      accessKey: AWS.config.credentials.accessKeyId,
      secretKey,
      sessionToken: AWS.config.credentials.sessionToken,
      region: APPCONFIG.apiGateway.REGION,
      endpoint: APPCONFIG.apiGateway.URL
    })
    .signRequest({
      method,
      path,
      headers,
      queryParams,
      body
    });

  body = body ? JSON.stringify(body) : body;
  headers = signedRequest.headers;

  const results = await fetch(signedRequest.url, {
    method,
    headers,
    body
  });

  if (results.status !== 200) {
    throw new Error(await results.text());
  }

  return results.json();
}

function getS3(token) {
  return new AWS.S3({
    accessKeyId: token.AccessKeyId,
    secretAccessKey: token.SecretAccessKey,
    sessionToken: token.SessionToken
  });
}

export function downloadFileFromS3(key, token) {
  const s3 = getS3(token);

  const params = {
    Bucket: APPCONFIG.s3.DATALAKE_BUCKET,
    Key: key
  };

  return s3.getObject(params).promise();
}

export function listFilesFromS3(token, prefix, maxItems, bucket) {
  const s3 = getS3(token);
  let params = {
    Bucket: bucket || APPCONFIG.s3.DATALAKE_BUCKET
  };

  if (prefix) { params.Prefix = prefix; }
  if (maxItems) { params.MaxKeys = maxItems; }

  return s3.listObjectsV2(params).promise();
}

export function listS3PublicObjects(params) {
  const s3 = new AWS.S3()
  return s3.listObjectsV2(params).promise();
}

export function listDeviceLogs(awsToken, deviceId, orgId, maxItems) {
  const prefix = `orgs/${orgId}/devices/${deviceId}/logs`;
  return listFilesFromS3(
    awsToken,
    prefix,
    maxItems,
    APPCONFIG.s3.DATALAKE_BUCKET
  );
}

export function uploadToS3(token, key, file) {
  return getS3(token).putObject({
    Bucket: APPCONFIG.s3.DATALAKE_BUCKET,
    Key: key,
    Body: file
  }).promise();
}

/**
 * Returns a S3 signed URL that allows the user to
 * download/view a S3 object
 * @param {{ AccessKeyId: string, SecretAccessKey: string, SessionToken: string }} token
 * @param {string} key - S3 location of the object
 * @param {{}} options - specific options based on the first argument provided to `getSignedUrl`
 */
export function getSignedUrl(token, key, options) {
  return getS3(token).getSignedUrl("getObject", {
    Bucket: APPCONFIG.s3.DATALAKE_BUCKET,
    Key: key,
    ...options
  });
}
/**
 * Returns successfully with metadata from an object without returning the object itself. Useful for checking if an object exists.
 * This function will throw if no object with the bucket and key exists
 * @param {{ AccessKeyId: string, SecretAccessKey: string, SessionToken: string }} token
 * @param {string} key - S3 location of the object
 * @param {{}} options - specific options based on the first argument provided to `getSignedUrl`
 */
export function headObject(token, key, options) {
  return getS3(token).headObject({
    Bucket: APPCONFIG.s3.DATALAKE_BUCKET,
    Key: key,
    ...options,
  }).promise();
}