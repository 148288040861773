import React from "react";
import PropTypes from "prop-types";
import IconCard from "components/Cards/IconCard.jsx";
import { Bar } from "components/Charts";
import Reports from "models/Reports";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import DropDown from "components/CustomDropdown/CustomDropdown";

// Styles
import LooksOne from "material-ui-icons/LooksOne";

export class AppFailureCodes extends React.Component {
  static propTypes = {
    report: PropTypes.instanceOf(Reports)
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.report && this.props.report ||
      prevProps.report && this.props.report && (!prevProps.report.start.isSame(this.props.report.start) || !prevProps.report.end.isSame(this.props.report.end))
    ) {
      this.updateSelected();
    }
  }

  updateSelected = () => {
    this.setState({
      selected: this.props.report.appWithMostFailureCodes()
    });
  }

  handleAppChange = (name) => {
    if (this.props.report) {
      const apps = this.props.report.filterAppsBy(a => a.appName === name);
      this.setState({ selected: apps[0] });
    }
  }

  findAppBy = (id) => {
    const apps = this.props.report.filterAppsBy(a => a.appId === id);
    if (!apps || !apps.length) {
      return null;
    }
    return apps[0];
  }

  render() {
    const { report } = this.props;
    const { selected } = this.state;
    let category;
    let dropDown = null;
    let labels = [];
    let series = null;
    if (report && selected) {
      const { appId } = selected;
      const selectedApp = this.findAppBy(appId);
      if (selectedApp) {
        const codes = report.exitCodesBy("appId");

        // report contains exit codes
        if (Object.keys(codes).length) {
          const appFailureCodes = codes[appId].failureCodes;
          labels = Object.keys(appFailureCodes);
          const dropDownList = Object.keys(codes).map(id => codes[id].appName);
          series = [
            Object.values(appFailureCodes).map(n => ({ value: n, meta: { style: "stroke: #ef5350; stroke-width: 30px;" } }))
          ];
          const appName = selected.appName;
          category = "- " + appName;
          dropDown = (
            <GridContainer justify="flex-end">
              <ItemGrid container xs={2} justify="flex-end">
                <DropDown
                  buttonColor="primary"
                  buttonText={appName}
                  onAction={this.handleAppChange}
                  dropdownList={dropDownList}
                />
              </ItemGrid>
            </GridContainer>
          );
        } else {
          // report exists but there were no failures
          series = [];
        }
      }
    }

    return (
      <IconCard
        iconColor="orange"
        icon={LooksOne}
        title="App Failure Code Breakdown"
        category={category}
        content={(
          <React.Fragment>
            {dropDown}
            <Bar
              noXSeriesRender={() => <h3><small>No report</small></h3>}
              emptyXSeriesRender={() => <h3><small>No failures were reported for this report</small></h3>}
              legendTitle="Legend"
              legend={["Failure"]}
              xLabels={labels}
              xSeries={series}
            />
          </React.Fragment>
        )}
      />
    );
  }
}

export default AppFailureCodes;