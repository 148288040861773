import React from "react";
import PropTypes from "prop-types";

// react table
import ReactTable from "react-table";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";

const style = {
  ...dashboardStyle,
  ...buttonsStyle
}

class DicomDevicesTable extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    let tableData = [];
    if (this.props.data) {
      tableData = this.props.data.map((prop, key) => {
        return ({
            name: (
            <div>
                <div>
                    {prop.name}
                </div>
                <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px"}}>
                    Device Name
                </div>
            </div>
            ),
            aeTitle: (
              <div>
                  <div>
                      {prop.aeTitle}
                  </div>
                  <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px"}}>
                      AE Title
                  </div>
              </div>
              ),
            ipAddress: (
                <div>
                    <div>
                        {prop.ipAddress}
                    </div>
                    <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px"}}>
                        IP Address
                    </div>
                </div>
                ),
            port: (
                <div>
                    <div>
                        {prop.port}
                    </div>
                    <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px"}}>
                        Listening Port
                    </div>
                </div>
                ),
            options: (
            <div style={{ textAlign: "right", paddingRight: "10px"}}>
              <Button onClick={(e) => { e.preventDefault(); this.props.onDelete(prop.nodeId) }}>Delete</Button>
            </div>
          )
        });
      });
    }
    return (
        tableData &&
        <ReactTable
        sortable={false}
        data={tableData}
        noDataText="No Devices!"
        columns={[
            {
            Header: "",
            accessor: "name",
            },
            {
            Header: "",
            accessor: "aeTitle",
            },
            {
            Header: "",
            accessor: "ipAddress",
            },
            {
            Header: "",
            accessor: "port",
            },
            {
            Header: "",
            accessor: "options",
            }
        ]}
        minRows={2}
        defaultPageSize={10}
        showPaginationBottom
        className="-highlight"
        />
    );
  }
}

DicomDevicesTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(DicomDevicesTable);
