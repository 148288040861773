import React from "react"
import PropTypes from "prop-types"
import withStyles from "material-ui/styles/withStyles"
import GridContainer from "components/Grid/GridContainer.jsx"
import ItemGrid from "components/Grid/ItemGrid.jsx"

const style = {
	background: {
		backgroundColor: "rgba(0,0,0,0.4)",
		position: "fixed",
		left: 0,
		top: 0,
		bottom: 0,
		zIndex: 9998,
		width: "100%",
		height: "100%",
	},
	foreground: {
		backgroundColor: "#FFF",
		position: "fixed",
		height: "600px",
		width: "500px",
		borderRadius: "4px",
		left: "50%",
		top: "50%",
		transform: "translate(-50%, -50%)",
		margin: "0",
		maxWidth: "90%",
		maxHeight: "90%",
		zIndex: 9999,
	}
}

const Modal = ({ classes, style, backgroundStyle, children }) => {
	return (
		<GridContainer>
			<ItemGrid xs={12} className={classes.background} style={backgroundStyle}></ItemGrid>
			<ItemGrid xs={12} className={classes.foreground} style={style}>{children}</ItemGrid>
		</GridContainer>
	)
}

Modal.propTypes = {
	classes: PropTypes.shape({
		background: PropTypes.string.isRequired,
		foreground: PropTypes.string.isRequired,
	}).isRequired,
	style: PropTypes.shape({}),
	backgroundStyle: PropTypes.shape({}),
	children: PropTypes.element.isRequired,
}

export default withStyles(style)(Modal)