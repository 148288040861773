import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputAdornment from "material-ui/Input/InputAdornment";

import SweetAlert from "react-bootstrap-sweetalert";

// material-ui-icons
import Email from "material-ui-icons/Email";
import Fingerprint from "material-ui-icons/Fingerprint";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoginCard from "components/Cards/LoginCard.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Danger from "components/Typography/Danger.jsx";

// additional components & styles
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// libs and api
import {
  confirm, getUserByEmail, resendConfirmation
} from "libs/awsLib";
import { verifyEmail, verifyLength } from 'libs/utils';

const style = {
  ...loginPageStyle,
  ...sweetAlertStyle,
}

const Error = ({ error }) => {
  if (!error || !error.length) {
    return null
  }
  return (
    <ItemGrid container justify="space-around">
      <Danger>
        <span data-test="error">{error}</span>
      </Danger>
    </ItemGrid>
  )
}

const errorInvalidEmail = "Invalid email address"

/*
 * Presentational is a pure function with the sole purpose of rendering the UI and necessary callbacks
 * Abstracting the UI from the ConfirmationPage component (below) allows us to perform unit testing much easier. See "__tests__/views/Pages/ConfirmationPage.js"
 */
export const Presentational = ({ classes, code, codeState, updateValue, change, cancel, error, onSubmit, resendConfirmation }) => (
  <LoginCard
    headerColor="blue"
    cardTitle={<h5 data-test="title">Account Confirmation</h5>}
    cardSubtitle={<span data-test="subtitle">Please confirm your account with the verification code sent to your email or <a style={{ cursor: "pointer" }} onClick={resendConfirmation}>send a new code now.</a></span>}
    content={
      <CustomInput
        labelText="Verification Code"
        formControlProps={{
          fullWidth: true
        }}
        error={codeState === "error"}
        success={codeState === "success"}
        inputProps={{
          id: "code",
          value: code,
          onChange: updateValue,
          onBlur: () => change("code", code, 6),
          endAdornment: (
            <InputAdornment position="end">
              <Fingerprint className={classes.inputAdornmentIcon} />
            </InputAdornment>
          )
        }}
      />
    }
    footer={
      <Fragment>
        <Error error={error} />
        <ItemGrid container justify="space-around">
          <Button
            color="dangerNoBackground"
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!code.length || codeState === "error" || !!error.length}
            onClick={onSubmit}
          >
            Confirm
          </Button>
        </ItemGrid>
      </Fragment>
    }
  />
)

export class ConfirmationPage extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(props.location.search)
    this.state = {
      email: urlParams.get("email"),
      emailState: "",
      code: "",
      codeState: "",
      error: "",
      success: false,
      alert: null,
      user: null
    };
  }

  componentDidMount() {
    if (verifyEmail(this.state.email)) {
      const user = getUserByEmail(this.state.email)
      this.setState({ user })
    } else {
      this.setState({ error: errorInvalidEmail })
    }
  }

  cancel = () => this.props.history.push('/pages/login')

  change = (key, value, equalTo) => {
    switch (key) {
      case "email":
        if (verifyEmail(value)) {
          this.setState({ [key + "State"]: "success" });
        } else {
          this.setState({ [key + "State"]: "error" });
        }
        break;
      case "code":
        if (verifyLength(value, equalTo)) {
          this.setState({ [key + "State"]: "success" });
        } else {
          this.setState({ [key + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [key]: value });
  }

  updateValue = (e) => this.setState({ [e.target.id]: e.target.value })

  resendConfirmation = async () => {
    try {
      if (verifyEmail(this.state.email)) {
        await resendConfirmation(this.state.email)
        this.setState({
          alert: <SweetAlert
            type="success"
            style={{ display: "block", marginTop: "-100px" }}
            title="Verification code sent. Check your email"
            onConfirm={() => { }}
            showConfirm={false}
            closeOnClickOutside
            allowEscape
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          />
        })
        setTimeout(() => {
          this.setState({ alert: null })
        }, 3000)
      } else {
        throw new Error(errorInvalidEmail)
      }
    } catch (e) {
      this.setState({ error: e.message || "Error resending verification code" })
    }
  }

  onSubmit = async () => {
    try {
      await confirm(this.state.user, this.state.code)
      this.setState({
        alert: <SweetAlert
          type="success"
          style={{ display: "block", marginTop: "-100px" }}
          title="Confirmation successful!"
          onConfirm={() => { }}
          showConfirm={false}
          closeOnClickOutside
          allowEscape
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      })
      setTimeout(() => {
        this.setState({ alert: null })
        this.props.history.push("/pages/login")
      }, 3000)
    } catch (e) {
      this.setState({ error: e.message })
    }
  }

  render() {
    const { classes } = this.props
    const { code, codeState } = this.state

    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <ItemGrid xs={12} sm={6} md={4}>
              {this.state.alert}
              <Presentational
                updateValue={this.updateValue}
                change={this.change}
                cancel={this.cancel}
                onSubmit={this.onSubmit}
                resendConfirmation={this.resendConfirmation}
                {...this.props}
                {...this.state}
              />
            </ItemGrid>
          </GridContainer>
        </div>
      </div>
    );
  }
}

ConfirmationPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(style)(ConfirmationPage))
