import React from "react";
import PropTypes from "prop-types";
import IconCard from "components/Cards/IconCard.jsx";
import Reports from "models/Reports";
import { Pie } from "components/Charts";

// Styles
import Scanner from "material-ui-icons/Scanner";

export const ScannerUsageContent = ({ report }) => {
  let data;
  let legend;
  if (report) {
    const scanners = report.modalityStatsBy("hostName");
    const IDs = Object.keys(scanners);
    legend = IDs;
    data = IDs.map(modality => ({ value: scanners[modality].successful + scanners[modality].failures }));
  }
  return (
    <Pie
      data={data}
      noDataRender={() => <h3><small>No report</small></h3>}
      emptyDataRender={() => <h3><small>No scanners reported for this date range</small></h3>}
      legend={legend}
      legendTitle="Legend"
    />
  );
};

ScannerUsageContent.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export const ScannerUsage = ({ report }) => (
  <IconCard
    iconColor="orange"
    icon={Scanner}
    title="Scanner Usage"
    content={<ScannerUsageContent report={report} />}
  />
);

ScannerUsage.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export default ScannerUsage;