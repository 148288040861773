import React from "react";
import pt from "prop-types";
import ReactTable from "react-table";
import Cell from "components/Table/ReactTableCell";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import Radio from "components/Radio";
import { CircularProgress } from "material-ui/Progress";
import { APPS } from "constants/Apps";

class AppStep extends React.Component {
  static propTypes = {
    selectedApp: pt.shape({
      appId: pt.string.isRequired,
    }),
    onSelect: pt.func.isRequired,
    apps: pt.arrayOf(pt.shape({
      appId: pt.string.isRequired,
    })),
    loading: pt.bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      page: this.page(props.selectedApp, props.apps)
    };
  }

  page = (selectedApp, apps) => {
    if (!apps || !apps.length || !selectedApp) {
      return 0;
    }
    const i = apps.findIndex(app => app.appId === selectedApp.appId);
    return Math.floor(i / 5);
  }

  formatCell = (key) => (cell) => {
    const { selectedApp, onSelect } = this.props;
    switch (key) {
    case "name":
      return <Cell label="Name" value={this.appName(cell.original)} />;
    case "select":
      return (
        <GridContainer justify="flex-end">
          <Radio onClick={() => onSelect(cell.original)} checked={selectedApp && selectedApp.appId === cell.original.appId} />
        </GridContainer>
      );
    default:
      return null;
    }
  };

  appName = (app) => {
    switch (app.appId) {
    case APPS.airr:
    case APPS.airrMci:
      return "Alzheimer's IntegratedRisk Report";
    case APPS.agrr:
      return "Alzheimer's GeneticRisk Report";
    case APPS.pgr:
      return "Prostate Cancer GeneticRisk Report";
    default:
      return app.appName;
    }
  }

  render() {
    const { apps, loading } = this.props;
    const { page } = this.state;
    if (loading) {
      return (
        <GridContainer justify='center' alignItems="center" style={{ paddingRight: 30, paddingLeft: 30, paddingTop: 30 }}>
          <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />
        </GridContainer>
      );
    }
    if (!apps.length) {
      return (
        <GridContainer style={{ paddingRight: 30, paddingLeft: 30, paddingTop: 30 }}>
          <ItemGrid xs={12} style={{ marginBottom: "30px" }}>
            <p>No applications have been licensed to this organization. Please contact <a href="mailto:support@healthlytix.com?subject=Application%20Licenses">support@healthlytix.com</a> to add application licenses.</p>
          </ItemGrid>
        </GridContainer>
      );
    }
    const noDataText = loading ? "Loading" : "No applications have been licensed to this organization. Please contact support@healthlytix.com to add application licenses.";
    return (
      <GridContainer style={{ paddingRight: 30, paddingLeft: 30, paddingTop: 30 }}>
        <ItemGrid xs={12} style={{ marginBottom: "30px" }}>
          <p>Please select which HealthLytix Application you would like to use to process your patient&apos;s data.</p>
        </ItemGrid>
        <ItemGrid xs={12}>
          <ReactTable
            noDataText={noDataText}
            sortable={false}
            loading={loading}
            page={page}
            onPageChange={page => this.setState({ page })}
            data={apps || []}
            minRows={apps.length > 5 ? 5 : apps.length}
            defaultPageSize={apps.length > 5 ? 5 : apps.length}
            showPagination={apps.length > 5}
            columns={
              [
                { accessor: "name" },
                { accessor: "select" },
              ].map(r => ({ accessor: r.accessor, Cell: this.formatCell(r.accessor), Header: "" }))
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

export default AppStep;