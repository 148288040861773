import React from "react";
import pt from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPatientJobs, deleteJobOnCloud } from "libs/apiLib";
import { APPS, APPSINTEGRATEDSET, APPSGENETICSET } from "constants/Apps";

import ReactTable from "react-table";
import Button from "components/CustomButtons/Button";
import Cell from "components/Table/ReactTableCell";
import HeaderCard from "components/Cards/HeaderCard";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Badge from "components/Badge/Badge";
import Pagination from "components/Pagination/ReactTablePagination";
import Dropdown from "components/CustomDropdown/CustomDropdown";

import withStyles from "material-ui/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/components/reactTableStyle";
import Modal from "components/Modal";
import { CircularProgress } from "material-ui/Progress";

export class Results extends React.Component {
  static propTypes = {
    history: pt.shape({
      push: pt.func.isRequired,
    }).isRequired,
    classes: pt.shape({
      wideHorizontalPadding: pt.string.isRequired,
    }).isRequired,
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    getPatientJobs: pt.func.isRequired,
    id: pt.string.isRequired,
    patient: pt.shape({}),
    orgId: pt.string,
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      results: null,
      paginationKey: null,
      page: 0,
      allPagesFulfilled: false,
      modal: null,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.id) {
      this.getPatientJobs();
    }
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  fullRefresh = () => {
    this.setState({
      results: null,
      paginationKey: null,
      page: 0,
      allPagesFulfilled: false,
    }, () => {
      this.getPatientJobs();
    });
  }

  getPatientJobs = async () => {
    const { id, getPatientJobs, signalError, clearError } = this.props;
    const { paginationKey } = this.state;
    this.setState({ loading: true });
    try {
      const response = await getPatientJobs(id, paginationKey);
      if (response.success && response.result.Items) {
        if (response.result.LastEvaluatedKey) {
          this.updatePaginationKey(response.result.LastEvaluatedKey);
        } else {
          this.setState({ allPagesFulfilled: true });
        }

        if (response.result.Items.length && this.state.results && this.state.page + 1 === Math.floor(this.state.results.length / 10)) {
          this.setState(prevState => ({ ...prevState, page: prevState.page + 1 }));
        }

        this.setState(prevState => {
          return ({
            ...prevState,
            results: prevState.results ? [...prevState.results, ...response.result.Items] : response.result.Items
          });
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      signalError(`Error getting results for patient "${id}". Please try again later.`);
      setTimeout(clearError, 5000);
    }
    this.setState({ loading: false });
  }

  updatePaginationKey = (paginationKey) => {
    if (!paginationKey || !paginationKey.eventId || !paginationKey.patientId || !paginationKey.received) {
      return;
    }
    this.setState({
      paginationKey
    });
  }

  jobStatusColor = (status) => {
    switch (status) {
    case "Finished Processing":
      return "success";
    case "Failed":
      return "danger";
    case "Queued":
    case "Receiving Dicoms":
    case "Waiting for Dicoms":
      return "primary";
    case "Pending":
    case "Waiting":
      return "rose";
    default:
      return "gray";
    }
  }

  handleDropdownSelection = (action, result) => {
    const { history: { push }, patient } = this.props;
    let state = { result, patient };
    switch (action) {
    case "View":
      return push({
        pathname: `/patients/${this.props.id}/${result.appId}/${result.eventId}`,
        state
      });
    case "Delete":
      this.setState({ modal: { type: "deleteJob", state: result.eventId } });
      return;
    default:
      return;
    }
  }

  dropDownList = (result) => {
    const list = [];
    if (!["Running", "Queued"].includes(result.jobStatus)) {
      list.push("View");
    }
    list.push("Delete");
    return list;
  }

  containsPDFVersion = (appId) => {
    switch (appId) {
    case APPS.airr:
    case APPS.airrMci:
      return true;
    default:
      return false;
    }
  }

  appName = (app) => {
    switch (app.appId) {
    case APPS.airr:
    case APPS.airrMci:
      return "Alzheimer's Integrated Risk Report";
    default:
      return app.appName;
    }
  }

  formatCell = (key) => (cell) => {
    switch (key) {
    case "received":
      return <Cell label="Submitted" value={moment(cell.value).format("MMMM DD YYYY, h:mm:ss a")} />;
    case "appName":
      return <Cell label="Application" value={this.appName(cell.original)} />;
    case "type":
    {
      let value = cell.value || cell.original.Modality || "";
      if (APPSINTEGRATEDSET.has(cell.original.appId)) {
        value = "Integrated";
      } else if (APPSGENETICSET.has(cell.original.appId)) {
        value = "Genetics";
      }
      return <Cell label="Type" value={value} />;
    }
    case "jobStatus":
      return <Cell style={{ marginTop: 0 }} label="Status" value={<Badge color={this.jobStatusColor(cell.value)}>{cell.value}</Badge>} />;
    case "more":
      return (
        <Dropdown
          buttonColor="primary"
          buttonText="Options"
          onAction={(action) => this.handleDropdownSelection(action, cell.original)}
          dropdownList={this.dropDownList(cell.original)}
        />
      );
    default:
      return cell.value;
    }
  }

  renderModal = () => {
    const { modal } = this.state;
    if (!modal) {
      return null;
    }
    switch (modal.type) {
    case "deleteJob":
      return this.renderDeleteJobModal();
    default:
      return null;
    }
  }

  renderDeleteJobModal = () => {
    const { loading } = this.state;
    let content;
    if (loading) {
      content = (
        <ItemGrid container xs={12} justify="center" alignItems="center">
          <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />;
        </ItemGrid>
      );
    }
    return (
      <Modal
        style={{
          height: 400,
          width: 500,
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <GridContainer style={{
          height: "100%",
          paddingLeft: "30px",
          paddingRight: "30px"
        }}>
          <ItemGrid xs={12} style={{ textAlign: "center", alignSelf: "flex-start" }}><h2>Delete Result?</h2></ItemGrid>
          {content}
          <ItemGrid xs={12} container justify="center" style={{ alignSelf: "flex-end", marginBottom: 15 }}>
            <Button style={{ marginRight: 15 }} onClick={() => this.setState({ modal: null })} disabled={loading}>Cancel</Button>
            <Button color="success" onClick={this.handleDeleteJob} disabled={loading}>Delete</Button>
          </ItemGrid>
        </GridContainer>
      </Modal>
    );
  }

  handleDeleteJob = async () => {
    const { deleteJob, signalError, clearError } = this.props;
    const { modal: { state } } = this.state;
    this.setState({ loading: true });
    try {
      const result = await deleteJob(state);
      if (!result.success) {
        throw new Error();
      }
      window.location.reload();
    } catch (error) {
      signalError("Error deleting patient result. Please try again later.");
      setTimeout(clearError, 5000);
      this.setState({ loading: false, modal: null });
    }
  }

  render() {
    const { page, loading, allPagesFulfilled } = this.state;
    return (
      <HeaderCard
        cardTitle="HealthLytix Reports"
        cardSubtitle="This section shows all the HealthLytix Apps processed results for this patient"
        headerColor="green"
        content={
          <GridContainer className={this.props.classes.wideHorizontalPadding}>
            {this.renderModal()}
            <ItemGrid xs={12}>
              <Button right onClick={this.fullRefresh}>Refresh</Button>
            </ItemGrid>
            <ItemGrid xs={12}>
              <ReactTable
                sortable={false}
                data={this.state.results || []}
                noDataText="No results"
                defaultPageSize={5}
                columns={[
                  { accessor: "received" },
                  { accessor: "appName" },
                  { accessor: "type" },
                  { accessor: "jobStatus" },
                  { accessor: "more", maxWidth: 150 },
                ].map(c => ({ Header: "", Cell: this.formatCell(c.accessor), accessor: c.accessor, maxWidth: c.maxWidth }))
                }
                PaginationComponent={Pagination}
                minRows={5}
                canGetMorePages
                onGetMore={this.getPatientJobs}
                onPageChange={page => this.setState({ page })}
                refreshing={loading}
                page={page}
                allPagesFulfilled={allPagesFulfilled}
              />
            </ItemGrid>
          </GridContainer>
        }
      />
    );
  }
}

export default connect(undefined, () => ({
  getPatientJobs: (id, key) => getPatientJobs(id, key),
  deleteJob: (eventId) => deleteJobOnCloud(eventId),
}))(withRouter(withStyles(styles)(Results)));