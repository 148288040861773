import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { searchParamsSelector } from "selectors";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// react table
import ReactTable from "react-table";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Bannercard from "components/Cards/BannerCard.jsx";
import Card from "components/Cards/RegularCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import LoadingModal from "components/LoadingModal/LoadingModal.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import NewApiToken from "./components/NewApiToken";

import { getOrgAPItokens, generateToken, deleteApiToken } from "libs/apiLib";

const style = {
  ...dashboardStyle,
  ...buttonsStyle,
  ...sweetAlertStyle
};

class Developers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: null,
      apiTokens: null
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showError = this.showError.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showLoading = this.showLoading.bind(this);
    this.retrieveApiTokens = this.retrieveApiTokens.bind(this);
    this.createApiToken = this.createApiToken.bind(this);
    this.handleCreateToken = this.handleCreateToken.bind(this);
    this.handleDeleteToken = this.handleDeleteToken.bind(this);
    this.deleteToken = this.deleteToken.bind(this);
  }

  async componentDidMount() {
    this.showLoading();

    try {
      await this.retrieveApiTokens();
    } catch (e) {
      console.error(e);
    }

    this.hideModal();
  }

  showLoading() {
    this.setState({
      modal: <LoadingModal />
    });
  }

  handleRefresh = async () => {
    this.showLoading();
    await this.retrieveApiTokens();
    this.hideModal();
  }

  hideModal() {
    this.setState({
      modal: null
    });
  }

  handleCreateToken = async () => {
    this.setState({
      modal: (
        <NewApiToken
          onCreate={(label) => this.createApiToken(label)}
          onCancel={() => this.hideModal()}
        />
      )
    });
  }

  async createApiToken(identifier) {
    this.showLoading();
    try {
      const { searchParams: { orgId } } = this.props;
      const result = await generateToken(identifier, orgId);
      if(!result || !result.apiToken) {
        throw "failed to create token";
      }
      
      await this.retrieveApiTokens();

      this.setState({
        modal: (
          <NewApiToken
            apiId={result.apiToken.apiId}
            apiSecret={result.apiToken.apiSecret}
            onCancel={() => this.hideModal()}
          />
        )
      });
    } catch (e) {
      console.error(e);
      this.hideModal();
    }
  }

  async retrieveApiTokens() {
    try {
      const { searchParams: { orgId } } = this.props;
      const results = await getOrgAPItokens(orgId);
      if (!results || !results.tokens) {
        throw new Error("Failed to retriev api tokens");
      }
      this.setState({ apiTokens: results.tokens });
    } catch (e) {
      console.error(e);
    }
  }

  handleDeleteToken = async (apiId) => {
    this.setState({
      modal: (<SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => this.hideModal()}
        onCancel={() => this.hideModal()}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        showConfirm={false}
      >
        <div>
                  Are you sure you want to delete this api token? If this is an edge device token, it might not allow the edge device to work properly.
        </div>
        <div style={{ marginTop: "40px" }}>
          <Button color="danger"
            style={{ marginRight: "10px" }}
            onClick={() => this.hideModal()}
          >Cancel</Button>
          <Button color="success"
            onClick={() => this.deleteToken(apiId)}
          >Delete</Button>
        </div>
      </SweetAlert>
      )
    });
  }

  deleteToken = async (apiId) => {
    this.showLoading();

    try {
      await deleteApiToken(apiId);
      await this.retrieveApiTokens();
    } catch(e) {
      console.error(e);
    }

    this.hideModal();
  }

  showSuccess(msg) {
    this.setState({
      modal: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Done!"
          onConfirm={() => this.hideModal()}
          onCancel={() => this.hideModal()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div>
            {msg}
          </div>
          <div style={{ marginTop: "40px" }}>
            <Button color="success" onClick={() => this.hideModal()}>Done</Button>
          </div>
        </SweetAlert>
      )
    });
  }

  showError(err) {
    this.setState({
      modal: (
        <SweetAlert
          type="error"
          style={{ display: "block", marginTop: "-100px" }}
          title="Ohh No!"
          onConfirm={() => this.hideModal()}
          onCancel={() => this.hideModal()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div>
            {err}
          </div>
          <div style={{ marginTop: "40px" }}>
            <Button onClick={() => this.hideModal()}>Close</Button>
          </div>
        </SweetAlert>
      )
    });
  }

  render() {
    let tableData = [];

    if (this.state.apiTokens) {
      tableData = this.state.apiTokens.map((prop) => {

        return ({
          id: (
            <div>
              <div>
                {prop.apiId}
              </div>
              <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                API ID
              </div>
            </div>
          ),
          label: (
            <div>
              <div>
                {prop.identifier ? prop.identifier : "No Label"}
              </div>
              <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                Label
              </div>
            </div>
          ),
          type: (
            <div>
              <div>
                {prop.isEdgeToken ? 
                  (<Button size="xs" color="success" round>
                    Edge Device
                  </Button>) : (
                    <Button size="xs" round>
                    API Token
                    </Button>
                  )}
              </div>
              <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                Token Type
              </div>
            </div>
          ),
          lastUsed: (
            <div>
              <div>
                {prop.lastUsed ? `${moment.duration(moment().diff(moment(prop.lastUsed))).humanize()} ago` : "Never" }
              </div>
              <div style={{ fontSize: "x-small", color: "gray", marginTop: "-5px" }}>
                Last Used
              </div>
            </div>
          ),
          options: (
            <div style={{ textAlign: "right", paddingRight: "10px" }}>
              <Button color="danger" onClick={(e) => {
                e.preventDefault();
                this.handleDeleteToken(prop.apiId);
              }}>Delete</Button>
            </div>
          )
        });
      });
    }

    return (
      <div>
        <Bannercard
          title="API Tokens"
          subtitle="Manage all tokens from your organization"
          button={{
            href: "https://docs.healthlytix.io/developers",
            text: "View Documentation"
          }}
        />
        <GridContainer>
          {this.state.modal}
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <Card
              content={
                tableData &&
                <div>
                  <ItemGrid xs={12}>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        onClick={this.handleRefresh}
                        style={{ marginRight: "10px" }}
                      >Refresh</Button>
                      <Button
                        onClick={this.handleCreateToken}
                        color="success"
                      >Create API Token</Button>
                    </div>
                  </ItemGrid>
                  <ReactTable
                    sortable={false}
                    data={tableData}
                    noDataText="No Tokens!"
                    columns={[
                      {
                        Header: "",
                        accessor: "id"
                      },
                      {
                        Header: "",
                        accessor: "label"
                      },
                      {
                        Header: "",
                        accessor: "type"
                      },
                      {
                        Header: "",
                        accessor: "lastUsed"
                      },
                      {
                        Header: "",
                        accessor: "options"
                      }
                    ]}
                    minRows={2}
                    defaultPageSize={10}
                    showPaginationBottom
                    className="-highlight"
                  />
                </div>}
            />
          </ItemGrid>
        </GridContainer>
      </div>
    );
  }
}

Developers.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
  user: state.user.user,
  org: state.user.org,
  searchParams: searchParamsSelector(state, props)
});

export default connect(mapStateToProps)(withRouter(withStyles(style)(Developers)));
