import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputAdornment from "material-ui/Input/InputAdornment";
import { CircularProgress } from 'material-ui/Progress';

// material-ui-icons
import Email from "material-ui-icons/Email";
import LockOutline from "material-ui-icons/LockOutline";
import Fingerprint from "material-ui-icons/Fingerprint";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoginCard from "components/Cards/LoginCard.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// additional components & styles
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

// libs and api
import {
  forgotPassword, confirmForgotPasswordChange
} from "libs/awsLib";
import { validatePassword, verifyLength, verifyEmail } from 'libs/utils';

const style = {
  ...loginPageStyle,
}

const LoadingCard = () => (
  <LoginCard
    headerColor="blue"
    cardTitle={<h5 data-test="title">Forgot Password</h5>}
    cardSubtitle={<span data-test="subtitle">Loading</span>}
    content={
      <ItemGrid container justify="center">
        <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />
      </ItemGrid>
    }
  />
)

const ErrorCard = ({ error }) => (
  <LoginCard
    headerColor="blue"
    cardTitle={<h5 data-test="title">Forgot Password</h5>}
    cardSubtitle={<span data-test="subtitle">Ooops, something went wrong.</span>}
    content={error && <ItemGrid container justify="center"><p data-test="error">{error}</p></ItemGrid>}
  />
)

export class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.email || "",
      emailState: "",
      code: "",
      codeState: "",
      password: "",
      passwordState: "",
      confirmPassword: "",
      confirmPasswordState: "",
      step: 1,
      error: "",
    };
  }

  changeStep = (n) => this.setState(prevState => ({ step: prevState.step + n }))

  cancel = () => this.props.history.push('/pages/login')

  change(key, value, equalTo) {
    switch (key) {
      case "email":
        if (verifyEmail(value)) {
          this.setState({ [key + "State"]: "success" });
        } else {
          this.setState({ [key + "State"]: "error" });
        }
        break;
      case "code":
        if (verifyLength(value, equalTo)) {
          this.setState({ [key + "State"]: "success" });
        } else {
          this.setState({ [key + "State"]: "error" });
        }
        break;
      case "confirmPassword":
        if (value.length && !validatePassword(value) && this.state[key] === this.state.password) {
          this.setState({ [key + "State"]: "success" });
        } else {
          this.setState({ [key + "State"]: "error" });
        }
        break;
      case "password":
        if (value.length && !validatePassword(value)) {
          this.setState({ [key + "State"]: "success" });
        } else {
          this.setState({ [key + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [key]: value });
  }

  updateValue = (e) => this.setState({ [e.target.id]: e.target.value })

  step1 = () => {
    const { classes } = this.props
    const { email, emailState } = this.state
    return (
      <LoginCard
        headerColor="blue"
        cardTitle={<h5 data-test="title">Forgot Password</h5>}
        cardSubtitle={<span data-test="subtitle">Enter the email associated with your account</span>}
        content={
          <CustomInput
            labelText="Email"
            formControlProps={{
              fullWidth: true
            }}
            error={emailState === "error"}
            success={emailState === "success"}
            inputProps={{
              id: "email",
              value: email,
              onChange: this.updateValue,
              onBlur: () => this.change("email", email),
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        }
        footer={
          <ItemGrid container justify="space-around">
            <Button
              color="dangerNoBackground"
              onClick={this.cancel}
            >
              Cancel
              </Button>
            <Button
              color="primary"
              disabled={!email.length || emailState === "error"}
              onClick={() => this.changeStep(1)}
            >
              Submit
              </Button>
          </ItemGrid>
        }
      />
    )
  }

  step2 = () => {
    (async () => {
      try {
        const user = await forgotPassword(this.state.email)
        this.setState(prevState => ({ step: prevState.step + 1, user }))
      } catch (e) {
        this.setState({ error: e.message })
      }
    })()
    return <LoadingCard />
  }

  step3 = () => {
    const { classes } = this.props
    const { code, codeState } = this.state
    return (
      <LoginCard
        headerColor="blue"
        cardTitle={<h5 data-test="title">Forgot Password</h5>}
        cardSubtitle={<span data-test="subtitle">Enter the verification code sent to your email</span>}
        content={
          <CustomInput
            labelText="Verification Code"
            formControlProps={{
              fullWidth: true
            }}
            success={codeState === "success"}
            error={codeState === "error"}
            inputProps={{
              id: "code",
              value: code,
              onChange: this.updateValue,
              onBlur: () => this.change("code", code, 4),
              endAdornment: (
                <InputAdornment position="end">
                  <Fingerprint className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        }
        footer={
          <ItemGrid container justify="space-around">
            <Button
              color="dangerNoBackground"
              onClick={this.cancel}
            >
              Cancel
              </Button>
            <Button
              color="primary"
              disabled={!code.length || codeState === "error"}
              onClick={() => this.changeStep(1)}
            >
              Submit
              </Button>
          </ItemGrid>
        }
      />
    )
  }

  step4 = () => {
    const { classes } = this.props
    const { password, passwordState, confirmPassword, confirmPasswordState } = this.state
    return (
      <LoginCard
        headerColor="blue"
        cardTitle={<h5 data-test="title">Forgot Password</h5>}
        cardSubtitle={<span data-test="subtitle">Enter a new password that has a minimum length of 8 and contains at least one uppercase and lowercase letter, and a number.</span>}
        content={
          <Fragment>
            <CustomInput
              labelText="Password"
              formControlProps={{
                fullWidth: true
              }}
              success={passwordState === "success"}
              error={passwordState === "error"}
              inputProps={{
                type: "password",
                id: "password",
                value: password,
                onChange: this.updateValue,
                onBlur: () => this.change("password", password),
                endAdornment: (
                  <InputAdornment position="end">
                    <LockOutline
                      className={classes.inputAdornmentIcon}
                    />
                  </InputAdornment>
                )
              }}
            />
            <CustomInput
              labelText="Confirm Password"
              formControlProps={{
                fullWidth: true
              }}
              success={confirmPasswordState === "success"}
              error={confirmPasswordState === "error"}
              inputProps={{
                type: "password",
                id: "confirmPassword",
                value: confirmPassword,
                onChange: this.updateValue,
                onBlur: () => this.change("confirmPassword", confirmPassword),
                endAdornment: (
                  <InputAdornment position="end">
                    <LockOutline
                      className={classes.inputAdornmentIcon}
                    />
                  </InputAdornment>
                )
              }}
            />
          </Fragment>
        }
        footer={
          <ItemGrid container justify="space-around">
            <Button
              color="dangerNoBackground"
              onClick={this.cancel}
            >
              Cancel
              </Button>
            <Button
              color="primary"
              disabled={confirmPasswordState === "error" || !password.length}
              onClick={() => this.changeStep(1)}
            >
              Save
              </Button>
          </ItemGrid>
        }
      />
    )
  }

  step5 = () => {
    (async () => {
      try {
        await confirmForgotPasswordChange(
          this.state.code,
          this.state.user,
          this.state.password
        );
        this.setState(prevState => ({ step: prevState.step + 1 }))
      } catch (e) {
        this.setState({ error: e.message })
      }
    })()
    return <LoadingCard />
  }

  step6 = () => {
    return (
      <LoginCard
        headerColor="blue"
        cardTitle={<h5 data-test="title">Forgot Password</h5>}
        cardSubtitle={<span data-test="subtitle">Password reset complete!</span>}
        footer={
          <ItemGrid container justify="center">
            <Button
              color="primary"
              onClick={this.cancel}
            >
              Log In
              </Button>
          </ItemGrid>
        }
      />
    )
  }

  render() {
    const { classes } = this.props
    const { error } = this.state
    let card
    if (error.length) {
      card = <ErrorCard error={error} />
    } else {
      card = this[`step${this.state.step}`]()
    }

    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <ItemGrid xs={12} sm={6} md={4}>
              {card}
            </ItemGrid>
          </GridContainer>
        </div>
      </div>
    );
  }
}

ForgotPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(style)(ForgotPasswordPage))
