import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

// steps
import Step1 from "./RegisterSteps/Step1.jsx";
import Step2 from "./RegisterSteps/Step2.jsx";

// styles
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <ItemGrid xs={12} sm={12} md={10}>
            <Wizard
              color={"primary"}
              validate
              steps={[
                { stepName: "About Me", stepComponent: Step1, stepId: "about" },
                { stepName: "Organization", stepComponent: Step2, stepId: "account" }
              ]}
              title="Create Your Account"
              subtitle="This information will let us know more about you."
            />
          </ItemGrid>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(RegisterPage);
