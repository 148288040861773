import {
	STORE_AUDITS, PAGE_CHANGE_AUDITS, STORE_ORGS_AUDITS, PAGE_CHANGE_ORGS_AUDITS
} from "actions/ActionTypes"

export const initialState = {
	page: 0,
	total: 0,
	key: null,
	data: null
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case STORE_AUDITS:
	{
		const orgID = action.orgID || action.audits[0].orgId
		if (state[orgID]) {
			// append audits to array of data
			return {
				...state,
				[orgID]: {
					...state[orgID],
					total: state[orgID].total + action.count,
					key: [...state[orgID].key, action.key],
					data: [
						...state[orgID].data,
						...action.audits
					]
				}
			}
		} else {
			// first set of audits for this org
			return {
				...state,
				[orgID]: {
					total: action.count,
					page: 0,
					key: ["", action.key],
					data: action.audits
				}
			}
		}
	}
	case STORE_ORGS_AUDITS:
		if (!state.data) {
			// first set of audits
			return {
				...state,
				total: action.count,
				page: 0,
				key: [action.key],
				data: action.audits
			}
		}
		return {
			...state,
			total: state.total + action.count,
			key: action.key ? [...state.key, action.key] : state.key,
			data: [
				...state.data,
				...action.audits
			]
		}
	case PAGE_CHANGE_AUDITS:
	{
		if (!action.orgID) { return { ...state } }

		const context = state[action.orgID]

		if (!context || !context.data) {
			return { ...state }
		}

		if (action.page < 0 || (Math.ceil(context.data.length / 10) < action.page)) {
			return { ...state }
		}

		return { ...state, [action.orgID]: { ...context, page: action.page } }
	}
	case PAGE_CHANGE_ORGS_AUDITS:
		if (action.page < 0 || (Math.ceil(state.data.length / 10) < action.page)) {
			return { ...state }
		}

		return { ...state, page: action.page }
	default:
		return state
	}
}

export default reducer
