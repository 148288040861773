
/**
 * Theme Action Types
 */
export const TOGGLE_BOXED_LAYOUT = "TOGGLE_BOXED_LAYOUT";
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSED_NAV";
export const TOGGLE_NAV_BEHIND = "TOGGLE_NAV_BEHIND";
export const TOGGLE_FIXED_HEADER = "TOGGLE_FIXED_HEADER";
export const CHANGE_SIDEBAR_WIDTH = "CHANGE_SIDEBAR_WIDTH";
export const CHANGE_COLOR_OPTION = "CHANGE_COLOR_OPTION";
export const CHANGE_THEME = "CHANGE_THEME";

/**
 * User Action Types
 */
export const USER_AUTHENTICATION = "USER_AUTHENTICATION";
export const USER_AUTHENTICATING = "USER_AUTHENTICATING";
export const LOGOUT = "LOGOUT";
export const STORE_ORG = "STORE_ORG";
export const WEB_PREVIEW_TOKEN = "WEB_PREVIEW_TOKEN";
export const STORE_USER = "STORE_USER";

/*
 * Case Action Types
*/
export const STORE_CASES = "STORE_CASES";
export const REFRESH_CASES = "REFRESH_CASES";
export const PAGE_CHANGE_CASES = "PAGE_CHANGE_CASES";

export const STORE_CONSUMER_ORG_CASES = "STORE_CONSUMER_ORG_CASES";
export const REFRESH_CONSUMER_ORG_CASES = "REFRESH_CONSUMER_ORG_CASES";
export const PAGE_CHANGE_CONSUMER_ORG_CASES = "PAGE_CHANGE_CONSUMER_ORG_CASES";

/*
 * Audit Action Types
*/
export const STORE_AUDITS = "STORE_AUDITS";
export const STORE_ORGS_AUDITS = "STORE_ORGS_AUDITS";
export const PAGE_CHANGE_AUDITS = "PAGE_CHANGE_AUDITS";
export const PAGE_CHANGE_ORGS_AUDITS = "PAGE_CHANGE_ORGS_AUDITS";

/*
 * Report Action Types
*/
export const STORE_ORG_REPORT = "STORE_ORG_REPORT";
export const STORE_ORGS_REPORT = "STORE_ORGS_REPORT";
export const STORE_GLOBAL_REPORT = "STORE_GLOBAL_REPORT";
export const STORE_SAMPLE_KITS_REPORT = "STORE_SAMPLE_KITS_REPORT";
export const STORE_GLOBAL_SAMPLE_KITS_REPORT = "STORE_GLOBAL_SAMPLE_KITS_REPORT";
export const STORE_ORG_PATIENTS_REPORT = "STORE_ORG_PATIENTS_REPORT";
export const STORE_TOTAL_ORGS = "STORE_TOTAL_ORGS";
export const STORE_TOTAL_ORGS_WITH_DEVICES = "STORE_TOTAL_ORGS_WITH_DEVICES";

/*
 * Notification Action Types
*/
export const STORE_NOTIFICATION_ERROR = "STORE_NOTIFICATION_ERROR";
export const REMOVE_NOTIFICATION_ERROR = "REMOVE_NOTIFICATION_ERROR";
export const STORE_NOTIFICATION_INFO = "STORE_NOTIFICATION_INFO";
export const REMOVE_NOTIFICATION_INFO = "REMOVE_NOTIFICATION_INFO";

/*
 * Patient Action Types
*/
export const STORE_PATIENTS = "STORE_PATIENTS";
export const STORE_ORG_PATIENTS = "STORE_ORG_PATIENTS";
export const PAGE_CHANGE_PATIENTS = "PAGE_CHANGE_PATIENTS";
export const PAGE_CHANGE_ORG_PATIENTS = "PAGE_CHANGE_ORG_PATIENTS";
export const REMOVE_PATIENT = "REMOVE_PATIENT";
export const REMOVE_ORG_PATIENT = "REMOVE_ORG_PATIENT";

export const REPLACE_SEARCHED_JOBS = "REPLACE_SEARCHED_JOBS";
export const UPDATE_JOB_SEARCH_FILTERS = "UPDATE_JOB_SEARCH_FILTERS";

/**
 * Orgs Action Types
 */
export const ASSUME_ORG = "ASSUME_ORG";
export const SURRENDER_ORG = "SURRENDER_ORG";

/**
 * Datalake Action Types
 */
export const UPDATE_DATALAKE_ACCESS_TOKEN = "UPDATE_DATALAKE_ACCESS_TOKEN";


/**
 * Version Types
 */
export const UPDATE_EDGE_VERSIONS = "UPDATE_EDGE_VERSIONS";