import React from "react";
import pt from "prop-types";
import moment from "moment";

import { getAppBuilds } from "libs/apiLib";
import { WithNotifications } from "components/HigherOrder";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui components
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import withStyles from "material-ui/styles/withStyles";
import InputLabel from "material-ui/Input/InputLabel";
import FormControl from "material-ui/Form/FormControl";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import { CircularProgress } from "material-ui/Progress";

// core 
import Button from "components/CustomButtons/Button.jsx";

// styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyleModal.jsx";

const style = {
  ...sweetAlertStyle,
  ...customSelectStyle
};

class InstallApp extends React.Component {
  static propTypes = {
    signalError: pt.func.isRequired,
    clearError: pt.func.isRequired,
    onCancel: pt.func.isRequired,
    onInstall: pt.func.isRequired,
    app: pt.shape({}).isRequired,
    classes: pt.shape({}).isRequired,
    user: pt.shape({}).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      installVersion: null,
      installType: null,
      appBuildsByTag: null,
      prodAppBuilds: null,
      rcAppBuilds: null,
      devAppBuild: null,
      hotfixAppBuilds: null,
      loading: false
    };
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleInstallType = this.handleInstallType.bind(this);
    this.handleVersionSelection = this.handleVersionSelection.bind(this);
  }

  componentDidMount() {
    this.getAppBuilds();
  }

  getAppBuilds = async () => {
    const app = this.props;
    if (!app) {
      return;
    }
    try {
      this.setState({ loading: true });
      const appBuildResults = await getAppBuilds(app.app.appId);
      if (appBuildResults.result) {
        this.setState({
          prodAppBuilds: this.filterProdAppBuilds(appBuildResults.result),
          rcAppBuilds: this.filterRCAppBuilds(appBuildResults.result),
          devAppBuild: this.filterDevAppBuild(appBuildResults.result),
          hotfixAppBuilds: this.filterHotfixAppBuilds(appBuildResults.result)
        });
        this.updateAppBuilds(appBuildResults.result);
      }
    } catch (error) {
      this.props.signalError("Error getting application builds. Please try again later.");
      setTimeout(this.props.clearError, 5000);
    }
    this.setState({ loading: false });
  }

  updateAppBuilds = (builds) => {
    const prodBuilds = this.filterProdAppBuilds(builds);
    const rcBuilds = this.filterRCAppBuilds(builds);
    const devBuild = this.filterDevAppBuild(builds);
    const hotfixBuilds = this.filterHotfixAppBuilds(builds);
    const appBuildsByTag = {};
    if (devBuild) {
      appBuildsByTag["dev"] = devBuild;
    }
    if (prodBuilds.length) {
      prodBuilds.forEach(build => {
        appBuildsByTag[build.tag] = build;
      });
    }
    if (rcBuilds.length) {
      rcBuilds.forEach(build => {
        appBuildsByTag[build.tag] = build;
      });
    }
    if (hotfixBuilds.length) {
      hotfixBuilds.forEach(build => {
        appBuildsByTag[build.tag] = build;
      });
    }
    this.setState({ appBuildsByTag });
  }

    handleConfirm = () => {
      if (this.validateForm()) {
        this.props.onInstall({
          app: this.props.app,
          installType: this.state.installType,
          installVersion: this.state.installVersion
        });
      }
    }

    handleCancel = () => {
      this.props.onCancel();
    }

    handleInstallType = (event) => {
      this.setState({
        installType: event.target.value
      });
    }

    handleVersionSelection = (event) => {
      this.setState(prevState => ({
        installVersion: prevState.appBuildsByTag[event.target.value]
      }));
    }

    validateForm() {
      return (
        this.state.installType &&
            this.state.installVersion
      );
    }

  /**
   * Returns true if `t` argument equals 'dev'
   * @param  {string} t
   * @returns boolean
   */
  isDevTag = (t) => t && t === "dev"

  /**
   * Returns true if `t` argument contains a valid semantic version (ie, x.y.z)
   * @param  {string} t
   * @returns boolean
   */
  isProdTag = (t) => t && Boolean(t.match(/^([0-9]+)\.([0-9]+)\.([0-9]+)/)) && !t.match(/[a-zA-Z]+/)

  /**
   * Returns true if `t` argument contains the substring 'hotfix'
   * @param  {string} t
   * @returns boolean
   */
  isHotfixTag = (t) => t && t.includes("hotfix")

  /**
   * Returns true if `t` argument contains the substring 'rc', 'release', or 'staging'.
   * HealthLytix convention should tag release candidate builds with a 'rc' suffix (ie, 1.0.0-rc)
   * @param  {string} t
   * @returns boolean
   */
  isRCTag = (t) => t && (Boolean(t.match(/^([0-9]+)\.([0-9]+)\.([0-9]+)-rc(([0-9]+)?)$/)) || ["release", "staging"].some(substring => t.includes(substring)))

  /**
   * Parses the number from a semantic version string
   */
  parseNumbersFromSemVer = (semver) => Number(semver.replace(/[^0-9]/g, ""));

  /**
   * Sort function to reverse
   */
  reverseSort = (a, b) => {
    const left = this.parseNumbersFromSemVer(a.tag);
    const right = this.parseNumbersFromSemVer(b.tag);
    if (left < right) {
      return 1;
    } else if (left > right) {
      return -1;
    }
    return 0;
  }

  filterProdAppBuilds = (appBuilds) => appBuilds.filter(build => this.isProdTag(build.tag)).sort(this.reverseSort);
  filterHotfixAppBuilds = (appBuilds) => appBuilds.filter(build => this.isHotfixTag(build.tag)).sort(this.reverseSort);
  filterRCAppBuilds = (appBuilds) => appBuilds.filter(build => this.isRCTag(build.tag)).sort(this.reverseSort);
  filterDevAppBuild = (appBuilds) => appBuilds.find(build => this.isDevTag(build.tag));

  userCanInstallRC = (user) => user.isResearch || user.appAdmin || this.userIsHLXSupport(user);
  userIsHLXSupport = (user) => user.superAdmin || user.supportEngineer;

  render() {
    const { loading, prodAppBuilds, rcAppBuilds, devAppBuild, hotfixAppBuilds } = this.state;
    const { classes, app, user } = this.props;
    const userIsHLXSupport = this.userIsHLXSupport(user);
    if (loading || (!prodAppBuilds && !rcAppBuilds && !devAppBuild && !hotfixAppBuilds)) {
      return (
        <SweetAlert
          style={{ display: "block" }}
          title="Install"
          onConfirm={() => this.handleConfirm()}
          onCancel={() => this.handleCancel()}
          showConfirm={false}
          confirmBtnCssClass={
            classes.button + " " + classes.success
          }
          cancelBtnCssClass={
            classes.button + " " + classes.danger
          }
        >
          <GridContainer style={{marginTop: 40}} direction="column" justify="center" alignItems="center">
            <h4>{app.name}</h4>
            <CircularProgress style={{ color: "#00ACEF" }} size={80} thickness={2} />
            <ItemGrid flex direction="row" justify="center">
              <Button
                color="danger"
                style={{ marginRight: "10px" }}
                onClick={this.handleCancel}
              >Cancel</Button>
              <Button
                color="success"
                disabled
              >Install</Button>
            </ItemGrid>
          </GridContainer>
        </SweetAlert>
      );
    }
    return (
      app && user &&
            <SweetAlert
              style={{ display: "block" }}
              title="Install"
              onConfirm={() => this.handleConfirm()}
              onCancel={() => this.handleCancel()}
              showConfirm={false}
              confirmBtnCssClass={
                classes.button + " " + classes.success
              }
              cancelBtnCssClass={
                classes.button + " " + classes.danger
              }
            >
              <div>
                <h4>{app.name}</h4>
                <form style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl + " " + "dialog-dropdown"}>
                    <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                Version
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu + " " + "dialog-dropdown"
                      }}
                      classes={{
                        select: classes.select
                      }}
                      onChange={this.handleVersionSelection}
                      inputProps={{
                        name: "installVersion",
                        id: "installVersion",
                        value: this.state.installVersion ? this.state.installVersion.tag : ""
                      }}
                    >
                      {prodAppBuilds.length && userIsHLXSupport ?
                        prodAppBuilds.map(build => (
                          <MenuItem key={build.tag} value={build.tag}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}>{`Version ${build.tag}`}</MenuItem>
                        ))
                        :
                        prodAppBuilds.length ?
                          <MenuItem value={prodAppBuilds[0].tag}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}>{`Version ${prodAppBuilds[0].tag} (Latest)`}</MenuItem>
                          :
                          null
                      }
                      {hotfixAppBuilds && userIsHLXSupport &&
                        hotfixAppBuilds.map(build => (
                          <MenuItem key={build.tag} value={build.tag}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}>{`Version ${build.tag} (Hotfix)`}</MenuItem>
                        ))
                      }
                      {rcAppBuilds && (this.userCanInstallRC(user)) && rcAppBuilds.map(build => (
                        <MenuItem key={build.tag} value={build.tag}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}>{`Version ${build.tag} (Release Candidate)`} </MenuItem>
                      ))}
                      {devAppBuild && this.userIsHLXSupport &&
                                <MenuItem value="dev"
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}>{`Latest dev build (${moment(devAppBuild.date).calendar()})`} </MenuItem>}
                      {!devAppBuild && 
                                !prodAppBuilds.length && 
                                !rcAppBuilds.length &&
                                <MenuItem disabled
                                  classes={{ root: classes.selectMenuItem}}>
                                    No valid version available
                                </MenuItem>}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                Install Type
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu + " " + "dialog-dropdown"
                      }}
                      classes={{
                        select: classes.select
                      }}
                      onChange={this.handleInstallType}
                      value={this.state.installType || ""}
                      inputProps={{
                        name: "installType",
                        id: "installType"
                      }}
                    >
                      <MenuItem value={"EDGE"}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}>Edge</MenuItem>
                      <MenuItem value={"CLOUD"}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}>Cloud</MenuItem>
                    </Select>
                  </FormControl>
                  <GridContainer style={{marginTop: 40}} direction="column" justify="center" alignItems="center">
                    <ItemGrid flex direction="row" justify="center">
                      <Button color="danger" style={{ marginRight: "10px" }} onClick={this.handleCancel}>Cancel</Button>
                      <Button color="success"
                        onClick={() => this.handleConfirm()}
                        disabled={!this.validateForm()}>Install</Button>
                    </ItemGrid>
                  </GridContainer>
                </form>
              </div>
            </SweetAlert>
    );
  }
}

export default WithNotifications(withStyles(style)(InstallApp));