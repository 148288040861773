import React from "react";
import pt from "prop-types";
import { getAppBuilds, getAllowedApps, runAlzIntegrated, runAlzGenetic, runCloudCoreApp } from "libs/apiLib";
import { WithNotifications } from "./index";
import { APPS, APPSSET } from "constants/Apps";

export const WithAppRunners = (WrappedComponent, {
  getAllowedApps,
  runAlzIntegrated,
  runAlzGenetic,
  runCloudCoreApp,
  getAppBuilds
}) => {
  class WrapperComponent extends React.Component {
    static propTypes = {
      signalError: pt.func.isRequired,
      clearError: pt.func.isRequired,
      orgId: pt.string.isRequired,
    }

    state = {
      loading: false,
      apps: [],
    }

    componentDidMount() {
      this.getAllowedApps();
    }

    getAllowedApps = async () => {
      const { orgId, signalError, clearError } = this.props;
      this.setState({ loading: true });
      try {
        const response = await getAllowedApps(orgId);
        if (response.success && response.apps) {
          const cloudApps = response.apps.filter(app => APPSSET.has(app.appId));
          const appBuilds = await Promise.all(cloudApps.map(async app => await getAppBuilds(app.appId)));
          const appBuildsMap = appBuilds.reduce((map, build) => {
            if (!build.result.length) {
              return map;
            }
            const appId = build.result[0].appId;
            const versions = build.result.map(r => r.tag);
            return {
              ...map,
              [appId]: versions
            };
          }, {});
          const apps = cloudApps.map(app => ({ ...app, versions: appBuildsMap[app.appId] || []}));
          this.setState({ apps });
        } else {
          throw new Error();
        }
      } catch (e) {
        signalError("Error getting apps. Please try again later.");
        setTimeout(clearError, 5000);
      }
      this.setState({ loading: false });
    }

    runAppRequest = async (patient, app, records, options) => {
      const { signalError, clearError } = this.props;
      this.setState({ loading: true });
      try {
        if (!patient || !app || !records || !records.length) {
          throw new Error("Missing either patient, app, or record data to run app.");
        }
        await this.runApp(patient, app, records, options);
        return Promise.resolve();
      } catch (e) {
        const errMsg = e || "Error running app. Please try again later.";
        signalError(errMsg);
        setTimeout(clearError, 5000);
        return Promise.reject(errMsg);
      }
    }

    runApp = (patient, app, records, options) => {
      switch (app.appId) {
      case APPS.airr:
      {
        const mci = options && options.mci || false;
        return runAlzIntegrated({
          patientId: patient.patientId,
          patientOrgId: patient.orgId,
          neuroQuantKey: this.findDicomKey(records),
          geneticsKey: this.findGenotypeKey(records),
          mci,
        });
      }
      case APPS.agrr:
        return runAlzGenetic({
          patientId: patient.patientId,
          orgId: patient.orgId,
          geneticsKey: this.findGenotypeKey(records),
        });
      case APPS.pgr:
        return runCloudCoreApp({
          appId: app.appId,
          appVersion: this.renderAppVersion(options.appVersion),
          patientId: patient.patientId,
          orgId: patient.orgId,
          s3KeyGenetics: this.findGenotypeKey(records),
          s3KeyImaging: this.findDicomKey(records)
        });
      default:
        return Promise.reject("Error due to unsupported app");
      }
    }

    renderAppVersion = (v) => {
      switch (v) {
      case "dev":
      case "Dev":
        return "dev";
      case "Latest":
      case "latest":
        return undefined; // App service knows to treat an undefined appVersion as the latest version
      default:
        return v; // this is most likely a release candidate or feature version
      }
    }

    findGenotypeKey = (records) => this.findS3LocationKey(records, "Genotype");
    findDicomKey = (records) => this.findS3LocationKey(records, "Dicom Medical Study");

    findS3LocationKey = (records, type) => {
      const key = records.find(r => r.type === type);
      if (!key) {
        return undefined;
      }
      return key.location;
    }

    render() {
      const { apps, loading } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          apps={apps}
          runApp={this.runAppRequest}
        />
      );
    }
  }


  return WrapperComponent;
};

export default (Component) => WithNotifications(WithAppRunners(Component, {
  getAllowedApps,
  runAlzIntegrated,
  runAlzGenetic,
  runCloudCoreApp,
  getAppBuilds
}));