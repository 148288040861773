import React from "react"
import PropTypes from "prop-types"
import Chartist from "react-chartist"
import PlainCard from "components/Cards/PlainCard.jsx"
import withStyles from "material-ui/styles/withStyles"
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle"
import Reports from "models/Reports"

export const SuccessRate = ({ classes, report }) => {
	let value = 0
	if (report) {
		value = report.successRate()
	}
	return (
		<PlainCard>
			<Chartist
				className={classes.chartCenter}
				data={{
					series: [{ value, className: "ct-series-success" }]
				}}
				type="Pie"
				options={{
					donutWidth: 26,
					donut: true,
					donutSolid: true,
					labelInterpolationFnc: function (value) {
						return Math.round(value / 100 * 100) + "%"
					},
					total: 100,
				}}
			/>
			<h4 style={{ textAlign: "center" }}>Success Rate</h4>
		</PlainCard>
	)
}

SuccessRate.propTypes = {
	classes: PropTypes.shape({}),
	report: PropTypes.instanceOf(Reports)
}

export default withStyles(chartsStyle)(SuccessRate)