import React from "react";
import PropTypes from "prop-types";
import Chartist from "react-chartist";
import IconCard from "components/Cards/IconCard.jsx";
import DropDown from "components/CustomDropdown/CustomDropdown";

import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

// Styles
import LooksOne from "material-ui-icons/LooksOne";

import Reports from "models/Reports";
import { Line } from "components/Charts";

export class SingleAppSuccessFailure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.report && this.props.report ||
      prevProps.report && this.props.report && (!prevProps.report.start.isSame(this.props.report.start) || !prevProps.report.end.isSame(this.props.report.end))
    ) {
      const topApp = this.props.report.topAppBy("usage");
      const app = this.props.report.filterAppsBy(a => a.appName === topApp.appName);
      this.setState({
        selected: app
      });
    }
  }

  handleAppChange = (name) => {
    if (this.props.report) {
      const app = this.props.report.filterAppsBy(a => a.appName === name);
      this.setState({ selected: app });
    }
  }

  render = () => {
    const { selected } = this.state;
    const { report } = this.props;
    let category;
    let content;
    if (report && selected) {
      const highestStat = selected.reduce((n, stat) => Math.max(n, stat.successful, stat.failures), 0);
      const failures = selected.map(stat => stat.failures);
      const successful = selected.map(stat => stat.successful);
      const dates = selected.map(stat => stat.date.format("MMM D"));
      const appNames = report.appNames();
      const appName = selected[0].appName;
      category = "- " + appName;
      let dropDown = null;
      if (appNames.length > 1) {
        dropDown = (
          <GridContainer justify="flex-end">
            <ItemGrid container xs={2} justify="flex-end">
              <DropDown
                buttonColor="primary"
                buttonText={appName}
                onAction={(name) => this.handleAppChange(name)}
                dropdownList={appNames}
              />
            </ItemGrid>
          </GridContainer>
        );
      }
      content = (
        <React.Fragment>
          {dropDown}
          <Line
            noXSeriesRender={() => <h3><small>No report</small></h3>}
            emptyXSeriesRender={() => <h3><small>No report</small></h3>}
            legendTitle="Legend"
            legend={["Failure", "Success"]}
            xLabels={dates}
            xSeries={[
              { name: "failures", data: failures, meta: { style: "stroke: #ef5350;" } },
              { name: "successful", data: successful, meta: { style: "stroke: #0fb8ad;" } },
            ]}
            options={{
              axisY: {
                onlyInteger: true,
                high: highestStat,
              },
              axisX: {
                type: Chartist.FixedScaleAxis,
                divisor: 5,
              }
            }}
          />
        </React.Fragment>
      );
    } else {
      content = <h3><small>No report</small></h3>;
    }

    return (
      <IconCard
        iconColor="orange"
        icon={LooksOne}
        title="App Performance"
        category={category}
        content={content}
      />
    );
  }
}

SingleAppSuccessFailure.propTypes = {
  report: PropTypes.instanceOf(Reports)
};

export default SingleAppSuccessFailure;