import React from "react";
import moment from "moment";
import pt from "prop-types";
import { withRouter } from "react-router-dom";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import DatePickerModal from "components/DatePickerModal";
import Button from "components/CustomButtons/Button.jsx";

// local components
import MonthlyOrganizationInsights from "./components/MonthlyOrganizationInsights";
import DailyOrganizationInsights from "./components/DailyOrganizationInsights";
import GeneticKits from "./components/GeneticKits";
import OrgsMetadata from "./components/OrgsMetadata";

export class Reports extends React.Component {
  static propTypes = {
    history: pt.shape({
      push: pt.func.isRequired
    }).isRequired,
    location: pt.shape({
      pathname: pt.string.isRequired,
      search: pt.string.isRequired
    }).isRequired
  }

  constructor(props) {
    super(props);
    this.day = moment();
    this.week = moment().startOf("week");
    this.month = moment().startOf("month");
    this.lastMonth = moment().subtract(1, "month").startOf("month");
    this.lastThreeMonths = moment().subtract(3, "month").startOf("month");
    this.year = moment().startOf("year");
    this.state = {
      start: this.day,
      end: this.day,
      modal: null,
    };
  }

  componentDidMount() {
    const { start, end } = this.getDatesInURLSearchParams();
    if (this.validDates({ start, end })) {
      this.updateDatesInURLSearchParams({ start, end });
      this.setState({
        start,
        end,
      });
    } else {
      this.updateDatesInURLSearchParams(this.state);
    }
  }

  handleDateChange = (start, end) => {
    this.updateDatesInURLSearchParams({ start, end });
    this.setState({ start, end, modal: null });
  }

  getDatesInURLSearchParams = () => {
    const url = new URLSearchParams(this.props.location.search);
    return {
      start: url.has("start") ? moment(url.get("start")) : undefined,
      end: url.has("end") ? moment(url.get("end")) : undefined
    };
  }

  validDates = ({ start, end }) => {
    if (!start || !end) {
      return false;
    }
    const s = moment(start);
    const e = moment(end);
    return s.isValid() && e.isValid();
  }

  updateDatesInURLSearchParams = ({ start, end }) => {
    if (!this.validDates({ start, end })) {
      return;
    }
    const url = new URLSearchParams(this.props.location.search);
    url.set("start", start.format("YYYY-MM-DD"));
    url.set("end", end.format("YYYY-MM-DD"));
    const search = url.toString();
    this.props.history.push({
      pathname: this.props.location.pathname,
      search
    });
  }

  openDatePickerModal = () => {
    this.setState({
      modal: <DatePickerModal
        start={this.state.start}
        end={this.state.end}
        onConfirm={this.handleDateChange}
        onCancel={() => this.setState({ modal: null })}
      />
    });
  }

  render() {
    const { start, end, modal } = this.state;
    return (
      <React.Fragment>
        {modal}
        <OrgsMetadata />
        <GridContainer style={{ marginTop: "3em" }}>
          <ItemGrid xs={12} md={6}>
            <h2 style={{ margin: 0 }}>Overview</h2>
          </ItemGrid>
          <ItemGrid xs={12} md={6}>
            <GridContainer justify="flex-end">
              <ItemGrid>
                <Button color="white" onClick={this.openDatePickerModal}>{`${start.format("MMMM Do YYYY")} - ${end.format("MMMM Do YYYY")}`}</Button>
              </ItemGrid>
            </GridContainer>
          </ItemGrid>
        </GridContainer>
        <MonthlyOrganizationInsights {...this.state} />
        <GeneticKits {...this.state} />
        <DailyOrganizationInsights {...this.state} />
      </React.Fragment>
    );
  }
}

export default withRouter(Reports);